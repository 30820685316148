import React from 'react';
import { compose } from 'redux';
import { useTheme } from '@mui/material/styles';

import {
  Box,
  Divider,
  IconButton,
  Link,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';

import {
  Pause as PauseIcon,
  PlayArrow as PlayIcon,
  SwapHoriz as TransferIcon,
} from '@mui/icons-material';

import FormDialog from '../Components/Dialog_NEW/FormDialog';
import UnitHealth, { HEALTH_STATES_ARRAY } from '../Components/UnitHealth';
import StateLabel from '../Components/State/StateLabel';
import StateLabelNEW from '../Components/StateNEW/StateLabel';
import ModeLabel from '../Components/Mode/ModeLabel';
import ModeLabelNEW from '../Components/ModeNEW/ModeLabel';
import BookmarkButton from '../Components/BookmarkButton';

import { useWindowDimensions } from '../../Utils/ScreenSize';
import { MODES, MODES_ARRAY } from '../../Utils/Modes';
import { STATES, STATES_ARRAY } from '../../Utils/States';
import { ROLES } from '../../Utils/Roles';

import withUserData from '../../redux/WithData/user';
import withTrackerData from '../../redux/WithData/tracker';
import withTrackerActions from '../../redux/WithActions/tracker';
import withEventData from '../../redux/WithData/event';
import withEventActions from '../../redux/WithActions/event';

const STATUS_SIZE = 28;

const HeaderRow = ({
  height,
  children,
}) => (
  <Box sx={theme => ({
    backgroundColor: theme.palette.background.header,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: `${height}px`,
  })}>
    {children}
  </Box>
)

const Header = ({
  rowHeight,
  tabs,
  tab,
  onChangeTab,

  role,
  tracker,
  isTrackerLoading,
  bookmarkedTrackers,
  autoUpdateEvents,
  getRecentAndBookmarkedTrackers,
  addTrackerToBookmarks,
  removeTrackerFromBookmarks,
  setAutoUpdateEvents,
}) => {
  const theme = useTheme();
  const { isMobileWidth, isDesktopWidth } = useWindowDimensions();

  const [transferOpen, setTransferOpen] = React.useState(false);
  const [bookmarkOpen, setBookmarkOpen] = React.useState(false);

  const [health, setHealth] = React.useState(0);
  const [mode, setMode] = React.useState(0);
  const [state, setState] = React.useState(0);

  const [bookmarkLocal, setBookmarkLocal] = React.useState(false);
  const [bookmarkOther, setBookmarkOther] = React.useState(false);

  const id            = tracker?.simiccid;
  const customerName  = tracker?.franchise?.name || null;       // TODO: switch to customer name
  const assetName     = tracker?.asset?.name || tracker?.name;

  const SPACING   = !isMobileWidth ? 1 : 0.5;
  const ICON_SIZE = !isMobileWidth ? '2rem' : '1.8rem';
  
  // Load bookmarked tracker data
  React.useEffect(() => {
    getRecentAndBookmarkedTrackers();
  }, [
    getRecentAndBookmarkedTrackers,
  ]);
  
  // Set bookmark icon state
  React.useEffect(() => {
    setBookmarkLocal(!!bookmarkedTrackers.find((bookmark) => bookmark.simiccid === tracker.simiccid));
  }, [
    tracker,
    bookmarkedTrackers,
  ]);
  
  const handleClickTransfer = () => {
    setHealth((health + 1) % HEALTH_STATES_ARRAY.length);
    setMode((mode + 1) % MODES_ARRAY.length);
    setState((state + 1) % STATES_ARRAY.length);

    setBookmarkOther(!bookmarkOther);
  }

  const handleClickBookmark = () => {
    // Tracker is already bookmarked -> remove from bookmarks
    if (bookmarkLocal) {
      removeTrackerFromBookmarks(tracker.simiccid);
    }
    // Else -> open dialog to bookmark tracker
    else {
      setBookmarkOpen(true);
    }
  }
  
  const handleSubmitTransfer = (values) => {
    console.log(`Transferred unit to '[${values.franchise}] ${values.customer}'`);

    // TODO: API call

    setTransferOpen(false);
  }

  const handleSubmitBookmark = (values) => {
    // API call
    addTrackerToBookmarks(tracker);

    setBookmarkOpen(false);
  }

  const handleValidateBookmark = (values) => {
    const errors = {};

    // Reason
    if (!values.reason || values.reason.length < 1) {
      errors.reason = 'Required';
    }

    return errors;
  }

  /**
   *  ROOT users:
   *  Get list of franchises. Get list of customers for selected franchise OR get all customers then filter by franchise?
   * 
   *  ADMIN users:
   *  Get list of customers for current franchise.
   */
  const getButtonTransfer = (sx) => (
    <>
      <IconButton onClick={handleClickTransfer} sx={sx}><TransferIcon sx={{ fontSize: ICON_SIZE }}/></IconButton>
      {/* <IconButton onClick={() => { setTransferOpen(true); }} sx={sx}><TransferIcon/></IconButton> */}
      {/* <FormDialog
        open={transferOpen}
        title="Transfer Unit"
        defaultValues={{ franchise: null, customer: null }}
        onClose={() => { setTransferOpen(false); }}
        onSubmit={handleSubmitTransfer}
        // onValidate={handleValidateTransfer}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
        }) => (
          <></>
        )}
      </FormDialog> */}
    </>
  );

  const getButtonBookmark = (sx, rowHeight) => (
    <>
      <BookmarkButton local={bookmarkLocal} other={bookmarkOther} iconSize={ICON_SIZE} onClick={handleClickBookmark} sx={sx}/>
      <FormDialog
        open={bookmarkOpen}
        title="Bookmark Unit"
        defaultValues={{ reason: '' }}
        onClose={() => setBookmarkOpen(false)}
        onSubmit={handleSubmitBookmark}
        onValidate={handleValidateBookmark}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
        }) => (
          <>
            <TextField
              required
              autoFocus
              multiline
              rows={3}
              name="reason"
              label="Reason"
              value={values.reason}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(errors.reason)}
              helperText={errors.reason || undefined}
            />
            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              Please provide a brief description about why you’re bookmarking this unit
            </Typography>
          </>
        )}
      </FormDialog>
    </>
  );

  const getButtonToggleAutoUpdate = (sx) => (
    <IconButton
      title={autoUpdateEvents ? 'Stop displaying live tracker data' : 'Display live tracker data'}
      onClick={() => setAutoUpdateEvents(!autoUpdateEvents)}
      sx={sx}
    >
      {autoUpdateEvents ? <PauseIcon sx={{ fontSize: ICON_SIZE }}/> : <PlayIcon sx={{ fontSize: ICON_SIZE }}/>}
    </IconButton>
  );

  return (
    <Paper sx={{
      width: '100%',
      borderRadius: 0,
      position: 'fixed',
      zIndex: 1,
      [theme.breakpoints.down('tablet')]: {
        position: 'sticky',
        top: 0,
      },
    }}>
      {tracker ? isDesktopWidth ? (
        // Desktop header
        <HeaderRow height={rowHeight}>
          <UnitHealth state={HEALTH_STATES_ARRAY[health]} size={STATUS_SIZE} sx={{ m: `auto ${theme.spacing(SPACING)}` }}/>
          <StateLabelNEW state={STATES_ARRAY[state]} sx={{ height: STATUS_SIZE, m: 'auto 0', mr: SPACING }}/>
          <ModeLabelNEW mode={MODES_ARRAY[mode]} sx={{ height: STATUS_SIZE, m: 'auto 0', mr: SPACING }}/>

          <Typography variant="h6" sx={{ fontWeight: 400, m: 'auto' }}>{assetName}</Typography>
          
          {role === ROLES.root && (
            <Link href="/franchises/123" target="_blank" rel="noopener noreferrer" underline="hover" color="inherit" variant="h6" sx={{ fontWeight: 400, m: 'auto 0', mr: 1 }}>{'[AM]'}</Link>
          )}

          <Link href="/customers/123" target="_blank" rel="noopener noreferrer" underline="hover" color="inherit" variant="h6" sx={{ fontWeight: 400, m: 'auto 0' }}>{customerName}</Link>

          <Typography variant="h6" sx={{ fontWeight: 400, m: 'auto' }}>{id}</Typography>

          {getButtonTransfer({ m: 'auto 0' })}
          {getButtonBookmark({ m: 'auto 0' })}
          {getButtonToggleAutoUpdate({ m: 'auto 0', mr: 1 })}
        </HeaderRow>
      ) : (
        // Tablet/Mobile header
        <>
          <HeaderRow height={rowHeight}>
            <UnitHealth state={HEALTH_STATES_ARRAY[health]} size={STATUS_SIZE} sx={{ m: 'auto 0', ml: SPACING * 2 }} />
            <StateLabelNEW state={STATES_ARRAY[state]} sx={{ height: STATUS_SIZE, m: 'auto 0', ml: SPACING }}/>
            <ModeLabelNEW mode={MODES_ARRAY[mode]} sx={{ height: STATUS_SIZE, m: 'auto 0', ml: SPACING }}/>

            <Typography variant="h6" sx={{ fontWeight: 400, m: 'auto' }}>{assetName}</Typography>

            {getButtonBookmark({ p: '4px', m: 'auto 0', mr: SPACING * 2 }, rowHeight)}
          </HeaderRow>

          <Divider/>

          <HeaderRow height={rowHeight}>
            {getButtonTransfer({ m: 'auto 0', ml: SPACING * 2, mr: SPACING })}

            {role === ROLES.root && (
              <Link href="/franchises/123" target="_blank" rel="noopener noreferrer" underline="hover" color="inherit" sx={{ m: 'auto 0', mr: 1 }}>{'[AM]'}</Link>
            )}
            
            <Link href="/customers/123" target="_blank" rel="noopener noreferrer" underline="hover" color="inherit" sx={{ m: 'auto 0' }}>{customerName}</Link>

            <Typography sx={{ m: 'auto', mr: SPACING * 2 }}>{id}</Typography>
          </HeaderRow>
        </>
      ) : isDesktopWidth ? (
        // Desktop header loading
        <Box sx={{ height: `${rowHeight}px` }}/>
      ) : (
        // Tablet/Mobile header loading
        <Divider sx={{ m: `${rowHeight}px 0` }}/>
      )}
    
      <Divider/>

      <Tabs value={tab} onChange={onChangeTab} variant={isDesktopWidth ? 'fullWidth' : 'scrollable'} sx={theme => ({ backgroundColor: theme.palette.background.header })}>
        {tabs.map((tab) => (
          <Tab value={tab.key} key={tab.key} label={tab.label} />
        ))}
      </Tabs>
    </Paper>
  );
}

export default compose(
  withUserData,
  withTrackerData,
  withTrackerActions,
  withEventData,
  withEventActions
)(Header);