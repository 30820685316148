import { DateTime } from  'luxon';

import { SORT_ORDERS } from './Sorting';
import { NAV_TYPES } from './NavTypes';

export const EVENT_TYPES = {
  location:     { id: 'location',     objectType: 'trackerEvent', type: 'LOCATION' },
  note:         { id: 'note',         objectType: 'trackerNote' },
  audit:        { id: 'audit',        objectType: 'auditableEvent' },
  comms:        { id: 'comms',        objectType: 'trackerEvent' },
  notification: { id: 'notification', objectType: '' },
};

export const EVENT_TYPES_ARRAY = [
  EVENT_TYPES.location,
  EVENT_TYPES.note,
  EVENT_TYPES.audit,
  EVENT_TYPES.comms,
  EVENT_TYPES.notification,
];

export const AUDIT_DETAILS_KEY = 'auditDetails';

export function getEventType(event) {
  switch (event?.objectType) {
    case 'trackerEvent':
      if (event.type === 'LOCATION') {
        return EVENT_TYPES.location;
      }
      else {
        return EVENT_TYPES.comms;
      }

    case 'trackerNote':
    return EVENT_TYPES.note;

    case 'auditableEvent':
    return EVENT_TYPES.audit;

    case 'notificationEvent':
    return EVENT_TYPES.notification;
  }
}

export function isLocationEvent(event) {
  return (event.objectType === EVENT_TYPES.location.objectType) && (event.type === EVENT_TYPES.location.type);
}

export function matchesEventFilter({ event, filter: { startTime, endTime } }) {
  return isWithinTimeBounds(event, startTime, endTime);
}

export function matchesLocationFilter({ event, filter: { startTime, endTime, gps, gsm, wifi } }) {
  return isLocationEvent(event)
    && isWithinTimeBounds(event, startTime, endTime)
    && isLocationTypeAllowed(event, gps, gsm, wifi);
}

export function sortEventByTimestamp(eventA, eventB) {
  const timestampA = DateTime.fromISO(eventA.timestamp);
  const timestampB = DateTime.fromISO(eventB.timestamp);

  if (timestampA < timestampB) return 1;
  if (timestampA > timestampB) return -1;
  return 0;
}

function isWithinTimeBounds(event, startTime, endTime) {
  if (!startTime && !endTime) {
    return true;
  }

  const eventTimestamp = DateTime.fromISO(event.timestamp);

  let afterStart = (!startTime || startTime <= eventTimestamp);
  let beforeEnd  = (!endTime || eventTimestamp <= endTime);

  return afterStart && beforeEnd;
}

function isLocationTypeAllowed(event, gps, gsm, wifi) {
  switch (event.details.position.method) {
    case NAV_TYPES.GPS.id:
      return gps;
      
    // case NAV_TYPES.GPS_LO.id:
    //   return gsm;

    case NAV_TYPES.GSM.id:
      return gsm;

    case NAV_TYPES.WIFI.id:
      return wifi;

    default:
      return false;
  }
}