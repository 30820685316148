import { connect } from 'react-redux';

const mapStateToProps = ({
  TrackerData: {
    trackerLocationsEvent: { totalCount, data, selectedEvent },
  },
}) => ({
  totalCount,
  selectedEvent,
  locationEvents: data,
});
export default (WrappedComponent) => connect(mapStateToProps, null)(WrappedComponent);
