import moment from 'moment-timezone';
import momentUtils from 'moment-timezone/moment-timezone-utils';
import timezoneWithOffset from '../Assets/availableTimezones.json';

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

const TimeTools = {
  transformToTimezone: (timestamp, { timezone = 'UTC' } = { timezone: 'UTC' }) => (
    { datetime: moment.utc(timestamp).tz(timezone), timezone }
  ),
  getTimezoneFormat: (timestamp, timezoneObj = undefined) => {
    const time = TimeTools.transformToTimezone(timestamp, timezoneObj);
    return `${time.datetime.format('D MMM YYYY, h:mm:ss')} ${time.timezone}`;
  },
  getTimezoneFormat24: (timestamp, timezoneObj = undefined) => {
    const time = TimeTools.transformToTimezone(timestamp, timezoneObj);
    return `${time.datetime.format('D MMM YYYY, HH:mm:ss')} ${time.timezone}`;
  },
  transform: (datetime) => moment.utc(datetime).tz(timeZone),
  dayOfWeek: (timestamp, timezoneObj = undefined) => TimeTools.transformToTimezone(timestamp, timezoneObj).datetime.format('dddd'),
  showDate: (timestamp, timezoneObj = undefined) => {
    const time = TimeTools.transformToTimezone(timestamp, timezoneObj);
    return time.datetime.format('D MMM YYYY');
  },
  showTime: (timestamp, timezoneObj = undefined) => {
    const time = TimeTools.transformToTimezone(timestamp, timezoneObj);
    return `${time.datetime.format('h:mm:ss')} ${time.timezone}`;
  },
  todayRange: (datetime = moment()) => {
    const startTime = moment(datetime);
    const endTime = moment(datetime);
    startTime.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    endTime.set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 999,
    });
    return { start: startTime.utcOffset(0), end: endTime.utcOffset(0) };
  },
  yesterdayRange: (datetime = moment()) => {
    const startTime = moment(datetime).subtract(1, 'day');
    const endTime = moment(datetime).subtract(1, 'day');
    startTime.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    endTime.set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 999,
    });
    return { start: startTime.utcOffset(0), end: endTime.utcOffset(0) };
  },
  getTzList: () => (
    timezoneWithOffset // momentUtils.tz.names()
  ),
  generateTimezoneListFormat: (tz) => {
    const offset = moment().tz(tz).utcOffset() / 60;
    const separator = (offset < 0) ? '' : '+';
    return { label: `UTC${separator}${offset}, ${tz}`, tz, offset };
  },
  // Helper to generate the timezone list
  buildTimezoneList: () => {
    const timezoneList = momentUtils.tz.names().map(
      (tz) => TimeTools.generateTimezoneListFormat(tz),
    );
    return timezoneList.sort((a, b) => ((a.offset > b.offset) ? 1 : -1));
  },
};
export default TimeTools;
