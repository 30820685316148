import { createAction } from 'redux-actions';

const ENTITY = '@@FOLDER';

// Get Root Folders
export const getRootLevelFolders = createAction(`${ENTITY}/GET_ROOT_LEVEL_FOLDERS`);
export const loadRootLevelFolders = createAction(`${ENTITY}/LOAD_ROOT_LEVEL_FOLDERS`);

// Get Current Folder
export const getCurrentFolder = createAction(`${ENTITY}/GET_CURRENT_FOLDER`);
export const loadCurrentFolder = createAction(`${ENTITY}/LOAD_CURRENT_FOLDER`);

// Pagination
export const getMoreFolderItems = createAction(`${ENTITY}/GET_MORE_FOLDER_ITEMS`);
export const loadMoreFolderItems = createAction(`${ENTITY}/LOAD_MORE_FOLDER_ITEMS`);

// Create New Folder
export const loadNewFolder = createAction(`${ENTITY}/LOAD_NEW_FOLDER`);
export const createNewFolder = createAction(`${ENTITY}/CREATE_NEW_FOLDER`);

// Change View
export const toggleView = createAction(`${ENTITY}/TOGGLE_VIEW`);

// Select
export const toggleItem = createAction(`${ENTITY}/TOGGLE_ITEM`);
export const toggleAllItems = createAction(`${ENTITY}/TOGGLE_ALL_ITEMS`);
export const deselectAllItems = createAction(`${ENTITY}/DESELECT_ALL_ITEMS`);

// Loading
export const loaded = createAction(`${ENTITY}/LOADED`);
export const loading = createAction(`${ENTITY}/LOADING`);

// Filter
export const filterItems = createAction(`${ENTITY}/FILTER_ITEMS`);
export const removeFilter = createAction(`${ENTITY}/REMOVE_FILTER`);

// Delete
export const deleteFolder = createAction(`${ENTITY}/DELETE_FOLDER`);
export const deleteFolderRequest = createAction(`${ENTITY}/DELETE_FOLDER_REQUEST`);

// Rename
export const renameFolder = createAction(`${ENTITY}/RENAME_FOLDER`);
export const renameFolderRequest = createAction(`${ENTITY}/RENAME_FOLDER_REQUEST`);

// Move
export const moveItems = createAction(`${ENTITY}/MOVE_ITEMS`);
export const toggleMoveItems = createAction(`${ENTITY}/TOGGLE_MOVE_ITEMS`);
export const moveItemsRequest = createAction(`${ENTITY}/MOVE_ITEMS_REQUEST`);

// Permissions
export const shareFolder = createAction(`${ENTITY}/SHARE_FOLDER`);
export const shareSubfolders = createAction(`${ENTITY}/SHARE_SUBFOLDERS`);
export const withdrawFolderAccess = createAction(`${ENTITY}/WITHDRAW_FOLDER_ACCESS`);
export const addUserToFolderPermissions = createAction(`${ENTITY}/ADD_USER_TO_FOLDER_PERMISSIONS`);
export const removeUserFromFolderPermissions = createAction(`${ENTITY}/REMOVE_USER_FROM_FOLDER_PERMISSIONS`);

// Assign
export const assignTrackersRequest = createAction(`${ENTITY}/ASSIGN_TRACKERS_REQUEST`);
export const assignTrackers = createAction(`${ENTITY}/ASSIGN_TRACKERS`);
export const unassignTrackersRequest = createAction(`${ENTITY}/UNASSIGN_TRACKERS_REQUEST`);
export const unassignTrackers = createAction(`${ENTITY}/UNASSIGN_TRACKERS`);
