import { createAction } from 'redux-actions';

const ENTITY = '@@EVENT_NEW';

// Latest Events
export const getLatestEvents = createAction(`${ENTITY}/GET_LATEST_EVENTS`);
export const storeLatestEvents = createAction(`${ENTITY}/STORE_LATEST_EVENTS`);
export const setLatestEventsLoading = createAction(`${ENTITY}/SET_LATEST_EVENTS_LOADING`);

// Notes
export const createNote = createAction(`${ENTITY}/CREATE_NOTE`);
export const deleteNote = createAction(`${ENTITY}/DELETE_NOTE`);
export const getNotes = createAction(`${ENTITY}/GET_NOTES`);
export const storeNewNote = createAction(`${ENTITY}/STORE_NEW_NOTE`);
export const storeNotes = createAction(`${ENTITY}/STORE_NOTES`);
export const updateNote = createAction(`${ENTITY}/UPDATE_NOTE`);
export const setNotesFilter = createAction(`${ENTITY}/SET_NOTES_FILTER`);
export const setNotesLoading = createAction(`${ENTITY}/SET_NOTES_LOADING`);

// Audit Log
export const getAuditLogs = createAction(`${ENTITY}/GET_AUDIT_LOGS`);
export const getAuditDetails = createAction(`${ENTITY}/GET_AUDIT_DETAILS`);
export const storeAuditLogs = createAction(`${ENTITY}/STORE_AUDIT_LOGS`);
export const storeAuditDetails = createAction(`${ENTITY}/STORE_AUDIT_DETAILS`);
export const setAuditLogsFilter = createAction(`${ENTITY}/SET_AUDIT_LOGS_FILTER`);
export const setAuditLogsLoading = createAction(`${ENTITY}/SET_AUDIT_LOGS_LOADING`);
export const setAuditDetailsLoading = createAction(`${ENTITY}/SET_AUDIT_DETAILS_LOADING`);

// Comms Log
export const getCommsLogs = createAction(`${ENTITY}/GET_COMMS_LOGS`);
export const storeCommsLogs = createAction(`${ENTITY}/STORE_COMMS_LOGS`);
export const setCommsLogsFilter = createAction(`${ENTITY}/SET_COMMS_LOGS_FILTER`);
export const setCommsLogsLoading = createAction(`${ENTITY}/SET_COMMS_LOGS_LOADING`);

// Notification Log
export const getNotificationLogs = createAction(`${ENTITY}/GET_NOTIFICATION_LOGS`);
export const storeNotificationLogs = createAction(`${ENTITY}/STORE_NOTIFICATION_LOGS`);
export const setNotificationLogsFilter = createAction(`${ENTITY}/SET_NOTIFICATION_LOGS_FILTER`);
export const setNotificationLogsLoading = createAction(`${ENTITY}/SET_NOTIFICATION_LOGS_LOADING`);

// WebSocket
export const receiveEventFromWS = createAction(`${ENTITY}/RECEIVE_EVENT_FROM_WS`);
export const setAutoUpdateEvents = createAction(`${ENTITY}/SET_AUTO_UPDATE_EVENTS`);

export const storeNoteWS = createAction(`${ENTITY}/STORE_NOTE_WS`);
export const storeAuditLogWS = createAction(`${ENTITY}/STORE_AUDIT_LOG_WS`);
export const storeCommsLogWS = createAction(`${ENTITY}/STORE_COMMS_LOG_WS`);
export const storeNotificationLogWS = createAction(`${ENTITY}/STORE_NOTIFICATION_LOG_WS`);
