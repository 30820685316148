import React from 'react';
import { compose } from 'redux';
import { styled, alpha } from '@mui/material/styles';

import { Link } from 'react-router-dom';

import {
  Box,
  ButtonBase,
  Typography,
} from '@mui/material';

import UnitHealth from '../Components/UnitHealth';
import Image from '../Components/Image';

import unitImage from '../../Assets/Images/vehicle.png';
import defaultSVGColour from '../../Assets/SVGs/mtrack_logo.svg';
import defaultSVGWhite from '../../Assets/SVGs/mtrack_logo_white.svg';

import withSystemData from '../../redux/WithData/system';

/**
 * TODO: Adjust size based on number of units - maintain 16:9 ratio
 * PC - Personal AND Bookmarked w/ >6 of either - 272 x 153 - title: 32, health: 22
 * PC - Personal XOR Bookmarked                 - 384 x 216 - title: 36, health: 24
 */

const CardBase = styled(ButtonBase)(({ theme }) => ({
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.borderRadius(2),
  boxShadow: theme.shadows[2],
  flexDirection: 'column',
  alignItems: 'start',
  justifyContent: 'start',
  overflow: 'clip',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',

  transition: theme.transitions.create(
    ['filter'],
    {
      duration: theme.transitions.duration.short,
    },
  ),

  // TODO: check performance impact on non-VM!!!
  // TODO: just change background colour for SVGs to reduce performance impact
  '&:hover': {
    filter: 'brightness(92%)',

    // Reset on touch devices, it doesn't add specificity
    '@media (hover: none)': {
      filter: null,
    },
  },
}));

const UnitCard = ({
  key,
  tracker,
  size = 'medium',
  clickable = true,
  sx,

  appTheme,
}) => {
  const sizes = (size === 'large') ? {
    imgW: 384,
    imgH: 216,
    header: 36,
    health: 24,
  } : {
    imgW: 272,
    imgH: 153,
    header: 32,
    health: 22,
  };

  return (
    <Box key={key} sx={sx}>
      <Link to={clickable ? `/trackers/${tracker.simiccid}` : undefined}>
        <CardBase sx={{
          width:  `${sizes.imgW}px`,
          height: `${sizes.imgH}px`,
          backgroundImage: `url(${tracker.image ? unitImage : (appTheme === 'light' ? defaultSVGColour : defaultSVGWhite)})`,
          backgroundSize: tracker.image ? 'cover' : 'auto',
        }}>
          <Box sx={theme => ({ display: 'flex', flexDirection: 'row', width: '100%', height: `${sizes.header}px`, justifyContent: 'center', backgroundColor: alpha(theme.palette.background.paper, 0.8)})}>
            <Typography sx={{ m: 'auto', ml: 2 }}>{tracker.name}</Typography>
            <Typography sx={{ m: 'auto 0' }}>{tracker.battery}%</Typography>

            <UnitHealth state={tracker.health} size={sizes.health} sx={{ m: 'auto 0', ml: 1, mr: 2 }}/>
          </Box>
        </CardBase>
      </Link>
    </Box>
  );
}

export default compose(withSystemData)(UnitCard);