import { isArray, isEmpty } from 'underscore';

const LocationEvents = {
  getSelectedEventObj: (events) => {
    const selected = events.filter((e) => (e.selected === true));
    return isEmpty(selected) ? events[0] : selected[0];
  },
  setSelected: (events, eventToSelect, force = false) => {
    if (isEmpty(events) || isEmpty(eventToSelect)) return false;
    const selectedItems = events.filter((e) => (e.selected === true));
    selectedItems.forEach((event) => {
      if (event.id !== eventToSelect.id && event.selected) event.selected = false;
    });
    const selected = (events.filter((e) => (e.id === eventToSelect.id)))[0];
    if (!selected) return false;
    selected.selected = (!selected.selected || force);
    return selected.selected;
  },
  getSelectedEventIndex: (events) => events.filter((e, i) => ((e.selected === true) ? i : -1)),
  getEventByIndex: (events, index) => (events.filter((e, i) => i === index))[0],
  getEventByID: (events, id) => (events.filter((e) => e.id === id))[0],
  getEventIndexByID: (events, id) => events.findIndex((e) => e.id === id),
  hasSelectedItem: (events) => {
    if (isEmpty(events) || !isArray(events)) return false;
    const selected = events.filter((e) => (e.selected === true));
    return !isEmpty(selected);
  },
  // Type helpers
  isLocationType: (event) => (event.type && event.type === 'LOCATION'),
  isGPSType: (event) => (LocationEvents.isLocationType(event) && event.details.position.method === 'GPS'),
  isGSMType: (event) => (LocationEvents.isLocationType(event) && event.details.position.method === 'GSM'),
  isWIFIType: (event) => (LocationEvents.isLocationType(event) && event.details.position.method === 'WIFI'),
  // Tab map view
  getLocationDetails: (locationEvent) => {
    const { details } = locationEvent;

    let locationData = {
      timestamp: locationEvent.timestamp,
      type: details.position.method,
    };

    if (!isEmpty(locationEvent)) {
      if ('gps' in details) {
        locationData = { ...locationData, ...details.gps };
      } if ('wifis' in details) {
        locationData = { ...locationData, ...details.wifi };
      } if (('gsmCells' in details)) {
        locationData = { ...locationData, ...details.gsm };
      }
    }
    return locationData;
  },
  cleanEventsFromAPI: (events) => events.filter((element) => element && element.objectType === 'trackerEvent'),
};
export default LocationEvents;
