import AuthCall from '../Endpoints/auth';

const FolderAPI = {
  getFolderContent: async (folderId, offset = 0, limit = 10) => {
    const response = await AuthCall.get(
      `/api/v1/secure/folder/content/${folderId}`,
      { limit, offset },
    );
    return response;
  },
  getFolderDetails: async (folderId) => {
    const response = await AuthCall.get(
      `/api/v1/secure/folder/instance/${folderId}`,
    );
    return response;
  },
  createNewFolder: async (folderName, parentFolderId) => {
    const response = await AuthCall.put(
      `/api/v1/secure/folder/instance/${parentFolderId}`,
      { name: folderName },
    );
    return response;
  },
  deleteFolder: async (folderId) => {
    const response = await AuthCall.delete(
      `/api/v1/secure/folder/instance/${folderId}`,
    );
    return response;
  },
  moveItems: async (newParentFolderId, itemsIds) => {
    const response = await AuthCall.post(
      `/api/v1/secure/folder/object/move/${newParentFolderId}`,
      { documentIds: itemsIds },
    );
    return response;
  },
  moveObjectToFolder: async (folder, listOfDocuments) => {
    const response = await AuthCall.post(
      `/api/v1/secure/folder/object/move/${folder}`,
      { listOfDocuments },
    );
    return response;
  },
  shareFolder: async (folderId, user, cascade = true) => {
    const response = await AuthCall.post(
      `/api/v1/secure/folder/permissions/${folderId}`,
      undefined,
      {},
      { cascade, user },
    );
    return response;
  },
  withdrawFolderAccess: async (folderId, user, cascade = true) => {
    const response = await AuthCall.delete(
      `/api/v1/secure/folder/permissions/${folderId}`,
      undefined,
      {},
      { cascade, user },
    );
    return response;
  },
  renameFolder: async (folderId, folderName) => {
    const response = await AuthCall.post(
      `/api/v1/secure/folder/rename/${folderId}`,
      { name: folderName },
    );
    return response;
  },
  getRootLevelFoldersIds: async () => {
    const response = await AuthCall.get('/api/v1/secure/folder/search/root');
    return response;
  },
};

export default FolderAPI;
