import makeStyles from '@mui/styles/makeStyles';

import {
  mobile,
  cardBoxShadow,
  tableRowOnHoverPurple,
} from '../../../Styles/styles';

const folderListStyles = makeStyles((theme) => ({
  folderListContainer: {
    width: '100%',
    flexGrow: '1',
    display: 'flex',
    padding: '24px',
    borderRadius: '8px',
    flexDirection: 'column',
    boxSizing: 'border-box',
    backgroundColor: 'white',
    boxShadow: cardBoxShadow,
    // Breakpoint for Mobile
    [theme.breakpoints.down(mobile)]: {
      boxShadow: 'unset',
    },
  },
  emptyFolderList: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyFolderListHeader: {
    color: '#9E9E9E',
    fontSize: '20px',
    textAlign: 'center',
  },
  actionBarContainer: {
    display: 'flex',
    padding: '8px 16px',
    alignItems: 'center',
    justifyContent: 'space-between',
    // Breakpoint for Mobile
    [theme.breakpoints.down(mobile)]: {
      paddingRight: '0px',
      minHeight: '50px',
    },
  },
  searchBarContainer: {
    display: 'flex',
    color: theme.palette.primary.main,
    alignItems: 'center',
  },
  searchBarIconContainer: {
    minWidth: '56px',
    '& svg': {
      fontSize: '24px',
    },
  },
  toggleAllCheckboxContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    // Breakpoint for Mobile
    [theme.breakpoints.down(mobile)]: {
      padding: 'unset',
    },
  },
  folderListView: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    // Breakpoint for Mobile
    [theme.breakpoints.down(mobile)]: {
      overflowY: 'unset',
    },
  },
  folderMosaicView: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    // Breakpoint for Mobile
    [theme.breakpoints.down(mobile)]: {
      justifyContent: 'center',
    },
  },
  folderItemListView: {
    '&:hover': {
      cursor: 'pointer',
      borderRadius: '10px',
      backgroundColor: '#fafafa',
    },
    // Breakpoint for Mobile
    [theme.breakpoints.down(mobile)]: {
      paddingRight: '0px',
    },
  },
  folderItemMosaicView: {
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    // Breakpoint for Mobile
    [theme.breakpoints.down(mobile)]: {
      width: '122px',
    },
  },
  folderListItemIconMosaicView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100px',
    height: '100px',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: '#F2F2F2',
    },
  },
  selectedFolderListItemIconMosaicView: {
    backgroundColor: '#ECF9F3',
  },
  folderListItemIconSmall: {
    fontSize: '24px',
  },
  folderListItemIconBig: {
    fontSize: '65px',
  },
  folderListItemText: {
    '& span': {
      fontSize: '1em',
      fontFamily: 'arial',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  folderListItemTextMosaic: {
    width: '100px',
    textAlign: 'center',
  },
  selectedFolderItem: {
    backgroundColor: '#ECF9F3',
  },
  spinnerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(100vh - 64px)',
  },
  infiniteLoaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  infiniteLoader: {
    margin: '20px auto',
  },
  folderListItemCheckboxContainer: {
    minWidth: '42px',
  },
  folderListItemCheckBox: {
    right: 'unset',
  },
  folderListItemCheckBoxContainerMosaicView: {
    top: '75px',
    right: '36px',
    position: 'absolute',
    // Breakpoint for Mobile
    [theme.breakpoints.down(mobile)]: {
      right: '21px',
    },
  },
  folderListItemCheckBoxMosaicView: {
    '& span': {
      '& svg': {
        borderRadius: '29px',
        backgroundColor: 'white',
      },
    },
    '&:hover': {
      backgroundColor: tableRowOnHoverPurple,
    },
  },
  folderListItemchecked: {
    '& span': {
      '& svg': {
        backgroundColor: 'aliceblue',
        borderRadius: '29px',
      },
    },
  },
}));

export default folderListStyles;
