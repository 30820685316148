import { compose } from 'redux';
import { isEmpty } from 'underscore';
import React, { useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import { Button, ImageList, TextField, ImageListItem } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import TimeTools from '../../../Components/TimeTools';
import {
  white,
  buttonPurple,
  buttonTextPurple,
  onInputHoverPurple,
  onInputFocusPurple,
  onHoverButtonPurple,
  disabledButtonTextGray,
  disabledButtonBackgroundGray,
} from '../../../Styles/styles';

import withUserData from '../../../redux/WithData/user';
import withProfileActions from '../../../Hocs/WithAction/profile';

const timezoneSelectorStyles = makeStyles(() => ({
  timezoneSelectorCancelButton: {
    borderColor: white,
    borderRadius: '8px',
    textTransform: 'none',
    color: buttonTextPurple,
  },
  timezoneSelectorConfirmButton: {
    color: white,
    borderRadius: '8px',
    textTransform: 'none',
    backgroundColor: buttonPurple,
    '&:disabled': {
      backgroundColor: disabledButtonBackgroundGray,
      color: disabledButtonTextGray,
    },
    '&:hover': {
      color: white,
      backgroundColor: onHoverButtonPurple,
    },
  },
  timezoneSelectorDropdown: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& .MuiAutocomplete-inputRoot': {
      height: '40px',
      '& .MuiAutocomplete-input': {
        paddingBottom: 'unset',
        paddingTop: 'unset',
      },
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${onInputHoverPurple}`,
    },
    '& .MuiInputBase-input:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: onInputFocusPurple,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: `1px solid ${onInputFocusPurple}`,
      },
    },
  },
}));

const TimezoneSelector = ({
  userTimezone: userTz,
  handleClose = () => {},
  updateProfileTimezone,
}) => {
  const options = TimeTools.getTzList();
  const classes = timezoneSelectorStyles();
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState(TimeTools.generateTimezoneListFormat(userTz || 'UTC'));
  const updateTimezone = () => {
    updateProfileTimezone(value.tz);
    handleClose();
  };
  return (
    <div>
      <Autocomplete
        value={value}
        options={options}
        inputValue={inputValue}
        getOptionLabel={(option) => option.label}
        className={classes.timezoneSelectorDropdown}
        isOptionEqualToValue={(option) => option.tz === value.tz}
        onChange={(_, newValue) => {
          if (newValue) setValue(newValue); // we don't have empty values
        }}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" />
        )}
      />
      <ImageList style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <ImageListItem style={{ height: 'auto', width: 'unset' }}>
          <Button
            variant="outlined"
            onClick={handleClose}
            className={classes.timezoneSelectorCancelButton}
          >
            Cancel
          </Button>
        </ImageListItem>
        <ImageListItem style={{ height: 'auto', width: 'unset' }}>
          <Button
            variant="contained"
            onClick={updateTimezone}
            className={classes.timezoneSelectorConfirmButton}
            disabled={userTz === value.tz || isEmpty(value)}
          >
            Save
          </Button>
        </ImageListItem>
      </ImageList>
    </div>
  );
};

export default compose(withUserData, withProfileActions)(TimezoneSelector);
