import { connect } from 'react-redux';

const mapStateToProps = ({
  AccountData: {
    accounts,
    areAccountsLoading,
    isCreatingNewAccount,
  },
}) => ({
  accounts,
  areAccountsLoading,
  isCreatingNewAccount,
});

export default (WrappedComponent) => connect(mapStateToProps, null)(WrappedComponent);
