import React from 'react';

import LoadingButton from '@mui/lab/LoadingButton';

const Submit = ({
  variant = "contained",
  buttonText = "Submit",
  ...props
}) => (
  <LoadingButton
    type="submit"
    variant={variant}
    {...props}
  >
    {buttonText}
  </LoadingButton>
);

export default Submit;
