import React from 'react';

import {
  Box,
  Stack,
} from '@mui/material';

import SettingsSection from './SettingsSection';

import { useWindowDimensions } from '../../../../Utils/ScreenSize';
import WakeUpSection from './WakeUpSection';
import MovementSection from './MovementSection';
import { getHeaderHeights } from '../..';

const SettingsTab = () => {
  const windowDimensions = useWindowDimensions();
  const { height, isTabletWidth, isDesktopWidth } = windowDimensions;

  const { totalHeaderHeight } = getHeaderHeights(windowDimensions);
  const USABLE_HEIGHT = height - totalHeaderHeight;

  // Desktop View
  if (isDesktopWidth) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', height: `${USABLE_HEIGHT}px` }}>
        <SettingsSection sx={{ width: '50%', height: '100%', maxHeight: '100%', mr: 0.5 }}/>

        <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column', marginLeft: 0.5 }}>
          <WakeUpSection sx={{ height: '60%', mb: 1 }}/>
          <MovementSection sx={{ height: '40%' }}/>
        </Box>
      </Box>
    );
  }
  // Tablet/Mobile View
  else {
    return (
      <Stack direction="column" spacing={2} sx={isTabletWidth ? { m: 2 } : { mt: 2 }}>
        <SettingsSection/>
        {/* <UnitSection/>
        <AssetSection/>
        <LogsSection/> */}
      </Stack>
    );
  }
}

export default SettingsTab;