import React from 'react';
import { compose } from 'redux';

import {
  Box,
  Button,
  TextField,
  Typography,
} from '@mui/material';

import {
  Delete as DiscardIcon,
  Send as SendIcon,
} from '@mui/icons-material';

import PaperSection from '../../../Components/PaperSection';

import withTrackerData from '../../../../redux/WithData/tracker';
import withTrackerActions from '../../../../redux/WithActions/tracker';

const WakeUpSection = ({
  sx,

  tracker,
  sendRawTrackerSettings,
}) => {
  const [data, setData] = React.useState('');

  function handleChange(e) {
    setData(e.target.value);
  }

  function handleClickSend() {
    // Send settings to server
    sendRawTrackerSettings({
      trackerId: tracker.simiccid,
      rawSettings: data,
    });

    setData('');
  }

  function handleClickDiscard() {
    setData('');
  }

  return (
    // <PaperSection sx={{ ...sx, p: 0 }}>
    //   <Typography variant="h6" sx={{ m: 2, ml: 3 }}>Wake Up Schedule</Typography>
    // </PaperSection>
    <PaperSection sx={sx}>
      <Typography variant="h6" sx={{ mb: 2 }}>Raw SMS Settings</Typography>

      <TextField
        multiline
        rows={5}
        value={data}
        onChange={handleChange}
      />

      <Box sx={{ ml: 'auto', mt: 2 }}>
        <Button variant="contained" startIcon={<SendIcon/>} sx={{ mr: 1 }} onClick={handleClickSend}>Send</Button>
        <Button variant="contained" startIcon={<DiscardIcon/>} onClick={handleClickDiscard}>Discard</Button>
      </Box>
    </PaperSection>
  );
}

export default compose(withTrackerData, withTrackerActions)(WakeUpSection);