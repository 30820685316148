import AuthCall from '../Endpoints/auth';

const FranchiseAPI = {
  getFranchise: async (franchiseId) => {
    const response = await AuthCall.get(`/api/v1/secure/franchise/instance/${franchiseId}`);
    return response;
  },
  getAllFranchises: async () => {
    const response = await AuthCall.get('/api/v1/secure/franchise/instance/all');
    return response;
  },
  generateNewFranchiseSecret: async () => {
    const response = await AuthCall.get('/api/v1/secure/franchise/secret');
    return response;
  },
  createNewFranchise: async (newFranchiseDetails) => {
    const response = await AuthCall.put('/api/v1/secure/franchise/instance', newFranchiseDetails);
    return response;
  },
  editFranchise: async (updatedFranchiseDetails) => {
    const response = await AuthCall
      .post(`/api/v1/secure/franchise/instance/${updatedFranchiseDetails.id}`, updatedFranchiseDetails);
    return response;
  },
  editFranchiseSecret: async (franchiseId, newSecret) => {
    const franchise = { secret: newSecret };
    const response = await AuthCall
      .post(`/api/v1/secure/franchise/secret/${franchiseId}`, franchise);
    return response;
  },
  deleteFranchise: async (franchiseId) => {
    const response = await AuthCall.delete(`/api/v1/secure/franchise/instance/${franchiseId}`);
    return response;
  },
};

export default FranchiseAPI;
