import makeStyles from '@mui/styles/makeStyles';

import {
  mobile,
} from '../../../Styles/styles';

const trackerDetailsStyles = makeStyles((theme) => ({
  trackerDetailsContainer: {
    display: 'flex',
    padding: '24px 32px',
    alignItems: 'center',
    justifyContent: 'space-between',
    // Breakpoint for Tablet
    [theme.breakpoints.down(mobile)]: {
      flexDirection: 'column',
      padding: '10px',
    },
  },
  trackerDetailsActionsContainer: {
    display: 'flex',
    minWidth: '500px',
    alignItems: 'center',
    // Breakpoint for Tablet
    [theme.breakpoints.down(mobile)]: {
      minWidth: 'unset',
      width: '100%',
    },
  },
  trackerImage: {
    // Breakpoint for Tablet
    [theme.breakpoints.down(mobile)]: {
      marginTop: '20px',
    },
  },
  trackersDropDownListContainer: {
    flexGrow: '1',
    display: 'flex',
    margin: '24px 32px',
    borderRadius: '8px',
    padding: '24px 32px',
    flexDirection: 'column',
    // Breakpoint for Mobile
    [theme.breakpoints.down(mobile)]: {
      textAlign: 'center',
    },
  },
  trackersDropDownListTypography: {
    fontSize: '1em',
    fontFamily: 'arial',
    marginBottom: '20px',
  },
  favoriteTrackerButtonContainer: {
    margin: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  favoriteTrackerButton: {
    color: theme.palette.primary.main,
  },
  spinnerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(100vh - 64px)',
  },
}));

export default trackerDetailsStyles;
