import React from 'react';

import {
  Box,
} from '@mui/material';

import PlaceholderPage from '../../Components/PlaceholderPage';

import OverviewTab from './Overview';
// import MapDetailsTab from './MapDetails';
import UsersTab from './Users';

const PLACEHOLDER_TAB = (
  <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
    <PlaceholderPage sx={{ m: 'auto' }}/>
  </Box>
);

const overview = {
  key: 'overview',
  label: 'Overview',
  content: <OverviewTab/>,
};

const reports = {
  key: 'reports',
  label: 'Reports',
  content: PLACEHOLDER_TAB,
};

const users = {
  key: 'users',
  label: 'Users',
  content: <UsersTab/>,
};

export const ALL_TABS = {
  overview,
  reports,
  users,
};

export const ALL_TABS_ARRAY = [
  ALL_TABS.overview,
  ALL_TABS.reports,
  ALL_TABS.users,
];

export function getTabName(tabKey) {
  return ALL_TABS[tabKey] ? ALL_TABS[tabKey].label : null;
}