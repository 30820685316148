import { compose } from 'redux';
import React, { useState } from 'react';

import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Typography,
  IconButton,
} from '@mui/material';

import newModalStyles from './style';
import withFranchiseActions from '../../../Hocs/WithAction/franchise';

const NewModal = ({
  modalButtonName,
  modalHeaderName,
  resetFranchiseSecret,
  ModalContentComponent,
}) => {
  const classes = newModalStyles();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    resetFranchiseSecret();
    setOpen(false);
  };

  const ModalHeader = (
    <div className={classes.modalHeaderContainer}>
      <Typography className={classes.modalHeader}>
        {modalHeaderName}
      </Typography>
      <IconButton onClick={handleClose} className={classes.modalCloseButton} size="large">
        <CloseIcon />
      </IconButton>
    </div>
  );

  const MondalContent = (
    <div className={classes.modalContentContainer}>
      {ModalHeader}
      <ModalContentComponent handleClose={handleClose} />
    </div>
  );

  return (
    <div className={classes.modalContainer}>
      <Button
        variant="contained"
        type="button"
        onClick={handleOpen}
        className={classes.modalButton}
      >
        {modalButtonName}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        className={classes.modal}
      >
        {MondalContent}
      </Modal>
    </div>
  );
};

export default compose(withFranchiseActions)(NewModal);
