import { defaultOverscanIndicesGetter } from 'react-virtualized';
import validator from 'validator';
import { DateTime } from 'luxon';

import roles from '../Assets/roles.json';
import { SLIDER_LABELS } from './Defines';

export const isEmptyArray = (obj) => {
  return Array.isArray(obj) && obj.length === 0;
}

export const isEmptyObject = (obj) => {
  return obj && Object.keys(obj).length === 0;
}

export const openInNewTab = (url) => {
  // Using '_blank' on its own has major security flaws:
  // https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');

  if (newWindow) {
    newWindow.opener = null;
  }
}

export const capitaliseFirstLetter = (string) => {
  return string ? string[0].toUpperCase() + string.slice(1) : string;
}

// TODO: remove
export const timestampToTrackerDatetime = (timestamp) => new Date(timestamp).toLocaleTimeString('en-GB', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  timeZone: 'utc',
  timeZoneName: 'short',
});

export const bearingToCoordinates = (bearing) => {
  if (bearing > 0 && bearing < 90) return 'NE';
  if (bearing === 90) return 'E';
  if (bearing > 90 && bearing < 180) return 'SE';
  if (bearing === 180) return 'S';
  if (bearing > 180 && bearing < 270) return 'SW';
  if (bearing === 270) return 'W';
  if (bearing > 270 && bearing < 360) return 'NW';
  return 'N';
};

export const trackerEventDetailsToRows = ({
  carrier, direction, eventDefinition, msisdn, rawData, simiccid, status, timestamp, type, details,
}) => {
  const rows = [
    { label: 'Event Type', value: type },
    { label: 'Tracker Time', value: timestampToTrackerDatetime(timestamp) },
    { label: 'SIMICCID', value: simiccid },
    { label: 'MSISDN', value: msisdn },
    { label: 'Carrier', value: carrier },
    { label: 'Status', value: status },
    { label: 'Raw Data', value: rawData },
    { label: 'Direction', value: direction },
    { label: 'Event Definition', value: eventDefinition },
  ];
  if (details) {
    const batteryPercentage = { label: 'Battery', value: `${details.battery}%` };
    rows.push(batteryPercentage);
    if (details.gps) {
      const { gps } = details;
      const gpsEventDetails = [
        { label: 'Latitude', value: gps.latitude },
        { label: 'Longitude', value: gps.longitude },
      ];
      rows.push(...gpsEventDetails);
      return rows;
    }
    if (details.wifis) {
      const { wifis } = details;
      const wifiEventDetails = wifis.flatMap((wifi, index) => [
        { label: `BSSID - WIFI ${index + 1}`, value: wifi.bssid },
        { label: `RSSI - WIFI ${index + 1}`, value: wifi.rssi },
      ]);
      rows.push(...wifiEventDetails);
      return rows;
    }
    if (details.gsmCells) {
      const { gsmCells } = details;
      const gsmEventDetails = [
        { label: 'Cell ID', value: gsmCells[0].cellId },
        { label: 'LAC', value: gsmCells[0].lac },
        { label: 'MCC', value: gsmCells[0].mcc },
        { label: 'MNC', value: gsmCells[0].mnc },
        { label: 'Network', value: gsmCells[0].network },
        { label: 'RSSI', value: gsmCells[0].rssi },
        { label: 'TA', value: gsmCells[0].ta },
      ];
      rows.push(...gsmEventDetails);
      return rows;
    }
  }
  return rows;
};

export const passwordRequirements = {
  minLength: 5,
  minLowercase: 0,
  minUppercase: 0,
  minNumbers: 0,
  minSymbols: 0,
};

export const isPasswordStrong = (password) => !validator.isStrongPassword(password, passwordRequirements);

export const passwordHelperText = 'Password must contain at least 5 characters';

export const isAccountRoleRoot = (role) => role === roles[0];
export const isAccountRoleAdmin = (role) => role === roles[1];
export const isAccountRoleUser = (role) => role === roles[2];

export const sortArrayAlphabetically = (array, sortBy) => {
  const sortedArray = array.sort((firstItem, nextItem) => {
    const firstItemName = firstItem[sortBy].toLowerCase();
    const nextItemName = nextItem[sortBy].toLowerCase();
    if (firstItemName < nextItemName) return -1;
    if (firstItemName > nextItemName) return 1;
    return 0;
  });
  return sortedArray;
};

export const formatSliderLabel = (value) => {
  return SLIDER_LABELS[value - 1];
}