import { createAction } from 'redux-actions';

const ENTITY = '@@USER';
// Profile
export const getProfileDetails = createAction(`${ENTITY}/GET_PROFILE_DETAILS`);
export const persistProfileDetails = createAction(`${ENTITY}/PERSIST_PROFILE_DETAILS`);
export const updateProfileAction = createAction(`${ENTITY}/UPDATE_PROFILE`);

// Profile Image
export const uploadProfileImage = createAction(`${ENTITY}/UPLOAD_PROFILE_IMAGE`);
export const profileImageUploading = createAction(`${ENTITY}/PROFILE_IMAGE_UPLOADING`);
export const loadDefaultProfileImage = createAction(`${ENTITY}/LOAD_DEFAULT_PROFILE_IMAGE`);

// Timezone
export const updateProfileTimezone = createAction(`${ENTITY}/UPDATE_PROFILE_TIMEZONE`);
export const saveProfileTimezone = createAction(`${ENTITY}/SAVE_PROFILE_TIMEZONE`);

// Profile Password
export const changePassword = createAction(`${ENTITY}/CHANGE_PASSWORD`);
export const resetPassword = createAction(`${ENTITY}/RESET_PASSWORD`);
