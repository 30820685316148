import React, { useEffect } from 'react';

import {
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

import { isAccountRoleRoot, isAccountRoleAdmin, isAccountRoleUser } from '../../../Utils/Utils';

const AssignAccount = ({
  role,
  franchise: { id: franchiseId, name: franchiseName },
  franchises,
  customers,
  values,
  setValues,
  handleChange,
  getAllFranchises,
  getAllCustomers,
}) => {
  useEffect(() => {
    if (isAccountRoleRoot(role)) {
      getAllFranchises();
    }
  }, [getAllFranchises, role]);

  useEffect(() => {
    if (!isAccountRoleUser(role)) {
      getAllCustomers(franchiseId);
    }
  }, [franchiseId, getAllCustomers, role]);

  useEffect(() => {
    // Prevent user from adding a customer account with root or admin priviledges
    if (isAccountRoleRoot(role)) setValues({...values, assignOption: ((values.role === 2) ? 1 : 0)});
    if (isAccountRoleAdmin(role) && values.role === 1) setValues({...values, assignOption: 1});
    if (isAccountRoleAdmin(role) && values.role === 0) setValues({...values, assignOption: 0});
  }, [values.role, role]);

  const dropdownOptions = [
    { id: 1, name: 'Franchise' },
    { id: 2, name: 'Customer' },
  ];
  
  return (
    <>
      <Typography variant="h6">
        Assign Account
      </Typography>
      <Typography variant="body2">
        Choose a franchise or customer that the new account will be assigned to.
      </Typography>
      <TextField
        select
        disabled
        name="assignOption"
        value={values.assignOption}
        onChange={handleChange}>
          {dropdownOptions.map((option, index) => (
            <MenuItem value={index} key={option.id}>{option.name}</MenuItem>
          ))}
      </TextField>
      { values.assignOption === 0 ? (
        // 'Franchise' selector
        <TextField
          select
          name="selectedFranchise"
          value={values.selectedFranchise}
          onChange={handleChange}>
            {isAccountRoleRoot(role) ? (
              (franchises.length > 0) ? (
                // ROOT && at least 1 franchise exists
                franchises.map((franchise, index) => (
                  <MenuItem value={index} key={franchise.id}>{franchise.name}</MenuItem>
                ))
              ) : (
                // ROOT but no franchises exist
                <MenuItem value={0} key={0}>No franchises found</MenuItem>
              )
            ) : (
              // ADMIN
              <MenuItem value={0} key={franchiseId}>{franchiseName}</MenuItem>
            )}
        </TextField>
      ) : (
        // 'Customer' selector
        <TextField
          select
          name="selectedCustomer"
          value={values.selectedCustomer}
          onChange={handleChange}>
            {customers.length !== 0 ?
              (customers.map((customer, index) => (<MenuItem value={index} key={customer.id}>{customer.name}</MenuItem>))
            ) : (
              <MenuItem value={0} key={0}>No customers found</MenuItem>
            )}
        </TextField>
      )}
    </>
  );
};

export default AssignAccount;