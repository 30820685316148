import { handleActions } from 'redux-actions';

import { logout } from '../Actions/auth';
import {
  setGenerateDummyLocations,
  setLocationsFilter,
  setLatestLocationsLoading,
  setLocationsLoading,
  setSelectedLocations,
  setShowTowers,
  storeLatestLocations,
  storeLocations,
  storeLocationWS,
  updateLocation,
} from '../Actions/location';

import { matchesLocationFilter } from '../../Utils/Events';

const defaultState = {
  latestLocations: {},
  filteredLocations: [],
  selectedLocations: [],
  locationFilter: {
    limit: 10,
    startTime: undefined,
    endTime: undefined,
    gps: true,
    gsm: true,
    wifi: true,
  },
  areLatestLocationsLoading: false,
  areLocationsLoading: false,
  showTowers: false,

  isGeneratingDummyLocations: false,
};

export default handleActions({
  [logout]: () => defaultState,

  [setGenerateDummyLocations]:  (state, { payload: generateDummyData }) => ({ ...state, isGeneratingDummyLocations: generateDummyData }),
  [setLocationsFilter]:         (state, { payload: filter })            => ({ ...state, locationFilter: filter }),
  [setLatestLocationsLoading]:  (state, { payload: isLoading })         => ({ ...state, areLatestLocationsLoading: isLoading }),
  [setLocationsLoading]:        (state, { payload: isLoading })         => ({ ...state, areLocationsLoading: isLoading }),
  [setSelectedLocations]:       (state, { payload: locations })         => ({ ...state, selectedLocations: locations }),
  [setShowTowers]:              (state, { payload: showTowers })        => ({ ...state, showTowers: showTowers }),
  [storeLatestLocations]:       (state, { payload: latestLocations })   => ({ ...state, latestLocations: latestLocations }),
  [storeLocations]:             (state, { payload: locations })         => ({ ...state, filteredLocations: locations }),

  [storeLocationWS]: (state, { payload: locationEvent }) => {
    let newFilteredLocations = [ ...state.filteredLocations ];

    const matchesFilter = matchesLocationFilter({ event: locationEvent, filter: state.locationFilter });

    if (matchesFilter) {
      newFilteredLocations.unshift(locationEvent);
      
      // Remove locations from the end of the array until it reaches the filtered limit
      if (state.locationFilter?.limit > 0) {
        while (newFilteredLocations.length > state.locationFilter.limit) {
          newFilteredLocations.pop();
        }
      }
    }

    return {
      ...state, 
      filteredLocations: newFilteredLocations,
      latestLocations: {
        ...state.latestLocations,
        [locationEvent.details.position.method]: locationEvent,
      },
    };
  },

  [updateLocation]: (state, { payload: location }) => {
    let newFilteredLocations = [ ...state.filteredLocations ]
    let newSelectedLocations = [ ...state.selectedLocations ]

    // Match locations using their IDs
    let filteredIdx = newFilteredLocations.findIndex(loc => loc.id === location.id);
    let selectedIdx = newSelectedLocations.findIndex(loc => loc.id === location.id);

    if (filteredIdx >= 0) { newFilteredLocations[filteredIdx] = location; }
    if (selectedIdx >= 0) { newSelectedLocations[selectedIdx] = location; }

    return { 
      ...state,
      filteredLocations: newFilteredLocations,
      selectedLocations: newSelectedLocations,
    };
  },
}, defaultState);