import React, { useEffect } from 'react';
import { compose } from 'redux';

import { CircularProgress } from '@mui/material';
import trackerSettingsStyles from './style';

import NewTrackerSettings from './NewTrackerSettings/NewTrackerSettings';
import CurrentTrackerSettings from './CurrentTrackerSettings/CurrentTrackerSettings';
import RawTextTrackerSettings from './RawTextTrackerSettings/RawTextTrackerSettings';

import withUserData from '../../../../redux/WithData/user';
import withTrackerData from '../../../../Hocs/WithData/tracker';
import withTrackerActions from '../../../../Hocs/WithAction/tracker';

const SettingsTab = ({
  trackerId,
  userTimezone,
  currentTracker,
  isAccountRoleRoot,
  getTrackerSettings,
  areTrackerSettingsLoading,
  updateTrackerSettingsRequest,
  updateTrackerRawSettingsRequest,
  areNewTrackerSettingsUpdating,
  areNewTrackerRawSettingsUpdating,
}) => {
  const classes = trackerSettingsStyles();

  useEffect(() => {
    getTrackerSettings({ trackerId });
  }, [getTrackerSettings, trackerId]);

  if (areTrackerSettingsLoading) {
    return (
      <div className={classes.trackerSettingsSpinnerContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div className={[classes.trackerSettingsContainer, classes.topElement].join(' ')}>
        <CurrentTrackerSettings
          classes={classes}
          currentSettings={currentTracker.currentSettings}
        />
        <NewTrackerSettings
          classes={classes}
          userTimezone={userTimezone}
          currentTracker={currentTracker}
          updateTrackerSettingsRequest={updateTrackerSettingsRequest}
          areNewTrackerSettingsUpdating={areNewTrackerSettingsUpdating}
        />
      </div>
      {isAccountRoleRoot && (
        <div className={classes.trackerSettingsContainer}>
          <RawTextTrackerSettings
            classes={classes}
            currentTracker={currentTracker}
            updateTrackerRawSettingsRequest={updateTrackerRawSettingsRequest}
            areNewTrackerRawSettingsUpdating={areNewTrackerRawSettingsUpdating}
          />
        </div>
      )}
    </>
  );
};

export default compose(
  withTrackerActions,
  withTrackerData,
  withUserData,
)(SettingsTab);
