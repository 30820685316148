import React from 'react';

import {
  Box,
  Button,
  Divider,
} from '@mui/material';

import {
  Clear as ClearIcon,
  Tune as FilterIcon,
} from '@mui/icons-material';

const FilterButton = ({
  fixedWidth = false,
  showClear,
  onFilter,
  onClear,
  sx,
}) => {
  return (
    <Box sx={theme => ({
      ...sx,
      display: 'flex',
      flexDirection: 'row',
      width: 'max-content',
      borderRadius: 1,
      boxShadow: theme.shadows[2],
      mr: (!showClear && fixedWidth) ? '37px' : 0
    })}>
      <Button
        variant="contained"
        startIcon={<FilterIcon/>}
        onClick={onFilter}
        sx={showClear ? { borderTopRightRadius: 0, borderBottomRightRadius: 0, boxShadow: 'none' } : { boxShadow: 'none' }}
      >
        Filter
      </Button>
      {showClear && (
        <>
          <Divider orientation="vertical" flexItem/>
          <Button
            variant="contained"
            startIcon={<ClearIcon/>}
            onClick={onClear}
            sx={{
              p: '6px 8px',
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              minWidth: 0,
              boxShadow: 'none',
              '& .MuiButton-startIcon': {
                m: 0,
              },
            }}
          />
        </>
      )}
    </Box>
  );
}

export default FilterButton;