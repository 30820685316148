import { FormControl, MenuItem, Select } from '@mui/material';
import React from 'react';

const ScheduleModeDropDown = ({
  setNewTrackerSettings,
  newTrackerSettings,
  scheduleModes,
  classes,
}) => {
  const handleChangeScheduleMode = ({ target: { value } }) => {
    setNewTrackerSettings({
      ...newTrackerSettings,
      mode: value,
    });
  };
  return (
    <FormControl
      variant="outlined"
      className={classes.trackerSettingsScheduleModeDropdownControl}
    >
      <Select
        value={newTrackerSettings.heartbeatType}
        onChange={handleChangeScheduleMode}
        className={classes.trackerSettingsScheduleModeDropdown}
      >
        {scheduleModes.map((mode, index) => (
          <MenuItem key={mode} value={index}>
            {mode === 'INT' ? 'Interval' : 'Calendar'}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ScheduleModeDropDown;
