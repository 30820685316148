import { compose } from 'redux';
import React, { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  Button,
  IconButton,
  DialogTitle,
  DialogContent,
} from '@mui/material';

import addNewScheduleModalStyles from './style';
import AddNewScheduleModalContent from './AddNewScheduleModalContent/AddNewScheduleModalContent';
import withSystemActions from '../../../../../../redux/WithActions/system';

const AddNewScheduleModal = ({
  setNewTrackerSettings,
  newTrackerSettings,
  addNotification,
  schedulesLimit,
}) => {
  const classes = addNewScheduleModalStyles();
  const [showAddNewScheduleModal, setShowAddNewScheduleModal] = useState(false);

  const handleClickOpen = () => {
    if (newTrackerSettings.calendarEvents.length >= schedulesLimit) {
      addNotification({
        type: 'error',
        source: 'AddNewScheduleModal',
        title: 'You have reached the limit!',
        message: 'You have reached the 14 schedules limit for this tracker. If you want to add a new schedule, please make sure to delete a existing one first.',
      });
    } else {
      setShowAddNewScheduleModal(true);
    }
  };

  const handleClose = () => {
    setShowAddNewScheduleModal(false);
  };

  return <>
    <Button
      onClick={handleClickOpen}
      className={classes.addNewScheduleModalButton}
    >
      Add new schedule +
    </Button>
    <Dialog
      onClose={handleClose}
      open={showAddNewScheduleModal}
      className={classes.addNewScheduleModalContainer}
    >
      <div className={classes.addNewScheduleModalHeaderContainer}>
        <DialogTitle className={classes.addNewScheduleModalHeader}>
          Add New Schedule
        </DialogTitle>
        <IconButton
          onClick={handleClose}
          className={classes.addNewScheduleModalCloseButton}
          size="large">
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent className={classes.addNewScheduleModalContent}>
        <AddNewScheduleModalContent
          handleClose={handleClose}
          newTrackerSettings={newTrackerSettings}
          setNewTrackerSettings={setNewTrackerSettings}
        />
      </DialogContent>
    </Dialog>
  </>;
};

export default compose(withSystemActions)(AddNewScheduleModal);
