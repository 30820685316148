import React from 'react';

import {
  Box,
} from '@mui/material';

const BORDER_WIDTH = '3px';

const ModeLabel = ({
  mode,
  sx,
}) => {
  return (
    <Box title={mode.description} sx={theme => ({
      p: '0 8px',
      backgroundColor: theme.palette.action.hover,
      borderBottomWidth: BORDER_WIDTH,
      borderBottomColor: theme.palette.modes[mode.id].dark,
      borderBottomStyle: 'solid',
      ...sx,
    })}>
      {mode?.label || '???'}
    </Box>
  );
}

export default ModeLabel;