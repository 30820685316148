import AuthCall from '../Endpoints/auth';

const CustomersAPI = {
  getCustomer: async (customerId) => {
    const response = await AuthCall.get(`/api/v1/secure/customer/instance/${customerId}`);
    return response;
  },
  getCustomers: async (franchise) => {
    const response = await AuthCall.get('/api/v1/secure/customer/instance/all', franchise);
    return response;
  },
  addCustomer: async (customer) => {
    const response = await AuthCall.put('/api/v1/secure/customer/instance', customer);
    return response;
  },
  editCustomer: async (customerDetails) => {
    const response = await AuthCall.post(`/api/v1/secure/customer/instance/${customerDetails.id}`, customerDetails);
    return response;
  },
  generateNewCustomerSecret: async () => {
    const response = await AuthCall.get('/api/v1/secure/customer/secret');
    return response;
  },
};

export default CustomersAPI;
