import { connect } from 'react-redux';

import {
  login,
  logout,
  resetPassword,
  registerUser,
} from '../Actions/auth';

export default (WrappedComponent) => connect(null, {
  login,
  logout,
  resetPassword,
  registerUser,
})(WrappedComponent);