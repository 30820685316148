const lightPalette = {
  background: {
    default: '#FAF7FA',
    paper: '#FFFFFF',
    header: '#FAFAFA',
  },
  primary: {
    main: '#0048FF',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#92278F',
  },

  states: {
    ALARM: {
      light: '#F5CFC6',
      main: '#F0BAAD',
      dark: '#E89581'
    },
    WARNING: {
      light: '#FAEBBF',
      main: '#F7E2A2',
      dark: '#F3D270'
    },
    ALARM_ACK: {
      light: '#FAE9E5',
      main: '#F8E0DA',
      dark: '#F5CFC6'
    },
    WARNING_ACK: {
      light: '#FDF6E2',
      main: '#FCF2D5',
      dark: '#FAEBBF'
    },
    FAULTY: {
      light: '#D5B3EF',
      main: '#C292E8',
      dark: '#A157DB'
    },
    NO_CONTACT: {
      light: '#C8D6FC',
      main: '#B0C4FA',
      dark: '#85A5F8'
    },
    LATE_CONTACT: {
      light: '#E6EDFE',
      main: '#DBE4FD',
      dark: '#C8D6FC'
    },
    FAULTY_SENT: {
      light: '#F4DAF6',
      main: '#EFC9F3',
      dark: '#E7ACEC'
    },
    FAULTY_ACK: {
      light: '#CEEBC0',
      main: '#B9E2A4',
      dark: '#93D273'
    },
    OK: {
      light: '#FFFFFF',
      main: '#F4F4F4',
      dark: '#EEEEEE'
    },
    OFF: {
      light: '#DEDEDE',
      main: '#CFCFCF',
      dark: '#B5B5B5'
    },
  },

  modes: {
    FACTORY: {
      light: '#DEDEDE',
      main: '#CFCFCF',
      dark: '#B5B5B5'
    },
    STOCK: {
      light: '#D2DBF0',
      main: '#BFCCEA',
      dark: '#9CB0DE'
    },
    STANDARD: {
      light: '#FFFFFF',
      main: '#F4F4F4',
      dark: '#EEEEEE'
    },
    WARNING: {
      light: '#FAEBBF',
      main: '#F7E2A2',
      dark: '#F3D270'
    },
    ALARM: {
      light: '#F5CFC6',
      main: '#F0BAAD',
      dark: '#E89581'
    },
    STOLEN: {
      light: '#E9C5DF',
      main: '#DFABD0',
      dark: '#CE7EB7'
    },
    TEST: {
      light: '#CBEEE8',
      main: '#B4E6DD',
      dark: '#8CD9CB'
    },
  },

  nav: {
    GPS: {
      light: '#FCB6AC',
      main: '#FA9587',
      dark: '#F85C47'
    },
    GPS_LO: {
      light: '#FFE4B0',
      main: '#FED88D',
      dark: '#FEC350'
    },
    WIFI: {
      light: '#B7DBFC',
      main: '#97CAFA',
      dark: '#5FAEF8'
    },
    GSM: {
      light: '#F1C2F3',
      main: '#EBA6ED',
      dark: '#E177E4'
    },
  },

  comms: {
    GPRS: '#34D926',
    CAT_M1: '#A157DB',
    SMS: '#55C6F6',
  },

  locationGpsHi: {
    main: '#F85C47',
  },
  locationGpsLo: {
    main: '#FEC350',
  },
  locationWifi: {
    main: '#5FAEF8',
  },
  locationGsm: {
    main: '#EBA6ED',
  },

  health: {
    t0: '#CFCFCF',  // Grey
    t1: '#67C9F3',  // Blue
    t2: '#65DB5B',  // Green
    t3: '#D1F860',  // Yellow
    t4: '#FAAE3E',  // Amber
    t5: '#E54242',  // Red
    t6: '#AB6DDC',  // Purple
  },
};

export default lightPalette;