import React from 'react';
import { Typography } from '@mui/material'
import { Restore } from '@mui/icons-material';

const textWithIcon = ({
  children, type = 'h3', className = '', icon = <Restore />,
}) => {
  const classes = `text-with-icon-wrapper ${className}`;
  return (
    <>
      <Typography className={classes} component={type}>
        {icon}
        {children}
      </Typography>
    </>
  );
};
export default textWithIcon;
