import React, { useState } from 'react';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import timezones from '../../../../../Assets/availableTimezones.json';

const TimezoneDropdown = ({ classes, newTrackerSettings, setNewTrackerSettings }) => {
  const options = timezones
    .map((timezone) => timezone.label);

  const initialValue = timezones
    .find((timezone) => timezone.tz === newTrackerSettings.timezone).label;

  const [value, setValue] = useState(initialValue);
  const [inputValue, setInputValue] = useState('');

  const handleTimezoneChange = (_, newValue) => {
    if (newValue) {
      const newTimezone = timezones.find((timezone) => timezone.label === newValue);
      setValue(newValue);
      setNewTrackerSettings({
        ...newTrackerSettings,
        timezone: newTimezone.tz,
      });
    }
  };

  return (
    <Autocomplete
      value={value}
      options={options}
      inputValue={inputValue}
      onChange={(_, newValue) => handleTimezoneChange(_, newValue)}
      className={classes.trackerSettingsScheduleModeTimezoneDropdown}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
      renderInput={(params) => <TextField {...params} variant="outlined" />}
    />
  );
};

export default TimezoneDropdown;
