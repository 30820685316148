import { createAction } from 'redux-actions';

const ENTITY = '@@EVENTS';
export const storeLastEventAction = createAction(`${ENTITY}/SAVE_LAST_EVENT_CURRENT_TRACKER`);
// All events
export const getAllEventsTrackerAction = createAction(`${ENTITY}/REQUEST_ALL_EVENTS_TRACKER`);
export const storeAllEventsTrackerAction = createAction(`${ENTITY}/STORE_ALL_EVENTS_TRACKER`);
export const loadingEventListAction = createAction(`${ENTITY}/LOADING_ALL_EVENT_TRACKER`);
export const changePageEventTrackerListAction = createAction(`${ENTITY}/CHANGE_PAGE_EVENT_TRACKER_LIST`);
export const changeLimitEventTrackerListAction = createAction(`${ENTITY}/CHANGE_LIMIT_EVENT_TRACKER_LIST`);
