import React from 'react';
import { compose } from 'redux';

import makeStyles from '@mui/styles/makeStyles';

import { mobile } from '../../Styles/styles';
import FolderList from './FolderList/FolderList';
import withFoldersData from '../../Hocs/WithData/folder';
import FolderToolBar from './FolderToolBar/FolderToolBar';
import withFoldersActions from '../../Hocs/WithAction/folder';

const folderManagerStyles = makeStyles((theme) => ({
  folderManagerContainer: {
    flexDirection: 'column',
    boxSizing: 'border-box',
    alignItems: 'center',
    padding: '24px',
    display: 'flex',
    [theme.breakpoints.down(mobile)]: {
      padding: '0px',
    },
  },
}));

const FolderManager = () => {
  const classes = folderManagerStyles();
  return (
    <div className={classes.folderManagerContainer}>
      <FolderToolBar />
      <FolderList />
    </div>
  );
};

export default compose(withFoldersActions, withFoldersData)(FolderManager);
