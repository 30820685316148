import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { styled } from '@mui/material/styles';

import {
  Box,
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SvgIcon,
  Typography,
} from '@mui/material';

import {
  Close as CloseIcon,
  Done as ConfirmIcon,
} from '@mui/icons-material';

import Submit from '../Inputs/SubmitButton';

function FormDialog ({
  open,
  title,
  children,
  defaultValues,
  confirmLabel = 'Confirm',
  confirmIcon = <ConfirmIcon/>,
  closeLabel = 'Cancel',
  closeIcon = <CloseIcon/>,
  caption,
  captionWarning,
  // isSubmitting,
  width = 480,
  onSubmit,
  onClose,
  onValidate,
}) {
  const DialogBase = styled(MuiDialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
      width: `${width}px`,
      maxWidth: '90vw',
    },
    '& .MuiDialogTitle-root': {
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    },
    '& .MuiDialogContent-root': {
      maxHeight: '60vh',
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    },
    '& .MuiDialogActions-root': {
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    },
  }));
  
  return (
    <DialogBase
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-header"
    >
      {title && (typeof(title) === 'string' ? (
        <DialogTitle>
          {title}
        </DialogTitle>
      ) : (
        {title}
      ))}

      <Formik
        initialValues={defaultValues}
        validate={onValidate}
        onSubmit={(values) => onSubmit(values)}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent dividers>
              {children({
                values,
                errors,
                handleChange,
                handleBlur,
              })}
            </DialogContent>

            <DialogActions>
              <Button variant="text" startIcon={closeIcon} onClose={onClose} sx={{ m: 'auto', ml: 0 }}>{closeLabel}</Button>

              {caption && (
                <Typography variant="caption" sx={theme => ({
                  ml: 2,
                  mr: 2,
                  textAlign: 'center',
                  color: captionWarning ? theme.palette.error.main : theme.palette.text.secondary,
                })}>
                  {caption}
                </Typography>
              )}

              <Submit
                // loading={isSubmitting}
                buttonText={confirmLabel}
                startIcon={confirmIcon}
                sx={{ m: 'auto', mr: 0 }}
              />
            </DialogActions>
          </form>
        )}
      </Formik>
    </DialogBase>
  );
}

FormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  children: PropTypes.func.isRequired,
  defaultValues: PropTypes.object.isRequired,
  confirmLabel: PropTypes.string,
  confirmIcon: PropTypes.instanceOf(SvgIcon),
  closeLabel: PropTypes.string,
  closeIcon: PropTypes.instanceOf(SvgIcon),
  caption: PropTypes.string,
  captionWarning: PropTypes.bool,
  // isSubmitting: PropTypes.bool,
  width: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onValidate: PropTypes.func,
};

export default FormDialog;