import { compose } from 'redux';
import validator from 'validator';
import React, { useEffect, useState } from 'react';

import {
  Button,
  Select,
  Switch,
  ImageList,
  MenuItem,
  FormGroup,
  FormControl,
  ImageListItem,
  FormControlLabel,
} from '@mui/material';

import modalContentStyles from './style';
import InputField from '../../Inputs/InputField';
import AuthorizedUsersList from './AuthorizedUsersList';
import CurrentlyAssignedList from './AssignedTrackersList';
import {
  isPasswordStrong,
  isAccountRoleRoot,
  isAccountRoleUser,
  isAccountRoleAdmin,
} from '../../../../Utils/Utils';

import withAuthData from '../../../../Hocs/WithData/auth';
import withUserData from '../../../../redux/WithData/user';
import withFoldersData from '../../../../Hocs/WithData/folder';
import withCustomerData from '../../../../Hocs/WithData/customer';
import withFranchiseData from '../../../../Hocs/WithData/franchise';
import withCustomerActions from '../../../../Hocs/WithAction/customer';
import withFranchiseActions from '../../../../Hocs/WithAction/franchise';

const ModalContent = ({
  role,
  customers,
  franchises,
  isLoggedIn,
  selectedItems,
  getAllCustomers,
  getAllFranchises,
  helperText = '',
  modalAction = '',
  inputFields = [],
  shareSubfolders,
  handleClose = () => {},
  handleConfirm = () => {},
  franchise: { id: franchiseId },
  arePermissionsCascadedToSubfolders,
}) => {
  const classes = modalContentStyles();

  const [values, setValues] = useState({ franchise: 0, customer: 0 });
  const [dropdownOption, setDropdownOption] = useState(isAccountRoleRoot(role) ? 0 : 1);

  const isAssigning = modalAction === 'Assign';
  const isAssigningTrackerToCustomer = dropdownOption === 1;
  const isAssigningTrackerToFranchise = dropdownOption === 0;
  const currentFranchiseCustomers = customers
    .filter((customer) => customer.franchise.uuid === franchiseId);

  const areAllTrackersUnassigned = selectedItems
    .filter((selectedItem) => selectedItem.type === 'tracker')
    .every((selectedTracker) => !selectedTracker.franchise.id && !selectedTracker.customer.id);

  const dropdownOptions = [
    { id: 1, name: isAssigning ? 'To franchise' : 'From all franchises' },
    { id: 2, name: isAssigning ? 'To customer' : 'From all customers' },
  ];

  useEffect(() => {
    if (isAccountRoleRoot(role) && isLoggedIn) {
      getAllFranchises();
    }
  }, [getAllFranchises, isLoggedIn, role]);

  useEffect(() => {
    if (!isAccountRoleUser(role) && isLoggedIn) {
      getAllCustomers();
    }
  }, [getAllCustomers, isLoggedIn, role]);

  const handleChange = ({ target }) => {
    setValues({ ...values, [target.name]: target.value });
  };

  const handleClick = () => {
    const franchise = franchises[values.franchise];
    const customer = currentFranchiseCustomers[values.customer];
    if (modalAction === 'Assign') {
      if (isAccountRoleRoot(role) && dropdownOption === 0) {
        handleConfirm({
          franchise: {
            id: franchise.id,
            name: franchise.name,
          },
        });
      } else {
        handleConfirm({
          customer: {
            id: customer.id,
            name: customer.name,
          },
        });
      }
    } else if (modalAction === 'Unassign') {
      if (isAccountRoleRoot(role) && dropdownOption === 0) {
        handleConfirm({ franchise: true });
      } else {
        handleConfirm({ customer: true });
      }
    } else {
      handleConfirm(values);
    }
    handleClose();
  };

  const handleOptionDropdownChange = ({ target: { value } }) => {
    setDropdownOption(value);
  };

  const isConfirmButtonDisabled = () => {
    switch (modalAction) {
      case 'Delete':
      case 'Unassign':
        return false;
      case 'Create':
      case 'Rename':
        return !values[inputFields[0].id];
      case 'Assign':
        return (isAssigningTrackerToCustomer && currentFranchiseCustomers.length === 0)
          || (isAssigningTrackerToFranchise && franchises.length === 0);
      case 'Share':
      case 'Reset':
        return !(values[inputFields[0].id] && validator.isEmail(values[inputFields[0].id]));
      case 'Change':
        return !(values[inputFields[0].id] && !isPasswordStrong(values[inputFields[0].id]));
      default:
        return true;
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !isConfirmButtonDisabled()) {
      // Hitting 'Enter' without e.preventDefault(); doesn't allow handleClose() to work
      e.preventDefault();
      handleConfirm(values);
      handleClose();
    }
  };

  const renderInputFields = (
    <ImageList
      cols={1}
      cellHeight="auto"
      className={classes.gridList}
      style={{ margin: 'unset' }}
    >
      {inputFields.map((field) => (
        <ImageListItem key={field.id} className={classes.gridListTile}>
          {modalAction === 'Change' && (
            <InputField
              required
              id="password"
              type="password"
              label="Password"
              name="password"
              autoComplete="password"
              onChange={handleChange}
              value={values[field.id] || ''}
            />
          )}
          {modalAction === 'Assign' && (
            <FormGroup>
              <FormControl variant="outlined" className={classes.modalContentFormControl}>
                <Select
                  name="options"
                  value={dropdownOption}
                  disabled={isAccountRoleAdmin(role)}
                  onChange={handleOptionDropdownChange}
                  className={classes.modalContentDropdown}
                >
                  {dropdownOptions.map((option, index) => (
                    <MenuItem value={index} key={option.id}>{option.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {isAccountRoleRoot(role) && dropdownOption === 0 ? (
                <FormControl variant="outlined" className={classes.modalContentFormControl}>
                  <Select
                    name="franchise"
                    onChange={handleChange}
                    value={values.franchise || 0}
                    className={classes.modalContentDropdown}
                  >
                    {franchises.length !== 0
                      ? franchises.map((franchise, index) => (
                        <MenuItem value={index} key={franchise.id}>{franchise.name}</MenuItem>
                      ))
                      : <MenuItem value={0} key={0}>No franchises found</MenuItem>}
                  </Select>
                </FormControl>
              )
                : (
                  <FormControl variant="outlined" className={classes.modalContentFormControl}>
                    <Select
                      name="customer"
                      onChange={handleChange}
                      value={values.customer || 0}
                      className={classes.modalContentDropdown}
                      disabled={currentFranchiseCustomers.length === 0}
                    >
                      {currentFranchiseCustomers.length !== 0
                        ? currentFranchiseCustomers.map((customer, index) => (
                          <MenuItem value={index} key={customer.id}>{customer.name}</MenuItem>
                        ))
                        : <MenuItem value={0} key={0}>No customers found</MenuItem>}
                    </Select>
                  </FormControl>
                )}
            </FormGroup>
          )}
          {modalAction === 'Unassign' && (
            <FormGroup>
              <FormControl variant="outlined" className={classes.modalContentFormControl}>
                <Select
                  name="options"
                  value={dropdownOption}
                  disabled={isAccountRoleAdmin(role)}
                  onChange={handleOptionDropdownChange}
                  className={classes.modalContentDropdown}
                >
                  {dropdownOptions.map((option, index) => (
                    <MenuItem value={index} key={option.id}>{option.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </FormGroup>
          )}
          {(modalAction !== 'Change' && modalAction !== 'Assign' && modalAction !== 'Unassign') && (
            <InputField
              autoFocus
              name={field.id}
              label={field.label}
              onChange={handleChange}
              helperText={helperText}
              modalAction={modalAction}
              onKeyDown={handleKeyDown}
              value={values[field.id] || ''}
            />
          )}
        </ImageListItem>
      ))}
    </ImageList>
  );

  const renderModalButtons = (
    <ImageList style={{ margin: 'unset', justifyContent: 'flex-end' }}>
      <ImageListItem className={classes.gridListTile} style={{ width: 'unset', height: 'unset' }}>
        <Button
          variant="outlined"
          onClick={handleClose}
          className={classes.cancelButton}
        >
          Cancel
        </Button>
      </ImageListItem>
      <ImageListItem className={classes.gridListTile} style={{ width: 'unset', height: 'unset' }}>
        <Button
          name={modalAction}
          variant="contained"
          onClick={handleClick}
          disabled={isConfirmButtonDisabled()}
          className={`
            ${classes.confirmButton}
            ${isConfirmButtonDisabled() && classes.disabledButton}`}
        >
          { modalAction }
        </Button>
      </ImageListItem>
    </ImageList>
  );

  const renderShareSubfoldersToggle = (
    <FormControlLabel
      labelPlacement="start"
      label="Include subfolders"
      className={classes.subfoldersSwitchControl}
      control={(
        <Switch
          onChange={shareSubfolders}
          className={classes.subfoldersSwitch}
          checked={arePermissionsCascadedToSubfolders}
        />
      )}
    />
  );

  return (
    <>
      {renderInputFields}
      {((modalAction === 'Unassign' || modalAction === 'Assign')
        && !areAllTrackersUnassigned)
        && <CurrentlyAssignedList />}
      {renderModalButtons}
      {modalAction === 'Share' && <AuthorizedUsersList />}
      {modalAction === 'Share' && renderShareSubfoldersToggle}
    </>
  );
};

export default compose(
  withAuthData,
  withUserData,
  withFoldersData,
  withCustomerData,
  withFranchiseData,
  withCustomerActions,
  withFranchiseActions,
)(ModalContent);
