import {
  put,
  delay,
  takeLatest,
  putResolve,
  call,
  select,
} from 'redux-saga/effects';

import {
  getTracker,
  loadTracker,
  searchTrackers,
  updatingTracker,
  loadingTrackers,
  getTrackerImage,
  updateTrackerName,
  TrackerNameUpdated,
  getTrackerSettings,
  uploadTrackerImage,
  loadTrackerSettings,
  getFeaturedTrackers,
  updatingTrackerName,
  getPaginatedTrackers,
  loadFeaturedTrackers,
  trackerImageUploaded,
  cancelSearchTrackers,
  loadPaginatedTrackers,
  updateTrackerTimezone,
  uploadingTrackerImage,
  addTrackerToFavourites,
  currentTrackerLoading,
  updateTrackerSettings,
  updateTrackerRawSettings,
  loadingTrackerSettings,
  TrackerTimezoneUpdated,
  loadingFeaturedTrackers,
  updatingTrackerTimezone,
  updatingTrackerSettings,
  updatingTrackerRawSettings,
  addTrackerToFavouritesReq,
  getFilteredTrackerAction,
  storeFilteredTrackerAction,
  updateFilteredTrackerAction,
  removeTrackerFromFavourites,
  markTrackerAsRecentlyViewed,
  updateTrackerSettingsRequest,
  updateTrackerRawSettingsRequest,
  updateTrackerEquipmentNumber,
  TrackerEquipmentNumberUpdated,
  removeTrackerFromFavouritesReq,
  updatingTrackerEquipmentNumber,
  setLoadingFilteredLocationTrackerAction,

  registerTrackerEvent,
  sendPeriodicDummyLocations,
  updateSendingPeriodicDummyLocations,
  getLocationEventAddress,
} from '../Actions/TrackerActions';
import {
  loadingEventListAction,
  getAllEventsTrackerAction,
  storeAllEventsTrackerAction,
} from '../Actions/EventActions';

import { authApiHandler } from './auth';
import UserAPI from '../../Api/Endpoints_OLD/user';
import TrackersAPI from '../../Api/Endpoints_OLD/tracker';
import { addNotification } from '../Actions/system';
import { ResponseTypes } from '../../Api/Responses/baseResponse';
import { locationTypes } from '../../Views/Trackers/Tabs/SettingsTab/trackerSettingsHelpers';

export function* getFilteredTracker({ payload }) {
  const connection = {
    gsm: payload.gsm,
    gps: payload.gps,
    wifi: payload.wifi,
  };
  yield putResolve(setLoadingFilteredLocationTrackerAction(true));

  const trackerInfo = yield authApiHandler(TrackersAPI.getTrackerLocations,
    payload.simiccid, connection,
    payload.startTime, payload.endTime,
    payload.limit, payload.offset);

  if (ResponseTypes.ActionCompleted === trackerInfo.type) {
    yield putResolve(storeFilteredTrackerAction(trackerInfo.data));
  }
  yield putResolve(setLoadingFilteredLocationTrackerAction(false));
}

export function* getAllTrackerLog({ payload }) {
  yield putResolve(loadingEventListAction(true));

  const trackerInfo = yield authApiHandler(TrackersAPI.getAllTrackerEvents,
    payload.simiccid, payload.startTime, payload.endTime, payload.limit, payload.offset);

  if (ResponseTypes.ActionCompleted === trackerInfo.type) {
    yield put(storeAllEventsTrackerAction(trackerInfo.data));
    return trackerInfo.data;
  }
  return false;
}

export function* handleGetTrackerImage(trackerId) {
  const response = yield authApiHandler(TrackersAPI.getTracker, trackerId);
  if (response.type === ResponseTypes.ActionCompleted) {
    const { image } = response.data;
    return image;
  } if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not retrieve tracker image',
      source: 'handleGetTrackerImage',
      message: 'Please, check your connection and try again.',
    }));
    return false;
  }
  return false;
}

export function* handleUploadTrackerImage({ payload: { trackerId, imageFile } }) {
  if (imageFile.size > 10485760) {
    yield put(addNotification({
      type: 'error',
      title: 'Image too large',
      source: 'handleUploadTrackerImage',
      message: 'File size limit is 10MB',
    }));
    return;
  }
  yield put(uploadingTrackerImage());
  const response = yield authApiHandler(TrackersAPI.uploadTrackerImage, trackerId, imageFile);
  if (response.type === ResponseTypes.ActionCompleted) {
    // Delay the redux store update to wait for AWS "S3"
    // to update the profile image resource behind the thumbnailUrl
    // Alternatively, fix the backend [JIRA-299]
    yield delay(1000);
    const updatedTrackerImages = yield call(handleGetTrackerImage, trackerId);
    if (updatedTrackerImages) {
      yield put(trackerImageUploaded({ updatedTrackerImages }));
    } else {
      yield put(trackerImageUploaded());
    }
  } if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not upload image',
      source: 'handleUploadTrackerImage',
      message: 'Please, check your connection and try again.',
    }));
  }
}

export function* handleGetFeaturedTrackers() {
  yield put(loadingFeaturedTrackers());
  const response = yield authApiHandler(UserAPI.getFeaturedTrackers);
  if (response.type === ResponseTypes.ActionCompleted) {
    yield put(loadFeaturedTrackers({
      favoriteTrackers: response.data.favourites,
      lastViewedTracker: response.data.lastUsed,
      recentlyViewedTrackers: response.data.recents,
    }));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      source: 'handleGetFeaturedTrackers',
      title: 'Could not retrieve trackers',
      message: 'Please, check your connection and try again.',
    }));
  }
}

export function* handleGetPaginatedTrackers({ payload: searchOptions }) {
  yield put(loadingTrackers());
  yield put(getFeaturedTrackers());
  let response = {};
  if (searchOptions) {
    // Get trackers with custom searchOptions
    response = yield authApiHandler(TrackersAPI.getPaginatedTrackers, searchOptions);
  } else {
    // Get trackers with default searchOptions
    response = yield authApiHandler(TrackersAPI.getPaginatedTrackers);
  }

  if (response.type === ResponseTypes.ActionCompleted) {
    const trackers = response.data.data.map((tracker) => ({
      ...tracker,
      updating: false,
    }));
    yield put(loadPaginatedTrackers({ trackers, totalNumberOfTrackers: response.data.totalCount }));
  } else if (response.type === ResponseTypes.Forbidden) {
    yield put(loadPaginatedTrackers({ trackers: [], totalNumberOfTrackers: 0 }));
    yield put(addNotification({
      type: 'error',
      source: 'handleGetPaginatedTrackers',
      title: 'Could not retrieve trackers',
      message: 'Your account permissions do not allow us to retrieve this content for you.',
    }));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      source: 'handleGetPaginatedTrackers',
      title: 'Could not retrieve trackers',
      message: 'Please, check your connection and try again.',
    }));
  }
}

export function* handleGetTracker({ payload: { trackerId } }) {
  yield put(currentTrackerLoading());
  const response = yield authApiHandler(TrackersAPI.getTracker, trackerId);
  if (response.type === ResponseTypes.ActionCompleted) {
    const tracker = response.data;
    yield put(loadTracker({
      ...tracker,
      // currentSettings: {
      //   calendarEvents: [],
      //   timezone: 'Europe/London',
      // },
      // newSettings: {
      //   calendarEvents: [],
      //   timezone: 'Europe/London',
      // },
      image: tracker.image ? tracker.image : '',
    }));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not retrieve tracker',
      source: 'handleGetTracker',
      message: 'Please, check your connection and try again.',
    }));
  }
}

export function* handleSearchTrackers({ payload: { searchFilters } }) {
  yield put(loadingTrackers());
  const searchOptions = {
    filters: {
      filters: Object.entries(searchFilters).map((searchFilter) => ({
        name: searchFilter[0],
        not: false,
        value: searchFilter[1],
      })),
      sorters: [{
        dir: 'asc',
        name: 'name',
      }],
    },
  };
  const response = yield authApiHandler(TrackersAPI.getPaginatedTrackers, searchOptions);
  if (response.type === ResponseTypes.ActionCompleted) {
    const trackers = response.data.data.map((tracker) => ({
      ...tracker,
      updating: false,
    }));
    yield put(loadPaginatedTrackers({ trackers, totalNumberOfTrackers: response.data.totalCount }));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(cancelSearchTrackers());
    yield put(addNotification({
      type: 'error',
      source: 'handleSearchTrackers',
      title: 'Could not search trackers',
      message: 'Please check your connection and try again.',
    }));
  }
}

export function* handleAddTrackerToFavoritesReq({ payload: tracker }) {
  yield put(updatingTracker(tracker.simiccid));
  const response = yield authApiHandler(UserAPI.addTrackerToFavourites, tracker.simiccid);
  if (response.type === ResponseTypes.ActionCompleted) {
    yield put(addTrackerToFavourites(tracker));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not add tracker to favorites',
      source: 'handleAddTrackerToFavoritesReq',
      message: 'Please, check your connection and try again.',
    }));
  }
}

export function* handleRemoveTrackerFromFavoritesReq({ payload: trackerId }) {
  yield put(updatingTracker(trackerId));
  const response = yield authApiHandler(UserAPI.removeTrackerFromFavourites, trackerId);
  if (response.type === ResponseTypes.ActionCompleted) {
    yield put(removeTrackerFromFavourites(trackerId));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not remove tracker from favorites',
      source: 'handleRemoveTrackerFromFavoritesReq',
      message: 'Please, check your connection and try again.',
    }));
  }
}

export function* handleMarkTrackerAsRecentlyViewed({ payload: trackerId }) {
  yield authApiHandler(UserAPI.addTrackerToRecentlyViewed, trackerId);
}

export function* handleUpdateTrackerName({ payload: { trackerName } }) {
  yield put(updatingTrackerName());
  const trackerId = yield select((state) => state.TrackerData.currentTracker.simiccid);
  const equipmentNumber = yield select((state) => state.TrackerData.currentTracker.equipmentNumber);
  const response = yield authApiHandler(TrackersAPI.updateTrackerDetails,
    trackerId, trackerName, equipmentNumber);
  if (response.type === ResponseTypes.ActionCompleted) {
    const newName = response.data.name;
    yield put(TrackerNameUpdated({ newName, trackerId }));
  } else if (response.type !== ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not update tracker',
      source: 'handleUpdateTracker',
      message: 'Please, check your connection and try again.',
    }));
  }
}

export function* handleUpdateTrackerEquipmentNumber({ payload: { equipmentNumber, tracker } }) {
  yield put(updatingTrackerEquipmentNumber());
  const response = yield authApiHandler(TrackersAPI.updateTrackerDetails,
    tracker.id, tracker.name, equipmentNumber);
  if (response.type === ResponseTypes.ActionCompleted) {
    const newEquipmentNumber = response.data.equipmentNumber;
    yield put(TrackerEquipmentNumberUpdated({ newEquipmentNumber, trackerId: tracker.id }));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not update tracker',
      source: 'handleUpdateTrackerEquipmentNumber',
      message: 'Please, check your connection and try again.',
    }));
  }
}

export function* handleUpdateTrackerTimezone({ payload: { timezone, tracker } }) {
  yield put(updatingTrackerTimezone());
  const response = yield authApiHandler(
    TrackersAPI.updateTrackerDetails,
    tracker.simiccid,
    tracker.name,
    tracker.equipmentNumber,
    timezone,
  );
  if (response.type === ResponseTypes.ActionCompleted) {
    const newTimezone = response.data.timezone;
    yield put(TrackerTimezoneUpdated({ newTimezone, trackerId: tracker.id }));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not update tracker',
      source: 'handleUpdateTrackerTimezone',
      message: 'Please, check your connection and try again.',
    }));
  }
}

export function* handleUpdateTrackerSettingsRequest({ payload: { trackerId, newSettings } }) {
  yield put(updatingTrackerSettings());
  const response = yield authApiHandler(TrackersAPI.updateTrackerSettings, trackerId, newSettings);

  if (response.type === ResponseTypes.ActionCompleted) {
    const updatedSettings = response.data.newSettings;
    const isCalendarMode = updatedSettings.heartbeatType === 'CAL';
    yield put(updateTrackerSettings({
      newSettings: {
        debug: updatedSettings.debug,
        mode: updatedSettings.heartbeatType,
        interval: updatedSettings.interval ?? 0,
        forceUpdate: updatedSettings.forceUpdate,
        timezone: updatedSettings.schedule?.timezone || 'Europe/London',
        calendarEvents: isCalendarMode
          ? updatedSettings.schedule.schedule.map((event) => ({
            time: event.time,
            locationType: event.update,
            essential: event.essential,
            day: event.dayOfWeek[0] + event.dayOfWeek.slice(1).toLowerCase(),
            id: `${event.dayOfWeek}-${event.time}-${event.update}-${event.essential}`,
            backgroundColor: locationTypes
              .find((type) => type.id === event.update).backgroundColor,
          }))
          : [],
      },
    }));
    yield put(addNotification({
      type: 'success',
      title: 'Tracker settings successfully updated!',
      source: 'handleUpdateTrackerSettingsRequest',
      message: 'Your new tracker settings have been updated. They will be received by the tracker during its next wake up time.',
    }));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not update settings',
      source: 'handleUpdateTrackerSettingsRequest',
      message: 'Please, check your connection and try again.',
    }));
  }
}

export function* handleUpdateTrackerRawSettingsRequest({ payload: { trackerId, rawSettings } }) {
  yield put(updatingTrackerRawSettings());
  const response = yield authApiHandler(TrackersAPI.updateTrackerRawSettings, trackerId, rawSettings);

  yield put(updateTrackerRawSettings());
  if (response.type === ResponseTypes.ActionCompleted) {
    yield put(addNotification({
      type: 'success',
      title: 'Tracker raw settings successfully updated!',
      source: 'handleUpdateTrackerRawSettingsRequest',
      message: 'Your new tracker raw settings have been updated. They will be received by the tracker during its next wake up time.',
    }));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not update settings',
      source: 'handleUpdateTrackerRawSettingsRequest',
      message: 'Please, check your connection and try again.',
    }));
  } else if (response.type === ResponseTypes.BadRequest) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not update settings',
      source: 'handleUpdateTrackerRawSettingsRequest',
      message: response.data.description,
    }));
  }
}

export function* handleGetTrackerSettings({ payload: { trackerId } }) {
  yield put(loadingTrackerSettings());
  const response = yield authApiHandler(TrackersAPI.getTracker, trackerId);
  if (response.type === ResponseTypes.ActionCompleted) {
    const { currentSettings, newSettings } = response.data;
    const isNewSettingCalendarMode = newSettings.heartbeatType === 'CAL';
    const isCurentSettingCalendarMode = currentSettings.heartbeatType === 'CAL';
    yield put(loadTrackerSettings({
      currentSettings: {
        debug: currentSettings.debug,
        mode: currentSettings.heartbeatType,
        interval: currentSettings.interval ?? 0,
        forceUpdate: currentSettings.forceUpdate,
        timezone: currentSettings.schedule?.timezone || 'Europe/London',
        calendarEvents: isCurentSettingCalendarMode
          ? currentSettings.schedule?.schedule.map((event) => ({
            time: event.time,
            locationType: event.update,
            essential: event.essential,
            day: event.dayOfWeek[0] + event.dayOfWeek.slice(1).toLowerCase(),
            id: `${event.dayOfWeek}-${event.time}-${event.update}-${event.essential}`,
            backgroundColor: locationTypes
              .find((type) => type.id === event.update).backgroundColor,
          }))
          : [],
      },
      newSettings: {
        debug: newSettings.debug,
        mode: newSettings.heartbeatType,
        interval: newSettings.interval ?? 0,
        forceUpdate: newSettings.forceUpdate,
        timezone: newSettings.schedule?.timezone || 'Europe/London',
        calendarEvents: isNewSettingCalendarMode
          ? newSettings.schedule.schedule.map((event) => ({
            time: event.time,
            locationType: event.update,
            essential: event.essential,
            day: event.dayOfWeek[0] + event.dayOfWeek.slice(1).toLowerCase(),
            id: `${event.dayOfWeek}-${event.time}-${event.update}-${event.essential}`,
            backgroundColor: locationTypes
              .find((type) => type.id === event.update).backgroundColor,
          }))
          : [],
      },
    }));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      source: 'handleGetTrackerSettings',
      title: 'Could not retrieve tracker settings',
      message: 'Please, check your connection and try again.',
    }));
  }
}

export function* handleRegisterTrackerEvent({ payload: { simiccid, event } }) {
  const response = yield authApiHandler(TrackersAPI.registerTrackerEvent, simiccid, event);
  // if (response.type === ResponseTypes.ActionCompleted) {
    
  // }
  if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not upload image',
      source: 'handleRegisterTrackerEvent',
      message: 'Please, check your connection and try again.',
    }));
  }
}

export function* handleSendPeriodicDummyLocations({ payload: isEnabled}) {
  yield put(updateSendingPeriodicDummyLocations(isEnabled))
}

// export function* getFilteredTracker({ payload }) {
//   const connection = {
//     gsm: payload.gsm,
//     gps: payload.gps,
//     wifi: payload.wifi,
//   };
//   yield putResolve(setLoadingFilteredLocationTrackerAction(true));

//   const trackerInfo = yield authApiHandler(TrackersAPI.getTrackerLocations,
//     payload.simiccid, connection,
//     payload.startTime, payload.endTime,
//     payload.limit, payload.offset);

//   if (ResponseTypes.ActionCompleted === trackerInfo.type) {
//     yield putResolve(storeFilteredTrackerAction(trackerInfo.data));
//   }
//   yield putResolve(setLoadingFilteredLocationTrackerAction(false));
// }

export function* handleGetLocationEventAddress({ payload: eventUuid }) {
  yield putResolve(setLoadingFilteredLocationTrackerAction(true));

  const response = yield authApiHandler(TrackersAPI.getLocationEventAddress, eventUuid);

  if (ResponseTypes.ActionCompleted === response.type) {
    console.log(response.data)
    yield putResolve(updateFilteredTrackerAction(response.data));
  }

  yield putResolve(setLoadingFilteredLocationTrackerAction(false));
}

export default function* TrackerSagas() {
  yield takeLatest(`${getFilteredTrackerAction}`, getFilteredTracker);
  yield takeLatest(`${getAllEventsTrackerAction}`, getAllTrackerLog);
  yield takeLatest(getTracker, handleGetTracker);
  yield takeLatest(searchTrackers, handleSearchTrackers);
  yield takeLatest(getTrackerImage, handleGetTrackerImage);
  yield takeLatest(updateTrackerName, handleUpdateTrackerName);
  yield takeLatest(getTrackerSettings, handleGetTrackerSettings);
  yield takeLatest(uploadTrackerImage, handleUploadTrackerImage);
  yield takeLatest(getFeaturedTrackers, handleGetFeaturedTrackers);
  yield takeLatest(getPaginatedTrackers, handleGetPaginatedTrackers);
  yield takeLatest(updateTrackerTimezone, handleUpdateTrackerTimezone);
  yield takeLatest(addTrackerToFavouritesReq, handleAddTrackerToFavoritesReq);
  yield takeLatest(markTrackerAsRecentlyViewed, handleMarkTrackerAsRecentlyViewed);
  yield takeLatest(updateTrackerEquipmentNumber, handleUpdateTrackerEquipmentNumber);
  yield takeLatest(updateTrackerSettingsRequest, handleUpdateTrackerSettingsRequest);
  yield takeLatest(updateTrackerRawSettingsRequest, handleUpdateTrackerRawSettingsRequest);
  yield takeLatest(removeTrackerFromFavouritesReq, handleRemoveTrackerFromFavoritesReq);

  yield takeLatest(registerTrackerEvent, handleRegisterTrackerEvent);
  yield takeLatest(sendPeriodicDummyLocations, handleSendPeriodicDummyLocations);
  yield takeLatest(getLocationEventAddress, handleGetLocationEventAddress);
}
