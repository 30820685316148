import { handleActions } from 'redux-actions';

import { logout } from '../Actions/auth';
import {
  storeLatestEvents,
  setLatestEventsLoading,

  setNotesFilter,
  setNotesLoading,
  storeNewNote,
  storeNotes,
  updateNote,

  setAuditLogsFilter,
  setAuditLogsLoading,
  setAuditDetailsLoading,
  storeAuditLogs,
  storeAuditDetails,

  setCommsLogsFilter,
  setCommsLogsLoading,
  storeCommsLogs,

  setNotificationLogsFilter,
  setNotificationLogsLoading,
  storeNotificationLogs,

  setAutoUpdateEvents,
  storeNoteWS,
  storeAuditLogWS,
  storeCommsLogWS,
  storeNotificationLogWS,
} from '../Actions/event';

import { matchesEventFilter } from '../../Utils/Events';
import { AUDIT_DETAILS_KEY } from '../../Utils/Events';

const DEFAULT_FILTER = {
  limit: 50,
  page: 0,
  startTime: undefined,
  endTime: undefined,
};

const defaultState = {
  latestEvents: {
    note: undefined,
    audit: undefined,
    comms: undefined,
    notification: undefined,
  },
  areLatestEventsLoading: false,

  notes: [],
  totalNumOfNotes: 0,
  notesFilter: { ...DEFAULT_FILTER, limit: 200 },
  areNotesLoading: false,

  auditLogs: [],
  totalNumOfAuditLogs: 0,
  auditLogFilter: { ...DEFAULT_FILTER },
  areAuditLogsLoading: false,
  areAuditDetailsLoading: false,

  commsLogs: [],
  totalNumOfCommsLogs: 0,
  commsLogFilter: { ...DEFAULT_FILTER },
  areCommsLogsLoading: false,

  notificationLogs: [],
  totalNumOfNotificationLogs: 0,
  notificationLogFilter: { ...DEFAULT_FILTER },
  areNotificationLogsLoading: false,

  autoUpdateEvents: true,
};

function addNewEvent(state, event, eventListID, eventFilterID, latestEventID) {
  let newEventsList = [ ...state[eventListID] ];

  if (matchesEventFilter({ event: event, filter: state[eventFilterID] })) {
    newEventsList.unshift(event);
    
    // Remove events from the end of the array until it reaches the filtered limit
    if (state[eventFilterID]?.limit > 0) {
      while (newEventsList.length > state[eventFilterID].limit) {
        newEventsList.pop();
      }
    }
  }

  return {
    ...state,
    [eventListID]: newEventsList,
    [latestEventID]: event,
  }
}

export default handleActions({
  [logout]: () => defaultState,

  // Latest Events
  [setLatestEventsLoading]:  (state, { payload: isLoading })   => ({ ...state, isLatestAuditLogLoading: isLoading }),
  [storeLatestEvents]: (state, { payload: { note, audit, comms, notification } }) => ({
    ...state,
    latestEvents: {
      ...state.latestEvents,
      note,
      audit,
      comms,
      notification,
    },
  }),
  
  // Notes
  [setNotesFilter]:             (state, { payload: filter })      => ({ ...state, notesFilter: filter }),
  [setNotesLoading]:            (state, { payload: isLoading })   => ({ ...state, areNotesLoading: isLoading }),
  [storeNewNote]:               (state, { payload: note })        => ({ ...state, notes: [ note, ...state.notes ] }),
  [storeNotes]:                 (state, { payload: notes })       => ({ ...state, notes: notes }),
  [updateNote]: (state, { payload: note }) => {
    let updatedNotes = [ ...state.notes ];

    const idx = updatedNotes.findIndex(event => event.id === note.id);

    if (idx > -1) {
      updatedNotes[idx] = note;
    }

    return { ...state, notes: updatedNotes };
  },
  
  // Audit Logs
  [setAuditLogsFilter]:         (state, { payload: filter })      => ({ ...state, auditLogFilter: filter }),
  [setAuditLogsLoading]:        (state, { payload: isLoading })   => ({ ...state, areAuditLogsLoading: isLoading }),
  [setAuditDetailsLoading]:     (state, { payload: isLoading })   => ({ ...state, areAuditDetailsLoading: isLoading }),
  [storeAuditLogs]:             (state, { payload: events })      => ({ ...state, auditLogs: events }),
  [storeAuditDetails]:          (state, { payload: { eventId, details } }) => {
    let newState = { ...state };

    // If this event is the latest audit, update its 'details' data
    if (newState.latestEvents.audit.id === eventId) {
      newState.latestEvents = {
        ...state.latestEvents,
        audit: {
          ...state.latestEvents.audit,
          [AUDIT_DETAILS_KEY]: details,
        }
      }
    }

    let idx = state.auditLogs.findIndex(event => event.id === eventId);

    // If this event is in the list of audits, update its 'details' data
    if (idx >= 0) {
      let events = [ ...newState.auditLogs ];

      events[idx] = {
        ...state.auditLogs[idx],
        [AUDIT_DETAILS_KEY]: details,
      }

      newState.auditLogs = events;
    }

    return newState;
  },
  
  // Comms Logs
  [setCommsLogsFilter]:         (state, { payload: filter })      => ({ ...state, commsLogFilter: filter }),
  [setCommsLogsLoading]:        (state, { payload: isLoading })   => ({ ...state, areCommsLogsLoading: isLoading }),
  [storeCommsLogs]:             (state, { payload: events })      => ({ ...state, commsLogs: events }),

  // Notification Logs
  [setNotificationLogsFilter]:  (state, { payload: filter })      => ({ ...state, notificationLogFilter: filter }),
  [setNotificationLogsLoading]: (state, { payload: isLoading })   => ({ ...state, areNotificationLogsLoading: isLoading }),
  [storeNotificationLogs]:      (state, { payload: events })      => ({ ...state, notificationLogs: events }),
  
  // WebSocket
  [setAutoUpdateEvents]: (state, { payload: autoUpdate }) => ({ ...state, autoUpdateEvents: autoUpdate }),

  [storeNoteWS]:                (state, { payload: event })       => addNewEvent(state, event, 'notes', 'notesFilter', 'latestNote'),
  [storeAuditLogWS]:            (state, { payload: event })       => addNewEvent(state, event, 'auditLogs', 'auditLogFilter', 'latestAuditLog'),
  [storeCommsLogWS]:            (state, { payload: event })       => addNewEvent(state, event, 'commsLogs', 'commsLogFilter', 'latestCommsLog'),
  [storeNotificationLogWS]:     (state, { payload: event })       => addNewEvent(state, event, 'notificationLogs', 'notificationLogFilter', 'latestNotificationLog'),
}, defaultState);