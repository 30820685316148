import React from 'react';
import { compose } from 'redux';

import makeStyles from '@mui/styles/makeStyles';

import { mobile } from '../../Styles/styles';
import NewModal from '../Components/NewModal/NewModal';
import CustomersList from './CustomersList/CustomersList';
import { isAccountRoleAdmin, isAccountRoleRoot } from '../../Utils/Utils';
import addNewCustomerModalContent from './AddNewCustomerModal/AddNewCustomerModal';

import withUserData from '../../redux/WithData/user';

const customersManagerStyles = makeStyles((theme) => ({
  customersManagerContainer: {
    flexDirection: 'column',
    boxSizing: 'border-box',
    alignItems: 'center',
    padding: '24px',
    display: 'flex',
    height: '100%',
    [theme.breakpoints.down(mobile)]: {
      padding: '0px',
    },
  },
}));

const CustomersManager = ({ role }) => {
  const classes = customersManagerStyles();

  return (
    <div className={classes.customersManagerContainer}>
      {(isAccountRoleRoot(role) || isAccountRoleAdmin(role)) && (
      <NewModal
        modalHeaderName="Add New Customer"
        modalButtonName="Add New Customer"
        ModalContentComponent={addNewCustomerModalContent}
      />
      )}
      <CustomersList />
    </div>
  );
};

export default compose(withUserData)(CustomersManager);
