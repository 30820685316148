import React from 'react';

import {
  Box,
} from '@mui/material';

const BORDER_WIDTH = '3px';

const StateLabel = ({
  state,
  sx,
}) => {
  return (
    <Box title={state.description} sx={theme => ({
      p: '0 8px',
      backgroundColor: theme.palette.action.hover,
      borderBottomWidth: BORDER_WIDTH,
      borderBottomColor: theme.palette.states[state.id].dark,
      borderBottomStyle: 'solid',
      ...sx,
    })}>
      {state?.label || '???'}
    </Box>
  );
}

export default StateLabel;