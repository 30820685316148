import Cookies from 'universal-cookie';

import {
  put,
  call,
  select,
  takeLatest,
  putResolve,
} from 'redux-saga/effects';

import {
  login,
  logout,
  resetPassword,
  registerUser,
  setLoggedIn,
  setLoggingIn,
  setRegistering,

  updateTokens,
} from '../Actions/auth';

import { addNotification } from '../Actions/system';   // TODO: replace with updated version
import { getUserDetails } from '../Actions/user';

import AuthAPI from '../../Api/Endpoints/auth';
import { ResponseTypes } from '../../Api/Responses/baseResponse';
import { AuthResponseTypes } from '../../Api/Responses/authResponse';
import ResponseCodes from '../../Api/Responses/responseCodes';

export function* authApiHandler(endpoint, ...args) {
  let authResponse = yield call(endpoint, ...args);

  if (authResponse.type === AuthResponseTypes.Unauthorized) {
    // Refresh accessToken if it is expired
    const refreshResponse = yield call(AuthAPI.refreshAccess);

    if (refreshResponse.type === AuthResponseTypes.AcceptedLogin) {
      const email = yield select((state) => state.AuthData_NEW.email);
      yield putResolve(updateTokens({ ...refreshResponse.data, email }));
    }

    // Logout user if refreshToken is expired
    if (refreshResponse.type === AuthResponseTypes.RejectedLogin) {
      // TODO: store current URL/page to redirect to after logging in again

      yield put(logout());
      yield put(addNotification({
        type: 'error',
        title: 'Your session has expired',
        source: 'authApiHandler',
        message: 'Please sign in again.',
      }));
    }
    // Otherwise re-try the endpoint
    else {
      authResponse = yield call(endpoint, ...args);
    }

    // NOTE: why try it again after logging out? - Moved to inside the else block
    // Re-try the endpoint
    // authResponse = yield call(endpoint, ...args);
  }

  return authResponse;
}

export function* handleLogin({ payload: { email, password } }) {
  yield put(setLoggingIn(true));

  const response = yield call(AuthAPI.login, email, password);

  if (response.type === AuthResponseTypes.AcceptedLogin) {
    yield putResolve(updateTokens({ ...response.data, email }));
    yield putResolve(getUserDetails());
    yield put(setLoggedIn(true));
  } else if (response.data.code === ResponseCodes.EmailUnverified) {
    yield put(addNotification({
      type: 'error',
      source: 'handleLoginRequest',
      title: 'Unverified email',
      message: 'Please go to your inbox and click on the verification link we have sent. Once that is done, you will be able to sign in.',
    }));
  } else if (response.data.code === ResponseCodes.InvalidCredentials) {
    yield put(addNotification({
      type: 'error',
      source: 'handleLoginRequest',
      title: 'Invalid credentials',
      message: 'If you are registered, please make sure your credentials are correctly typed. If you are not registered yet, click on the sign up tab to register. If you forgot your password, click on the forgot password link below to reset it.',
    }));
  } else if (response.type === AuthResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      source: 'handleLoginRequest',
      title: 'Could not sign in',
      message: 'Please, check your connection and try again.',
    }));
  }

  yield put(setLoggingIn(false));
}

export function* handleLogout() {
  yield putResolve(updateTokens(null));
  yield put(setLoggedIn(false));
}

export function* handleResetPassword({ payload: email }) {
  const response = yield AuthAPI.resetPassword(email);

  // TODO: this is BAD security - it confirms whether an email account is registered with us!
  if (response.type === ResponseTypes.ActionCompleted) {
    yield put(addNotification({
      type: 'success',
      source: 'handleResetPassword',
      title: 'Password successfully reset!',
      message: "You'll receive a confirmation email to complete the process.",
    }));
  } else if (response.type === ResponseTypes.NotFound) {
    yield put(addNotification({
      type: 'error',
      source: 'handleResetPassword',
      title: 'Email not registered!',
      message: 'Please check if you typed your email correctly or try with another email.',
    }));
  } else if (response.type === ResponseTypes.ClientError) {
    yield put(addNotification({
      type: 'error',
      title: 'Email not verified!',
      source: 'handleResetPassword',
      message: 'Please check your inbox and verify your email before resetting the password.',
    }));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      source: 'handleResetPassword',
      title: 'Could not reset password',
      message: 'Please, check your connection and try again.',
    }));
  }
}

export function* handleRegisterUser({ payload: { credentials, history } }) {
  yield put(setRegistering(true));

  const response = yield call(AuthAPI.register, credentials);

  if (response.type === AuthResponseTypes.AcceptedRegister) {
    yield history.push('/login');

    yield put(addNotification({
      type: 'success',
      source: 'handleRegisterRequest',
      title: 'You have successfully signed up!',
      message: "You'll receive a confirmation email to complete the process",
    }));
  } else if (response.data.code === ResponseCodes.InvalidSecretCode) {
    yield put(addNotification({
      type: 'error',
      source: 'handleRegisterRequest',
      title: 'Invalid Activation Code',
      message: 'The activation code you have typed is invalid. If you have forgotten your activation code, contact your Automatrics account manager to receive a new one.',
    }));
  } else if (response.data.code === ResponseCodes.EmailAlreadyRegistered) {
    yield put(addNotification({
      type: 'error',
      source: 'handleRegisterRequest',
      title: 'Email already registered',
      message: 'This email address has already been used to register an account. If you want to register a new account please use a different email. If you want to sign in, but forgot your password, simply switch to the sign in tab and click on the forgot password link.',
    }));
  } else if (response.type === AuthResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not sign up',
      source: 'handleRegisterRequest',
      message: 'Please, check your connection and try again.',
    }));
  }

  yield put(setRegistering(false));
}

export default function* AuthSagas() {
  yield takeLatest(login, handleLogin);
  yield takeLatest(logout, handleLogout);
  yield takeLatest(resetPassword, handleResetPassword);
  yield takeLatest(registerUser, handleRegisterUser);
}
