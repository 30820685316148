import React from 'react';
import { compose } from 'redux';

import {
  Box,
  Divider,
} from '@mui/material';

import PaperSection from '../Components/PaperSection';
import VirtualisedTable, { FONT_SIZE_S, FONT_SIZE_M } from '../Components/VirtualisedTable/VirtualisedTable';
import CustomPagination from '../Components/VirtualisedTable/CustomPagination';
import ResultsCounter from '../Components/VirtualisedTable/ResultsCounter';

import renderRow from './renderRow';
import { getColumns } from './columns';
import { useWindowDimensions } from '../../Utils/ScreenSize';
import { SORT_ORDERS } from '../../Utils/Sorting';
import { getFilters, ALL_FILTERS } from './filters';
import { STATES } from '../../Utils/States';
import { MODES } from '../../Utils/Modes';

import withSystemActions from '../../redux/WithActions/system';
import withTrackerData from '../../redux/WithData/tracker';
import withTrackerActions from '../../redux/WithActions/tracker';
import withUserData from '../../redux/WithData/user';
import FilterHeader from '../Components/Filter/FilterHeader';

const ROWS_PER_PAGE_OPTIONS = [50, 200, 1000];

const DEFAULT_SEARCH_OPTIONS = {
  page: 0,
  limit: 50,
  filters: [],
  sortBy: {
    column: 'state',
    dir: SORT_ORDERS.asc.key,
  },
};

const FALLBACK_SORTER = {
  column: 'lastPositionTimestamp',
  dir: SORT_ORDERS.asc.key,
};

const DEFAULT_FILTER_VALUES = {
  includeStock: true,
  includeOff: true,
};

const UnitSearch = ({
  role,
  trackers,
  areTrackersLoading,
  totalNumOfTrackers,
  getTrackers,
  setShowSubHeader,
}) => {
  // Set the browser tab's title
  document.title = 'Unit Search - MTrack';

  const windowDimensions = useWindowDimensions();
  const { isDesktopWidth } = windowDimensions;

  const TABLE_FONT_SIZE = isDesktopWidth ? FONT_SIZE_M : FONT_SIZE_S;

  const [hasStartedLoading, setStartedLoading] = React.useState(false);
  const [columns, setColumns] = React.useState([]);
  
  const [searchOptions, setSearchOptions] = React.useState(DEFAULT_SEARCH_OPTIONS);
  
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [filterToggles, setFilterToggles] = React.useState([]);
  const [filterValues, setFilterValues] = React.useState(DEFAULT_FILTER_VALUES);

  // Clear 'showSubHeader' so the main header renders correctly
  React.useEffect(() => { setShowSubHeader(false); }, [ setShowSubHeader ]);

  // Set the columns depending on the window dimensions & the user's role
  React.useEffect(() => {
    setColumns(getColumns(windowDimensions, role));
  }, [
    role,
    windowDimensions,
  ]);

  // Get the filter options available for the current user
  React.useEffect(() => {
    let { filters, toggles } = getFilters(role);

    setFilterOptions(filters);
    setFilterToggles(toggles);
  }, [
    role,
  ]);
  
  // Load tracker data
  React.useEffect(() => {
    getTrackers({
      offset: searchOptions.limit * searchOptions.page,
      limit: searchOptions.limit,
      filters: searchOptions.filters,
      sorters: [ searchOptions.sortBy, FALLBACK_SORTER ],
    });

    setStartedLoading(true);
  }, [
    searchOptions,
    getTrackers,
    setStartedLoading,
  ]);
  
  // TODO: Use totalNumOfTrackers once API supports it (returns -1 currently)
  const totalCount = trackers.length;

  const rowCount = searchOptions.page + 1 < Math.ceil(totalCount / searchOptions.limit)
    ? searchOptions.limit
    : ((totalCount - 1) % searchOptions.limit) + 1;

  function convertFilterValuesToList(filters = {}) {
    // console.log(filters);

    let filterList = [];

    // Stock mode & Off state units are excluded by default
    if (!filters[ALL_FILTERS.includeStock.id]) {
      filterList.push({ name: ALL_FILTERS.mode.id, value: MODES.STOCK.id, not: true });
    }
    
    if (!filters[ALL_FILTERS.includeOff.id]) {
      filterList.push({ name: ALL_FILTERS.state.id, value: STATES.OFF.id, not: true });
    }

    for (const [key, value] of Object.entries(filters)) {
      if (value) {
        if (Array.isArray(value) && value.length > 0) {
          // TODO: handle array filters (multi-select inputs)
        }
        else if (typeof value === 'string' && value) {
          // Handle other (string) filters
          filterList.push({
            name: key,
            value: value,
          });
        }
      }
    }

    // console.log(filterList);
    return filterList;
  }
  
  function handleFilter(useDefaultFilter = false) {
    setSearchOptions({
      ...searchOptions,
      filters: convertFilterValuesToList(useDefaultFilter ? DEFAULT_FILTER_VALUES : filterValues),
    });
  }

  const handleClickColumnHeader = ({ columnIndex }) => {
    // Already sorting by this column -> toggle direction
    if (searchOptions.sortBy.column === columns[columnIndex].dataKey) {
      setSearchOptions({
        ...searchOptions,
        sortBy: {
          ...searchOptions.sortBy,
          dir: (searchOptions.sortBy.dir === SORT_ORDERS.asc.key) ? SORT_ORDERS.desc.key : SORT_ORDERS.asc.key
        }
      });
    }
    // Otherwise switch to this column
    else {
      setSearchOptions({
        ...searchOptions,
        sortBy: {
          column: columns[columnIndex].dataKey,
          dir: SORT_ORDERS.asc.key
        }
      });
    }
    
    // TODO: Update data
  }
  
  const handlePageChange = (newPage) => {
    setSearchOptions({
      ...searchOptions,
      page: newPage
    });
  }

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setSearchOptions({
      ...searchOptions,
      page: 0,
      limit: newRowsPerPage
    });
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <PaperSection sx={{ display: 'table', height: '100%', p: 0 }}>
        <FilterHeader
          filters={filterOptions}
          toggles={filterToggles}
          defaultValues={DEFAULT_FILTER_VALUES}
          values={filterValues}
          setValues={setFilterValues}
          onFilter={handleFilter}
        />

        <Box sx={{ display: 'table-row', height: '100%', overflow: 'hidden' }}>
          <VirtualisedTable
            rowHeight={36}
            fontSize={TABLE_FONT_SIZE}
            rowRenderer={renderRow}
            columns={columns}
            loading={!hasStartedLoading || areTrackersLoading}
            rowCount={(!hasStartedLoading || areTrackersLoading) ? 0 : rowCount}
            rowGetter={({ index }) => trackers[index]}
            sortBy={searchOptions.sortBy}
            onColumnHeaderClick={handleClickColumnHeader}
          />
        </Box>

        <Box sx={{ display: 'table-row', height: 0 }}>
          <Divider/>

          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <ResultsCounter
              rowCount={rowCount}
              totalCount={totalCount}
              sx={{ m: 1 }}
            />
            <CustomPagination
              page={searchOptions.page}
              count={totalCount}
              rowsPerPage={searchOptions.limit}
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              sx={{ m: 'auto', mr: 1 }}
            />
          </Box>
        </Box>
      </PaperSection>
    </Box>
  );
};

export default compose(withSystemActions, withTrackerData, withTrackerActions, withUserData)(UnitSearch);