import { combineReducers } from 'redux';

import AuthData from './AuthReducer';
import UserData from './UserReducer';
import SysData from './SystemReducer';
import FoldersData from './FolderReducer';
import AccountData from './AccountReducer';
import TrackerData from './TrackerReducer';
import CustomerData from './CustomerReducer';
import FranchiseData from './FranchiseReducer';

import SystemData_NEW from './system';
import AuthData_NEW from './auth';
import UserData_NEW from './user';
import TrackerData_NEW from './tracker';
import EventData_NEW from './event';
import LocationEventData_NEW from './location';

import ExampleData from './example';

export default combineReducers({
  AuthData,
  AuthData_NEW,

  SystemData_NEW,
  UserData_NEW,
  TrackerData_NEW,
  EventData_NEW,
  LocationEventData_NEW,

  ExampleData,

  SysData,
  UserData,
  TrackerData,
  AccountData,
  FoldersData,
  CustomerData,
  FranchiseData,
});
