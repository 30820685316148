import React from 'react';

import {
  Box,
  Button,
} from '@mui/material';

import PlaceholderPage from '../../Components/PlaceholderPage';

import OverviewTab from './Overview';
import MapDetailsTab from './MapDetails';
import ControlPanelTab from './ControlPanel';
import NotificationsTab from './Notifications';
import NotesAndAuditTab from './NotesAndAudit';
import CommsLogTab from './CommsLog';
import NotificationLogTab from './NotificationLog';
import SettingsTab from './Settings';

// const PLACEHOLDER_TAB = (
//   <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
//     <PlaceholderPage sx={{ m: 'auto' }}/>
//   </Box>
// );

const overview = {
  key: 'overview',
  label: 'Overview',
  content: (<OverviewTab/>),
};

const map = {
  key: 'map',
  label: 'Map Details',
  content: (<MapDetailsTab/>),
};

const controlPanel = {
  key: 'controlPanel',
  label: 'Control Panel',
  content: (<ControlPanelTab/>),
};

const notifications = {
  key: 'notifications',
  label: 'Notifications',
  content: (<NotificationsTab/>),
};

const notesAndAudit = {
  key: 'notesAndAudit',
  label: 'Notes & Audit',
  content: (<NotesAndAuditTab/>),
};

const commsLog = {
  key: 'commsLog',
  label: 'Comms Log',
  content: (<CommsLogTab/>),
};

const notificationLog = {
  key: 'notificationLog',
  label: 'Notification Log',
  content: (<NotificationLogTab/>),
};

const settings = {
  key: 'settings',
  label: 'Settings',
  content: (<SettingsTab/>),
};

const ALL_TABS = {
  overview,
  map,
  controlPanel,
  notifications,
  notesAndAudit,
  commsLog,
  notificationLog,
  settings,
};

// TODO: Return different tabs depending on user's access level
export function getTabs(role) {
  return [
    overview,
    map,
    controlPanel,
    notifications,
    notesAndAudit,
    commsLog,
    notificationLog,
    settings,
  ];
}

export function getTabData(tabKey) {
  return ALL_TABS[tabKey] || null;
}

export function getTabName(tabKey) {
  return ALL_TABS[tabKey] ? ALL_TABS[tabKey].label : null;
}