export const MODES = {
  FACTORY: {
    id: 'FACTORY',
    label: 'Factory',
    colour: 'modeFactory',
  },
  STOCK: {
    id: 'STOCK',
    label: 'Stock',
    colour: 'modeStock',
  },
  STANDARD: {
    id: 'STANDARD',
    label: 'Standard',
    colour: 'modeStandard',
  },
  WARNING: {
    id: 'WARNING',
    label: 'Warning',
    colour: 'modeWarning',
  },
  ALARM: {
    id: 'ALARM',
    label: 'Alarm',
    colour: 'modeAlarm',
  },
  STOLEN: {
    id: 'STOLEN',
    label: 'Stolen',
    colour: 'modeStolen',
  },
  TEST: {
    id: 'TEST',
    label: 'Test',
    colour: 'modeTest',
  },
};

export const MODES_ARRAY = [
  MODES.FACTORY,
  MODES.STOCK,
  MODES.STANDARD,
  MODES.WARNING,
  MODES.ALARM,
  MODES.STOLEN,
  MODES.TEST,
];