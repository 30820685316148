import React from 'react';

import {
  Box,
} from '@mui/material';

import PaperSection from '../../../Components/PaperSection';
import FilterHeader from '../../../Components/Filter/FilterHeader';
import NotesSection from './NotesSection';
import AuditSection from './AuditSection';

import { getFilters } from './filters';

const EMPTY_FILTER = {
  startTime: null,
  endTime: null,
};

const NotesAndAudit = () => {
  const FILTERS = getFilters();

  const [filter, setFilter] = React.useState(EMPTY_FILTER);
  const [appliedFilter, setAppliedFilter] = React.useState(EMPTY_FILTER);

  function handleFilter(useDefaultFilter = false) {
    setAppliedFilter(useDefaultFilter ? { ...EMPTY_FILTER } : filter);
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <PaperSection sx={{ mb: 1, p: 0 }}>
        <FilterHeader
          fixedWidth
          values={filter}
          defaultValues={EMPTY_FILTER}
          setValues={setFilter}
          filters={FILTERS}
          onFilter={handleFilter}
          sx={{ m: '0 auto' }}
        />
      </PaperSection>

      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%', flexGrow: 1, minHeight: 0 }}>
        <NotesSection sx={{ width: '50%', mr: 0.5 }} filter={appliedFilter}/>
        <AuditSection sx={{ width: '50%', ml: 0.5 }} filter={appliedFilter}/>
      </Box>
    </Box>
  );
}

export default NotesAndAudit;