import React from 'react';
import {
  Button,
} from '@mui/material';

const EventLimitFilter = ({ onClickEvent, filters }) => (
  <div className="tabs-buttons-options">
    <Button
      className={`${(filters.limit === 10) ? 'active' : ''}`}
      variant="contained"
      onClick={() => onClickEvent('SetLimit10')}
    >
      10
    </Button>
    <Button
      className={`${(filters.limit === 50) ? 'active' : ''}`}
      variant="contained"
      onClick={() => onClickEvent('SetLimit50')}
    >
      50
    </Button>
    <Button
      className={`${(filters.limit === 100) ? 'active' : ''}`}
      variant="contained"
      onClick={() => onClickEvent('SetLimit100')}
    >
      100
    </Button>
    <Button
      className={`${(filters.settedFilter === 'TODAY') ? 'active' : ''}`}
      variant="contained"
      onClick={() => onClickEvent('SetToday')}
    >
      TODAY
    </Button>
    <Button
      className={`${(filters.settedFilter === 'YESTERDAY') ? 'active' : ''}`}
      variant="contained"
      onClick={() => onClickEvent('SetYesterday')}
    >
      YESTERDAY
    </Button>
  </div>
);
export default EventLimitFilter;
