import React from 'react';
import { compose } from 'redux';
import { useHistory } from 'react-router-dom';

import RecentIcon from '@mui/icons-material/Restore';
import FavoritesIcon from '@mui/icons-material/Star';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  CircularProgress,
  TableContainer,
} from '@mui/material';

import featuredTrackersTableStyles from './style';
import withTrackersData from '../../../Hocs/WithData/tracker';

const FeaturedTrackersTable = ({
  tableType,
  favoriteTrackers,
  recentlyViewedTrackers,
  areFeaturedTrackersLoading,
}) => {
  const history = useHistory();
  const classes = featuredTrackersTableStyles();
  const isFavoriteTrackerTable = tableType === 'favorites';
  const featuredTrackers = isFavoriteTrackerTable ? favoriteTrackers : recentlyViewedTrackers;

  const handleTableRowClick = (trackerId) => {
    history.push(`/trackerDetails_old/${trackerId}`);
  };

  const loadingSpinner = (
    <div className={classes.featuredTrackersTableSpinnerContainer}>
      <CircularProgress className={classes.spinner} />
    </div>
  );

  const placeHolder = (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell colSpan={2} style={{ border: 'none' }}>
            {isFavoriteTrackerTable
              ? 'Mark a tracker as favorite to see it featured here.'
              : 'Once viewed, the three most recently viewed trackers will be featured here.'}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );

  const featuredTrackersTable = (
    <TableContainer className={classes.featuredTrackersTableContainer}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.featuredTrackersTableCell}>Serial Number</TableCell>
            <TableCell className={classes.featuredTrackersTableCell}>Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.featuredTrackersTableBody}>
          { featuredTrackers
            .map((featuredTracker) => (
              <TableRow
                className={classes.featuredTrackersTableRow}
                key={featuredTracker.simiccid}
                onClick={() => handleTableRowClick(featuredTracker.simiccid, history)}
              >
                <TableCell className={`
                ${classes.featuredTrackersTableCell} 
                ${classes.featuredTrackersTableCellTracker}`}
                >
                  {featuredTracker.simiccid}
                </TableCell>
                <TableCell className={`
                ${classes.featuredTrackersTableCell} 
                ${classes.featuredTrackersTableCellTracker}`}
                >
                  {featuredTracker.name}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <div className={classes.featuredTrackersContainer}>
      <Typography className={classes.featuredTrackersTableHeader}>
        {isFavoriteTrackerTable ? <FavoritesIcon /> : <RecentIcon />}
        {isFavoriteTrackerTable ? 'Favorites' : 'Recently Viewed'}
      </Typography>
      {areFeaturedTrackersLoading && loadingSpinner}
      {(!areFeaturedTrackersLoading && featuredTrackers.length === 0) && placeHolder}
      {(!areFeaturedTrackersLoading && featuredTrackers.length !== 0) && featuredTrackersTable}
    </div>
  );
};

export default compose(withTrackersData)(FeaturedTrackersTable);
