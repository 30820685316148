import React from 'react';

import { FormControlLabel, FormGroup, Switch } from '@mui/material';

import { textPurple } from '../../../../../Styles/styles';

const ToggleOptions = ({
  setNewTrackerSettings,
  newTrackerSettings,
  currentSettings,
  classes,
}) => {
  const toggleDebug = () => {
    setNewTrackerSettings({
      ...newTrackerSettings,
      debug: !newTrackerSettings.debug,
    });
  };

  const toggleForceUpdate = () => {
    setNewTrackerSettings({
      ...newTrackerSettings,
      forceUpdate: !newTrackerSettings.forceUpdate,
    });
  };

  return (
    <FormGroup className={classes.trackerSettingsToggleContainer}>
      <FormControlLabel
        label="Debug"
        labelPlacement="start"
        className={classes.trackerSettingsToggle}
        style={currentSettings.debug !== newTrackerSettings.debug
          ? { color: textPurple } : {}}
        control={(
          <Switch
            checked={newTrackerSettings.debug}
            onChange={toggleDebug}
          />
        )}
      />
      <FormControlLabel
        label="Force Update"
        labelPlacement="start"
        className={classes.trackerSettingsToggle}
        style={currentSettings.forceUpdate !== newTrackerSettings.forceUpdate
          ? { color: textPurple } : {}}
        control={(
          <Switch
            checked={newTrackerSettings.forceUpdate}
            onChange={toggleForceUpdate}
          />
        )}
      />
    </FormGroup>
  );
};

export default ToggleOptions;
