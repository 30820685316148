import { SCROLLBAR_WIDTH } from "../../Utils/Defines";

export const getColumns = (totalWidth) => {
  const COL_UNITS = 80;

  const COL_NAMES = Math.min((totalWidth - COL_UNITS - SCROLLBAR_WIDTH) / 2, 400);

  return [
    { width: COL_NAMES, label: 'Name', dataKey: 'name' },
    { width: COL_NAMES, label: 'Franchise', dataKey: 'franchise' },
    { width: COL_UNITS, label: 'Units', dataKey: 'units' },
  ];
};