import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducers from './Reducers';
import sagas from './Sagas/sagas';

const sagasMiddleware = createSagaMiddleware();

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });

const store = createStore(reducers, composeEnhancers(
  applyMiddleware(sagasMiddleware),
));

sagasMiddleware.run(sagas);

export default store;

// TODO: replace 'createStore' with 'configureStore' (requires '@reduxjs/toolkit')

// import { configureStore } from '@reduxjs/toolkit'
// import createSagaMiddleware from 'redux-saga'

// import reducer from './reducers'
// import mySaga from './sagas'

// // create the saga middleware
// const sagaMiddleware = createSagaMiddleware()
// // mount it on the Store
// const store = configureStore({
//   reducer, 
//   middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
// })

// // then run the saga
// sagaMiddleware.run(mySaga)

// // render the application