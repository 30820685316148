import { connect } from 'react-redux';

import {
  getTracker,
  updateTracker,

  getTrackers,

  getRecentAndBookmarkedTrackers,    // TODO: split into separate 'bookmarked' & 'recently-viewed' functions in all redux code (requires API changes)
  addTrackerToRecents,
  addTrackerToBookmarks,
  removeTrackerFromBookmarks,

  getCustomerTrackers,

  updateAsset,
  updateAssetImage,

  setTrackerState,
  getTrackerSettings,
  setTrackerSettings,
  sendRawTrackerSettings,

  setZones,
  setHighlightedZone,
  setShowZones,
  setNewZone,
} from '../Actions/tracker';

export default (WrappedComponent) => connect(null, {
  getTracker,
  updateTracker,

  getTrackers,
  
  getRecentAndBookmarkedTrackers,
  addTrackerToRecents,
  addTrackerToBookmarks,
  removeTrackerFromBookmarks,

  getCustomerTrackers,

  updateAsset,
  updateAssetImage,

  setTrackerState,
  getTrackerSettings,
  setTrackerSettings,
  sendRawTrackerSettings,

  setZones,
  setHighlightedZone,
  setShowZones,
  setNewZone,
})(WrappedComponent);