import React from 'react';

import {
  Box,
} from '@mui/material';

const TabPanel = ({
  value,
  name,
  content,
  sx,
  ...props
}) => (
  <Box
    role="tabpanel"
    hidden={value !== name}
    sx={{ height: '100%', ...sx }}
    {...props}
  >
    {value === name && (
      content
    )}
  </Box>
);

export default TabPanel;