import React, { useState } from 'react';
import { compose } from 'redux';
import { Button, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import RowComponent from './RowComponent';
import withTrackerAction from '../../../../Hocs/WithAction/tracker';
import {
  valueColor,
  buttonPurple,
  semiBoldFont,
  onHoverButtonPurple,
} from '../../../../Styles/styles';

const trackerNameStyles = makeStyles(() => ({
  trackerName: {
    fontFamily: semiBoldFont,
    fontSize: '14px',
    width: '100%',
    lineHeight: '20px',
    color: valueColor,
    display: 'flex',
    alignItems: 'center',
    '& .MuiInputBase-root': {
      marginBottom: '8px',
    },
  },
  labelWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    color: buttonPurple,
    borderRadius: '8px',
    margin: '0px',
    textTransform: 'none',
    width: '100%',
    maxWidth: 'fit-content',
  },
  submitButton: {
    textTransform: 'none',
    backgroundColor: buttonPurple,
    color: 'white',
    margin: '0px 0px 0px 12px',
    '&:disabled': {
      backgroundColor: '#E4E7EC',
      color: '#929FB4',
    },
    '&:hover': {
      backgroundColor: onHoverButtonPurple,
      color: 'white',
    },
  },
}));

const TrackerEditableName = ({ tracker, updateTrackerName, toggleTrackerNameInputField }) => {
  const classes = trackerNameStyles();
  const [trackerName, setTrackerName] = useState(tracker.name);

  const cancelChange = () => {
    setTrackerName(tracker.name);
    toggleTrackerNameInputField(false);
  };

  const changeHandler = (e) => {
    setTrackerName(e.target.value);
  };
  const submitChange = (e) => {
    e.preventDefault();
    updateTrackerName({ trackerName });
  };

  const TrackerNameLabel = (
    <>
      <RowComponent field="Tracker Name" />
      <div className={classes.labelWrapper}>
        <span className={classes.trackerName}>{tracker.name}</span>
        <Button
          className={classes.button}
          onClick={() => toggleTrackerNameInputField(true)}
        >
          Edit name
        </Button>
      </div>
    </>
  );

  const TrackerNameInput = (
    <>
      <RowComponent field="Tracker Name" />
      <TextField
        field="Tracker Name"
        value={trackerName}
        onChange={changeHandler}
        fullWidth
        variant="outlined"
        size="small"
        className={classes.trackerName}
        InputProps={{ className: classes.trackerName }}
      />
      <div className={classes.buttonWrapper}>
        <Button
          className={classes.button}
          onClick={() => cancelChange()}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className={classes.submitButton}
          disabled={tracker.name === trackerName}
          onClick={submitChange}
        >
          {(tracker.isTrackerDetailsUpdating) ? 'Saving' : 'Save'}
        </Button>
      </div>
    </>
  );
  return (
    <>
      {tracker.showTrackerNameInputField ? TrackerNameInput : TrackerNameLabel}
    </>
  );
};

export default compose(withTrackerAction)(TrackerEditableName);
