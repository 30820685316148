import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

import {
  Box,
  Dialog,
  Button,
  IconButton,
  Typography,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '540px',
    maxWidth: '90vw',
  },
  '& .MuiDialogContent-root': {
    maxHeight: '60vh',
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  },
  '& .MuiDialogActions-root': {
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  },
}));

const BootstrapDialogHeader = ({
  headerText,
  onClose,
  ...other
}) => {
  return (
    <Box sx={{ m: 0, p: 2 }} {...other}>
      <Typography variant="h6" sx={{ paddingRight: "35px" }}>{headerText}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </Box>
  );
};

BootstrapDialogHeader.propTypes = {
  headerText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

const CustomDialog = React.forwardRef(function MyComponent(props, ref) {
  const {
    variant = "contained",
    btnText,
    btnIcon,
    headerText,
    body,
    ...other
  } = props;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  return (
    <>
      {variant !== 'icon' ? (
        <Button
          ref={ref}
          variant={variant}
          onClick={handleClickOpen}
          {...other} >
          {btnText}
        </Button>
      ) : (
        <IconButton
          ref={ref}
          onClick={handleClickOpen}
          {...other} >
          {btnIcon}
        </IconButton>
      )}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="custom-dialog-header"
        open={open}
      >
        <BootstrapDialogHeader
          id="custom-dialog-header"
          onClose={handleClose}
          headerText={headerText} />
        {body && body(handleClose)}
      </BootstrapDialog>
    </>
  );
});

CustomDialog.propTypes = {
  children: PropTypes.node,
  btnText: PropTypes.string,
  title: PropTypes.string,
  //onClose: PropTypes.func.isRequired,
};

export default CustomDialog;