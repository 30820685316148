import React from 'react';

import { deepmerge } from '@mui/utils';
import { PaletteMode } from '@mui/material';
import { createTheme, alpha } from '@mui/material/styles';

import { MOBILE_MIN_WIDTH, TABLET_MIN_WIDTH, DESKTOP_MIN_WIDTH } from '../Utils/ScreenSize';
import lightPalette from './Light';
import darkPalette from './Dark';

const BORDER_RADIUS = 8;

const themeConstants = {
  spacing: 8,
  shape: {
    borderRadius: BORDER_RADIUS,
  },
  borderRadius: (factor: number) => `${BORDER_RADIUS * factor}px`,
  breakpoints: {
    // These are the minimum widths (in px) of each screen type
    values: {
      mobile: MOBILE_MIN_WIDTH,
      tablet: TABLET_MIN_WIDTH,
      desktop: DESKTOP_MIN_WIDTH,
    },
  },
};

const getModePalette = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === 'light' ? lightPalette : darkPalette)
  }
})

export function getTheme(mode: PaletteMode) {
  let theme = createTheme(deepmerge(getModePalette(mode), themeConstants));

  return createTheme(theme, {
    components: {
      MuiPaper: {
        styleOverrides: {
          rounded: {
            borderRadius: `${BORDER_RADIUS * 1.5}px`,
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.background.paper,
            '& .MuiTab-root': {
              borderBottomColor: theme.palette.divider,
              borderBottomStyle: 'solid',
              borderBottomWidth: '1px',
              textTransform: "none",
            },
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            margin: 0,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...(!ownerState.disabled && !ownerState.error && {
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: alpha(theme.palette.primary.main, 0.5),
              },
            }),
          }),
        },
      },
      MuiTextField: {
        defaultProps: {
          fullWidth: true,
          margin: "dense",
          size: "small",
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
            minWidth: 'min-content',
          },
          contained: {
            padding: '6px 12px',
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
      },
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            color: theme.palette.primary.main,
          },
        },
        // defaultProps: {          // Add this default prop to improve appearance under high CPU load
        //   disableShrink: true,
        // }
      },
    },
  });
}
