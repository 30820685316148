import makeStyles from '@mui/styles/makeStyles';

import { mobile, tableRowOnHoverPurple } from '../../../Styles/styles';

const trackersTableStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    position: 'relative',
  },
  tableHead: {
    backgroundColor: '#F0F0F0',
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: tableRowOnHoverPurple,
    },
  },
  starIconButton: {
    '& span': {
      '& svg': {
        color: 'grey',
      },
    },
  },
  spinner: {
    alignSelf: 'center',
  },
  pagination: {
    [theme.breakpoints.down(mobile)]: {
      margin: 'unset',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      '& .MuiTablePagination-caption': {
        display: 'none',
      },
    },
  },
}));

export default trackersTableStyles;
