import { connect } from 'react-redux';

const mapStateToProps = ({
  // Data in the Store
  UserData_NEW: {
    email,
    firstName,
    lastName,
    role,
    avatar,
    userFranchise,
    userCustomer,
    isAvatarUploading,
  }
}) => ({
  // Props for the Component
  email,
  firstName,
  lastName,
  role,
  avatar,
  userFranchise,
  userCustomer,
  isAvatarUploading,
})

export default (WrappedComponent) => connect(mapStateToProps, null)(WrappedComponent);