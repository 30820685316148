import { connect } from 'react-redux';

const mapStateToProps = ({
  // Data in the Store
  TrackerData_NEW: {
    tracker,
    isTrackerLoading,

    trackers,
    totalNumOfTrackers,
    trackerFilter,
    areTrackersLoading,
    
    recentTrackers,
    bookmarkedTrackers,
    areRecentTrackersLoading,
    areBookmarkedTrackersLoading,

    customerTrackers,
    areCustomerTrackersLoading,

    zones,
    highlightedZone,
    showZones,
    newZone,
  }
}) => ({
  // Props for the Component
  tracker,
  isTrackerLoading,

  trackers,
  totalNumOfTrackers,
  trackerFilter,
  areTrackersLoading,
  
  recentTrackers,
  bookmarkedTrackers,
  areRecentTrackersLoading,
  areBookmarkedTrackersLoading,

  customerTrackers,
  areCustomerTrackersLoading,

  zones,
  highlightedZone,
  showZones,
  newZone,
})

export default (WrappedComponent) => connect(mapStateToProps, null)(WrappedComponent);