import { connect } from 'react-redux';

import {
  setAppTheme,
  setAppLanguage,
  setShowSubHeader,
  checkVersion,

  addNotification,
  removeNotifications,
} from '../Actions/system';

export default (WrappedComponent) => connect(null, {
  setAppTheme,
  setAppLanguage,
  setShowSubHeader,
  checkVersion,

  addNotification,
  removeNotifications,
})(WrappedComponent);