import React from 'react';

import {
  Select,
  MenuItem,
  Container,
  FormControl,
} from '@mui/material';

import { hours, minutes } from '../trackerSettingsHelpers';

const TimeIntervalDropdown = ({ classes, newTrackerSettings, setNewTrackerSettings }) => {
  const handleChangeIntervalModeHour = ({ target: { value } }) => {
    setNewTrackerSettings({
      ...newTrackerSettings,
      intervalHour: value,
    });
  };

  const handleChangeIntervalModeMinute = ({ target: { value } }) => {
    setNewTrackerSettings({
      ...newTrackerSettings,
      intervalMinute: value,
    });
  };

  return (
    <Container className={classes.trackerSettingsIntervalDropdownContainer}>
      <FormControl
        variant="outlined"
        className={classes.trackerSettingsIntervalDropdownControl}
      >
        <Select
          value={`${newTrackerSettings.intervalHour}`}
          onChange={handleChangeIntervalModeHour}
          className={classes.trackerSettingsIntervalDropdown}
        >
          {hours.map((hour, index) => (
            <MenuItem key={hour} value={index}>
              {`Every ${hour === 1 ? '' : hour} ${hour === 1 ? 'hour' : 'hours'}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl
        variant="outlined"
        className={classes.trackerSettingsIntervalDropdownControl}
      >
        <Select
          value={`${newTrackerSettings.intervalMinute}`}
          onChange={handleChangeIntervalModeMinute}
          className={classes.trackerSettingsIntervalDropdown}
        >
          {minutes.map((minute, index) => (
            <MenuItem key={minute} value={index}>
              {`and ${minute} ${minute === 1 ? 'minute' : 'minutes'}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Container>
  );
};

export default TimeIntervalDropdown;
