import makeStyles from '@mui/styles/makeStyles';

import {
  white,
  mobile,
  toggleUncheckedGrey,
  listItemOnHoverPurple,
  toggleTrackUncheckedGrey,
} from '../../../../Styles/styles';

const trackerSettingsStyles = makeStyles((theme) => ({
  trackerSettingsContainer: {
    padding: '30px',
    paddingTop: '0px',
    display: 'flex',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    // Breakpoint for Tablet
    [theme.breakpoints.down(mobile)]: {
      flexDirection: 'column',
    },
  },
  topElement: {
    paddingTop: '30px',
  },
  trackerSettings: {
    width: '49%',
    // Breakpoint for Tablet
    [theme.breakpoints.down(mobile)]: {
      marginBottom: '24px',
      width: '100%',
    },
  },
  trackerSettingsHeader: {
    color: '#323547',
    fontSize: '24px',
    fontWeight: '600',
    letterSpacing: '0',
    fontFamily: 'Source Sans Pro',
  },
  trackerSettingsSubHeader: {
    color: '#323547',
    fontSize: '14px',
    letterSpacing: '0',
    marginBottom: '10px',
    fontFamily: 'Source Sans Pro',
  },
  trackerSettingsToggleContainer: {
    width: '100%',
    display: 'flex',
    marginTop: '15px',
    justifyContent: 'space-between',
  },
  trackerSettingsToggle: {
    justifyContent: 'space-between',
    display: 'flex',
    margin: 'unset',
    '& span': {
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '20px',
      letterSpacing: '0',
      fontFamily: 'Source Sans Pro',
      '& span': {
        '&.MuiSwitch-switchBase': {
          color: toggleUncheckedGrey,
        },
        '&.MuiSwitch-track': {
          backgroundColor: toggleTrackUncheckedGrey,
        },
        '&.Mui-checked.MuiSwitch-switchBase': {
          color: theme.palette.primary.main,
        },
        '&.Mui-checked + .MuiSwitch-track': {
          backgroundColor: theme.palette.primary.light,
        },
      },
    },
  },
  trackerSettingsDivider: {
    margin: '24px 0px',
  },
  trackerSettingsScheduleModeContainer: {
    display: 'flex',
  },
  trackerSettingsFieldContainer: {
    justifyContent: 'space-between',
    paddingLeft: 'unset',
    marginBottom: '24px',
    display: 'flex',
  },
  trackerSettingsFieldLabel: {
    color: '#323547',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '20px',
    letterSpacing: '0',
    fontFamily: 'Source Sans Pro',
  },
  trackerSettingsFieldValue: {
    color: '#323547',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0',
    fontFamily: 'Source Sans Pro',
  },
  trackerSettingsScheduleModeDropdownControl: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.primary.light}`,
    },
    '& .MuiInputBase-input:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: theme.palette.primary.light,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: `1px solid ${theme.palette.primary.light}`,
      },
    },
    // Breakpoint for Tablet
    [theme.breakpoints.down(mobile)]: {
      flexDirection: 'column',
    },
  },
  trackerSettingsScheduleModeDropdown: {
    height: '40px',
    width: '100%',
    marginBottom: '15px',
    '& div': {
      display: 'flex',
    },
  },
  trackerSettingsIntervalDropdownContainer: {
    width: '100%',
    display: 'flex',
    padding: 'unset',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.primary.light}`,
    },
    '& .MuiInputBase-input:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: theme.palette.primary.light,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: `1px solid ${theme.palette.primary.light}`,
      },
    },
    // Breakpoint for Tablet
    [theme.breakpoints.down(mobile)]: {
      flexDirection: 'column',
    },
  },
  trackerSettingsIntervalDropdownControl: {
    width: '49%',
    // Breakpoint for Tablet
    [theme.breakpoints.down(mobile)]: {
      width: '100%',
    },
  },
  trackerSettingsIntervalDropdown: {
    width: '100%',
    height: '40px',
    minWidth: '150px',
    marginBottom: '10px',
    '& div': {
      display: 'flex',
    },
  },
  trackerSettingsDropdownLabel: {
    color: '#323547',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '20px',
    letterSpacing: '0',
    marginBottom: '6px',
    fontFamily: 'Source Sans Pro',
  },
  trackerSettingsSchedulesCount: {
    color: 'darkgray',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '20px',
    letterSpacing: '0',
    marginBottom: '6px',
    padding: '0px 10px',
    fontFamily: 'Source Sans Pro',
  },
  trackerSettingsButtonsContainer: {
    display: 'flex',
    paddingTop: '26px',
    justifyContent: 'flex-end',
  },
  trackerSettingsCancelButton: {
    margin: '0px',
    width: '100%',
    borderRadius: '8px',
    color: theme.palette.primary.main,
    textTransform: 'none',
    maxWidth: 'fit-content',
  },
  trackerSettingsSaveButton: {
    color: 'white',
    padding: '6px 15px',
    borderRadius: '8px',
    textTransform: 'none',
    margin: '0px 0px 0px 12px',
    backgroundColor: theme.palette.primary.main,
    '&:disabled': {
      backgroundColor: '#E4E7EC',
      color: '#929FB4',
    },
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.primary.light,
    },
  },
  trackerSettingsCalendarEventListItemCurrentSettings: {
    padding: '12px',
    '&:hover': {
      borderRadius: '8px',
      backgroundColor: listItemOnHoverPurple,
    },
  },
  trackerSettingsCalendarEventListItemNewSettings: {
    padding: '0px 0px 0px 16px',
    '&:hover': {
      borderRadius: '8px',
      backgroundColor: listItemOnHoverPurple,
    },
  },
  trackerSettingsCalendarEventTimeLabel: {
    color: '#4F5572',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0',
    fontFamily: 'Source Sans Pro',
  },
  trackerSettingsCalendarEventLocationLabel: {
    color: white,
    width: '48px',
    fontSize: '12px',
    fontWeight: '600',
    letterSpacing: '0',
    marginLeft: 'auto',
    lineHeight: '24px',
    marginRight: '10px',
    borderRadius: '8px',
    textAlign: 'center',
    fontFamily: 'Source Sans Pro',
    // Breakpoint for Tablet
    [theme.breakpoints.down(mobile)]: {
      marginRight: '0px',
    },
  },
  trackerSettingsCalendarEventEssentialIcon: {
    marginRight: '10px',
    color: 'slategray',
    height: '16px',
    width: '16px',
  },
  trackerSettingsCalendarEventDeleteIcon: {
    color: 'slategray',
  },
  trackerSettingsScheduleModeTimezoneDropdown: {
    '& div': {
      '& .MuiInputBase-root': {
        padding: '0px 0px 0px 10px',
      },
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.primary.light}`,
    },
    '& .MuiInputBase-input:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: theme.palette.primary.light,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: `1px solid ${theme.palette.primary.light}`,
      },
    },
  },
  trackerSettingsSpinnerContainer: {
    height: '100%',
    padding: '30px',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    justifyContent: 'center',
  },
  trackerTextArea: {
    minWidth: '100%',
    maxWidth: '100%',
    minHeight: '150px',
  },
}));

export default trackerSettingsStyles;
