import {
  takeLatest,
  call,
} from 'redux-saga/effects';
import moment from 'moment-timezone';

import packageJson from '../../../package.json';
import BaseAPI from '../../Api/Endpoints/base';
import { checkCurrentVersion } from '../Actions/SystemActions';
import { ResponseTypes } from '../../Api/Responses/baseResponse';

const VersionHandler = {
  isGreaterVersionThan: (versionA, versionB) => {
    const versionsA = versionA.split(/\./g);
    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
      const a = Number(versionsA.shift());
      const b = Number(versionsB.shift());
      if (a > b || isNaN(b)) return true;
    }
    return false;
  },
  isBuildDateGreaterThan: (latestVersion, localVersion) => {
    const latestDateTime = moment(latestVersion);
    const localDateTime = moment(localVersion);
    return !!latestDateTime.isAfter(localDateTime);
  },
};

// TODO: FIX! - This relates to refreshing the service worker when a new version is available
export function* handleCurrentVersion() {
  const response = yield call(BaseAPI.currentVersion);
  if (ResponseTypes.ActionCompleted === response.type) {
    if (VersionHandler.isBuildDateGreaterThan(response.data.buildDate, packageJson.buildDate)) {
      console.log({
        oldVersion: packageJson.buildDate,
        newVersion: response.data.buildDate,
      },'New version found');
      if ('caches' in window) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
          names.forEach((name) => caches.delete(name));
        });
      }
      // delete browser cache and hard reload
      window.location.reload();
    }
  }
}

export default function* TrackerSettingsSagas() {
  yield takeLatest(checkCurrentVersion, handleCurrentVersion);
}
