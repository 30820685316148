import AuthCall from './auth';

export default {
  getLocations: async (trackerId, limit = 10, gps = true, gsm = true, wifi = true, startTime, endTime) => {
    return await AuthCall.get(`/api/v1/secure/event/tracker/position/${trackerId}`, { limit, offset: 0, gps, gsm, wifi, startTime, endTime });
  },
  getLocationAddress: async (eventId) => {
    return await AuthCall.get(`/api/v1/secure/event/instance/address/${eventId}`);
  },
  createDummyLocation: async (trackerId, event) => {
    const body = `${trackerId}@0L3=${Object.keys(event).map((key) => `${key}:${event[key]}`).join('?')}`;
    
    return await AuthCall.post(`/api/v1/open/helios/event/${trackerId}`, body, { 'Content-Type': 'text/plain' });
  },
}