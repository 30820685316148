import { compose } from 'redux';
import React, { useEffect } from 'react';

import {
  List,
  Avatar,
  ListItem,
  Typography,
  ListItemText,
  ListItemAvatar,
  CircularProgress,
} from '@mui/material';

import franchiseListStyles from './style';
import defaultLogo from '../../../Assets/Images/automatrics.png';
import EditFranchiseModal from '../EditFranchiseModal/EditFranchiseModal';
import { isAccountRoleAdmin, isAccountRoleRoot } from '../../../Utils/Utils';
import DeleteFranchiseModal from '../DeleteFranchiseModal/DeleteFranchiseModal';

import withUserData from '../../../redux/WithData/user';
import withFranchiseData from '../../../Hocs/WithData/franchise';
import withFranchiseActions from '../../../Hocs/WithAction/franchise';

function FranchiseList({
  franchise: { id: franchiseId },
  areFranchisesLoading,
  getAllFranchises,
  getFranchise,
  franchises,
  role,
}) {
  const classes = franchiseListStyles();

  useEffect(() => {
    if (isAccountRoleRoot(role)) getAllFranchises();
  }, [getAllFranchises, role]);

  useEffect(() => {
    if (franchiseId && isAccountRoleAdmin(role)) getFranchise({ franchiseId });
  }, [franchiseId, getFranchise, role]);

  const spinner = (
    <div className={classes.spinnerContainer}>
      <CircularProgress />
    </div>
  );

  const noFranchises = (
    <div className={classes.emptyFranchiseList}>
      <Typography className={classes.emptyFranchiseListHeader}>
        No franchises have been added yet. Use the add new franchise button to create one.
      </Typography>
    </div>
  );

  if (areFranchisesLoading) return spinner;
  if (franchises.length === 0) return noFranchises;

  return (
    <>
      <List className={classes.franchiseList}>
        {franchises.map((franchise) => (
          <ListItem
            key={franchise.id}
            disabled={areFranchisesLoading}
            className={classes.franchiseListItem}
          >
            <ListItemAvatar>
              <Avatar alt="Automatrix HQ" src={defaultLogo} />
            </ListItemAvatar>
            <ListItemText
              primary={franchise.name}
              className={classes.franchiseListItemText}
            />
            {(isAccountRoleRoot(role) || isAccountRoleAdmin(role)) && (
              <div>
                <EditFranchiseModal role={role} franchise={franchise} />
                {isAccountRoleRoot(role) && (
                  <DeleteFranchiseModal franchise={franchise} />
                )}
              </div>
            )}
          </ListItem>
        ))}
      </List>
    </>
  );
}

export default compose(withFranchiseData, withFranchiseActions, withUserData)(FranchiseList);
