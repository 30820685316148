import React from 'react';
import { compose } from 'redux';

import { Container } from '@mui/material';

import CustomAppBar from './CustomAppBar';
import CustomDrawer from './CustomDrawer';

import { useWindowDimensions } from '../../../Utils/ScreenSize';

import withAuthActions from '../../../redux/WithActions/auth';
import withUserData from '../../../redux/WithData/user';

import { HEADER_ROW_HEIGHT } from '../../../Utils/Defines';

const MenuBar = ({
  role,
  avatar,
  firstName,
  lastName,
  logout,
  children,
}) => {
  const [isOpen, setOpen] = React.useState(false);

  const { height } = useWindowDimensions();

  const userData = {
    role: role,
    avatar: avatar,
    firstName: firstName,
    lastName: lastName,
  };

  const handleOpenDrawer = () => {
    setOpen(true);
  };

  const handleCloseDrawer = () => {
    setOpen(false);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <CustomAppBar
        userData={userData}
        logout={logout}
        barHeight={HEADER_ROW_HEIGHT}
        isOpen={isOpen}
        handleOpenDrawer={handleOpenDrawer}
        handleCloseDrawer={handleCloseDrawer} />
      <CustomDrawer
        barHeight={HEADER_ROW_HEIGHT}
        isOpen={isOpen}
        handleCloseDrawer={handleCloseDrawer} />
      <Container
        component="main"
        sx={theme => ({
          width: '100%',
          padding: 0,
          [theme.breakpoints.up('tablet')]: {
            // Not needed on mobile due to 'relative' position of AppBar
            marginTop: `${HEADER_ROW_HEIGHT}px`,
            height: `${height - HEADER_ROW_HEIGHT}px`,
          },
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
        })}
      >
        {children}
      </Container>
    </div>
  )
};

export default compose(withUserData, withAuthActions)(MenuBar);
