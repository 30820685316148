import React from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Box,
  Button,
  Divider,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';

import {
  Add as AddIcon,
  Close as CancelIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';

import PaperSection from '../../../Components/PaperSection';
import VirtualisedTable from '../../../Components/VirtualisedTable/VirtualisedTable';

import { SCROLLBAR_WIDTH } from '../../../../Utils/Defines';
import { useWindowDimensions, getHalfPaperSectionWidth } from '../../../../Utils/ScreenSize';
import { SHAPES, SHAPES_ARRAY } from './zoneShapes';

const getZoneTypeName = (isForbidden) => {
  return isForbidden ? 'Forbidden' : 'Permitted';
}

const getColumns = (totalWidth, onDeleteZone) => {
  const COL_ICON = 60;
  const COL_TYPE = 100;
  const COL_NAME = totalWidth - COL_TYPE - COL_ICON - SCROLLBAR_WIDTH;

  return [
    { width: COL_TYPE, label: 'Type', dataKey: 'forbidden', render: ({ cellData }) => getZoneTypeName(cellData) },
    { width: COL_NAME, label: 'Name', dataKey: 'name' },
    { width: COL_ICON, label: '', dataKey: 'removable', render: ({ rowIdx }) => (
      <IconButton onClick={() => { onDeleteZone(rowIdx) }}>
        <DeleteIcon/>
      </IconButton>
    )},
  ];
}

const ZonesSection = ({
  zones,
  newZone,
  areZonesVisible,
  setNewZone,
  setHighlightedRow,
  onToggleZoneVisibility,
  onDeleteZone,
  onClickCreateNewZone,
  onClickSaveNewZone,
  onClickCancelNewZone,
  sx,
}) => {
  const theme = useTheme();
  const windowDimensions = useWindowDimensions();

  const isAddingPath = (newZone.path !== false) && (newZone.path.length > 0);

  const PAPER_WIDTH = getHalfPaperSectionWidth(windowDimensions, theme);
  const columns = getColumns(PAPER_WIDTH, onDeleteZone);

  const handleChangeZoneShape = (_, shape) => {
    setNewZone({
      ...newZone,
      shape: shape,
    });
  }

  const handleChangeZoneType = (_, isForbidden) => {
    setNewZone({
      ...newZone,
      forbidden: isForbidden,
    });
  }

  const handleChangeZoneName = (event) => {
    setNewZone({
      ...newZone,
      name: event.target.value
    });
  }

  const handleRowMouseOver = ({ index }) => {
    areZonesVisible && setHighlightedRow(index);
  }

  const handleRowMouseOut = ({ index }) => {
    areZonesVisible && setHighlightedRow(-1);
  }

  return (
    <PaperSection sx={{ ...sx, p: 0, minHeight: '250px', overflow: 'hidden' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 1, pb: 1, pl: 2, pr: 2 }}>
        <Typography variant="h6" sx={{ m: 'auto 0' }}>Zones</Typography>
        <IconButton onClick={onToggleZoneVisibility}>
          {areZonesVisible ? <VisibilityIcon/> : <VisibilityOffIcon/>}
        </IconButton>
        {!isAddingPath ? (
          <Button variant="contained" startIcon={<AddIcon/>} onClick={onClickCreateNewZone} sx={{ m: 'auto', mr: 0 }}>Add</Button>
        ) : (
          <>
            <ToggleButtonGroup
              exclusive
              size="small"
              value={newZone.shape}
              onChange={handleChangeZoneShape}
              sx={{ ml: 4 }}
            >
              {SHAPES_ARRAY.map((shape) =>
                <ToggleButton key={shape.id} value={shape.id}>{shape.icon}</ToggleButton>
              )}
            </ToggleButtonGroup>
            <Button variant="contained" startIcon={<SaveIcon/>} onClick={onClickSaveNewZone} sx={{ m: 'auto', mr: 0 }}>Save</Button>
            <Button variant="contained" startIcon={<CancelIcon/>} onClick={onClickCancelNewZone} sx={{ m: 'auto 0', ml: 1 }}>Cancel</Button>
          </>
        )}
      </Box>

      {isAddingPath && (
        <Box sx={{ display: 'flex', flexDirection: 'row', pb: 1, pl: 2, pr: 2 }}>
          <ToggleButtonGroup
            exclusive
            size="small"
            value={newZone.forbidden}
            onChange={handleChangeZoneType}
          >
            <ToggleButton value={false}>{getZoneTypeName(false)}</ToggleButton>
            <ToggleButton value={true}>{getZoneTypeName(true)}</ToggleButton>
          </ToggleButtonGroup>
          <TextField
            label="Name"
            value={newZone.name}
            onChange={handleChangeZoneName}
            sx={{ m: 0, ml: 2 }}
          />
        </Box>
      )}

      <Divider/>

      <Box sx={{ height: '100%' }}>
        <VirtualisedTable
          hover
          rowHeight={40}
          columns={columns}
          rowCount={zones.length}
          rowGetter={({ index }) => zones[index]}
          onRowMouseOver={handleRowMouseOver}
          onRowMouseOut={handleRowMouseOut}
        />
      </Box>
    </PaperSection>
  );
}

export default ZonesSection;