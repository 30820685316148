import React from 'react';

import {
  Box,
  Stack,
} from '@mui/material';

import NotificationsSection from './NotificationsSection';
import ForwardingSection from './ForwardingSection';

import { useWindowDimensions } from '../../../../Utils/ScreenSize';

const NotificationsTab = () => {
  const { isTabletWidth, isDesktopWidth } = useWindowDimensions();

  // Desktop View
  if (isDesktopWidth) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
        <NotificationsSection sx={{ width: '50%', height: '100%', mr: 0.5 }}/>
        <ForwardingSection sx={{ width: '50%', height: '100%', ml: 0.5 }}/>
      </Box>
    );
  }
  // Tablet/Mobile View
  else {
    return (
      <Stack direction="column" spacing={2} sx={isTabletWidth ? { m: 2 } : { mt: 2 }}>
        <NotificationsSection/>
        <ForwardingSection/>
      </Stack>
    );
  }
}

export default NotificationsTab;