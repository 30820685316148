import React from 'react';
import { compose } from 'redux';

import {
  Alert,
  AlertTitle,
  Snackbar,
} from '@mui/material';

import withSystemActions from '../../redux/WithActions/system';

// TODO: switch to Notistack (https://github.com/iamhosseindhv/notistack)

const SysNotification = ({
  id,
  type,
  message,
  title,
  removeNotifications,
}) => {
  const handleClose = () => {
    removeNotifications();
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open
      key={id}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={type}>
        <AlertTitle>{title}</AlertTitle>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default compose(withSystemActions)(SysNotification);
