import { createAction } from 'redux-actions';

const ENTITY = '@@AUTH';

// Login
export const loginRequest = createAction(`${ENTITY}/LOGIN_REQUEST`);
export const login = createAction(`${ENTITY}/LOGIN`);

// Register
export const registerRequest = createAction(`${ENTITY}/REGISTER_REQUEST`);
export const register = createAction(`${ENTITY}/REGISTER`);

// Token Refresh
export const tokenRefreshing = createAction(`${ENTITY}/TOKEN_REFRESHING`);
export const tokenRefreshed = createAction(`${ENTITY}/TOKEN_REFRESHED`);

// Logout
export const logout = createAction(`${ENTITY}/LOGOUT`);

// Loading
export const submitting = createAction(`${ENTITY}/SUBMITTING`);
export const submitted = createAction(`${ENTITY}/SUBMITTED`);
