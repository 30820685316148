import { DateTime } from 'luxon';

import ConfinedTypography from '../../../Components/Typographies/ConfinedTypography';

import { CARRIERS } from './carriers';
import CommsDirection, { DIRECTIONS } from './CommsDirection';
import { TYPES } from './types';
import { SCROLLBAR_WIDTH } from "../../../../Utils/Defines";
import { formatDateTime } from '../../../../Utils/TimeUtils';

export const getColumns = (totalWidth) => {
  const COL_TIMESTAMP = 170;
  const COL_CARRIER   = 100;
  const COL_DIRECTION = 120;
  const COL_TYPE      = 140;

  const remainingWidth = totalWidth - COL_TIMESTAMP - COL_CARRIER - COL_DIRECTION - COL_TYPE - SCROLLBAR_WIDTH;

  return [
    { width: COL_TIMESTAMP, label: 'Timestamp', dataKey: 'timestamp', render: ({ cellData }) => formatDateTime(DateTime.fromISO(cellData), true) },
    { width: COL_CARRIER, label: 'Carrier', dataKey: 'carrier' , render: ({ cellData }) => CARRIERS[cellData]?.label || <ConfinedTypography disabled>{cellData}</ConfinedTypography>},
    { width: COL_DIRECTION, label: 'Direction', dataKey: 'direction', render: ({ cellData, rowData }) => <CommsDirection direction={DIRECTIONS[cellData]} type={CARRIERS[rowData.carrier]}/> },
    { width: COL_TYPE, label: 'Type', dataKey: 'type' , render: ({ cellData }) => TYPES[cellData]?.label || <ConfinedTypography disabled>{cellData}</ConfinedTypography>},
    { width: remainingWidth, label: 'Data', dataKey: 'rawData', render: ({ cellData }) => <ConfinedTypography title maxLines={2} forceWrap>{cellData}</ConfinedTypography> },
  ];
};