import React from 'react';

import { Typography } from '@mui/material';

import trackerDetailsStyles from './style';
import TrackerSelector from '../Elements/TrackerSelector';

const TrackersDropDownList = ({ selectTracker }) => {
  const classes = trackerDetailsStyles();
  return (
    <div className={classes.trackersDropDownListContainer}>
      <Typography
        component="h1"
        className={classes.trackersDropDownListTypography}
      >
        Choose a tracker from the dropdown below to get started.
      </Typography>
      <TrackerSelector selectTracker={selectTracker} />
    </div>
  );
};

export default TrackersDropDownList;
