import { handleActions } from 'redux-actions';

import { logout } from '../Actions/AuthActions';
import LocationEventsHelper from '../../Components/LocationEvents';
import {
  storeLastEventAction,
  loadingEventListAction,
  storeAllEventsTrackerAction,
  changePageEventTrackerListAction,
  changeLimitEventTrackerListAction,
} from '../Actions/EventActions';
import {
  loadTracker,
  loadingTrackers,
  updatingTracker,
  addTrackerEventOnWSAction, // WebSocket Event
  addLocationTrackerEventOnWSAction, // WebSocket Event
  loadFeaturedTrackers,
  loadingFeaturedTrackers,
  addTrackerToFavourites,
  removeTrackerFromFavourites,
  updateSendingPeriodicDummyLocations,

  pauseMapUpdates,
  storeFilteredTrackerAction,
  updateFilteredTrackerAction,
  storeSingleTackerEventAction,
  updateTrackerLocationFilterValue,
  setMapPositionAction,
  changeEventDetailsTabAction,
  setSelectedEventAction,
  resetFilteredLocationTrackerAction,
  setLoadingFilteredLocationTrackerAction,
  updateLocationEventAction,
  changePageFilteredTrackerListAction,
  changeLimitFilterTrackerListAction,
  selectTrackerEvent,
  TrackerNameUpdated,
  updatingTrackerName,
  loadTrackerSettings,
  trackerImageUploaded,
  cancelSearchTrackers,
  loadPaginatedTrackers,
  currentTrackerLoading,
  uploadingTrackerImage,
  updateTrackerSettings,
  updateTrackerRawSettings,
  TrackerTimezoneUpdated,
  loadingTrackerSettings,
  updatingTrackerTimezone,
  updatingTrackerSettings,
  updatingTrackerRawSettings,
  changeTrackersListFilter,
  toggleTrackerNameInputField,
  TrackerEquipmentNumberUpdated,
  updatingTrackerEquipmentNumber,
  toggleTrackerTimezoneInputField,
  toggleTrackerEquipmentNumberInputField,
} from '../Actions/TrackerActions';

const initialTrackerLocationFilters = {
  settedFilter: undefined,
  offset: 0,
  limit: 5,
  gps: true,
  gsm: true,
  wifi: true,
  startTime: undefined,
  endTime: undefined,
};

const initialMapPosition = {
  longitude: undefined,
  latitude: undefined,
  accuracy: 0,
};

const initialGeneralLocationsEvent = {
  data: [],
  limit: 5,
  offset: 0,
  totalCount: 0,
  loading: true,
};

const initialtrackerLocationsEvent = {
  data: [],
  elapsedTime: null,
  mapPosition: { ...initialMapPosition },
  limit: 0,
  noOfObjects: 0,
  offset: 0,
  totalCount: 0,
  selectedEvent: '',
  loading: true,
  tabEventContext: 'eventList', // ['eventList','eventDetails']
  filters: { ...initialTrackerLocationFilters },
};

const initialState = {
  trackers: [],
  currentTracker: '',
  favoriteTrackers: [],
  lastViewedTracker: '',
  recentlyViewedTrackers: [],
  totalNumberOfTrackers: 0,
  trackersListFilter: 'all',
  areTrackersLoading: true,
  areMapUpdatesPaused: false,
  isCurrentTrackerLoading: false,
  isTrackerDetailsUpdating: false,
  isTrackerImageUploading: false,
  areTrackerSettingsLoading: false,
  areFeaturedTrackersLoading: true,
  areNewTrackerSettingsUpdating: false,
  areNewTrackerRawSettingsUpdating: false,
  filters: {},
  singleTrackerEvent: {},
  allTrackerEvents: { ...initialGeneralLocationsEvent },
  trackerLocationsEvent: { ...initialtrackerLocationsEvent },
  filteredTrackerList: {
    data: undefined,
    filters: {
      filters: [],
      sorters: [{
        dir: 'asc',
        name: 'name',
      }],
    },
    selectedFilter: 'all',
    limit: 5,
    offset: 0,
    totalCount: 0,
  },
  isSendingPeriodicDummyLocations: false,
};

export default handleActions({
  [logout]: () => ({ ...initialState }),
  // Add an event from the WebSocket
  [addTrackerEventOnWSAction]: (state, { payload: newEvent }) => {
    // Get existing list of events (initialise if necessary)
    const generalEvents = (state.allTrackerEvents.data && [...state.allTrackerEvents.data]) ?? [];

    // Add new event to the start of the list
    generalEvents.unshift(newEvent);

    // Only allow up to 50 event objects
    // TODO: update limit
    while (generalEvents.length > 50) {
      generalEvents.pop();
    }

    // Create modified list of events object
    const allTrackerEvents = {
      ...state.allTrackerEvents,
      data: generalEvents,
      totalCount: state.allTrackerEvents.totalCount + 1,
    };

    // Update system state
    return ({ ...state, allTrackerEvents });
  },
  // Add a location event from the WebSocket
  [addLocationTrackerEventOnWSAction]: (state, { payload: newEvent }) => {
    if (newEvent.objectType !== 'trackerEvent' || newEvent.type !== 'LOCATION') {
      return state;
    }

    // Get existing list of events (initialise if necessary)
    const locationEvents = (state.trackerLocationsEvent.data && [...state.trackerLocationsEvent.data]) ?? [];

    // Add new event to the start of the list
    locationEvents.unshift(newEvent);

    // Only allow up to 50 event objects
    // TODO: update limit
    while (locationEvents.length > 50) {
      locationEvents.pop();
    }

    // Create modified list of location events object
    const trackerLocationsEvent = {
      ...state.trackerLocationsEvent,
      data: locationEvents,
      totalCount: state.trackerLocationsEvent.totalCount + 1,
    };

    // Update system state
    return ({ ...state, trackerLocationsEvent });
  },
  [changeEventDetailsTabAction]: (state, { payload }) => (
    {
      ...state,
      ...{
        trackerLocationsEvent:
          { ...state.trackerLocationsEvent, ...{ tabEventContext: payload } },
      },
    }),
  [setMapPositionAction]: (state, { payload }) => (
    {
      ...state,
      ...{
        trackerLocationsEvent:
          { ...state.trackerLocationsEvent, ...{ mapPosition: payload } },
      },
    }),
  [setSelectedEventAction]: (state, { payload: selectedEvent }) => ({
    ...state,
    areMapUpdatesPaused: state.trackerLocationsEvent.selectedEvent.id
      ? selectedEvent.id !== state.trackerLocationsEvent.selectedEvent.id
      : true,
    trackerLocationsEvent: {
      ...state.trackerLocationsEvent,
      selectedEvent: selectedEvent.id !== state.trackerLocationsEvent.selectedEvent.id
        ? selectedEvent
        : '',
    },
  }),
  [setLoadingFilteredLocationTrackerAction]: (state, { payload }) => (
    {
      ...state,
      trackerLocationsEvent:
        { ...state.trackerLocationsEvent, loading: payload },
    }
  ),
  [resetFilteredLocationTrackerAction]: (state) => (
    {
      ...state,
      trackerLocationsEvent: { ...initialtrackerLocationsEvent },
    }
  ),
  [storeFilteredTrackerAction]: (state, { payload: locationEvents }) => {
    let { mapPosition } = state.trackerLocationsEvent;
    const data = LocationEventsHelper.cleanEventsFromAPI(Object.values(locationEvents.data));
    if (data.length > 0) {
      LocationEventsHelper.setSelected(data,
        { id: state.trackerLocationsEvent.selectedEvent.id });
      const selectedEvent = LocationEventsHelper.getSelectedEventObj(data);
      mapPosition = selectedEvent.details.position; // get the selected
    } else {
      mapPosition = { ...initialMapPosition };
    }
    const trackerLocationsEvent = {
      ...state.trackerLocationsEvent,
      ...locationEvents,
      data,
      mapPosition,
    };
    return ({ ...state, ...{ trackerLocationsEvent } });
  },
  [updateFilteredTrackerAction]: (state, { payload: locationEvent }) => {
    console.log(locationEvent);

    let locationData = [ ...state.trackerLocationsEvent.data ];
    let idx = locationData.findIndex((event) => event.id === locationEvent.id);

    locationData[idx] = locationEvent;

    return ({
      ...state,
      trackerLocationsEvent: {
        ...state.trackerLocationsEvent,
        data: locationData,
      }
    })
  },
  [updateLocationEventAction]: (state, { payload: locationEvents }) => (
    {
      ...state,
      ...{
        trackerLocationsEvent:
        { ...state.trackerLocationsEvent, ...{ data: locationEvents } },
      },
    }
  ),
  [pauseMapUpdates]: (state) => ({
    ...state,
    areMapUpdatesPaused: !state.areMapUpdatesPaused,
    trackerLocationsEvent: {
      ...state.trackerLocationsEvent,
      // Recenter map to the last received position
      mapPosition: state.trackerLocationsEvent.data[0].details.position,
      selectedEvent: !state.areMapUpdatesPaused ? state.trackerLocationsEvent.data[0] : '',
    },
  }),
  [storeLastEventAction]: (state, { payload }) => {
    const currentTracker = { ...state.currentTracker, lastEvent: payload };
    return { ...state, currentTracker };
  },
  [loadingEventListAction]: (state) => (
    {
      ...state,
      allTrackerEvents: {
        ...state.allTrackerEvents,
        loading: true,
        data: [],
      },
    }
  ),
  [changePageEventTrackerListAction]: (state, { payload }) => {
    const filters = state.allTrackerEvents;
    let offset = state.allTrackerEvents;
    if (payload === 'next') {
      offset = filters.offset + filters.limit;
    }
    if (payload === 'prev') {
      if (filters.offset - filters.limit < 0) {
        offset = 0;
      } else {
        offset = filters.offset - filters.limit;
      }
    }
    return ({ ...state, ...{ allTrackerEvents: { ...state.allTrackerEvents, offset } } });
  },
  [changeLimitEventTrackerListAction]: (state, { payload = 10 }) => (
    {
      ...state,
      allTrackerEvents: {
        ...state.allTrackerEvents,
        data: [],
        loading: false,
        limit: payload,
        offset: 0,
      },
    }
  ),
  [storeAllEventsTrackerAction]: (state, { payload: eventsData }) => (
    {
      ...state,
      allTrackerEvents:
        {
          ...state.allTrackerEvents,
          data: eventsData.data,
          totalCount: eventsData.totalCount,
          loading: false,
        },
    }
  ),
  [storeSingleTackerEventAction]: (state, { payload: singleTrackerEvent }) => (
    { ...state, singleTrackerEvent }
  ),
  [updateTrackerLocationFilterValue]: (state, { payload }) => {
    const newFiltersState = { ...state.trackerLocationsEvent.filters, ...payload };
    const trackerLocationsEvent = {
      ...state.trackerLocationsEvent,
      ...{ filters: newFiltersState },
    };
    return ({ ...state, ...{ trackerLocationsEvent } });
  },
  [changePageFilteredTrackerListAction]: (state, { payload }) => {
    const filters = { ...state.filteredTrackerList };
    let offset = state.allTrackerEvents;
    if (payload === 'next') {
      offset = filters.offset + filters.limit;
    }
    if (payload === 'prev') {
      if (filters.offset - filters.limit < 0) {
        offset = 0;
      } else {
        offset = filters.offset - filters.limit;
      }
    }
    return ({ ...state, ...{ filteredTrackerList: { ...state.filteredTrackerList, offset } } });
  },
  [changeLimitFilterTrackerListAction]: (state, { payload = 10 }) => (
    {
      ...state,
      filteredTrackerList:
          { ...state.filteredTrackerList, limit: payload, offset: 0 },
    }
  ),
  [loadingTrackers]: (state) => ({
    ...state,
    areTrackersLoading: true,
  }),
  [cancelSearchTrackers]: (state) => ({
    ...state,
    areTrackersLoading: false,
  }),
  [loadingFeaturedTrackers]: (state) => ({
    ...state,
    areFeaturedTrackersLoading: true,
  }),
  [updatingTracker]: (state, { payload: trackerId }) => ({
    ...state,
    trackers: state.trackers
      .map((tracker) => ({ ...tracker, updating: tracker.simiccid === trackerId })),
  }),
  [loadFeaturedTrackers]: (state, {
    payload: { favoriteTrackers, lastViewedTracker, recentlyViewedTrackers },
  }) => ({
    ...state,
    favoriteTrackers,
    lastViewedTracker,
    recentlyViewedTrackers,
    areFeaturedTrackersLoading: false,
  }),
  [loadPaginatedTrackers]: (state, { payload: { trackers, totalNumberOfTrackers } }) => ({
    ...state,
    trackers,
    totalNumberOfTrackers,
    areTrackersLoading: false,
  }),
  [loadTracker]: (state, { payload: currentTracker }) => ({
    ...state,
    currentTracker,
    isCurrentTrackerLoading: false,
  }),
  [currentTrackerLoading]: (state) => ({
    ...state,
    isCurrentTrackerLoading: true,
  }),
  [toggleTrackerNameInputField]: (state, { payload: showTrackerNameInputField }) => ({
    ...state,
    currentTracker: {
      ...state.currentTracker,
      showTrackerNameInputField,
    },
  }),
  [toggleTrackerEquipmentNumberInputField]: (state, {
    payload: showTrackerEquipmentNumberInputField,
  }) => ({
    ...state,
    currentTracker: {
      ...state.currentTracker,
      showTrackerEquipmentNumberInputField,
    },
  }),
  [toggleTrackerTimezoneInputField]: (state, { payload: showTrackerTimezoneInputField }) => ({
    ...state,
    currentTracker: {
      ...state.currentTracker,
      showTrackerTimezoneInputField,
    },
  }),
  [updatingTrackerName]: (state) => ({
    ...state,
    currentTracker: {
      ...state.currentTracker,
      isTrackerDetailsUpdating: true,
    },
  }),
  [updatingTrackerEquipmentNumber]: (state) => ({
    ...state,
    currentTracker: {
      ...state.currentTracker,
      isTrackerDetailsUpdating: true,
    },
  }),
  [updatingTrackerTimezone]: (state) => ({
    ...state,
    currentTracker: {
      ...state.currentTracker,
      isTrackerDetailsUpdating: true,
    },
  }),
  [addTrackerToFavourites]: (state, { payload: tracker }) => ({
    ...state,
    favoriteTrackers: [
      ...state.favoriteTrackers,
      {
        simiccid: tracker.simiccid,
        msisdn: tracker.msisdn,
        name: tracker.name,
      },
    ],
    trackers: state.trackers.map((track) => ({
      ...track,
      updating: false,
    })),
  }),
  [removeTrackerFromFavourites]: (state, { payload: trackerId }) => ({
    ...state,
    favoriteTrackers: state.favoriteTrackers.filter((tracker) => tracker.simiccid !== trackerId),
    trackers: state.trackers.map((tracker) => ({
      ...tracker,
      updating: false,
    })),
  }),
  [selectTrackerEvent]: (state, { payload: eventId }) => ({
    ...state,
    trackerLocationsEvent: {
      ...state.trackerLocationsEvent,
      selectedEvent: state.trackerLocationsEvent.data
        .find((event) => event.id === eventId),
    },
  }),
  [uploadingTrackerImage]: (state) => ({
    ...state,
    isTrackerImageUploading: true,
  }),
  [trackerImageUploaded]: (state, { payload: { updatedTrackerImages } }) => ({
    ...state,
    currentTracker: {
      ...state.currentTracker,
      image: updatedTrackerImages,
    },
    isTrackerImageUploading: false,
  }),
  [changeTrackersListFilter]: (state, { payload: { filterName } }) => ({
    ...state,
    trackersListFilter: filterName,
  }),
  [TrackerNameUpdated]: (state, { payload: { trackerId, newName } }) => ({
    ...state,
    currentTracker: {
      ...state.currentTracker,
      name: newName,
      isTrackerDetailsUpdating: false,
      showTrackerNameInputField: false,
    },
    favoriteTrackers: state.favoriteTrackers
      .map((tracker) => (tracker.simiccid === trackerId ? { ...tracker, name: newName } : tracker)),
    recentlyViewedTrackers: state.recentlyViewedTrackers
      .map((tracker) => (tracker.simiccid === trackerId ? { ...tracker, name: newName } : tracker)),
  }),
  [TrackerEquipmentNumberUpdated]: (state, { payload: { trackerId, newEquipmentNumber } }) => ({
    ...state,
    currentTracker: {
      ...state.currentTracker,
      equipmentNumber: newEquipmentNumber,
      isTrackerDetailsUpdating: false,
      showTrackerEquipmentNumberInputField: false,
    },
    favoriteTrackers: state.favoriteTrackers
      .map((tracker) => (tracker.simiccid === trackerId
        ? { ...tracker, equipmentNumber: newEquipmentNumber }
        : tracker)),
    recentlyViewedTrackers: state.recentlyViewedTrackers
      .map((tracker) => (tracker.simiccid === trackerId
        ? { ...tracker, equipmentNumber: newEquipmentNumber }
        : tracker)),
  }),
  [TrackerTimezoneUpdated]: (state, { payload: { trackerId, newTimezone } }) => ({
    ...state,
    currentTracker: {
      ...state.currentTracker,
      timezone: newTimezone,
      isTrackerDetailsUpdating: false,
      showTrackerTimezoneInputField: false,
    },
    favoriteTrackers: state.favoriteTrackers
      .map((tracker) => (tracker.simiccid === trackerId
        ? { ...tracker, timezone: newTimezone }
        : tracker)),
    recentlyViewedTrackers: state.recentlyViewedTrackers
      .map((tracker) => (tracker.simiccid === trackerId
        ? { ...tracker, timezone: newTimezone }
        : tracker)),
  }),
  [updatingTrackerSettings]: (state) => ({
    ...state,
    areNewTrackerSettingsUpdating: true,
  }),
  [updateTrackerSettings]: (state, { payload: { newSettings } }) => ({
    ...state,
    currentTracker: {
      ...state.currentTracker,
      newSettings,
    },
    areNewTrackerSettingsUpdating: false,
  }),
  [updatingTrackerRawSettings]: (state) => ({
    ...state,
    areNewTrackerRawSettingsUpdating: true,
  }),
  [updateTrackerRawSettings]: (state) => ({
    ...state,
    areNewTrackerRawSettingsUpdating: false,
  }),
  [loadingTrackerSettings]: (state) => ({
    ...state,
    areTrackerSettingsLoading: true,
  }),
  [loadTrackerSettings]: (state, { payload: { currentSettings, newSettings } }) => ({
    ...state,
    currentTracker: {
      ...state.currentTracker,
      currentSettings,
      newSettings,
    },
    areTrackerSettingsLoading: false,
  }),
  [updateSendingPeriodicDummyLocations]: (state, { payload: isEnabled }) => ({
    ...state,
    isSendingPeriodicDummyLocations: isEnabled,
  }),
}, initialState);
