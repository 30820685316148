import { NAV_TYPES } from "../../../Utils/NavTypes";

const DISTANCE_PER_DEGREE = 110000    // Wikipedia: one latitudinal degree is ~110km

export function isAreaPositionType(position) {
  return position.type === NAV_TYPES.GSM.id || position.type === NAV_TYPES.WIFI.id;
}

export function getBounds(positions) {
  let bounds = getBoundsPos(positions[0]);

  for (let i = 1; i < positions.length; i++) {
    let pos = getBoundsPos(positions[i]);

    bounds.north = Math.max(bounds.north, pos.north);
    bounds.south = Math.min(bounds.south, pos.south);
    bounds.east  = Math.max(bounds.east,  pos.east);
    bounds.west  = Math.min(bounds.west,  pos.west);
  }

  return bounds;
}

export function getBoundsPos(position) {
  if (!isAreaPositionType(position)) {
    return {
      north: position.pos.lat,
      south: position.pos.lat,
      east:  position.pos.lng,
      west:  position.pos.lng,
    };
  }
  else {
    let offset = position.accuracy / DISTANCE_PER_DEGREE;

    return {
      north: position.pos.lat + offset,
      south: position.pos.lat - offset,
      east:  position.pos.lng + offset,
      west:  position.pos.lng - offset,
    };
  }
}

export function convertEventToMapMarker(locationEvent) {
  if (locationEvent) {
    const { id, type, timestamp, details } = locationEvent;

    if (details && details.position && type === 'LOCATION') {
      return {
        id: id,
        type: details.position.method,
        timestamp: timestamp,
        pos: {
          lat: details.position.latitude,
          lng: details.position.longitude,
        },
        accuracy: details.position.accuracy,
        w3w: details.position.w3w,
        address: details.position.address,
      };
    }
  }

  return undefined;
}