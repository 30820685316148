import React from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import {
  Close as ClearIcon,
  Delete as DeleteIcon,
  NotificationsOff as NotificationDisabled,
  NotificationsActive as NotificationEnabled,
  Save as SaveIcon,
} from '@mui/icons-material';

import PaperSection from '../../../Components/PaperSection';
import VirtualisedTable from '../../../Components/VirtualisedTable/VirtualisedTable';
import ConfinedTypography from '../../../Components/Typographies/ConfinedTypography';

import {
  useWindowDimensions,
  getHalfPaperSectionWidth,
  TABLET_MIN_WIDTH,
} from '../../../../Utils/ScreenSize';
import { SCROLLBAR_WIDTH } from '../../../../Utils/Defines';
import { SORT_ORDERS } from '../../../../Utils/Sorting';

const getColumns = (totalWidth, onToggleEntry, onDeleteEntry) => {
  const COL_ICON = (totalWidth < TABLET_MIN_WIDTH) ? 30 : 40;
  const ICON_SIZE = (totalWidth < TABLET_MIN_WIDTH) ? 'small' : 'medium';

  const COL_PHONE = 170;
  const remainingWidth = totalWidth - COL_PHONE - (2 * COL_ICON) - SCROLLBAR_WIDTH;
  
  return [
    { width: remainingWidth * 0.3, label: "Filter", dataKey: "filter", render: ({ cellData, rowData }) =>
      <ConfinedTypography title disabled={!rowData.enabled}>{cellData}</ConfinedTypography>
    },
    { width: COL_PHONE, label: "Phone Number", dataKey: "phoneNumber", render: ({ cellData, rowData }) =>
      <ConfinedTypography disabled={!rowData.enabled}>{cellData}</ConfinedTypography>
    },
    { width: remainingWidth * 0.7, label: "Content", dataKey: "content", render: ({ cellData, rowData }) =>
      <ConfinedTypography title disabled={!rowData.enabled}>{cellData}</ConfinedTypography>
    },
    { width: COL_ICON, label: '', dataKey: 'enabled', noPadding: true, render: ({ cellData, rowIdx }) => (
      <IconButton size={ICON_SIZE} onClick={() => { onToggleEntry(rowIdx) }}>
        {cellData ? <NotificationEnabled fontSize={ICON_SIZE}/> : <NotificationDisabled fontSize={ICON_SIZE}/>}
      </IconButton>
    )},
    { width: COL_ICON, label: '', dataKey: 'removable', noPadding: true, render: ({ cellData, rowIdx }) => (cellData ? (
      <IconButton size={ICON_SIZE} onClick={() => { onDeleteEntry(rowIdx) }}>
        <DeleteIcon fontSize={ICON_SIZE}/>
      </IconButton>
    ) : <></>)},
  ];
}

const ForwardingSection = ({
  sx,
}) => {
  const theme = useTheme();

  const windowDimensions = useWindowDimensions();
  // const { isDesktopWidth } = windowDimensions;

  const [sortBy, setSortBy] = React.useState({
    column: 'phoneNumber',
    dir: SORT_ORDERS.asc.key,
  });

  const [newEntry, setNewEntry] = React.useState({
    filter: '',
    phoneNumber: '',
    content: '',
  });

  const [entries, setEntries] = React.useState([
    { filter: 'cheese', phoneNumber: '+44 1234 567890', content: 'This is example data', enabled: true, removable: false },
    { filter: 'cheese', phoneNumber: '+44 1234 567890', content: 'This example data is much much longer than all the other bits of example data just to see what happens to the spacing/layout when it gets this longer and longer', enabled: false, removable: false },
    { filter: 'cheese', phoneNumber: '+44 1234 567890', content: 'This is example data', enabled: false, removable: true },
  ]);

  const handleChange = (e) => {
    if (newEntry[e.target.name] !== e.target.value) {
      setNewEntry({
        ...newEntry,
        [e.target.name]: e.target.value,
      })
    }
  }

  const handleClickSave = () => {
    if (!validateInputs()) {
      return;
    }

    // TODO: add entry to list via API

    setEntries([
      ...entries,
      {
        ...newEntry,
        enabled: true,
        removable: true
      }
    ]);

    setNewEntry({
      filter: '',
      phoneNumber: '',
      content: '',
    });
  }

  const handleClickClear = () => {
    setNewEntry({
      filter: '',
      phoneNumber: '',
      content: '',
    });
  }

  const handleToggleEntry = (rowIdx) => {
    // TODO: update entry via API

    setEntries(entries.map((entry, idx) => {
      // Toggle 'enabled' state of specified entry
      if (idx === rowIdx) {
        return {
          ...entry,
          enabled: !entry.enabled
        };
      }
      else {
        return entry;
      }
    }));
  }

  const handleDeleteEntry = (rowIdx) => {
    // TODO: remove entry from list via API

    const entryToDelete = entries[rowIdx];

    setEntries(entries.filter((entry) => entry !== entryToDelete));
  }

  const PAPER_WIDTH = getHalfPaperSectionWidth(windowDimensions, theme);
  const columns = getColumns(PAPER_WIDTH, handleToggleEntry, handleDeleteEntry);
  
  const handleClickColumnHeader = ({ columnIndex }) => {
    // TODO: move generic handler to VirtualisedTable

    // Already sorting by this column -> toggle direction
    if (sortBy.column === columns[columnIndex].dataKey) {
      setSortBy({
        ...sortBy,
        dir: (sortBy.dir === SORT_ORDERS.asc.key) ? SORT_ORDERS.desc.key : SORT_ORDERS.asc.key
      });
    }
    // Otherwise switch to this column
    else {
      setSortBy({
        column: columns[columnIndex].dataKey,
        dir: SORT_ORDERS.asc.key
      });
    }
    
    // update data
  }

  const validateInputs = () => {
    // TODO: validate phone number?
    return newEntry.filter && newEntry.phoneNumber && newEntry.content;
  }
  
  const checkForAnyInputs = () => {
    return newEntry.filter || newEntry.phoneNumber || newEntry.content;
  }
  
  return (
    <PaperSection sx={{ ...sx, p: 0, overflow: 'hidden' }}>
      <Stack spacing={2} sx={{ p: 2 }}>
        <Typography variant="h6">Forwarding</Typography>

        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            name="filter"
            label="Filter"
            value={newEntry.filter}
            onChange={handleChange}
            sx={{ mr: 1, mt: 0 }}
          />
          <TextField
            name="phoneNumber"
            label="Phone Number"
            value={newEntry.phoneNumber}
            onChange={handleChange}
            sx={{ maxWidth: '300px', ml: 1, mt: 0 }}
          />
        </Box>
        
        <TextField
          multiline
          name="content"
          label="Content"
          rows={4}
          value={newEntry.content}
          onChange={handleChange}
        />

        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Button variant="contained" startIcon={<SaveIcon/>} onClick={handleClickSave} disabled={!validateInputs()} sx={{ ml: 'auto', mr: 1 }}>Save</Button>
          <Button variant="contained" startIcon={<ClearIcon/>} onClick={handleClickClear} disabled={!checkForAnyInputs()}>Clear</Button>
        </Box>
      </Stack>

      <Divider/>

      <Box sx={{ height: '100%', minHeight: '300px' }}>
        <VirtualisedTable
          hover
          hideButtons
          rowHeight={40}
          columns={columns}
          rowCount={entries.length}
          rowGetter={({ index }) => entries[index]}
          onColumnHeaderClick={handleClickColumnHeader}
          sortBy={sortBy}
        />
      </Box>
    </PaperSection>
  );
}

export default ForwardingSection;