import { createAction } from 'redux-actions';

const ENTITY = '@@TRACKER_NEW';

// Current Tracker
export const getTracker = createAction(`${ENTITY}/GET_TRACKER`);
export const updateTracker = createAction(`${ENTITY}/UPDATE_TRACKER`);

export const storeTracker = createAction(`${ENTITY}/STORE_TRACKER`);
export const setTrackerLoading = createAction(`${ENTITY}/SET_TRACKER_LOADING`);

// List of Trackers
export const getTrackers = createAction(`${ENTITY}/GET_TRACKERS`);

export const storeTrackers = createAction(`${ENTITY}/STORE_TRACKERS`);
export const setTrackersLoading = createAction(`${ENTITY}/SET_TRACKERS_LOADING`);

// Recently-Viewed & Bookmarked Trackers
export const getRecentAndBookmarkedTrackers = createAction(`${ENTITY}/GET_RECENT_AND_BOOKMARKED_TRACKERS`);
export const addTrackerToRecents = createAction(`${ENTITY}/ADD_TRACKER_TO_RECENTS`);
export const addTrackerToBookmarks = createAction(`${ENTITY}/ADD_TRACKER_TO_BOOKMARKS`);
export const removeTrackerFromBookmarks = createAction(`${ENTITY}/REMOVE_TRACKER_FROM_BOOKMARKS`);

export const storeRecentAndBookmarkedTrackers = createAction(`${ENTITY}/STORE_RECENT_AND_BOOKMARKED_TRACKERS`);
export const setRecentAndBookmarkedTrackersLoading = createAction(`${ENTITY}/SET_RECENT_AND_BOOKMARKED_TRACKERS_LOADING`);
export const addRecentTrackerToStore = createAction(`${ENTITY}/ADD_RECENT_TRACKER_TO_STORE`);
export const addBookmarkedTrackerToStore = createAction(`${ENTITY}/ADD_BOOKMARKED_TRACKER_TO_STORE`);
export const removeBookmarkedTrackerFromStore = createAction(`${ENTITY}/REMOVE_BOOKMARKED_TRACKER_FROM_STORE`);

// Personal Trackers
export const getCustomerTrackers = createAction(`${ENTITY}/GET_CUSTOMER_TRACKERS`);

export const storeCustomerTrackers = createAction(`${ENTITY}/STORE_CUSTOMER_TRACKERS`);
export const setCustomerTrackersLoading = createAction(`${ENTITY}/SET_CUSTOMER_LOADING`);

// Asset
export const updateAsset = createAction(`${ENTITY}/UPDATE_ASSET`);
export const updateAssetImage = createAction(`${ENTITY}/UPDATE_ASSET_IMAGE`);

export const storeAssetDetails = createAction(`${ENTITY}/STORE_ASSET_DETAILS`);
export const storeAssetImage = createAction(`${ENTITY}/STORE_ASSET_IMAGE`);

// Settings
export const setTrackerState = createAction(`${ENTITY}/SET_TRACKER_STATE`);
export const getTrackerSettings = createAction(`${ENTITY}/GET_TRACKER_SETTINGS`);
export const setTrackerSettings = createAction(`${ENTITY}/SET_TRACKER_SETTINGS`);
export const sendRawTrackerSettings = createAction(`${ENTITY}/SEND_RAW_TRACKER_SETTINGS`);

export const storeTrackerSettings = createAction(`${ENTITY}/STORE_TRACKER_SETTINGS`);
export const setTrackerSettingsLoading = createAction(`${ENTITY}/SET_TRACKER_SETTINGS_LOADING`);

// Zones
// TODO: this will presumably be merged into trackers once the API supports zones
export const setZones = createAction(`${ENTITY}/SET_ZONES`);
export const setHighlightedZone = createAction(`${ENTITY}/SET_HIGHLIGHTED_ZONE`);
export const setShowZones = createAction(`${ENTITY}/SET_SHOW_ZONES`);
export const setNewZone = createAction(`${ENTITY}/SET_NEW_ZONE`);
