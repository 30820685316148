import React from 'react';

import {
  MobileScreenShare as AppIcon,
  Email as EmailIcon,
  Sms as SmsIcon,
  WhatsApp as WhatsAppIcon,
} from '@mui/icons-material';

export const METHODS = {
  sms: { id: 'sms', label: 'SMS', icon: <SmsIcon/> },
  email: { id: 'email', label: 'Email', icon: <EmailIcon/> },
  whatsApp: { id: 'whatsApp', label: 'WhatsApp', icon: <WhatsAppIcon/> },
  app: { id: 'app', label: 'App', icon: <AppIcon/> },
};

export const METHODS_ARRAY = [
  METHODS.sms,
  METHODS.email,
  METHODS.whatsApp,
  METHODS.app,
];