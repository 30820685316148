import {
  call,
  // put,
  // select,
  takeLatest,
} from "redux-saga/effects";

import {
  checkVersion
} from '../Actions/system';

// import { addNotification } from '../Actions/system';
// import { authApiHandler } from './auth';

import BaseAPI from '../../Api/Endpoints/base';
// import SystemAPI from '../../Api/Endpoints/system';
import { ResponseTypes } from '../../Api/Responses/baseResponse';
import packageJson from '../../../package.json';

export function* handleCheckVersion() {
  const response = yield call(BaseAPI.currentVersion);

  // If a newer version (later build timestamp) is found -> clear the service worker and refresh the page
  // TODO: refreshing without a Service Worker will display an error unless at the base URL - FIX!
  if ((ResponseTypes.ActionCompleted === response.type) && (response.data.buildDate > packageJson.buildDate)) {
    console.log({
      oldVersion: packageJson.buildDate,
      newVersion: response.data.buildDate,
    }, 'New version found');

    if ('caches' in window) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        names.forEach((name) => caches.delete(name));
      });
    }

    // Delete browser cache and hard reload
    window.location.reload();
  }
}

export default function* Sagas() {
  yield takeLatest(checkVersion, handleCheckVersion);
}