import { createAction } from 'redux-actions';

const ENTITY = '@@TRACKER';

// Tracker
export const getTracker = createAction(`${ENTITY}/GET_TRACKER`);
export const loadTracker = createAction(`${ENTITY}/LOAD_TRACKER`);
export const currentTrackerLoading = createAction(`${ENTITY}/CURRENT_TRACKER_LOADING`);
export const updatingTracker = createAction(`${ENTITY}/UPDATING_TRACKER`);
export const updateTrackerName = createAction(`${ENTITY}/UPDATE_TRACKER_NAME`);
export const toggleTrackerNameInputField = createAction(`${ENTITY}/TOGGLE_TRACKER_NAME_INPUT_FIELD`);
export const updatingTrackerName = createAction(`${ENTITY}/TRACKER_NAME_UPDATING`);
export const TrackerNameUpdated = createAction(`${ENTITY}/TRACKER_NAME_UPDATED`);
export const updateTrackerEquipmentNumber = createAction(`${ENTITY}/UPDATE_TRACKER_EQUIPMENT_NUMBER`);
export const toggleTrackerEquipmentNumberInputField = createAction(`${ENTITY}/TOGGLE_TRACKER_EQUIPMENT_NUMBER_INPUT_FIELD`);
export const updatingTrackerEquipmentNumber = createAction(`${ENTITY}/UPDATING_TRACKER_EQUIPMENT_NUMBER`);
export const TrackerEquipmentNumberUpdated = createAction(`${ENTITY}/TRACKER_EQUIPMENT_NUMBER_UPDATED`);
export const updateTrackerTimezone = createAction(`${ENTITY}/UPDATE_TRACKER_TIMEZONE`);
export const toggleTrackerTimezoneInputField = createAction(`${ENTITY}/TOGGLE_TRACKER_TIMEZONE_INPUT_FIELD`);
export const updatingTrackerTimezone = createAction(`${ENTITY}/UPDATING_TRACKER_TIMEZONE`);
export const TrackerTimezoneUpdated = createAction(`${ENTITY}/TRACKER_TIMEZONE_UPDATED`);

// Tracker Asset
export const uploadTrackerImage = createAction(`${ENTITY}/UPLOAD_TRACKER_IMAGE`);
export const uploadingTrackerImage = createAction(`${ENTITY}/UPLOADING_TRACKER_IMAGE`);
export const trackerImageUploaded = createAction(`${ENTITY}/TRACKER_IMAGE_UPLOADED`);
export const getTrackerImage = createAction(`${ENTITY}/GET_TRACKER_IMAGE`);
export const updateTrackerAsset = createAction(`${ENTITY}/UPDATE_TRACKER_ASSET`);

// Trackers
export const searchTrackers = createAction(`${ENTITY}/SEARCH_TRACKERS`);
export const loadingTrackers = createAction(`${ENTITY}/LOADING_TRACKERS`);
export const cancelSearchTrackers = createAction(`${ENTITY}/CANCEL_SEARCH_TRACKERS`);
export const getPaginatedTrackers = createAction(`${ENTITY}/GET_PAGINATED_TRACKERS`);
export const loadPaginatedTrackers = createAction(`${ENTITY}/LOAD_PAGINATED_TRACKERS`);
export const changeTrackersListFilter = createAction(`${ENTITY}/CHANGE_TRACKERS_LIST_FILTER`);

// Featured Trackers
export const getFeaturedTrackers = createAction(`${ENTITY}/GET_FEATURED_TRACKERS`);
export const loadingFeaturedTrackers = createAction(`${ENTITY}/LOADING_FEATURED_TRACKERS`);
export const loadFeaturedTrackers = createAction(`${ENTITY}/LOAD_FEATURED_TRACKERS`);
export const addTrackerToFavouritesReq = createAction(`${ENTITY}/ADD_TRACKER_TO_FAVORITES_REQUEST`);
export const removeTrackerFromFavouritesReq = createAction(`${ENTITY}/REMOVE_TRACKER_FROM_FAVORITES_REQUEST`);
export const addTrackerToFavourites = createAction(`${ENTITY}/ADD_TRACKER_TO_FAVORITES`);
export const removeTrackerFromFavourites = createAction(`${ENTITY}/REMOVE_TRACKER_FROM_FAVORITES`);
export const markTrackerAsRecentlyViewed = createAction(`${ENTITY}/MARK_TRACKER_AS_RECENTLY_VIEWED`);

// Trackers Quick Filters
export const changePageFilteredTrackerListAction = createAction(`${ENTITY}/CHANGE_PAGE_TRACKER_FILTERED_LIST_REQUEST`);
export const changeLimitFilterTrackerListAction = createAction(`${ENTITY}/CHANGE_LIMIT_FILTERED_TRACKER_LIST`);

// Trackers Events
export const selectTrackerEvent = createAction(`${ENTITY}/SELECT_TRACKER_EVENT`);
export const pauseMapUpdates = createAction(`${ENTITY}/PAUSE_MAP_UPDATES`);
export const setMapPositionAction = createAction(`${ENTITY}/SET_TRACKER_MAP_POSITION`);
export const receivedMessageTrackerOnWS = createAction(`${ENTITY}/TRACKER_MSG_WEBSOCKET`);
export const addLocationTrackerEventOnWSAction = createAction(`${ENTITY}/ADD_LOCATION_TRACKER_EVENT_WS`);
export const addTrackerEventOnWSAction = createAction(`${ENTITY}/ADD_TRACKER_EVENT_WS`);
export const selectLocationEventAction = createAction(`${ENTITY}/SELECT_LOCATION_EVENT`);
export const updateLocationEventAction = createAction(`${ENTITY}/UPDATE_LOCATION_EVENTS`);
export const setSelectedEventAction = createAction(`${ENTITY}/SET_SELECTED_EVENT_ACTION`);

export const registerTrackerEvent = createAction(`${ENTITY}/REGISTER_TRACKER_EVENT`);
export const sendPeriodicDummyLocations = createAction(`${ENTITY}/SEND_PERIODIC_DUMMY_LOCATIONS`);
export const updateSendingPeriodicDummyLocations = createAction(`${ENTITY}/UPDATE_SENDING_PERIODIC_DUMMY_LOCATIONS`);

// GPS, GSM and WIFI Tracker Events Filters
export const getFilteredTrackerAction = createAction(`${ENTITY}/REQUEST_FILTER_LOCATIONS_TRACKER`);
export const setLoadingFilteredLocationTrackerAction = createAction(`${ENTITY}/SET_LOADING_FILTER_LOCATIONS_TRACKER`);
export const resetFilteredLocationTrackerAction = createAction(`${ENTITY}/RESET_FILTER_LOCATIONS_TRACKER`);
export const storeFilteredTrackerAction = createAction(`${ENTITY}/STORE_FILTER_LOCATIONS_TRACKER`);
export const updateFilteredTrackerAction = createAction(`${ENTITY}/UPDATE_FILTER_LOCATIONS_TRACKER`);
export const setTrackerLocationFilter = createAction(`${ENTITY}/SET_TRACKER_LOCATION_FILTER`);
export const updateTrackerLocationFilterValue = createAction(`${ENTITY}/UPDATE_TRACKER_LOCATION_FILTER`);
export const getLocationEventAddress = createAction(`${ENTITY}/GET_LOCATION_EVENT_ADDRESS`);

// Tracker Events List
export const changeEventDetailsTabAction = createAction(`${ENTITY}/CHANGE_EVENT_DETAILS_TAB`);

// Tracker Event Modal
export const storeSingleTackerEventAction = createAction(`${ENTITY}/STORE_SINGLE_TRACKER_EVENT`);

// Tracker Settings
export const getTrackerSettings = createAction(`${ENTITY}/GET_TRACKER_SETTINGS`);
export const loadingTrackerSettings = createAction(`${ENTITY}/LOADING_TRACKER_SETTINGS`);
export const loadTrackerSettings = createAction(`${ENTITY}/LOAD_TRACKER_SETTINGS`);
export const updateTrackerSettingsRequest = createAction(`${ENTITY}/UPDATE_TRACKER_SETTINGS_REQUEST`);
export const updatingTrackerSettings = createAction(`${ENTITY}/UPDATING_TRACKER_SETTINGS`);
export const updateTrackerSettings = createAction(`${ENTITY}/UPDATED_TRACKER_SETTINGS`);
export const updateTrackerRawSettingsRequest = createAction(`${ENTITY}/UPDATE_TRACKER_RAW_SETTINGS_REQUEST`);
export const updatingTrackerRawSettings = createAction(`${ENTITY}/UPDATING_TRACKER_RAW_SETTINGS`);
export const updateTrackerRawSettings = createAction(`${ENTITY}/UPDATED_TRACKER_RAW_SETTINGS`);
