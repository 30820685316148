import React from 'react';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { useTheme, styled } from '@mui/material/styles';

import {
  Box,
  Button,
  Divider,
  Paper,
  Typography,
} from '@mui/material';

import VirtualisedTable from '../Components/VirtualisedTable/VirtualisedTable';
import CustomPagination from '../Components/VirtualisedTable/CustomPagination';
import ResultsCounter from '../Components/VirtualisedTable/ResultsCounter';
import FilterButton_OLD from '../Components/Filter/FilterButton';

import { useWindowDimensions, getFullPaperSectionWidth } from '../../Utils/ScreenSize';
import { SORT_ORDERS } from '../../Utils/Sorting';
import { isEmptyObject } from '../../Utils/Utils';
import { getColumns } from './columns';

import withSystemActions from '../../redux/WithActions/system';

const customers = [
  { id: 0, name: 'Morgan IAT', franchise: 'Automatics UK', units: 100 },
  { id: 1, name: 'Transport for London', franchise: 'Automatics UK', units: 101 },
  { id: 2, name: 'John Smith', franchise: 'Automatics UK', units: 8 },
  { id: 3, name: 'Mr Plow', franchise: 'Automatics US', units: 16 },
  { id: 4, name: 'Kwik-e-Mart', franchise: 'Automatics US', units: 104 },
];

const totalCustomers = Math.min(5, customers.length);

const ROWS_PER_PAGE_OPTIONS = [50, 200, 1000];

const renderRow = ({
  className,
  columns,
  index,
  key,
  rowData,
  style,
}) => {
  const a11yProps = {'aria-rowindex': index + 1};

  a11yProps['aria-label'] = 'row';
  a11yProps.tabIndex = 0;

  const Row = styled('div')(({ theme }) => ({
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  }));

  return (
    <Link to={`/customers/${rowData.id}`} target="_blank" rel="noopener noreferrer" key={key}>
      <Row
        {...a11yProps}
        className={className}
        key={key}
        role="row"
        style={style}>
        {columns}
      </Row>
    </Link>
  );
}

const CustomerSearch = ({
  setShowSubHeader,
}) => {
  const theme = useTheme();

  const windowDimensions = useWindowDimensions();
  const { isMobileWidth } = windowDimensions;

  const [values, setValues] = React.useState({
    page: 0,
    rowsPerPage: 50,
    filters: {},
    sortBy: {
      column: 'name',
      dir: SORT_ORDERS.asc.key,
    },
  });

  // Set the browser tab's title
  document.title = 'Customers - MTrack';
  
  // Clear 'showSubHeader' so the main header renders correctly
  React.useEffect(() => { setShowSubHeader(false); }, [ setShowSubHeader ]);

  const TABLE_WIDTH = getFullPaperSectionWidth(windowDimensions, theme);
  const COLUMNS = getColumns(TABLE_WIDTH);
  
  // const { trackers, totalTrackers } = getTrackersApiCall({
  //   startIndex: values.page * values.rowsPerPage,
  //   stopIndex: (values.page + 1) * values.rowsPerPage - 1,
  //   filters: values.filters,
  //   sortBy: values.sortBy,
  // });

  // Row count == rows per page, EXCEPT on the last page
  const rowCount = values.page + 1 < Math.ceil(totalCustomers / values.rowsPerPage)
    ? values.rowsPerPage
    : ((totalCustomers - 1) % values.rowsPerPage) + 1;

  const handleRefreshClick = () => {
    console.log('Refresh!');
    
    // TODO: Update data
  }
  
  const handleClickClearFilter = () => {
    setValues({
      ...values,
      filters: {}
    });
    
    // TODO: Update data
  }

  const handleColumnHeaderClick = ({ columnIndex }) => {
    // Already sorting by this column -> toggle direction
    if (values.sortBy.column === COLUMNS[columnIndex].dataKey) {
      setValues({
        ...values,
        sortBy: {
          ...values.sortBy,
          dir: (values.sortBy.dir === SORT_ORDERS.asc) ? SORT_ORDERS.desc.key : SORT_ORDERS.asc.key
        }
      });
    }
    // Otherwise switch to this column
    else {
      setValues({
        ...values,
        sortBy: {
          column: COLUMNS[columnIndex].dataKey,
          dir: SORT_ORDERS.asc.key
        }
      });
    }
    
    // TODO: Update data
  }

  const handleSearch = ({ count, filters }) => {
    setValues({
      ...values,
      page: 0,
      rowsPerPage: count,
      filters: filters
    });
  }

  const handlePageChange = (newPage) => {
    setValues({
      ...values,
      page: newPage
    });
  }

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setValues({
      ...values,
      page: 0,
      rowsPerPage: newRowsPerPage
    });
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {/* {header} */}
      <FilterButton_OLD sx={{ m: 2 }}/>

      <Paper sx={theme => ({
        display: 'table',
        height: '100%',
        [theme.breakpoints.up('desktop')]: {
          margin: 2,
          marginTop: 0,
          // overflow: 'hidden',
        },
        [theme.breakpoints.down('desktop')]: {
          borderRadius: 0,
        },
      })}>
        <Box sx={{ display: 'table-row', height: '100%', overflow: 'hidden' }}>
          <VirtualisedTable
            hover
            rowHeight={56}
            fontSize="1.125rem"
            columns={COLUMNS}
            rowCount={rowCount}
            rowGetter={({ index }) => customers[index]}
            rowRenderer={renderRow}
            sortBy={values.sortBy}
            onColumnHeaderClick={handleColumnHeaderClick}
          />
        </Box>

        <Box sx={{ display: 'table-row', height: 0 }}>
          <Divider/>

          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <ResultsCounter
              rowCount={rowCount}
              totalCount={totalCustomers}
              sx={{ m: 1, ml: 2 }}
            />
            <CustomPagination
              page={values.page}
              count={totalCustomers}
              rowsPerPage={values.rowsPerPage}
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              sx={{ m: 'auto', mr: 1 }}
            />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default compose(withSystemActions)(CustomerSearch);