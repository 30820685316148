import React from 'react';
import { Modal, IconButton } from '@mui/material';
import { Cancel } from '@mui/icons-material';

const CustomModal = ({
  title = 'Simple Modal',
  className = '',
  body,
  openModal = false,
  handleClose = () => {},
}) => {
  const content = (
    <div className="modal-content">
      <div className="header">
        <h2 className="modal-title">
          {title}
        </h2>
        <IconButton onClick={handleClose} className="close-btn" size="large">
          <Cancel fontSize="large" />
        </IconButton>
      </div>
      <div className="body">
        {body()}
      </div>
    </div>
  );
  return (
    <Modal
      className={`modal-dialog ${className}`}
      open={openModal}
      onClose={handleClose}

    >
      {content}
    </Modal>
  );
};
export default CustomModal;
