import {
  put,
  takeLatest,
} from 'redux-saga/effects';

import {
  editCustomer,
  getCustomer,
  loadNewCustomer,
  getAllCustomers,
  editingCustomer,
  loadAllCustomers,
  createNewCustomer,
  cancelEditCustomer,
  editCustomerRequest,
  loadingAllCustomers,
  creatingNewCustomer,
  loadNewCustomerSecret,
  stopLoadingAllCustomers,
  generateNewCustomerSecret,
  generatingNewCustomerSecret,
} from '../Actions/CustomerActions';
import { authApiHandler } from './auth';
import { profileDetails } from './selectors';
import CustomersAPI from '../../Api/Endpoints_OLD/customer';
import { addNotification } from '../Actions/system';
import { sortArrayAlphabetically } from '../../Utils/Utils';
import { ResponseTypes } from '../../Api/Responses/baseResponse';

export function* handleGetAllCustomers() {
  yield put(loadingAllCustomers());

  const { franchise } = profileDetails;
  const response = yield authApiHandler(CustomersAPI.getCustomers, franchise);

  if (response.type === ResponseTypes.ActionCompleted) {
    const customers = sortArrayAlphabetically(response.data, 'name');
    yield put(loadAllCustomers({ customers }));
  } else if (response.type === ResponseTypes.Unauthorized) {
    yield put(stopLoadingAllCustomers());
    yield put(addNotification({
      type: 'error',
      title: 'Could not retreive customers',
      source: 'handleGetAllCustomers',
      message: 'Your account permissions do not allow us to retrieve this content for you.',
    }));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(stopLoadingAllCustomers());
    yield put(addNotification({
      type: 'error',
      title: 'Could not retreive customers',
      source: 'handleGetAllCustomers',
      message: 'Please, check your connection and try again.',
    }));
  }
}

export function* handleGetCustomer({ payload: { customerId } }) {
  yield put(loadingAllCustomers());
  const response = yield authApiHandler(CustomersAPI.getCustomer, customerId);
  if (response.type === ResponseTypes.ActionCompleted) {
    yield put(loadAllCustomers({ customers: [response.data] }));
  } else if (response.type === ResponseTypes.Unauthorized) {
    yield put(stopLoadingAllCustomers());
    yield put(addNotification({
      type: 'error',
      source: 'handleGetCustomer',
      title: 'Could not retreive customer',
      message: 'Your account permissions do not allow us to retrieve this content for you.',
    }));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(stopLoadingAllCustomers());
    yield put(addNotification({
      type: 'error',
      source: 'handleGetCustomer',
      title: 'Could not retreive customer',
      message: 'Please, check your connection and try again.',
    }));
  }
}

export function* handleCreateNewCustomer({ payload: { customerDetails } }) {
  yield put(creatingNewCustomer());
  const response = yield authApiHandler(CustomersAPI.addCustomer, customerDetails);
  if (response.type === ResponseTypes.ActionCompleted) {
    const customer = response.data;
    yield put(loadNewCustomer(customer));
    yield put(addNotification({
      type: 'success',
      title: 'Customer successfully created!',
      source: 'handleCreateNewCustomer',
      message: `${customerDetails.companyName || customerDetails.name} has been successfully created.`,
    }));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not add new customer',
      source: 'handleCreateNewCustomer',
      message: 'Please, check your connection and try again.',
    }));
  }
}

function* handleEditCustomerRequest({ payload: { updatedCustomerDetails } }) {
  yield put(editingCustomer());
  const response = yield authApiHandler(CustomersAPI.editCustomer, updatedCustomerDetails);

  if (response.type === ResponseTypes.ActionCompleted) {
    yield put(editCustomer({ updatedCustomer: response.data }));
    yield put(addNotification({
      type: 'success',
      title: 'Customer successfully updated!',
      source: 'handleEditCustomerRequest',
      message: `${updatedCustomerDetails.companyName || updatedCustomerDetails.name} has been successfully updated.`,
    }));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(cancelEditCustomer());
    yield put(addNotification({
      type: 'error',
      title: 'Could not update customer',
      source: 'handleEditCustomerRequest',
      message: 'Please, check your connection and try again.',
    }));
  }
}

function* handleGenerateNewCustomerSecret() {
  yield put(generatingNewCustomerSecret());
  const response = yield authApiHandler(CustomersAPI.generateNewCustomerSecret);

  if (response.type === ResponseTypes.ActionCompleted) {
    yield put(loadNewCustomerSecret({ newCustomerSecret: response.data.secrets[0] }));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not generate new secret',
      source: 'handleGenerateNewCustomerSecret',
      message: 'Please, check your connection and try again.',
    }));
  }
}

export default function* CustomerSagas() {
  yield takeLatest(getCustomer, handleGetCustomer);
  yield takeLatest(getAllCustomers, handleGetAllCustomers);
  yield takeLatest(createNewCustomer, handleCreateNewCustomer);
  yield takeLatest(editCustomerRequest, handleEditCustomerRequest);
  yield takeLatest(generateNewCustomerSecret, handleGenerateNewCustomerSecret);
}
