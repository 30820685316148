import makeStyles from '@mui/styles/makeStyles';

import { mobile } from '../../../Styles/styles';

const trackersManagerStyles = makeStyles((theme) => ({
  trackerManagerContainer: {
    maxWidth: 'unset',
    padding: '24px',
  },
  trackerListHeaderContainer: {
    display: 'flex',
    padding: 'unset',
    maxWidth: 'unset',
    marginBottom: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  trackerListHeader: {
    fontSize: '34px',
    marginBottom: '20px',
    color: 'rgba(0, 0, 0, 0.87)',
    [theme.breakpoints.down(mobile)]: {
      textAlign: 'center',
      width: '100%',
    },
  },
  trackerListActionsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down(mobile)]: {
      flexDirection: 'column',
    },
  },
  trackersListTableContainer: {
    justifyContent: 'center',
    maxWidth: 'unset',
    padding: 'unset',
    display: 'flex',
  },
}));

export default trackersManagerStyles;
