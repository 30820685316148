import { connect } from 'react-redux';

import {
  getCustomer,
  editCustomer,
  getAllCustomers,
  loadNewCustomer,
  editingCustomer,
  loadAllCustomers,
  createNewCustomer,
  cancelEditCustomer,
  loadingAllCustomers,
  creatingNewCustomer,
  editCustomerRequest,
  resetCustomerSecret,
  generateNewCustomerSecret,

} from '../../redux/Actions/CustomerActions';

export default (WrappedComponent) => connect(
  null, {
    getCustomer,
    editCustomer,
    getAllCustomers,
    loadNewCustomer,
    editingCustomer,
    loadAllCustomers,
    createNewCustomer,
    cancelEditCustomer,
    loadingAllCustomers,
    creatingNewCustomer,
    editCustomerRequest,
    resetCustomerSecret,
    generateNewCustomerSecret,
  },
)(WrappedComponent);
