import React from 'react';

import { Button } from '@mui/material';

const SubmitButtons = ({
  areNewTrackerRawSettingsUpdating,
  updateTrackerRawSettingsRequest,
  setNewTrackerRawSettings,
  newTrackerRawSettings,
  currentTracker,
  classes,
}) => {
  const handleClearTrackerRawSettings = () => {
    setNewTrackerRawSettings('');
  };

  const handleSaveTrackerRawSettings = () => {
    updateTrackerRawSettingsRequest({
      trackerId: currentTracker.simiccid,
      rawSettings: newTrackerRawSettings,
    });

    //setNewTrackerRawSettings('');
  };

  return (
    <div className={classes.trackerSettingsButtonsContainer}>
      <Button
        onClick={handleClearTrackerRawSettings}
        className={classes.trackerSettingsCancelButton}
        disabled={!newTrackerRawSettings}
      >
        Clear
      </Button>
      <Button
        type="submit"
        onClick={handleSaveTrackerRawSettings}
        className={classes.trackerSettingsSaveButton}
        disabled={areNewTrackerRawSettingsUpdating || !newTrackerRawSettings}
      >
        {areNewTrackerRawSettingsUpdating ? 'Sending...' : 'Send'}
      </Button>
    </div>
  );
};

export default SubmitButtons;
