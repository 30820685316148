import { connect } from 'react-redux';

const mapStateToProps = ({
  FoldersData: {
    mode,
    selectAll,
    isLoading,
    isMovingItems,
    selectedItems,
    currentFolder,
    rootLevelFolders,
    totalNumberOfItems,
    numberOfItemsLoaded,
    arePermissionsCascadedToSubfolders,
  },
}) => ({
  mode,
  selectAll,
  isLoading,
  isMovingItems,
  selectedItems,
  currentFolder,
  rootLevelFolders,
  totalNumberOfItems,
  numberOfItemsLoaded,
  arePermissionsCascadedToSubfolders,
});

export default (WrappedComponent) => connect(mapStateToProps, null)(WrappedComponent);
