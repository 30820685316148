import React from 'react';
import { compose } from 'redux';

import { Link } from 'react-router-dom';

import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
} from '@mui/material';

import {
  Menu as MenuIcon,
} from '@mui/icons-material/';

import ProfileMenu from './ProfileMenu';

import { ReactComponent as LogoColour } from '../../../Assets/SVGs/mtrack_logo.svg';
import { ReactComponent as LogoWhite } from '../../../Assets/SVGs/mtrack_logo_white.svg';

import withSystemData from '../../../redux/WithData/system';

/**
 * TODO:
 * - Remove primary colour
 * - Toggle shadow & border based on whether secondary header is visible (use 'store')
 */

const CustomAppBar = ({
  userData,
  logout,
  barHeight,
  handleOpenDrawer,
  handleCloseDrawer,

  appTheme,
  showingSubHeader,
}) => {
  return (
    <AppBar
      color="transparent"
      sx={theme => ({
        backgroundColor: theme.palette.background.header,
        height: `${barHeight}px`,
        position: 'fixed',
        transition: 'none',
        [theme.breakpoints.down('tablet')]: {
          // Hide app bar on scroll (mobile only)
          position: 'relative',
        },
        ...(showingSubHeader && {
          boxShadow: 'none',
          borderBottom: `1px solid ${theme.palette.divider}`,
        }),
      })}>
      <Toolbar variant="dense" sx={{ margin: 'auto 0' }}>
        <IconButton
          edge="start"
          color="inherit"
          size="large"
          aria-label="toggle menu"
          onClick={handleOpenDrawer}
        >
          <MenuIcon/>
        </IconButton>
        <Button
          edge="start"
          color="inherit"
          size="large"
          sx={{ p: '4px 8px' }}>
          <Link to="/" key="logo" style={{ display: 'flex' }}>
            {appTheme === 'light' ? (
              <LogoColour width={undefined} height={`${barHeight * 0.75}px`} />
            ) : (
              <LogoWhite width={undefined} height={`${barHeight * 0.75}px`} />
            )}
          </Link>
        </Button>
        <ProfileMenu
          edge="end"
          color="inherit"
          size="large"
          userData={userData}
          barHeight={barHeight}
          handleCloseDrawer={handleCloseDrawer}
          handleLogout={logout} />
      </Toolbar>
    </AppBar>
  )
};

export default compose(withSystemData)(CustomAppBar);
