import React from 'react';

import {
  Typography,
} from '@mui/material';

import PaperSection from '../../../Components/PaperSection';
import StateSelector from '../../../Components/StateNEW/StateSelector';

import { STATES_ARRAY } from '../../../../Utils/States';

const StateSection = ({
  sx,
}) => {
  return (
    <PaperSection sx={{ ...sx, overflow: 'auto' }}>
      <Typography variant="h6" sx={{ mb: 2 }}>State</Typography>

      <StateSelector sx={{ ml: 'auto', mr: 'auto' }}/>
    </PaperSection>
  );
}

export default StateSection;