import { connect } from 'react-redux';
import {
  getFilteredTrackerAction,
  setLoadingFilteredLocationTrackerAction,
  getLocationEventAddress,
} from '../../../redux/Actions/TrackerActions';
import {
  loadingEventListAction,
  getAllEventsTrackerAction,
} from '../../../redux/Actions/EventActions';

export default (WrappedComponent) => connect(
  null, {
    getFilteredLocationTrackerAction: (initialValue) => getFilteredTrackerAction(initialValue),
    setLoadingFilteredLocationTracker: (value) => setLoadingFilteredLocationTrackerAction(value),
    getAllEventsAction: ({
      simiccid, startTime = '', endTime = '', limit = 50, offset = 0,
    }) => getAllEventsTrackerAction({
      simiccid, startTime, endTime, limit, offset,
    }),
    setLoadingAllEventAction: (status = true) => loadingEventListAction(status),
    getTrackerLocationEventAddress: (eventId) => getLocationEventAddress(eventId),
  },
)(WrappedComponent);
