import { compose } from 'redux';
import React from 'react';

import TrackerTabs from '../Tabs';
import trackerDetailsStyles from './style';
import TrackerImage from '../Elements/trackerImage';
import TrackerSelector from '../Elements/TrackerSelector';
import FavoriteTrackerButton from './FavoriteTrackerButton';
import withTrackersData from '../../../Hocs/WithData/tracker';

const TrackerDetails = ({ enabledTab, currentTracker, selectTracker }) => {
  const classes = trackerDetailsStyles();

  return (
    <>
      <div className={classes.trackerDetailsContainer}>
        <div className={classes.trackerDetailsActionsContainer}>
          <TrackerSelector selectTracker={selectTracker} />
          <FavoriteTrackerButton tracker={currentTracker} />
        </div>
        <TrackerImage tracker={currentTracker} />
      </div>
      <TrackerTabs trackerId={currentTracker.simiccid} enabledTab={enabledTab} />
    </>
  );
};

export default compose(withTrackersData)(TrackerDetails);
