import { createAction } from 'redux-actions';

const ENTITY = '@@FRANCHISE';

// Get Franchise
export const getFranchise = createAction(`${ENTITY}/GET_FRANCHISE`);

// Get Franchises
export const getAllFranchises = createAction(`${ENTITY}/GET_ALL_FRANCHISES`);
export const loadingAllFranchises = createAction(`${ENTITY}/LOADING_ALL_FRANCHISES`);
export const stopLoadingAllFranchises = createAction(`${ENTITY}/STOP_LOADING_ALL_FRANCHISES`);
export const loadAllFranchises = createAction(`${ENTITY}/LOAD_ALL_FRANCHISES`);

// Get franchise folder
export const getFranchiseFolder = createAction(`${ENTITY}/GET_FRANCHISE_FOLDER`);
export const loadingFranchiseFolder = createAction(`${ENTITY}/LOADING_FRANCHISE_FOLDER`);
export const loadFranchiseFolder = createAction(`${ENTITY}/LOAD_FRANCHISE_FOLDER`);
export const getFranchiseFolderParent = createAction(`${ENTITY}/LOAD_FRANCHISE_FOLDER_PARENT`);

// Create new franchise folder
export const createNewFranchiseFolder = createAction(`${ENTITY}/CREATE_NEW_FRANCHISE_FOLDER`);
export const creatingNewFranchiseFolder = createAction(`${ENTITY}/CREATING_NEW_FRANCHISE_FOLDER`);
export const loadNewFranchiseFolder = createAction(`${ENTITY}/LOAD_NEW_FRANCHISE_FOLDER`);

// Generate new Franchise Secret
export const generateNewFranchiseSecret = createAction(`${ENTITY}/GENERATE_NEW_FRANCHISE_SECRET`);
export const generatingNewFranchiseSecret = createAction(`${ENTITY}/GENERATING_NEW_FRANCHISE_SECRET`);
export const resetFranchiseSecret = createAction(`${ENTITY}/RESET_FRANCHISE_SECRET`);
export const loadNewFranchiseSecret = createAction(`${ENTITY}/LOAD_NEW_FRANCHISE_SECRET`);

// Create new franchise
export const createNewFranchise = createAction(`${ENTITY}/CREATE_NEW_FRANCHISE`);
export const creatingNewFranchise = createAction(`${ENTITY}/CREATING_NEW_FRANCHISE`);
export const cancelCreatingNewFranchise = createAction(`${ENTITY}/CALCEL_CREATING_NEW_FRANCHISE`);
export const loadNewFranchise = createAction(`${ENTITY}/LOAD_NEW_FRANCHISE`);

// Delete franchise
export const deleteFranchiseRequest = createAction(`${ENTITY}/DELETE_FRANCHISE_REQUEST`);
export const cancelDeleteFranchise = createAction(`${ENTITY}/CANCEL_DELETE_FRANCHISE`);
export const deletingFranchise = createAction(`${ENTITY}/DELETING_FRANCHISE`);
export const deleteFranchise = createAction(`${ENTITY}/DELETE_FRANCHISE`);

// Edit franchise
export const editFranchiseRequest = createAction(`${ENTITY}/EDIT_FRANCHISE_REQUEST`);
export const cancelEditFranchise = createAction(`${ENTITY}/CANCEL_EDIT_FRANCHISE`);
export const editingFranchise = createAction(`${ENTITY}/EDITING_FRANCHISE`);
export const editFranchise = createAction(`${ENTITY}/EDIT_FRANCHISE`);

// Edit franchise secret
export const editFranchiseSecretRequest = createAction(`${ENTITY}/EDIT_FRANCHISE_SECRET_REQUEST`);
export const editFranchiseSecret = createAction(`${ENTITY}/EDIT_FRANCHISE_SECRET`);
