import { compose } from 'redux';
import React, { useEffect, useState } from 'react';

import { Tabs, Tab } from '@mui/material';
import { List, ViewWeek } from '@mui/icons-material';
import Pagination from '@mui/material/Pagination';
import makeStyles from '@mui/styles/makeStyles';

import TabContent from './tabPanelContent';
import { textPurple } from '../../../../Styles/styles';
import PlayWebSocketButton from '../../Elements/playWebSocketButton';
import ContentWrapper from '../../../Components/Elements/ContentWrapper';
import LocationEventHandler from '../../../../Components/LocationEvents';
import TrackerLocationsActions from '../../../../Hocs/WithAction/Trackers/locations';
import TrackerTabEventContext from '../../../../Hocs/WithData/Trackers/tabEventContext';
import TrackerEventLocationsData from '../../../../Hocs/WithData/Trackers/eventLocationEvents';

export const eventsListStyle = makeStyles(() => ({
  pagination: {
    display: 'flex',
    justifyContent: 'center',
  },
  tabs: {
    color: textPurple,
    '& div > span': {
      backgroundColor: textPurple,
    },
  },
}));

const EventsList = ({
  currentTracker, tabEventContext, changeEventDetailsTab,
  locationEvents, selectLocationEvent, selectedEvent,
}) => {
  const classes = eventsListStyle();
  const [indexList, setIndexList] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const selectEventToDisplay = () => {
      const selectedEventIndex = locationEvents
        .findIndex((locationEvent) => locationEvent.id === selectedEvent.id) + 1;
      return selectedEvent ? setPage(selectedEventIndex) : setPage(1);
    };
    selectEventToDisplay();
  }, [locationEvents, selectedEvent]);

  const handleChange = (_, newValue) => {
    changeEventDetailsTab(newValue);
  };

  const handleClickOnRow = (rowIndex) => {
    setIndexList(rowIndex);
    setPage(rowIndex + 1);
    selectLocationEvent(LocationEventHandler.getEventByIndex(locationEvents, rowIndex));
  };

  const handleChangeEvent = (_, eventIndex) => {
    const index = eventIndex - 1;
    setIndexList(eventIndex - 1);
    setPage(eventIndex);
    selectLocationEvent(LocationEventHandler.getEventByIndex(locationEvents, index));
  };

  return (
    <ContentWrapper className="tabs-size">
      <div className="tabs-header">
        <div className="header-buttons">
          <PlayWebSocketButton />
          <Tabs
            value={tabEventContext}
            onChange={handleChange}
            className={classes.tabs}
          >
            <Tab value="eventList" icon={<List className="tab-icons" />} />
            <Tab value="eventDetails" icon={<ViewWeek className="tab-icons" />} />
          </Tabs>
        </div>
      </div>
      <div className="tabs-container">
        <TabContent
          value={tabEventContext}
          indexList={(tabEventContext === 'eventList') ? currentTracker : indexList}
          onClickEventRow={(rowIndex) => handleClickOnRow(rowIndex)}
        />
      </div>
      {tabEventContext === 'eventDetails'
          && (
          <Pagination
            page={page}
            onChange={handleChangeEvent}
            count={locationEvents.length}
            className={classes.pagination}
          />
          )}
    </ContentWrapper>
  );
};

export default compose(TrackerEventLocationsData,
  TrackerTabEventContext, TrackerLocationsActions)(EventsList);
