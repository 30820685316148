import { createAction } from 'redux-actions';

const ENTITY = '@@ACCOUNT';

// Account List
export const getPaginatedAccounts = createAction(`${ENTITY}/GET_PAGINATED_ACCOUNTS`);
export const loadingPaginatedAccounts = createAction(`${ENTITY}/LOADING_PAGINATED_ACCOUNTS`);
export const stopLoadingPaginatedAccounts = createAction(`${ENTITY}/STOP_LOADING_PAGINATED_ACCOUNTS`);
export const loadPaginatedAccounts = createAction(`${ENTITY}/LOAD_PAGINATED_ACCOUNTS`);

// Create Account
export const createNewAccount = createAction(`${ENTITY}/CREATE_NEW_ACCOUNT`);
export const creatingNewAccount = createAction(`${ENTITY}/CREATING_NEW_ACCOUNT`);
export const loadNewAccount = createAction(`${ENTITY}/LOAD_NEW_ACCOUNT`);
