import makeStyles from '@mui/styles/makeStyles';
import {
  mobile,
  valueColor,
  buttonPurple,
  semiBoldFont,
  onInputHoverPurple,
  onInputFocusPurple,
  onHoverButtonPurple,
} from '../../../../Styles/styles';

const editFranchiseModalContentStyles = makeStyles((theme) => ({
  editFranchiseModalContentContainer: {
    maxHeight: '60vh',
    paddingTop: '15px',
    paddingRight: '15px',
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    // Breakpoint for Mobile
    [theme.breakpoints.down(mobile)]: {
      height: '400px',
    },
  },
  editFranchiseModalFormControl: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${onInputHoverPurple}`,
    },
    '& .MuiInputBase-input:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: onInputFocusPurple,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: `1px solid ${onInputFocusPurple}`,
      },
    },
  },
  editFranchiseModalDropdown: {
    height: '40px',
    width: '330px',
    '& div': {
      display: 'flex',
    },
  },
  editFranchiseModalDropdownItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  editFranchiseModalDropdownItemIconContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'lightsteelblue',
    },
  },
  editFranchiseModalDropdownItemIcon: {
    color: 'darkslategray',
    fontSize: '15px',
  },
  editFranchiseModalSectionDivider: {
    margin: '24px 0px',
  },
  editFranchiseModalSectionHeader: {
    color: '#323547',
    fontSize: '20px',
    fontWeight: '600',
    letterSpacing: '0',
    lineHeight: '32px',
    fontFamily: semiBoldFont,
  },
  editFranchiseModalSectionSubheader: {
    color: '#323547',
    fontSize: '14px',
    letterSpacing: '0',
    lineHeight: '20px',
    fontFamily: semiBoldFont,
    padding: '15px 0px 10px 0px',
  },
  editFranchiseModalInputFieldContainer: {
    display: 'flex',
    margin: '20px auto',
    justifyContent: 'space-between',
    '& .MuiFormLabel-root.Mui-focused': {
      color: onInputFocusPurple,
    },
  },
  editFranchiseModalInputField: {
    width: '100%',
    display: 'flex',
    fontSize: '14px',
    color: valueColor,
    lineHeight: '20px',
    alignItems: 'center',
    fontFamily: semiBoldFont,
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${onInputHoverPurple}`,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: `1px solid ${onInputFocusPurple}`,
      },
    },
  },
  editFranchiseModalInputFieldWithButton: {
    width: '100%',
  },
  editFranchiseModalInputFieldButtonContainer: {
    marginLeft: '10px',
    display: 'flex',
    whiteSpace: 'nowrap',
    justifyContent: 'flex-end',
  },
  editFranchiseModalInputFieldButton: {
    margin: '0px',
    borderRadius: '8px',
    color: buttonPurple,
    textTransform: 'none',
    minWidth: 'fit-content',
  },
  editFranchiseModalInputFieldButtonSecretCode: {
    width: '110px',
  },
  editFranchiseModalInputFieldButtonSubFolder: {
    width: '80px',
  },
  editFranchiseModalButtonsContainer: {
    display: 'flex',
    paddingTop: '26px',
    justifyContent: 'flex-end',
  },
  editFranchiseModalCancelButton: {
    margin: '0px',
    width: '100%',
    borderRadius: '8px',
    color: buttonPurple,
    textTransform: 'none',
    maxWidth: 'fit-content',
  },
  editFranchiseModalSaveButton: {
    color: 'white',
    padding: '6px 15px',
    borderRadius: '8px',
    textTransform: 'none',
    margin: '0px 0px 0px 12px',
    backgroundColor: buttonPurple,
    '&:disabled': {
      backgroundColor: '#E4E7EC',
      color: '#929FB4',
    },
    '&:hover': {
      color: 'white',
      backgroundColor: onHoverButtonPurple,
    },
  },
  franchiseFolderName: {
    marginRight: 'auto',
    marginLeft: '10px',
  },
  franchiseFolderIcon: {
    fontSize: '24px',
    color: 'slategray',
  },
}));

export default editFranchiseModalContentStyles;
