import { connect } from 'react-redux';

import {
  resetPassword,
  changePassword,
  getProfileDetails,
  uploadProfileImage,
  updateProfileTimezone,
  profileImageUploading,
} from '../../redux/Actions/UserActions';

export default (WrappedComponent) => connect(
  null, {
    resetPassword,
    changePassword,
    getProfileDetails,
    uploadProfileImage,
    updateProfileTimezone,
    profileImageUploading,
  },
)(WrappedComponent);
