import Cookies from 'universal-cookie';
import { handleActions } from 'redux-actions';

import { logout } from '../Actions/AuthActions';
import {
  updateProfileAction,
  saveProfileTimezone,
  profileImageUploading,
  loadDefaultProfileImage,
} from '../Actions/UserActions';

const cookies = new Cookies();
const profileInfo = cookies.get('profileInfo');

const initialState = {
  customer: '',
  role: profileInfo?.role || '',
  username: profileInfo?.username || '',
  lastName: profileInfo?.lastName || '',
  firstName: profileInfo?.firstName || '',
  timezone: profileInfo?.timezone || 'UTC',
  franchise: {
    name: profileInfo?.franchise.name || '',
    id: profileInfo?.franchise.uuid || '',
  },
  avatar: {
    fullsizeUrl: '',
    thumbnailUrl: '',
  },
  isProfileImageUploading: true,
  isProfileTimezoneUpdating: false,
};

export default handleActions({
  [logout]: () => ({
    ...initialState,
  }),
  [loadDefaultProfileImage]: (state) => ({
    ...state,
    avatar: {
      fullsizeUrl: '',
      thumbnailUrl: '',
    },
    isProfileImageUploading: false,
  }),
  [profileImageUploading]: (state) => ({
    ...state,
    isProfileImageUploading: true,
  }),
  [updateProfileAction]: (state, { payload: profileDetails }) => ({
    ...state,
    ...profileDetails,
    isProfileImageUploading: false,
  }),
  [saveProfileTimezone]: (state, { payload: timezone }) => ({
    ...state,
    timezone,
  }),
}, initialState);
