import React from 'react';
import { styled, useTheme } from '@mui/material/styles';

import {
  Box,
  ButtonBase,
  IconButton,
  Dialog,
  Paper,
} from '@mui/material';

import {
  DeleteOutlined as DeleteIcon,
  Fullscreen as ExpandIcon,
  AddAPhotoOutlined as UploadIcon,
} from '@mui/icons-material';

import { ASSET_TYPES } from '../../../../../Utils/AssetTypes';

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.borderRadius(1.5),
  boxShadow: theme.shadows[1],
  overflow: 'hidden',
  '& .fadeInIcon': {
    position: 'absolute',
    color: theme.palette.common.white,
    opacity: 0.0,
    transition: theme.transitions.create('opacity'),
  },
  '& .iconTopRight': {
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  '&:hover, &.Mui-focusVisible': {
    '& .ImageOverlay': {
      opacity: 0.2,
      zIndex: 2,
    },
    '& .fadeInIcon': {
      opacity: 1.0,
      zIndex: 3,
    },
    '& .fadeInFallbackIcon': {
      color: theme.palette.common.white,
      zIndex: 3,
    },
  },
}));

const ImageSrcSmall = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  height: '100%',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  borderRadius: 'inherit',
  backgroundSize: 'contain',
}));

const ImageSrcLarge = styled('img')(({ theme }) => ({
  borderRadius: 'inherit',
  // backgroundSize: 'contain',
}));

const Overlay = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  borderRadius: 'inherit',
  opacity: 0.0,
  transition: theme.transitions.create('opacity'),
}));

const AssetImage = ({
  editable = false,
  image,
  assetType,
  onEdit,
  sx,
}) => {
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);

  const useDefaultImage = !image?.thumbnailUrl;
  const useFallbackIcon = !ASSET_TYPES[assetType];
  const canEnlarge = !useDefaultImage && !editable;

  const hoverIconClass = 'fadeInIcon';
  const hoverFallbackIconClass = 'fadeInFallbackIcon';
  
  const defaultImage = !useFallbackIcon ? (
    <ImageSrcSmall src={ASSET_TYPES[assetType].defaultImage} alt='Asset Type'/>
  ) : (
    <Box className={hoverFallbackIconClass} sx={{ m: 'auto', height: '35px', transition: theme.transitions.create('color') }}>
      <UploadIcon fontSize="large"/>
    </Box>
  );

  const defaultImageSx = {
    border: `${theme.palette.divider} solid 1px`,
    color: theme.palette.text.secondary,
  }

  const handleClickOpen = () => {
    if (!editable) {
      setOpen(true);
    }
    else {
      onEdit && onEdit();
    }
  }

  return (!editable && useDefaultImage) ? (
    <Paper sx={{ display: 'flex', ...defaultImageSx, ...sx }}>
      {defaultImage}
    </Paper>
  ) : (
    <>
      <ImageButton onClick={handleClickOpen} sx={{ ...(useDefaultImage && defaultImageSx), ...sx }}>
        {useDefaultImage ? defaultImage : (
          <ImageSrcSmall
            src={image.thumbnailUrl}
            alt='Asset Photo'
          />
        )}

        <Overlay className='ImageOverlay'/>
        {canEnlarge && <ExpandIcon fontSize="large" className={hoverIconClass}/>}
        {editable && !useFallbackIcon && <UploadIcon fontSize="large" className={hoverIconClass}/>}
      </ImageButton>

      {canEnlarge && (
        <Dialog open={open} onClose={() => setOpen(false)}>
          <ImageSrcLarge
            src={(image.fullsizeUrl)}
            alt='Fullsize Asset Photo'
          />
        </Dialog>
      )}
    </>
  );
};

export default AssetImage;
