import { connect } from 'react-redux';

const mapStateToProps = ({
  // Data in the Store
  LocationEventData_NEW: {
    latestLocations,
    filteredLocations,
    selectedLocations,
    locationFilter,
    areLocationsLoading,
    showTowers,

    isGeneratingDummyLocations,
  }
}) => ({
  // Props for the Component
  latestLocations,
  filteredLocations,
  selectedLocations,
  locationFilter,
  areLocationsLoading,
  showTowers,

  isGeneratingDummyLocations,
})

export default (WrappedComponent) => connect(mapStateToProps, null)(WrappedComponent);