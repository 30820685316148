import React from 'react';
import {
  TableContainer, Table, TableRow, TableCell, TableHead, TableBody, Paper,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const EventModalTableStyles = makeStyles(() => ({
  tableRow: {
    '& td': {
      overflowWrap: 'anywhere',
    },
    '& th': {
      whiteSpace: 'nowrap',
    },
  },
}));

const EventModalTable = ({ header, rows }) => {
  const classes = EventModalTableStyles();
  return (
    <TableContainer component={Paper}>
      <Table className="" aria-label="caption table">
        <TableHead>
          <TableRow>
            {header.map((headerItem) => (
              <TableCell key={`${headerItem}`}>{headerItem}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.label} className={classes.tableRow}>
              <TableCell component="th" scope="row">
                {row.label}
              </TableCell>
              <TableCell align="left">{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default EventModalTable;
