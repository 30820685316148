import { compose } from 'redux';
import React, { useEffect } from 'react';

import { Box, CircularProgress } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import TableMaps from './tabMaps';
import EventLimitFilter from './eventsFilter';
import TabColumns from './tabColumns/tabColumns';
import Checkfield from '../../../Components/Elements/Checkbox';
import TrackerActions from '../../../../Hocs/WithAction/Trackers';
import TrackerEventsWS from '../../../Components/WebSocket/TrackerEventsWS';
import TrackerLocationsActions from '../../../../Hocs/WithAction/Trackers/locations';
import CurrentTrackerWithData from '../../../../Hocs/WithData/Trackers/singleTracker';
import TrackerLocationsFilterValues from '../../../../Hocs/WithData/Trackers/filterLocations';

const tabPanelStyles = makeStyles(() => ({
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const TabPanel = ({
  children, name, value, ...other
}) => (
  <div
    role="tabpanel"
    hidden={value !== name}
    id={`scrollable-prevent-tabpanel-${name}`}
    aria-labelledby={`scrollable-prevent-tab-${name}`}
    {...other}
  >
    {value === name && (
    <Box>
      {children}
    </Box>
    )}
  </div>
);

const LoadingEventModal = () => {
  const classes = tabPanelStyles();
  return (
    <div className={classes.spinnerContainer}>
      <CircularProgress />
    </div>
  );
};

const TabPanelContent = ({
  value, onClickEventRow,
  setTrackerLocationFilterAction, filters, getFilteredLocationTrackerAction, currentEvent,
  currentTracker, locationsCanBeShown, selectLocationEvent,
}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const eventID = urlParams.get('eventID');

  useEffect(() => {
    // request to Filtered List
    getFilteredLocationTrackerAction({ ...filters, simiccid: currentTracker.simiccid });
  }, [
    getFilteredLocationTrackerAction,
    filters,
    currentTracker
  ]);

  if (eventID && locationsCanBeShown) {
    const refresh = window.location.pathname;
    window.history.pushState({ path: refresh }, '', refresh);
    selectLocationEvent(currentEvent);
  }

  if (!locationsCanBeShown) return (<LoadingEventModal />);
  return (
    <>
      <TrackerEventsWS trackerId={currentTracker.simiccid}/>

      <TabPanel value={value} name="eventList">
        <div>
          <div className="tabs-filter-panel">
            <div className="tabs-checkboxes">
              <Checkfield status={filters.gps} handleCheckBoxChange={() => setTrackerLocationFilterAction('GPS')} name="GPS" />
              <Checkfield status={filters.gsm} handleCheckBoxChange={() => setTrackerLocationFilterAction('GSM')} name="GSM" />
              <Checkfield status={filters.wifi} handleCheckBoxChange={() => setTrackerLocationFilterAction('WIFI')} name="WIFI" />
            </div>
            <EventLimitFilter filters={filters} onClickEvent={setTrackerLocationFilterAction} />
          </div>
          <div className="tabs-table">
            <TableMaps onClick={onClickEventRow} />
          </div>
          <div />
        </div>
      </TabPanel>
      <TabPanel value={value} name="eventDetails">
        <TabColumns tracker={currentTracker} />
      </TabPanel>
    </>
  );
};

export default compose(
  TrackerLocationsActions,
  TrackerLocationsFilterValues,
  CurrentTrackerWithData,
  TrackerActions,
)(TabPanelContent);
