import moment from 'moment';
import { compose } from 'redux';
import { values } from 'underscore';
import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';

import EventModal from './EventModal';
import Table from '../../../Components/Table';
import ShowModal from '../../../Components/Modal/showModal';
import TrackerEventsWS from '../../../Components/WebSocket/TrackerEventsWS';
import ContentWrapper from '../../../Components/Elements/ContentWrapper';
import TimeTools from '../../../../Components/TimeTools';

import withTrackersActions from '../../../../Hocs/WithAction/Trackers';
import withEventListActions from '../../../../Hocs/WithAction/Events/list';
import AllTrackers from '../../../../Hocs/WithData/Trackers/allTrackerEvents';
import SingleTracker from '../../../../Hocs/WithData/Trackers/singleTracker';
import withUserData from '../../../../redux/WithData/user';

const LogsTab = ({
  prevPage,
  nextPage,
  trackerId,
  changeLimit,
  setActiveTab,
  currentTracker,
  userTimezone,
  getAllEventsAction,
  setLoadingAllEventAction,
  allTrackerEvents: {
    data,
    limit,
    offset,
    loading,
    totalCount,
  },
}) => {
  useEffect(() => {
    getAllEventsAction({ simiccid: currentTracker.simiccid, offset, limit });
    setLoadingAllEventAction(true);
  }, [currentTracker, getAllEventsAction, setLoadingAllEventAction, offset, limit]);

  const today = moment().format('hh:mm DD-MM-YYYY');
  const [open, setOpen] = useState(false);

  const [logRowID, setLogRowID] = useState('');
  const columns = [
    { id: 'userDatetime', label: 'Your Time' },
    { id: 'trackerDatetime', label: 'Tracker Time' },
    { id: 'eventType', label: 'Event Type' },
    { id: 'preview', label: 'Preview' },
    { id: 'type', label: 'Type' },
  ];

  const logEvents = values(data).map(({
    timestamp, type, rawData, objectType,
  }) => ({
    userDatetime: TimeTools.getTimezoneFormat24(timestamp, { timezone: userTimezone }),
    trackerDatetime: TimeTools.getTimezoneFormat24(timestamp, currentTracker),
    eventType: type,
    type: objectType,
    preview: rawData,
  }));

  const eventRowData = {
    rows: logEvents,
    totalCount,
  };

  const handleOpen = (index) => {
    setOpen(true);
    setLogRowID(values(data)[index].id);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const setContent = () => {
    const selectedEvent = data.find((event) => event.id === logRowID);
    return (
      <EventModal
        setActiveTab={setActiveTab}
        selectedEvent={selectedEvent}
      />
    );
  };

  if (loading || currentTracker.simiccid !== trackerId) {
    return (
      <div className="loading spinner">
        <CircularProgress />
      </div>
    );
  }
  return (
    <ContentWrapper className="trackers-tabLogs-wrapper">
      <TrackerEventsWS trackerId={trackerId}/>

      <ShowModal
        openModal={open}
        body={setContent}
        title="Event Info"
        className="modalTitle"
        handleClose={handleClose}
      />

      <Table
        columns={columns}
        hasRowColumn={false}
        data={eventRowData}
        title={`Last updated: ${today}`}
        onClick={(index) => handleOpen(index)}
        offset={offset}
        limit={limit}
        changeLimit={changeLimit}
        nextPage={nextPage}
        prevPage={prevPage}
      />
    </ContentWrapper>
  );
};
export default compose(
  withTrackersActions, SingleTracker, withEventListActions, AllTrackers, withUserData,
)(LogsTab);
