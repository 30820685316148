import React, { useState } from 'react';
import { compose } from 'redux';
import { isEmpty } from 'underscore';
import { Button, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Autocomplete from '@mui/material/Autocomplete';

import RowComponent from './RowComponent';
import TimeTools from '../../../../Components/TimeTools';
import TrackerData from '../../../../Hocs/WithData/tracker';
import TrackerActions from '../../../../Hocs/WithAction/tracker';
import {
  buttonPurple, font, onHoverButtonPurple, valueColor,
} from '../../../../Styles/styles';

const trackerTimezoneStyles = makeStyles(() => ({
  trackerName: {
    fontFamily: font,
    fontSize: '14px',
    width: '100%',
    lineHeight: '20px',
    color: valueColor,
    display: 'flex',
    alignItems: 'center',
    '& .MuiInputBase-root': {
      marginBottom: '8px',
    },
  },
  labelWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    color: buttonPurple,
    borderRaduis: '8px',
    margin: '0px',
    textTransform: 'none',
    width: '100%',
    maxWidth: 'fit-content',
  },
  submitButton: { // button
    textTransform: 'none',
    backgroundColor: buttonPurple,
    color: 'white',
    margin: '0px 0px 0px 12px',
    '&:disabled': {
      backgroundColor: '#E4E7EC',
      color: '#929FB4',
    },
    '&:hover': {
      backgroundColor: onHoverButtonPurple,
      color: 'white',
    },
  },
  autocomplete: {
    marginBottom: '8px',
  },
}));

const TrackerTimezone = ({ tracker, updateTrackerTimezone, toggleTrackerTimezoneInputField }) => {
  const classes = trackerTimezoneStyles();
  const trackerTimezoneWithOffset = TimeTools.generateTimezoneListFormat(tracker.timezone);
  const TrackerTimezoneLabel = (
    <>
      <RowComponent field="Tracker Timezone" />
      <div className={classes.labelWrapper}>
        <span className={classes.trackerName}>{trackerTimezoneWithOffset.label}</span>
        <Button
          className={classes.button}
          onClick={() => toggleTrackerTimezoneInputField(true)}
        >
          Edit timezone
        </Button>
      </div>
    </>
  );

  const TrackerTzSelector = () => {
    const options = TimeTools.getTzList();
    const [value, setValue] = useState(trackerTimezoneWithOffset);
    const [inputValue, setInputValue] = useState('');
    const handleClose = () => {
      toggleTrackerTimezoneInputField(false);
    };
    const updateTrackerTz = () => {
      updateTrackerTimezone({ timezone: value.tz, tracker });
    };
    return <>
      <RowComponent field="Tracker Timezone" />
      <Autocomplete
        className={classes.autocomplete}
        isOptionEqualToValue={(option) => option.tz === value.tz}
        getOptionLabel={(option) => option.label}
        value={value}
        inputValue={inputValue}
        options={options}
        onChange={(event, newValue) => {
          if (newValue) setValue(newValue); // we don't have empty values
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" />
        )}
      />
      <div className={classes.buttonWrapper}>
        <Button
          onClick={handleClose}
          className={classes.button}
        >
          Cancel
        </Button>
        <Button
          onClick={updateTrackerTz}
          className={classes.submitButton}
          disabled={tracker.timezone === value.tz || isEmpty(value)}
        >
          {tracker.isTrackerDetailsUpdating ? 'Saving' : 'Save'}
        </Button>
      </div>
    </>;
  };

  return (
    <div>
      <>
        {tracker.showTrackerTimezoneInputField ? <TrackerTzSelector /> : TrackerTimezoneLabel}
      </>
    </div>
  );
};

export default compose(TrackerData, TrackerActions)(TrackerTimezone);
