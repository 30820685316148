import { connect } from 'react-redux';

import {
  loadNewAccount,
  createNewAccount,
  creatingNewAccount,
  getPaginatedAccounts,
  loadPaginatedAccounts,
  loadingPaginatedAccounts,
} from '../../redux/Actions/AccountActions';

export default (WrappedComponent) => connect(
  null, {
    loadNewAccount,
    createNewAccount,
    creatingNewAccount,
    getPaginatedAccounts,
    loadPaginatedAccounts,
    loadingPaginatedAccounts,
  },
)(WrappedComponent);
