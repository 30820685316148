import AuthCall from './auth';

export default {
  getUserDetails: async (email) => {
    return await AuthCall.get(`/api/v1/secure/account/instance/${email}`)
  },
  setUserDetails: async () => {
    // return await AuthCall.get(`/api/v1/secure/tracker/instance/${trackerId}`)
  },
  setUserPassword: async (email, newPassword) => {
    return await AuthCall.post('/api/v1/secure/account/password/change', { username: email, password: newPassword });
  },
  setUserAvatar: async (email, imageFile) => {
    const formData = new FormData().append('file', imageFile);
    const header = { 'Content-Type': 'multipart/form-data' };

    return await AuthCall.post(`/api/v1/secure/account/avatar/upload/${email}`, formData, header);
  },
}