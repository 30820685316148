import React from 'react';
import { compose } from 'redux';

import {
  Box,
  Divider,
} from '@mui/material';

import StateButton, { LARGE_HEIGHT, SMALL_HEIGHT } from './StateButton';

import { STATES } from '../../../Utils/States';
import { MODES } from '../../../Utils/Modes';

import withTrackerData from '../../../redux/WithData/tracker';
import withTrackerActions from '../../../redux/WithActions/tracker';

const StateSelector = ({
  small = false,
  sx,

  tracker,
  setTrackerState,
}) => {
  const SPACING = (small ? SMALL_HEIGHT : LARGE_HEIGHT) / 2;
  const rowSx = { display: 'flex', flexDirection: 'row' };

  function handleClick(state) {
    setTrackerState({ trackerId: tracker.simiccid, stateId: state.id });
  }

  function getStateButton({ state, left, right, widthMult, clickable }) {
    return (
      <StateButton state={state} small={small} left={left} right={right} widthMult={widthMult} active={tracker?.state?.mnemonic === state.id} onClick={clickable && handleClick}/>
    )
  }

  return (
    <Box sx={{ ...sx, width: 'max-content' }}>
      <Divider/>
      <Box sx={rowSx}>
        {getStateButton({ state: STATES.ALARM, left: true, clickable: tracker?.currentSettings?.mode === MODES.ALARM.id })}
        <Divider orientation="vertical" flexItem/>
        {getStateButton({ state: STATES.ALARM_ACK, right: true, clickable: tracker?.currentSettings?.mode === MODES.ALARM.id })}
      </Box>
      <Divider/>
      <Box sx={rowSx}>
        {getStateButton({ state: STATES.WARNING, left: true, clickable: tracker?.currentSettings?.mode === MODES.WARNING.id })}
        <Divider orientation="vertical" flexItem/>
        {getStateButton({ state: STATES.WARNING_ACK, right: true, clickable: tracker?.currentSettings?.mode === MODES.WARNING.id })}
      </Box>
      <Divider/>

      <Divider sx={{ mt: `${SPACING}px` }}/>
      <Box sx={rowSx}>
        {getStateButton({ state: STATES.FAULTY, left: true })}
        <Divider orientation="vertical" flexItem/>
        {getStateButton({ state: STATES.FAULTY_SENT, right: true, clickable: true })}
      </Box>
      <Divider/>
      <Box sx={rowSx}>
        {getStateButton({ state: STATES.NO_CONTACT, left: true })}
        <Divider orientation="vertical" flexItem/>
        {getStateButton({ state: STATES.FAULTY_ACK, right: true, clickable: true })}
      </Box>
      <Divider/>
      <Box sx={rowSx}>
        {getStateButton({ state: STATES.LATE_CONTACT, left: true })}
        <Divider orientation="vertical" flexItem/>
        {getStateButton({ state: STATES.OFF, right: true, clickable: true })}
      </Box>
      <Divider/>

      <Divider sx={{ mt: `${SPACING}px` }}/>
      <Box sx={rowSx}>
        {getStateButton({ state: STATES.OK, left: true, right: true, widthMult: 2, clickable: true })}
      </Box>
      <Divider/>
    </Box>
  );
}

export default compose(withTrackerData, withTrackerActions)(StateSelector);