import { Checkbox } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const checkBoxStyles = () => ({
  root: {
    '&$checked': {
      color: '#00C853',
    },
  },
  checked: {},
});

const GreenCheckbox = withStyles(checkBoxStyles)(Checkbox);

export default GreenCheckbox;
