import {
  put,
  takeLatest,
} from "redux-saga/effects";

import {
  createDummyLocation,
  getLatestLocations,
  getLocations,
  getLocationAddress,
  setLocationsFilter,
  setLatestLocationsLoading,
  setLocationsLoading,
  storeLatestLocations,
  storeLocations,
  updateLocation,
  
} from '../Actions/location';

import { addNotification } from '../Actions/system';
import { authApiHandler } from './auth';

import LocationAPI from '../../Api/Endpoints/location';
import { ResponseTypes } from '../../Api/Responses/baseResponse';

import { NAV_TYPES, NAV_TYPES_ARRAY } from '../../Utils/NavTypes';

export function* handleGetLatestLocations({ payload: trackerId }) {
  yield put(setLatestLocationsLoading(true));

  let hasFailed = false;
  let response;
  let latestLocations = {};

  for (let navType of NAV_TYPES_ARRAY) {
    response = yield authApiHandler(LocationAPI.getLocations, trackerId, 1,
      navType === NAV_TYPES.GPS,
      navType === NAV_TYPES.GSM,
      navType === NAV_TYPES.WIFI
    );

    if (response.type == ResponseTypes.ActionCompleted) {
      // If a location was found, store it. Otherwise leave it undefined
      if (response.data.data.length > 0) {
        latestLocations[navType.id] = response.data.data[0];
      }
    }
    else if (response.type == ResponseTypes.Exception) {
      yield put(addNotification({
        type: 'error',
        title: 'Could not retrieve locations',
        source: 'handleGetLatestLocations',
        message: 'Please check your connection and try again.',
      }));

      hasFailed = true;
    }
  }

  if (!hasFailed) {
    yield put(storeLatestLocations(latestLocations));
  }

  yield put(setLatestLocationsLoading(false));
}

export function* handleGetLocations({ payload: { trackerId, limit, gps, gsm, wifi, startTime, endTime } }) {
  yield put(setLocationsLoading(true));
  yield put(setLocationsFilter({ limit, gps, gsm, wifi, startTime, endTime }));

  let response = yield authApiHandler(LocationAPI.getLocations, trackerId, limit, gps, gsm, wifi, startTime?.toISO(), endTime?.toISO());

  if (response.type == ResponseTypes.ActionCompleted) {
    yield put(storeLocations(response.data.data));
  }
  else if (response.type == ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not retrieve locations',
      source: 'handleGetLocations',
      message: 'Please check your connection and try again.',
    }));
  }

  yield put(setLocationsLoading(false));
}

export function* handleGetLocationAddress({ payload: eventId }) {
  let response = yield authApiHandler(LocationAPI.getLocationAddress, eventId);

  if (response.type == ResponseTypes.ActionCompleted) {
    yield put(updateLocation(response.data));
  }
  else if (response.type == ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not retrieve location address',
      source: 'handleGetLocationAddress',
      message: 'Please check your connection and try again.',
    }));
  }
}

export function* handleCreateDummyLocation({ payload: { trackerId, event } }) {
  const response = yield authApiHandler(LocationAPI.createDummyLocation, trackerId, event);
  
  if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not create dummy location',
      source: 'handleCreateDummyLocation',
      message: 'Please, check your connection and try again.',
    }));
  }
}

export default function* Sagas() {
  yield takeLatest(getLatestLocations, handleGetLatestLocations);
  yield takeLatest(getLocations, handleGetLocations);
  yield takeLatest(getLocationAddress, handleGetLocationAddress);
  yield takeLatest(createDummyLocation, handleCreateDummyLocation);
}