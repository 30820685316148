import { compose } from 'redux';
import React, { useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  ListItemSecondaryAction,
} from '@mui/material';

import editFranchiseModalStyles from './style';
import withFranchiseData from '../../../Hocs/WithData/franchise';
import withFranchiseActions from '../../../Hocs/WithAction/franchise';
import EditFranchiseModalContent from './EditFranchiseModalContent/EditFranchiseModalContent';

const EditFranchiseModal = ({ role, franchise, resetFranchiseSecret }) => {
  const classes = editFranchiseModalStyles();
  const [showEditFranchiseModal, setShowEditFranchiseModal] = useState(false);

  const handleClickOpen = () => {
    setShowEditFranchiseModal(true);
  };

  const handleClose = () => {
    resetFranchiseSecret();
    setShowEditFranchiseModal(false);
  };

  return <>
    <ListItemSecondaryAction
      onClick={handleClickOpen}
      className={classes.editFranchiseModalOpenButton}
    >
      <IconButton edge="end" size="large">
        <EditIcon />
      </IconButton>
    </ListItemSecondaryAction>
    <Dialog
      onClose={handleClose}
      open={showEditFranchiseModal}
      className={classes.editFranchiseModalContainer}
    >
      <div className={classes.editFranchiseModalHeaderContainer}>
        <DialogTitle className={classes.editFranchiseModalHeader}>
          Edit Franchise
        </DialogTitle>
        <IconButton
          onClick={handleClose}
          className={classes.editFranchiseModalCloseButton}
          size="large">
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent className={classes.editFranchiseModalContent}>
        <EditFranchiseModalContent role={role} franchise={franchise} handleClose={handleClose} />
      </DialogContent>
    </Dialog>
  </>;
};

export default compose(withFranchiseData, withFranchiseActions)(EditFranchiseModal);
