export const STATES = {
  ALARM: {
    id: 'ALARM',
    label: 'Alarm',
    description: 'Alarm mode active',
    tableClassName: 'tableRow-ALARM',
    auto: true,
  },
  WARNING: {
    id: 'WARNING',
    label: 'Warning',
    description: 'Warning mode active',
    tableClassName: 'tableRow-WARNING',
    auto: true,
  },
  ALARM_ACK: {
    id: 'ALARM_ACK',
    label: 'Alarm ACK',
    description: 'Alarm mode active - handled by operator',
    tableClassName: 'tableRow-ALARM_ACK',
  },
  WARNING_ACK: {
    id: 'WARNING_ACK',
    label: 'Warning ACK',
    description: 'Warning mode active - handled by operator',
    tableClassName: 'tableRow-WARNING_ACK',
  },
  FAULTY: {
    id: 'FAULTY',
    label: 'Faulty',
    description: 'Last contact was >27 days ago - Urgent customer contact required',
    tableClassName: 'tableRow-FAULTY',
    auto: true,
  },
  NO_CONTACT: {
    id: 'NO_CONTACT',
    label: 'No Contact',
    description: 'Last contact 17+ days ago',
    tableClassName: 'tableRow-NO_CONTACT',
    auto: true,
  },
  LATE_CONTACT: {
    id: 'LATE_CONTACT',
    label: 'Late Contact',
    description: 'Last contact 8-16 days ago',
    tableClassName: 'tableRow-LATE_CONTACT',
    auto: true,
  },
  FAULTY_SENT: {
    id: 'FAULTY_SENT',
    label: 'Faulty Sent',
    description: 'Customer has been notified of fault',
    tableClassName: 'tableRow-FAULTY_SENT',
  },
  FAULTY_ACK: {
    id: 'FAULTY_ACK',
    label: 'Faulty ACK',
    description: 'Customer has acknowledged fault',
    tableClassName: 'tableRow-FAULTY_ACK',
  },
  OK: {
    id: 'OK',
    label: 'Ok',
    description: 'Healthy reported for last 7 days',
    tableClassName: 'tableRow-OK',
    auto: true,
  },
  OFF: {
    id: 'OFF',
    label: 'Off',
    description: 'De-stock - unit is no longer in use',
    tableClassName: 'tableRow-OFF',
  },
};

export const STATES_ARRAY = [
  STATES.ALARM,
  STATES.WARNING,
  STATES.ALARM_ACK,
  STATES.WARNING_ACK,
  STATES.FAULTY,
  STATES.NO_CONTACT,
  STATES.LATE_CONTACT,
  STATES.FAULTY_SENT,
  STATES.FAULTY_ACK,
  STATES.OK,
  STATES.OFF,
];