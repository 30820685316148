import React from 'react';

import { DateTime } from 'luxon';

import {
  ContentCopy as CopyIcon,
} from '@mui/icons-material';

import TooltipIconButton from '../../../../Components/TooltipIconButton';

import { NAV_TYPES } from '../../../../../Utils/NavTypes';
import { SCROLLBAR_WIDTH } from '../../../../../Utils/Defines';
import { formatDateTime } from '../../../../../Utils/TimeUtils';

const TOOLTIP_COPY_W3W = 'Copy W3W';
const TOOLTIP_COPIED = 'Copied to clipboard!';

export function getColumns(totalWidth, onClickCopy) {
  const COL_TYPE      = 100;
  const COL_TIMESTAMP = 200;
  const COL_ICON      = 60;
  const COL_W3W       = totalWidth - COL_TYPE - COL_TIMESTAMP - COL_ICON - SCROLLBAR_WIDTH;

  return [
    { width: COL_TYPE, label: 'Type', dataKey: 'type', render: ({ rowData }) => NAV_TYPES[rowData.details?.position?.method || 'GPS'].label },
    { width: COL_TIMESTAMP, label: 'Timestamp', dataKey: 'timestamp', render: ({ cellData }) => formatDateTime(DateTime.fromISO(cellData), true)},
    { width: COL_W3W, label: 'W3W', dataKey: 'w3w', render: ({ rowData }) => rowData.details?.position?.w3w || ''},
    { width: COL_ICON, label: '', dataKey: 'w3wIcon', render: ({ rowData }) => (
      <TooltipIconButton
        tooltip={TOOLTIP_COPY_W3W}
        tooltipAfterClick={TOOLTIP_COPIED}
        tooltipPlacement="left"
        icon={<CopyIcon/>}
        onClick={(event) => onClickCopy(event, rowData.details?.position?.w3w || '')}
      />
    )},
  ];
}