import AuthCall from '../Endpoints/auth';

const AccountsAPI = {

  getAccounts: async (args) => {
    const response = await AuthCall.get('/api/v1/secure/account/instance/all', args);
    return response;
  },
  addAccount: async (account) => {
    const response = await AuthCall.put('/api/v1/secure/account/instance', account);
    return response;
  },
};

export default AccountsAPI;
