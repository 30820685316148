import React from 'react';
import { compose } from 'redux';
import { Card, Divider, Typography, CardContent } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import RowComponent from './RowComponent';
import TrackerTimezone from './TrackerTimezone';
import EditableName from './TrackerEditableName';
import TrackerImageUploader from './TrackerImageUploader';
import EditableEquipmentNumber from './EditableEquipmentNumber';
import withTrackerAction from '../../../../Hocs/WithAction/tracker';
import { cardBorder, cardBoxShadow, semiBoldFont } from '../../../../Styles/styles';

const trackerInfoStyles = makeStyles(() => ({
  trackerCardInfo: {
    width: '100%',
    borderRadius: '8px',
    boxShadow: cardBoxShadow,
    border: cardBorder,
  },
  trackerCardInfoContent: {
    padding: '24px',
    '& h4': {
      '&$sectionHeader': {
        fontSize: '24px',
      },
    },
  },
  sectionWrapper: {
    padding: '24px 0px',
  },
  sectionHeader: {
    fontWeight: '600',
    letterSpacing: '0',
    lineHeight: '32px',
    fontFamily: semiBoldFont,
    fontSize: '20px',
    marginBottom: '10px',
    color: '#323547',
  },

}));

const TrackerInfo = ({ tracker }) => {
  const classes = trackerInfoStyles();

  return (
    <Card className={classes.trackerCardInfo}>
      <CardContent className={classes.trackerCardInfoContent}>
        <Typography variant="h4" className={classes.sectionHeader}>
          Tracker Profile
        </Typography>
        <RowComponent field="Tracker Photo" />
        <TrackerImageUploader tracker={tracker} />
        <div className={classes.sectionWrapper}>
          <EditableName tracker={tracker} />
          <EditableEquipmentNumber tracker={tracker} />
          <TrackerTimezone tracker={tracker} style={{ marginBottom: '8px' }} />
        </div>

        <Divider />

        <div className={classes.sectionWrapper}>
          <Typography variant="h5" className={classes.sectionHeader}>
            Tracker Details
          </Typography>
          <RowComponent field="Tel" value={tracker.msisdn} />
          <RowComponent field="Serial" value={tracker.simiccid} />
          <RowComponent field="Tracker Model" value={tracker.type} />
        </div>

        <Divider />

        <div className={classes.sectionWrapper}>
          <Typography variant="h5" className={classes.sectionHeader}>
            Company Information
          </Typography>
          <RowComponent field="Franchise" value={tracker.franchise.name} />
          <RowComponent field="Customer" value={tracker.customer?.name || 'N/A'} />
        </div>

        <Divider />

        <div className={classes.sectionWrapper}>
          <Typography variant="h5" className={classes.sectionHeader}>
            Status
          </Typography>
          <RowComponent field="Battery" value={`${tracker.battery} %`} />
        </div>
      </CardContent>
    </Card>
  );
};

export default compose(withTrackerAction)(TrackerInfo);
