import React from 'react';
import { compose } from 'redux';

import { IconButton } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WithDrawFolderAccessIcon from '@mui/icons-material/Close';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import withFoldersData from '../../../../Hocs/WithData/folder';
import { tableRowOnHoverPurple } from '../../../../Styles/styles';
import withFoldersActions from '../../../../Hocs/WithAction/folder';

const useStyles = makeStyles((theme) => ({
  accordion: {
    width: '100%',
    position: 'unset',
    marginTop: '20px',
    boxShadow: 'unset',
    '& .MuiCollapse-wrapper': {
      maxHeight: '200px',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  accordionHeader: {
    color: 'gray',
    fontSize: '1rem',
    fontWeight: theme.typography.fontWeightRegular,
  },
  accordionDetailsEmailList: {
    display: 'flex',
    borderRadius: '8px',
    alignItems: 'center',
    padding: '8px 4px 8px 16px',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: tableRowOnHoverPurple,
      // backgroundColor: theme.palette.primary.light,
    },
  },
}));

function AuthorizedUsersList({
  arePermissionsCascadedToSubfolders,
  withdrawFolderAccess,
  selectedItems,
}) {
  const classes = useStyles();

  const selectedFolder = selectedItems[0];

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.accordionHeader}>
          Currently shared with...
        </Typography>
      </AccordionSummary>
      {selectedFolder.sharedWith.map((email) => (
        <AccordionDetails key={email} className={classes.accordionDetailsEmailList}>
          <Typography>
            {email}
          </Typography>
          <IconButton
            onClick={() => withdrawFolderAccess({
              arePermissionsCascadedToSubfolders,
              folderName: selectedFolder.name,
              folderId: selectedFolder.id,
              email,
            })}
            size="large">
            <WithDrawFolderAccessIcon />
          </IconButton>
        </AccordionDetails>
      ))}
    </Accordion>
  );
}

export default compose(withFoldersData, withFoldersActions)(AuthorizedUsersList);
