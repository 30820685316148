import { all } from 'redux-saga/effects';

import AuthSagas_OLD from './AuthSagas';
import UserSagas_OLD from './UserSagas';
import SystemSagas_OLD from './SystemSagas';
import FolderSagas_OLD from './FolderSagas';
import AccountSagas_OLD from './AccountSagas';
import TrackerSagas_OLD from './TrackerSagas';
import CustomerSagas_OLD from './CustomerSagas';
import FranchiseSagas_OLD from './FranchiseSagas';
import LocationEventsSagas_OLD from './LocationEventsSagas';

import AuthSagas from './auth';
import SystemSagas from './system';
import UserSagas from './user';
import TrackerSagas from './tracker';
import EventSagas from './event';
import LocationSagas from './location';

import ExampleSagas from './example';

export default function* () {
  yield all([
    AuthSagas_OLD(),
    AuthSagas(),

    SystemSagas(),
    UserSagas(),
    TrackerSagas(),
    EventSagas(),
    LocationSagas(),

    ExampleSagas(),

    UserSagas_OLD(),
    FolderSagas_OLD(),
    SystemSagas_OLD(),
    AccountSagas_OLD(),
    TrackerSagas_OLD(),
    CustomerSagas_OLD(),
    FranchiseSagas_OLD(),
    LocationEventsSagas_OLD(),
  ]);
}
