import React from 'react';
import { compose } from 'redux';
import { VerifiedUser } from '@mui/icons-material';
import { Card, Typography, CardContent } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import {
  font,
  mobile,
  fieldColor,
} from '../../Styles/styles';
import TimeTools from '../../Components/TimeTools';
import ProfileImageUploader from './ProfileImageUploader';

import withUserData from '../../redux/WithData/user';

const profileCardStyles = makeStyles((theme) => ({
  profileCard: {
    display: 'flex',
    padding: '30px',
    boxShadow: 'unset',
    alignItems: 'center',
    // Breakpoint for Mobile
    [theme.breakpoints.down(mobile)]: {
      flexDirection: 'column',
      height: 'unset',
    },
  },
  profileCardContent: {
    justifyContent: 'center',
    flexDirection: 'column',
    display: 'flex',
    height: '100%',
    width: '100%',
    // Breakpoint for Mobile
    [theme.breakpoints.down(mobile)]: {
      alignItems: 'center',
    },
  },
  profileCardTypography: {
    fontSize: '24px',
    fontFamily: font,
    fontWeight: '600',
    color: fieldColor,
    letterSpacing: '0',
  },
  profileCardRoleContainer: {
    marginTop: '5px',
    display: 'flex',
    alignItems: 'center',
  },
  profileCardRoleTypography: {
    fontSize: '18px',
    fontFamily: font,
    fontWeight: '600',
    color: fieldColor,
    letterSpacing: '0',
  },
  profileCardIcon: {
    marginRight: '5px',
    fontSize: '20px',
  },
}));

const ProfileCard = ({
  role,
  lastName,
  firstName,
  userTimezone,
}) => {
  const classes = profileCardStyles();
  const userTz = TimeTools.generateTimezoneListFormat(userTimezone || 'UTC');

  return (
    <Card className={classes.profileCard}>
      <ProfileImageUploader />
      <CardContent className={classes.profileCardContent}>
        <Typography className={classes.profileCardTypography}>
          {`${firstName} ${lastName}`}
        </Typography>
        <Typography className={classes.profileCardTypography}>
          {userTz.tz}
        </Typography>
        <div className={classes.profileCardRoleContainer}>
          <VerifiedUser className={classes.profileCardIcon} />
          <Typography className={classes.profileCardRoleTypography}>
            {role}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export default compose(withUserData)(ProfileCard);
