import React from 'react';
import { compose } from 'redux';
import useMeasure from 'react-use-measure';
import { styled } from '@mui/material/styles';

import { DateTime } from 'luxon';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';

import {
  Close as CloseIcon,
  Pause as PauseIcon,
  Alarm as PauseDummyIcon,
  PlayArrow as PlayIcon,
  AlarmOff as PlayDummyIcon,
  Search as SearchIcon,
} from '@mui/icons-material';

import PaperSection from '../../../../Components/PaperSection';
import VirtualisedTable from '../../../../Components/VirtualisedTable/VirtualisedTable';

import { NAV_TYPES, NAV_TYPES_ARRAY } from '../../../../../Utils/NavTypes';
import { setTimeToMidnight } from '../../../../../Utils/TimeUtils';
import { getColumns } from './columns';

import withTrackerData from '../../../../../redux/WithData/tracker';
import withTrackerActions from '../../../../../redux/WithActions/tracker';
import withLocationData from '../../../../../redux/WithData/location';
import withLocationActions from '../../../../../redux/WithActions/location';

const BLANK_SELECTION = {
  index: -1,
  rows: [],
};

const CLASS_HIGHLIGHT = 'highlight';

function renderRow({
  params: {
    className,
    columns,
    index,
    key,
    rowData,
    style,
    onRowClick,
  },
  highlightedRows,
}) {
  const a11yProps = { 'aria-rowindex': index + 1 };

  a11yProps['aria-label'] = 'row';
  a11yProps.tabIndex = 0;

  const Row = styled('div')(({ theme }) => ({
    userSelect: 'none',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    [`&.${CLASS_HIGHLIGHT}`]: {
      backgroundColor: theme.palette.action.selected,
      '&:hover': {
        backgroundColor: theme.palette.action.focus,
      },
    },
  }));
  
  if (highlightedRows?.includes(rowData.id)) {
    className = className + ' ' + CLASS_HIGHLIGHT;
  }

  return (
    <Row
      {...a11yProps}
      className={className}
      key={key}
      role="row"
      style={style}
      onClick={(event) => onRowClick(event, index)}
    >
      {columns}
    </Row>
  );
}

const PositionsSection = ({
  sx,

  tracker,
  isTrackerLoading,
  filteredLocations,
  locationFilter,
  areLocationsLoading,
  isGeneratingDummyLocations,
  getLocations,
  setSelectedLocations,   // TODO: make selection an array of IDs - other components can match it against the list of filtered units
  setGenerateDummyLocations,
}) => {
  const [ref, bounds] = useMeasure();

  /**
   * Filter types:
   * 
   * Count:     'locationTypes' + 'limit'                 --- 'startTime' & 'endTime' undefined
   * Timestamp: 'locationTypes' + 'startTime' + 'endTime' --- 'limit' of 200?
   */

  // TODO: only update position data once the date picker dialog is closed
  const [filter, setFilter] = React.useState({
    // locationTypes: [ NAV_TYPES.GPS.id, NAV_TYPES.GPS_LO.id, NAV_TYPES.GSM.id, NAV_TYPES.WIFI.id ],
    locationTypes: [ NAV_TYPES.GPS.id, NAV_TYPES.GSM.id, NAV_TYPES.WIFI.id ],
    rangeType: '10',
    startTime: null,
    endTime: null,
  });

  const [dataSelection, setDataSelection] = React.useState({ ...BLANK_SELECTION });

  // React.useEffect(() => {
  //   // TODO: Import filter on first render
  // }, [])

  // Get recent location data
  React.useEffect(() => {
    if (tracker?.simiccid) {
      // TODO: use UI-defined filter?
      getLocations({
        trackerId: tracker.simiccid,
        limit: 10,
        gps: true,
        gsm: true,
        wifi: true,
      });
    }
  }, [
    tracker,
    getLocations,
  ]);

  React.useEffect(() => {
    // Change 'selected locations' based on user-selection
    setSelectedLocations(dataSelection.rows.length === 0 ? filteredLocations : filteredLocations.filter(location => dataSelection.rows.includes(location.id)));
  }, [
    dataSelection,
    filteredLocations,
    setSelectedLocations,
  ]);

  const [columns, setColumns] = React.useState([]);

  // Setup the table columns
  React.useEffect(() => {
    if (bounds.width) {
      setColumns(getColumns(bounds.width, handleClickCopy));
    }
  }, [
    bounds,
  ]);

  function handleChangeLocationTypes(_, newValue) {
    setFilter({
      ...filter,
      locationTypes: newValue
    });
  }

  function handleChangeRangeType(_, newValue) {
    // At least one option must be selected
    if (newValue !== null) {
      setFilter({
        ...filter,
        rangeType: newValue
      });
    }
  }

  function handleChangeStartTime(newValue) {
    setFilter({
      ...filter,
      startTime: newValue
    });
  }

  function handleChangeEndTime(newValue) {
    setFilter({
      ...filter,
      endTime: newValue
    });
  }

  function handleClickCopy(event, text) {
    if (text) {
      navigator.clipboard.writeText(text);
    }

    event.stopPropagation();
  }
  
  function handleClickSearch() {
    setDataSelection({
      index: -1,
      rows: [],
    })

    let startTime = undefined;
    let endTime = undefined;

    switch (filter.rangeType) {
      case 'today':
        startTime = setTimeToMidnight(DateTime.now());
        break;

      case 'yesterday':
        endTime = setTimeToMidnight(DateTime.now());
        startTime = endTime.minus({ days: 1 });
        break;

      case 'custom':
        startTime = filter.startTime || undefined;
        endTime = filter.endTime || undefined;
        break;
    }

    getLocations({
      trackerId: tracker.simiccid,
      limit: parseInt(filter.rangeType) || 10,
      gps: filter.locationTypes.includes(NAV_TYPES.GPS.id) || filter.locationTypes.includes(NAV_TYPES.GPS_LO.id),
      gsm: filter.locationTypes.includes(NAV_TYPES.GSM.id),
      wifi: filter.locationTypes.includes(NAV_TYPES.WIFI.id),
      startTime,
      endTime,
    });
  }

  // Manage selecting/de-selecting rows - click to toggle or shift-click to select a range
  function handleClickRow(event, index) {
    // New location event occurs & matches filter -> added to list without changing selection
    // New filter applied -> clear selection

    // 'previous' row is -1 by default

    const newSelection = {}

    if (event.shiftKey) {
      // Do not update 'previous' row
      newSelection.index = dataSelection.index;

      let newRows = [];

      // Get all rows between this and the previously-selected row (ensure indices are without the bounds of the array of locations)
      for (let i = Math.max(Math.min(newSelection.index, index), 0); i <= Math.min(Math.max(newSelection.index, index), filteredLocations.length - 1); i++) {
        newRows.push(filteredLocations[i].id);
      }

      // Add new rows to existing selection
      newSelection.rows = [ ...new Set(dataSelection.rows.concat(newRows)) ];
    }
    else {
      // Toggle this row
      // Update 'previous' row
      newSelection.index = index;

      // Row is already selected -> remove it from selection
      if (dataSelection.rows.includes(filteredLocations[index].id)) {
        newSelection.rows = dataSelection.rows.filter(eventId => eventId !== filteredLocations[index].id);
      }
      // Row is not selected -> add it to selection
      else {
        newSelection.rows = [ ...dataSelection.rows, filteredLocations[index].id ];
      }
    }

    setDataSelection(newSelection);
  }
  
  function handleClickDeselectAll() {
    setDataSelection({ ...BLANK_SELECTION });
  }

  function getSelectionComponent() {
    return (dataSelection?.rows?.length > 0) ? (
      <Box sx={theme => ({ position: 'absolute', right: theme.spacing(2), top: '4px', display: 'flex', flexDirection: 'row' })}>
        <Typography sx={{ m: 'auto 0', mr: 1 }}>{dataSelection.rows.length} row(s) selected</Typography>
        <IconButton onClick={handleClickDeselectAll}><CloseIcon/></IconButton>
      </Box>
    ) : (
      <></>
    );
  }

  return (
    <PaperSection ref={ref} sx={{ ...sx, p: 0, overflow: 'hidden' }}>
      <Stack spacing={1} sx={{ m: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography variant="h6">Positions</Typography>

          <IconButton onClick={() => setGenerateDummyLocations(!isGeneratingDummyLocations)} sx={{ ml: 'auto', mr: 1 }} title={isGeneratingDummyLocations ? 'Disable periodic dummy data' : 'Send periodic dummy data'}>
            {isGeneratingDummyLocations ? <PauseDummyIcon/> : <PlayDummyIcon/>}
          </IconButton>

          {/* TODO: change to FilterButton? */}
          <Button variant="contained" startIcon={<SearchIcon/>} onClick={handleClickSearch}>Search</Button>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'row'}}>
          <Typography sx={{ m: 'auto 0', minWidth: '120px' }}>Type</Typography>
          <ToggleButtonGroup
            size="small"
            value={filter.locationTypes}
            onChange={handleChangeLocationTypes}
          >
            {NAV_TYPES_ARRAY.map(nav => (
              <ToggleButton key={nav.id} value={nav.id} color={nav.colour}>{nav.label}</ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'row'}}>
          <Typography sx={{ m: 'auto 0', minWidth: '120px' }}>Shortcuts</Typography>
          <ToggleButtonGroup
            exclusive
            size="small"
            value={filter.rangeType}
            onChange={handleChangeRangeType}
          >
            <ToggleButton value="1">1</ToggleButton>
            <ToggleButton value="10">10</ToggleButton>
            <ToggleButton value="100">100</ToggleButton>
            <ToggleButton value="today">Today</ToggleButton>
            <ToggleButton value="yesterday">Yesterday</ToggleButton>
            <ToggleButton value="custom">Custom Range</ToggleButton>
          </ToggleButtonGroup>
        </Box>

        {filter.rangeType === 'custom' && (
          <Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', ml: '120px' }}>
              <DateTimePicker
                renderInput={(props) => <TextField {...props} sx={{ ...props?.sx, maxWidth: '250px', mr: 2 }} />}
                label="From"
                value={filter.startTime}
                onChange={handleChangeStartTime}
              />
              <DateTimePicker
                renderInput={(props) => <TextField {...props} sx={{ ...props?.sx, maxWidth: '250px' }} />}
                label="To"
                value={filter.endTime}
                onChange={handleChangeEndTime}
              />
            </Box>
          </Box>
        )}
      </Stack>

      <Divider/>
      
      <Box sx={{ height: '100%', position: 'relative' }}>
        {getSelectionComponent()}

        <VirtualisedTable
          hover
          rowHeight={40}
          columns={columns}
          rowRenderer={(params) => renderRow({ params, highlightedRows: dataSelection.rows })}
          onRowClick={handleClickRow}
          loading={!bounds.width || areLocationsLoading}
          rowCount={areLocationsLoading ? 0 : filteredLocations.length}
          rowGetter={({ index }) => filteredLocations[index]}
        />
      </Box>
    </PaperSection>
  );
}

export default compose(withTrackerData, withTrackerActions, withLocationData, withLocationActions)(PositionsSection);