import React from 'react';
import { compose } from 'redux';

import {
  Box,
  Typography,
} from '@mui/material';

import PaperSection from '../../../Components/PaperSection';
import TagButton from '../../../Components/TagButton';

import { TAGS_SUBSCRIPTION, TAGS_COMMAND, TAGS_OPERATOR, TAGS_SERVER } from '../../../../Utils/Tags';

import withTrackerData from '../../../../redux/WithData/tracker';

const TagsSection = ({
  sx,
  
  tracker,
  isTrackerLoading,
}) => {
  const SERVICE_TAG_IDS = TAGS_SUBSCRIPTION.map(tag => tag.id);

  const [activeTags, setActiveTags] = React.useState([ 'ignoreId', 'special', 'standard', 'customSettings' ]);
  const [pendingTags, setPendingTags] = React.useState([]);

  const handleClickCommandTag = (tag, active, pending) => {
    // Active tags -> toggle pending state - active state updates once the unit wakes up
    if (!pending) {
      setPendingTags([
        ...pendingTags,
        tag.id,
      ]);
    }
    else {
      setPendingTags(pendingTags.filter(pendingTag => pendingTag !== tag.id));
    }
  }

  const handleClickOperatorTag = (tag, active) => {
    // Passive tags -> toggle active state directly
    if (!active) {
      setActiveTags([
        ...activeTags,
        tag.id,
      ]);
    }
    else {
      setActiveTags(activeTags.filter(activeTag => activeTag !== tag.id));
    }
  }

  const handleClickSubscriptionTag = (tag, active) => {
    // Service tags -> only one tag may be active at a time & can only be de-activated by selecting a different service tag
    if (!active) {
      const newTags = activeTags.filter(activeTag => !SERVICE_TAG_IDS.includes(activeTag));   // Remove all service tags
      newTags.push(tag.id);                                                                   // Add selected service tag

      setActiveTags(newTags);
    }
  }

  // const testSx = { mb: 1, mr: 1 };

  return (
    <PaperSection sx={{ ...sx }}>
      <Typography variant="h6" sx={{ mb: 1 }}>Subscription Tags</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', flexFlow: 'wrap' }}>
        {TAGS_SUBSCRIPTION.map((tag, index) => (
          <TagButton key={index} tag={tag} active={activeTags.includes(tag.id)} onClick={handleClickSubscriptionTag} sx={{ mb: 1, mr: 1 }} />
        ))}
      </Box>
      
      <Typography variant="h6" sx={{ mt: 4, mb: 1 }}>Command Tags</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', flexFlow: 'wrap' }}>
        {TAGS_COMMAND.map((tag, index) => (
          <TagButton key={index} tag={tag} active={activeTags.includes(tag.id)} pending={pendingTags.includes(tag.id)} onClick={handleClickCommandTag} sx={{ mb: 1, mr: 1 }} />
        ))}
      </Box>
      
      <Typography variant="h6" sx={{ mt: 4, mb: 1 }}>Operator Tags</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', flexFlow: 'wrap' }}>
        {TAGS_OPERATOR.map((tag, index) => (
          <TagButton key={index} tag={tag} active={activeTags.includes(tag.id)} onClick={handleClickOperatorTag} sx={{ mb: 1, mr: 1 }} />
        ))}
      </Box>
      
      <Typography variant="h6" sx={{ mt: 4, mb: 1 }}>Server Tags</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', flexFlow: 'wrap' }}>
        {TAGS_SERVER.map((tag, index) => (
          <TagButton key={index} tag={tag} active={activeTags.includes(tag.id)} sx={{ mb: 1, mr: 1 }} />
        ))}
      </Box>
      
      {/* <Box sx={{ display: 'flex', flexDirection: 'row', flexFlow: 'wrap', mt: 4 }}>
        <TagButton tag={TAGS_SUBSCRIPTION[0]} sx={testSx} onClick={() => {}} active/>
        <TagButton tag={TAGS_SUBSCRIPTION[0]} sx={testSx} onClick={() => {}}/>      
        <TagButton tag={TAGS_COMMAND[0]} sx={testSx} onClick={() => {}} active/>
        <TagButton tag={TAGS_COMMAND[0]} sx={testSx} onClick={() => {}}/>
        <TagButton tag={TAGS_COMMAND[0]} sx={testSx} onClick={() => {}} active pending/>
        <TagButton tag={TAGS_COMMAND[0]} sx={testSx} onClick={() => {}} pending/>
        <TagButton tag={TAGS_OPERATOR[0]} sx={testSx} onClick={() => {}} active/>
        <TagButton tag={TAGS_OPERATOR[0]} sx={testSx} onClick={() => {}}/>
        <TagButton tag={TAGS_SERVER[0]} sx={testSx} active/>
        <TagButton tag={TAGS_SERVER[0]} sx={testSx}/>
        <TagButton tag={TAGS_SERVER[1]} sx={testSx} active/>
        <TagButton tag={TAGS_SERVER[1]} sx={testSx}/>
      </Box> */}
    </PaperSection>
  );
}

export default compose(withTrackerData)(TagsSection);