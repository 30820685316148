export const ResponseTypes = {
  ActionCompleted: 'ActionCompleted',
  RefreshedAccess: 'RefreshedAccess',
  HealthCheckedAlive: 'HealthCheckedAlive',
  HealthCheckedDied: 'HealthCheckedDied',
  Redirect: 'Redirect', // 400
  BadRequest: 'BadRequest',
  Unauthorized: 'Unauthorized',
  PaymentRequired: 'PaymentRequired',
  Forbidden: 'Forbidden',
  NotFound: 'NotFound',
  MethodNotAllowed: 'MethodNotAllowed',
  NotAcceptable: 'NotAcceptable',
  ClientError: 'ClientError', // 500
  Error: 'Error',
  Exception: 'Exception',
};

export const getResultTypeByStatus = (code) => {
  let resultType = ResponseTypes.NotFound;

  // eslint-disable-next-line default-case
  switch (true) {
    case (code < 300):
      resultType = ResponseTypes.ActionCompleted;
      break;
    case (code === 400):
      resultType = ResponseTypes.BadRequest;
      break;
    case (code === 401):
      resultType = ResponseTypes.Unauthorized;
      break;
    case (code === 402):
      resultType = ResponseTypes.PaymentRequired;
      break;
    case (code === 403):
      resultType = ResponseTypes.Forbidden;
      break;
    case (code === 404):
      resultType = ResponseTypes.NotFound;
      break;
    case (code === 405):
      resultType = ResponseTypes.MethodNotAllowed;
      break;
    case (code === 406):
      resultType = ResponseTypes.NotAcceptable;
      break;
    case (code >= 300 && code < 400):
      resultType = ResponseTypes.Redirect;
      break;
    case (code >= 407 && code < 500):
      resultType = ResponseTypes.ClientError;
      break;
    case (code <= 500):
      resultType = ResponseTypes.Error;
      break;
  }
  return resultType;
};

export const ErrorHandler = (error) => {
  let status = 505;
  let data;
  let resultType = ResponseTypes.Exception;
  try {
    if ('format' in error && error.format === 'helios') {
      return error;
    }
    if ('response' in error && 'data' in error.response) {
      status = error.response.data.status;
      data = error.response.data;
      resultType = getResultTypeByStatus(error.response.data.status);
    } else {
      data = { message: error.message };
    }
  } catch (exception) {
    return {
      format: 'helios',
      type: ResponseTypes.Exception,
      code: 500,
      data: { message: exception.message },
    };
  }

  return {
    format: 'helios',
    type: resultType,
    code: status,
    data,
  };
};

export const ResponseHandler = (payload) => {
  let status = 404;
  let data;
  let resultType = ResponseTypes.NotFound;
  try {
    if ('format' in payload && payload.format === 'helios') {
      return payload;
    }
    if ('status' in payload && 'data' in payload) {
      status = payload.status;
      data = payload.data;
      resultType = getResultTypeByStatus(payload.status);
    } else {
      data = payload;
    }
  } catch (exception) {
    return {
      format: 'helios',
      type: ResponseTypes.Exception,
      code: 500,
      data: { message: exception.message },
    };
  }

  return {
    format: 'helios',
    type: resultType,
    code: status,
    data,
  };
};
