import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import {
  Box,
  Button,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';

import SignIn from './SignIn';
import SignUp from './SignUp';

import { useWindowDimensions } from '../../Utils/ScreenSize';

import { ReactComponent as RawMTrackLogo } from '../../Assets/SVGs/mtrack_logo.svg';
import { ReactComponent as RawAutomatricsLogo } from '../../Assets/SVGs/automatrics_logo.svg';

const AUTOMATRICS_WIDTH = 270;
const MTRACK_WIDTH = 300;

const AutomatricsLogo = (
  <a href="https://mtrack.launching-soon.com/applications/">
    <RawAutomatricsLogo width={`${AUTOMATRICS_WIDTH}px`} height={undefined} />
  </a>
);

const MTrackLogo = styled(RawMTrackLogo)({
  margin: '0 auto',
});

// TODO: update tab switching to match Unit/Customer/Franchise tabs & URL
// TODO: can 'history' be used to auto-redirect back to previous page if user times out?
const Login = ({ history }) => {
  const { isMobileWidth } = useWindowDimensions();
  const location = useLocation();

  console.log(history)

  // Set the browser tab's title to the current UI tab
  React.useEffect(() => {
    document.title = location.pathname === '/login' ? `Sign in - MTrack` : `Sign up - MTrack`;
  }, [ location ]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {!isMobileWidth ? (
        <Box sx={{ display: 'flex', flexDirection: 'row', m: 2, mb: 8 }}>
          {AutomatricsLogo}

          <Button variant="text" sx={{ m: 'auto', mr: 0 }}>English</Button>
        </Box>
      ) : (
        <Button variant="text" sx={{ m: 2, ml: 'auto' }}>English</Button>
      )}

      <Paper sx={{
        display: 'flex',
        flexDirection: 'column',
        p: 4,
        pb: 2,
        m: { mobile: 0, tablet: '0 auto' },
        width: { mobile: '100%', tablet: '500px' },
        borderRadius: { mobile: 0, tablet: 3 },
      }}>
        <MTrackLogo width={`${MTRACK_WIDTH}px`} height={undefined} />
        <Tabs
          variant="fullWidth"
          value={location.pathname === '/login' ? 0 : 1}
          aria-label="tabs"
          sx={{ mt: 2, mb: 2 }}
        >
          <Tab label="Sign in" component={Link} to="/login" />
          <Tab label="Sign up" component={Link} to="/register" />
        </Tabs>
        {location.pathname === '/login'
          ? <SignIn />
          : <SignUp history={history} />}
      </Paper>

      <Typography variant="caption" color="text.secondary" sx={{ ml: 'auto', mr: 'auto', mt: 2 }}>v{process.env.REACT_APP_VERSION}</Typography>

      {isMobileWidth && (
        <Box sx={theme => ({ position: 'absolute', bottom: theme.spacing(4), left: '50%', ml: `-${AUTOMATRICS_WIDTH / 2}px` })}>
          {AutomatricsLogo}
        </Box>
      )}
    </Box>
  )
};

export default Login;
