import React from 'react';
import { PlayArrow, Pause } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { compose } from 'redux';
import TrackerOnWebSocketActions from '../../../Hocs/WithAction/TrackerOnWebSocket';
import TrackerOnWebSocketData from '../../../Hocs/WithData/Trackers/trackerWebsocket';

const PlayWebSocketButton = ({
  areMapUpdatesPaused, pauseMapUpdates,
}) => (
  <IconButton onClick={pauseMapUpdates} size="large">
    { areMapUpdatesPaused ? <PlayArrow className="websocket-icon" /> : <Pause className="websocket-icon" />}
  </IconButton>
);
export default compose(TrackerOnWebSocketActions, TrackerOnWebSocketData)(PlayWebSocketButton);
