import React from 'react';
import { styled, alpha } from '@mui/material/styles';

import {
  Box,
  ButtonBase,
  Typography,
} from '@mui/material';

import Image from '../Components/Image';

import defaultProfile from '../../Assets/Images/profile.png';

const LOGO_SIZE = 96;

const CardBase = styled(ButtonBase)(({ theme }) => ({
  width: '360px',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.borderRadius(2),
  boxShadow: theme.shadows[2],
  padding: theme.spacing(2),
  flexDirection: 'column',
  alignItems: 'start',

  transition: theme.transitions.create(
    ['background-color', 'box-shadow', 'border-color', 'color'],
    {
      duration: theme.transitions.duration.short,
    },
  ),

  '&:hover': {
    backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity),

    // Reset on touch devices, it doesn't add specificity
    '@media (hover: none)': {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));

const FranchiseCard = ({
  franchise,
  sx,
}) => {
  return (
    <CardBase href={`/franchises/${franchise.id}`} sx={sx}>
      <Typography variant="h5" sx={{ m: '0 auto', fontWeight: 500 }}>{franchise.name}</Typography>
      <Typography sx={{ m: '0 auto', mb: 1 }}>{franchise.region}</Typography>

      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <Image src={defaultProfile} fit="contain" height={LOGO_SIZE} width={LOGO_SIZE} sx={{ borderRadius: 1 }}/>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', m: 'auto 0', ml: 2, mr: 2 }}>
          <Typography>Tag</Typography>
          <Typography>Customers</Typography>
          <Typography>Units</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', m: 'auto', mr: 1 }}>
          <Typography>{franchise.tag}</Typography>
          <Typography>{franchise.customers}</Typography>
          <Typography>{franchise.units}</Typography>
        </Box>
      </Box>
    </CardBase>
  );
}

export default FranchiseCard;