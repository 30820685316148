import makeStyles from '@mui/styles/makeStyles';

import { tableRowOnHoverPurple } from '../../../Styles/styles';

const accountsTableStyles = makeStyles(() => ({
  container: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tableHead: {
    backgroundColor: '#F0F0F0',
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: tableRowOnHoverPurple,
    },
  },
  spinnerContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default accountsTableStyles;
