import React from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';

import {
  Close as ClearIcon,
  Delete as DeleteIcon,
  NotificationsOff as NotificationDisabled,
  NotificationsActive as NotificationEnabled,
  Save as SaveIcon,
} from '@mui/icons-material';

import PaperSection from '../../../Components/PaperSection';
import VirtualisedTable from '../../../Components/VirtualisedTable/VirtualisedTable';
import ConfinedTypography from '../../../Components/Typographies/ConfinedTypography';

import { METHODS, METHODS_ARRAY } from './methods';
import { EVENTS, EVENTS_ARRAY } from './events';
import {
  useWindowDimensions,
  getHalfPaperSectionWidth,
  TABLET_MIN_WIDTH,
} from '../../../../Utils/ScreenSize';
import { SCROLLBAR_WIDTH } from '../../../../Utils/Defines';
import { SORT_ORDERS } from '../../../../Utils/Sorting';

const getColumns = (totalWidth, onToggleEntry, onDeleteEntry) => {
  const COL_ICON = (totalWidth < TABLET_MIN_WIDTH) ? 30 : 40;
  const ICON_SIZE = (totalWidth < TABLET_MIN_WIDTH) ? 'small' : 'medium';
  
  const COL_METHOD = 150;
  const COL_EVENT = totalWidth - COL_METHOD - (2 * COL_ICON) - SCROLLBAR_WIDTH;

  return [
    { width: COL_METHOD, label: 'Method', dataKey: 'method', sortable: true, render: ({ cellData, rowData }) =>
      <ConfinedTypography disabled={!rowData.enabled}>{METHODS[cellData].label}</ConfinedTypography>
    },
    { width: COL_EVENT, label: 'Event', dataKey: 'event', sortable: true, render: ({ cellData, rowData }) =>
      <ConfinedTypography disabled={!rowData.enabled}>{EVENTS[cellData].label}</ConfinedTypography>
    },
    { width: COL_ICON, label: '', dataKey: 'enabled', noPadding: true, render: ({ cellData, rowIdx }) => (
      <IconButton size={ICON_SIZE} onClick={() => { onToggleEntry(rowIdx) }}>
        {cellData ? <NotificationEnabled fontSize={ICON_SIZE}/> : <NotificationDisabled fontSize={ICON_SIZE}/>}
      </IconButton>
    )},
    { width: COL_ICON, label: '', dataKey: 'removable', noPadding: true, render: ({ cellData, rowIdx }) => (cellData ? (
      <IconButton size={ICON_SIZE} onClick={() => { onDeleteEntry(rowIdx) }}>
        <DeleteIcon fontSize={ICON_SIZE}/>
      </IconButton>
    ) : <></>)},
  ];
}

const NotificationsSection = ({
  sx,
}) => {
  const theme = useTheme();

  const windowDimensions = useWindowDimensions();
  const { isMobileWidth } = windowDimensions;

  const [methods, setMethods] = React.useState([]);
  const [events, setEvents] = React.useState([]);

  const [entries, setEntries] = React.useState([
    { method: METHODS.sms.id, event: EVENTS.event1.id, enabled: true, removable: false },
    { method: METHODS.email.id, event: EVENTS.event2.id, enabled: true, removable: false },
    { method: METHODS.email.id, event: EVENTS.event3.id, enabled: false, removable: false },
    { method: METHODS.sms.id, event: EVENTS.event4.id, enabled: false, removable: true },
  ]);

  const [sortBy, setSortBy] = React.useState({
    column: 'event',
    dir: SORT_ORDERS.asc.key,
  });

  const handleChangeMethods = (_, newTypes) => {
    setMethods(newTypes);
  }

  const handleClickEvent = (e) => {
    const event = e.target.value;
    
    if (events.includes(event)) {
      setEvents(events.filter(x => x !== event))
    }
    else {
      setEvents([
        ...events,
        event,
      ])
    }
  }

  const handleClickSave = () => {
    if (!validateInputs()) {
      return;
    }

    // TODO: add entry to list via API

    let newEntries = [];

    methods.forEach(method => {
      events.forEach(event => {
        newEntries.push({
          method: method,
          event: event,
          enabled: true,
          removable: true,
        })
      })
    });

    setEntries([
      ...entries,
      ...newEntries,
    ])

    setMethods([]);
    setEvents([]);
  }

  const handleClickClear = () => {
    setMethods([]);
    setEvents([]);
  }

  const handleToggleEntry = (rowIdx) => {
    // TODO: update entry via API

    setEntries(entries.map((entry, idx) => {
      // Toggle 'enabled' state of specified entry
      if (idx === rowIdx) {
        return {
          ...entry,
          enabled: !entry.enabled
        };
      }
      else {
        return entry;
      }
    }));
  }

  const handleDeleteEntry = (rowIdx) => {
    // TODO: remove entry from list via API

    const entryToDelete = entries[rowIdx];

    setEntries(entries.filter((entry) => entry !== entryToDelete));
  }

  const PAPER_WIDTH = getHalfPaperSectionWidth(windowDimensions, theme);
  const columns = getColumns(PAPER_WIDTH, handleToggleEntry, handleDeleteEntry);

  const handleClickColumnHeader = ({ columnIndex }) => {
    // TODO: move generic handler to VirtualisedTable

    // Already sorting by this column -> toggle direction
    if (sortBy.column === columns[columnIndex].dataKey) {
      setSortBy({
        ...sortBy,
        dir: (sortBy.dir === SORT_ORDERS.asc.key) ? SORT_ORDERS.desc.key : SORT_ORDERS.asc.key
      });
    }
    // Otherwise switch to this column
    else {
      setSortBy({
        column: columns[columnIndex].dataKey,
        dir: SORT_ORDERS.asc.key
      });
    }
    
    // update data
  }
  
  const validateInputs = () => {
    return methods.length > 0 && events.length > 0;
  }

  const checkForAnyInputs = () => {
    return methods.length > 0 || events.length > 0;
  }
  
  return (
    <PaperSection sx={{ ...sx, p: 0, overflow: 'hidden' }}>
      <Stack spacing={2} sx={{ p: 2 }}>
        <Typography variant="h6">Notifications</Typography>

        <Box sx={{ display: 'flex', flexDirection: !isMobileWidth ? 'row' : 'column' }}>
          <Typography sx={{ m: 'auto 0', minWidth: '150px' }}>{'Method(s)'}</Typography>
          <ToggleButtonGroup value={methods} onChange={handleChangeMethods} sx={{ mt: !isMobileWidth ? 0 : 1 }}>
            {METHODS_ARRAY.map((method) => (
              <ToggleButton key={method.id} value={method.id} size="small">
                {method.icon}
                <Typography sx={{ ml: 1 }}>{method.label}</Typography>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: !isMobileWidth ? 'row' : 'column' }}>
          <Typography sx={{ minWidth: '150px' }}>{'Event(s)'}</Typography>
          <Box sx={{ mt: !isMobileWidth ? 0 : 1 }}>
            {EVENTS_ARRAY.map((event) => (
              <Button
                key={event.id}
                value={event.id}
                variant={events.includes(event.id) ? 'contained' : 'outlined'}
                onClick={handleClickEvent}
                sx={{ mr: 1, mb: 1 }}
              >
                {event.label}
              </Button>
            ))}
          </Box>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Button variant="contained" startIcon={<SaveIcon/>} onClick={handleClickSave} disabled={!validateInputs()} sx={{ ml: 'auto', mr: 1 }}>Save</Button>
          <Button variant="contained" startIcon={<ClearIcon/>} onClick={handleClickClear} disabled={!checkForAnyInputs()}>Clear</Button>
        </Box>
      </Stack>

      <Divider/>

      <Box sx={{ height: '100%', minHeight: '300px' }}>
        <VirtualisedTable
          hover
          hideButtons
          rowHeight={40}
          columns={columns}
          rowCount={entries.length}
          rowGetter={({ index }) => entries[index]}
          onColumnHeaderClick={handleClickColumnHeader}
          sortBy={sortBy}
        />
      </Box>
    </PaperSection>
  );
}

export default NotificationsSection;