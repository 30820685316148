// Drawer
export const drawerWidth = 240;
export const drawerPurple = '#92278F';

// Pages
export const backgroundPurple = '#F5F6F9';

// Toggles
export const toggleUncheckedGrey = '#BDBDBD';
export const toggleCheckedPurple = '#92278F';
export const toggleTrackCheckedPurple = '#C893C7';
export const toggleTrackUncheckedGrey = '#C3C3C3';

// Texts
export const fieldColor = '#323547';
export const valueColor = '#4F5572';
export const textPurple = '#92278F';
export const font = 'Source Sans Pro';
export const semiBoldFont = 'Source Sans Pro SemiBold, sans-serif';

// Inputs
export const inputLabelColor = '#323547';
export const inputLabelFontSize = '14px';
export const inputLabelFontWeight = '600';
export const inputLabelLineHeight = '20px';
export const onInputFocusPurple = '#BA32B6';
export const onInputHoverPurple = '#BA32B6';
export const inputLabelLetterSpacing = '0px';
export const inputLabelFontFamily = 'Source Sans Pro';

// Cards
export const white = '#FFFFFF';
export const cardBorder = '1px solid rgba(146,159,180,0.25)';
export const cardBoxShadow = '0 2px 4px 0 rgba(31,37,59,0.1)';

// Icons
export const iconPurple = '#92278F';

// Buttons
export const buttonPurple = '#92278F';
export const buttonTextPurple = '#92278F';
export const onHoverButtonPurple = '#BA32B6';
export const disabledButtonTextGray = '#929FB4';
export const disabledButtonBackgroundGray = '#E4E7EC';
export const buttonBoxShadow = '0 1px 1px 0 rgb(31 37 59 / 14%), 0 2px 1px -1px rgb(31 37 59 / 12%), 0 1px 3px 0 rgb(31 37 59 / 20%)';

// Modals
export const modalBoxShadow = '0 8px 10px 1px rgba(31,37,59,0.14), 0 3px 14px 2px rgba(31,37,59,0.12), 0 5px 5px -3px rgba(31,37,59,0.2)';

// Lists
export const listItemOnHoverPurple = '#F5F6F9';

// Tables
export const tableRowOnHoverPurple = '#92278f45';

// Devices
export const mobile = 950;
export const viewportHeight = window.innerHeight;
