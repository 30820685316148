import React from 'react';

import {
  Typography,
} from '@mui/material';

import PaperSection from '../../../Components/PaperSection';

const MovementSection = ({
  sx,
}) => {
  return (
    <PaperSection sx={{ ...sx, p: 0 }}>
      <Typography variant="h6" sx={{ m: 2, ml: 3 }}>Movement Alert Schedule</Typography>
    </PaperSection>
  );
}

export default MovementSection;