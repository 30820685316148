import React, { useState } from 'react';
import { compose, withProps } from 'recompose';
import {
  Marker,
  GoogleMap,
  withScriptjs,
  withGoogleMap,
} from 'react-google-maps';
import MarkerCircle from './Circle';
import TrackerMapPosition from '../../../Hocs/WithData/Trackers/mapPosition';

const HeliosMap = (props) => {
  const { mapPosition } = props;
  const refs = { map: undefined };
  const [zoom, setZoom] = useState(18);
  function handleZoomChanged() {
    const zoomLevel = refs.map.getZoom();
    setZoom(zoomLevel);

    console.log(zoomLevel);
  }

  const mapPos_ALT = {
    latitude: 51.0030048,
    longitude: -0.9494693,
  }

  return (
    <GoogleMap
      key={`point${Math.random()}`}
      defaultZoom={zoom}
      defaultCenter={{ lat: mapPos_ALT.latitude ?? 0, lng: mapPos_ALT.longitude ?? 0 }}
      ref={(ref) => { refs.map = ref; }}
      onZoomChanged={handleZoomChanged}
      options={{
        tilt: 0,
        //scrollwheel: false,
        gestureHandling: 'cooperative',
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: true,
        rotateControl: false,
        fullscreenControl: true,
      }}
    >
      { (mapPos_ALT.latitude !== undefined && mapPos_ALT.longitude !== undefined)
        && (
          <>
            <MarkerCircle
              show
              zoom={zoom}
              lat={mapPos_ALT.latitude}
              long={mapPos_ALT.longitude}
              accuracy={mapPos_ALT.accuracy ?? 1}
            />
            <Marker position={{ lat: mapPos_ALT.latitude, lng: mapPos_ALT.longitude }} />
          </>
        ) }
    </GoogleMap>
  );
};
export default compose(
  withProps({
    // googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyC-XZRiRrnbYk2bponbVZhJ4h4qIAfjxjk&callback=initMap',
    googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyC-XZRiRrnbYk2bponbVZhJ4h4qIAfjxjk',
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '80vh', marginLeft: '30px', marginRight: '30px' }} />,
    mapElement: <div style={{ height: '100%' }} />,
  }),
  withScriptjs,
  withGoogleMap,
  TrackerMapPosition,
)(HeliosMap);
