import { connect } from 'react-redux';

const mapStateToProps = ({
  FranchiseData: {
    franchises,
    franchiseFolder,
    franchiseSecret,
    isDeletingFranchise,
    areFranchisesLoading,
    isCreatingNewFranchise,
    isFranchiseFolderLoading,
    isCreatingNewFranchiseFolder,
    isGeneratingNewFranchiseSecret,
  },
}) => ({
  franchises,
  franchiseFolder,
  franchiseSecret,
  isDeletingFranchise,
  areFranchisesLoading,
  isCreatingNewFranchise,
  isFranchiseFolderLoading,
  isCreatingNewFranchiseFolder,
  isGeneratingNewFranchiseSecret,
});

export default (WrappedComponent) => connect(mapStateToProps, null)(WrappedComponent);
