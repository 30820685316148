import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

import {
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SvgIcon,
} from '@mui/material';

import {
  Close as CloseIcon,
} from '@mui/icons-material';

function InfoDialog ({
  open,
  title,
  children,
  closeLabel = 'Cancel',
  closeIcon = <CloseIcon/>,
  width = 480,
  onClose,
}) {
  const DialogBase = styled(MuiDialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
      width: `${width}px`,
      maxWidth: '90vw',
    },
    '& .MuiDialogTitle-root': {
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    },
    '& .MuiDialogContent-root': {
      maxHeight: '60vh',
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    },
    '& .MuiDialogActions-root': {
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    },
  }));
  
  return (
    <DialogBase
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-header"
    >
      {title && (typeof(title) === 'string' ? (
        <DialogTitle>
          {title}
        </DialogTitle>
      ) : (
        {title}
      ))}

      <DialogContent dividers>
        {children}
      </DialogContent>

      <DialogActions>
        <Button variant="text" startIcon={closeIcon} onClose={onClose} sx={{ m: 'auto', ml: 0 }}>{closeLabel}</Button>
      </DialogActions>
    </DialogBase>
  );
}

InfoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  children: PropTypes.object.isRequired,
  closeLabel: PropTypes.string,
  closeIcon: PropTypes.instanceOf(SvgIcon),
  width: PropTypes.number,
  onClose: PropTypes.func.isRequired,
};

export default InfoDialog;