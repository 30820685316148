import AuthCall from './auth';

export default {
  getTracker: async (trackerId) => {
    return await AuthCall.get(`/api/v1/secure/tracker/instance/${trackerId}`);
  },

  getTrackers: async (limit = 50, offset = 0, filters = [], sorters = []) => {
    return await AuthCall.post('/api/v1/secure/tracker/search/global', { filters, sorters }, {}, { limit, offset });
  },

  getRecentAndBookmarkedTrackers: async () => {
    return await AuthCall.get('/api/v1/secure/account/favourites');
  },
  addTrackerToRecents: async (trackerId) => {
    return await AuthCall.post('/api/v1/secure/account/recents', { simiccids: [ trackerId ] });
  },
  addTrackerToBookmarks: async (trackerId) => {
    return await AuthCall.post('/api/v1/secure/account/favourites', { simiccids: [ trackerId ] });
  },
  removeTrackerFromBookmarks: async (trackerId) => {
    return await AuthCall.delete('/api/v1/secure/account/favourites', { simiccids: [ trackerId ] });
  },

  getCustomerTrackers: async () => {
    // TODO: replace with API customer trackers only, or global search but filter by customer
    return await AuthCall.post('/api/v1/secure/tracker/search/global', { filters: [], sorters: [] }, {}, { limit: 15, offset: 0 });
  },

  setTrackerState: async (trackerId, trackerState) => {
    return await AuthCall.post(`/api/v1/secure/tracker/state/${trackerId}`, { ...trackerState });
  },
  updateAsset: async (trackerId, assetDetails) => {
    return await AuthCall.post(`/api/v1/secure/tracker/asset/${trackerId}`, { ...assetDetails });
  },

  setTrackerSettings: async (trackerId, newSettings) => {
    return await AuthCall.post(`/api/v1/secure/tracker/settings/${trackerId}`, { ...newSettings });
  },
  sendRawTrackerSettings: async (trackerId, rawSettings) => {
    return await AuthCall.post(`/api/v1/secure/tracker/sms/${trackerId}`, {
      body: rawSettings,
      type: 'XT'
    });
  },
}