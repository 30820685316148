import { put, takeLatest } from 'redux-saga/effects';

import {
  getFranchise,
  editFranchise,
  deleteFranchise,
  loadNewFranchise,
  getAllFranchises,
  editingFranchise,
  deletingFranchise,
  loadAllFranchises,
  createNewFranchise,
  getFranchiseFolder,
  loadFranchiseFolder,
  cancelEditFranchise,
  editFranchiseSecret,
  editFranchiseRequest,
  loadingAllFranchises,
  creatingNewFranchise,
  cancelDeleteFranchise,
  loadingFranchiseFolder,
  loadNewFranchiseFolder,
  loadNewFranchiseSecret,
  deleteFranchiseRequest,
  getFranchiseFolderParent,
  createNewFranchiseFolder,
  stopLoadingAllFranchises,
  creatingNewFranchiseFolder,
  cancelCreatingNewFranchise,
  generateNewFranchiseSecret,
  editFranchiseSecretRequest,
  generatingNewFranchiseSecret,
} from '../Actions/FranchiseActions';
import { authApiHandler } from './auth';
import FolderAPI from '../../Api/Endpoints_OLD/folder';
import { sortArrayAlphabetically } from '../../Utils/Utils';
import FranchiseAPI from '../../Api/Endpoints_OLD/franchise';
import { addNotification } from '../Actions/system';
import { ResponseTypes } from '../../Api/Responses/baseResponse';

function* handleGetAllFranchises() {
  yield put(loadingAllFranchises());
  const response = yield authApiHandler(FranchiseAPI.getAllFranchises);
  if (response.type === ResponseTypes.ActionCompleted) {
    const franchises = sortArrayAlphabetically(response.data, 'name');
    yield put(loadAllFranchises({ franchises }));
  } if (response.type === ResponseTypes.Exception) {
    yield put(stopLoadingAllFranchises());
    yield put(addNotification({
      type: 'error',
      source: 'handleGetAllFranchises',
      title: 'Could not retrieve franchises',
      message: 'Please, check your connection and try again.',
    }));
  }
}

function* handleGetFranchise({ payload: { franchiseId } }) {
  yield put(loadingAllFranchises());
  const response = yield authApiHandler(FranchiseAPI.getFranchise, franchiseId);
  if (response.type === ResponseTypes.ActionCompleted) {
    yield put(loadAllFranchises({ franchises: [response.data] }));
  } if (response.type === ResponseTypes.Exception) {
    yield put(stopLoadingAllFranchises());
    yield put(addNotification({
      type: 'error',
      source: 'handleGetFranchise',
      title: 'Could not retrieve your franchise',
      message: 'Please, check your connection and try again.',
    }));
  }
}

function* handleGetFranchiseFolder({ payload: { currentFolderId } }) {
  yield put(loadingFranchiseFolder());
  const response = yield authApiHandler(FolderAPI.getFolderDetails, currentFolderId);
  if (response.type === ResponseTypes.ActionCompleted) {
    const {
      id,
      name,
      location,
      subfolders,
    } = response.data;
    const franchiseFolder = {
      id,
      name,
      parentFolder: name === 'root'
        ? { id: 'virtual-root', name: 'Virtual Root' }
        : { id: location.uuid, name: location.name },
      subfolders: subfolders.map((subfolder) => ({
        id: subfolder.uuid,
        name: subfolder.name,
      })),
    };
    yield put(loadFranchiseFolder({ franchiseFolder }));
  } if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      source: 'handleGetFranchiseHomeFolder',
      title: 'Could not retrieve franchise home folder',
      message: 'Please, check your connection and try again.',
    }));
  }
}

function* handleGetFranchiseFolderParent({ payload: { currentFolderId } }) {
  yield put(loadingFranchiseFolder());
  const response1 = yield authApiHandler(FolderAPI.getFolderDetails, currentFolderId);
  if (response1.type === ResponseTypes.ActionCompleted) {
    const { location: { uuid: parentFolderId } } = response1.data;
    const response2 = yield authApiHandler(FolderAPI.getFolderDetails, parentFolderId);
    if (response2.type === ResponseTypes.ActionCompleted) {
      const {
        id,
        name,
        location,
        subfolders,
      } = response2.data;

      const franchiseFolderParent = {
        id,
        name,
        parentFolder: name === 'root'
          ? { id: 'virtual-root', name: 'Virtual Root' }
          : { id: location.uuid, name: location.name },
        subfolders: subfolders.map((subfolder) => ({
          id: subfolder.uuid,
          name: subfolder.name,
        })),
      };
      yield put(loadFranchiseFolder({ franchiseFolder: franchiseFolderParent }));
    } if (response2.type === ResponseTypes.Exception) {
      yield put(addNotification({
        type: 'error',
        source: 'handleGetFranchiseHomeFolder',
        title: 'Could not retrieve franchise home folder',
        message: 'Please, check your connection and try again.',
      }));
    }
  } if (response1.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      source: 'handleGetFranchiseHomeFolder',
      title: 'Could not retrieve franchise home folder',
      message: 'Please, check your connection and try again.',
    }));
  }
}

function* handleCreateNewFranchiseFolder({ payload: { folderName, parentFolder } }) {
  yield put(creatingNewFranchiseFolder());
  const response = yield authApiHandler(FolderAPI.createNewFolder, folderName, parentFolder.id);
  if (response.type === ResponseTypes.ActionCompleted) {
    yield put(loadNewFranchiseFolder());
    yield put(addNotification({
      type: 'success',
      title: 'Folder successfully created!',
      source: 'handleCreateNewFranchiseFolder',
      message: `"${folderName}" has been successfully created inside the "${parentFolder.name}" folder. Use the "Select Folder" dropdown under the "Home Folder" section to navigate to it.`,
    }));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not create folder',
      source: 'handleCreateNewFranchiseFolder',
      message: 'Please, check your connection and try again.',
    }));
  }
}

function* handleGenerateNewFranchiseSecret() {
  yield put(generatingNewFranchiseSecret());
  const response = yield authApiHandler(FranchiseAPI.generateNewFranchiseSecret);
  if (response.type === ResponseTypes.ActionCompleted) {
    yield put(loadNewFranchiseSecret({ newFranchiseSecret: response.data.secrets[0] }));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not generate new secret',
      source: 'handleGenerateNewFranchiseSecret',
      message: 'Please, check your connection and try again.',
    }));
  }
}

function* handleCreateNewFranchise({ payload: { newFranchiseDetails } }) {
  yield put(creatingNewFranchise());
  const response = yield authApiHandler(FranchiseAPI.createNewFranchise, newFranchiseDetails);
  if (response.type === ResponseTypes.ActionCompleted) {
    yield put(loadNewFranchise({ newFranchise: response.data }));
    yield put(addNotification({
      type: 'success',
      title: 'Franchise successfully created!',
      source: 'handleCreateNewFranchise',
      message: `${newFranchiseDetails.name} has been successfully added to your franchise list.`,
    }));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(cancelCreatingNewFranchise());
    yield put(addNotification({
      type: 'error',
      title: 'Could not create franchise',
      source: 'handleCreateNewFranchise',
      message: 'Please, check your connection and try again.',
    }));
  }
}

function* handleDeleteFranchiseRequest({ payload: { franchise } }) {
  yield put(deletingFranchise());
  const response = yield authApiHandler(FranchiseAPI.deleteFranchise, franchise.id);
  if (response.type === ResponseTypes.ActionCompleted) {
    yield put(deleteFranchise({ franchiseId: franchise.id }));
    yield put(addNotification({
      type: 'success',
      title: 'Franchise successfully deleted!',
      source: 'handleDeleteFranchiseRequest',
      message: `${franchise.name} has been successfully deleted.`,
    }));
  } else if (response.type === ResponseTypes.ClientError) {
    yield put(cancelDeleteFranchise());
    yield put(addNotification({
      type: 'error',
      title: 'Could not delete franchise',
      source: 'handleDeleteFranchiseRequest',
      message: 'Please make sure no account, tracker or customer is assigned to this franchise before deleting it.',
    }));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(cancelDeleteFranchise());
    yield put(addNotification({
      type: 'error',
      title: 'Could not delete franchise',
      source: 'handleDeleteFranchiseRequest',
      message: 'Please, check your connection and try again.',
    }));
  }
}

function* handleEditFranchiseRequest({ payload: { updatedFranchiseDetails } }) {
  yield put(editingFranchise());
  const response = yield authApiHandler(FranchiseAPI.editFranchise, updatedFranchiseDetails);
  if (response.type === ResponseTypes.ActionCompleted) {
    yield put(editFranchise({ updatedFranchise: response.data }));
    yield put(addNotification({
      type: 'success',
      title: 'Franchise successfully updated!',
      source: 'handleEditFranchiseRequest',
      message: `${updatedFranchiseDetails.name} has been successfully updated.`,
    }));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(cancelEditFranchise());
    yield put(addNotification({
      type: 'error',
      title: 'Could not update franchise',
      source: 'handleEditFranchiseRequest',
      message: 'Please, check your connection and try again.',
    }));
  }
}

function* handleEditFranchiseSecretRequest({ payload: { franchiseId, newSecret } }) {
  yield put(editingFranchise());
  const response = yield authApiHandler(FranchiseAPI.editFranchiseSecret, franchiseId, newSecret);
  if (response.type === ResponseTypes.ActionCompleted) {
    yield put(editFranchiseSecret({ franchiseId, newSecret }));
    yield put(addNotification({
      type: 'success',
      title: 'Franchise successfully updated!',
      source: 'handleEditFranchiseSecretRequest',
      message: 'Your franchise has been successfully updated.',
    }));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(cancelEditFranchise());
    yield put(addNotification({
      type: 'error',
      title: 'Could not update your franchise',
      source: 'handleEditFranchiseSecretRequest',
      message: 'Please, check your connection and try again.',
    }));
  }
}

export default function* FranchiseSagas() {
  yield takeLatest(getFranchise, handleGetFranchise);
  yield takeLatest(getAllFranchises, handleGetAllFranchises);
  yield takeLatest(getFranchiseFolder, handleGetFranchiseFolder);
  yield takeLatest(createNewFranchise, handleCreateNewFranchise);
  yield takeLatest(editFranchiseRequest, handleEditFranchiseRequest);
  yield takeLatest(deleteFranchiseRequest, handleDeleteFranchiseRequest);
  yield takeLatest(createNewFranchiseFolder, handleCreateNewFranchiseFolder);
  yield takeLatest(getFranchiseFolderParent, handleGetFranchiseFolderParent);
  yield takeLatest(editFranchiseSecretRequest, handleEditFranchiseSecretRequest);
  yield takeLatest(generateNewFranchiseSecret, handleGenerateNewFranchiseSecret);
}
