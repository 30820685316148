import React from 'react';
import { compose } from 'redux';
import { useTheme } from '@mui/material/styles';

import {
  Box,
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material';

import PaperSection from '../../../../Components/PaperSection';
import VirtualisedTable from '../../../../Components/VirtualisedTable/VirtualisedTable';
import CustomPagination from '../../../../Components/VirtualisedTable/CustomPagination';
import ResultsCounter from '../../../../Components/VirtualisedTable/ResultsCounter';
import InfoDialog from '../../../../Components/Dialog_NEW/InfoDialog';

import { AUDIT_DETAILS_KEY } from '../../../../../Utils/Events';
import { getColumns } from './columns';
import { useWindowDimensions, getHalfPaperSectionWidth } from '../../../../../Utils/ScreenSize';

import withTrackerData from '../../../../../redux/WithData/tracker';
import withEventData from '../../../../../redux/WithData/event';
import withEventActions from '../../../../../redux/WithActions/event';

const ROWS_PER_PAGE_OPTIONS = [ 50, 200, 1000 ];

const DEFAULT_PAGE_LIMITS = {
  limit: 50,
  page: 0,
};

const AuditSection = ({
  filter = [],
  sx,
  
  tracker,
  auditLogs,
  totalNumOfAuditLogs,
  areAuditLogsLoading,
  areAuditDetailsLoading,
  getAuditLogs,
  getAuditDetails,
}) => {
  const theme = useTheme();
  const windowDimensions = useWindowDimensions();

  const TABLE_WIDTH = getHalfPaperSectionWidth(windowDimensions, theme);
  const COLUMNS = getColumns(TABLE_WIDTH, handleClickDetails);

  const [hasStartedLoading, setStartedLoading] = React.useState(false);
  const [pageLimits, setPageLimits] = React.useState(DEFAULT_PAGE_LIMITS);
  const [totalCount, setTotalCount] = React.useState(0);
  
  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const [auditDetailsIdx, setAuditDetailsIdx] = React.useState(-1);
  
  // Fetch all events matching the filter via the API
  React.useEffect(() => {
    if (tracker?.simiccid) {
      searchLogs();
  
      setStartedLoading(true);
    }
  }, [
    filter,
    tracker,
    getAuditLogs,
    setStartedLoading,
  ]);

  // Update the row/page counters based on whether the data is still loading
  React.useEffect(() => {
    let count = (pageLimits.page * pageLimits.limit) + auditLogs.length;

    if (count % pageLimits.limit === 0) {
      // If current page is full, assume there is another page (not guaranteed)
      count++;
    }
    
    setTotalCount(count);    // TODO: replace with 'totalNumOfAuditLogs' once API is updated
  }, [
    pageLimits,
    auditLogs,
    // totalNumOfAuditLogs,
  ]);

  function searchLogs(newFilter = undefined) {
    if (newFilter) {
      const updatedFilter = { ...pageLimits, ...newFilter };

      setPageLimits(updatedFilter);

      getAuditLogs({ trackerId: tracker.simiccid, ...{ ...updatedFilter, ...filter } });
    }
    else {
      getAuditLogs({ trackerId: tracker.simiccid, ...{ ...pageLimits, ...filter } });
    }
  }

  function handleClickDetails(auditEvent) {
    // Load audit details if necessary
    if (!auditEvent[AUDIT_DETAILS_KEY]) {
      getAuditDetails(auditEvent.id);
    }

    setAuditDetailsIdx(auditLogs.findIndex(event => event.id === auditEvent.id));
    
    setDialogOpen(true);
  }

  function handleCloseDetails() {
    setDialogOpen(false);

    setAuditDetailsIdx(-1);
  }
  
  function handlePageChange(newPage) {
    searchLogs({
      page: newPage,
    });
  }

  function handleRowsPerPageChange(newRowsPerPage) {
    searchLogs({
      limit: newRowsPerPage,
      page: 0,
    })
  }

  return (
    <PaperSection sx={{ ...sx, display: 'table', height: '100%', p: 0 }}>
      <Box sx={{ display: 'table-row', height: '100%', overflow: 'hidden' }}>
        <VirtualisedTable
          hover
          hideButtons
          rowHeight={40}
          columns={COLUMNS}
          loading={!hasStartedLoading || areAuditLogsLoading}
          rowCount={(!hasStartedLoading || areAuditLogsLoading) ? 0 : auditLogs.length}
          rowGetter={({ index }) => auditLogs[index]}
        />
      </Box>

      <Box sx={{ display: 'table-row', height: 0 }}>
        <Divider/>

        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <ResultsCounter
            rowCount={!hasStartedLoading ? 0 : auditLogs.length}
            totalCount={!hasStartedLoading ? 0 : totalCount}
            sx={{ m: 1, ml: 2 }}
          />
          <CustomPagination
            page={pageLimits.page}
            count={!hasStartedLoading ? 0 : totalCount}
            rowsPerPage={pageLimits.limit}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            sx={{ m: 'auto', mr: 1 }}
          />
        </Box>
      </Box>

      <InfoDialog
        open={isDialogOpen}
        title="Audit Details"
        onClose={handleCloseDetails}
      >
        {(!areAuditDetailsLoading && auditDetailsIdx > -1) ? (
          <Typography>{JSON.stringify(auditLogs[auditDetailsIdx][AUDIT_DETAILS_KEY])}</Typography>
        ) : (
          <CircularProgress sx={{ m: '0 auto' }}/>
        )}
      </InfoDialog>
    </PaperSection>
  );
}

export default compose(withTrackerData, withEventData, withEventActions)(AuditSection);