import React from 'react';
import { compose } from 'redux';
import makeStyles from '@mui/styles/makeStyles';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UnassignTrackerIcon from '@mui/icons-material/Close';

import { isAccountRoleRoot } from '../../../../Utils/Utils';
import { tableRowOnHoverPurple } from '../../../../Styles/styles';

import withFoldersData from '../../../../Hocs/WithData/folder';
import withUserData from '../../../../redux/WithData/user';
import withFoldersActions from '../../../../Hocs/WithAction/folder';

const assignedTrackersListStyles = makeStyles((theme) => ({
  assignedTrackersListContainer: {
    width: '100%',
    position: 'unset',
    marginBottom: '20px',
    boxShadow: 'unset',
    '& .MuiCollapse-wrapper': {
      maxHeight: '200px',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  assignedTrackersListHeader: {
    color: 'gray',
    fontSize: '1rem',
    fontWeight: theme.typography.fontWeightRegular,
  },
  assignedTrackersListItem: {
    display: 'flex',
    minHeight: '64px',
    borderRadius: '8px',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: '8px 4px 8px 16px',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: tableRowOnHoverPurple,
      // backgroundColor: theme.palette.action.action,
    },
  },
}));

function AssignedTrackersList({
  unassignTrackersRequest,
  selectedItems,
  role,
}) {
  const classes = assignedTrackersListStyles();

  const selectedTrackers = selectedItems.filter((selectedItem) => selectedItem.type === 'tracker');

  return (
    <Accordion className={classes.assignedTrackersListContainer}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.assignedTrackersListHeader}>
          Currently assigned trackers...
        </Typography>
      </AccordionSummary>
      {selectedTrackers.map((selectedTracker) => selectedTracker.franchise.name && (
      <AccordionDetails key={selectedTracker.id} className={classes.assignedTrackersListItem}>
        <Typography>
          {`${selectedTracker.name} (${selectedTracker.franchise.name})`}
        </Typography>
        {isAccountRoleRoot(role) && (
          <IconButton
            onClick={() => unassignTrackersRequest({
              selectedTrackersIds: [selectedTracker.id],
              franchise: {
                id: selectedTracker.franchise.id,
                name: selectedTracker.franchise.name,
              },
            })}
            size="large">
            <UnassignTrackerIcon />
          </IconButton>
        )}
      </AccordionDetails>
      ))}
      {selectedTrackers.map((selectedTracker) => selectedTracker.customer.name && (
        <AccordionDetails key={selectedTracker.id} className={classes.assignedTrackersListItem}>
          <Typography>
            {`${selectedTracker.name} (${selectedTracker.customer.name})`}
          </Typography>
          <IconButton
            onClick={() => unassignTrackersRequest({
              selectedTrackersIds: [selectedTracker.id],
              customer: {
                id: selectedTracker.customer.id,
                name: selectedTracker.customer.name,
              },
            })}
            size="large">
            <UnassignTrackerIcon />
          </IconButton>
        </AccordionDetails>
      ))}
    </Accordion>
  );
}

export default compose(withFoldersData, withFoldersActions, withUserData)(AssignedTrackersList);
