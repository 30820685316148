import { connect } from 'react-redux';

const mapStateToProps = ({
  CustomerData: {
    customers,
    customerSecret,
    isEditingCustomer,
    areCustomersLoading,
    isGeneratingNewCustomerSecret,
  },
}) => ({
  customers,
  customerSecret,
  isEditingCustomer,
  areCustomersLoading,
  isGeneratingNewCustomerSecret,
});

export default (WrappedComponent) => connect(mapStateToProps, null)(WrappedComponent);
