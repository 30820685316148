import React from 'react';
import { compose } from 'redux';
import { useParams, useHistory  } from 'react-router-dom';

import {
  Box,
  CircularProgress,
} from '@mui/material';

import TabHeader from '../Components/TabHeader';
import TabPanel from '../Components/TabPanel';
import PlaceholderPage from '../Components/PlaceholderPage';

import { ALL_TABS_ARRAY, getTabName } from './Tabs/tabs';
import { ROLES } from '../../Utils/Roles';
import { useWindowDimensions } from '../../Utils/ScreenSize';
import { capitaliseFirstLetter } from '../../Utils/Utils';

import withSystemActions from '../../redux/WithActions/system';
import withUserData from '../../redux/WithData/user';

/*
TODO:
- Redirect back to customer list if customerId is invalid

- Convert to class
- When mounted, update 'current customer'
- When unmounted, clear 'current customer'
 */

const CustomerDetails = ({
  role,
  setShowSubHeader,
}) => {
  // TODO: remove
  const count = React.useRef(0);
  React.useEffect(() => {
    count.current = count.current + 1;
    console.log(`Customer render #${count.current}`);
  });

  const { customerId, tab } = useParams();
  let history = useHistory();
  
  // const [hasStartedLoading, setStartedLoading] = React.useState(false);

  // Load customer data
  // React.useEffect(() => {
  //   getCustomer({ customerId });
  //   setStartedLoading(true);
  // }, [
  //   customerId,
  //   getCustomer,
  //   setStartedLoading,
  // ]);
  
  // TODO: If ID is invalid, redirect!
  // - Does the API differentiate 'ID not found' and 'Failed to reach server'?

  const { isMobileWidth } = useWindowDimensions();
  const HEADER_HEIGHT = 48;

  const [activeTab, setActiveTab] = React.useState('');
  
  // Set 'showSubHeader' so the main header renders correctly
  React.useEffect(() => { setShowSubHeader(true); }, [ setShowSubHeader ]);

  // Set active tab based on to URL
  React.useEffect(() => {
    let tabToShow = ALL_TABS_ARRAY[0].key;

    if (tab) {
      for (let i = 0; i < ALL_TABS_ARRAY.length; i++) {
        if (ALL_TABS_ARRAY[i].key.toLowerCase() === tab.toLowerCase()) {
          tabToShow = ALL_TABS_ARRAY[i].key;
          break;
        }
      }
    }

    setActiveTab(tabToShow);

    history.replace(`/customers/${customerId}/${capitaliseFirstLetter(tabToShow)}`);
  }, [
    history,
    role,
    tab,
    customerId,
  ]);

  // Set the browser tab's title to the current UI tab
  React.useEffect(() => {
    const tabName = getTabName(activeTab);

    document.title = tabName ? `${tabName} - *Customer-${customerId}* - MTrack` : `*Customer-${customerId}* - MTrack`;
  }, [
    activeTab,
    customerId,
  ]);

  const handleChangeTab = (_, tabValue) => {
    setActiveTab(tabValue);

    history.replace(`/customers/${customerId}/${capitaliseFirstLetter(tabValue)}`);
  };

  return (
    <>
      {role !== ROLES.user ? (
        <>
          <TabHeader tabs={ALL_TABS_ARRAY} tab={activeTab} onChangeTab={handleChangeTab} />
          {/* {
            (!hasStartedLoading || isCurrentTrackerLoading) ? (
              <CircularProgress sx={{ margin: 'auto' }} />
            ) : (
              <> */}
                {ALL_TABS_ARRAY.map((tab) => (
                  <TabPanel value={activeTab} key={tab.key} name={tab.key} content={tab.content} sx={{ mt: isMobileWidth ? 0 : `${HEADER_HEIGHT}px` }}/>
                ))}
              {/* </>
            )
          } */}
        </>
      ) : (
        <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
          <PlaceholderPage sx={{ m: 'auto' }}/>
        </Box>
      )}
    </>
  );
};

export default compose(withSystemActions, withUserData)(CustomerDetails);