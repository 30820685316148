import { compose } from 'redux';
import React, { useState } from 'react';

import {
  Dialog,
  Button,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';

import searchTrackersModalStyles from './style';
import withTrackersData from '../../../Hocs/WithData/tracker';
import withTrackersActions from '../../../Hocs/WithAction/tracker';

const SearchTrackersModal = ({ searchTrackers, areTrackersLoading }) => {
  const classes = searchTrackersModalStyles();

  const [open, setOpen] = useState(false);
  const [searchFilters, setSearchFilters] = useState({
    simiccid: '',
    name: '',
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSearchFilters({
      simiccid: '',
      name: '',
    });
  };

  const handleChangeSearchCriteria = ({ target: { name, value } }) => {
    setSearchFilters({
      ...searchFilters,
      [name]: value,
    });
  };

  const handleSearch = () => {
    searchTrackers({ searchFilters });
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        className={classes.searchTrackersModalOpenButton}
      >
        Search
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        className={classes.searchTrackersModalContentContainer}
      >
        <DialogTitle className={classes.searchTrackersModalHeader}>
          Search Trackers
        </DialogTitle>
        <DialogContent className={classes.searchTrackersModalContent}>
          <DialogContentText className={classes.searchTrackersModalSubheader}>
            Use the fields below to search trackers by their name or their serial number.
          </DialogContentText>
          <TextField
            fullWidth
            name="name"
            margin="dense"
            variant="outlined"
            label="Type tracker name"
            value={searchFilters.name}
            onChange={handleChangeSearchCriteria}
          />
          <TextField
            fullWidth
            margin="dense"
            name="simiccid"
            variant="outlined"
            value={searchFilters.simiccid}
            label="Type tracker serial number"
            onChange={handleChangeSearchCriteria}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.searchTrackersModalCancelButton}>
            Cancel
          </Button>
          <Button onClick={handleSearch} className={classes.searchTrackersModalSearchButton}>
            {areTrackersLoading ? 'Searching...' : 'Search'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default compose(withTrackersData, withTrackersActions)(SearchTrackersModal);
