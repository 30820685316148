import makeStyles from '@mui/styles/makeStyles';

import {
  font,
  mobile,
  fieldColor,
} from '../../../Styles/styles';

const searchTrackersModalStyles = makeStyles((theme) => ({
  searchTrackersModalOpenButton: {
    width: '49%',
    color: 'white',
    height: '48px',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.primary.light,
    },
    [theme.breakpoints.down(mobile)]: {
      marginBottom: '20px',
      width: '100%',
    },
  },
  searchTrackersModalContentContainer: {
    '& .MuiDialog-paper': {
      borderRadius: '8px',
      padding: '24px',
    },
  },
  searchTrackersModalContent: {
    overflow: 'unset',
    padding: '0px 0px 24px 0px',
  },
  searchTrackersModalHeader: {
    padding: 'unset',
    fontFamily: font,
    fontWeight: '600',
    color: fieldColor,
    letterSpacing: '0',
  },
  searchTrackersModalSubheader: {
    margin: 'unset',
    fontSize: '14px',
    fontFamily: font,
    lineHeight: '20px',
    letterSpacing: '0',
    padding: '15px 0px 10px 0px',
  },
  searchTrackersModalCancelButton: {
    margin: '0px',
    width: '100%',
    borderRaduis: '8px',
    color: theme.palette.primary.main,
    textTransform: 'none',
    maxWidth: 'fit-content',
  },
  searchTrackersModalSearchButton: {
    color: 'white',
    padding: '6px 15px',
    borderRadius: '8px',
    textTransform: 'none',
    margin: '0px 0px 0px 12px',
    backgroundColor: theme.palette.primary.main,
    '&:disabled': {
      backgroundColor: '#E4E7EC',
      color: '#929FB4',
    },
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

export default searchTrackersModalStyles;
