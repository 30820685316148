import {
  call,
  put,          // Dispatch an Action asynchronously
  putResolve,   // Dispatch an Action and wait for its response
  takeEvery,    // Process every request for this Action
  takeLatest,   // Process only the latest request if called multiple times concurrently
} from "redux-saga/effects";

import {
  getRemoteTracker,
  loadRemoteTracker,
  setRemoteTrackerLoading,
} from '../Actions/example';

import { addNotification } from '../Actions/system';

import { authApiHandler } from './auth';

import ExampleAPI from '../../Api/Endpoints/example';
import { ResponseTypes } from '../../Api/Responses/baseResponse';

// Function for retrieving data via API
export function* handleGetRemoteTracker({ payload: { trackerId } }) {
  yield put(setRemoteTrackerLoading(true));         // Set Store's 'isLoading' field to true

  let response = yield authApiHandler(ExampleAPI.loadRemoteTracker, trackerId);   // Call (authenticated) API function

  // Handle the response
  if (response.type === ResponseTypes.ActionCompleted) {
    yield put(loadRemoteTracker(response.data));    // Load data into Store
    yield put(setRemoteTrackerLoading(false));      // Set Store's 'isLoading' field to false
  }
  else {
    yield put(setRemoteTrackerLoading(false));      // Set Store's 'isLoading' field to false
    yield put(addNotification({                     // Shows an error notification to the user
      type: 'error',
      title: 'Could not retrieve tracker',
      source: 'handleGetRemoteTracker',
      message: 'Please check your connection and try again.',
    }));
  }
}

export default function* Sagas() {
  yield takeLatest(getRemoteTracker, handleGetRemoteTracker);
}