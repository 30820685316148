import React from 'react';

import ErrorIcon from '@mui/icons-material/Error';
import AccountIcon from '@mui/icons-material/Person';
import FranchiseIcon from '@mui/icons-material/Business';
import TrackerIcon from '@mui/icons-material/LocationOn';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import CustomerIcon from '@mui/icons-material/EmojiTransportation';

import folderListStyles from './style';

const ItemIcom = ({ isListVew, itemType }) => {
  const classes = folderListStyles();

  const renderItemIcon = () => {
    switch (itemType) {
      case 'folder':
        return (
          <FolderIcon
            className={isListVew
              ? classes.folderListItemIconSmall
              : classes.folderListItemIconBig}
          />
        );
      case 'tracker':
        return (
          <TrackerIcon
            className={isListVew
              ? classes.folderListItemIconSmall
              : classes.folderListItemIconBig}
          />
        );
      case 'franchise':
        return (
          <FranchiseIcon
            className={isListVew
              ? classes.folderListItemIconSmall
              : classes.folderListItemIconBig}
          />
        );
      case 'account':
        return (
          <AccountIcon
            className={isListVew
              ? classes.folderListItemIconSmall
              : classes.folderListItemIconBig}
          />
        );
      case 'customer':
        return (
          <CustomerIcon
            className={isListVew
              ? classes.folderListItemIconSmall
              : classes.folderListItemIconBig}
          />
        );
      default:
        return (
          <ErrorIcon
            className={isListVew
              ? classes.folderListItemIconSmall
              : classes.folderListItemIconBig}
          />
        );
    }
  };

  return (
    <>
      {renderItemIcon()}
    </>
  );
};

export default ItemIcom;
