import React, { useState } from 'react';

import {
  Autocomplete,
  TextField,
  Typography,
} from '@mui/material';

import timezones from '../../../Assets/availableTimezones.json';

const AccountDetails = ({
  values,
  setValues,
  handleChange,
}) => {
  const timezoneOptions = timezones.map((timezone) => timezone.label);
  const initialTimezone = timezones.find((timezone) => timezone.tz === values.timezone).label;

  const [timezone, setTimezone] = useState(initialTimezone);
  const [inputValue, setInputValue] = useState('');

  const handleTimezoneChange = (_, newValue) => {
    if (newValue) {
      const newTimezone = timezones.find((timezone) => timezone.label === newValue);
      setTimezone(newValue);
      setValues({
        ...values,
        timezone: newTimezone.tz,
      });
    }
  };

  return (
    <>
      <Typography variant="h6">
        Account Details
      </Typography>
      <TextField
        required
        name="firstName"
        label="First Name"
        value={values.firstName || ''}
        onChange={handleChange} />
      <TextField
        required
        name="lastName"
        label="Last Name"
        value={values.lastName || ''}
        onChange={handleChange} />
      <TextField
        required
        name="email"
        label="Email Address"
        value={values.email || ''}
        onChange={handleChange}
        error={values.errors.email}
        helperText={values.errors.email ? 'Please enter a valid email address' : ''} />
      <Typography variant="body2" sx={{ paddingTop: 2 }}>
        Choose a timezone for the new account:
      </Typography>
      <Autocomplete
        name="timezone"
        value={timezone}
        options={timezoneOptions}
        inputValue={inputValue}
        onChange={(_, newValue) => handleTimezoneChange(_, newValue)}
        onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
        renderInput={(params) => <TextField {...params} variant="outlined"/>} />
    </>
  );
};

export default AccountDetails;