import React from 'react';
import { compose } from 'redux';

import {
  Box,
  Button,
  Divider,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from '@mui/material';

import {
  Delete as DiscardIcon,
  Save as SaveIcon,
} from '@mui/icons-material';

import PaperSection from '../../../Components/PaperSection';

import tempSettings from './tempSettings';

import withTrackerData from '../../../../redux/WithData/tracker';
import withTrackerActions from '../../../../redux/WithActions/tracker';

const SETTING_TITLE_WIDTH = '240px';

const DEFAULT_SETTINGS = {
  aGpsOn: false,
  accelerometerOn: false,
  debug: true,
  downloadFirmwareUpdate: false,
  forceUpdate: true,
  gpsAdditional2GInfo: false,
  gpsEphemerisValidFor: 0,
  gpsMaximumHdop: 0,
  gpsMinimumHdop: 0,
  gpsOn: false,
  gpsPowerMode: 'PSM_OFF',
  gpsWaitTime: 0,
  gsmCellsForTriangulation: 3,
  gtpOn: false,
  interval: 60,
  heartbeatType: 'INT',
  pursuitInterval: 15,
  mode: 'FACTORY',
  smsc: '+447723565974',
  tamperOn: false,
  uhfRadioOn: false,
  vhfRadioOn: false,
  wifiOn: false,
};

const Settings = ({
  tracker,
  isTrackerLoading,
  setTrackerSettings,
  sx,
}) => {
  const [values, setValues] = React.useState(DEFAULT_SETTINGS);

  const pendingValues = React.useRef({});

  React.useEffect(() => {
    if (!isTrackerLoading && tracker) {
      setValues({
        ...DEFAULT_SETTINGS,
        ...tracker.newSettings,
      });
  
      pendingValues.current = values;
    }
  }, [
    tracker,
    isTrackerLoading,
  ])

  const isInvalidInt = (value) => {
    return isNaN(Number(value));
  }

  const handleChangeBool = (e) => {
    if (values[e.target.name] !== e.target.checked) {
      setValues({
        ...values,
        [e.target.name]: e.target.checked,
      })
    }
  }

  const handleChangeInt = (e) => {
    if (values[e.target.name] !== e.target.value) {
      setValues({
        ...values,
        // [e.target.name]: Number(e.target.value),
        [e.target.name]: e.target.value,
      })
    }
  }

  const handleChangeString = (e) => {
    if (values[e.target.name] !== e.target.value) {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
      })
    }
  }

  const handleClickSave = () => {
    // Send settings to server
    setTrackerSettings({
      trackerId: tracker.simiccid,
      newSettings: values,
    });

    pendingValues.current = values;
  }

  const handleClickDiscard = () => {
    setValues(pendingValues.current);
  }

  const getSettingElement = (setting) => {
    let element = <></>

    switch (setting.type) {
      case 'bool':
        element = (
          <>
            <Switch
              name={setting.id}
              checked={values[setting.id]}
              onChange={handleChangeBool}
            />
            <Typography sx={{ m:'auto 0', ml: 1 }}>{`${values[setting.id]}`}</Typography>
          </>
        );
        break;

      case 'int':
        element = (
          <TextField
            name={setting.id}
            value={values[setting.id]}
            error={isInvalidInt(values[setting.id])}
            onChange={handleChangeInt}
            sx={{
              width: '300px',
            }}
          />
        );
        break;

      case 'string':
        element = (
          <TextField
            name={setting.id}
            value={values[setting.id]}
            onChange={handleChangeString}
            sx={{
              width: '300px',
            }}
          />
        );
        break;

      default:
        if (Array.isArray(setting.type)) {
          element = (
            <TextField
              select
              name={setting.id}
              value={values[setting.id]}
              onChange={handleChangeString}
              sx={{
                width: '300px',
              }}
            >
              {setting.type.map((type) => (
                <MenuItem key={type} value={type}>{type}</MenuItem>
              ))}
            </TextField>
          );
        }
        break;
    }

    return (
      <Box key={setting.id} sx={{ display: 'flex', flexDirection: 'row', mt: 1 }}>
        <Typography sx={{ minWidth: SETTING_TITLE_WIDTH, m: 'auto 0' }}>{setting.id}</Typography>

        {element}
      </Box>
    );
  }

  return (
    <PaperSection sx={{ ...sx, p: 0 }}>
      <Typography variant="h6" sx={{ m: 2, ml: 3 }}>Custom Settings (TEMP)</Typography>
      <Divider/>

      <Box sx={{ height: '100%', p: 3, pt: 1, pb: 2, overflow: 'auto' }}>
        {tempSettings.map((setting) => getSettingElement(setting))}
      </Box>

      <Divider/>
      <Box sx={{ m: 2, mr: 3, ml: 'auto' }}>
        <Button variant="contained" startIcon={<SaveIcon/>} sx={{ mr: 1 }} onClick={handleClickSave}>Save</Button>
        <Button variant="contained" startIcon={<DiscardIcon/>} onClick={handleClickDiscard}>Discard</Button>
      </Box>
    </PaperSection>
  );
}

export default compose(withTrackerData, withTrackerActions)(Settings);