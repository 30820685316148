import React from 'react';
import { Grid, CircularProgress } from '@mui/material';

import EventModalTable from './EventModalTable';
import EventModalSideBar from './EventModalSideBar';
import { trackerEventDetailsToRows } from '../../../../Utils/Utils';

const EventModal = ({
  selectedEvent,
  setActiveTab,
}) => {
  const header = [
    'Item',
    'Value',
  ];

  if (!selectedEvent) {
    return (
      <div className="loading">
        <CircularProgress />
      </div>
    );
  }

  const isSettingsEvent = selectedEvent.type === 'SETTINGS';

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={!isSettingsEvent && 9}>
        <EventModalTable
          header={header}
          className="infoAlign"
          rows={trackerEventDetailsToRows(selectedEvent)}
        />
      </Grid>
      {!isSettingsEvent
        && (
        <Grid item xs={12} sm={3}>
          <EventModalSideBar
            setActiveTab={setActiveTab}
            selectedEvent={selectedEvent}
          />
        </Grid>
        )}
    </Grid>
  );
};
export default EventModal;
