import React, { useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EssentialIcon from '@mui/icons-material/NotificationsActive';
import {
  Card,
  List,
  Divider,
  ListItem,
  Typography,
  IconButton,
  CardContent,
} from '@mui/material';

import ToggleOptions from './ToggleOptions';
import SubmitButtons from './SubmitButtons';
import TimezoneDropdown from './TimezoneDropdown';
import TimeIntervalDropdown from './TimeIntervalDropDown';
import ScheduleModeDropDown from './ScheduleModeDropdown';
import { textPurple } from '../../../../../Styles/styles';
import AddNewScheduleModal from './AddNewScheduleModal/AddNewScheduleModal';
import {
  scheduleModes,
  locationTypes,
  extractHoursFromTimeInterval,
  extractMinutesFromTimeInterval,
} from '../trackerSettingsHelpers';

const NewTrackerSettings = ({
  areNewTrackerSettingsUpdating,
  updateTrackerSettingsRequest,
  currentTracker,
  userTimezone,
  classes,
}) => {
  const initialSettings = {
    debug: currentTracker.newSettings.debug,
    timezone: currentTracker.newSettings.timezone,
    forceUpdate: currentTracker.newSettings.forceUpdate,
    calendarEvents: currentTracker.newSettings.calendarEvents,
    heartbeatType: scheduleModes.indexOf(currentTracker.newSettings.heartbeatType),
    intervalHour: extractHoursFromTimeInterval(currentTracker.newSettings.interval),
    intervalMinute: extractMinutesFromTimeInterval(currentTracker.newSettings.interval),
  };

  const schedulesLimit = 14;
  const [newTrackerSettings, setNewTrackerSettings] = useState({ ...initialSettings });

  const isCalendarMode = newTrackerSettings.heartbeatType === 1;

  const handleDeleteCalendarEvent = (_, calendarEventId) => {
    setNewTrackerSettings({
      ...newTrackerSettings,
      calendarEvents: newTrackerSettings.calendarEvents
        .filter((calendarEvent) => calendarEvent.id !== calendarEventId),
    });
  };

  return (
    <Card className={classes.trackerSettings}>
      <CardContent>
        <Typography className={classes.trackerSettingsHeader}>
          New Tracker Settings
        </Typography>
        <Typography className={classes.trackerSettingsSubHeader}>
          The settings below will not be active
          until the next scheduled wake-up time of the tracker.
        </Typography>
        <ToggleOptions
          classes={classes}
          newTrackerSettings={newTrackerSettings}
          setNewTrackerSettings={setNewTrackerSettings}
          currentSettings={currentTracker.currentSettings}
        />
        <Divider className={classes.trackerSettingsDivider} />
        <div className={classes.trackerSettingsScheduleModeContainer}>
          <Typography
            variant="h4"
            className={classes.trackerSettingsDropdownLabel}
            style={currentTracker.currentSettings.heartbeatType !== scheduleModes[newTrackerSettings.heartbeatType]
              ? { color: textPurple } : {}}
          >
            Schedule Mode
          </Typography>
          <Typography
            variant="h4"
            className={classes.trackerSettingsSchedulesCount}
          >
            {`${newTrackerSettings.calendarEvents?.length ?? 0}/${schedulesLimit}`}
          </Typography>
        </div>
        <ScheduleModeDropDown
          classes={classes}
          userTimezone={userTimezone}
          scheduleModes={scheduleModes}
          newTrackerSettings={newTrackerSettings}
          setNewTrackerSettings={setNewTrackerSettings}
        />
        {isCalendarMode
          ? (
            <Typography
              variant="h4"
              className={classes.trackerSettingsDropdownLabel}
              style={newTrackerSettings.timezone !== currentTracker.currentSettings.timezone
                ? { color: textPurple } : {}}
            >
              Timezone
            </Typography>
          )
          : (
            <Typography
              variant="h4"
              className={classes.trackerSettingsDropdownLabel}
              style={(newTrackerSettings.intervalHour * 60 + newTrackerSettings.intervalMinute)
                !== currentTracker.currentSettings.interval
                ? { color: textPurple } : {}}
            >
              Time Interval
            </Typography>
          )}
        {isCalendarMode && (
        <Typography className={classes.trackerSettingsSubHeader}>
          The timezone below will not affect your timezone nor the tracker timezone.
          It will be only used to specify the timezone of the scheduled wake up times added below.
        </Typography>
        )}
        {isCalendarMode
          ? (
            <>
              <TimezoneDropdown
                classes={classes}
                newTrackerSettings={newTrackerSettings}
                setNewTrackerSettings={setNewTrackerSettings}
              />
              <AddNewScheduleModal
                schedulesLimit={schedulesLimit}
                newTrackerSettings={newTrackerSettings}
                setNewTrackerSettings={setNewTrackerSettings}
              />
              {newTrackerSettings.calendarEvents && newTrackerSettings.calendarEvents.length !== 0 && (
                <List>
                  {newTrackerSettings.calendarEvents.map((calendarEvent) => (
                    <ListItem
                      key={calendarEvent.id}
                      className={classes.trackerSettingsCalendarEventListItemNewSettings}
                    >
                      {calendarEvent.essential && (
                        <EssentialIcon
                          className={classes.trackerSettingsCalendarEventEssentialIcon}
                        />
                      )}
                      <Typography className={classes.trackerSettingsCalendarEventTimeLabel}>
                        {`${calendarEvent.day} ${calendarEvent.time}`}
                      </Typography>
                      <Typography
                        className={classes.trackerSettingsCalendarEventLocationLabel}
                        style={{ backgroundColor: calendarEvent.backgroundColor }}
                      >
                        {locationTypes.find((type) => type.id === calendarEvent.locationType).name}
                      </Typography>
                      <IconButton
                        onClick={(_) => handleDeleteCalendarEvent(_, calendarEvent.id)}
                        size="large">
                        <DeleteIcon className={classes.trackerSettingsCalendarEventDeleteIcon} />
                      </IconButton>
                    </ListItem>
                  ))}
                </List>
              )}
            </>
          )
          : (
            <TimeIntervalDropdown
              classes={classes}
              newTrackerSettings={newTrackerSettings}
              setNewTrackerSettings={setNewTrackerSettings}
            />
          )}
        <SubmitButtons
          classes={classes}
          scheduleModes={scheduleModes}
          currentTracker={currentTracker}
          initialSettings={initialSettings}
          newTrackerSettings={newTrackerSettings}
          setNewTrackerSettings={setNewTrackerSettings}
          updateTrackerSettingsRequest={updateTrackerSettingsRequest}
          areNewTrackerSettingsUpdating={areNewTrackerSettingsUpdating}
        />
      </CardContent>
    </Card>
  );
};

export default NewTrackerSettings;
