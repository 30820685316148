import { DateTime } from 'luxon';

import {
  IconButton,
} from '@mui/material';

import {
  Info as DetailsIcon,
} from '@mui/icons-material';

import { formatDateTime } from '../../../../../Utils/TimeUtils';
import { TYPES } from './types';
import { SCROLLBAR_WIDTH } from "../../../../../Utils/Defines";
import { AUDIT_DETAILS_KEY } from '../../../../../Utils/Events';

export const getColumns = (totalWidth, handleClickDetails) => {
  const COL_TIMESTAMP = 170;
  const COL_USER      = 350;
  const COL_DETAILS   = 70;

  const remainingWidth = totalWidth - COL_TIMESTAMP - COL_USER - COL_DETAILS - SCROLLBAR_WIDTH;

  return [
    { width: COL_TIMESTAMP, label: 'Timestamp', dataKey: 'timestamp', render: ({ cellData }) => formatDateTime(DateTime.fromISO(cellData), true) },
    { width: COL_USER, label: 'User', dataKey: 'who', render: ({ cellData }) => cellData },   // TODO: Get the user's name
    { width: remainingWidth, label: 'Type', dataKey: 'type' , render: ({ cellData }) => TYPES[cellData].label},
    { width: COL_DETAILS, label: '', dataKey: AUDIT_DETAILS_KEY, render: ({ rowData }) => <IconButton onClick={() => handleClickDetails(rowData)}><DetailsIcon/></IconButton> },
  ];
};