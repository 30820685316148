import { put, takeLatest, select } from 'redux-saga/effects';

import {
  updateTrackerLocationFilterValue,
  setTrackerLocationFilter,
  receivedMessageTrackerOnWS,
  addLocationTrackerEventOnWSAction,
  addTrackerEventOnWSAction,
  setMapPositionAction,
  selectLocationEventAction,
  setSelectedEventAction,
  updateLocationEventAction,
} from '../Actions/TrackerActions';
import TimeTools from '../../Components/TimeTools';
import LocationEventHandler from '../../Components/LocationEvents';

const getTrackerLocationFilters = (state) => state.TrackerData.trackerLocationsEvent.filters;
const getTrackerLocationEvents = (state) => state.TrackerData.trackerLocationsEvent;
const areMapUpdatesPaused = (state) => state.TrackerData.areMapUpdatesPaused;

export function* setTrackerLocationFilterHandler({ payload }) {
  let filter = {};
  const currentFilters = yield select(getTrackerLocationFilters);
  switch (payload) {
    case 'SetLimit10':
      filter = { limit: 10 };
      break;
    case 'SetLimit50':
      filter = { limit: 50 };
      break;
    case 'SetLimit100':
      filter = { limit: 100 };
      break;
    case 'SetToday':
      if (currentFilters.settedFilter === 'TODAY') {
        filter = {
          settedFilter: '',
          startTime: undefined,
          endTime: undefined,
        };
      } else {
        const time = TimeTools.todayRange();
        filter = {
          settedFilter: 'TODAY',
          startTime: time.start.format(),
          endTime: time.end.format(),
        };
      }
      break;
    case 'SetYesterday':
      if (currentFilters.settedFilter === 'YESTERDAY') {
        filter = {
          settedFilter: '',
          startTime: undefined,
          endTime: undefined,
        };
      } else {
        const time = TimeTools.yesterdayRange();
        filter = {
          settedFilter: 'YESTERDAY',
          startTime: time.start.format(),
          endTime: time.end.format(),
        };
      }
      break;
    case 'GPS':
      filter = { gps: !currentFilters.gps };
      break;
    case 'WIFI':
      filter = { wifi: !currentFilters.wifi };
      break;
    case 'GSM':
      filter = { gsm: !currentFilters.gsm };
      break;
    default:
  }
  yield put(updateTrackerLocationFilterValue(filter));

  return { ...currentFilters, ...filter };
}

export function* receivedMessageTrackerOnWSHandler({ payload: { message: event } }) {
  const filter = yield select(getTrackerLocationFilters);
  const locationData = yield select(getTrackerLocationEvents);
  const isMapPaused = yield select(areMapUpdatesPaused);

  // If the event is not a Location event, or we are looking for events from Yesterdar, we aren't
  // interested to add it in Map Tab.
  if (filter.settedFilter !== 'YESTERDAY' && LocationEventHandler.isLocationType(event)) {
    // We only can add the event if the event Type is enabled in the filters
    if ((filter.gps && LocationEventHandler.isGPSType(event))
        || (filter.gsm && LocationEventHandler.isGSMType(event))
        || (filter.wifi && LocationEventHandler.isWIFIType(event))
    ) {
      if (!isMapPaused
        && !LocationEventHandler.hasSelectedItem(locationData.data)) {
        // Update mapPosition
        yield put(setMapPositionAction(event.details.position));
      }
      // Add new event in location event list.
      yield put(addLocationTrackerEventOnWSAction(event));
    }
  }
  // Add new event in event list. used in tracker Log Tab
  yield put(addTrackerEventOnWSAction(event));
}

export function* selectLocationEventActionHandler({
  payload: { event: selectedEvent, force = false },
}) {
  const trackerlocation = yield select(getTrackerLocationEvents);
  const messages = [...trackerlocation.data];
  LocationEventHandler.setSelected(messages, selectedEvent, force);
  const event = LocationEventHandler.getSelectedEventObj(messages);
  if (event) {
    yield put(updateLocationEventAction(messages));
    yield put(setMapPositionAction(event.details.position));
    yield put(setSelectedEventAction(selectedEvent));
  }

  return true;
}

export default function* LocationEventsSagas() {
  yield takeLatest(`${setTrackerLocationFilter}`, setTrackerLocationFilterHandler);
  yield takeLatest(`${receivedMessageTrackerOnWS}`, receivedMessageTrackerOnWSHandler);
  yield takeLatest(`${selectLocationEventAction}`, selectLocationEventActionHandler);
}
