import React from 'react';
import { compose } from 'redux';

import {
  Box,
  CircularProgress,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';

import {
  AccountCircle as PersonalIcon,
  Bookmark as CompanyIcon,
  Sort as SortIcon,
} from '@mui/icons-material';

import PaperSection from '../Components/PaperSection';
import UnitCard from './UnitCard';

import { sortArrayAlphabetically } from '../../Utils/Utils';
import { HEALTH_STATES } from '../Components/UnitHealth';
import { SORT_ORDERS } from '../../Utils/Sorting';
import { ROLES } from '../../Utils/Roles';

import withUserData from '../../redux/WithData/user';
import withTrackerData from '../../redux/WithData/tracker';
import withTrackerActions from '../../redux/WithActions/tracker';

const SORT_FIELDS = [
  { field: 'name', label: 'Name', type: 'text', order: SORT_ORDERS.asc },
  { field: 'battery', label: 'Battery', type: 'number', order: SORT_ORDERS.asc },
  { field: 'health', label: 'Health', type: 'health', order: SORT_ORDERS.desc },
  // { field: 'purchaseDate', label: 'Purchase Date', type: 'timestamp', order: SORT_ORDERS.desc },
];

const CustomerUnitsSection = ({
  hasStartedLoading,
  sx,

  role,
  customerTrackers,
  areCustomerTrackersLoading,
}) => {
  const [sorterIdx, setSorterIdx] = React.useState(0);
  const [sortedCustomerUnits, setSortedCustomerUnits] = React.useState([]);

  // Sort the units on initialisation
  React.useEffect(() => {
    sortUnits(sorterIdx);
  }, [
    customerTrackers,
  ]);

  // TODO: switch between unit sizes depending on screen space available
  function getUnitCardSize() {
    return sortedCustomerUnits.length > 6 ? 'medium' : 'large';
  }

  function sortByNumber(a, b, sorter) {
    // Order by main category first
    if (a[sorter.field] < b[sorter.field]) return -1 * sorter.order.multiplier;
    if (a[sorter.field] > b[sorter.field]) return  1 * sorter.order.multiplier;

    // Then by name
    const aName = a.name.toLowerCase();
    const bName = b.name.toLowerCase();
    if (aName < bName) return -1;
    if (aName > bName) return 1;

    return 0;
  }

  function sortByHealth(a, b, sorter) {
    // Order by health first
    const aHealth = HEALTH_STATES.indexOf(a.health);
    const bHealth = HEALTH_STATES.indexOf(b.health);
    if (aHealth < bHealth) return -1 * sorter.order.multiplier;
    if (aHealth > bHealth) return  1 * sorter.order.multiplier;

    // Then by name
    const aName = a.name.toLowerCase();
    const bName = b.name.toLowerCase();
    if (aName < bName) return -1;
    if (aName > bName) return 1;

    return 0;
  }

  function sortUnits(index) {
    const sorter = SORT_FIELDS[index];

    if (!sorter) {
      return;
    }

    switch (sorter.type) {
      case 'text':
        setSortedCustomerUnits(sortArrayAlphabetically(customerTrackers, sorter.field));
        break;

      case 'number':
        setSortedCustomerUnits(customerTrackers.sort((a, b) => sortByNumber(a, b, sorter)));
        break;

      case 'health':
        setSortedCustomerUnits(customerTrackers.sort((a, b) => sortByHealth(a, b, sorter)));
        break;
    }
  }

  function handleChangeSorterIdx(_, index) {
    setSorterIdx(index);

    sortUnits(index);
  }

  return (
    <PaperSection sx={{ ...sx, p: 0 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2, mb: 2, mr: 3, ml: 3 }}>
        <PersonalIcon fontSize="large" sx={{ m: 'auto 0' }}/>
        <Typography variant="h4" sx={{ m: 'auto', ml: 1 }}>My Units</Typography>

        <SortIcon fontSize="large" sx={{ m: 'auto 0', mr: 1 }}/>
        <ToggleButtonGroup
          exclusive
          value={sorterIdx}
          onChange={handleChangeSorterIdx}
        >
          {SORT_FIELDS.map((sorter, index) => (
            <ToggleButton key={sorter.field} value={index}>{sorter.label}</ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>

      <Divider/>

      <Box sx={{ display: 'flex', flexDirection: 'row', flexFlow: 'wrap', width: '100%', minHeight: '100px', justifyContent: 'center', overflow: 'auto', p: 3 }}>
        {(!hasStartedLoading || areCustomerTrackersLoading) ? (
          <CircularProgress/>
        ) : sortedCustomerUnits.map((unit) => (
          <UnitCard key={unit.simiccid} tracker={unit} size={getUnitCardSize()} sx={{ m: 1, mt: 0, mb: 2 }}/>
        ))}
      </Box>
    </PaperSection>
  )
}

export default compose(withUserData, withTrackerData, withTrackerActions)(CustomerUnitsSection);