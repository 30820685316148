import React from 'react';
import { compose } from 'redux';
import { useTheme } from '@mui/material/styles';

import {
  Box,
  Typography,
} from '@mui/material';

import {
  Bookmark as BookmarkIcon,
} from '@mui/icons-material';

import PaperSection from '../Components/PaperSection';
import VirtualisedTable, { FONT_SIZE_L } from '../Components/VirtualisedTable/VirtualisedTable';

import { useWindowDimensions } from '../../Utils/ScreenSize';
import { getColumns } from './columns';
import { renderRow } from './rows';

import withUserData from '../../redux/WithData/user';
import withTrackerData from '../../redux/WithData/tracker';
import withTrackerActions from '../../redux/WithActions/tracker';

const BookmarkedUnitsSection = ({
  hasStartedLoading,
  sx,

  role,
  bookmarkedTrackers,
  areBookmarkedTrackersLoading,
}) => {
  const theme = useTheme();
  const windowDimensions = useWindowDimensions();

  const columns = getColumns(windowDimensions, theme, role);

  return (
    <PaperSection sx={{ ...sx, p: 0 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2, mb: 2, mr: 3, ml: 3 }}>
        <BookmarkIcon fontSize="large" sx={{ m: 'auto 0' }}/>
        <Typography variant="h4" sx={{ ml: 1 }}>Bookmarks</Typography>
      </Box>

      <Box sx={{ height: '100%', position: 'relative' }}>
        <VirtualisedTable
          hover
          rowHeight={48}
          fontSize={FONT_SIZE_L}
          columns={columns}
          rowRenderer={renderRow}
          loading={!hasStartedLoading || areBookmarkedTrackersLoading}
          rowCount={!hasStartedLoading || areBookmarkedTrackersLoading ? 0 : bookmarkedTrackers.length}
          rowGetter={({ index }) => bookmarkedTrackers[index]}
        />
      </Box>
    </PaperSection>
  )
}

export default compose(withUserData, withTrackerData, withTrackerActions)(BookmarkedUnitsSection);