import validator from 'validator';

import store from '../redux/Store';
import { loginRequest, registerRequest } from '../redux/Actions/AuthActions';

import { isPasswordStrong, passwordHelperText } from '../Utils/Utils';

const Auth = {
  LoginInitialValue: () => ({
    email: '',
    password: '',
  }),

  LoginValidate: (values) => {
    const errors = {};

    // Email
    if (!values.email) {
      errors.email = 'Required';
    } else if (!validator.isEmail(values.email)) {
      errors.email = 'Invalid email address';
    }

    // Password
    if (!values.password) {
      errors.password = 'Required';
    } else if (isPasswordStrong(values.password)) {
      errors.password = passwordHelperText;
    }
    
    return errors;
  },

  LoginSubmit: (data) => {
    store.dispatch(loginRequest(data));
  },
  
  RegisterInitialValue: () => ({
    codeValidation: '',
    email: '',
    firstName: '',
    lastName: '',
    password: '',
  }),

  RegisterValidate: (values) => {
    const errors = {};

    // Email
    if (!values.email) {
      errors.email = 'Required';
    // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    } else if (!validator.isEmail(values.email)) {
      errors.email = 'Invalid email address';
    }

    // Password
    if (!values.password) {
      errors.password = 'Required';
    } else if (isPasswordStrong(values.password)) {
      errors.password = passwordHelperText;
    }

    // First Name
    if (!values.firstName) {
      errors.firstName = 'Required';
    }

    // Last name
    if (!values.lastName) {
      errors.lastName = 'Required';
    }

    // codeValidation
    if (!values.codeValidation) {
      errors.codeValidation = 'Required';
    }

    return errors;
  },

  RegisterSubmit: ({ credentials, history }) => {
    store.dispatch(registerRequest({ credentials, history }));
  },
};

// export default  connect(null, mapDispatchToProps )(Auth)
export default Auth;
