import {
  CircleOutlined as CircleIcon,
  RectangleOutlined as RectangleIcon,
} from '@mui/icons-material';

export const SHAPES = {
  rectangle: {
    id: 'rectangle',
    icon: <RectangleIcon/>,
  },
  circle: {
    id: 'circle',
    icon: <CircleIcon/>,
  },
  // polygon: {
  //   id: 'polygon',
  //   icon: <RectangleIcon/>,
  // },
}

export const SHAPES_ARRAY = [
  SHAPES.rectangle,
  SHAPES.circle,
  // SHAPES.polygon,
];