export const EVENTS = {
  event1: { id: 'event1', label: 'Event 1' },
  event2: { id: 'event2', label: 'Event 2' },
  event3: { id: 'event3', label: 'Event 3' },
  event4: { id: 'event4', label: 'Event 4' },
  event5: { id: 'event5', label: 'Event 5' },
  event6: { id: 'event6', label: 'Event 6' },
  event7: { id: 'event7', label: 'Event 7' },
  event8: { id: 'event8', label: 'Event 8' },
};

export const EVENTS_ARRAY = [
  EVENTS.event1,
  EVENTS.event2,
  EVENTS.event3,
  EVENTS.event4,
  EVENTS.event5,
  EVENTS.event6,
  EVENTS.event7,
  EVENTS.event8,
];