import 'typeface-roboto';
import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { compose } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import './Sass/helios.scss';
import store from './redux/Store';
import * as serviceWorker from './serviceWorkerRegistration';
import RequireReload from './Views/System/RequireReload';
import SysNotification from './Views/System/Notifications';
import Navigation from './Routes/Navigation';

import lightTheme from './Themes/Light_OLD';
import { getTheme } from './Themes/index.ts';

import withSystemData from './redux/WithData/system';
import { getListOfTz } from './Utils/TimeUtils';

/*
TODO:
- Remove 'react-google-maps' dependency (old unsupported library)
*/

const AppThemeProvider = ({
  appTheme,
  appLanguage,

  children,
}) => {
  const theme = React.useMemo(() => getTheme(appTheme), [appTheme]);

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
}

const MTrackThemeProvider = compose(withSystemData)(AppThemeProvider);

const App = () => {
  // TODO: update 'adapterLocale' with user-selected language
  return (
    <StyledEngineProvider injectFirst>
      <Provider store={store}>
        <MTrackThemeProvider>
          <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-gb">
            <CssBaseline />
            <Navigation />
            <SysNotification />
            <RequireReload />
          </LocalizationProvider>
        </MTrackThemeProvider>
      </Provider>
    </StyledEngineProvider>
  )
};

// React 18
const root = createRoot(document.getElementById('root'));

root.render(
  <Router>
    <App/>
  </Router>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.register();
