import { DateTime } from 'luxon';

import timezoneOffsets from './timezones';

let listOfTz = undefined;

export function getTimezones() {
  if (!listOfTz) {
    listOfTz = [
      { label: 'Local', luxonTz: 'local' },
      ...timezoneOffsets.map((tz) => ({
        offset:   tz,
        label:    formatTzLabel(tz),
        luxonTz:  `UTC${tz >= 0 ? '+' : ''}${tz}`,
      }))
    ];
  }

  return listOfTz;
}

function formatTzLabel(tz) {
  let isNegative = tz < 0;

  if (isNegative) tz *= -1;

  let hours   = Math.floor(tz);
  let minutes = (tz % 1) * 60;

  return `UTC${isNegative ? '-' : '+'}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
}

export const setTimeToMidnight = (dateTime) => {
  return dateTime.set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
}

export const formatDate = (dateTime) => {
  return dateTime.toLocaleString(DateTime.DATE_SHORT);
}

export const formatTime = (dateTime, includeSeconds = false) => {
  return dateTime.toLocaleString(includeSeconds ? DateTime.TIME_WITH_SECONDS : DateTime.TIME_SIMPLE);
}

export const formatDateTime = (dateTime, includeSeconds = false) => {
  return dateTime.toLocaleString(includeSeconds ? DateTime.DATETIME_SHORT_WITH_SECONDS : DateTime.DATETIME_SHORT);
}