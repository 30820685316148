const ALL_FILTERS = {
  startTime: {
    id: 'startTime',
    label: 'From',
    type: 'dateTime',
  },
  endTime: {
    id: 'endTime',
    label: 'To',
    type: 'dateTime',
  },
};

export function getFilters() {
  let filters = [
    ALL_FILTERS.startTime,
    ALL_FILTERS.endTime,
  ];

  return filters;
}