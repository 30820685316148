import React from 'react';
import { compose } from 'redux';

import {
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import {
  Edit as EditIcon,
  SwapHoriz as TransferIcon,
} from '@mui/icons-material';

import PaperSection from '../../../Components/PaperSection';
import Image from '../../../Components/Image';
import FormDialog from '../../../Components/Dialog_NEW/FormDialog';

import { ROLES } from '../../../../Utils/Roles';
import defaultProfile from '../../../../Assets/Images/profile.png';

import withUserData from '../../../../redux/WithData/user';

const LOGO_SIZE = 144;

const OverviewTab = ({
  role,
}) => {
  const [transferCustomersOpen, setTransferCustomersOpen] = React.useState(false);
  const [transferUnitsOpen, setTransferUnitsOpen] = React.useState(false);

  const emails = [

  ];

  const phoneNumbers = [
    {
      number: '01234 567890',
      description: 'Landline',
    },
    {
      number: '97531 147369',
      description: 'Mobile 1',
    },
    {
      number: '84265 014739',
      description: 'Mobile 2',
    },
  ];

  const address = [

  ];

  // TODO: replace with real franchises from API
  const franchises = [
    'Franchise 1',
    'Franchise 2',
    'Franchise 3',
  ];

  const handleClickTransferCustomers = () => {
    // TODO: get franchises via API OR get from 'store'?

    setTransferCustomersOpen(true);
  }

  const handleClickTransferUnits = () => {
    // TODO: get franchises via API OR get from 'store'?

    setTransferUnitsOpen(true);
  }

  const handleSubmitTransferCustomers = (values) => {
    console.log(`Transferred customers to '${values.franchise}'`);

    // TODO: API call

    setTransferCustomersOpen(false);
  }

  const handleSubmitTransferUnits = (values) => {
    console.log(`Transferred unassigned units to '${values.franchise}'`);

    // TODO: API call

    setTransferUnitsOpen(false);
  }

  const handleValidateTransfer = (values) => {
    const errors = {};

    // Franchise
    if (!values.franchise) {
      errors.email = 'Required';
    }

    return errors;
  }

  const getTransferTextField = ({ values, errors, handleChange, handleBlur }) => (
    <TextField
      required
      select
      name="franchise"
      label="Franchise"
      value={values.franchise}
      onChange={handleChange}
      onBlur={handleBlur}
      error={Boolean(errors.franchise)}
      helperText={errors.franchise || undefined}
    >
      {franchises.map((franchise) => (
        <MenuItem key={franchise} value={franchise}>{franchise}</MenuItem>
      ))}
    </TextField>
  );

  const getButtonTransferCustomers = (sx) => (
    <>
      <IconButton onClick={handleClickTransferCustomers} sx={sx}><TransferIcon/></IconButton>
      <FormDialog
        open={transferCustomersOpen}
        title="Transfer Customers"
        caption="Confirmation from Automatrics management is required for the changes to take effect"
        captionWarning
        defaultValues={{ franchise: '' }}
        onClose={() => setTransferCustomersOpen(false)}
        onSubmit={handleSubmitTransferCustomers}
        onValidate={handleValidateTransfer}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
        }) => (
          <>
            <Typography>Transfer all of this franchises customers to a different franchise</Typography>
            <Typography sx={{ mt: 1, mb: 2}}>NOTE: This should only be done in the event of a franchise shutting down</Typography>
            {getTransferTextField({ values, errors, handleChange, handleBlur })}
          </>
        )}
      </FormDialog>
    </>
  );

  const getButtonTransferUnits = (sx) => (
    <>
      <IconButton onClick={handleClickTransferUnits} sx={sx}><TransferIcon/></IconButton>
      <FormDialog
        open={transferUnitsOpen}
        title="Transfer Unassigned Units"
        caption="Confirmation from Automatrics management is required for the changes to take effect"
        captionWarning
        defaultValues={{ franchise: '' }}
        onClose={() => setTransferUnitsOpen(false)}
        onSubmit={handleSubmitTransferUnits}
        onValidate={handleValidateTransfer}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
        }) => (
          <>
            <Typography>Transfer all of this franchises unassigned units to a different franchise</Typography>
            <Typography sx={{ mt: 1, mb: 2}}>NOTE: This should only be done in the event of a franchise shutting down</Typography>
            {getTransferTextField({ values, errors, handleChange, handleBlur })}
          </>
        )}
      </FormDialog>
    </>
  );

  return (
    <PaperSection sx={{ display: 'flex', flexDirection: 'column', ml: 'auto', mr: 'auto', p: 6, pt: 4, pb: 4, width: '100%', maxWidth: '800px', height: '100%' }}>
      <Typography variant="h4" sx={{ m: '0 auto', mb: 2 }}>*Franchise Name*</Typography>

      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Image src={defaultProfile} fit="contain" height={LOGO_SIZE} width={LOGO_SIZE} sx={{ borderRadius: 1 }}/>

        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', m: 'auto 0', ml: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography sx={{ m: 'auto 0', width: '200px' }}>Region</Typography>
            <Typography sx={{ m: 'auto 0' }}>*United Kingdom*</Typography>
          </Box>
          
          <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
            <Typography sx={{ m: 'auto 0', width: '200px' }}>Number of Customers</Typography>
            <Typography sx={{ m: 'auto 0' }}>*12*</Typography>
            {role === ROLES.root && getButtonTransferCustomers({ m: '-8px 0 -8px auto' })}
          </Box>
          
          <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
            <Typography sx={{ m: 'auto 0', width: '200px' }}>Number of Units</Typography>
            <Typography sx={{ m: 'auto 0' }}>*123*</Typography>
            {role === ROLES.root && getButtonTransferUnits({ m: '-8px 0 -8px auto' })}
          </Box>
        </Box>
      </Box>
      
      <Divider sx={{ mt: 2, mb: 2 }}/>

      {/* Only ROOT or ADMIN users can edit the contact details */}
      {(role === ROLES.root || role === ROLES.admin) ? (
        <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
          <Typography variant="h6" sx={{ m: 'auto 0' }}>Contact Details</Typography>
          <Button variant="contained" startIcon={<EditIcon/>} sx={{ m: 'auto', mr: 0 }}>Edit</Button>
        </Box>
      ) : (
        <Typography variant="h6">Contact Details</Typography>
      )}

      <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
        <Typography sx={{ width: '200px' }}>Email</Typography>
        {(emails && emails.length > 0) ? (
          <Stack spacing={1}>
            {emails.map((email) => (
              <Typography key={email}>{email}</Typography>
            ))}
          </Stack>
        ) : (
          <Typography>N/A</Typography>
        )}
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
        <Typography sx={{ width: '200px' }}>Phone Number</Typography>
        {(phoneNumbers && phoneNumbers.length > 0) ? (
          <Stack spacing={1}>
            {phoneNumbers.map((phone) => (
              <Typography key={phone.number}>{phone.number} - {phone.description}</Typography>
            ))}
          </Stack>
        ) : (
          <Typography>N/A</Typography>
        )}
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
        <Typography sx={{ width: '200px' }}>Address</Typography>
        {(address && address.length > 0) ? (
          <Stack spacing={1}>
            {address.map((adr) => (
              <Typography key={adr}>{adr}</Typography>
            ))}
          </Stack>
        ) : (
          <Typography>N/A</Typography>
        )}
      </Box>
    </PaperSection>
  );
}

export default compose(withUserData)(OverviewTab);