import React from 'react';

import {
  Box,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

import {
  FirstPage as FirstPageIcon,
  LastPage as LastPageIcon,
  ChevronRight as NextPageIcon,
  ChevronLeft as PreviousPageIcon,
} from '@mui/icons-material';

const iconProps = {
  size: 'small',
};

const iconSx = {
  margin: 'auto 0',
};

const CustomPagination = ({
  page,
  count,
  rowsPerPage,
  rowsPerPageOptions,
  onPageChange,
  onRowsPerPageChange,
  sx,
}) => {
  const totalPages = Math.ceil(count / (rowsPerPage.value ?? rowsPerPage));
  // const pageRowCount = page + 1 < totalPages ? rowsPerPage : ((count - 1) % rowsPerPage) + 1;

  const pageLabelWidth = `${totalPages.toString().length * 10}px`;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', ...sx }}>
      <Typography sx={{ m: 'auto 0' }}>Rows:</Typography>
      <TextField
        select
        size="small"
        variant="standard"
        value={rowsPerPage}
        onChange={(event) => onRowsPerPageChange(event.target.value)}
        sx={{
          ml: 1,
          mr: 2,
          mt: '6px',
          '& .MuiSelect-icon': {
            mt: '-2px',
          },
        }}
      >
        {rowsPerPageOptions.map((option) => (
          <MenuItem key={option.value ?? option} value={option.value ?? option}>
            {option.label ?? option}
          </MenuItem>
        ))}
      </TextField>

      <IconButton {...iconProps} sx={iconSx} disabled={page === 0} onClick={() => onPageChange(0)}><FirstPageIcon/></IconButton>
      <IconButton {...iconProps} sx={iconSx} disabled={page === 0} onClick={() => onPageChange(page - 1)}><PreviousPageIcon/></IconButton>
      <Box sx={{ display: 'flex', flexDirection: 'row', m: 'auto 0' }}>
        <Typography sx={{ width: pageLabelWidth, textAlign: 'right' }}>{count === 0 ? 0 : page + 1}</Typography>
        <Typography>/</Typography>
        <Typography sx={{ width: pageLabelWidth }}>{totalPages}</Typography>
      </Box>
      <IconButton {...iconProps} sx={iconSx} disabled={page === totalPages - 1} onClick={() => onPageChange(page + 1)}><NextPageIcon/></IconButton>
      <IconButton {...iconProps} sx={iconSx} disabled={page === totalPages - 1} onClick={() => onPageChange(totalPages - 1)}><LastPageIcon/></IconButton>
    </Box>
  );
}

export default CustomPagination;