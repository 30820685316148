import React from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Typography,
} from '@mui/material';

import {
  DoubleArrow as ArrowIcon,
} from '@mui/icons-material';

import { CARRIERS } from './carriers';

// NOTE: key is server's perspective, label is tracker's perspective
export const DIRECTIONS = {
  INBOUND:  { id: 'INBOUND',  label: 'In' },
  OUTBOUND: { id: 'OUTBOUND', label: 'Out' },
};

export const DIRECTIONS_ARRAY = [
  DIRECTIONS.INBOUND,
  DIRECTIONS.OUTBOUND,
];

const CommsDirection = ({
  direction,
  type,
}) => {
  const theme = useTheme();

  const isDirectionIn = direction === DIRECTIONS.INBOUND;
  const colour = type?.colour ? theme.palette.comms[type.colour] : theme.palette.text.secondary;

  return (
    <>
      <ArrowIcon sx={{ mr: 1, visibility: isDirectionIn ? 'visible' : 'hidden', color: colour, transform: "scaleX(-1)" }}/>
      <Typography sx={{ width: '40px', textAlign: 'center' }}>{direction?.label || '-'}</Typography>
      <ArrowIcon sx={{ ml: 1, visibility: !isDirectionIn ? 'visible' : 'hidden', color: colour }}/>
    </>
  );
}

export default CommsDirection;