import { createAction } from 'redux-actions';

const ENTITY = '@@USER_NEW';

// User Details
export const getUserDetails = createAction(`${ENTITY}/GET_USER_DETAILS`);
export const setUserDetails = createAction(`${ENTITY}/SET_USER_DETAILS`);
export const setUserPassword = createAction(`${ENTITY}/SET_USER_PASSWORD`);
export const setUserAvatar = createAction(`${ENTITY}/SET_USER_AVATAR`);

export const updateUserDetails = createAction(`${ENTITY}/UPDATE_USER_DETAILS`);
export const updateUserAvatar = createAction(`${ENTITY}/UPDATE_USER_AVATAR`);
export const setUploadingAvatar = createAction(`${ENTITY}/SET_UPLOADING_AVATAR`);
