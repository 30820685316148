import { compose } from 'redux';
import React, { useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Dialog,
  TextField,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  ListItemSecondaryAction,
} from '@mui/material';

import deleteFranchiseModalStyles from './style';
import withFranchiseData from '../../../Hocs/WithData/franchise';
import withFranchiseActions from '../../../Hocs/WithAction/franchise';

const DeleteFranchiseModal = ({ franchise, deleteFranchiseRequest, isDeletingFranchise }) => {
  const classes = deleteFranchiseModalStyles();
  const deleteFranchiseInputFormat = `Delete ${franchise.name}`;
  const [deleteFranchiseInput, setDeleteFranchiseInput] = useState('');
  const [showDeleteFranchiseModal, setShowDeleteFranchiseModal] = useState(false);

  const handleClickOpen = () => {
    setShowDeleteFranchiseModal(true);
  };

  const handleClose = () => {
    setShowDeleteFranchiseModal(false);
  };

  const handleConfirmationInputChange = ({ target: { value } }) => {
    setDeleteFranchiseInput(value);
  };

  const handleDeleteFranchise = () => {
    deleteFranchiseRequest({
      franchise: {
        id: franchise.id,
        name: franchise.name,
      },
    });
    setShowDeleteFranchiseModal(false);
    setDeleteFranchiseInput('');
  };

  return <>
    <ListItemSecondaryAction
      onClick={handleClickOpen}
      className={classes.deleteFranchiseModalOpenButton}
    >
      <IconButton edge="end" size="large">
        <DeleteIcon />
      </IconButton>
    </ListItemSecondaryAction>
    <Dialog
      onClose={handleClose}
      open={showDeleteFranchiseModal}
      className={classes.deleteFranchiseModalContainer}
    >
      <DialogTitle id="form-dialog-title" className={classes.deleteFranchiseModalHeader}>
        Delete Franchise?
      </DialogTitle>
      <DialogContent className={classes.deleteFranchiseModalContent}>
        <DialogContentText className={classes.deleteFranchiseModalSubheader}>
          This action will permanently delete the franchise profile and all it’s settings.
        </DialogContentText>
        <Typography className={classes.deleteFranchiseModalInputLabel}>
          To confirm, please type
          <span>{` ${deleteFranchiseInputFormat} `}</span>
          below and hit Delete.
        </Typography>
        <TextField
          required
          fullWidth
          autoFocus
          size="small"
          variant="outlined"
          id="outlined-helperText"
          field={deleteFranchiseInput}
          value={deleteFranchiseInput}
          onChange={handleConfirmationInputChange}
          placeholder={`Type ${deleteFranchiseInputFormat}`}
          className={classes.deleteFranchiseModalInputField}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          className={classes.deleteFranchiseModalCancelButton}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className={classes.deleteFranchiseModalSaveButton}
          onClick={handleDeleteFranchise}
          disabled={deleteFranchiseInput !== deleteFranchiseInputFormat || isDeletingFranchise}
        >
          {isDeletingFranchise ? 'Deleting...' : 'Delete'}
        </Button>
      </DialogActions>
    </Dialog>
  </>;
};

export default compose(withFranchiseData, withFranchiseActions)(DeleteFranchiseModal);
