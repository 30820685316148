import { useState, useEffect } from 'react';

export const MOBILE_MIN_WIDTH = 0;
export const TABLET_MIN_WIDTH = 600;
export const DESKTOP_MIN_WIDTH = 1000;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;

  const isMobileWidth = width < TABLET_MIN_WIDTH;
  const isTabletWidth = width >= TABLET_MIN_WIDTH && width < DESKTOP_MIN_WIDTH;
  const isDesktopWidth = width >= DESKTOP_MIN_WIDTH;

  return {
    width,
    height,
    isMobileWidth,
    isTabletWidth,
    isDesktopWidth
  };
}

/**
 * Source: https://stackoverflow.com/a/36862446
 */
export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const getHalfPaperSectionWidth = (windowDimensions, theme) => {
  const SPACING = parseInt(theme.spacing(1));

  // Half width w/ margins
  if (windowDimensions.isDesktopWidth) {
    return ((windowDimensions.width - SPACING) / 2) - 2;    // -2 due to border width
  }
  // Full width
  else {
    return windowDimensions.width - 2;                      // -2 due to border width
  }
}

export const getFullPaperSectionWidth = (windowDimensions) => {
  return windowDimensions.width - 2;    // -2 due to border width
}