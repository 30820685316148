import { connect } from 'react-redux';

const mapStateToProps = ({
  // Data in the Store
  EventData_NEW: {
    latestEvents,
    areLatestEventsLoading,

    notes,
    totalNumberOfNotes,
    notesFilter,
    areNotesLoading,

    auditLogs,
    totalNumberOfAuditLogs,
    auditLogFilter,
    areAuditLogsLoading,
    areAuditDetailsLoading,

    commsLogs,
    totalNumberOfCommsLogs,
    commsLogFilter,
    areCommsLogsLoading,

    notificationLogs,
    totalNumberOfNotificationLogs,
    notificationLogFilter,
    areNotificationLogsLoading,

    autoUpdateEvents,
  }
}) => ({
  // Props for the Component
  latestEvents,
  areLatestEventsLoading,

  notes,
  totalNumberOfNotes,
  notesFilter,
  areNotesLoading,

  auditLogs,
  totalNumberOfAuditLogs,
  auditLogFilter,
  areAuditLogsLoading,
  areAuditDetailsLoading,

  commsLogs,
  totalNumberOfCommsLogs,
  commsLogFilter,
  areCommsLogsLoading,

  notificationLogs,
  totalNumberOfNotificationLogs,
  notificationLogFilter,
  areNotificationLogsLoading,

  autoUpdateEvents,
})

export default (WrappedComponent) => connect(mapStateToProps, null)(WrappedComponent);