import {
  white,
  mobile,
  buttonBoxShadow,
  onHoverButtonPurple,
} from '../../../Styles/styles';

import makeStyles from '@mui/styles/makeStyles';

const toolBarStyles = makeStyles((theme) => ({
  menuList: {
    padding: '17px',
    display: 'flex',
    minHeight: '76px',
    marginBottom: '5px',
    alignSelf: 'stretch',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    '&:focus': {
      outline: 'none',
    },
  },
  lowerToolBar: {
    flexDirection: 'row',
  },
  menuItemModalButton: {
    marginLeft: '5px',
    '& button': {
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiButton-startIcon': {
      display: 'inherit',
      marginLeft: 0,
      marginRight: 0,
    },
    '& .MuiSvgIcon-root': {
      marginRight: '10px',
    },
    // Breakpoint for Mobile
    [theme.breakpoints.down(mobile)]: {
      '& button': {
        width: 'auto',
      },
      '& .MuiSvgIcon-root': {
        marginRight: '0px',
      },
    },
  },
  toggleAllCheckBox: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    // Breakpoint for Mobile
    [theme.breakpoints.down(mobile)]: {
      padding: 'unset',
    },
  },
  menuIcon: {
    minWidth: '42px',
    minHeight: '42px',
    // Breakpoint for Mobile
    [theme.breakpoints.down(mobile)]: {
      minHeight: 'unset',
    },
    '& svg': {
      margin: 'auto',
    },
  },
  toggleViewContainer: {
    // Breakpoint for Mobile
    [theme.breakpoints.down(mobile)]: {
      display: 'none',
    },
  },
  toggleViewIcon: {
    color: '#9E9E9E',
    marginRight: '16px',
  },
  menuItemLeft: {
    padding: 'unset',
    marginRight: 'auto',
    borderRadius: '28px',
  },
  searchBar: {
    // Breakpoint for Mobile
    [theme.breakpoints.down(mobile)]: {
      paddingRight: '0px',
    },
  },
  closeUpperToolBarButton: {
    alignItems: 'center',
    marginRight: '10px',
    display: 'flex',
    // Breakpoint for Mobile
    [theme.breakpoints.down(mobile)]: {
      margin: 'auto 10px',
    },
  },
  closeUpperToolBarButtonIcon: {
    minWidth: 'unset',
    color: theme.palette.primary.main,
    '& svg': {
      fontSize: '24px',
    },
  },
  menuItemButton: {
    height: '36px',
    width: '143px',
    borderRadius: '8px',
    boxShadow: buttonBoxShadow,
    backgroundColor: theme.palette.primary.main,
    '& span': {
      color: white,
      fontWeight: '600',
      letterSpacing: '0',
      textTransform: 'initial',
      fontFamily: 'Source Sans Pro',
    },
    '&:hover': {
      color: 'white',
      backgroundColor: onHoverButtonPurple,
    },
  },
  menuItemCancelButton: {
    width: '143px',
    height: '36px',
    border: 'none',
    borderRadius: '8px',
    '& span': {
      fontWeight: '600',
      letterSpacing: '0',
      color: theme.palette.primary.main,
      textTransform: 'initial',
      fontFamily: 'Source Sans Pro',
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
}));

export default toolBarStyles;
