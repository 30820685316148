import { compose } from 'redux';
import React, { useState } from 'react';

import { Tab, Box, Tabs } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import MapTab from './MapTab';
import LogsTab from './LogsTab';
import DetailsTab from './DetailsTab';
import SettingsTab from './SettingsTab';
import { isAccountRoleAdmin, isAccountRoleRoot } from '../../../Utils/Utils';

import withUserData from '../../../redux/WithData/user';
import withTrackersData from '../../../Hocs/WithData/tracker';

const tabPaneStyles = makeStyles(() => ({
  tabPanelContainer: {
    height: '100%',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tabPanel: {
    height: '100%',
  },
}));

const TabPanel = ({ children, name, value }) => {
  const classes = tabPaneStyles();
  return (
    <div
      role="tabpanel"
      hidden={value !== name}
      className={classes.tabPanelContainer}
      id={`scrollable-prevent-tabpanel-${name}`}
    >
      {value === name && (
      <Box className={classes.tabPanel}>
        {children}
      </Box>
      )}
    </div>
  );
};

const TrackerDetailsTabs = ({
  role,
  currentTracker: {
    simiccid: trackerId,
  },
}) => {
  //const [activeTab, setActiveTab] = useState('map');
  const [activeTab, setActiveTab] = useState('settings');

  const handleTabChange = (_, tabValue) => {
    setActiveTab(tabValue);
  };

  return (
    <>
      <Tabs
        id="trackerDetailsTabs"
        variant="fullWidth"
        value={activeTab}
        onChange={handleTabChange}
        sx={{
          bgcolor: 'background.paper',
        }}
      >
        <Tab label="Map" value="map" aria-label="list" />
        {(isAccountRoleRoot(role) || isAccountRoleAdmin(role)) && (
          <Tab label="Profile" value="profile" aria-label="viewWeek" />
        )}
        <Tab label="Logs" value="logs" aria-label="viewWeek" />
        {(isAccountRoleRoot(role) || isAccountRoleAdmin(role)) && (
          <Tab label="Settings" value="settings" aria-label="viewWeek" />
        )}
      </Tabs>
      <TabPanel value={activeTab} name="map">
        <MapTab trackerId={trackerId} />
      </TabPanel>
      <TabPanel value={activeTab} name="profile">
        <DetailsTab trackerId={trackerId} setActiveTab={setActiveTab} />
      </TabPanel>
      <TabPanel value={activeTab} name="logs">
        <LogsTab trackerId={trackerId} setActiveTab={setActiveTab} />
      </TabPanel>
      <TabPanel value={activeTab} name="settings">
        <SettingsTab trackerId={trackerId} setActiveTab={setActiveTab} isAccountRoleRoot={isAccountRoleRoot(role)} />
      </TabPanel>
    </>
  );
};
export default compose(withTrackersData, withUserData)(TrackerDetailsTabs);
