export const ROLES = {
  root:  'ROOT',
  admin: 'ADMIN',
  user:  'USER',
};

export const ROLES_ARRAY = [
  ROLES.root,
  ROLES.admin,
  ROLES.user,
];