import {
  BatteryCharging90Rounded as BatteryIcon,
  MonitorHeart as HealthIcon,
} from '@mui/icons-material';

import ConfinedTypography from '../Components/Typographies/ConfinedTypography';
import UnitHealth, { HEALTH_STATES_ARRAY } from '../Components/UnitHealth';

import { getHalfPaperSectionWidth } from '../../Utils/ScreenSize';
import { ROLES } from '../../Utils/Roles';

const COL_SERIAL        = 200;
const COL_BATTERY       = 70;
const COL_HEALTH        = 70;

const ALL_COLUMNS = {
  serialNumber:       { width: COL_SERIAL, label: "SIM ICC ID", dataKey: "simiccid" },
  name:               { width: 0, label: "Name", dataKey: "name", dynamic: true, render: ({ cellData }) => <ConfinedTypography>{cellData || '-'}</ConfinedTypography> },
  customerName:       { width: 0, label: "Customer", dataKey: "customer", dynamic: true, render: ({ cellData }) => cellData ? <ConfinedTypography title>{cellData}</ConfinedTypography> : '-' },
  customerFranchise:  { width: 0, label: "Customer", dataKey: "customer", dynamic: true, render: ({ cellData, rowData }) => cellData ? <ConfinedTypography title>{`[AM] ${cellData}`}</ConfinedTypography> : '-' },
  battery:            { width: COL_BATTERY, label: <BatteryIcon/>, dataKey: "battery", render: ({ cellData }) => cellData ? `${cellData}%` : '-' },
  health:             { width: COL_HEALTH, label: <HealthIcon/>, dataKey: "health", render: ({ cellData, rowIdx }) => <UnitHealth state={HEALTH_STATES_ARRAY[rowIdx % HEALTH_STATES_ARRAY.length]} size={20} sx={{ ml: '2px' }}/> },
};

export function getColumns(windowDimensions, theme, role) {
  const TABLE_WIDTH = getHalfPaperSectionWidth(windowDimensions, theme);

  // Columns are defined based on how wide the screen is
  let columns = [];

  columns.push(ALL_COLUMNS.serialNumber);
  columns.push(ALL_COLUMNS.name);

  if (role === ROLES.root) {
    columns.push(ALL_COLUMNS.customerFranchise);
  }
  else if (role === ROLES.admin) {
    columns.push(ALL_COLUMNS.customerName);
  }

  columns.push(ALL_COLUMNS.battery);
  columns.push(ALL_COLUMNS.health);

  let remainingWidth        = TABLE_WIDTH;
  let dyncamicColumnIndices = [];
  
  // Find total width of fixed columns and indices of dynamic columns
  for (let i = 0; i < columns.length; i++) {
    if (!columns[i].dynamic) {
      remainingWidth -= columns[i].width;
    }
    else {
      dyncamicColumnIndices.push(i);
    }
  }

  const dynamicColWidth = Math.floor(remainingWidth / dyncamicColumnIndices.length);

  // Apply equal width to all dynamic columns 
  for (let j = 0; j < dyncamicColumnIndices.length; j++) {
    columns[dyncamicColumnIndices[j]].width = dynamicColWidth;
  }
  
  return columns;
}