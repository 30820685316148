import { createTheme } from '@mui/material/styles';

let theme = createTheme({
  palette: {
    mode: 'light',
    background: {
      // default: '#fafafa',
      default: '#faf7fa',
    },
    primary: {
      main: '#92278f',
      // main: '#06b416',
      contrastText: '#fff'
    },
    secondary: {
      main: '#0048ff',
    },
    /*
    'Dark' colours are derived from the 'main' colour in Figma:
    The 'main' colour is overlayed with #FFFFFF @ 6% opacity
     */
    testColor: {
      main: '#00FF00',
    },
    modes: {
      factory: {
        main: '#D0CECE',
        dark: '#C4C2C2',
      },
      stock: {
        main: '#D9E1F2',
        dark: '#CCD3E3',
      },
      standard: {
        main: '#FFFFFF',
        dark: '#F0F0F0',
      },
      warning: {
        main: '#FFE69A',
        dark: '#F0D891',
      },
      alarm: {
        main: '#F6BDB0',
        dark: '#E7B2A5',
      },
      stolen: {
        main: '#CC9AFF',
        dark: '#C091F0',
      },
      test: {
        main: '#E2EFDA',
        dark: '#D4E1CD',
      },
    },
    location: {
      gpsHi: {
        main: '#F4A59A',
        dark: '#E59B91',
      },
      gpsLo: {
        main: '#EDC7FF',
        dark: '#DFBBF0',
      },
      gsm: {
        main: '#ADDFA5',
        dark: '#A3D29B',
      },
      wifi: {
        main: '#B3B2FE',
        dark: '#A8A7EF',
      },
    },
    health: {
      green: '#43d537',
      amber: '#ffa218',
      red: '#f01515',
    },
  },
  spacing: 8,
  shape: {
    borderRadius: 8,
  },
  borderRadius: (factor) => `${8 * factor}px`,
  breakpoints: {
    // These are the minimum widths (in px) of each screen type
    values: {
      mobile: 0,
      tablet: 600,
      desktop: 1000,
    },
  },
});

export default theme = createTheme(theme, {
  components: {
    MuiPaper: {
      styleOverrides: {
        rounded: {
          //backgroundColor: '#ccc',
          borderRadius: theme.borderRadius(1.5),
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.paper,
          '& .MuiTab-root': {
            borderBottomColor: '#E5E5E5',
            borderBottomStyle: 'solid',
            borderBottomWidth: '1px',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '4px',
          },
          // '& .MuiOutlinedInput-input': {
          //   paddingTop: '10px',
          //   paddingBottom: '10px',
          // },
          '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.palette.primary.main}`,
          },
          '& .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline': {
            border: `2px solid ${theme.palette.primary.main}`,
          },
          '& .MuiOutlinedInput-root.Mui-error:hover .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.palette.error.main}`,
          },
          '& .MuiOutlinedInput-root.Mui-focused.Mui-error:hover .MuiOutlinedInput-notchedOutline': {
            border: `2px solid ${theme.palette.error.main}`,
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        margin: "dense",
        size: "small",
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: theme.palette.primary.main,
        },
      },
      // defaultProps: {          // Add this default prop to improve appearance under high CPU load
      //   disableShrink: true,
      // }
    },
  },
});
