import React from 'react';
import { compose } from 'redux';
import { useTheme } from '@mui/material/styles';

import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import {
  Box,
  Button,
  Divider,
  MenuItem,
  TextField,
} from '@mui/material';

import {
  Clear as ClearIcon,
  Tune as FilterIcon,
} from '@mui/icons-material';

import PaperSection from '../../../Components/PaperSection';
import FilterHeader from '../../../Components/Filter/FilterHeader';
import VirtualisedTable from '../../../Components/VirtualisedTable/VirtualisedTable';
import CustomPagination from '../../../Components/VirtualisedTable/CustomPagination';
import ResultsCounter from '../../../Components/VirtualisedTable/ResultsCounter';

import { getFilters } from './filters';
import { getColumns } from './columns';
import { useWindowDimensions, getFullPaperSectionWidth } from '../../../../Utils/ScreenSize';

import withTrackerData from '../../../../redux/WithData/tracker';
import withEventData from '../../../../redux/WithData/event';
import withEventActions from '../../../../redux/WithActions/event';

const ROWS_PER_PAGE_OPTIONS = [ 50, 200, 1000 ];

const EMPTY_FILTER = {
  limit: 50,
  page: 0,
  startTime: null,
  endTime: null,
};

const CommsLog = ({
  tracker,
  commsLogs,
  totalNumOfCommsLogs,
  areCommsLogsLoading,
  getCommsLogs,
}) => {
  const theme = useTheme();

  const windowDimensions = useWindowDimensions();

  const TABLE_WIDTH = getFullPaperSectionWidth(windowDimensions, theme);
  const COLUMNS = getColumns(TABLE_WIDTH);
  const FILTERS = getFilters();

  const [hasStartedLoading, setStartedLoading] = React.useState(false);
  const [filter, setFilter] = React.useState({ ...EMPTY_FILTER });

  const [totalCount, setTotalCount] = React.useState(0);

  // Fetch all events matching the filter via the API
  React.useEffect(() => {
    if (tracker?.simiccid) {
      searchLogs();
  
      setStartedLoading(true);
    }
  }, [
    tracker,
    getCommsLogs,
    setStartedLoading,
  ]);

  // Update the row/page counters based on whether the data is still loading
  React.useEffect(() => {
    let count = (filter.page * filter.limit) + commsLogs.length;

    if (count % filter.limit === 0) {
      // If current page is full, assume there is another page (not guaranteed)
      count++;
    }
    
    setTotalCount(count);    // TODO: replace with 'totalNumOfCommsLogs' once API is updated
  }, [
    filter,
    commsLogs,
    // totalNumOfCommsLogs,
  ]);

  function searchLogs(newFilter = undefined) {
    if (newFilter) {
      const updatedFilter = { ...filter, ...newFilter };

      setFilter(updatedFilter);

      getCommsLogs({ trackerId: tracker.simiccid, ...updatedFilter });
    }
    else {
      getCommsLogs({ trackerId: tracker.simiccid, ...filter });
    }
  }

  function handleFilter() {
    searchLogs();
  }

  function handleClickRefresh() {
    searchLogs();
  }

  function handlePageChange(newPage) {
    searchLogs({
      page: newPage,
    });
  }

  function handleRowsPerPageChange(newRowsPerPage) {
    searchLogs({
      limit: newRowsPerPage,
      page: 0,
    })
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <PaperSection sx={{ display: 'table', height: '100%', p: 0 }}>
        <FilterHeader
          filters={FILTERS}
          defaultValues={EMPTY_FILTER}
          values={filter}
          setValues={setFilter}
          onFilter={handleFilter}
        />

        <Box sx={{ display: 'table-row', height: '100%', overflow: 'hidden' }}>
          <VirtualisedTable
            hover
            rowHeight={48}
            columns={COLUMNS}
            loading={!hasStartedLoading || areCommsLogsLoading}
            rowCount={(!hasStartedLoading || areCommsLogsLoading) ? 0 : commsLogs.length}
            rowGetter={({ index }) => commsLogs[index]}
          />
        </Box>

        <Box sx={{ display: 'table-row', height: 0 }}>
          <Divider/>

          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <ResultsCounter
              rowCount={!hasStartedLoading ? 0 : commsLogs.length}
              totalCount={!hasStartedLoading ? 0 : totalCount}
              onClickRefresh={handleClickRefresh}
              sx={{ m: 1, ml: 2 }}
            />
            <CustomPagination
              page={filter.page}
              count={!hasStartedLoading ? 0 : totalCount}
              rowsPerPage={filter.limit}
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              sx={{ m: 'auto', mr: 1 }}
            />
          </Box>
        </Box>
      </PaperSection>
    </Box>
  );
}

export default compose(withTrackerData, withEventData, withEventActions)(CommsLog);