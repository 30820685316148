export const UNITS = {
  metric: {
    id: 'metric',
    label: 'Metric',
  },
  imperial: {
    id: 'imperial',
    label: 'Imperial',
  },
};

export const UNITS_ARRAY = [
  UNITS.metric,
  UNITS.imperial,
];