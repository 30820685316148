import {
  put,
  select,
  takeLatest,
} from "redux-saga/effects";

import {
  storeLatestEvents,
  setLatestEventsLoading,

  createNote,
  deleteNote,
  getNotes,
  setNotesFilter,
  setNotesLoading,
  storeNewNote,
  storeNotes,
  updateNote,

  getAuditLogs,
  getAuditDetails,
  setAuditLogsFilter,
  setAuditLogsLoading,
  setAuditDetailsLoading,
  storeAuditLogs,
  storeAuditDetails,

  getCommsLogs,
  setCommsLogsFilter,
  setCommsLogsLoading,
  storeCommsLogs,

  getNotificationLogs,
  setNotificationLogsFilter,
  setNotificationLogsLoading,
  storeNotificationLogs,

  receiveEventFromWS,
  getLatestEvents,
  storeNoteWS,
  storeAuditLogWS,
  storeCommsLogWS,
  storeNotificationLogWS,
} from '../Actions/event';

import {
  storeLocationWS,
} from '../Actions/location';

import EventAPI from '../../Api/Endpoints/event';

import { authApiHandler } from './auth';
import { ResponseTypes } from '../../Api/Responses/baseResponse';
import { addNotification } from '../Actions/system';

import { EVENT_TYPES, getEventType } from "../../Utils/Events";

export function* handleGetLatestEvents({ payload: trackerId }) {
  yield put(setLatestEventsLoading(true));

  let requests = [
    { key: 'note', api: EventAPI.getNotes },
    { key: 'audit', api: EventAPI.getAuditLogs },
    { key: 'comms', api: EventAPI.getCommsLogs },
    { key: 'notification', api: EventAPI.getNotificationLogs },
  ];

  let result = {};
  let success = true;

  let response;

  // Fetech latest event for each event type
  for (let i = 0; i < requests.length; i++) {
    response = yield authApiHandler(requests[i].api, trackerId, 1, 0);

    if (response.type == ResponseTypes.ActionCompleted) {
      if (response.data.data.length > 0) {
        result[requests[i].key] = response.data.data[0];
      }
    }
    else if (response.type == ResponseTypes.Exception) {
      success = false;

      yield put(addNotification({
        type: 'error',
        title: 'Could not retrieve latest event data',
        source: 'handleGetLatestEvents',
        message: 'Please check your connection and try again.',
      }));

      break;
    }
  }

  if (success) {
    yield put(storeLatestEvents(result));
  }

  yield put(setLatestEventsLoading(false));
}

export function* handleCreateNote({ payload: { trackerId, text } }) {
  let response = yield authApiHandler(EventAPI.createNote, trackerId, text);

  if (response.type == ResponseTypes.ActionCompleted) {
    yield put(storeNewNote(response.data));
  }
  else if (response.type == ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not create note',
      source: 'handleCreateNote',
      message: 'Please check your connection and try again.',
    }));
  }
}

export function* handleDeleteNote({ payload: eventId }) {
  let response = yield authApiHandler(EventAPI.deleteNote, eventId);

  if (response.type == ResponseTypes.ActionCompleted) {
    yield put(updateNote(response.data));
  }
  else if (response.type == ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not delete note',
      source: 'handleDeleteNote',
      message: 'Please check your connection and try again.',
    }));
  }
}

export function* handleGetNotes({ payload: { trackerId, startTime, endTime } }) {
  yield put(setNotesLoading(true));
  yield put(setNotesFilter({ startTime, endTime }));

  let response = yield authApiHandler(EventAPI.getNotes, trackerId, 200, 0, startTime?.toISO(), endTime?.toISO());

  if (response.type == ResponseTypes.ActionCompleted) {
    yield put(storeNotes(response.data.data));
  }
  else if (response.type == ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not retrieve notes',
      source: 'handleGetNotes',
      message: 'Please check your connection and try again.',
    }));
  }

  yield put(setNotesLoading(false));
}

export function* handleGetAuditLogs({ payload: { trackerId, limit, page, startTime, endTime } }) {
  yield put(setAuditLogsLoading(true));
  yield put(setAuditLogsFilter({ limit, page, startTime, endTime }));

  const offset = limit * page;

  let response = yield authApiHandler(EventAPI.getAuditLogs, trackerId, limit, offset, startTime?.toISO(), endTime?.toISO());

  if (response.type == ResponseTypes.ActionCompleted) {
    yield put(storeAuditLogs(response.data.data));
  }
  else if (response.type == ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not retrieve audit data',
      source: 'handleGetAuditLogs',
      message: 'Please check your connection and try again.',
    }));
  }

  yield put(setAuditLogsLoading(false));
}

export function* handleGetAuditDetails({ payload: eventId }) {
  yield put(setAuditDetailsLoading(true));

  let response = yield authApiHandler(EventAPI.getAuditDetails, eventId);

  if (response.type == ResponseTypes.ActionCompleted) {
    yield put(storeAuditDetails({ eventId: eventId, details: response.data }));
  }
  else if (response.type == ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not retrieve audit details',
      source: 'handleGetAuditDetails',
      message: 'Please check your connection and try again.',
    }));
  }

  yield put(setAuditDetailsLoading(false));
}

export function* handleGetCommsLogs({ payload: { trackerId, limit, page, startTime, endTime } }) {
  yield put(setCommsLogsLoading(true));
  yield put(setCommsLogsFilter({ limit, page, startTime, endTime }));

  const offset = limit * page;

  let response = yield authApiHandler(EventAPI.getCommsLogs, trackerId, limit, offset, startTime?.toISO(), endTime?.toISO());

  if (response.type == ResponseTypes.ActionCompleted) {
    yield put(storeCommsLogs(response.data.data));
  }
  else if (response.type == ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not retrieve comms data',
      source: 'handleGetCommsLogs',
      message: 'Please check your connection and try again.',
    }));
  }

  yield put(setCommsLogsLoading(false));
}

export function* handleGetNotificationLogs({ payload: { trackerId, limit, page, startTime, endTime } }) {
  yield put(setNotificationLogsLoading(true));
  yield put(setNotificationLogsFilter({ limit, page, startTime, endTime }));

  const offset = limit * page;

  let response = yield authApiHandler(EventAPI.getNotificationLogs, trackerId, limit, offset, startTime?.toISO(), endTime?.toISO());

  if (response.type == ResponseTypes.ActionCompleted) {
    yield put(storeNotificationLogs(response.data.data));
  }
  else if (response.type == ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not retrieve notification data',
      source: 'handleGetNotificationLogs',
      message: 'Please check your connection and try again.',
    }));
  }

  yield put(setNotificationLogsLoading(false));
}

export function* handleReceiveEventFromWS({ payload: event }) {
  const autoUpdate = yield select((state) => state.EventData_NEW.autoUpdateEvents);

  if (autoUpdate) {
    switch (getEventType(event)) {
      case EVENT_TYPES.location:
        yield put(storeLocationWS(event));
        break;
  
      case EVENT_TYPES.note:
        yield put(storeNoteWS(event));
        break;
    
      case EVENT_TYPES.audit:
        yield put(storeAuditLogWS(event));
        break;
    
      case EVENT_TYPES.comms:
        yield put(storeCommsLogWS(event));
        break;
  
      case EVENT_TYPES.notification:
        yield put(storeNotificationLogWS(event));
        break;
  
      default:
        console.debug('Unknown WS event type:');
        console.debug(event);
        break;
    }
  }
  else {
    // TODO: store in buffer then process after unpausing?
  }
}

export default function* Sagas() {
  yield takeLatest(getLatestEvents, handleGetLatestEvents);
  yield takeLatest(createNote, handleCreateNote);
  yield takeLatest(deleteNote, handleDeleteNote);
  yield takeLatest(getNotes, handleGetNotes);
  yield takeLatest(getAuditLogs, handleGetAuditLogs);
  yield takeLatest(getAuditDetails, handleGetAuditDetails);
  yield takeLatest(getCommsLogs, handleGetCommsLogs);
  yield takeLatest(getNotificationLogs, handleGetNotificationLogs);
  yield takeLatest(receiveEventFromWS, handleReceiveEventFromWS);
}