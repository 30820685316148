// Trackers
export const favoriteTrackers = (state) => state.TrackerData.favoriteTrackers;

// Folders
export const currentFolderAuthorizations = (state) => state
  .FoldersData.selectedItems[0].sharedWith;

// Profile
export const userTimezone = (state) => state.UserData.timezone;
export const profileDetails = ({
  UserData: {
    role,
    avatar,
    lastName,
    username,
    location,
    timezone,
    firstName,
    franchise,
  },
}) => ({
  role,
  avatar,
  lastName,
  username,
  location,
  timezone,
  firstName,
  franchise,
});
