import ApiCall from './base';
import store from '../../redux/Store';
import { ResponseHandler, ErrorHandler } from '../Responses/baseResponse';
import { LoginResponseHandler, RegisterResponseHandler } from '../Responses/authResponse';

const AuthCall = {
  getState: () => {
    const { AuthData_NEW } = store.getState();
    return AuthData_NEW;
  },
  getAccessToken: () => (
    AuthCall.getState().accessToken ? AuthCall.getState().accessToken : undefined
  ),
  getRefreshToken: () => (
    AuthCall.getState().refreshToken ? AuthCall.getState().refreshToken : undefined
  ),
  getUserEmail: () => {
    const { UserData_NEW } = store.getState();
    return UserData_NEW.username;
  },
  handleError: (error) => ErrorHandler(error),
  handleResponse: (response) => ResponseHandler(response),
  buildOptions: (extraOptions = {}) => {
    const headers = {
      Authorization: AuthCall.getAccessToken(),
      'Content-Type': 'application/json',
    };
    return { ...headers, ...extraOptions };
  },

  get: async (endPoint, dataQS = undefined, extraOptions = {}, dataBody = undefined) => {
    let responseObj;
    try {
      const headers = AuthCall.buildOptions(extraOptions);
      responseObj = await ApiCall.get(endPoint, dataQS, headers, dataBody);
      responseObj = AuthCall.handleResponse(responseObj);
    } catch (exception) {
      responseObj = AuthCall.handleError(exception);
    }

    return responseObj;
  },

  post: async (endPoint, dataBody = undefined, extraOptions = {}, dataQS = undefined) => {
    let responseObj;
    try {
      const headers = AuthCall.buildOptions(extraOptions);
      responseObj = await ApiCall.post(endPoint, dataBody, headers, dataQS);
      responseObj = AuthCall.handleResponse(responseObj);
    } catch (exception) {
      responseObj = AuthCall.handleError(exception);
    }
    return responseObj;
  },

  put: async (endPoint, dataBody = undefined, extraOptions = {}, dataQS = undefined) => {
    let responseObj;
    try {
      const headers = AuthCall.buildOptions(extraOptions);
      responseObj = await ApiCall.put(endPoint, dataBody, headers, dataQS);
      responseObj = AuthCall.handleResponse(responseObj);
    } catch (exception) {
      responseObj = AuthCall.handleError(exception);
    }
    return responseObj;
  },

  delete: async (endPoint, dataBody = undefined, extraOptions = {}, dataQS = undefined) => {
    let responseObj;
    try {
      const headers = AuthCall.buildOptions(extraOptions);
      responseObj = await ApiCall.delete(endPoint, dataBody, headers, dataQS);
      responseObj = AuthCall.handleResponse(responseObj);
    } catch (exception) {
      responseObj = AuthCall.handleError(exception);
    }
    return responseObj;
  },

  login: async (user, password) => {
    const response = await AuthCall.post('/api/v1/secure/login', undefined, { Authorization: `Basic ${btoa(`${user}:${password}`)}` });

    return LoginResponseHandler(response);
  },

  register: async ({ email, password, codeValidation, firstName, lastName }) => {
    const response = await ApiCall.put('/api/v1/open/account/instance', {
      username: email,
      password,
      activationCode: codeValidation,
      firstName,
      lastName,
    }, { 'Content-Type': 'application/json' });

    return RegisterResponseHandler(response);
  },

  refreshAccess: async () => {
    const response = await AuthCall.get('/api/v1/jwt/refresh', undefined, { Authorization: AuthCall.getRefreshToken() });

    return LoginResponseHandler(response);
  },
};

export default AuthCall;
