import { handleActions } from 'redux-actions';

// Import all Actions that this Reducer will handle (Actions may be handled in multiple Reducers)
import { logout } from '../Actions/auth';
import {
  setLocalParam,
  loadRemoteTracker,
  setRemoteTrackerLoading
} from '../Actions/example';

// Define default/initial values for all fields
const defaultState = {
  localParam: 0,
  remoteTracker: null,
  isRemoteTrackerLoading: false,
};

export default handleActions({
  [logout]: () => defaultState,

  [setLocalParam]: (state, { payload: { value } }) => {
    // Perform validation and/or calculations

    // Return updated state 
    return { ...state, localParam: value };
  },
  [loadRemoteTracker]: (state, { payload: tracker }) => ({ ...state, remoteTracker: tracker }),
  [setRemoteTrackerLoading]: (state, { payload: isLoading }) => ({ ...state, isRemoteTrackerLoading: isLoading }),
}, defaultState);