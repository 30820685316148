import React from 'react';
import { compose } from 'redux';
import { DateTime } from 'luxon';

import {
  Box,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';

import PaperSection from '../../../Components/PaperSection';
import Map from '../../../Components/Map';

import { useWindowDimensions } from '../../../../Utils/ScreenSize';
import { formatDateTime } from '../../../../Utils/TimeUtils';
import { sortEventByTimestamp } from '../../../../Utils/Events';
import { convertEventToMapMarker } from '../../../Components/Map/Utils';
import { NAV_TYPES, NAV_TYPES_ARRAY } from '../../../../Utils/NavTypes';

import withTrackerData from '../../../../redux/WithData/tracker';
import withLocationData from '../../../../redux/WithData/location';
import withLocationActions from '../../../../redux/WithActions/location';

const ALL_POSITIONS = [
  {
    type: NAV_TYPES.GPS.id,
    timestamp: new Date('2022-06-10T12:00:00'),
    pos: {
      lat: 51.00202586493287,
      lng: -0.9473548785898962
    }
  },
  {
    type: NAV_TYPES.GPS_LO.id,
    timestamp: new Date('2022-06-10T13:00:00'),
    pos: {
      lat: 51.00202586493287,
      lng: -0.9513548785898962
    }
  },
  {
    type: NAV_TYPES.GSM.id,
    timestamp: new Date('2022-06-10T14:00:00'),
    pos: {
      lat: 51.00402586493287,
      lng: -0.9473548785898962
    }
  },
  {
    type: NAV_TYPES.WIFI.id,
    timestamp: new Date('2022-06-10T15:00:00'),
    pos: {
      lat: 51.00402586493287,
      lng: -0.9513548785898962
    }
  },
];

const MapDetails = ({
  sx,

  tracker,
  latestLocations,
  areLatestLocationsLoading,
  getLatestLocations,
}) => {
  const { isMobileWidth, isTabletWidth } = useWindowDimensions();

  // const [locationTypes, setLocationTypes] = React.useState(['gpsHi', 'gpsLo', 'gsm', 'wifi']);
  const [locationTypes, setLocationTypes] = React.useState([]);
  const [posToShow, setPosToShow] = React.useState(undefined);

  const [positions, setPositions] = React.useState([ ALL_POSITIONS[0] ]);

  // Get latest location based on allowed types
  React.useEffect(() => {
    setPosToShow(convertEventToMapMarker(getLocationToShow(latestLocations, locationTypes)));
  }, [
    latestLocations,
    locationTypes,
    setPosToShow,
  ]);

  const mapCentre = {
    lat: 51.0030048,
    lng: -0.9494693
  };

  function getLocationToShow(locations, allowedLocationTypes) {
    let allowedLocations = [];
    
    for (let type of NAV_TYPES_ARRAY) {
      // Add all allowed location types to the array
      if (locations[type.id] && (allowedLocationTypes.length === 0 || allowedLocationTypes.includes(type.id))) {
        allowedLocations.push(locations[type.id]);
      }
    }
    
    if (allowedLocations.length > 0) {
      allowedLocations.sort(sortEventByTimestamp);

      return allowedLocations[0];
    }
    else {
      return undefined;
    }
  }
  
  const handleChangeLocationTypes = (_, newTypes) => {
    setLocationTypes(newTypes);

    updateLatestPosition(newTypes);
  }

  const updateLatestPosition = (enabledTypes) => {
    for (let i = 0; i < ALL_POSITIONS.length; i++) {
      // If no types are selected, or this position's type is selected
      if (!enabledTypes || enabledTypes.length === 0 || enabledTypes.includes(ALL_POSITIONS[i].type)) {
        setPositions([ ALL_POSITIONS[i] ]);
        break;
      }
    }
  }
  
  let mapSx = undefined;

  if (isMobileWidth) {
    mapSx = {
      height: '300px',
    };
  }
  else if (isTabletWidth) {
    mapSx = {
      height: '400px',
    };
  }

  const locationToggleButtons = (
    <ToggleButtonGroup value={locationTypes} onChange={handleChangeLocationTypes}>
      {NAV_TYPES_ARRAY.map(navType => (
        <ToggleButton key={navType.id} value={navType.id} size="small" color={navType.colour}>{navType.label}</ToggleButton>
      ))}
    </ToggleButtonGroup>
  );

  const locationMetadata = posToShow ? (
    <>
      <Typography>*94%*</Typography>
      <Divider orientation="vertical" flexItem sx={{ ml: 1, mr: 1 }}/>
      <Typography>*Vodafone*</Typography>
      <Divider orientation="vertical" flexItem sx={{ ml: 1, mr: 1 }}/>
      <Typography>{formatDateTime(DateTime.fromISO(posToShow.timestamp), true)}</Typography>
    </>
  ) : (
    <Typography>No location data matches filter</Typography>
  );

  return (
    <PaperSection sx={{ display: 'flex', flexDirection: 'column', overflow: 'auto', p: 0, ...sx }}>
      {/* <Map centre={positions[0].pos} positions={positions} sx={mapSx}/> */}
      <Map positions={posToShow && [ posToShow ]} sx={mapSx}/>

      <Divider/>

      {!isMobileWidth ? (
        <Box sx={{ m: 1, display: 'flex', flexDirection: 'row' }}>
          {locationToggleButtons}
          <Box sx={{ ml: 'auto', mt: 'auto', mb: 'auto', display: 'flex', flexDirection: 'row' }}>
            {locationMetadata}
          </Box>
        </Box>
      ) : (
        <Box sx={{ m: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Box sx={{ ml: 'auto', mr: 'auto' }}>
            {locationToggleButtons}
          </Box>
          <Box sx={{ mt: 2, ml: 'auto', mr: 'auto', display: 'flex', flexDirection: 'row' }}>
            {locationMetadata}
          </Box>
        </Box>
      )}
    </PaperSection>
  );
}

export default compose(withTrackerData, withLocationData, withLocationActions)(MapDetails);