import React from 'react';
import { Container } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import CustomDialog from '../Components/Dialog/CustomDialog';
import AddNewAccountDialogContent from './AddNewAccountDialogContent/AddNewAccountDialogContent';
import AccountsTable from './AccountsTable/AccountsTable';

const accountsManagerStyles = makeStyles(() => ({
  accountsListTableContainer: {
    flexDirection: 'column',
    boxSizing: 'border-box',
    alignItems: 'center',
    maxWidth: 'unset',
    padding: '24px',
    display: 'flex',
    height: '100%',
  },
}));

const AccountsManager = () => {
  const classes = accountsManagerStyles();

  return (
    <>
      <Container className={classes.accountsListTableContainer}>
        <CustomDialog
          btnText="Add New Account"
          headerText="Add New Account"
          sx={theme => ({
            [theme.breakpoints.up('tablet')]: {
              // Right-align the button on tablets & PCs
              alignSelf: 'flex-end',
            },
            marginBottom: 2,
          })}
          body={(handleClose) => (
            <AddNewAccountDialogContent handleClose={handleClose}/>
          )} />
        <AccountsTable />
      </Container>
    </>
  );
};

export default AccountsManager;
