import AuthCall from './auth';

export default {
  createNote: async (trackerId, text) => {
    return await AuthCall.post(`/api/v1/secure/audit/note/${trackerId}`, { text });
  },
  deleteNote: async (eventId) => {
    return await AuthCall.delete(`/api/v1/secure/audit/note/instance/${eventId}`);
  },
  getNotes: async (trackerId, limit = 200, offset = 0, startTime, endTime) => {
    return await AuthCall.get(`/api/v1/secure/audit/note/tracker/${trackerId}`, { limit, offset, startTime, endTime });
  },
  getAuditLogs: async (trackerId, limit = 50, offset = 0, startTime, endTime) => {
    return await AuthCall.get(`/api/v1/secure/audit/tracker/${trackerId}`, { limit, offset, startTime, endTime });
  },
  getAuditDetails: async (eventId) => {
    return await AuthCall.get(`/api/v1/secure/audit/details/${eventId}`);
  },
  getCommsLogs: async (trackerId, limit = 50, offset = 0, startTime, endTime) => {
    return await AuthCall.get(`/api/v1/secure/event/tracker/${trackerId}`, { limit, offset, startTime, endTime });
  },
  getNotificationLogs: async (trackerId, limit = 50, offset = 0, startTime, endTime) => {
    // TODO
    // return await AuthCall.get(`/api/v1/secure/???/???/${trackerId}`, { limit, offset, startTime, endTime });
    return { type: null, data: { data: [] } };
  },
}