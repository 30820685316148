import AuthCall from '../Endpoints/auth';

const TrackersAPI = {
  getTracker: async (simiccid) => {
    const response = await AuthCall.get(`/api/v1/secure/tracker/instance/${simiccid}`);
    return response;
  },
  getPaginatedTrackers: async (searchOptions = {}) => {
    // Set default values if no custom searchOptions are passed
    const offset    = searchOptions.offset || 0;
    const limit     = searchOptions.limit || 1000;
    // Sorting defaults to favorite trackers first in the backend
    const filters   = searchOptions.filters || {};
    // const response  = await AuthCall.post('/api/v1/secure/tracker/search/dashboard', filters, {}, { limit, offset });
    const response  = await AuthCall.post('/api/v1/secure/tracker/search/global', filters, {}, { limit, offset });
    return response;
  },
  getTrackersByFranchise: async (franchiseID, limit = 10, offset = 0) => {
    const filter = {
      filters: [],
      sorters: [],
    };
    const response = await AuthCall.post(`/api/v1/secure/tracker/search/franchise/${franchiseID}`, filter, {}, { limit, offset });
    return response;
  },
  getTrackersByFolder: async (folderID, limit = 10, offset = 0) => {
    const filter = {
      filters: [],
      sorters: [],
    };
    const response = await AuthCall.post(`/api/v1/secure/tracker/search/folder/${folderID}`, filter, {}, { limit, offset });
    return response;
  },
  createTracker: async (tracker) => {
    const data = {
      franchise: {
        name: tracker.franchiseName,
        uuid: tracker.franchiseUuid,
      },
      msisdn: tracker.msisdn,
      name: tracker.name,
      simiccid: tracker.simiccid,
    };
    const response = await AuthCall.put('/api/v1/secure/tracker/fromFactory', data);
    return response;
  },
  updateTrackerDetails: async (trackerId, newName, equipmentNumber, timezone) => {
    const response = await AuthCall.post(`/api/v1/secure/tracker/instance/${trackerId}`, { name: newName, equipmentNumber, timezone });
    return response;
  },
  uploadTrackerImage: async (trackerId, imagefile) => {
    const formData = new FormData();
    formData.append('file', imagefile);
    const header = { 'Content-Type': 'multipart/form-data' };
    const response = await AuthCall.post(`/api/v1/secure/tracker/image/upload/${trackerId}`, formData, header);
    return response;
  },
  registerTrackerEvent: async (simiccid, event) => {
    // const body = `${simiccid}@0L1=utc:133800.005?${Object.keys(event).map((key) => `${key}:${event[key]}`).join('?')}`;
    const body = `${simiccid}@0L3=${Object.keys(event).map((key) => `${key}:${event[key]}`).join('?')}`;
    const header = { 'Content-Type': 'text/plain' };
    const response = await AuthCall.post(`/api/v1/open/helios/event/${simiccid}`, body, header);
    return response;
  },
  getTrackerLocations: async (simiccid, { gsm, gps, wifi }, startTime, endTime, limit = 10, offset = 0) => {
    const response = await AuthCall.get(`/api/v1/secure/event/tracker/position/${simiccid}`, {
      gps, gsm, wifi, limit, offset, startTime, endTime,
    });
    return response;
  },
  getAllTrackerEvents: async (simiccid, startTime, endTime, limit = 10, offset = 0) => {
    const response = await AuthCall.get(`/api/v1/secure/event/tracker/${simiccid}`, {
      limit, offset, startTime, endTime,
    });
    return response;
  },
  updateTrackerSettings: async (trackerId, newSettings) => {
    const response = await AuthCall.post(`/api/v1/secure/tracker/settings/${trackerId}`, {
      ...newSettings,
    });
    return response;
  },
  updateTrackerRawSettings: async (trackerId, rawSettings) => {
    const response = await AuthCall.post(`/api/v1/secure/tracker/sms/${trackerId}`, {
      body: rawSettings,
      type: 'XT'
    });
    return response;
  },
  assignTrackersToFranchise: async (trackerIds, franchiseId) => {
    const response = await AuthCall.post(`/api/v1/secure/tracker/assign/franchise/${franchiseId}`, {
      simiccids: trackerIds,
    });
    return response;
  },
  assignTrackersToCustomer: async (trackerIds, customerId) => {
    const response = await AuthCall.post(`/api/v1/secure/tracker/assign/customer/${customerId}`, {
      simiccids: trackerIds,
    });
    return response;
  },
  unassignTrackersFromFranchise: async (trackerIds) => {
    const response = await AuthCall.delete('/api/v1/secure/tracker/assign/franchise', {
      simiccids: trackerIds,
    });
    return response;
  },
  unassignTrackersFromCustomer: async (trackerIds) => {
    const response = await AuthCall.delete('/api/v1/secure/tracker/assign/customer', {
      simiccids: trackerIds,
    });
    return response;
  },
  getLocationEventAddress: async (eventUuid) => {
    const response = await AuthCall.get(`/api/v1/secure/event/instance/address/${eventUuid}`);
    return response;
  },
  updateTrackerAsset: async (trackerId, assetDetails) => {
    const response = await AuthCall.get(`/api/v1/secure/tracker/asset/${trackerId}`, assetDetails);
    return response;
  },
};

export default TrackersAPI;
