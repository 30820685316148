import React from 'react';

import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Circle,
  Polygon,
} from '@react-google-maps/api';

import { useTheme } from '@mui/material/styles';

import {
  Box,
  CircularProgress,
} from '@mui/material';

import {
  isAreaPositionType,
  getBounds,
  getBoundsPos,
} from './Utils';

import { NAV_TYPES } from '../../../Utils/NavTypes';

const API_KEY = 'AIzaSyC-XZRiRrnbYk2bponbVZhJ4h4qIAfjxjk';

/**
 * React Map API: https://react-google-maps-api-docs.netlify.app
 * 
 * Map functions (https://developers.google.com/maps/documentation/javascript/reference/map):
 * moveCamera ( LatLng )        - abruptly move camera to co-ords
 * setZoom( number )            - abruptly changes zoom
 * fitBounds ( LatLngBounds )   - smoothly move & zoom camera to show bounds (adds some spacing automatically)
 * panTo( LatLng )              - smoothly move camera to co-ords
 * panBy({ x, y })              - smoothly move camera by co-ord values
 * panToBounds( LatLngBounds )  - smoothly move camera to show bounds (no zoom)
 */

const DEFAULT_POS = {
  lat: 51.0030048,
  lng: -0.9494693
};

const DEFAULT_ZOOM_NO_POS = 15;
const DEFAULT_ZOOM_GPS = 19;
// GSM/WIFI: fitBounds w/ LatLng +- (radius in m) * (0.1 / 7000)

const MAP_STYLE = {
  width: '100%',
  height: '100%'
};

const MARKER_OPTIONS = {
  opacity: 1.0,
};

// MUI LocationSearching:   'M20.94 11c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z'
// MUI LocationOn:          'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z'
// MUI Navigation:          'M12 2 4.5 20.29l.71.71L12 18l6.79 3 .71-.71z'
// MUI GPSFixed (modified): 'M12 10.5C11.2 10.5 10.5 11.2 10.5 12S11.2 13.5 12 13.5 13.5 12.8 13.5 12 12.8 10.5 12 10.5zM20.94 11c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z'

const MARKER_ICON = {
  // Path of MUI 'LocationSearching' icon
  // path: 'M20.94 11c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z',
  path: 'M12 10.5C11.2 10.5 10.5 11.2 10.5 12S11.2 13.5 12 13.5 13.5 12.8 13.5 12 12.8 10.5 12 10.5zM20.94 11c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z',
  anchor: {x: 12, y: 12},
  // fillColor: '#BF5FEC',
  fillOpacity: 0.8,
  // strokeColor: '#F7EBFD',
  // strokeColor: '#FFFFFF',
  // strokeOpacity: 1.0,
  // strokeWeight: 1.5,
  strokeWeight: 0,
  scale: 2,
};

const CIRCLE_OPTIONS = {
  fillColor: '#0000FF',
  fillOpacity: 0.25,
  // strokeColor: '#FF0000',
  strokeColor: '#FFFFFF',
  strokeOpacity: 1.0,
  strokeWeight: 2,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 200,
  // zIndex: 1,
};

const markerPos = { lat: 51.00302586493287, lng: -0.9493548785898962 };
const circlePos = { lat: 51.001776, lng: -0.9490214 };

const getMarker = (theme, index, position) => {
  // if (!position) {
  //   return <></>;
  // }
  
  let colourMain = theme.palette.nav[position.type].main;
  let colourDark = theme.palette.nav[position.type].dark;

  let radius = position.accuracy;
  
  if (!radius) {
    switch (position.type) {
      case NAV_TYPES.GSM.id:
        radius = 200;
        break;
  
      case NAV_TYPES.WIFI.id:
        radius = 100;
        break;
    }
  }
  
  switch (position.type) {
    case NAV_TYPES.GSM.id:
    case NAV_TYPES.WIFI.id:
      return <Circle
        key={position.timestamp}
        center={position.pos}
        options={{
          ...CIRCLE_OPTIONS,
          fillColor: colourMain,
          strokeColor: colourDark,
          radius: radius
        }}
      />;

    default:
      return <Marker
        key={position.timestamp}
        position={position.pos}
        icon={{
          ...MARKER_ICON,
          // fillColor: colourMain,
          // strokeColor: colourDark,
          fillColor: colourDark,
        }}
      />;
  }
};

const getZone = (theme, index, zone, isHightlighted) => {
  // if (!zone) {
  //   return <></>;
  // }

  const colour = zone.editable ? '#0000ff' :
    zone.forbidden ? '#ff0000' : '#00ff00';

  return <Polygon
    key={zone.id ? `p${zone.id}` : `p#${index}`}
    path={zone.path}
    draggable={zone.draggable}
    editable={zone.editable}
    options={{
      fillColor: colour,
      fillOpacity: isHightlighted ? 0.4 : 0.2,
      strokeColor: colour,
      strokeOpacity: isHightlighted ? 1.0 : 0.8,
      strokeWeight: isHightlighted ? 3 : 1,
    }}
  />
}

const getEditableZone = (theme, path, onEdit, onLoad, onUnmount) => {
  const colour = '#0000ff';

  return <Polygon
    path={path}
    draggable={true}
    editable={true}
    options={{
      fillColor: colour,
      fillOpacity: 0.2,
      strokeColor: colour,
      strokeOpacity: 0.8,
    }}
    onMouseUp={onEdit}    // Event used when manipulating and adding points
    onDragEnd={onEdit}    // Event used when dragging the whole Polygon
    onLoad={onLoad}
    onUnmount={onUnmount}
  />
}

const MTrackMap = ({
  autoPan = false,
  fullMap = false,
  positions,
  zones,
  highlightedZone,
  hightlightedZoneIdx,
  newZonePath,
  onEditPath,
  onLoadEditablePolygon,
  onUnmountEditablePolygon,
  sx,
}) => {
  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: API_KEY,
  });

  const theme = useTheme();

  const [map, setMap] = React.useState(null);
  // const [centre, setCentre] = React.useState({
  //   lat: 51.0030048,
  //   lng: -0.9494693
  // });
  // const [zoom, setZoom] = React.useState(15);
  
  const handleLoad = React.useCallback((mapInstance) => {
    setMap(mapInstance);
  }, []);

  // Pan & Zoom to show all positions
  React.useEffect(() => {
    if (map && positions) {
      // No pos -> Default pos & zoom
      if (positions.length === 0) {
        map.panTo({
          lat: 51.0,
          lng: -0.95
        });
        map.setZoom(DEFAULT_ZOOM_NO_POS);
      }
      else if (positions.length === 1) {
        // GSM/Wifi -> Zoom to rough bounds
        if (isAreaPositionType(positions[0])) {
          map.fitBounds(getBoundsPos(positions[0]));
        }
        // GPS -> Zoom to point
        else {
          map.panTo(positions[0].pos);
          map.setZoom(DEFAULT_ZOOM_GPS);
        }
      }
      // Multi-pos -> Zoom to bounds
      else {
        map.fitBounds(getBounds(positions));
      }
    }
  }, [
    map,
    positions,
  ]);

  // const handleTilesLoaded = () => {
  //   // Set centre & zoom to null after loading to prevent the map resetting anytime a marker is added
  //   setCentre(null);
  //   setZoom(null);
  // }

  const handleUnmount = React.useCallback((mapInstance) => {
    setMap(null);
  }, []);

  const MAP_OPTIONS = {
    // backgroundColor: '#e8eaed',   // Google Maps' background colour
    controlSize: 40,            // Default is 40. 30 for mobile?
    clickableIcons: false,
    fullscreenControl: fullMap,
    keyboardShortcuts: false,
    streetViewControl: fullMap,
    minZoom: 1,
    maxZoom: 21,
  };
  
  if (loadError) {
    console.error(loadError)
    // Render error message
  }
  
  return (
    <Box sx={[
      { width: '100%', height: '100%', ...sx },
      !isLoaded && { display: 'flex' }
    ]}>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={MAP_STYLE}
          // center={centre}
          // zoom={zoom}
          options={MAP_OPTIONS}
          // onDragStart={() => { console.log('onDragStart') }}
          // onDragEnd={() => { console.log('onDragEnd') }}
          // onIdle={() => { console.log('onIdle') }}
          // onZoomChanged={() => { console.log(`onZoomChanged${map ? ` [${map.getZoom()}]` : ''}`) }}
          onLoad={handleLoad}
          // onTilesLoaded={handleTilesLoaded}
          onUnmount={handleUnmount}
        >
          {positions && positions.map((pos, index) => (
            pos && getMarker(theme, index, pos)
          ))}
          {zones && zones.map((zone, index) => (
            zone && getZone(theme, index, zone, zone === highlightedZone || hightlightedZoneIdx === index)
          ))}
          {newZonePath && getEditableZone(theme, newZonePath, onEditPath, onLoadEditablePolygon, onUnmountEditablePolygon)}
        </GoogleMap>
      ) : (
        <CircularProgress sx={{ m: 'auto' }}/>
      )}
    </Box>
  );
}

export default MTrackMap;