import React from 'react';
import { compose } from 'redux';

import makeStyles from '@mui/styles/makeStyles';

import { mobile } from '../../Styles/styles';
import { isAccountRoleRoot } from '../../Utils/Utils';
import NewModal from '../Components/NewModal/NewModal';
import FranchiseList from './FranchiseList/FranchiseList';
import AddNewFranchiseModalContent from './AddNewFranchiseModal/AddNewFranchiseModal';

import withUserData from '../../redux/WithData/user';

const franchiseManagerStyles = makeStyles((theme) => ({
  franchiseManagerContainer: {
    flexDirection: 'column',
    boxSizing: 'border-box',
    alignItems: 'center',
    padding: '24px',
    display: 'flex',
    height: '100%',
    [theme.breakpoints.down(mobile)]: {
      padding: '0px',
    },
  },
}));

const FranchiseManager = ({ role }) => {
  const classes = franchiseManagerStyles();

  return (
    <div className={classes.franchiseManagerContainer}>
      {isAccountRoleRoot(role) && (
        <NewModal
          modalHeaderName="Add New Franchise"
          modalButtonName="Add New Franchise"
          ModalContentComponent={AddNewFranchiseModalContent}
        />
      )}
      <FranchiseList />
    </div>
  );
};

export default compose(withUserData)(FranchiseManager);
