import React from 'react';
import EventsList from './EventsList';
import GoogleMap from '../../../Components/Map_OLD';

const MapTab = () => (
  <>
    <div className="heliosMap-wrapper">
      <GoogleMap />
    </div>
    <EventsList />
  </>
);

export default MapTab;
