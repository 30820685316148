import Cookies from 'universal-cookie';
import { handleActions } from 'redux-actions';

import {
  loaded,
  loading,
  moveItems,
  toggleView,
  toggleItem,
  filterItems,
  removeFilter,
  deleteFolder,
  renameFolder,
  loadNewFolder,
  toggleAllItems,
  assignTrackers,
  toggleMoveItems,
  shareSubfolders,
  deselectAllItems,
  unassignTrackers,
  loadCurrentFolder,
  loadMoreFolderItems,
  loadRootLevelFolders,
  addUserToFolderPermissions,
  removeUserFromFolderPermissions,
} from '../Actions/FolderActions';
import { logout } from '../Actions/AuthActions';

const cookies = new Cookies();

const initialState = {
  rootLevelFolders: [],
  currentFolder: {
    content: [],
    subfolders: [],
  },
  selectedItems: [],
  selectAll: false,
  isLoading: true,
  isMovingItems: false,
  totalNumberOfItems: 0,
  numberOfItemsLoaded: 0,
  arePermissionsCascadedToSubfolders: true,
  mode: cookies.get('foldersView') || 'list',
};

export default handleActions({
  [logout]: () => initialState,
  [toggleView]: (state) => {
    const selectedView = state.mode === 'list' ? 'mosaic' : 'list';
    cookies.set('foldersView', selectedView, { path: '/', sameSite: 'lax' });
    return { ...state, mode: selectedView };
  },
  [loadRootLevelFolders]: (state, { payload: rootLevelFolders }) => ({
    ...state,
    rootLevelFolders,
    isLoading: false,
  }),
  [loadCurrentFolder]: (state, {
    payload: {
      currentFolder,
      totalNumberOfItems,
      numberOfItemsLoaded,
    },
  }) => ({
    ...state,
    currentFolder,
    totalNumberOfItems,
    numberOfItemsLoaded,
    isLoading: false,
  }),
  [loadMoreFolderItems]: (state, {
    payload: {
      numberOfItemsLoaded,
      items,
    },
  }) => {
    // Avoid duplicates after moving items between folders
    let newContent = [...items];
    if (state.currentFolder.content.length !== 0) {
      newContent = items
        .filter((item) => !state.currentFolder.content
          .some((currentItem) => currentItem.id === item.id));
    }
    return {
      ...state,
      currentFolder: {
        ...state.currentFolder,
        content: [
          ...state.currentFolder.content,
          ...newContent,
        ],
      },
      numberOfItemsLoaded: state.numberOfItemsLoaded + numberOfItemsLoaded,
      isLoading: false,
    };
  },
  [loadNewFolder]: (state, { payload: newFolder }) => ({
    ...state,
    currentFolder: {
      ...state.currentFolder,
      subfolders: [
        newFolder,
        ...state.currentFolder.subfolders,
      ],
      content: [
        newFolder,
        ...state.currentFolder.content,
      ],
    },
  }),
  [toggleItem]: (state, { payload: selectedItemId }) => {
    const isItemAlreadySelected = state.selectedItems
      .find((item) => item.id === selectedItemId);
    if (isItemAlreadySelected) {
      return {
        ...state,
        selectAll: false,
        selectedItems: state.selectedItems.filter((item) => item.id !== selectedItemId),
      };
    }
    const selectedItem = state.currentFolder.content
      .find((item) => item.id === selectedItemId);
    if (selectedItem) {
      return {
        ...state,
        selectAll: false,
        selectedItems: [
          ...state.selectedItems,
          selectedItem,
        ],
      };
    }
    const selectedRootLevelFolder = state.rootLevelFolders
      .find((item) => item.id === selectedItemId);
    return {
      ...state,
      selectAll: false,
      selectedItems: [
        ...state.selectedItems,
        selectedRootLevelFolder,
      ],
    };
  },
  [toggleAllItems]: (state) => ({
    ...state,
    isMovingItems: false,
    selectAll: !state.selectAll,
    selectedItems: !state.selectAll ? state.currentFolder.content : [],
  }),
  [deselectAllItems]: (state) => ({
    ...state,
    selectAll: false,
    selectedItems: [],
  }),
  [loading]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [loaded]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [filterItems]: (state, { payload: searchInput }) => ({
    ...state,
    currentFolder: {
      ...state.currentFolder,
      content: state.currentFolder.content
        .map((item) => (item.name.toLowerCase().includes(searchInput)
          ? { ...item, isFiltered: true }
          : { ...item, isFiltered: false })),
    },
  }),
  [removeFilter]: (state) => ({
    ...state,
    currentFolder: {
      ...state.currentFolder,
      content: state.currentFolder.content
        .map((folder) => ({ ...folder, isFiltered: true })),
    },
  }),
  [deleteFolder]: (state, { payload: folderId }) => ({
    ...state,
    selectedItems: [],
    currentFolder: {
      ...state.currentFolder,
      content: state.currentFolder.content
        .filter((item) => item.id !== folderId),
    },
  }),
  [renameFolder]: (state, { payload: { folderId, folderName } }) => ({
    ...state,
    selectedItems: [],
    currentFolder: {
      ...state.currentFolder,
      content: state.currentFolder.content
        .map((folder) => (folder.id === folderId
          ? { ...folder, name: folderName } : folder)),
    },
  }),
  [toggleMoveItems]: (state) => ({
    ...state,
    isMovingItems: !state.isMovingItems,
    selectedItems: !state.isMovingItems ? state.selectedItems : [],
  }),
  [moveItems]: (state, { payload: items }) => ({
    ...state,
    selectAll: false,
    isMovingItems: false,
    selectedItems: [],
    currentFolder: {
      ...state.currentFolder,
      content: [
        ...state.currentFolder.content,
        ...items,
      ],
    },
  }),
  [addUserToFolderPermissions]: (state, { payload: { email } }) => ({
    ...state,
    currentFolder: {
      ...state.currentFolder,
      content: state.currentFolder.content
        .map((folder) => (folder.id === state.selectedItems[0].id
          ? {
            ...folder,
            sharedWith: [
              ...folder.sharedWith,
              email,
            ],
          }
          : folder)),
    },
    selectedItems: [],
  }),
  [removeUserFromFolderPermissions]: (state, { payload: { email } }) => ({
    ...state,
    currentFolder: {
      ...state.currentFolder,
      content: state.currentFolder.content
        .map((folder) => (folder.id === state.selectedItems[0].id
          ? {
            ...folder,
            sharedWith: folder.sharedWith.filter((emailAddress) => emailAddress !== email),
          }
          : folder)),
    },
    selectedItems: [{
      ...state.selectedItems[0],
      sharedWith: state.selectedItems[0].sharedWith
        .filter((emailAddress) => emailAddress !== email),
    }],
  }),
  [shareSubfolders]: (state) => ({
    ...state,
    arePermissionsCascadedToSubfolders: !state.arePermissionsCascadedToSubfolders,
  }),
  [assignTrackers]: (state, { payload: { selectedTrackersIds, franchise, customer } }) => {
    if (franchise) {
      return {
        ...state,
        currentFolder: {
          ...state.currentFolder,
          content: state.currentFolder.content
            .map((item) => ((item.type === 'tracker' && selectedTrackersIds.includes(item.id))
              ? {
                ...item,
                franchise: {
                  id: franchise?.id,
                  name: franchise?.name,
                },
                customer: {
                  id: '',
                  name: '',
                },
              }
              : item)),
        },
      };
    }
    return {
      ...state,
      currentFolder: {
        ...state.currentFolder,
        content: state.currentFolder.content
          .map((item) => ((item.type === 'tracker' && selectedTrackersIds.includes(item.id))
            ? {
              ...item,
              customer: {
                id: customer?.id,
                name: customer?.name,
              },
            }
            : item)),
      },
    };
  },
  [unassignTrackers]: (state, { payload: { selectedTrackersIds, franchise } }) => {
    if (franchise) {
      return {
        ...state,
        currentFolder: {
          ...state.currentFolder,
          content: state.currentFolder.content
            .map((selectedItem) => (selectedTrackersIds.includes(selectedItem.id)
              ? {
                ...selectedItem,
                customer: {
                  id: '',
                  name: '',
                },
                franchise: {
                  id: '',
                  name: '',
                },
              }
              : selectedItem)),
        },
      };
    }
    return {
      ...state,
      currentFolder: {
        ...state.currentFolder,
        content: state.currentFolder.content
          .map((selectedItem) => (selectedTrackersIds.includes(selectedItem.id)
            ? {
              ...selectedItem,
              customer: {
                id: '',
                name: '',
              },
            }
            : selectedItem)),
      },
    };
  },
}, initialState);
