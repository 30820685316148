import { put, takeLatest } from 'redux-saga/effects';

import {
  loadNewAccount,
  createNewAccount,
  creatingNewAccount,
  getPaginatedAccounts,
  loadPaginatedAccounts,
  loadingPaginatedAccounts,
  stopLoadingPaginatedAccounts,
} from '../Actions/AccountActions';
import { authApiHandler } from './auth';
import AccountsAPI from '../../Api/Endpoints_OLD/account';
import { addNotification } from '../Actions/system';
import { ResponseTypes } from '../../Api/Responses/baseResponse';

export function* handleGetPaginatedAccounts() {
  yield put(loadingPaginatedAccounts());
  const response = yield authApiHandler(AccountsAPI.getAccounts, { limit: 1000, offset: 0 });

  if (response.type === ResponseTypes.ActionCompleted) {
    yield put(loadPaginatedAccounts(response.data.data));
  } else if (response.type === ResponseTypes.Unauthorized) {
    yield put(stopLoadingPaginatedAccounts());
    yield put(addNotification({
      type: 'error',
      title: 'Could not retreive accounts',
      source: 'handleGetPaginatedAccounts',
      message: 'Your account permissions do not allow us to retrieve this content for you.',
    }));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(stopLoadingPaginatedAccounts());
    yield put(addNotification({
      type: 'error',
      title: 'Could not retreive accounts',
      source: 'handleGetPaginatedAccounts',
      message: 'Please, check your connection and try again.',
    }));
  }
}

export function* handleCreateNewAccount({ payload: { newAccountDetails } }) {
  yield put(creatingNewAccount());
  const response = yield authApiHandler(AccountsAPI.addAccount, newAccountDetails);
  if (response.type === ResponseTypes.ActionCompleted) {
    const account = response.data;
    yield put(loadNewAccount(account));
    yield put(addNotification({
      type: 'success',
      title: 'Account successfully created!',
      source: 'handleCreateNewAccount',
      message: `Account for ${newAccountDetails.firstName} has been successfully created.`,
    }));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not add new account',
      source: 'handleCreateNewAccount',
      message: 'Please, check your connection and try again.',
    }));
  }
}

export default function* AccountSagas() {
  yield takeLatest(createNewAccount, handleCreateNewAccount);
  yield takeLatest(getPaginatedAccounts, handleGetPaginatedAccounts);
}
