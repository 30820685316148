import React from 'react';
import { compose } from 'redux';
import validator from 'validator';

import {
  Box,
  Button,
  TextField,
} from '@mui/material';

import {
  PersonAdd as AddUserIcon,
} from '@mui/icons-material';

import UserTable from '../../../Components/UserTable';
import FormDialog from '../../../Components/Dialog_NEW/FormDialog';

import withAccountData from '../../../../Hocs/WithData/account';
import withAccountActions from '../../../../Hocs/WithAction/account';

const UsersTab = ({
  accounts,
  areAccountsLoading,
  getPaginatedAccounts,
}) => {
  const [inviteOpen, setInviteOpen] = React.useState(false);
  
  // Load account data
  React.useEffect(() => {
    // TODO: filter by franchise
    getPaginatedAccounts({
      offset: 0,
      limit: 1000,
      filters: {},
    });
  }, [
    getPaginatedAccounts,
  ]);

  const handleClickAddUser = () => {
    setInviteOpen(true);
  }

  const handleCloseNewUser = () => {
    setInviteOpen(false);
  }

  const handleSubmitNewUser = (values) => {
    console.log(`Invited new user: '${values.email}'`);

    // TODO: API call

    setInviteOpen(false);
  }

  const handleValidateNewUser = (values) => {
    const errors = {};

    // Email
    if (!values.email) {
      errors.email = 'Required';
    } else if (!validator.isEmail(values.email)) {
      errors.email = 'Invalid email address';
    }

    return errors;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Button variant="contained" startIcon={<AddUserIcon/>} onClick={handleClickAddUser} sx={{ m: 2, ml: 'auto' }}>Invite</Button>
      <FormDialog
        open={inviteOpen}
        title="Invite New User"
        defaultValues={{ email: '' }}
        onSubmit={handleSubmitNewUser}
        onClose={handleCloseNewUser}
        onValidate={handleValidateNewUser}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
        }) => (
          <TextField
            required
            name="email"
            label="Email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(errors.email)}
            helperText={errors.email || undefined}
          />
        )}
      </FormDialog>

      <UserTable users={accounts} areUsersLoading={areAccountsLoading}/>
    </Box>
  );
}

export default compose(withAccountData, withAccountActions)(UsersTab);