import { connect } from 'react-redux';
import {
  changePageEventTrackerListAction,
  changeLimitEventTrackerListAction,
} from '../../../redux/Actions/EventActions';

export default (WrappedComponent) => connect(
  null,
  {
    nextPage: () => changePageEventTrackerListAction('next'),
    prevPage: () => changePageEventTrackerListAction('prev'),
    setPage: (page) => changePageEventTrackerListAction(page),
    changeLimit: (limit) => changeLimitEventTrackerListAction(limit),
  },
)(WrappedComponent);
