import { compose } from 'redux';
import React, { useEffect } from 'react';

import { Container } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import LastViewed from './lastViewed';
import { mobile } from '../../Styles/styles';
import withTrackersData from '../../Hocs/WithData/tracker';
import withTrackersActions from '../../Hocs/WithAction/tracker';
import FeaturedTrackersTable from './FeaturedTrackersTable/FeaturedTrackersTable';

const dashboardStyles = makeStyles((theme) => ({
  dashboardContainer: {
    maxWidth: 'unset',
    padding: '24px',
  },
  dashboardSectionContainer: {
    padding: 'unset',
    maxWidth: 'unset',
    marginBottom: '24px',
    justifyContent: 'space-between',
    // Breakpoint for Mobile
    [theme.breakpoints.down(mobile)]: {
      flexDirection: 'column',
      minHeight: 'unset',
    },
  },
  featuredTrackersContainer: {
    marginBottom: 'unset',
    padding: 'unset',
    display: 'flex',
    flexGrow: '1',
  },
}));

const Dashboard = ({
  lastViewedTracker,
  getFeaturedTrackers,
  getTracker,
}) => {
  const classes = dashboardStyles();

  useEffect(() => {
    getFeaturedTrackers();
  }, [getFeaturedTrackers]);

  useEffect(() => {
    if (lastViewedTracker) {
      getTracker(lastViewedTracker.simiccid);
    }
  }, [getTracker, lastViewedTracker]);

  return (
    <Container className={classes.dashboardContainer}>
      <Container className={classes.dashboardSectionContainer}>
        <LastViewed />
      </Container>
      <Container className={`
        ${classes.dashboardSectionContainer} 
        ${classes.featuredTrackersContainer}`}
      >
        <FeaturedTrackersTable tableType="favorites" />
        <FeaturedTrackersTable tableType="recent" />
      </Container>
    </Container>
  );
};
export default compose(withTrackersActions, withTrackersData)(Dashboard);
