import { compose } from 'redux';
import React, { useState } from 'react';

import {
  Button,
  TextField,
  Typography,
} from '@mui/material';

import addNewCustomerModalContentStyles from './style';
import withCustomerActions from '../../../Hocs/WithAction/customer';

const AddNewCustomerModalContent = ({
  handleClose,
  createNewCustomer,
  isCreatingNewCustomer,
}) => {
  const classes = addNewCustomerModalContentStyles();
  const [customerDetails, setCustomerDetails] = useState({
    name: '',
    companyName: '',
    defaultRole: 'USER',
  });

  const handleChangeCustomerDetails = ({ target: { name, value } }) => {
    setCustomerDetails({
      ...customerDetails,
      [name]: value,
    });
  };

  const handleSave = () => {
    createNewCustomer({ customerDetails });
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  const customerDetailsForm = (
    <>
      <Typography variant="h4" className={classes.addCustomerModalSectionHeader}>
        Customer Details
      </Typography>
      <div className={classes.addCustomerModalInputFieldContainer}>
        <TextField
          required
          autoFocus
          fullWidth
          name="name"
          size="small"
          variant="outlined"
          label="Customer Name"
          id="outlined-helperText"
          value={customerDetails.name}
          field={customerDetails.name}
          onChange={handleChangeCustomerDetails}
          className={classes.addCustomerModalInputField}
        />
      </div>
      <div className={classes.addCustomerModalInputFieldContainer}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="companyName"
          label="Company Name (Optional)"
          value={customerDetails.companyName}
          field={customerDetails.companyName}
          onChange={handleChangeCustomerDetails}
          className={classes.addCustomerModalInputField}
        />
      </div>
    </>
  );

  const submitButtons = (
    <div className={classes.addCustomerModalButtonsContainer}>
      <Button
        onClick={handleCancel}
        className={classes.addCustomerModalCancelButton}
      >
        Cancel
      </Button>
      <Button
        type="submit"
        onClick={handleSave}
        className={classes.addCustomerModalSaveButton}
        disabled={!customerDetails.name || isCreatingNewCustomer}
      >
        {isCreatingNewCustomer ? 'Saving...' : 'Save'}
      </Button>
    </div>
  );

  return (
    <div className={classes.customerModalContentContainer}>
      {customerDetailsForm}
      {submitButtons}
    </div>
  );
};

export default compose(withCustomerActions)(AddNewCustomerModalContent);
