import { styled } from '@mui/system';

export const BORDER_WIDTH = 1;

const PaperSection = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: `${theme.palette.divider} solid ${BORDER_WIDTH}px`, 
  padding: theme.spacing(3),
  // overflow: 'hidden',        // Breaks formatting in some places - use only on specific PaperSection elements

  display: 'flex',
  flexDirection: 'column',
}));

export default PaperSection;