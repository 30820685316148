import { DateTime } from 'luxon';

import {
  SignalCellular3BarRounded as SignalIcon,
  BatteryCharging90Rounded as BatteryIcon,
  MonitorHeart as HealthIcon,
} from '@mui/icons-material';

import ConfinedTypography from '../Components/Typographies/ConfinedTypography';
import UnitHealth, { HEALTH_STATES_ARRAY } from '../Components/UnitHealth';

import { getFullPaperSectionWidth } from '../../Utils/ScreenSize';
import { formatDateTime } from '../../Utils/TimeUtils';
import { SCROLLBAR_WIDTH } from "../../Utils/Defines";
import { ROLES } from '../../Utils/Roles';
import { STATES } from '../../Utils/States';
import { MODES } from '../../Utils/Modes';
import { TAGS } from '../../Utils/Tags';
import { ASSET_TYPES } from '../../Utils/AssetTypes';

const convertTagsToList = (tags) => {
  if (!tags || tags.length < 1) {
    return '-';
  }
  else {
    let tagList = '';

    for (let i = 0; i < tags.length; i++) {
      if (i > 0) {
        tagList = tagList.concat(', ');
      }

      tagList = tagList.concat(TAGS[tags[i]].label);
    }

    return tagList;
  }
}

// TODO: remove all dummy data
// const DUMMY_TAGS = [ 'customSettings', 'wired', 'lowPower' ];
const DUMMY_TAGS = [];

const COL_STATE         = 110;
const COL_ALERT_TYPE    = 130;
const COL_MODE          = 90;
const COL_TAGS_COUNT    = 55;
const COL_TAGS_LIST_MIN = 120;
const COL_SERIAL        = 180;
const COL_TYPE          = 120;
const COL_TIMESTAMP     = 160;
const COL_SIGNAL        = 70;
const COL_BATTERY       = 70;
const COL_HEALTH        = 70;

const THRESHOLD_BASE        = 450;
const THRESHOLD_BATTERY     = THRESHOLD_BASE + COL_BATTERY;
const THRESHOLD_TAGS_COUNT  = THRESHOLD_BATTERY + COL_TAGS_COUNT;
const THRESHOLD_TIMESTAMP   = THRESHOLD_TAGS_COUNT + COL_TIMESTAMP;
const THRESHOLD_TYPE        = THRESHOLD_TIMESTAMP + COL_TYPE;
const THRESHOLD_SERIAL      = THRESHOLD_TYPE + COL_SERIAL;
const THRESHOLD_ALERT_TYPE  = THRESHOLD_SERIAL + COL_ALERT_TYPE;
const THRESHOLD_SIGNAL      = THRESHOLD_ALERT_TYPE + COL_SIGNAL;
const THRESHOLD_TAGS_LIST   = THRESHOLD_SIGNAL - COL_TAGS_COUNT + COL_TAGS_LIST_MIN;

// TODO: use real data
const ALL_COLUMNS = {
  unitState:          { width: COL_STATE, label: "State", dataKey: "state", sortable: true, render: ({ cellData }) => STATES[cellData?.mnemonic]?.label || '-' },
  alertType:          { width: COL_ALERT_TYPE, label: "Alert Type", dataKey: "alertType", sortable: true, render: ({ cellData }) => cellData ? cellData : '-' },
  mode:               { width: COL_MODE, label: "Mode", dataKey: "mode", sortable: true, render: ({ rowData }) => MODES[rowData?.currentSettings?.mode]?.label || '-' },
  tagsList:           { width: 0, label: "Tags", dataKey: "tags", dynamic: true, render: ({ cellData }) => <ConfinedTypography title={(cellData && cellData.length > 0) || DUMMY_TAGS.length > 0 || undefined}>{convertTagsToList(cellData || DUMMY_TAGS)}</ConfinedTypography> },
  tagsCount:          { width: COL_TAGS_COUNT, label: "Tags", dataKey: "tags", render: ({ cellData }) => cellData ? cellData.length : DUMMY_TAGS.length },
  serialNumber:       { width: COL_SERIAL, label: "SIM ICC ID", dataKey: "simiccid", sortable: true },
  type:               { width: COL_TYPE, label: "Type", dataKey: "asset", sortable: true, render: ({ cellData }) => ASSET_TYPES[cellData?.type]?.label || '-' },
  // name:               { width: 0, label: "Name", dataKey: "asset", sortable: true, dynamic: true, render: ({ cellData, rowData }) => <ConfinedTypography title>{cellData?.name || `*${rowData.name}` || '-'}</ConfinedTypography> },
  name:               { width: 0, label: "Name", dataKey: "asset", sortable: true, dynamic: true, render: ({ cellData }) => <ConfinedTypography title>{cellData?.name || '-'}</ConfinedTypography> },
  customerName:       { width: 0, label: "Customer", dataKey: "customer", sortable: true, dynamic: true, render: ({ cellData }) => cellData ? <ConfinedTypography title>{cellData}</ConfinedTypography> : '-' },
  customerFranchise:  { width: 0, label: "Customer", dataKey: "customer", sortable: true, dynamic: true, render: ({ cellData, rowData }) => cellData ? <ConfinedTypography title>{`[AM] ${cellData}`}</ConfinedTypography> : '-' },
  timestamp:          { width: COL_TIMESTAMP, label: "Latest Update", dataKey: "lastPositionTimestamp", sortable: true, render: ({ cellData }) => cellData ? formatDateTime(DateTime.fromISO(cellData), false) : '-'},
  signal:             { width: COL_SIGNAL, label: <SignalIcon/>, dataKey: "signal", sortable: true, render: ({ cellData }) => cellData ? `${cellData}%` : '-' },
  battery:            { width: COL_BATTERY, label: <BatteryIcon/>, dataKey: "battery", sortable: true, render: ({ cellData }) => cellData ? `${cellData}%` : '-' },
  health:             { width: COL_HEALTH, label: <HealthIcon/>, dataKey: "health", sortable: true, render: ({ cellData, rowIdx }) => <UnitHealth state={HEALTH_STATES_ARRAY[rowIdx % HEALTH_STATES_ARRAY.length]} size={20} sx={{ ml: '2px' }}/> },
};

export function getColumns(windowDimensions, role) {
  const TABLE_WIDTH = getFullPaperSectionWidth(windowDimensions);

  // Columns are defined based on how wide the screen is
  let columns = [];

  columns.push(ALL_COLUMNS.unitState);

  if (TABLE_WIDTH > THRESHOLD_ALERT_TYPE) {
    columns.push(ALL_COLUMNS.alertType);
  }

  columns.push(ALL_COLUMNS.mode);

  if (TABLE_WIDTH > THRESHOLD_TAGS_LIST) {
    columns.push(ALL_COLUMNS.tagsList);
  }
  else if (TABLE_WIDTH > THRESHOLD_TAGS_COUNT) {
    columns.push(ALL_COLUMNS.tagsCount);
  }

  if (TABLE_WIDTH > THRESHOLD_SERIAL) {
    columns.push(ALL_COLUMNS.serialNumber);
  }

  if (TABLE_WIDTH > THRESHOLD_TYPE) {
    columns.push(ALL_COLUMNS.type);
  }

  columns.push(ALL_COLUMNS.name);

  if (role === ROLES.root) {
    columns.push(ALL_COLUMNS.customerFranchise);
  }
  else if (role === ROLES.admin) {
    columns.push(ALL_COLUMNS.customerName);
  }

  if (TABLE_WIDTH > THRESHOLD_TIMESTAMP) {
    columns.push(ALL_COLUMNS.timestamp);
  }

  if (TABLE_WIDTH > THRESHOLD_SIGNAL) {
    columns.push(ALL_COLUMNS.signal);
  }

  if (TABLE_WIDTH > THRESHOLD_BATTERY) {
    columns.push(ALL_COLUMNS.battery);
  }

  columns.push(ALL_COLUMNS.health);

  let remainingWidth        = TABLE_WIDTH;
  let dyncamicColumnIndices = [];
  
  // Find total width of fixed columns and indices of dynamic columns
  for (let i = 0; i < columns.length; i++) {
    if (!columns[i].dynamic) {
      remainingWidth -= columns[i].width;
    }
    else {
      dyncamicColumnIndices.push(i);
    }
  }

  const dynamicColWidth = Math.floor(remainingWidth / dyncamicColumnIndices.length);

  // Apply equal width to all dynamic columns 
  for (let j = 0; j < dyncamicColumnIndices.length; j++) {
    columns[dyncamicColumnIndices[j]].width = dynamicColWidth;
  }
  
  return columns;
}