import React from 'react';

import {
  Folder as FolderIcon,
  Store as CustomersIcon,
  GpsFixed as TrackerIcon,
  Business as FranchiseIcon,
  Dashboard as DashboardIcon,
  WifiTethering as InspectIcon,
  SupervisorAccount as AccountsIcon,
} from '@mui/icons-material';

import { isAccountRoleRoot, isAccountRoleUser } from '../Utils/Utils';
import { ROLES } from '../Utils/Roles';

const dashboard = {
  id: 'dashboard',
  label: 'Dashboard',
  linkTo: '/dashboard',
  icon: <DashboardIcon />,
};

const unitSearch = {
  id: 'unitSearch',
  label: 'Trackers',
  linkTo: '/trackers',
  icon: <TrackerIcon />,
};

const customers = {
  id: 'customers',
  label: 'Customers',
  linkTo: '/customers',
  icon: <CustomersIcon />,
};

const franchises = {
  id: 'franchises',
  label: 'Franchises',
  linkTo: '/franchises',
  icon: <FranchiseIcon />,
};



const dashboard_old = {
  id: 'dashboard_old',
  label: 'Dashboard OLD',
  linkTo: '/dashboard_old',
  icon: <DashboardIcon />,
};

const trackers_old = {
  id: 'trackers_old',
  label: 'Trackers OLD',
  linkTo: '/trackers_old',
  icon: <TrackerIcon />,
};

const franchises_old = {
  id: 'franchises_old',
  label: 'Franchises OLD',
  linkTo: '/franchises_old',
  icon: <FranchiseIcon />,
};

const customers_old = {
  id: 'customers_old',
  label: 'Customers OLD',
  linkTo: '/customers_old',
  icon: <CustomersIcon />,
};

const folders_old = {
  id: 'folders_old',
  label: 'Folders OLD',
  linkTo: '/folders',
  icon: <FolderIcon />,
};

const accounts_old = {
  id: 'accounts_old',
  label: 'Accounts OLD',
  linkTo: '/accounts',
  icon: <AccountsIcon />,
};



const getRoutes = (role, simiccid) => {
  const customerDetails = {
    id: 'customerDetails',
    label: 'Customer Details',  // TODO: update to customer name?
    linkTo: '/customers',       // TODO: update to full URL
    icon: <CustomersIcon />,
  };
  
  const franchiseDetails = {
    id: 'franchiseDetails',
    label: 'Franchise Details', // TODO: update to franchise name?
    linkTo: '/franchises',      // TODO: update to full URL
    icon: <FranchiseIcon />,
  };

  const trackerDetails_old = {
    id: 'trackerDetails_old',
    label: 'Tracker Details OLD',
    linkTo: simiccid ? `/trackerDetails_old/${simiccid}` : '/trackerDetails_old',
    icon: <InspectIcon />,
  };

  let routes = [];

  routes.push(dashboard);       // All users

  if (role !== ROLES.user) {
    // TODO: B2B customers should also get access to Unit Search
    routes.push(unitSearch);
  }

  if (role !== ROLES.user) {
    routes.push(customers);
  }
  else {
    routes.push(customerDetails);
  }

  if (role === ROLES.root) {
    routes.push(franchises);
  }
  else {
    routes.push(franchiseDetails);
  }

  // if (role === ROLES.root) {
  //   // TODO: remove
  //   routes.push(dashboard_old);
  //   routes.push(trackers_old);
  //   routes.push(trackerDetails_old);
  //   routes.push(franchises_old);
  //   routes.push(customers_old);
  //   routes.push(folders_old);
  //   routes.push(accounts_old);
  // }

  return routes;
};

export default getRoutes;
