import { createAction } from 'redux-actions';

const ENTITY = '@@EXAMPLE';

/**
 * Groups of redux files for:
 * - system       - TODO (theme, language, notifications, header shadow?)
 * - auth
 * - user
 * - tracker
 * - events       - TODO (new locations/notifications/warning/alarms)
 * - locations
 * - logs         - TODO
 * - customer     - TODO
 * - franchise    - TODO
 */

/**
 * Define any individual Actions that can modify the Store or call API functions
 */

// Local Param
export const setLocalParam = createAction(`${ENTITY}/SET_LOCAL_PARAM`);

// Remote Tracker
// * Exposed in WithActions *
export const getRemoteTracker = createAction(`${ENTITY}/GET_REMOTE_TRACKER`);
export const setRemoteTrackerName = createAction(`${ENTITY}/SET_REMOTE_TRACKER_NAME`);

// * For use in Sagas only *
export const loadRemoteTracker = createAction(`${ENTITY}/LOAD_REMOTE_TRACKER`);
export const setRemoteTrackerLoading = createAction(`${ENTITY}/SET_REMOTE_TRACKER_LOADING`);