import React from 'react';
import { compose } from 'redux';

import { IconButton } from '@mui/material';
import { Star, StarBorder } from '@mui/icons-material';

import trackerDetailsStyles from './style';
import withTrackersData from '../../../Hocs/WithData/tracker';
import withTrackersActions from '../../../Hocs/WithAction/tracker';

const FavoriteTrackerButton = ({
  tracker,
  favoriteTrackers,
  addTrackerToFavouritesReq,
  removeTrackerFromFavouritesReq,
}) => {
  const classes = trackerDetailsStyles();
  const isTrackerFavorite = favoriteTrackers
    .find((favoriteTracker) => favoriteTracker.simiccid === tracker.simiccid);

  const handleClick = () => (
    isTrackerFavorite
      ? removeTrackerFromFavouritesReq(tracker.simiccid)
      : addTrackerToFavouritesReq(tracker));

  return (
    <div className={classes.favoriteTrackerButtonContainer}>
      <IconButton
        onClick={handleClick}
        className={classes.favoriteTrackerButton}
        size="large">
        {(isTrackerFavorite) ? <Star /> : <StarBorder />}
      </IconButton>
    </div>
  );
};

export default compose(withTrackersActions, withTrackersData)(FavoriteTrackerButton);
