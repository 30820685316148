import { compose } from 'redux';
import React, { useEffect } from 'react';
import { PhotoCamera as CameraIcon } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { mobile } from '../../Styles/styles';
import CustomAvatar from '../Components/Elements/avatar';

import withUserData from '../../redux/WithData/user';
import withProfileActions from '../../Hocs/WithAction/profile';

const profileImageUploaderStyles = makeStyles((theme) => ({
  overlay: {
    opacity: '0',
    color: 'white',
    width: '150px',
    left: '-150px',
    height: '150px',
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    position: 'relative',
    background: '#92278F',
    transition: '.5s ease',
    justifyContent: 'center',
    // Breakpoint for Mobile
    [theme.breakpoints.down(mobile)]: {
      left: 'unset',
      top: '-150px',
    },
  },
  loading: {
    opacity: '50%',
    '& svg': {
      color: 'white',
    },
  },
  imageUploader: {
    display: 'flex',
    cursor: 'pointer',
    '&:hover': {
      '& div': {
        opacity: '50%',
      },
    },
    // Breakpoint for Mobile
    [theme.breakpoints.down(mobile)]: {
      maxHeight: '150px',
      display: 'unset',
      width: 'unset',
    },
  },
}));

const ProfileImageUploader = ({
  isProfileImageUploading,
  profileImageUploading,
  uploadProfileImage,
  getProfileDetails,
}) => {
  const classes = profileImageUploaderStyles();

  useEffect(() => {
    getProfileDetails();
  }, [getProfileDetails]);

  const handleChangeImage = ({ target: { files } }) => {
    const imageFile = files[0];

    if (imageFile) {
      profileImageUploading();
      uploadProfileImage(imageFile);
    }
  };

  return (
    <label htmlFor="file-upload" className={classes.imageUploader}>
      <CustomAvatar />
      {isProfileImageUploading
        ? (
          <div className={`${classes.overlay} ${classes.loading}`}>
            <CircularProgress />
          </div>
        )
        : (
          <div className={classes.overlay}>
            <CameraIcon />
          </div>
        )}
      <input
        hidden
        type="file"
        id="file-upload"
        accept="image/jpeg"
        onChange={handleChangeImage}
      />
    </label>
  );
};

export default compose(withUserData, withProfileActions)(ProfileImageUploader);
