import { connect } from 'react-redux';

import {
  getLatestEvents,

  createNote,
  deleteNote,
  getNotes,
  getAuditLogs,
  getAuditDetails,
  getCommsLogs,
  getNotificationLogs,

  receiveEventFromWS,
  setAutoUpdateEvents,
} from '../Actions/event';

export default (WrappedComponent) => connect(null, {
  getLatestEvents,

  createNote,
  deleteNote,
  getNotes,
  getAuditLogs,
  getAuditDetails,
  getCommsLogs,
  getNotificationLogs,

  setAutoUpdateEvents,
  receiveEventFromWS,
})(WrappedComponent);