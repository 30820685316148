export const CARRIERS = {
  GPRS: {
    id: 'GPRS',
    label: 'GPRS',
    colour: 'GPRS',
  },
  CAT_M1: {
    id: 'CAT_M1',
    label: 'CAT-M1',
    colour: 'CAT_M1',
  },
  SMS: {
    id: 'SMS',
    label: 'SMS',
    colour: 'SMS',
  },
};

export const CARRIERS_ARRAY = [
  CARRIERS.GPRS,
  CARRIERS.CAT_M1,
  CARRIERS.SMS,
];