import React from 'react';
import { styled } from '@mui/system';
import { useTheme, alpha } from '@mui/material/styles';

import {
  Box,
  ButtonBase,
} from '@mui/material';

import {
  NotificationsActive as AlarmOnIcon,
  NotificationsOff as AlarmOffIcon,
} from '@mui/icons-material';

import { LARGE_WIDTH, SMALL_WIDTH, LARGE_HEIGHT, SMALL_HEIGHT, LARGE_BORDER_WIDTH, SMALL_BORDER_WIDTH, LARGE_FONT_SIZE, SMALL_FONT_SIZE } from './ModeButton';
import { MODES } from '../../../Utils/Modes';

const HandleBase = styled(ButtonBase)(({ theme, ownerState }) => ({
  width: `${ownerState.width}px`,
  height: `${ownerState.height}px`,
  backgroundColor: ownerState.active ? theme.palette.action.selected : 'transparent',
  fontSize: ownerState.fontSize,
  fontWeight: ownerState.active ? 'bold' : 'normal',
  color: ownerState.textColour,
  borderRightWidth: ownerState.borderWidth,
  borderRightColor: ownerState.colour,
  borderRightStyle: 'solid',

  ...(ownerState.clickable && {
    transition: theme.transitions.create(
      ['background-color', 'border-color', 'color'],
      { duration: theme.transitions.duration.short },
    ),

    '&:hover': {
      backgroundColor: ownerState.active ? theme.palette.action.focus : theme.palette.action.hover,

      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: ownerState.active ? theme.palette.action.selected : 'transparent',
      },
    },
  }),
}));

const HandleButton = ({
  mode,
  active,
  small = false,
  onClick,
  sx,
  ...props
}) => {
  const theme = useTheme();

  const disabled = !(mode === MODES.ALARM.id) && !(mode === MODES.WARNING.id);
  const clickable = !disabled && !active && onClick;

  const WIDTH  = small ? SMALL_WIDTH  : LARGE_WIDTH;
  const HEIGHT = small ? SMALL_HEIGHT : LARGE_HEIGHT;
  const BORDER_WIDTH = small ? SMALL_BORDER_WIDTH : LARGE_BORDER_WIDTH;
  const FONT_SIZE = small ? SMALL_FONT_SIZE : LARGE_FONT_SIZE;

  const TEXT_COLOUR = !disabled ? theme.palette.text.primary : theme.palette.text.disabled;
  const ICON_COLOUR = (!disabled && !active) ? theme.palette.text.secondary : theme.palette.text.disabled;
  const ICON_SIZE = small ? 'medium' : 'large';

  let colour;

  if (disabled) {
    colour = alpha(theme.palette.health.t0, 0.3);
  }
  else if (active) {
    colour = theme.palette.modes[mode].dark;
  }
  else {
    colour = theme.palette.modes[mode].light;
  }

  const ownerState = {
    colour,
    active,
    width: WIDTH,
    height: HEIGHT * 2 + 1,
    borderWidth: BORDER_WIDTH,
    textColour: TEXT_COLOUR,
    fontSize: FONT_SIZE,
    clickable,
  };
  
  return (
    <Box sx={sx} {...props}>
      <HandleBase ownerState={ownerState} disabled={disabled} onClick={onClick} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ height: `${HEIGHT}px`, display: 'flex', alignItems: 'center' }}>Handle</Box>
        <Box sx={{ height: `${HEIGHT}px`, display: 'flex', mt: '1px', color: ICON_COLOUR }}>
          {(!disabled && !active) ? (
            <AlarmOnIcon fontSize={ICON_SIZE}/>
          ) : (
            <AlarmOffIcon fontSize={ICON_SIZE}/>
          )}
        </Box>
      </HandleBase>
    </Box>
  );
}

export default HandleButton;