import Cookies from 'universal-cookie';
import { handleActions } from 'redux-actions';
import {
  logout,
  login,
  register,
  submitted,
  submitting,
  tokenRefreshed,
  tokenRefreshing,
} from '../Actions/AuthActions';

const cookies = new Cookies();

const initialState = {
  email: cookies.get('email') || '',
  auth: {
    accessToken: cookies.get('accessToken') || '',
    refreshToken: cookies.get('refreshToken') || '',
  },
  isSubmitting: false,
  tokenRefreshing: false,
  isLoggedIn: !!cookies.get('accessToken'),
};

export default handleActions({
  [register]: (state) => ({
    ...state,
    isSubmitting: false,
  }),
  [login]: (state, { payload: { accessToken, refreshToken, email } }) => ({
    ...state,
    email,
    auth: {
      accessToken,
      refreshToken,
    },
    isLoggedIn: true,
    isSubmitting: false,
  }),
  [tokenRefreshed]: (state, { payload: { accessToken } }) => ({
    ...state,
    auth: {
      ...state.auth,
      accessToken,
    },
    tokenRefreshing: false,
  }),
  [tokenRefreshing]: (state) => ({
    ...state,
    tokenRefreshing: true,
  }),
  [logout]: () => {
    cookies.remove('accessToken');
    cookies.remove('refreshToken');
    return ({
      auth: {
        accessToken: '',
        refreshToken: '',
      },
      isLoggedIn: false,
      isSubmitting: false,
      tokenRefreshing: false,
    });
  },
  [submitting]: (state) => ({
    ...state,
    isSubmitting: true,
  }),
  [submitted]: (state) => ({
    ...state,
    isSubmitting: false,
  }),
}, initialState);
