import React from 'react';
import { compose } from 'redux';
import {
  Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';

import TimeTools from '../../../../Components/TimeTools';
import { bearingToCoordinates } from '../../../../Utils/Utils';

import withTrackerLocations from '../../../../Hocs/WithData/Trackers/eventLocationEvents';
import CurrentTrackerData from '../../../../Hocs/WithData/Trackers/singleTracker';
import withUserData from '../../../../redux/WithData/user';

const TableRowMaps = ({ event: { timestamp, details }, userTimezone, tracker }) => (
  <>
    <TableCell className="table-row">{ TimeTools.getTimezoneFormat24(timestamp, { timezone: userTimezone }) }</TableCell>
    <TableCell className="table-row">{ TimeTools.getTimezoneFormat24(timestamp, tracker) }</TableCell>
    <TableCell className="table-row">{ details.position.method }</TableCell>
    <TableCell className="table-row">
      {details.position.w3w.split('.').map((word) => (<div key={word}>{word}</div>))}
    </TableCell>
    <TableCell className="table-row">
      { details.gps
        ? `${details.gps.speed} km/h` : '-' }
    </TableCell>
    <TableCell className="table-row">
      { details.gps
        ? `${details.gps.bearing}° ${bearingToCoordinates(details.gps.bearing)}` : '-'}
    </TableCell>
  </>
);

const TableMaps = ({
  onClick, locationEvents, selectedEvent, userTimezone, currentTracker,
}) => {
  const headers = [
    'Your Time',
    'Tracker Time',
    'Type',
    'Location',
    'Speed',
    'Bearing',
  ];

  return (
    <Table className="tableMap" aria-label="customized table">
      <TableHead className="table-header">
        <TableRow>
          {headers.map((head) => <TableCell className="table-head" key={`${head}Head`}>{head}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody className="table-body">
        { locationEvents.map((event, index) => (
          <TableRow
            onClick={() => onClick(index)}
            key={`${event.timestamp}${Math.random()}Rows`}
            className={`table-row-body ${event.id === selectedEvent.id ? 'selected' : ''}`}
          >
            <TableRowMaps event={event} userTimezone={userTimezone} tracker={currentTracker} />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
export default compose(withTrackerLocations, CurrentTrackerData, withUserData)(TableMaps);
