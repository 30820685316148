import React from 'react';

import { Container } from '@mui/material';

import FilterSelector from './filterSelector';
import TrackersTable from '../TrackersTable/TrackersTable';
import SearchTrackersModal from '../SearchTrackersModal/SearchTrackersModal';
import trackersManagerStyles from './style';

const TrackerManager = () => {
  const classes = trackersManagerStyles();

  return (
    <Container className={classes.trackerManagerContainer}>
      <Container className={classes.trackerListHeaderContainer}>
        <div className={classes.trackerListActionsContainer}>
          <SearchTrackersModal />
          <FilterSelector />
        </div>
      </Container>
      <Container className={classes.trackersListTableContainer}>
        <TrackersTable />
      </Container>
    </Container>
  );
};

export default TrackerManager;
