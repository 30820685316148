import { compose } from 'redux';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import CloseIcon from '@mui/icons-material/Close';
import ShareIcon from '@mui/icons-material/Share';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBack from '@mui/icons-material/ArrowBack';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import MosaicViewIcon from '@mui/icons-material/Apps';
import ListViewIcon from '@mui/icons-material/ViewList';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';

import {
  Button,
  IconButton,
  Typography,
  ListItemIcon,
} from '@mui/material';

import toolBarStyles from './style';
import { mobile } from '../../../Styles/styles';
import CustomModal from '../../Components/Modal';
import withFoldersData from '../../../Hocs/WithData/folder';
import withFoldersActions from '../../../Hocs/WithAction/folder';
import ModalContent from '../../Components/Modal/ModalContent/ModalContent';

function FolderToolBar({
  mode,
  toggleView,
  shareFolder,
  isMovingItems,
  selectedItems,
  currentFolder,
  createNewFolder,
  shareSubfolders,
  toggleMoveItems,
  moveItemsRequest,
  deselectAllItems,
  rootLevelFolders,
  deleteFolderRequest,
  renameFolderRequest,
  assignTrackersRequest,
  unassignTrackersRequest,
  arePermissionsCascadedToSubfolders,
}) {
  const history = useHistory();
  const classes = toolBarStyles();
  const { id: currentFolderId } = useParams();
  const isCurrentFolderVirtualRoot = currentFolder.name === 'Virtual Root';
  const areAllSelectedItemsTrackers = selectedItems
    .every((selectedItem) => selectedItem.type === 'tracker');

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener('resize', handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, []);

  const handleCreateNewFolder = ({ folderName }) => {
    createNewFolder(
      { folderName, parentFolderId: currentFolderId },
    );
  };

  const handleRenameFolder = ({ folderName }) => {
    renameFolderRequest(
      { folderId: selectedItems[0].id, folderName },
    );
  };

  const handleDeleteFolders = () => {
    selectedItems
      .forEach((item) => deleteFolderRequest({ folderId: item.id, folderName: item.name }));
  };

  const handleShareFolder = ({ email }) => {
    shareFolder({
      arePermissionsCascadedToSubfolders,
      folderName: selectedItems[0].name,
      folderId: selectedItems[0].id,
      email,
    });
  };

  const handleMoveItems = () => {
    const isMovingToSameFolder = selectedItems
      .every((selectedItem) => selectedItem.parentFolder.id === currentFolder.id);
    if (isMovingItems && !isMovingToSameFolder) {
      moveItemsRequest({
        newParentFolder: currentFolder,
        items: selectedItems,
      });
    } else {
      toggleMoveItems();
    }
  };

  const handleAssignTracker = ({ franchise, customer }) => {
    const selectedTrackersIds = selectedItems.map((selectedItem) => selectedItem.id);
    if (franchise) {
      assignTrackersRequest({ selectedTrackersIds, franchise });
    } else {
      assignTrackersRequest({ selectedTrackersIds, customer });
    }
  };

  const handleUnassignTracker = ({ franchise, customer }) => {
    const selectedTrackersIds = selectedItems.map((selectedItem) => selectedItem.id);
    if (franchise) {
      unassignTrackersRequest({ selectedTrackersIds, franchise });
    } else {
      unassignTrackersRequest({ selectedTrackersIds, customer });
    }
  };

  const backButton = (
    <Button
      key="back"
      variant="text"
      startIcon={<ArrowBack />}
      onClick={() => history.goBack()}
      sx={{
        marginRight: 'auto',
        padding: '5px unset 5px 5px',
        textTransform: 'none',
      }}
    >
      {currentFolder.name}
    </Button>
  );

  let moveItemsButtonText;
  if (isMovingItems && (width > mobile)) {
    moveItemsButtonText = 'Move Here';
  }
  if (!isMovingItems && width > mobile) {
    moveItemsButtonText = 'Move';
  }

  function moveItemsButtonIcon() {
    if ((isMovingItems) && (selectedItems.length > 0)) {
      return <MoveToInboxIcon />;
    }
    return <ExitToAppIcon style={{ fontSize: '24px' }} />;
  }

  const moveItemsButton = (
    <div key="move" className={classes.menuItemModalButton}>
      <Button
        key="move"
        variant="contained"
        onClick={handleMoveItems}
        startIcon={moveItemsButtonIcon()}
        className={classes.menuItemButton}
      >
        {moveItemsButtonText}
      </Button>
    </div>
  );

  const cancelMoveItemsButton = (
    <div key="cancel" className={classes.menuItemModalButton}>
      <Button
        key="cancel"
        variant="outlined"
        onClick={toggleMoveItems}
        className={classes.menuItemCancelButton}
      >
        Cancel
      </Button>
    </div>
  );

  const deleteFolderModal = (
    <div key="delete" className={classes.menuItemModalButton}>
      <CustomModal
        title={`Delete Folder${selectedItems.length > 1 ? 's' : ''}`}
        btnText="Delete"
        className="delete-folder"
        startIcon={<DeleteIcon />}
        body={(handleClose) => (
          <ModalContent
            handleClose={handleClose}
            modalAction="Delete"
            handleConfirm={handleDeleteFolders}
          />
        )}
      />
    </div>
  );

  const shareFolderModal = (
    <div key="share" className={classes.menuItemModalButton}>
      <CustomModal
        btnText="Share"
        title="Share Folder"
        className="share-folder"
        startIcon={<ShareIcon />}
        body={(handleClose) => (
          <ModalContent
            handleClose={handleClose}
            modalAction="Share"
            handleConfirm={handleShareFolder}
            shareSubfolders={shareSubfolders}
            inputFields={[{ id: 'email', label: 'Email' }]}
            arePermissionsCascadedToSubfolders={arePermissionsCascadedToSubfolders}
          />
        )}
      />
    </div>
  );

  const renameFolderModal = (
    <div key="rename" className={classes.menuItemModalButton}>
      <CustomModal
        title="Rename Folder"
        btnText="Rename"
        className="rename-folder"
        startIcon={<EditIcon />}
        body={(handleClose) => (
          <ModalContent
            modalAction="Rename"
            inputFields={[{ id: 'folderName', label: 'New Name' }]}
            handleConfirm={handleRenameFolder}
            handleClose={handleClose}
          />
        )}
      />
    </div>
  );

  const newFolderModal = (
    <div key="create" className={classes.menuItemModalButton}>
      <CustomModal
        title="New Folder"
        btnText="New Folder"
        className="new-folder"
        startIcon={<CreateNewFolderIcon />}
        body={(handleClose) => (
          <ModalContent
            modalAction="Create"
            handleClose={handleClose}
            handleConfirm={handleCreateNewFolder}
            inputFields={[{ id: 'folderName', label: 'Folder Name' }]}
          />
        )}
      />
    </div>
  );

  const assignTrackerButton = (
    <div key="assign-trackers" className={classes.menuItemModalButton}>
      <CustomModal
        btnText="Assign"
        title="Assign Trackers"
        className="assign-trackers"
        startIcon={<LinkIcon />}
        body={(handleClose) => (
          <ModalContent
            modalAction="Assign"
            handleClose={handleClose}
            handleConfirm={handleAssignTracker}
            inputFields={[{ id: 'franchise', label: 'franchise' }]}
          />
        )}
      />
    </div>
  );

  const unassignTrackerButton = (
    <div key="unassign-trackers" className={classes.menuItemModalButton}>
      <CustomModal
        btnText="Unassign"
        title="Unassign Trackers"
        className="unassign-trackers"
        startIcon={<LinkOffIcon />}
        body={(handleClose) => (
          <ModalContent
            modalAction="Unassign"
            handleClose={handleClose}
            handleConfirm={handleUnassignTracker}
            inputFields={[{ id: 'franchise', label: 'franchise' }]}
          />
        )}
      />
    </div>
  );

  const toggleViewButton = (
    <IconButton
      key="view"
      className={classes.toggleViewContainer}
      onClick={toggleView}
      size="large">
      <ListItemIcon className={classes.menuIcon}>
        {mode === 'list'
          ? (<MosaicViewIcon className={classes.toggleViewIcon} />)
          : (<ListViewIcon className={classes.toggleViewIcon} />)}
      </ListItemIcon>
    </IconButton>
  );

  const closeUpperToolBarButton = (
    <IconButton
      key="close"
      onClick={deselectAllItems}
      className={`${classes.menuItemLeft} ${classes.closeUpperToolBarButton}`}
      size="large">
      <ListItemIcon className={classes.closeUpperToolBarButtonIcon}>
        <CloseIcon />
      </ListItemIcon>
    </IconButton>
  );

  const upperToolBar = () => {
    const areAllFoldersDeselected = selectedItems.length === 0;
    const areAllSelectedItemsFolders = selectedItems.every((item) => item.type === 'folder');
    return (
      <div className={classes.menuList}>
        {!currentFolderId
        && areAllSelectedItemsFolders
        && selectedItems.length === 1
        && [
          closeUpperToolBarButton,
          shareFolderModal,
        ]}
        {currentFolderId
          && !isCurrentFolderVirtualRoot
          && areAllFoldersDeselected
          && [backButton, newFolderModal]}
        {currentFolderId
          && !areAllFoldersDeselected
          && [
            closeUpperToolBarButton,
            moveItemsButton,
          ]}
        {currentFolderId
          && areAllSelectedItemsFolders
          && selectedItems.length === 1
          && [
            renameFolderModal,
            shareFolderModal,
          ]}
        {currentFolderId
            && areAllSelectedItemsFolders
            && !areAllFoldersDeselected
            && deleteFolderModal}
        {!currentFolderId
          && rootLevelFolders.length !== 0
          && selectedItems.length === 0
          && toggleViewButton}
        {currentFolderId
          && currentFolder.content.length !== 0
          && selectedItems.length === 0
          && toggleViewButton}
        {currentFolderId
          && selectedItems.length !== 0
          && areAllSelectedItemsTrackers
          && [
            assignTrackerButton,
            unassignTrackerButton,
          ]}
      </div>
    );
  };

  const moveItemsToolbar = () => {
    const isCurrentFolderRootLevelFolder = rootLevelFolders
      .some((folder) => folder.id === currentFolder.id);
    return (
      <div className={classes.menuList}>
        {currentFolderId
        && !isCurrentFolderVirtualRoot
        && !isCurrentFolderRootLevelFolder
        && backButton}
        {selectedItems.length > 0
        && [cancelMoveItemsButton, moveItemsButton]}
      </div>
    );
  };

  return (
    <>
      {isMovingItems
        ? moveItemsToolbar()
        : upperToolBar()}
    </>
  );
}

export default compose(withFoldersData, withFoldersActions)(FolderToolBar);
