import React from 'react';

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

export default ({ title }) => (
  <Toolbar
    className="toolbar"
  >
    <Typography className="title" variant="h6" id="tableTitle" component="div">
      {title}
    </Typography>
  </Toolbar>
);
