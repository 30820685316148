import makeStyles from '@mui/styles/makeStyles';
import {
  white,
  mobile,
  buttonPurple,
  modalBoxShadow,
  buttonBoxShadow,
  onHoverButtonPurple,
} from '../../../Styles/styles';

const newModalStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalButton: {
    //height: '36px',
    //width: '143px',
    margin: '23px 0px 17px auto',
    textTransform: 'none',
    '& span': {
      fontWeight: '600',
      letterSpacing: '0',
      fontFamily: 'Source Sans Pro',
    },
  },
  modalContainer: {
    alignSelf: 'flex-end',
    // Breakpoint for Mobile
    [theme.breakpoints.down(mobile)]: {
      alignSelf: 'center',
    },
  },
  modalHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  modalHeader: {
    height: '32px',
    width: '197px',
    color: '#323547',
    fontSize: '24px',
    fontWeight: '600',
    lineHeight: '32px',
    letterSpacing: '0px',
    fontFamily: 'Source Sans Pro',
  },
  modalCloseButton: {
    marginLeft: 'auto',
  },
  modalContentContainer: {
    width: '70%',
    height: 'auto',
    padding: '24px',
    maxWidth: '500px',
    minWidth: '300px',
    maxHeight: '90vh',
    borderRadius: '8px',
    backgroundColor: white,
    boxShadow: modalBoxShadow,
    '&:focus': {
      outline: 'none',
    },
  },
}));

export default newModalStyles;
