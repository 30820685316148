export const HEADER_ROW_HEIGHT = 48;
export const MENU_DRAWER_WIDTH = 240;

export const SCROLLBAR_WIDTH = 20;

export const SLIDER_LABELS = [
  'Very Low',
  'Low',
  'Normal',
  'High',
  'Very High',
];
