import React from 'react';
import { compose } from 'redux';

import { Visibility } from '@mui/icons-material';
import { Typography, CircularProgress } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import withTrackersData from '../../Hocs/WithData/tracker';
import { cardBoxShadow } from '../../Styles/styles';
import LastViewedTrackerCard from './lastViewedTrackerCard/LastViewedTrackerCard';

const lastViewedTrackerStyles = makeStyles((theme) => ({
  lastViewedTrackerContainer: {
    padding: '15px',
    display: 'flex',
    borderRadius: '8px',
    flexDirection: 'column',
    backgroundColor: 'white',
    boxShadow: cardBoxShadow,
  },
  lastViewedTrackerHeader: {
    display: 'flex',
  },
  lastViewedTrackerHeaderIcon: {
    marginRight: '10px',
  },
  lastViewedTrackerHeaderText: {
    fontWeight: 'bold',
    color: '#333333',
  },
  spinner: {
    margin: '20px auto 76px auto',
  },
  typography: {
    marginTop: '20px',
  },
}));

const LastViewed = ({
  lastViewedTracker,
  isCurrentTrackerLoading,
  areFeaturedTrackersLoading,
}) => {
  const classes = lastViewedTrackerStyles();

  const content = () => {
    if (areFeaturedTrackersLoading || isCurrentTrackerLoading) {
      return <CircularProgress className={classes.spinner} />;
    }
    if (!lastViewedTracker) {
      return (
        <Typography className={classes.typography}>
          Once viewed, your most recently viewed tracker will be featured here.
        </Typography>
      );
    }
    return <LastViewedTrackerCard />;
  };

  return (
    <div className={classes.lastViewedTrackerContainer}>
      <div className={classes.lastViewedTrackerHeader}>
        <Visibility className={classes.lastViewedTrackerHeaderIcon} />
        <Typography className={classes.lastViewedTrackerHeaderText}>
          LAST VIEWED
        </Typography>
      </div>
      {content()}
    </div>
  );
};
export default compose(withTrackersData)(LastViewed);
