import React from 'react';

import {
  Typography,
} from '@mui/material';

import PaperSection from '../../../Components/PaperSection';
import ModeSelector from '../../../Components/ModeNEW/ModeSelector';

const ModeSection = ({
  sx,
}) => {
  return (
    <PaperSection sx={{ ...sx }}>
      <Typography variant="h6" sx={{ mb: 2 }}>Mode</Typography>

      <ModeSelector sx={{ ml: 'auto', mr: 'auto' }}/>
    </PaperSection>
  );
}

export default ModeSection;