import { compose } from 'redux';
import React, { useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';

import trackerDetailsStyles from './style';
import TrackerDetails from './TrackerDetails';
import TrackersDropDownList from './TrackersDropDownList';
import withTrackersData from '../../../Hocs/WithData/tracker';
import withTrackersActions from '../../../Hocs/WithAction/tracker';

const TrackerDetailsManager = ({
  enabledTab,
  getTracker,
  currentTracker,
  getFeaturedTrackers,
  currentTrackerLoading,
  isCurrentTrackerLoading,
  markTrackerAsRecentlyViewed,
}) => {
  const history = useHistory();
  const { trackerId } = useParams();
  const classes = trackerDetailsStyles();

  const selectTracker = (selectedTrackerId) => {
    history.push(`/trackerDetails_old/${selectedTrackerId}`);
  };

  useEffect(() => {
    getFeaturedTrackers();
    if (trackerId) {
      getTracker({ trackerId });
      markTrackerAsRecentlyViewed(trackerId);
    }
  }, [
    trackerId,
    getTracker,
    getFeaturedTrackers,
    currentTrackerLoading,
    markTrackerAsRecentlyViewed,
  ]);

  if (isCurrentTrackerLoading) {
    return (
      <div className={classes.spinnerContainer}>
        <CircularProgress />
      </div>
    );
  }

  if (!currentTracker) {
    return (
      <TrackersDropDownList selectTracker={selectTracker} />
    );
  }

  return (
    <TrackerDetails
      trackerId={trackerId}
      enabledTab={enabledTab}
      selectTracker={selectTracker}
    />
  );
};

export default compose(withTrackersActions, withTrackersData)(TrackerDetailsManager);
