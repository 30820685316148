import { createAction } from 'redux-actions';

const ENTITY = '@@LOCATION_EVENT_NEW';

// Location Data
export const getLatestLocations = createAction(`${ENTITY}/GET_LATEST_LOCATIONS`);
export const getLocations = createAction(`${ENTITY}/GET_LOCATIONS`);
export const setSelectedLocations = createAction(`${ENTITY}/SET_SELECTED_LOCATIONS`);
export const getLocationAddress = createAction(`${ENTITY}/GET_LOCATION_ADDRESS`);

export const storeLatestLocations = createAction(`${ENTITY}/STORE_LATEST_LOCATIONS`);
export const storeLocations = createAction(`${ENTITY}/STORE_LOCATIONS`);
export const setLocationsFilter = createAction(`${ENTITY}/SET_LOCATIONS_FILTER`);
export const setLatestLocationsLoading = createAction(`${ENTITY}/SET_LATEST_LOCATIONS_LOADING`);
export const setLocationsLoading = createAction(`${ENTITY}/SET_LOCATIONS_LOADING`);
export const storeLocationWS = createAction(`${ENTITY}/STORE_LOCATION_WS`);
export const updateLocation = createAction(`${ENTITY}/UPDATE_LOCATION`);

// Map Display
export const setShowTowers = createAction(`${ENTITY}/SET_SHOW_TOWERS`);

// Dummy Data
// TODO: Remove after testing (including all related redux code)
export const createDummyLocation = createAction(`${ENTITY}/CREATE_DUMMY_LOCATION`);
export const setGenerateDummyLocations = createAction(`${ENTITY}/SET_GENERATE_DUMMY_LOCATIONS`);
