import makeStyles from '@mui/styles/makeStyles';
import {
  font,
  mobile,
  fieldColor,
  onInputFocusPurple,
} from '../../../Styles/styles';

const editCustomerModalStyles = makeStyles((theme) => ({
  editCustomerModalOpenButton: {
    transform: 'unset',
    position: 'unset',
  },
  editCustomerModalHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  editCustomerModalCloseButton: {
    transform: 'unset',
    position: 'unset',
    // Breakpoint for Mobile
    [theme.breakpoints.down(mobile)]: {
      padding: 'unset',
    },
  },
  editCustomerModalContainer: {
    '& .MuiDialog-paper': {
      borderRadius: '8px',
      maxWidth: '500px',
      padding: '24px',
      width: '70%',
      // Breakpoint for Mobile
      [theme.breakpoints.down(mobile)]: {
        margin: 'unset',
        width: '80%',
      },
    },

  },
  editCustomerModalContent: {
    padding: 'unset',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: onInputFocusPurple,
    },
  },
  editCustomerModalHeader: {
    padding: 'unset',
    '& h2': {
      fontSize: '24px',
      fontFamily: font,
      fontWeight: '600',
      color: fieldColor,
      letterSpacing: '0',
    },
  },
}));

export default editCustomerModalStyles;
