import React, { useState } from 'react';
import { compose } from 'redux';
import { Button, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import RowComponent from './RowComponent';
import withTrackerAction from '../../../../Hocs/WithAction/tracker';
import { valueColor, buttonTextPurple, font } from '../../../../Styles/styles';

const trackerEquipmentNumberStyles = makeStyles(() => ({
  trackerEquipmentNumber: {
    fontFamily: font,
    fontSize: '14px',
    width: '100%',
    lineHeight: '20px',
    color: valueColor,
    display: 'flex',
    alignItems: 'center',
    '& .MuiInputBase-root': {
      marginBottom: '8px',
    },
  },
  labelWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    color: buttonTextPurple,
    borderRadius: '8px',
    margin: '0px',
    textTransform: 'none',
    width: '100%',
    maxWidth: 'fit-content',
  },
  submitButton: { // button
    textTransform: 'none',
    backgroundColor: buttonTextPurple,
    color: 'white',
    margin: '0px 0px 0px 12px',
    '&:disabled': {
      backgroundColor: '#E4E7EC',
      color: '#929FB4',
    },
    '&:hover': {
      backgroundColor: '#cb3ac6',
      color: 'white',
    },
  },
}));

const TrackerEditableEquipmentNumber = ({
  tracker,
  updateTrackerEquipmentNumber,
  toggleTrackerEquipmentNumberInputField,
}) => {
  const classes = trackerEquipmentNumberStyles();
  const [equipmentNumber, setEquipmentNumber] = useState(tracker.equipmentNumber || '');

  const cancelChange = () => {
    setEquipmentNumber(tracker.equipmentNumber);
    toggleTrackerEquipmentNumberInputField(false);
  };

  const changeHandler = (e) => {
    setEquipmentNumber(e.target.value);
  };
  const submitChange = (e) => {
    e.preventDefault();
    updateTrackerEquipmentNumber({
      equipmentNumber,
      tracker: { id: tracker.simiccid, name: tracker.name },
    });
  };

  const TrackerEquipmentNumberLabel = (
    <>
      <RowComponent field="Equipment Number" />
      <div className={classes.labelWrapper}>
        <span className={classes.trackerEquipmentNumber}>{tracker.equipmentNumber || 'No number has been added yet'}</span>
        <Button
          className={classes.button}
          onClick={() => toggleTrackerEquipmentNumberInputField(true)}
        >
          {(!tracker.equipmentNumber) ? 'Add number' : 'Edit number'}
        </Button>
      </div>
    </>
  );

  const TrackerEquipmentNumberInput = (
    <>
      <RowComponent field="Equipment Number" />
      <TextField
        field="Equipment Number"
        value={equipmentNumber}
        onChange={changeHandler}
        fullWidth
        variant="outlined"
        size="small"
        className={classes.trackerEquipmentNumber}
        InputProps={{ className: classes.trackerEquipmentNumber }}
      />
      <div className={classes.buttonWrapper}>
        <Button
          className={classes.button}
          onClick={() => cancelChange()}
        >
          Cancel
        </Button>
        <Button type="submit" className={classes.submitButton} disabled={tracker.equipmentNumber === equipmentNumber} onClick={submitChange}>
          {(tracker.isTrackerDetailsUpdating) ? 'Saving' : 'Save'}
        </Button>
      </div>
    </>
  );
  return (
    <>
      {tracker.showTrackerEquipmentNumberInputField ? TrackerEquipmentNumberInput : TrackerEquipmentNumberLabel}
    </>
  );
};

export default compose(withTrackerAction)(TrackerEditableEquipmentNumber);
