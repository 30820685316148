import React from 'react';

import {
  Box,
  Typography,
} from '@mui/material';

import {
  Construction as ConstructionIcon,
} from '@mui/icons-material';

const PlaceholderPage = ({
  sx,
}) => (
  <Box sx={{ ...sx, display: 'flex', flexDirection: 'row' }}>
    <ConstructionIcon sx={{ m: 'auto 0', mr: 2, fontSize: 40 }}/>
    <Typography variant="h3">Under Construction</Typography>
    <ConstructionIcon sx={{ m: 'auto 0', ml: 2, fontSize: 40 }}/>
  </Box>
)

export default PlaceholderPage;