import React from 'react';
import { compose } from 'redux';

import {
  Box,
  Button,
  Divider,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';

import {
  Add as AddIcon,
  Close as CancelIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Visibility as VisibilityOnIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';

import PaperSection from '../../../../Components/PaperSection';
import VirtualisedTable from '../../../../Components/VirtualisedTable/VirtualisedTable';
import ConfinedTypography from '../../../../Components/Typographies/ConfinedTypography';

import { SCROLLBAR_WIDTH } from '../../../../../Utils/Defines';
import { useWindowDimensions, getHalfPaperSectionWidth } from '../../../../../Utils/ScreenSize';
import { ZONE_TYPES, ZONE_TYPES_ARRAY } from './types';
import { SHAPES, SHAPES_ARRAY } from './shapes';

import withTrackerData from '../../../../../redux/WithData/tracker';
import withTrackerActions from '../../../../../redux/WithActions/tracker';

const DEFAULT_NEW_ZONE = {
  forbidden: ZONE_TYPES_ARRAY[0].value,
  shape: SHAPES_ARRAY[0].id,
}

const COL_TYPE = 100;

const ZoneSection = ({
  sx,

  tracker,
  zones,
  highlightedZone,
  showZones,
  newZone,
  setZones,
  setHighlightedZone,
  setShowZones,
  setNewZone,
}) => {
  const [newZoneName, setNewZoneName] = React.useState('');

  const ZoneRow = ({
    index,
    zone,
    disabled = false,
  }) => {
    return (
      <Box onClick={() => handleClickZoneRow(zone)} sx={theme => ({
        backgroundColor: (showZones && zone === highlightedZone) ? theme.palette.action.selected : 'transparent',
        pl: 3, pr: 2,
        display: 'flex',
        flexDirection: 'row',
        '.MuiIconButton-root': {
          visibility: 'hidden',
        },
        '&:hover': {
          backgroundColor: (showZones && zone === highlightedZone) ? theme.palette.action.focus : theme.palette.action.hover,
          '.MuiIconButton-root': {
            visibility: 'visible',
          },
        },
      })}>
        <ConfinedTypography disabled={disabled} sx={{ m: 'auto 0', width: `${COL_TYPE}px` }}>{zone.forbidden ? 'Forbidden' : 'Permitted'}</ConfinedTypography>
        <ConfinedTypography disabled={disabled} sx={{ m: 'auto 0' }}>{zone.name || 'Unnamed Zone'}</ConfinedTypography>
        <IconButton onClick={(e) => handleClickDeleteZone(e, zone, index)} sx={{ ml: 'auto' }}>
          <DeleteIcon/>
        </IconButton>
      </Box>
    )
  }
  
  function handleClickAddNewZone() {
    setNewZone({ ...DEFAULT_NEW_ZONE });
  }

  function handleClickSaveNewZone() {
    // TODO: add 'newZone' to 'zones' w/ name & clear 'newZone'
  }

  function handleClickCancelNewZone() {
    setNewZone(null);
    setNewZoneName('');
  }

  function handleChangeNewZoneType(_, isForbidden) {
    if (newZone && newZone.forbidden !== isForbidden) {
      setNewZone({
        ...newZone,
        forbidden: isForbidden,
      });
    }
  }

  function handleChangeNewZoneShape(e, value) {
    console.log(e)
    console.log(value)
    if (newZone && newZone.shape !== value) {
      setNewZone({
        ...newZone,
        shape: value,
      });
    }
  }

  function handleChangeNewZoneName(e) {
    if (newZoneName !== e.target.value) {
      setNewZoneName(e.target.value);
    }
  }

  function handleClickToggleVisibility() {
    setShowZones(!showZones);
  }

  function handleClickZoneRow(zone) {
    if (!showZones) {
      // No benefit to selecting zones while hidden
      return;
    }
    
    if (zone === highlightedZone) {
      setHighlightedZone(null);
    }
    else {
      setHighlightedZone(zone);
    }
  }

  function handleClickDeleteZone(e, zone, index) {
    // If the zone is highlighted, unhighlight it first
    if (zone === highlightedZone) {
      setHighlightedZone(null);
    }

    // Then remove zone at from list
    let editedZones = [ ...zones ];
    editedZones.splice(index, 1);
    setZones(editedZones);

    e.stopPropagation();
  }

  return (
    <PaperSection sx={{ ...sx, p: 0, flexDirection: 'row', minHeight: '265px' }}>
      <Box sx={{ width: '50%', p: 3, display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h6">Zones</Typography>

        <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', mr: 2 }}>
            <Typography variant="body2" sx={{ mb: 1 }}>Type</Typography>
            <ToggleButtonGroup
              exclusive
              disabled={!newZone}
              size="small"
              value={(typeof newZone?.forbidden == 'boolean') ? newZone.forbidden : null}
              onChange={handleChangeNewZoneType}
            >
              {ZONE_TYPES_ARRAY.map((type) => (
                <ToggleButton key={type.id} value={type.value} title={type.description}>{type.name}</ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="body2" sx={{ mb: 1 }}>Shape</Typography>
            <ToggleButtonGroup
              exclusive
              disabled={!newZone}
              size="small"
              value={newZone?.shape || null}
              onChange={handleChangeNewZoneShape}
            >
              {SHAPES_ARRAY.map((shape) => (
                <ToggleButton key={shape.id} value={shape.id}>{shape.icon}</ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>
        </Box>
        
        <TextField
          disabled={!newZone}
          label='Name'
          value={newZone?.name || ''}
          onChange={handleChangeNewZoneName}
          sx={{ mt: 2, mb: 2 }}
        />

        {newZone ? (
          <Box sx={{ display: 'flex', flexDirection: 'row', ml: 'auto' }}>
            <Button startIcon={<CancelIcon/>} onClick={handleClickCancelNewZone} sx={{ mr: 1 }}>Cancel</Button>
            <Button variant="contained" startIcon={<SaveIcon/>} onClick={handleClickSaveNewZone}>Save</Button>
          </Box>
        ) : (
          <Button variant="contained" startIcon={<AddIcon/>} onClick={handleClickAddNewZone} sx={{ ml: 'auto' }}>Add</Button>
        )}
      </Box>

      <Divider orientation="vertical" flexItem/>

      <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', ml: 3, mr: 2, mt: 1, mb: 1 }}>
          <Typography sx={{ m: 'auto 0', width: `${COL_TYPE}px` }}>Type</Typography>
          <Typography sx={{ m: 'auto 0' }}>Name</Typography>

          <IconButton onClick={handleClickToggleVisibility} sx={{ ml: 'auto' }}>
            {showZones ? <VisibilityOnIcon/> : <VisibilityOffIcon/>}
          </IconButton>
        </Box>

        <Divider/>

        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
          {zones.map((zone, index) => (
            <ZoneRow key={zone.id} index={index} zone={zone} disabled={!showZones}/>
          ))}
        </Box>
      </Box>

      {/* <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
        <VirtualisedTable
          hover
          rowHeight={40}
          // rowRenderer
          columns={columns}
          rowCount={zones.length}
          rowGetter={({ index }) => zones[index]}
        />
      </Box> */}
    </PaperSection>
  );
}

export default compose(withTrackerData, withTrackerActions)(ZoneSection);