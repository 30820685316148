import makeStyles from '@mui/styles/makeStyles';

import { mobile } from '../../../../../Styles/styles';

const marginBetweenBox = '20px';
const tabColumnsStyle = makeStyles((theme) => ({
  boxesContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: `-${marginBetweenBox}`,
    flexWrap: 'nowrap',
    justifyContent: 'space-evenly',
    // Breakpoint for Tablet
    [theme.breakpoints.down(mobile)]: {
      flexWrap: 'wrap',
    },
    [theme.breakpoints.down(mobile)]: {
      flexDirection: 'column',
      marginLeft: 'unset',
      alignItems: 'center',
    },
  },
  box: {
    width: '100%',
    display: 'flex',
    padding: '30px',
    minHeight: '116px',
    marginLeft: `${marginBetweenBox}`,
    marginBottom: `${marginBetweenBox}`,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: '#F0F0F0',
    // Breakpoint for Tablet
    [theme.breakpoints.down(mobile)]: {
      width: 'calc(100% - 30px)',
      marginLeft: '0px',
      marginBottom: '20px',
      padding: '15px',
      justifyContent: 'center',
    },

  },
  lastBox: {
    marginRight: '0px',
  },
  text: {
    fontWeight: '400',
    fontSize: 'x-large',
    textTransform: 'capitalize',
  },
  timezoneWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  timezoneSpan: {
    fontSize: '12px',
  },
  speed: {
    textTransform: 'unset',
  },
  placeholder: {
    margin: 'auto',
    fontWeight: '400px',
    fontSize: 'x-large',
    textAlign: 'center',
  },
}));

export default tabColumnsStyle;
