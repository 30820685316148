import React from 'react';

import {
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

import roles from '../../../Assets/roles.json';

const DefaultRole = ({
  role,
  values,
  handleChange,
}) => {
  let roleIdx = 0;
  
  // Find index of current users role
  for (roleIdx = 0; roleIdx < roles.length; roleIdx++) {
    if (role.localeCompare(roles[roleIdx, undefined, { sensitivity: 'accent' }])) {
      break;
    }
  }
  
  return (
    <>
      <Typography variant="h6">
        Default Role
      </Typography>
      <Typography variant="body2">
        Choose a role for the new account:
      </Typography>
      <TextField
        select
        name="role"
        value={values.role}
        onChange={handleChange}
        sx={{ textTransform: 'capitalize' }}
      >
        {roles.slice(roleIdx).map((r, index) => (
          <MenuItem key={r} value={r} sx={{ textTransform: 'capitalize' }}>{r.toLocaleLowerCase()}</MenuItem>
        ))}
      </TextField>
    </>
  );
};

export default DefaultRole;