import React, { useState } from 'react';
import {
  FormControlLabel, Checkbox,
} from '@mui/material';

const CheckField = ({
  name = '',
  status = true,
  handleCheckBoxChange = () => {},
}) => {
  const [checkbox, setCheckbox] = useState({
    [name]: status,
  });
  const handleCheckbox = (event) => {
    setCheckbox({ ...checkbox, [event.target.name]: event.target.checked });
    handleCheckBoxChange(event.target.checked);
  };

  return (
    <FormControlLabel
      control={(
        <Checkbox
          checked={checkbox[name]}
          onChange={handleCheckbox}
          name={name}
          color="default"
        />
    )}
      label={name}
    />
  );
};
export default CheckField;
