import { sortArrayAlphabetically } from "./Utils";

export const TAG_TYPES = {
  subscription: 'subscription',
  command: 'command',
  operator: 'operator',
  server: 'server',
}

export const TAGS = {
  noSub: {
    id: 'noSub',
    type: TAG_TYPES.subscription,
    label: 'No Sub',
    description: 'No customer subsciption',
  },
  standard: {
    id: 'standard',
    type: TAG_TYPES.subscription,
    label: 'Standard',
    description: 'Standard subscription',
  },
  premium: {
    id: 'premium',
    type: TAG_TYPES.subscription,
    label: 'Premium',
    description: 'Premium subscription',
  },


  geolockGSM: {
    id: 'geolockGSM',
    type: TAG_TYPES.command,
    label: 'Geolock-GSM',
    description: 'Geolock to local cell towers',
  },
  geolockWIFI: {
    id: 'geolockWIFI',
    type: TAG_TYPES.command,
    label: 'Geolock-WiFi',
    description: 'Geolock to local WiFi networks',
  },
  hibernate: {
    id: 'hibernate',
    type: TAG_TYPES.command,
    label: 'Hibernate',
    description: 'Hibernate until ???',
  },
  ignoreId: {
    id: 'ignoreId',
    type: TAG_TYPES.command,
    label: 'Ignore ID',
    description: 'De-activate driver ID sensor',
  },
  ignorePowerLoss: {
    id: 'ignorePowerLoss',
    type: TAG_TYPES.command,
    label: 'Ignore Power Loss',
    description: '[Wired] De-activate power monitoring',
  },
  ignoreTamper: {
    id: 'ignoreTamper',
    type: TAG_TYPES.command,
    label: 'Ignore Tamper',
    description: 'De-activate tamper sensor',
  },
  lowPower: {
    id: 'lowPower',
    type: TAG_TYPES.command,
    label: 'Low Power',
    description: '[Wired] Switch to low power settings',
  },
  move: {
    id: 'move',
    type: TAG_TYPES.command,
    label: 'Move',
    description: 'Activate motion sensor',
  },
  watch: {
    id: 'watch',
    type: TAG_TYPES.command,
    label: 'Watch',
    description: 'If alerted, go straight to Alarm mode',
  },
  wired: {
    id: 'wired',
    type: TAG_TYPES.command,
    label: 'Wired',
    description: 'Unit is wired to external power source',
  },


  checkPing: {
    id: 'checkPing',
    type: TAG_TYPES.operator,
    label: 'Check Ping',
    description: 'Unit has been pinged - Response must be acknowledged by operator',
  },
  noConnection: {
    id: 'noConnection',
    type: TAG_TYPES.operator,
    label: 'No Connection',
    description: 'Disconnected unit - SIM kept live for 12 months',
  },
  noNetwork: {
    id: 'noNetwork',
    type: TAG_TYPES.operator,
    label: 'No Network',
    description: 'No network at this units normal storage location',
  },
  notification: {
    id: 'notification',
    type: TAG_TYPES.operator,
    label: 'Notification',
    description: 'Unit has unread notification(s)',
  },
  returning: {
    id: 'returning',
    type: TAG_TYPES.operator,
    label: 'Returning',
    description: 'Unit is being returned',
  },
  simOff: {
    id: 'simOff',
    type: TAG_TYPES.operator,
    label: 'SIM Off',
    description: 'SIM has been deactivated',
  },
  sold: {
    id: 'sold',
    type: TAG_TYPES.operator,
    label: 'Sold',
    description: 'Unit has been sold - SIM still active',
  },
  special: {
    id: 'special',
    type: TAG_TYPES.operator,
    label: 'Special',
    description: '???',
  },
  unpaid: {
    id: 'unpaid',
    type: TAG_TYPES.operator,
    label: 'Unpaid',
    description: 'Unpaid invoice',
  },


  customSettings: {
    id: 'customSettings',
    type: TAG_TYPES.server,
    label: 'Custom Settings',
    description: 'Unit is using non-standard settings',
    important: true,
  },
  driverId: {
    id: 'driverId',
    type: TAG_TYPES.server,
    label: 'Driver ID',
    description: 'Unit has been assigned at least one driver ID',
  },
  lb1: {
    id: 'lb1',
    type: TAG_TYPES.server,
    label: 'LB-1',
    description: 'Low battery - 1st threshold reached (???%)',
  },
  lb2: {
    id: 'lb2',
    type: TAG_TYPES.server,
    label: 'LB-2',
    description: 'Low battery - 2nd threshold reached (???%)',
  },
  lb3: {
    id: 'lb3',
    type: TAG_TYPES.server,
    label: 'LB-3',
    description: 'Low battery - 3rd threshold reached (???%)',
  },
};

export const TAGS_SUBSCRIPTION = [
  TAGS.noSub,
  TAGS.standard,
  TAGS.premium,
];

export const TAGS_COMMAND = [
  TAGS.geolockGSM,
  TAGS.geolockWIFI,
  TAGS.hibernate,
  TAGS.ignoreId,
  TAGS.ignorePowerLoss,
  TAGS.ignoreTamper,
  TAGS.lowPower,
  TAGS.move,
  TAGS.watch,
  TAGS.wired,
];

export const TAGS_OPERATOR = [
  TAGS.checkPing,
  TAGS.noConnection,
  TAGS.noNetwork,
  TAGS.notification,
  TAGS.returning,
  TAGS.simOff,
  TAGS.sold,
  TAGS.special,
  TAGS.unpaid,
];

export const TAGS_SERVER = [
  TAGS.customSettings,
  TAGS.driverId,
  TAGS.lb1,
  TAGS.lb2,
  TAGS.lb3,
];

export const TAGS_ARRAY = sortArrayAlphabetically([
  ...TAGS_SUBSCRIPTION,
  ...TAGS_COMMAND,
  ...TAGS_OPERATOR,
  ...TAGS_SERVER,
],
'label');