import React from 'react';
import { compose } from 'redux';

import {
  Box,
  Divider,
} from '@mui/material';

import ModeButton, { LARGE_HEIGHT, SMALL_HEIGHT } from './ModeButton';
import HandleButton from './HandleButton';

import { STATES } from '../../../Utils/States';
import { MODES } from '../../../Utils/Modes';

import withTrackerData from '../../../redux/WithData/tracker';
import withTrackerActions from '../../../redux/WithActions/tracker';

const ModeSelector = ({
  small = false,
  horizontal = false,
  sx,

  tracker,
  setTrackerSettings,
  setTrackerState,
}) => {
  const [activeMode, setActiveMode] = React.useState('');
  const [pendingMode, setPendingMode] = React.useState('');
  const [handled, setHandled] = React.useState(false);

  const SPACING = (small ? SMALL_HEIGHT : LARGE_HEIGHT) / 2;
  const rowSx = { display: 'flex', flexDirection: 'row' };

  React.useEffect(() => {
    if (tracker?.currentSettings) {
      const newActiveMode   = tracker.currentSettings.mode;
      const newPendingMode  = (tracker.currentSettings.mode !== tracker.newSettings.mode) ? tracker.newSettings.mode : '';
      const newHandled      = (newActiveMode === MODES.ALARM.id && tracker.state.mnemonic === STATES.ALARM_ACK.id) || (newActiveMode === MODES.WARNING.id && tracker.state.mnemonic === STATES.WARNING_ACK.id);

      if (activeMode !== newActiveMode) {
        setActiveMode(newActiveMode);
      }

      if (pendingMode !== newPendingMode) { 
        setPendingMode(newPendingMode);
      }

      if (handled !== newHandled) { 
        setHandled(newHandled);
      }
    }
  }, [
    tracker,
  ]);

  function handleClickMode(mode) {
    const newMode = (mode.id !== pendingMode) ? mode.id : tracker.currentSettings.mode;

    setTrackerSettings({ trackerId: tracker.simiccid, hideNotification: true, newSettings: { mode: newMode }});
  }

  function handleClickHandle() {
    // Alarm mode - toggle 'ACK' state on/off
    if (tracker.currentSettings.mode === MODES.ALARM.id) {
      setTrackerState(handled ? STATES.ALARM.id : STATES.ALARM_ACK.id);
    }
    // Warning mode - toggle 'ACK' state on/off
    else if (tracker.currentSettings.mode === MODES.WARNING.id) {
      setTrackerState(handled ? STATES.WARNING.id : STATES.WARNING_ACK.id);
    }
  }

  function getModeButton({ mode, left, right, widthMult, heightMult }) {
    return (
      <ModeButton mode={mode} small={small} left={left} right={right} widthMult={widthMult} heightMult={heightMult} active={activeMode === mode.id} pending={pendingMode === mode.id} onClick={handleClickMode}/>
    )
  }

  return !horizontal ? (
    <Box sx={{ ...sx, width: 'max-content' }}>
      <Divider/>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box>
          {getModeButton({ mode: MODES.ALARM, left: true })}
          <Divider/>
          {getModeButton({ mode: MODES.WARNING, left: true })}
        </Box>
        <Divider orientation="vertical" flexItem/>
        <HandleButton mode={activeMode} active={handled} small={small} onClick={handleClickHandle}/>
      </Box>
      <Divider/>

      <Divider sx={{ mt: `${SPACING}px` }}/>
      <Box sx={rowSx}>
        {getModeButton({ mode: MODES.TEST, left: true })}
        <Divider orientation="vertical" flexItem/>
        {getModeButton({ mode: MODES.STOLEN, right: true })}
      </Box>
      <Divider/>
      <Box sx={rowSx}>
        {getModeButton({ mode: MODES.FACTORY, left: true })}
        <Divider orientation="vertical" flexItem/>
        {getModeButton({ mode: MODES.STOCK, right: true })}
      </Box>
      <Divider/>

      <Divider sx={{ mt: `${SPACING}px` }}/>
      {getModeButton({ mode: MODES.STANDARD, left: true, right: true, widthMult: 2 })}
      <Divider/>
    </Box>
  ) : (
    <Box sx={{ ...sx, display: 'flex', flexDirection: 'row', width: 'max-content' }}>
      <Box>
        <Divider/>
        {getModeButton({ mode: MODES.STANDARD, left: true, right: true, heightMult: 2 })}
        <Divider/>
      </Box>

      <Box sx={{ ml: `${SPACING}px` }}>
        <Divider/>
        <Box sx={rowSx}>
          {getModeButton({ mode: MODES.TEST, left: true })}
          <Divider orientation="vertical" flexItem/>
          {getModeButton({ mode: MODES.STOLEN, right: true })}
        </Box>
        <Divider/>
        <Box sx={rowSx}>
          {getModeButton({ mode: MODES.FACTORY, left: true })}
          <Divider orientation="vertical" flexItem/>
          {getModeButton({ mode: MODES.STOCK, right: true })}
        </Box>
        <Divider/>
      </Box>

      <Box sx={{ ml: `${SPACING}px` }}>
        <Divider/>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box>
            {getModeButton({ mode: MODES.ALARM, left: true })}
            <Divider/>
            {getModeButton({ mode: MODES.WARNING, left: true })}
          </Box>
          <Divider orientation="vertical" flexItem/>
          <HandleButton mode={activeMode} active={handled} small={small} onClick={handleClickHandle}/>
        </Box>
        <Divider/>
      </Box>
    </Box>
  );
}

export default compose(withTrackerData, withTrackerActions)(ModeSelector);