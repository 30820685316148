import carSVG from '../Assets/SVGs/Assets/car.svg';
import motorccycleSVG from '../Assets/SVGs/Assets/motorcycle.svg';
import plantSVG from '../Assets/SVGs/Assets/plant.svg';
import tractorSVG from '../Assets/SVGs/Assets/tractor.svg';
import caravanSVG from '../Assets/SVGs/Assets/caravan.svg';
import motorhomeSVG from '../Assets/SVGs/Assets/motorhome.svg';
import truckSVG from '../Assets/SVGs/Assets/truck.svg';
import trailerSVG from '../Assets/SVGs/Assets/trailer.svg';
import ebikeSVG from '../Assets/SVGs/Assets/ebike.svg';
import jetskiSVG from '../Assets/SVGs/Assets/jetski.svg';
import outboardSVG from '../Assets/SVGs/Assets/outboard.svg';
import boatSVG from '../Assets/SVGs/Assets/boat.svg';

export const ASSET_TYPES = {
  CAR: {
    id: 'CAR',
    label: 'Car',
    defaultImage: carSVG,
  },
  MOTORCYCLE: {
    id: 'MOTORCYCLE',
    label: 'Motorcycle',
    defaultImage: motorccycleSVG,
  },
  PLANT: {
    id: 'PLANT',
    label: 'Plant Machinery',
    defaultImage: plantSVG,
  },
  TRACTOR: {
    id: 'TRACTOR',
    label: 'Farm Machinery',
    defaultImage: tractorSVG,
  },
  CARAVAN: {
    id: 'CARAVAN',
    label: 'Caravan',
    defaultImage: caravanSVG,
  },
  MOTORHOME: {
    id: 'MOTORHOME',
    label: 'Motorhome',
    defaultImage: motorhomeSVG,
  },
  TRUCK: {
    id: 'TRUCK',
    label: 'Truck',
    defaultImage: truckSVG,
  },
  TRAILER: {
    id: 'TRAILER',
    label: 'Trailer',
    defaultImage: trailerSVG,
  },
  EBIKE: {
    id: 'EBIKE',
    label: 'E-Bike',
    defaultImage: ebikeSVG,
  },
  JETSKI: {
    id: 'JETSKI',
    label: 'Jet Ski',
    defaultImage: jetskiSVG,
  },
  OUTBOARD: {
    id: 'OUTBOARD',
    label: 'Outboard Motor',
    defaultImage: outboardSVG,
  },
  BOAT: {
    id: 'BOAT',
    label: 'Boat',
    defaultImage: boatSVG,
  },
  
  // VAN: {
  //   id: 'VAN',
  //   label: 'Van',
  //   defaultImage: undefined,
  // },
  // ATV: {
  //   id: 'ATV',
  //   label: 'ATV',
  //   defaultImage: undefined,
  // },
  // PORTABLE_TOOLS: {
  //   id: 'PORTABLE_TOOLS',
  //   label: 'Portable Tools',
  //   defaultImage: undefined,
  // },
  // BICYCLE: {
  //   id: 'BICYCLE',
  //   label: 'Bicycle',
  //   defaultImage: undefined,
  // },
  // CATERING_TRAILER: {
  //   id: 'CATERING_TRAILER',
  //   label: 'Catering Trailer',
  //   defaultImage: undefined,
  // },
  // HORSE_BOX: {
  //   id: 'HORSE_BOX',
  //   label: 'Horse Box',
  //   defaultImage: undefined,
  // },
  // TOOLS: {
  //   id: 'TOOLS',
  //   label: 'Tools',
  //   defaultImage: undefined,
  // },
};

export const ASSET_TYPES_ARRAY = [
  ASSET_TYPES.CAR,
  ASSET_TYPES.MOTORCYCLE,
  ASSET_TYPES.PLANT,
  ASSET_TYPES.TRACTOR,
  ASSET_TYPES.CARAVAN,
  ASSET_TYPES.MOTORHOME,
  ASSET_TYPES.TRUCK,
  ASSET_TYPES.TRAILER,
  ASSET_TYPES.EBIKE,
  ASSET_TYPES.JETSKI,
  ASSET_TYPES.OUTBOARD,
  ASSET_TYPES.BOAT,

  // ASSET_TYPES.VAN,
  // ASSET_TYPES.ATV,
  // ASSET_TYPES.PORTABLE_TOOLS,
  // ASSET_TYPES.BICYCLE,
  // ASSET_TYPES.CATERING_TRAILER,
  // ASSET_TYPES.HORSE_BOX,
  // ASSET_TYPES.TOOLS,
];