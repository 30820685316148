import { connect } from 'react-redux';

const mapStateToProps = ({
  TrackerData: {
    trackers,
    currentTracker,
    favoriteTrackers,
    lastViewedTracker,
    trackersListFilter,
    areTrackersLoading,
    trackerLocationsEvent,
    totalNumberOfTrackers,
    recentlyViewedTrackers,
    isCurrentTrackerLoading,
    isTrackerImageUploading,
    areTrackerSettingsLoading,
    areFeaturedTrackersLoading,
    areNewTrackerSettingsUpdating,
    areNewTrackerRawSettingsUpdating,

    isSendingPeriodicDummyLocations,
  },
}) => ({
  trackers,
  currentTracker,
  favoriteTrackers,
  lastViewedTracker,
  trackersListFilter,
  areTrackersLoading,
  trackerLocationsEvent,
  totalNumberOfTrackers,
  recentlyViewedTrackers,
  isCurrentTrackerLoading,
  isTrackerImageUploading,
  areTrackerSettingsLoading,
  areFeaturedTrackersLoading,
  areNewTrackerSettingsUpdating,
  areNewTrackerRawSettingsUpdating,

  isSendingPeriodicDummyLocations,
});

export default (WrappedComponent) => connect(mapStateToProps, null)(WrappedComponent);
