import makeStyles from '@mui/styles/makeStyles';

import {
  font,
  mobile,
  fieldColor,
  textPurple,
  buttonPurple,
  onInputHoverPurple,
  onInputFocusPurple,
  onHoverButtonPurple,
} from '../../../../../../Styles/styles';

const addNewScheduleModalStyles = makeStyles((theme) => ({
  addNewScheduleModalButton: {
    margin: '8px 0px 0px 0px',
    textTransform: 'none',
    borderRadius: '8px',
    padding: '8px 16px',
    color: textPurple,
  },
  addNewScheduleModalHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  addNewScheduleModalCloseButton: {
    transform: 'unset',
    position: 'unset',
    // Breakpoint for Mobile
    [theme.breakpoints.down(mobile)]: {
      padding: 'unset',
    },
  },
  addNewScheduleModalContainer: {
    '& .MuiDialog-paper': {
      borderRadius: '8px',
      maxWidth: '400px',
      padding: '24px',
      width: '80%',
      // Breakpoint for Mobile
      [theme.breakpoints.down(mobile)]: {
        margin: 'unset',
      },
    },

  },
  addNewScheduleModalContent: {
    flexDirection: 'column',
    paddingRight: '12px',
    padding: 'unset',
    display: 'flex',
    '& .MuiFormLabel-root.Mui-focused': {
      color: onInputFocusPurple,
    },
  },
  addNewScheduleModalHeader: {
    padding: 'unset',
    '& h2': {
      fontSize: '24px',
      fontFamily: font,
      fontWeight: '600',
      color: fieldColor,
      letterSpacing: '0',
    },
  },
  addNewScheduleModalSubheader: {
    margin: 'unset',
    fontSize: '14px',
    fontFamily: font,
    lineHeight: '20px',
    letterSpacing: '0',
    padding: '12px 0px 24px 0px',
  },
  addNewScheduleModalInputLabel: {
    fontSize: '14px',
    fontFamily: font,
    fontWeight: '600',
    color: fieldColor,
    lineHeight: '20px',
    letterSpacing: '0',
    paddingBottom: '6px',
    '& span': {
      fontStyle: 'italic',
    },
  },
  addNewScheduleModalInputField: {
    paddingBottom: '24px',
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${onInputHoverPurple}`,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: `1px solid ${onInputFocusPurple}`,
      },
    },
    '& div': {
      '& input': {
        '&::placeholder': {
          fontStyle: 'italic',
        },
      },
    },
  },
  addNewScheduleModalCancelButton: {
    margin: '0px',
    width: '100%',
    borderRadius: '8px',
    color: buttonPurple,
    textTransform: 'none',
    maxWidth: 'fit-content',
  },
  addNewScheduleModalSaveButton: {
    color: 'white',
    padding: '6px 15px',
    borderRadius: '8px',
    textTransform: 'none',
    margin: '0px 0px 0px 12px',
    backgroundColor: buttonPurple,
    '&:disabled': {
      backgroundColor: '#E4E7EC',
      color: '#929FB4',
    },
    '&:hover': {
      color: 'white',
      backgroundColor: onHoverButtonPurple,
    },
  },
}));

export default addNewScheduleModalStyles;
