import { compose } from 'redux';
import React, { useEffect, useState } from 'react';

import FolderIcon from '@mui/icons-material/Folder';
import AccessFolderIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Select,
  Button,
  Divider,
  MenuItem,
  TextField,
  Typography,
  IconButton,
  InputLabel,
  FormControl,
} from '@mui/material';

import addNewFranchiseModalContentStyles from './style';
import withFoldersData from '../../../Hocs/WithData/folder';
import withFoldersActions from '../../../Hocs/WithAction/folder';
import withFranchiseData from '../../../Hocs/WithData/franchise';
import withFranchiseActions from '../../../Hocs/WithAction/franchise';

const AddNewFranchiseModalContent = ({
  handleClose,
  franchiseFolder,
  franchiseSecret,
  rootLevelFolders,
  getFranchiseFolder,
  createNewFranchise,
  getRootLevelFolders,
  loadFranchiseFolder,
  isCreatingNewFranchise,
  isFranchiseFolderLoading,
  createNewFranchiseFolder,
  cancelCreatingNewFranchise,
  generateNewFranchiseSecret,
  isCreatingNewFranchiseFolder,
  isGeneratingNewFranchiseSecret,
}) => {
  const roles = ['Root', 'Admin', 'User'];
  const classes = addNewFranchiseModalContentStyles();
  const [folders, setFolders] = useState([]);
  const [isGoingBack, setIsGoingBack] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [showFolderIcons, setShowFolderIcons] = useState(false);
  const [franchiseDetails, setFranchiseDetails] = useState({
    defaultRole: 0,
    homeFolder: 0,
    name: '',
  });

  useEffect(() => {
    getRootLevelFolders();
  }, [getRootLevelFolders]);

  useEffect(() => {
    generateNewFranchiseSecret();
  }, [generateNewFranchiseSecret]);

  useEffect(() => {
    const virtualRoot = {
      id: 'virtual-root',
      name: 'Virtual Root',
      parentFolder: '',
      subfolders: rootLevelFolders,
    };
    loadFranchiseFolder({ franchiseFolder: virtualRoot });
    setFolders(rootLevelFolders);
  }, [loadFranchiseFolder, rootLevelFolders]);

  useEffect(() => {
    // Avoid rendering an empty home folder dropdown
    if (franchiseFolder.subfolders.length !== 0) {
      setFolders(franchiseFolder.subfolders);
      setIsGoingBack(false);
      // Reset franchiseFolder to its parentFolder
      // in case franchiseFolder has no subfolders
    } else if (franchiseFolder.parentFolder) {
      getFranchiseFolder({ currentFolderId: franchiseFolder.parentFolder.id });
    }
  }, [franchiseFolder, getFranchiseFolder, rootLevelFolders.subfolders]);

  const handleChangeFranchiseName = ({ target: { value } }) => (
    setFranchiseDetails({
      ...franchiseDetails,
      name: value,
    })
  );

  const handleGenerateSecretCode = () => {
    generateNewFranchiseSecret();
  };

  const handleChangeDefaultRole = ({ target: { value } }) => (
    setFranchiseDetails({
      ...franchiseDetails,
      defaultRole: value,
    })
  );

  const toggleOpenHomeFolderDropdown = () => {
    setShowFolderIcons(!showFolderIcons);
  };

  const handleChangeHomeFolder = ({ target: { value } }) => (
    setFranchiseDetails({
      ...franchiseDetails,
      homeFolder: value,
    })
  );

  const handleAccessFolder = (e, folderId) => {
    e.stopPropagation();
    setFranchiseDetails({
      ...franchiseDetails,
      homeFolder: 0,
    });
    getFranchiseFolder({ currentFolderId: folderId });
  };

  const handleGoBack = () => {
    setIsGoingBack(true);
    setFranchiseDetails({
      ...franchiseDetails,
      homeFolder: 0,
    });
    const isRootLevelFolder = rootLevelFolders
      .some((rootLevelFolder) => rootLevelFolder.id === franchiseFolder.id);
    if (isRootLevelFolder) {
      const virtualRoot = {
        id: 'virtual-root',
        name: 'Virtual Root',
        parentFolder: '',
        subfolders: rootLevelFolders,
      };
      loadFranchiseFolder({ franchiseFolder: virtualRoot });
      setFolders(rootLevelFolders);
    } else {
      getFranchiseFolder({ currentFolderId: franchiseFolder.parentFolder.id });
    }
  };

  const handleChangeNewFolderName = (e) => {
    setNewFolderName(e.target.value);
  };

  const handleCreateNewFolder = () => {
    const parentFolder = franchiseFolder.subfolders[franchiseDetails.homeFolder];
    createNewFranchiseFolder({
      folderName: newFolderName,
      parentFolder,
    });
    setNewFolderName('');
  };

  const handleSave = () => {
    const newFranchiseDetails = {
      name: franchiseDetails.name,
      secret: franchiseSecret,
      defaultRole: roles[franchiseDetails.defaultRole].toUpperCase(),
      homeFolder: {
        uuid: franchiseFolder.subfolders[franchiseDetails.homeFolder].id,
        name: franchiseFolder.subfolders[franchiseDetails.homeFolder].name,
      },
    };
    createNewFranchise({ newFranchiseDetails });
    handleClose();
  };

  const handleCancel = () => {
    cancelCreatingNewFranchise();
    handleClose();
  };

  const franchiseDetailsForm = (
    <>
      <Typography variant="h4" className={classes.addFranchiseModalSectionHeader}>
        Franchise Details
      </Typography>
      <div className={classes.addFranchiseModalInputFieldContainer}>
        <TextField
          required
          fullWidth
          autoFocus
          size="small"
          variant="outlined"
          label="Franchise Name"
          id="outlined-helperText"
          value={franchiseDetails.name}
          field={franchiseDetails.name}
          onChange={handleChangeFranchiseName}
          className={classes.addFranchiseModalInputField}
        />
      </div>
      <div className={classes.addFranchiseModalInputFieldContainer}>
        <TextField
          required
          fullWidth
          size="small"
          key="Secret Code"
          variant="outlined"
          label="Secret Code"
          field={franchiseSecret}
          value={franchiseSecret}
          disabled={isGeneratingNewFranchiseSecret}
          className={`
              ${classes.addFranchiseModalInputField} 
              ${classes.addFranchiseModalInputFieldWithButton}`}
        />
        <div className={classes.addFranchiseModalInputFieldButtonContainer}>
          <Button
            disabled={isGeneratingNewFranchiseSecret}
            onClick={handleGenerateSecretCode}
            className={`
                ${classes.addFranchiseModalInputFieldButton} 
                ${classes.addFranchiseModalInputFieldButtonLarge}`}
          >
            {isGeneratingNewFranchiseSecret ? 'Generating...' : 'Generate Code'}
          </Button>
        </div>
      </div>
    </>
  );

  const defaultRoleDropdown = (
    <>
      <Typography variant="h4" className={classes.addFranchiseModalSectionHeader}>
        Default Role
      </Typography>
      <Typography variant="h4" className={classes.addFranchiseModalSectionSubheader}>
        Choose a role that will be applied to new users added to the franchise.
      </Typography>
      <FormControl variant="outlined" className={classes.addFranchiseModalFormControl}>
        <InputLabel>Role</InputLabel>
        <Select
          label="Role"
          onChange={handleChangeDefaultRole}
          value={franchiseDetails.defaultRole}
          className={classes.addFranchiseModalDropdown}
        >
          {roles.map((role, index) => (
            <MenuItem key={role} value={index}>{role}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );

  const homeFolderDropdown = (
    <>
      <Typography variant="h4" className={classes.addFranchiseModalSectionHeader}>
        Home Folder
      </Typography>
      <Typography variant="h4" className={classes.addFranchiseModalSectionSubheader}>
        Select the folder that this franchise will see as its home folder.
      </Typography>
      <FormControl variant="outlined" className={classes.addFranchiseModalFormControl}>
        <InputLabel>Selected Folder</InputLabel>
        <Select
          label="Select Folder"
          onChange={handleChangeHomeFolder}
          value={franchiseDetails.homeFolder}
          onOpen={toggleOpenHomeFolderDropdown}
          onClose={toggleOpenHomeFolderDropdown}
          className={classes.addFranchiseModalDropdown}
          disabled={isFranchiseFolderLoading || isGoingBack}
        >
          {folders.map((folder, index) => (
            <MenuItem
              value={index}
              key={folder.id}
              className={classes.addFranchiseModalDropdownItem}
            >
              <FolderIcon className={classes.franchiseFolderIcon} />
              <Typography className={classes.franchiseFolderName}>
                {folder.name}
              </Typography>
              {showFolderIcons && (
                <IconButton
                  onClick={(e) => handleAccessFolder(e, folder.id)}
                  className={classes.addFranchiseModalDropdownItemIconContainer}
                  size="large">
                  <AccessFolderIcon className={classes.addFranchiseModalDropdownItemIcon} />
                </IconButton>
              )}
            </MenuItem>
          ))}
        </Select>
        {franchiseFolder.name && franchiseFolder.name !== 'Virtual Root' && (
          <div className={classes.addFranchiseModalInputFieldButtonContainer}>
            <Button
              onClick={handleGoBack}
              disabled={isGoingBack}
              className={`
                  ${classes.addFranchiseModalInputFieldButton} 
                  ${classes.addFranchiseModalInputFieldButtonLarge}`}
            >
              {isGoingBack ? 'Going back...' : 'Go Back'}
            </Button>
          </div>
        )}
      </FormControl>
      <Typography variant="h4" className={classes.addFranchiseModalSectionSubheader}>
        Use this input to create a subfolder inside the folder selected above.
      </Typography>
      <FormControl variant="outlined" className={classes.addFranchiseModalFormControl}>
        <TextField
          required
          fullWidth
          size="small"
          variant="outlined"
          label="New Subfolder"
          value={newFolderName}
          field={newFolderName}
          onChange={handleChangeNewFolderName}
          className={`
                ${classes.addFranchiseModalInputField} 
                ${classes.addFranchiseModalInputFieldWithButton}`}
        />
        <div className={classes.addFranchiseModalInputFieldButtonContainer}>
          <Button
            disabled={!newFolderName}
            onClick={handleCreateNewFolder}
            className={`
                ${classes.addFranchiseModalInputFieldButton} 
                ${classes.addFranchiseModalInputFieldButtonSmall}`}
          >
            {isCreatingNewFranchiseFolder ? 'Creating...' : 'Create'}
          </Button>
        </div>
      </FormControl>
    </>
  );

  const submitButtons = (
    <div className={classes.addFranchiseModalButtonsContainer}>
      <Button
        onClick={handleCancel}
        className={classes.addFranchiseModalCancelButton}
      >
        Cancel
      </Button>
      <Button
        type="submit"
        onClick={handleSave}
        className={classes.addFranchiseModalSaveButton}
        disabled={!franchiseDetails.name || isCreatingNewFranchise}
      >
        {isCreatingNewFranchise ? 'Saving...' : 'Save'}
      </Button>
    </div>
  );

  return (
    <div className={classes.franchiseModalContentContainer}>
      {franchiseDetailsForm}
      <Divider className={classes.addFranchiseModalSectionDivider} />
      {defaultRoleDropdown}
      <Divider className={classes.addFranchiseModalSectionDivider} />
      {homeFolderDropdown}
      {submitButtons}
    </div>
  );
};

export default compose(
  withFoldersData,
  withFoldersActions,
  withFranchiseData,
  withFranchiseActions,
)(AddNewFranchiseModalContent);
