import React from 'react';
import { compose } from 'redux';

import {
  Box,
  Button,
  Divider,
  IconButton,
  Slider,
  TextField,
  Typography,
} from '@mui/material';

import {
  Add as AddIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';

import PaperSection from '../../../Components/PaperSection';

import { formatSliderLabel } from '../../../../Utils/Utils';

import withTrackerData from '../../../../redux/WithData/tracker';

const DRIVER_IDS = [
  '0001',
  '0002',
  '0003',
  '0004',
]

const DriverIDSection = ({
  sx,
  
  tracker,
  isTrackerLoading,
}) => {
  const [values, setValues] = React.useState({
    start: 3,
    end: 3,
    newID: '',
  });

  const [driverIDs, setDriverIDs] = React.useState(DRIVER_IDS);

  const DriverIdRow = ({
    driverID,
  }) => {
    return (
      <Box sx={theme => ({
        display: 'flex',
        flexDirection: 'row',
        pl: 3, pr: 2,
        '.MuiIconButton-root': {
          visibility: 'hidden',
        },
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
          '.MuiIconButton-root': {
            visibility: 'visible',
          },
        },
      })}>
        <Typography sx={{ m: 'auto', ml: 0 }}>{driverID}</Typography>
        <IconButton onClick={() => handleClickDeleteID(driverID)}>
          <DeleteIcon/>
        </IconButton>
      </Box>
    )
  }
  
  function handleChange(e) {
    if (values[e.target.name] !== e.target.value) {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
      })
    }
  }

  function handleClickAddID() {
    // TODO: add validation to prevent duplicates

    setDriverIDs([
      ...driverIDs,
      values.newID,
    ]);

    setValues({
      ...values,
      newID: '',
    });
  }

  function handleClickDeleteID(driverID) {
    console.log(driverID);

    setDriverIDs([ ...driverIDs.filter(id => id !== driverID) ]);
  }

  return (
    <PaperSection sx={{ ...sx, p: 0, flexDirection: 'row', height: '250px', minHeight: '250px' }}>
      <Box sx={{ width: '50%', p: 3 }}>
        <Typography variant="h6">Driver ID</Typography>

        <Typography variant="body2" sx={{ mt: 3 }}>Start-of-Journey Sensitivity</Typography>
        <Slider
          // disabled={!isEditable}
          disabled
          name="start"
          value={values.start}
          onChange={handleChange}
          valueLabelFormat={formatSliderLabel}
          valueLabelDisplay="auto"
          min={1}
          max={5}
          step={1}
          marks
        />

        <Typography variant="body2" sx={{ mt: 1 }}>End-of-Journey Sensitivity</Typography>
        <Slider
          // disabled={!isEditable}
          disabled
          name="end"
          value={values.end}
          onChange={handleChange}
          valueLabelFormat={formatSliderLabel}
          valueLabelDisplay="auto"
          min={1}
          max={5}
          step={1}
          marks
        />
      </Box>

      <Divider orientation="vertical" flexItem/>

      <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
        <Box sx={theme => ({ display: 'flex', flexDirection: 'row', p: `${theme.spacing(2)} ${theme.spacing(3)}` })}>
          <TextField
            // disabled={!isEditable}
            name="newID"
            label="New ID"
            value={values.newID}
            onChange={handleChange}
          />
          <Button variant="contained" startIcon={<AddIcon/>} disabled={!values.newID} onClick={handleClickAddID} sx={{ m: 'auto 0', ml: 2 }}>Add</Button>
        </Box>

        <Divider/>

        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
          {driverIDs.map((driverID) => (
            <DriverIdRow key={driverID} driverID={driverID}/>
          ))}
        </Box>
      </Box>
    </PaperSection>
  );
}

export default compose(withTrackerData)(DriverIDSection);