import React from 'react';
import { compose } from 'redux';

import { Formik } from 'formik';

import {
  Box,
  TextField,
} from '@mui/material';

import {
  PersonAdd as SignUpIcon,
} from '@mui/icons-material';

import LoginHandler from '../../Components/Auth';
import Submit from '../Components/Inputs/SubmitButton';
import PasswordField from '../Components/Inputs/PasswordField';

import withAuthData from '../../redux/WithData/auth';
import withAuthActions from '../../redux/WithActions/auth';

const Register = ({
  history,
  isRegistering,
  registerUser,
}) => {
  return (
    <Formik
      initialValues={LoginHandler.RegisterInitialValue()}
      validate={LoginHandler.RegisterValidate}
      onSubmit={(values) => registerUser({ credentials: values, history })}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              required
              name="codeValidation"
              label="Activation Code"
              autoComplete="one-time-code"
              value={values.codeValidation}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(errors.codeValidation)}
              helperText="This code is needed to activate your account. If you haven't received one yet, contact your Automatrics account manager."
              sx={{ mt: 2 }}
            />
            <TextField
              required
              name="email"
              label="Email"
              autoComplete="username"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(errors.email)}
              helperText={errors.email || undefined}
              sx={{ mt: 3 }}
            />
            <TextField
              required
              name="firstName"
              label="First Name"
              autoComplete="given-name"
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(errors.firstName)}
              helperText={errors.firstName || undefined}
              sx={{ mt: 2 }}
            />
            <TextField
              required
              name="lastName"
              label="Last Name"
              autoComplete="family-name"
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(errors.lastName)}
              helperText={errors.lastName || undefined}
              sx={{ mt: 2 }}
            />
            <PasswordField
              name="password"
              autoComplete="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(errors.password)}
              helperText={errors.password || undefined}
              sx={{ mt: 2 }}
            />
            <Submit
              buttonText="Sign up"
              loading={isRegistering}
              startIcon={<SignUpIcon/>}
              sx={{ m: '0 auto', mt: 2 }}
            />
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default compose(withAuthData)(Register);
