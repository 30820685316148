import React, { useState, useEffect } from 'react';

import { Modal, Button, Typography, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Cancel } from '@mui/icons-material';

import {
  mobile,
} from '../../../Styles/styles';

const useStyles = makeStyles(() => ({
  button: {
    // height: '36px',
    // width: '143px',
    // borderRadius: '8px',
    textTransform: 'none',
  },
}));

const CustomModal = ({
  btnText = 'Open modal',
  title = 'Simple Modal',
  openWith = 'button',
  className = '',
  startIcon,
  body,
}) => {
  const classes = useStyles();
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener('resize', handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, []);

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const content = (
    <div className={`modal-content ${className}`}>
      <div className="header">
        <h2 className="modal-title">
          {title}
        </h2>
        <IconButton onClick={handleClose} className="close-btn" size="large">
          <Cancel fontSize="large" />
        </IconButton>
      </div>
      <div className="body">
        {body(handleClose)}
      </div>
    </div>
  );

  return <>
    {openWith === 'button' && (
      <Button variant="contained" startIcon={startIcon} onClick={handleOpen} className={classes.button}>
        {width > mobile ? btnText : ''}
      </Button>
    )}
    {openWith === 'textButton' && (
      <Typography onClick={handleOpen} style={{ cursor: 'pointer' }}>
        {btnText}
      </Typography>
    )}
    <Modal
// `handleOnEscapeKeyDown` is removed by codemod.

      open={open}
      onBackdropClick={handleClose}
      className={`modal-dialog ${className}`}>
      {content}
    </Modal>
  </>;
};

export default CustomModal;
