import makeStyles from '@mui/styles/makeStyles';
import {
  valueColor,
  buttonPurple,
  semiBoldFont,
  onInputHoverPurple,
  onInputFocusPurple,
  onHoverButtonPurple,
} from '../../../../Styles/styles';

const editCustomerModalContentStyles = makeStyles(() => ({
  editCustomerModalContentContainer: {
    paddingTop: '15px',
    paddingRight: '15px',
    overflow: 'hidden',
  },
  editCustomerModalFormControl: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${onInputHoverPurple}`,
    },
    '& .MuiInputBase-input:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: onInputFocusPurple,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: `1px solid ${onInputFocusPurple}`,
      },
    },
  },
  editCustomerModalDropdown: {
    height: '40px',
    width: '330px',
    '& div': {
      display: 'flex',
    },
  },
  editCustomerModalDropdownItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  editCustomerModalDropdownItemIconContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'lightsteelblue',
    },
  },
  editCustomerModalDropdownItemIcon: {
    color: 'darkslategray',
    fontSize: '15px',
  },
  editCustomerModalSectionDivider: {
    margin: '24px 0px',
  },
  editCustomerModalSectionHeader: {
    color: '#323547',
    fontSize: '20px',
    fontWeight: '600',
    letterSpacing: '0',
    lineHeight: '32px',
    fontFamily: semiBoldFont,
  },
  editCustomerModalSectionSubheader: {
    color: '#323547',
    fontSize: '14px',
    letterSpacing: '0',
    lineHeight: '20px',
    fontFamily: semiBoldFont,
    padding: '15px 0px 10px 0px',
  },
  editCustomerModalInputFieldContainer: {
    display: 'flex',
    margin: '20px auto',
    justifyContent: 'space-between',
    '& .MuiFormLabel-root.Mui-focused': {
      color: onInputFocusPurple,
    },
  },
  editCustomerModalInputField: {
    width: '100%',
    display: 'flex',
    fontSize: '14px',
    color: valueColor,
    lineHeight: '20px',
    fontFamily: semiBoldFont,
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${onInputHoverPurple}`,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: `1px solid ${onInputFocusPurple}`,
      },
    },
  },
  editCustomerModalInputFieldWithButton: {
    width: '330px',
  },
  editCustomerModalInputFieldButtonContainer: {
    display: 'flex',
    whiteSpace: 'nowrap',
    justifyContent: 'flex-end',
  },
  editCustomerModalInputFieldButton: {
    margin: '0px',
    width: '100%',
    borderRadius: '8px',
    color: buttonPurple,
    textTransform: 'none',
    maxWidth: 'fit-content',
  },
  editCustomerModalButtonsContainer: {
    display: 'flex',
    paddingTop: '26px',
    justifyContent: 'flex-end',
  },
  editCustomerModalCancelButton: {
    margin: '0px',
    width: '100%',
    borderRadius: '8px',
    color: buttonPurple,
    textTransform: 'none',
    maxWidth: 'fit-content',
  },
  editCustomerModalSaveButton: {
    color: 'white',
    padding: '6px 15px',
    borderRadius: '8px',
    textTransform: 'none',
    margin: '0px 0px 0px 12px',
    backgroundColor: buttonPurple,
    '&:disabled': {
      backgroundColor: '#E4E7EC',
      color: '#929FB4',
    },
    '&:hover': {
      color: 'white',
      backgroundColor: onHoverButtonPurple,
    },
  },
}));

export default editCustomerModalContentStyles;
