export const scheduleModes = ['INT', 'CAL'];

export const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const locationTypes = [
  { id: 'PING', name: 'Ping', backgroundColor: '#948BFE' },
  { id: 'GSM', name: 'GSM', backgroundColor: '#0091FF' },
  { id: 'WIFI', name: 'WiFi', backgroundColor: '#1FB09E' },
  { id: 'GPS', name: 'GPS', backgroundColor: '#FF9B2B' },
];

export const hours = [...Array(25).keys()];
export const minutes = [...Array(60).keys()];

export const extractMinutesFromTimeInterval = (timeInterval) => timeInterval % 60;
export const extractHoursFromTimeInterval = (timeInterval) => Math.floor(timeInterval / 60);
