import React from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Box,
} from '@mui/material';

import PaperSection from '../PaperSection';
import VirtualisedTable, { FONT_SIZE_XL } from '../VirtualisedTable/VirtualisedTable';

import { useWindowDimensions, getFullPaperSectionWidth } from '../../../Utils/ScreenSize';
import { getColumns } from './columns';

const AVATAR_SIZE = 48;

const UserTable = ({
  users,
  areUsersLoading,
  sx,
}) => {
  const theme = useTheme();
  const SPACING = parseInt(theme.spacing(1));

  const windowDimensions = useWindowDimensions();
  
  const COLUMNS = getColumns(getFullPaperSectionWidth(windowDimensions, theme), AVATAR_SIZE, SPACING);

  return (
    <PaperSection sx={{ height: '100%', p: 0, overflow: 'hidden', ...sx }}>
      <Box sx={{ width: '100%', height: '100%' }}>
        <VirtualisedTable
          hover
          rowHeight={AVATAR_SIZE + (2 * SPACING)}
          fontSize={FONT_SIZE_XL}
          columns={COLUMNS}
          loading={areUsersLoading}
          rowCount={!areUsersLoading && users ? users.length : 0}
          rowGetter={({ index }) => users ? users[index] : null}
        />
      </Box>
    </PaperSection>
  );
}

export default UserTable;