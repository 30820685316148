import React from 'react';
import { compose } from 'redux';
import { DateTime } from 'luxon';

import {
  Box,
  Tabs,
  Tab,
  Typography,
} from '@mui/material';

import PaperSection from '../../../Components/PaperSection';
import ConfinedTypography from '../../../Components/Typographies/ConfinedTypography';
import CommsDirection, { DIRECTIONS as COMMS_DIRECTIONS } from '../CommsLog/CommsDirection';

import { formatDateTime } from '../../../../Utils/TimeUtils';
import { AUDIT_DETAILS_KEY } from '../../../../Utils/Events';
import { TYPES as AUDIT_TYPES } from '../NotesAndAudit/AuditSection/types';
import { CARRIERS } from '../CommsLog/carriers';
import { TYPES as COMMS_TYPES } from '../CommsLog/types';
import { TYPES as NOTIFICATION_TYPES } from '../NotificationLog/types';

import withEventData from '../../../../redux/WithData/event';
import withEventActions from '../../../../redux/WithActions/event';

const TABS = {
  note: {
    key: 'note',
    label: 'Notes',
    types: AUDIT_TYPES,
    userField: 'user',
  },
  audit: {
    key: 'audit',
    label: 'Audit',
    types: AUDIT_TYPES,
  },
  comms: {
    key: 'comms',
    label: 'Comms',
    types: CARRIERS,
  },
  notification: {
    key: 'notification',
    label: 'Notifications',
    types: NOTIFICATION_TYPES,
  },
};

const TABS_ARRAY = [
  TABS.note,
  TABS.audit,
  TABS.comms,
  TABS.notification,
];

// TODO: retrieve via API
const TEST_DATA = {
  note: { timestamp: '2017-09-14T03:20:34.091-04:00', user: 'John Smith', data: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna' },
  audit: { timestamp: '2017-09-14T03:20:34.091-04:00', user: 'John Smith', type: AUDIT_TYPES.ASSET_INFO.id, data: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.' },
  comms: { timestamp: '2017-09-14T03:20:34.091-04:00', type: CARRIERS.SMS.id, direction: COMMS_DIRECTIONS.INBOUND.id, data: 'more things' },
  notification: { timestamp: '2017-09-14T03:20:34.091-04:00', type: NOTIFICATION_TYPES.warning.id, data: 'other stuff' },
}

const LatestLog = ({
  sx,
  
  latestEvents,
  areLatestEventsLoading,
  getAuditDetails,
}) => {
  const [activeTab, setActiveTab] = React.useState(TABS.note.key);
  const [tabData, setTabData] = React.useState({
    // note: TEST_DATA.note,
    // audit: TEST_DATA.audit,
    // comms: TEST_DATA.comms,
    // notification: TEST_DATA.notification,
  });

  // Update tab data
  React.useEffect(() => {
    setTabData({
      ...tabData,
      // TODO: this will include deleted notes - FIX???
      note: latestEvents.note && {
        timestamp:  latestEvents.note.timestamp,
        user:       latestEvents.note.whoCreated,
        data:       latestEvents.note.text,
      },
      audit: latestEvents.audit && {
        timestamp:  latestEvents.audit.timestamp,
        user:       latestEvents.audit.who,
        info1:      AUDIT_TYPES[latestEvents.audit.type].label,
        data:       JSON.stringify(latestEvents.audit[AUDIT_DETAILS_KEY], null, 2),
      },
      comms: latestEvents.comms && {
        timestamp:  latestEvents.comms.timestamp,
        info1:      CARRIERS[latestEvents.comms.carrier]?.label || latestEvents.comms.carrier,    // TODO: remove alt value once API is updated
        info2:      COMMS_TYPES[latestEvents.comms.type].label,
        direction:  latestEvents.comms.direction,
        data:       latestEvents.comms.rawData
      },
    });
  }, [
    latestEvents,
  ]);

  // Fetch audit details if necessary
  React.useEffect(() => {
    if (activeTab === TABS.audit.key && latestEvents.audit && !latestEvents.audit[AUDIT_DETAILS_KEY]) {
      getAuditDetails(latestEvents.audit.id);
    }
  }, [
    activeTab,
    latestEvents,
    getAuditDetails,
  ]);

  const handleTabChange = (_, tabValue) => {
    setActiveTab(tabValue);
  };

  return (
    <PaperSection sx={{ minHeight: '160px', overflow: 'clip', p: 0, ...sx }}>
      <Tabs
        variant="fullWidth"
        value={activeTab}
        onChange={handleTabChange}
      >
        {TABS_ARRAY.map((tab) => (
          <Tab value={tab.key} key={tab.key} label={tab.label} />
        ))}
      </Tabs>

      {tabData[activeTab] ? (
        <Box sx={{ p: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
            <Typography sx={{ width: '200px' }}>{formatDateTime(DateTime.fromISO(tabData[activeTab].timestamp), true)}</Typography>

            {tabData[activeTab]?.user && (
              <ConfinedTypography title sx={{ width: '300px', mr: 2 }}>{tabData[activeTab].user}</ConfinedTypography>
            )}

            {tabData[activeTab]?.info1 && (
              <Typography sx={{ width: '120px' }}>{tabData[activeTab]?.info1}</Typography>
            )}

            {tabData[activeTab]?.info2 && (
              <Typography sx={{ width: '120px' }}>{tabData[activeTab]?.info2}</Typography>
            )}
            
            {activeTab === TABS.comms.key && (
              <CommsDirection direction={COMMS_DIRECTIONS[tabData.comms.direction]} type={CARRIERS[tabData.comms.type]}/>
            )}
          </Box>
    
          <ConfinedTypography title maxLines={2} forceWrap={activeTab === TABS.comms.key}>
            {tabData[activeTab].data}
          </ConfinedTypography>
        </Box>
      ) : (
        <Typography sx={{ m: 'auto' }}>No Data Found</Typography>
      )}
    </PaperSection>
  );
}

export default compose(withEventData, withEventActions)(LatestLog);