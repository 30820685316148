import makeStyles from '@mui/styles/makeStyles';

import {
  white,
  // buttonPurple,
  // buttonTextPurple,
  // onInputHoverPurple,
  // onInputFocusPurple,
  // onHoverButtonPurple,
  toggleUncheckedGrey,
  // toggleCheckedPurple,
  disabledButtonTextGray,
  toggleTrackUncheckedGrey,
  // toggleTrackCheckedPurple,
  disabledButtonBackgroundGray,
} from '../../../../Styles/styles';

const modalContentStyles = makeStyles((theme) => ({
  gridList: {
    width: '100%',
  },
  gridListTile: {
    width: 'unset',
    height: 'auto',
    '& div': {
      '& div': {
        marginTop: '0px',
      },
    },
  },
  cancelButton: {
    //borderColor: white,
    borderRadius: '8px',
    textTransform: 'none',
    //color: buttonTextPurple,
  },
  confirmButton: {
    color: white,
    borderRadius: '8px',
    textTransform: 'none',
    //backgroundColor: buttonPurple,
    '&:disabled': {
      backgroundColor: disabledButtonBackgroundGray,
      color: disabledButtonTextGray,
    },
    '&:hover': {
      color: white,
      //backgroundColor: onHoverButtonPurple,
    },
  },
  disabledButton: {
    backgroundColor: 'lightgrey',
  },
  subfoldersSwitchControl: {
    color: 'gray',
    margin: 'unset',
    display: 'flex',
    paddingLeft: '16px',
    justifyContent: 'space-between',
  },
  subfoldersSwitch: {
    '& span': {
      '&.MuiSwitch-switchBase': {
        color: toggleUncheckedGrey,
      },
      '&.MuiSwitch-track': {
        backgroundColor: toggleTrackUncheckedGrey,
      },
      '&.Mui-checked.MuiSwitch-switchBase': {
        // color: toggleCheckedPurple,
        color: theme.palette.primary.main,
      },
      // '&.Mui-checked + .MuiSwitch-track': {
      //   backgroundColor: toggleTrackCheckedPurple,
      // },
    },
  },
  modalContentFormControl: {
    width: '100%',
    display: 'flex',
    marginBottom: '10px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    // '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    //   border: `1px solid ${onInputHoverPurple}`,
    // },
    // '& .MuiInputBase-input:focus': {
    //   backgroundColor: 'transparent',
    // },
    // '& .MuiFormLabel-root.Mui-focused': {
    //   color: onInputFocusPurple,
    // },
    // '& .MuiOutlinedInput-root': {
    //   '&.Mui-focused fieldset': {
    //     border: `1px solid ${onInputFocusPurple}`,
    //   },
    // },
  },
  modalContentDropdown: {
    height: '40px',
    width: '100%',
    '& div': {
      display: 'flex',
    },
    '& .MuiSelect-select': {
      marginBottom: '0px',
    },
  },
}));

export default modalContentStyles;
