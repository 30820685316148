import { handleActions } from 'redux-actions';

import { logout } from '../Actions/AuthActions';
import { addNotification, removeNotifications } from '../Actions/SystemActions';

const initialState = {
  notifications: [],
};

export default handleActions({
  [addNotification]: (state, { payload: notification }) => ({
    ...state,
    notifications: [
      ...state.notifications,
      notification,
    ],
  }),
  [removeNotifications]: (state) => ({
    ...state,
    notifications: state.notifications.slice(0, state.notifications.length - 1),
  }),
  [logout]: (state) => ({
    ...state,
    notifications: [],
  }),
}, initialState);
