import Cookies from 'universal-cookie';
import { handleActions } from 'redux-actions';

import { logout } from '../Actions/auth';
import {
  setLoggedIn,
  setLoggingIn,
  setRegistering,

  updateTokens,
} from '../Actions/auth';

const COOKIE_EMAIL = 'email';
const COOKIE_ACCESS_TOKEN = 'accessToken';
const COOKIE_REFRESH_TOKEN = 'refreshToken';

const cookies = new Cookies();

const defaultState = {
  isLoggedIn: !!cookies.get(COOKIE_ACCESS_TOKEN),
  isLoggingIn: false,
  isRegistering: false,

  email: cookies.get(COOKIE_EMAIL) || '',
  accessToken: cookies.get(COOKIE_ACCESS_TOKEN) || '',
  refreshToken: cookies.get(COOKIE_REFRESH_TOKEN) || '',
};

export default handleActions({
  [logout]: () => defaultState,

  // Sign in/up
  [setLoggedIn]: (state, { payload: isLoggedIn }) => ({ ...state, isLoggedIn }),
  [setLoggingIn]: (state, { payload: isLoggingIn }) => ({ ...state, isLoggingIn }),
  [setRegistering]: (state, { payload: isRegistering }) => ({ ...state, isRegistering }),

  // Access/Refresh Tokens
  [updateTokens]: (state, { payload }) => {
    if (payload) {
      cookies.set(COOKIE_EMAIL, payload.email, { path: '/', sameSite: 'lax' });
      cookies.set(COOKIE_ACCESS_TOKEN, payload.accessToken, { path: '/', sameSite: 'lax' });
      cookies.set(COOKIE_REFRESH_TOKEN, payload.refreshToken, { path: '/', sameSite: 'lax' });
    }
    else {
      cookies.remove(COOKIE_EMAIL);
      cookies.remove(COOKIE_ACCESS_TOKEN);
      cookies.remove(COOKIE_REFRESH_TOKEN);
    }

    return { ...state, email: payload?.email || '', accessToken: payload?.accessToken || '', refreshToken: payload?.refreshToken || '' }
  },
}, defaultState);