import React from 'react';
import { compose } from 'redux';

import MenuItem from '@mui/material/MenuItem';
import FavoritesIcon from '@mui/icons-material/Star';
import { Warning, CalendarToday, Schedule } from '@mui/icons-material';
import { Select, ListSubheader } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import withTrackersData from '../../../Hocs/WithData/tracker';
import { buttonBoxShadow, mobile } from '../../../Styles/styles';
import TextWithIcon from '../../Components/Elements/TextWithIcon';
import withTrackersActions from '../../../Hocs/WithAction/tracker';

const trackerFilterSelectorStyles = makeStyles((theme) => ({
  trackerFilterSelector: {
    width: '49%',
    height: '50px',
    paddingLeft: '20px',
    borderRadius: '8px',
    backgroundColor: 'white',
    boxShadow: buttonBoxShadow,
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
    '& div': {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    [theme.breakpoints.down(mobile)]: {
      width: '100%',
    },
  },
  trackerFilterSelectorIcon: {
    color: 'gray',
  },
}));

const TrackerFilterSelector = ({
  trackersListFilter,
  changeTrackersListFilter,
}) => {
  const classes = trackerFilterSelectorStyles();

  const handleChange = ({ target: { value: filterName } }) => {
    changeTrackersListFilter({ filterName });
  };

  return (
    <Select
      onChange={handleChange}
      value={trackersListFilter}
      className={classes.trackerFilterSelector}
    >
      <MenuItem value="all">
        <TextWithIcon component="p" icon={<Schedule />}>All trackers</TextWithIcon>
      </MenuItem>
      <MenuItem disabled value="updated-24h">
        <TextWithIcon component="p" icon={<Schedule />}>Updated last 24 hours</TextWithIcon>
      </MenuItem>
      <MenuItem disabled value="updated-7d">
        <TextWithIcon component="p" icon={<Schedule />}>Updated last 7 days</TextWithIcon>
      </MenuItem>
      <MenuItem disabled value="not-updated-1m">
        <TextWithIcon component="p" icon={<Schedule />}>Not updated in 1 month</TextWithIcon>
      </MenuItem>
      <MenuItem disabled value="not-updated-1y">
        <TextWithIcon component="p" icon={<Schedule />}>Not updated in 1 year</TextWithIcon>
      </MenuItem>
      <ListSubheader>Your trackers</ListSubheader>
      <MenuItem disabled value="alarm-states">
        <TextWithIcon component="p" icon={<Warning />}>Alarm States</TextWithIcon>
      </MenuItem>
      <MenuItem value="recent">
        <TextWithIcon
          component="p"
          icon={<CalendarToday className={classes.trackerFilterSelectorIcon} />}
        >Recent</TextWithIcon>
      </MenuItem>
      <MenuItem value="favorites">
        <TextWithIcon
          component="p"
          icon={<FavoritesIcon className={classes.trackerFilterSelectorIcon} />}
        >Favourites</TextWithIcon>
      </MenuItem>
    </Select>
  );
};
export default compose(withTrackersData, withTrackersActions)(TrackerFilterSelector);
