const darkPalette = {
  background: {
    default: '#282628',
    paper: '#2E2E2E',
    header: '#3D3D3D',
  },
  primary: {
    main: '#0048FF',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#92278F',
  },
  action: {
    focusOpacity: 0.24,
    focus: 'rgba(255, 255, 255, 0.24)',
  },

  states: {
    ALARM: {
      light: '#8C6359',
      main: '#A87265',
      dark: '#C38171'
    },
    WARNING: {
      light: '#918150',
      main: '#AF995A',
      dark: '#CCB263'
    },
    ALARM_ACK: {
      light: '#93807B',
      main: '#B09791',
      dark: '#CEAFA8'
    },
    WARNING_ACK: {
      light: '#958D77',
      main: '#B3AA8D',
      dark: '#D2C6A2'
    },
    FAULTY: {
      light: '#694385',
      main: '#79499F',
      dark: '#8A4FB9'
    },
    NO_CONTACT: {
      light: '#5B6B94',
      main: '#677CB2',
      dark: '#748ED0'
    },
    LATE_CONTACT: {
      light: '#7C8396',
      main: '#939CB5',
      dark: '#AAB5D3'
    },
    FAULTY_SENT: {
      light: '#8B6E8E',
      main: '#A781AA',
      dark: '#C293C6'
    },
    FAULTY_ACK: {
      light: '#618151',
      main: '#70995C',
      dark: '#7FB266'
    },
    OK: {
      light: '#303030',
      main: '#5B5B5B',
      dark: '#656565'
    },
    OFF: {
      light: '#737373',
      main: '#868686',
      dark: '#9A9A9A'
    },
  },

  modes: {
    FACTORY: {
      light: '#737373',
      main: '#868686',
      dark: '#9A9A9A'
    },
    STOCK: {
      light: '#667087',
      main: '#7683A1',
      dark: '#8696BB'
    },
    STANDARD: {
      light: '#303030',
      main: '#5B5B5B',
      dark: '#656565'
    },
    WARNING: {
      light: '#918150',
      main: '#AF995A',
      dark: '#CCB263'
    },
    ALARM: {
      light: '#C38171',
      main: '#A87265',
      dark: '#C38171'
    },
    STOLEN: {
      light: '#7F5774',
      main: '#976388',
      dark: '#AF6F9C'
    },
    TEST: {
      light: '#5E857E',
      main: '#6C9E95',
      dark: '#7AB8AC'
    },
  },

  nav: {
    GPS: {
      light: '#8A443A',
      main: '#B24D3F',
      dark: '#D05342'
    },
    GPS_LO: {
      light: '#8D723E',
      main: '#B69045',
      dark: '#D5A64A'
    },
    WIFI: {
      light: '#45698A',
      main: '#4F82B2',
      dark: '#5695D0'
    },
    GSM: {
      light: '#89538A',
      main: '#A35EA5',
      dark: '#BE69C0'
    },
  },

  comms: {
    GPRS: '#33B728',
    CAT_M1: '#8A4FB9',
    SMS: '#4EA8CE',
  },

  locationGpsHi: {
    main: '#B24D3F',
  },
  locationGpsLo: {
    main: '#B69045',
  },
  locationWifi: {
    main: '#4F82B2',
  },
  locationGsm: {
    main: '#A35EA5',
  },

  health: {
    t0: '#656565',  // Grey
    t1: '#5CAACC',  // Blue
    t2: '#5AB952',  // Green
    t3: '#B1D056',  // Yellow
    t4: '#D2953B',  // Amber
    t5: '#C13E3E',  // Red
    t6: '#9261BA',  // Purple
  },
};

export default darkPalette;
