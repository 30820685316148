import { ASSET_TYPES_ARRAY } from "../../Utils/AssetTypes";
import { MODES_ARRAY } from "../../Utils/Modes";
import { ROLES } from "../../Utils/Roles";
import { STATES_ARRAY } from "../../Utils/States";
import { TAGS_ARRAY } from "../../Utils/Tags";

export const ALL_FILTERS = {
  any: {
    id: 'any',
    label: 'Any Field',
    type: 'text',
    disabled: true,
  },
  state: {
    id: 'state',
    label: 'State',
    type: STATES_ARRAY,
    disabled: true,
  },
  name: {
    id: 'name',
    label: 'Name',
    type: 'text',
  },
  serialNumber: {
    // id: 'serialNumber',
    // label: 'Serial Number',
    id: 'simiccid',
    label: 'SIM ICC ID',
    type: 'text',
    // disabled: true,
  },
  mode: {
    id: 'mode',
    label: 'Mode',
    type: MODES_ARRAY,
    disabled: true,
  },
  tags: {
    id: 'tags',
    label: 'Tags',
    type: TAGS_ARRAY,
    multiple: true,
    disabled: true,
  },
  assetType: {
    id: 'assetType',
    label: 'Asset Type',
    type: ASSET_TYPES_ARRAY,
    disabled: true,
  },
  contractNumber: {
    id: 'contractNumber',
    label: 'Contract Number',
    type: 'text',
    disabled: true,
  },
  franchise: {
    id: 'franchise',
    label: 'Franchise',
    type: [],
    disabled: true,
  },
  customer: {
    id: 'customer',
    label: 'Customer',
    type: [],
    disabled: true,
  },
  firmware: {
    id: 'firmwareVersion',
    label: 'Firmware',
    type: 'text',
    disabled: true,
  },
  model: {
    id: 'model',
    label: 'Model',
    type: [],
    disabled: true,
  },

  includeStock: {
    id: 'includeStock',
    label: 'Include Stock Mode',
    type: 'toggle',
    disabled: true,
  },
  includeOff: {
    id: 'includeOff',
    label: 'Include Off State',
    type: 'toggle',
    disabled: true,
  },
};

export function getFilters(role) {
  let filters = [
    ALL_FILTERS.any,
    ALL_FILTERS.state,
    ALL_FILTERS.name,
    ALL_FILTERS.serialNumber,
    ALL_FILTERS.mode,
    ALL_FILTERS.tags,
    ALL_FILTERS.assetType,
    ALL_FILTERS.contractNumber,
  ];

  if (role === ROLES.root) {
    filters.push(ALL_FILTERS.franchise);
  }

  if (role !== ROLES.user) {
    filters.push(ALL_FILTERS.customer);
  }

  filters.push(ALL_FILTERS.firmware);
  filters.push(ALL_FILTERS.model);

  let toggles = [
    ALL_FILTERS.includeStock,
    ALL_FILTERS.includeOff,
  ];

  return { filters, toggles };
}