// Based on: 'https://mui.com/material-ui/react-table/#ReactVirtualizedTable.js'

/**
 * react-virtualized source: https://github.com/bvaughn/react-virtualized
 * Default rowRenderer: https://github.com/bvaughn/react-virtualized/blob/master/source/Table/defaultRowRenderer.js
 */

// TODO: look into converting to https://github.com/bvaughn/react-window

import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';

import {
  TableCell,
  TableSortLabel,
} from '@mui/material';

import { AutoSizer, Column, Table } from 'react-virtualized';
import { defaultRowRenderer, renderNoRows, renderLoadingRows } from './defaultRenderers';

export const FONT_SIZE_XS = '0.675rem';
export const FONT_SIZE_S = '0.75rem';
export const FONT_SIZE_M = '0.875rem';
export const FONT_SIZE_L = '1rem';
export const FONT_SIZE_XL = '1.125rem';

const classes = {
  flexContainer: 'VTable-flexContainer',
  tableCell: 'VTable-cell',
  clickable: 'VTable-clickable',
  hoverColour: 'VTable-hoverColour',
  hideButtons: 'VTable-hideButtons',
  noClick: 'VTable-noClick',
};

const styles = ({ theme }) => ({
  // temporary right-to-left patch, waiting for
  // https://github.com/bvaughn/react-virtualized/issues/454
  '& .ReactVirtualized__Table__headerRow': {
    borderBottom: `1px solid ${theme.palette.divider}`,
    ...(theme.direction === 'rtl' && {
      paddingLeft: '0 !important',
    }),
    ...(theme.direction !== 'rtl' && {
      paddingRight: undefined,
    }),
  },
  ['& .ReactVirtualized__Table__headerColumn']: {
    overflow: 'hidden',
  },
  [`& .${classes.flexContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  [`& .${classes.tableCell}`]: {
    flex: 1,
  },
  [`& .${classes.clickable}`]: {
    cursor: 'pointer',
  },
  [`& .${classes.noClick}`]: {
    cursor: 'initial',
  },
  [`& .${classes.hoverColour}`]: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  [`& .${classes.selected}`]: {
    backgroundColor: theme.palette.action.selected,
    '&:hover': {
      backgroundColor: theme.palette.action.focus,
    },
  },
  [`& .${classes.hideButtons}`]: {
    '.MuiIconButton-root': {
      display: 'none',
    },
    '&:hover': {
      '.MuiIconButton-root': {
        display: 'inline-flex',
      },
    },
  },
});

class MuiVirtualizedTable extends React.PureComponent {
  static defaultProps = {
    headerHeight: 48,
    rowHeight: 48,
    loading: false,
    fontSize: FONT_SIZE_M,
    rowRenderer: defaultRowRenderer,
    noRowsRenderer: renderNoRows,
    loadingRowsRenderer: renderLoadingRows,
  };

  getRowClassName = ({ index }) => {
    const { hover, selectable, hideButtons, onRowClick } = this.props;

    // Index is -1 for the header row
    return clsx(classes.flexContainer, {
      [classes.clickable]:   index >= 0 && onRowClick != null,
      [classes.hoverColour]: index >= 0 && (hover || onRowClick != null),
      // [classes.selected]: selectable,
      [classes.hideButtons]: index >= 0 && hideButtons,
    });
  };

  cellRenderer = ({ cellData, columnIndex, rowData, rowIndex }) => {
    const { columns, rowHeight, fontSize } = this.props;

    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer)}
        variant="body"
        sx={{ height: rowHeight, borderBottom: '0 none', padding: columns[columnIndex].noPadding ? 0 : 2, fontSize: fontSize }}
      >
        {!columns[columnIndex].render ? cellData : columns[columnIndex].render({
          cellData,
          rowData,
          rowIdx: rowIndex,
          colIdx: columnIndex,
          fontSize,
        })}
      </TableCell>
    );
  };

  headerRenderer = ({ label, columnIndex }) => {
    const { headerHeight, columns, onColumnHeaderClick, sortBy, fontSize } = this.props;

    return (
      <TableCell
        component="div"
        className={clsx(
          classes.tableCell,
          classes.flexContainer,
          columns[columnIndex].sortable ? classes.clickable : classes.noClick,
          columns[columnIndex].sortable && classes.hoverColour
        )}
        variant="head"
        style={{ height: headerHeight, borderBottom: '0 none', padding: columns[columnIndex].sortable ? 0 : undefined, fontSize: fontSize }}
        align={columns[columnIndex].numeric || false ? 'right' : 'left'}
        onClick={() => columns[columnIndex].sortable && onColumnHeaderClick({ columnIndex })}
        sortDirection={sortBy?.column === columns[columnIndex].dataKey ? sortBy?.dir : false}
      >
        {columns[columnIndex].sortable ? (
          <TableSortLabel
            active={sortBy?.column === columns[columnIndex].dataKey}
            direction={sortBy?.dir}
            hideSortIcon={!columns[columnIndex].sortable}
            sx={theme => ({
              display: 'flex',
              alignItems: 'center',
              padding: 2,
              '&:hover': {
                color: theme.palette.text.primary,
              },
              width: "100%",
            })}
          >
            {label}
          </TableSortLabel>
        ) : (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            {label}
          </span>
        )}
      </TableCell>
    );
  };

  render() {
    const {
      columns,
      rowHeight,
      headerHeight,
      sortBy,
      loading,
      loadingRowsRenderer,
      noRowsRenderer,
      onRowClick,
      onRowDoubleClick,
      onRowMouseOut,
      onRowMouseOver,
      onRowRightClick,
      ...tableProps
    } = this.props;

    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            height={height}
            width={width}
            rowHeight={rowHeight}
            gridStyle={{
              direction: 'inherit',
            }}
            headerHeight={headerHeight}
            {...tableProps}
            rowClassName={this.getRowClassName}
            noRowsRenderer={loading ? loadingRowsRenderer : noRowsRenderer}
            onRowClick={onRowClick}
            onRowDoubleClick={onRowDoubleClick}
            onRowMouseOut={onRowMouseOut}
            onRowMouseOver={onRowMouseOver}
            onRowRightClick={onRowRightClick}
          >
            {columns.map(({ dataKey, ...other }, index) => {
              return (
                <Column
                  key={dataKey}
                  headerRenderer={(headerProps) =>
                    this.headerRenderer({
                      ...headerProps,
                      columnIndex: index,
                    })
                  }
                  className={classes.flexContainer}
                  cellRenderer={this.cellRenderer}
                  dataKey={dataKey}
                  {...other}
                />
              );
            })}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

MuiVirtualizedTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      width: PropTypes.number.isRequired,
      label: PropTypes.node.isRequired,
      dataKey: PropTypes.string.isRequired,
      sortable: PropTypes.bool,
      dynamic: PropTypes.bool,
      render: PropTypes.func,
    }),
  ).isRequired,
  rowHeight: PropTypes.number,
  headerHeight: PropTypes.number,
  loading: PropTypes.bool,

  hover: PropTypes.bool,
  hideButtons: PropTypes.bool,

  onRowClick: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  onRowMouseOut: PropTypes.func,
  onRowMouseOver: PropTypes.func,
  onRowRightClick: PropTypes.func,
};

const VirtualisedTable = styled(MuiVirtualizedTable)(styles);

export default VirtualisedTable;