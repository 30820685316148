import React from 'react';
import { compose } from 'redux';
import makeStyles from '@mui/styles/makeStyles';

import TrackerInfo from './TrackerInfo';
import { mobile } from '../../../../Styles/styles';
import withTrackersData from '../../../../Hocs/WithData/tracker';

const detailsTabStyles = makeStyles((theme) => ({
  container: {
    padding: '30px',
    display: 'block',
    maxWidth: '600px',
    position: 'relative',
    margin: '0 auto auto auto',
    [theme.breakpoints.down(mobile)]: {
      flexDirection: 'column',
      maxWidth: 'unset',
      padding: '15px',
      margin: '0px',
    },
  },
}));

const DetailsTab = ({ currentTracker }) => {
  const classes = detailsTabStyles();
  return (
    <div className={classes.container}>
      <TrackerInfo tracker={currentTracker} />
    </div>
  );
};

export default compose(withTrackersData)(DetailsTab);
