import React from 'react';
import { compose } from 'redux';
import { Route, Redirect, Switch } from 'react-router-dom';

import Login from '../Views/Login';
import MenuBar from '../Views/Components/MenuBar/MenuBar';
import UnitSearch from '../Views/UnitSearch';
import UnitDetails from '../Views/UnitDetails';
import UnitDashboard from '../Views/UnitDashboard';
import FranchiseSearch from '../Views/FranchiseSearch';
import FranchiseDetails from '../Views/FranchiseDetails';
import CustomerSearch from '../Views/CustomerSearch';
import CustomerDetails from '../Views/CustomerDetails';
import UserProfile from '../Views/UserProfile';

import ProfileManager_OLD from '../Views/Profile';
import Dashboard_OLD from '../Views/Dashboard';
import FolderManager from '../Views/Folders';
import AccountsManager from '../Views/Accounts';
import CustomersManager from '../Views/Customers';
import FranchiseManager from '../Views/Franchises';
import TrackerManager_OLD from '../Views/Trackers/ListPage';
import TrackerDetailsManager_OLD from '../Views/Trackers/TrackerDetails';

import withAuthData from '../redux/WithData/auth';

const Navigation = ({
  isLoggedIn,
}) => {
  /*
      TODO:

      Only ROOT users can access franchise search
      Only ADMIN+ users can access customer search

      ADMIN/USER users can only access their related franchises details -> '/franchise' URL
      USER users can only access their related customers details -> '/customer' URL
   */

  return isLoggedIn ? (
    <MenuBar>
      <Switch>
        <Route path="/trackers/:trackerId/:tab" children={<UnitDetails/>} />
        <Route path="/trackers/:trackerId" children={<UnitDetails/>} />
        <Route exact path="/trackers" children={<UnitSearch/>} />
        <Route path="/trackers" render={() => <Redirect to="/trackers" />} />
        <Route exact path="/dashboard" children={<UnitDashboard/>} />
    
        <Route path="/franchises/:franchiseId/:tab" children={<FranchiseDetails/>} />
        <Route path="/franchises/:franchiseId" children={<FranchiseDetails/>} />
        <Route exact path="/franchises" children={<FranchiseSearch/>} />
        <Route path="/franchises" render={() => <Redirect to="/franchises" />} />
    
        <Route path="/customers/:customerId/:tab" children={<CustomerDetails/>} />
        <Route path="/customers/:customerId" children={<CustomerDetails/>} />
        <Route exact path="/customers" children={<CustomerSearch/>} />
        <Route path="/customers" render={() => <Redirect to="/customers" />} />
        
        <Route exact path="/profile" children={<UserProfile/>} />
        <Route exact path="/profile_old" children={<ProfileManager_OLD/>} />
    
        <Route exact path="/dashboard_old" children={<Dashboard_OLD/>} />
        <Route exact path="/trackers_old" children={<TrackerManager_OLD/>} />
        <Route exact path="/trackerDetails_old/:trackerId" children={<TrackerDetailsManager_OLD/>} />
        <Route exact path="/folders" children={<FolderManager/>} />
        <Route exact path="/folders/:id" children={<FolderManager/>} />
        <Route exact path="/franchises_old" children={<FranchiseManager/>} />
        <Route exact path="/accounts" children={<AccountsManager/>} />
        <Route exact path="/customers_old" children={<CustomersManager/>} />
    
        <Route render={() => <Redirect to="/trackers" />} />
      </Switch>
    </MenuBar>
  ) : (
    <Switch>
      <Route exact path="/login" children={<Login/>} />
      <Route exact path="/register" children={<Login/>} />

      <Route path="/register" render={() => <Redirect to="/register" />} />
      <Route render={() => <Redirect to="/login" />} />
    </Switch>
  )
};

export default compose(withAuthData)(Navigation);