import React from 'react';
import { compose } from 'redux';
import { useTheme } from '@mui/material/styles';

import {
  Box,
  Button,
  MenuItem,
  Slider,
  TextField,
  Typography,
} from '@mui/material';

import {
  Close as DiscardIcon,
  Edit as EditIcon,
  Save as SaveIcon,
} from '@mui/icons-material';

import PaperSection from '../../../../Components/PaperSection';
import AssetImage from './AssetImage';
import placeholderImage from '../../../../../Assets/Images/vehicle.png'

import { useWindowDimensions } from '../../../../../Utils/ScreenSize';
import { formatSliderLabel } from '../../../../../Utils/Utils';
import { ASSET_TYPES, ASSET_TYPES_ARRAY } from '../../../../../Utils/AssetTypes';

import withTrackerData from '../../../../../redux/WithData/tracker';
import withTrackerActions from '../../../../../redux/WithActions/tracker';

const DEFAULT_VALUES = {
  name: '',
  type: '',
  registration: '',
  fitLocation: '',
  risk: 3,
  sensitivity: 3,
};

const DEFAULT_IMAGE = {
  thumbnailUrl: '',
  fullsizeUrl: '',
}

const AssetSection = ({
  sx,

  tracker,
  isTrackerLoading,
  updateAsset,
}) => {
  const theme = useTheme();
  const { isMobileWidth, isDesktopWidth } = useWindowDimensions();

  const [isEditable, setEditable] = React.useState(false);
  const [values, setValues] = React.useState({ ...DEFAULT_VALUES });
  const [image, setImage] = React.useState({ ...DEFAULT_IMAGE });
  
  const savedValues = React.useRef({});
  const savedImage  = React.useRef({});

  // Load trackers data into 'values' and 'savedState'
  React.useEffect(() => {
    let currentValues = { ...DEFAULT_VALUES };
    let currentImage = { ...DEFAULT_IMAGE };

    if (tracker?.asset) {
      const validAssetType = !!ASSET_TYPES[tracker.asset.type];

      currentValues = {
        name: tracker.asset.name,
        type: validAssetType ? tracker.asset.type : '',
        registration: tracker.asset.registration,
        fitLocation: tracker.asset.fitLocation,
        risk: tracker.asset.risk,
        sensitivity: tracker.asset.sensitivity,
      };

      // newImage = {

      // };
    }

    setValues(currentValues);
    setImage(currentImage);

    savedValues.current = currentValues;
    savedImage.current  = currentImage;
  }, [])
  

  const assetTypes = ASSET_TYPES_ARRAY.sort((a, b) => a.label.localeCompare(b.label));

  // Height = 2 single-line TextFields + 2x spacing
  const fitLocInputHeight = (40 * 2) + parseInt(theme.spacing(2));

  const handleClickEdit = () => {
    setEditable(true);
  }

  const handleClickSave = () => {
    // Send via API
    updateAsset(values);

    savedValues.current = values;

    setEditable(false);
  }

  const handleClickDiscard = () => {
    setValues(savedValues.current);

    setEditable(false);
  }

  const handleChange = (e) => {
    if (values[e.target.name] !== e.target.value) {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
      })
    }
  }

  const handleEditPhoto = () => {
    if (values.image?.thumbnailUrl) {
      console.log('handleUploadPhoto - Upload or delete');
    }
    else {
      console.log('handleUploadPhoto - Upload only');
    }
  }

  return (
    <PaperSection sx={{ ...sx }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
        <Typography variant="h6">Asset Details</Typography>
        {isEditable ? (
          <>
            <Button variant="contained" startIcon={<SaveIcon/>} onClick={handleClickSave} sx={{ ml: 'auto', mr: 1 }}>Save</Button>
            <Button variant="contained" startIcon={<DiscardIcon/>} onClick={handleClickDiscard}>Discard</Button>
          </>
        ) : (
          <Button variant="contained" startIcon={<EditIcon/>} onClick={handleClickEdit} sx={{ ml: 'auto' }}>Edit</Button>
        )}
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ width: '50%', mr: 3 }}>
          <TextField
            disabled={!isEditable}
            name="name"
            label="Name"
            value={values.name}
            onChange={handleChange}
          />

          <Box sx={{ mt: 2, mb: 2, display: 'flex', flexDirection: 'row' }}>
            <TextField
              disabled={!isEditable}
              select
              name="type"
              label="Type"
              value={values.type}
              onChange={handleChange}
              sx={{ mr: 1 }}
            >
              {assetTypes.map((type) => (
                <MenuItem key={type.id} value={type.id}>{type.label}</MenuItem>
              ))}
            </TextField>
            <TextField
              disabled={!isEditable}
              name="registration"
              label="Registration"
              value={values.registration}
              onChange={handleChange}
              sx={{ ml: 1 }}
            />
          </Box>

          <Typography>Risk Profile</Typography>
          <Slider
            disabled={!isEditable}
            name="risk"
            value={values.risk}
            onChange={handleChange}
            valueLabelFormat={formatSliderLabel}
            valueLabelDisplay="auto"
            min={1}
            max={5}
            step={1}
            marks
          />
        </Box>

        <Box sx={{ width: '50%', mr: 3 }}>
          <TextField
            disabled={!isEditable}
            multiline
            rows={3}
            name="fitLocation"
            label="Fit Location"
            value={values.fitLocation}
            onChange={handleChange}
            sx={{
              mb: 2,
              '& .MuiInputBase-root': {
                height: `${fitLocInputHeight}px`,
              },
            }}
          />
          <Typography>Sensitivity</Typography>
          <Slider
            disabled={!isEditable}
            name="sensitivity"
            value={values.sensitivity}
            onChange={handleChange}
            valueLabelFormat={formatSliderLabel}
            valueLabelDisplay="auto"
            min={1}
            max={5}
            step={1}
            marks
          />
        </Box>

        <Box sx={{ width: '30%', maxWidth: '240px', height: '160px' }}>
          <AssetImage
            editable={isEditable}
            image={image}
            assetType={values.type}
            onEdit={handleEditPhoto}
            sx={{ float: 'right', width: '100%', height: '100%' }}
          />
        </Box>
      </Box>
    </PaperSection>
  );
}

export default compose(withTrackerData, withTrackerActions)(AssetSection);