import React from 'react';
import { compose } from 'redux';

import MenuItem from '@mui/material/MenuItem';
import { Select } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FormControl from '@mui/material/FormControl';

import { buttonBoxShadow } from '../../../Styles/styles';
import withTrackersData from '../../../Hocs/WithData/tracker';
import withTrackersActions from '../../../Hocs/WithAction/tracker';

const trackerSelectorStyles = makeStyles(() => ({
  trackerSelectorContainer: {
    width: '100%',
  },
  trackerSelector: {
    height: '50px',
    padding: '24px',
    borderRadius: '8px',
    backgroundColor: 'white',
    boxShadow: buttonBoxShadow,
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
    '& div': {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

const TrackerSelector = ({
  selectTracker,
  currentTracker,
  favoriteTrackers,
  recentlyViewedTrackers,
}) => {
  const classes = trackerSelectorStyles();

  const handleChange = ({ target }) => {
    selectTracker(target.value);
  };

  const getFeaturedTrackers = () => {
    // Add favorite trackers to dropdown
    const featuredTrackers = [
      ...favoriteTrackers,
    ];

    // Add recently viewed trackers to dropdown only if they are not already favorited
    recentlyViewedTrackers
      .forEach((recentlyViewedTracker) => (favoriteTrackers
        .every((favoriteTracker) => favoriteTracker.simiccid !== recentlyViewedTracker.simiccid)
          && featuredTrackers.push(recentlyViewedTracker)));

    const isCurrentTrackerAlreadyAdded = featuredTrackers
      .some((featuredTracker) => featuredTracker.simiccid === currentTracker.simiccid);

    // Add current tracker to dropdown only if it is not already a favorited or recently viewed
    if (currentTracker && !isCurrentTrackerAlreadyAdded) {
      featuredTrackers.push({
        simiccid: currentTracker.simiccid,
        msisdn: currentTracker.msisdn,
        name: currentTracker.name,
      });
    }

    return featuredTrackers;
  };

  const featuredTrackersList = getFeaturedTrackers().map((featuredTracker) => (
    <MenuItem
      key={featuredTracker.simiccid}
      value={featuredTracker.simiccid}
    >
      {featuredTracker.name}
    </MenuItem>
  ));

  const noFeaturedTrackersFound = (
    <MenuItem value="">No featured trackers found</MenuItem>
  );

  return (
    <FormControl className={classes.trackerSelectorContainer}>
      <Select
        onChange={handleChange}
        value={currentTracker ? currentTracker.simiccid : ''}
        className={classes.trackerSelector}
      >
        {getFeaturedTrackers().length !== 0
          ? featuredTrackersList
          : noFeaturedTrackersFound}
      </Select>
    </FormControl>
  );
};

export default compose(withTrackersActions, withTrackersData)(TrackerSelector);
