import React from 'react';
import { compose } from 'redux';
import { Paper, Typography } from '@mui/material';

import tabColumnsStyle from './style';
import TimeTools from '../../../../../Components/TimeTools';
import { bearingToCoordinates } from '../../../../../Utils/Utils';

import withUserData from '../../../../../redux/WithData/user';
import TrackerLocations from '../../../../../Hocs/WithData/Trackers/eventLocationEvents';

const TabColumns = ({
  selectedEvent, locationEvents, tracker, userTimezone,
}) => {
  const classes = tabColumnsStyle();

  if (locationEvents.length === 0) {
    return (
      <div className={classes.boxesContainer}>
        <Paper className={`${classes.box} ${classes.lastBox}`} variant="outlined" square>
          <Typography className={classes.placeholder}>
            Could not retrieve any location from this tracker
          </Typography>
        </Paper>
      </div>
    );
  }
  // Display either the currently selected location event or the most recent one
  const { timestamp, details } = selectedEvent || locationEvents[0];

  return (
    <div className={classes.boxesContainer}>
      <Paper className={classes.box} variant="outlined" square>
        <Typography className={`${classes.text} ${classes.timezoneWrapper}`}>
          { TimeTools.dayOfWeek(timestamp, { timezone: userTimezone })}
          <span className={classes.timezoneSpan}>Your time</span>
        </Typography>
        <Typography className={classes.text}>
          { TimeTools.showDate(timestamp, { timezone: userTimezone })}
        </Typography>
        <Typography className={classes.text}>
          { TimeTools.showTime(timestamp, { timezone: userTimezone })}
        </Typography>
      </Paper>
      <Paper className={classes.box} variant="outlined" square>
        <Typography className={`${classes.text} ${classes.timezoneWrapper}`}>
          { TimeTools.dayOfWeek(timestamp, tracker)}
          <span className={classes.timezoneSpan}>Tracker time</span>
        </Typography>
        <Typography className={classes.text}>
          { TimeTools.showDate(timestamp, tracker)}
        </Typography>
        <Typography className={classes.text}>
          { TimeTools.showTime(timestamp, tracker)}
        </Typography>
      </Paper>
      <Paper className={classes.box} variant="outlined" square>
        {details.position.w3w.split('.').map((word) => (
          <Typography key={word} className={classes.text}>
            {word}
          </Typography>
        ))}
      </Paper>
      <Paper className={`${classes.box} ${classes.lastBox}`} variant="outlined" square>
        <Typography className={`${classes.text} ${classes.speed}`}>
          {details.gps
            ? `${details.gps.speed} km/h`
            : '-'}
        </Typography>
        <Typography className={classes.text}>
          {details.gps
            ? `${details.gps.bearing}° ${bearingToCoordinates(details.gps.bearing)}`
            : '-'}
        </Typography>
      </Paper>
    </div>
  );
};
export default compose(TrackerLocations, withUserData)(TabColumns);
