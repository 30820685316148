import React from 'react';
import { Popover } from '@mui/material';

const PopoverComponent = ({
  TrigerContent,
  extraClass = '',
  PopOverContent = undefined,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ display: 'flex' }}>
      <button
        type="button"
        onClick={handleClick}
        className={`popover-trigger-wrapper ${extraClass}`}
      >
        {TrigerContent}
      </button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className="popover-wrapper-modal"
        anchorReference="anchorPosition"
        anchorPosition={{
          top: 0,
          left: 0,
        }}
      >
        {PopOverContent}
      </Popover>
    </div>
  );
};

export default PopoverComponent;
