import { connect } from 'react-redux';

import {
  setUserDetails,
  setUserAvatar,
  setUserPassword,
} from '../Actions/user';

export default (WrappedComponent) => connect(null, {
  setUserDetails,
  setUserAvatar,
  setUserPassword,
})(WrappedComponent);