import { connect } from 'react-redux';

import {
  getTracker,
  searchTrackers,
  updateTrackerName,
  selectTrackerEvent,
  getTrackerSettings,
  uploadTrackerImage,
  getFeaturedTrackers,
  getPaginatedTrackers,
  updateTrackerTimezone,
  currentTrackerLoading,
  addTrackerToFavouritesReq,
  changeTrackersListFilter,
  toggleTrackerNameInputField,
  markTrackerAsRecentlyViewed,
  updateTrackerEquipmentNumber,
  updateTrackerSettingsRequest,
  updateTrackerRawSettingsRequest,
  removeTrackerFromFavouritesReq,
  toggleTrackerTimezoneInputField,
  toggleTrackerEquipmentNumberInputField,

  registerTrackerEvent,
  sendPeriodicDummyLocations,
} from '../../redux/Actions/TrackerActions';

export default (WrappedComponent) => connect(
  null, {
    getTracker,
    searchTrackers,
    updateTrackerName,
    selectTrackerEvent,
    getTrackerSettings,
    uploadTrackerImage,
    getFeaturedTrackers,
    getPaginatedTrackers,
    updateTrackerTimezone,
    currentTrackerLoading,
    addTrackerToFavouritesReq,
    changeTrackersListFilter,
    toggleTrackerNameInputField,
    markTrackerAsRecentlyViewed,
    updateTrackerSettingsRequest,
    updateTrackerRawSettingsRequest,
    updateTrackerEquipmentNumber,
    removeTrackerFromFavouritesReq,
    toggleTrackerTimezoneInputField,
    toggleTrackerEquipmentNumberInputField,

    registerTrackerEvent,
    sendPeriodicDummyLocations,
  },
)(WrappedComponent);
