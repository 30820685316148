import React from 'react';

import { Button } from '@mui/material';

const SubmitButtons = ({
  areNewTrackerSettingsUpdating,
  updateTrackerSettingsRequest,
  setNewTrackerSettings,
  newTrackerSettings,
  initialSettings,
  currentTracker,
  scheduleModes,
  classes,
}) => {
  const isCalendarMode = scheduleModes[newTrackerSettings.heartbeatType] === 'CAL';
  const hasAddedCalendarEvents = newTrackerSettings.calendarEvents && newTrackerSettings.calendarEvents.length !== 0;

  const handleCancelTrackerSettings = () => {
    setNewTrackerSettings({ ...initialSettings });
  };

  const handleSaveTrackerSettings = () => {
    const intervalSettings = {
      debug: newTrackerSettings.debug,
      forceUpdate: newTrackerSettings.forceUpdate,
      mode: scheduleModes[newTrackerSettings.heartbeatType],
      interval: (newTrackerSettings.intervalHour * 60) + newTrackerSettings.intervalMinute,
    };

    const scheduleSettings = {
      debug: newTrackerSettings.debug,
      forceUpdate: newTrackerSettings.forceUpdate,
      mode: scheduleModes[newTrackerSettings.heartbeatType],
      schedule: {
        schedule: newTrackerSettings.calendarEvents.map((calendarEvent) => ({
          time: calendarEvent.time,
          essential: calendarEvent.essential,
          dayOfWeek: calendarEvent.day.toUpperCase(),
          update: calendarEvent.locationType.toUpperCase(),
        })),
        timezone: newTrackerSettings.timezone,
      },
    };

    updateTrackerSettingsRequest({
      trackerId: currentTracker.simiccid,
      newSettings: isCalendarMode ? scheduleSettings : intervalSettings,
    });
  };
  return (
    <div className={classes.trackerSettingsButtonsContainer}>
      <Button
        onClick={handleCancelTrackerSettings}
        className={classes.trackerSettingsCancelButton}
      >
        Cancel
      </Button>
      <Button
        type="submit"
        onClick={handleSaveTrackerSettings}
        className={classes.trackerSettingsSaveButton}
        disabled={areNewTrackerSettingsUpdating || (isCalendarMode && !hasAddedCalendarEvents)}
      >
        {areNewTrackerSettingsUpdating ? 'Saving...' : 'Save'}
      </Button>
    </div>
  );
};

export default SubmitButtons;
