export const ZONE_TYPES = {
  permitted: {
    id: 'permitted',
    name: 'Permitted',
    description: 'Notify when leaving this zone',
    value: false,
  },
  forbidden: {
    id: 'forbidden',
    name: 'Forbidden',
    description: 'Notify when entering this zone',
    value: true,
  },
}

export const ZONE_TYPES_ARRAY = [
  ZONE_TYPES.permitted,
  ZONE_TYPES.forbidden,
];