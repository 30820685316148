import { MODES_ARRAY } from "../../../../Utils/Modes";

const TEMP_SETTINGS = [
  {
    id: 'aGpsOn',
    type: 'bool',
  },
  {
    id: 'accelerometerOn',
    type: 'bool',
  },
  {
    id: 'debug',
    type: 'bool',
  },
  {
    id: 'downloadFirmwareUpdate',
    type: 'bool',
  },
  {
    id: 'forceUpdate',
    type: 'bool',
  },
  {
    id: 'gpsAdditional2GInfo',
    type: 'bool',
  },
  {
    id: 'gpsEphemerisValidFor',
    type: 'int',
  },
  {
    id: 'gpsMaximumHdop',
    type: 'int',
  },
  {
    id: 'gpsMinimumHdop',
    type: 'int',
  },
  {
    id: 'gpsOn',
    type: 'bool',
  },
  {
    id: 'gpsPowerMode',
    type: 'string',
  },
  {
    id: 'gpsWaitTime',
    type: 'int',
  },
  {
    id: 'gsmCellsForTriangulation',
    type: 'int',
  },
  {
    id: 'gtpOn',
    type: 'bool',
  },
  {
    id: 'interval',
    type: 'int',
  },
  {
    id: 'heartbeatType',
    type: 'string',
  },
  {
    id: 'pursuitInterval',
    type: 'int',
  },
  {
    id: 'mode',
    type: MODES_ARRAY.map(mode => mode.id),
    // type: 'string',
  },
  {
    id: 'smsc',
    type: 'string',
  },
  {
    id: 'tamperOn',
    type: 'bool',
  },
  {
    id: 'uhfRadioOn',
    type: 'bool',
  },
  {
    id: 'vhfRadioOn',
    type: 'bool',
  },
  {
    id: 'wifiOn',
    type: 'bool',
  },
];

export default TEMP_SETTINGS;