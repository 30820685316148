import { connect } from 'react-redux';

import {
  getFranchise,
  getAllFranchises,
  editingFranchise,
  getFranchiseFolder,
  createNewFranchise,
  loadFranchiseFolder,
  cancelEditFranchise,
  editFranchiseRequest,
  resetFranchiseSecret,
  deleteFranchiseRequest,
  createNewFranchiseFolder,
  getFranchiseFolderParent,
  editFranchiseSecretRequest,
  cancelCreatingNewFranchise,
  generateNewFranchiseSecret,
} from '../../redux/Actions/FranchiseActions';

export default (WrappedComponent) => connect(null, {
  getFranchise,
  getAllFranchises,
  editingFranchise,
  getFranchiseFolder,
  createNewFranchise,
  cancelEditFranchise,
  loadFranchiseFolder,
  editFranchiseRequest,
  resetFranchiseSecret,
  deleteFranchiseRequest,
  createNewFranchiseFolder,
  getFranchiseFolderParent,
  editFranchiseSecretRequest,
  cancelCreatingNewFranchise,
  generateNewFranchiseSecret,
})(WrappedComponent);
