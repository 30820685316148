import React from 'react';
import { compose } from 'redux';

import {
  Box,
} from '@mui/material';

import CustomerUnitsSection from './CustomerUnitsSection';
import RecentUnitsSection from './RecentUnitsSection';
import BookmarkedUnitsSection from './BookmarkedUnitsSection';

import withSystemActions from '../../redux/WithActions/system';
import withTrackerActions from '../../redux/WithActions/tracker';
import withUserData from '../../redux/WithData/user';

const UnitDashboard = ({
  role,
  getCustomerTrackers,
  getRecentAndBookmarkedTrackers,
  setShowSubHeader,
}) => {
  // Set the browser tab's title
  document.title = 'Dashboard - MTrack';

  const [hasStartedLoading, setStartedLoading] = React.useState(false);

  // Clear 'showSubHeader' so the main header renders correctly
  React.useEffect(() => { setShowSubHeader(false); }, [ setShowSubHeader ]);

  // Get units
  React.useEffect(() => {
    getRecentAndBookmarkedTrackers();   // Get recently-viewed & bookmarked trackers
    getCustomerTrackers();              // Gets any trackers associated with the user's customer

    setStartedLoading(true);
  }, [
    getRecentAndBookmarkedTrackers,
    getCustomerTrackers,
    setStartedLoading,
  ]);
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
     <CustomerUnitsSection hasStartedLoading={hasStartedLoading} sx={{ width: '50%', mr: 0.5 }}/>

     <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%', ml: 0.5 }}>
      <RecentUnitsSection hasStartedLoading={hasStartedLoading} sx={{ height: '50%', mb: 0.5 }}/>
      <BookmarkedUnitsSection hasStartedLoading={hasStartedLoading} sx={{ height: '50%', mt: 0.5 }}/>
     </Box>
    </Box>
  );
};

export default compose(withSystemActions, withTrackerActions, withUserData)(UnitDashboard);