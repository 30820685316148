import React from 'react';

import {
  Box,
  Stack,
} from '@mui/material';

import PositionsSection from './PositionsSection';
import ZonesSection from './ZonesSection';
import ZoneSection_NEW from './ZoneSection';
import MapSection from './MapSection';
import MapSection_NEW from './MapSection_NEW';

import { useWindowDimensions } from '../../../../Utils/ScreenSize';
import { NAV_TYPES } from '../../../../Utils/NavTypes';
import { SHAPES, SHAPES_ARRAY } from './zoneShapes';

const ZONES = [
  {
    id: 100,
    name: 'Zone 1',
    forbidden: false,
    path: [
      { lat: 51.001, lng: -0.945 },
      { lat: 51.001, lng: -0.943 },
      { lat: 51.002, lng: -0.944 },
      { lat: 51.003, lng: -0.944 }
    ],
  },
  {
    id: 101,
    name: 'Zone 2',
    forbidden: true,
    path: [
      { lat: 51.001, lng: -0.952 },
      { lat: 51.000, lng: -0.949 },
      { lat: 51.001, lng: -0.949 },
      { lat: 51.003, lng: -0.951 }
    ],
  },
  {
    id: 102,
    name: 'Zone 3',
    forbidden: false,
    path: [
      { lat: 51.007, lng: -0.946 },
      { lat: 51.007, lng: -0.947 },
      { lat: 51.006, lng: -0.948 },
      { lat: 51.005, lng: -0.947 }
    ],
  },
];

const DEFAULT_NEW_PATH = [
  { lat: 51.002, lng: -0.949 },
  { lat: 51.003, lng: -0.947 },
  { lat: 51.004, lng: -0.948 },
  { lat: 51.003, lng: -0.949 }
];

const MapDetailsTab = () => {
  const { isTabletWidth, isDesktopWidth } = useWindowDimensions();

  const [zones, setZones] = React.useState(ZONES);
  const [highlightedRow, setHighlightedRow] = React.useState(-1);
  const [areZonesVisible, setZoneVisibility] = React.useState(false);
  const [newZone, setNewZone] = React.useState({
    name: '',
    shape: SHAPES.rectangle.id,
    forbidden: false,
    path: [],
  });

  const visibleZones = areZonesVisible ? zones : [];

  /*
    Editable polygon path code derived from:
    https://codesandbox.io/s/reactgooglemapsapi-editing-a-polygon-popr2?file=/src/index.js
    https://stackoverflow.com/a/68230807
  */

  // Define refs for Polygon instance and listeners
  const polygonRef = React.useRef(null);
  const listenersRef = React.useRef([]);

  // Call setPath with new edited path
  const handleEditPath = React.useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map(latLng => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });

        setNewZone({
          ...newZone,
          path: nextPath,
        })
    }
  }, [newZone, setNewZone]);

  // Bind refs to current Polygon and listeners
  const handleLoadPolygon = React.useCallback(
    polygon => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", handleEditPath),
        path.addListener("insert_at", handleEditPath),
        path.addListener("remove_at", handleEditPath)
      );
    },
    [handleEditPath]
  );

  // Clean up refs
  const handleUnmountPolygon = React.useCallback(() => {
    listenersRef.current.forEach(lis => lis.remove());
    polygonRef.current = null;
  }, []);

  const handleToggleZoneVisibility = () => {
    setZoneVisibility(!areZonesVisible);
  }

  const handleDeleteZone = (rowIdx) => {
    const zoneToDelete = zones[rowIdx];

    setZones(zones.filter((zone) => zone !== zoneToDelete));
  }

  const handleClickCreateNewZone = () => {
    setNewZone({
      name: '',
      shape: SHAPES.rectangle.id,
      forbidden: false,
      path: DEFAULT_NEW_PATH,
    });
  }

  const handleClickSaveNewZone = () => {
    setZones([
      ...zones,
      newZone
    ]);

    setNewZone({
      name: '',
      shape: SHAPES.rectangle.id,
      forbidden: false,
      path: [],
    });
  }

  const handleClickCancelNewZone = () => {
    setNewZone({
      name: '',
      forbidden: false,
      path: [],
    });
  }
  
  // Desktop View
  if (isDesktopWidth) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
        <Box sx={{ width: '60%', minWidth: '600px', display: 'flex', flexDirection: 'column', mr: 0.5 }}>
          <PositionsSection sx={{ height: '100%', mb: 1 }} />
          <ZoneSection_NEW sx={{ mb: 1 }}/>
          {/* <ZonesSection
            zones={zones}
            newZone={newZone}
            areZonesVisible={areZonesVisible}
            setNewZone={setNewZone}
            setHighlightedRow={setHighlightedRow}
            onToggleZoneVisibility={handleToggleZoneVisibility}
            onDeleteZone={handleDeleteZone}
            onClickCreateNewZone={handleClickCreateNewZone}
            onClickSaveNewZone={handleClickSaveNewZone}
            onClickCancelNewZone={handleClickCancelNewZone}
          /> */}
        </Box>

        <MapSection_NEW sx={{ height: '100%', width: '100%', ml: 0.5 }}/>
        {/* <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <MapSection_NEW
            sx={{ height: '50%', width: '100%', ml: 0.5 }}
          />
          <MapSection
            zones={visibleZones}
            hightlightedZoneIdx={highlightedRow}
            newZonePath={newZone.path}
            onEditPath={handleEditPath}
            onLoadEditablePolygon={handleLoadPolygon}
            onUnmountEditablePolygon={handleUnmountPolygon}
            sx={{ height: '50%', width: '100%', ml: 0.5 }}
          />
        </Box> */}
      </Box>
    );
  }
  // Tablet/Mobile View
  else {
    return (
      <Stack direction="column" spacing={2} sx={isTabletWidth ? { m: 2 } : { mt: 2 }}>
        <MapSection
          zones={visibleZones}
          hightlightedZoneIdx={highlightedRow}
          newZonePath={newZone.path}
          onEditPath={handleEditPath}
          onLoadEditablePolygon={handleLoadPolygon}
          onUnmountEditablePolygon={handleUnmountPolygon}
        />
        <PositionsSection/>
        <ZonesSection
          zones={zones}
          newZone={newZone}
          areZonesVisible={areZonesVisible}
          setNewZone={setNewZone}
          setHighlightedRow={setHighlightedRow}
          onToggleZoneVisibility={handleToggleZoneVisibility}
          onDeleteZone={handleDeleteZone}
          onClickCreateNewZone={handleClickCreateNewZone}
          onClickSaveNewZone={handleClickSaveNewZone}
          onClickCancelNewZone={handleClickCancelNewZone}
        />
      </Stack>
    );
  }
}

export default MapDetailsTab;