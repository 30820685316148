import { handleActions } from 'redux-actions';

import { logout } from '../Actions/AuthActions';
import {
  editFranchise,
  deleteFranchise,
  editingFranchise,
  loadNewFranchise,
  loadAllFranchises,
  deletingFranchise,
  editFranchiseSecret,
  loadFranchiseFolder,
  cancelEditFranchise,
  loadingAllFranchises,
  resetFranchiseSecret,
  creatingNewFranchise,
  cancelDeleteFranchise,
  loadNewFranchiseSecret,
  loadingFranchiseFolder,
  loadNewFranchiseFolder,
  stopLoadingAllFranchises,
  cancelCreatingNewFranchise,
  creatingNewFranchiseFolder,
  generatingNewFranchiseSecret,
} from '../Actions/FranchiseActions';

const initialState = {
  franchises: [],
  franchiseFolder: {
    parentFolder: '',
    subfolders: [],
  },
  franchiseSecret: '',
  isEditingFranchise: false,
  isDeletingFranchise: false,
  areFranchisesLoading: false,
  isFranchiseFolderLoading: false,
  isCreatingNewFranchiseFolder: false,
  isGeneratingNewFranchiseSecret: false,
};

export default handleActions({
  [logout]: () => initialState,
  [loadingAllFranchises]: (state) => ({
    ...state,
    areFranchisesLoading: true,
  }),
  [stopLoadingAllFranchises]: (state) => ({
    ...state,
    areFranchisesLoading: false,
  }),
  [loadAllFranchises]: (state, { payload: { franchises } }) => ({
    ...state,
    franchises,
    areFranchisesLoading: false,
  }),
  [loadingFranchiseFolder]: (state) => ({
    ...state,
    isFranchiseFolderLoading: true,
  }),
  [loadFranchiseFolder]: (state, { payload: { franchiseFolder } }) => ({
    ...state,
    franchiseFolder,
    isFranchiseFolderLoading: false,
  }),
  [creatingNewFranchiseFolder]: (state) => ({
    ...state,
    isCreatingNewFranchiseFolder: true,
  }),
  [loadNewFranchiseFolder]: (state) => ({
    ...state,
    isCreatingNewFranchiseFolder: false,
  }),
  [generatingNewFranchiseSecret]: (state) => ({
    ...state,
    isGeneratingNewFranchiseSecret: true,
  }),
  [loadNewFranchiseSecret]: (state, { payload: { newFranchiseSecret } }) => ({
    ...state,
    franchiseSecret: newFranchiseSecret,
    isGeneratingNewFranchiseSecret: false,
  }),
  [creatingNewFranchise]: (state) => ({
    ...state,
    isCreatingNewFranchise: true,
  }),
  [cancelCreatingNewFranchise]: (state) => ({
    ...state,
    franchiseSecret: '',
    isCreatingNewFranchise: false,
  }),
  [loadNewFranchise]: (state, { payload: { newFranchise } }) => ({
    ...state,
    franchises: [
      newFranchise,
      ...state.franchises,
    ],
    franchiseSecret: '',
    isCreatingNewFranchise: false,
  }),
  [deletingFranchise]: (state) => ({
    ...state,
    isDeletingFranchise: true,
  }),
  [cancelDeleteFranchise]: (state) => ({
    ...state,
    isDeletingFranchise: false,
  }),
  [deleteFranchise]: (state, { payload: { franchiseId } }) => ({
    ...state,
    franchises: state.franchises.filter((franchise) => franchise.id !== franchiseId),
    isDeletingFranchise: false,
  }),
  [editingFranchise]: (state) => ({
    ...state,
    isEditingFranchise: true,
  }),
  [cancelEditFranchise]: (state) => ({
    ...state,
    franchiseSecret: '',
    isEditingFranchise: false,
  }),
  [resetFranchiseSecret]: (state) => ({
    ...state,
    franchiseSecret: '',
  }),
  [editFranchise]: (state, { payload: { updatedFranchise } }) => ({
    ...state,
    franchises: state.franchises
      .map((franchise) => (franchise.id === updatedFranchise.id
        ? updatedFranchise
        : franchise
      )),
    franchiseSecret: '',
    isEditingFranchise: false,
  }),
  [editFranchiseSecret]: (state, { payload: { franchiseId, newSecret } }) => ({
    ...state,
    franchises: state.franchises
      .map((franchise) => (franchise.id === franchiseId
        ? {
          ...franchise,
          secret: newSecret,
        }
        : franchise
      )),
    franchiseSecret: '',
    isEditingFranchise: false,
  }),
}, initialState);
