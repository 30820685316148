import React from 'react';
import { compose } from 'redux';
import validator from 'validator';

import {
  DialogActions,
  DialogContent,
} from '@mui/material';

import AccountDetails from './AccountDetails';
import DefaultRole from './DefaultRole';
import AssignAccount from './AssignAccount';
import SectionDivider from '../../Components/Dialog/SectionDivider';
import Submit from '../../Components/Inputs/SubmitButton';

import { isAccountRoleRoot, isAccountRoleAdmin, isAccountRoleUser } from '../../../Utils/Utils';

import withUserData from '../../../redux/WithData/user';
import withCustomerData from '../../../Hocs/WithData/customer';
import withFranchiseData from '../../../Hocs/WithData/franchise';
import withAccountActions from '../../../Hocs/WithAction/account';
import withCustomerActions from '../../../Hocs/WithAction/customer';
import withFranchiseActions from '../../../Hocs/WithAction/franchise';

const AddNewAccountDialogContent = ({
  role,
  customers,
  franchises,
  getAllCustomers,
  getAllFranchises,
  createNewAccount,
  isCreatingNewAccount,
  franchise: { id: franchiseId, name: franchiseName },
  handleClose = () => {},
}) => {
  const [values, setValues] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    timezone: 'Europe/London',
    role: role,
    assignOption: isAccountRoleRoot(role) ? 0 : 1,
    selectedCustomer: 0,
    selectedFranchise: 0,
    errors: {}
  });

  const handleChange = ({ target }) => {
    setValues({
      ...values,
      [target.name]: target.value
    });
  };

  const currentFranchiseCustomers = customers.filter(
    (customer) => customer.franchise.uuid === franchiseId
  );
  
  const form = {
    firstName: values.firstName.length !== 0,
    lastName: values.lastName.length !== 0,
    email: validator.isEmail(values.email),
    assign: values.assignOption === 0
      // Prevents the creation of an account without a customer
      || (values.assignOption === 1 && currentFranchiseCustomers.length !== 0)
      || (isAccountRoleUser(role)
          && values.assignOption === 1
          && currentFranchiseCustomers.length === 0),
  };

  const canBeSaved = !Object.values(form).some((value) => value === false);

  const handleSave = () => {
    let newAccountDetails = {
      firstName: values.firstName,
      lastName: values.lastName,
      username: values.email,
      timezone: values.timezone,
      role: values.role,
    };

    if (values.assignOption === 0) {
      newAccountDetails = {
        ...newAccountDetails,
        franchise: {
          name: isAccountRoleAdmin(role) ? franchiseName : franchises[values.selectedFranchise].name,
          uuid: isAccountRoleAdmin(role) ? franchiseId : franchises[values.selectedFranchise].id,
        },
      };
    } else if (!isAccountRoleUser(role)) {
      newAccountDetails = {
        ...newAccountDetails,
        customer: {
          name: currentFranchiseCustomers[values.selectedCustomer].name,
          uuid: currentFranchiseCustomers[values.selectedCustomer].id,
        },
      };
    }

    createNewAccount({ newAccountDetails });
    handleClose();
  };

  return (
    <>
      <DialogContent dividers>
        <AccountDetails
          values={values}
          setValues={setValues}
          handleChange={handleChange} />
        {(!isAccountRoleUser(role)) && (
          <>
            <SectionDivider/>
            <DefaultRole
              role={role}
              values={values}
              handleChange={handleChange} />
            <SectionDivider/>
            <AssignAccount
              role={role}
              franchise={{ id: franchiseId, name: franchiseName }}
              franchises={franchises}
              customers={currentFranchiseCustomers}
              values={values}
              setValues={setValues}
              handleChange={handleChange}
              getAllFranchises={getAllFranchises}
              getAllCustomers={getAllCustomers} />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Submit
          buttonText="Save"
          disabled={!canBeSaved}
          loading={isCreatingNewAccount}
          onClick={handleSave} />
      </DialogActions>
    </>
  );
};

export default compose(
  withUserData,
  withCustomerData,
  withFranchiseData,
  withAccountActions,
  withCustomerActions,
  withFranchiseActions,
)(AddNewAccountDialogContent);