import { connect } from 'react-redux';

const mapStateToProps = ({
  // Data in the Store
  SystemData_NEW: {
    appTheme,
    appLanguage,
    showingSubHeader,

    notifications,
  }
}) => ({
  // Props for the Component
  appTheme,
  appLanguage,
  showingSubHeader,
  
  notifications,
})

export default (WrappedComponent) => connect(mapStateToProps, null)(WrappedComponent);