import { compose } from 'redux';
import React, { useEffect } from 'react';

import {
  List,
  ListItem,
  Typography,
  ListItemText,
  CircularProgress,
} from '@mui/material';

import customersListStyles from './style';
import EditCustomerModal from '../EditCustomerModal/EditCustomerModal';
import { isAccountRoleAdmin, isAccountRoleRoot, isAccountRoleUser } from '../../../Utils/Utils';

import withUserData from '../../../redux/WithData/user';
import withCustomerData from '../../../Hocs/WithData/customer';
import withCustomerActions from '../../../Hocs/WithAction/customer';

function CustomersList({
  customer: { id: customerId },
  areCustomersLoading,
  getAllCustomers,
  getCustomer,
  customers,
  role,
}) {
  const classes = customersListStyles();

  useEffect(() => {
    if (isAccountRoleRoot(role) || isAccountRoleAdmin(role)) getAllCustomers();
  }, [getAllCustomers, role]);

  useEffect(() => {
    if (isAccountRoleUser(role) && customerId) getCustomer({ customerId });
  }, [customerId, getCustomer, role]);

  const spinner = (
    <div className={classes.spinnerContainer}>
      <CircularProgress />
    </div>
  );

  const noCustomers = (
    <div className={classes.emptyCustomerList}>
      <Typography className={classes.emptyCustomerListHeader}>
        No customers have been added yet.
      </Typography>
    </div>
  );

  if (areCustomersLoading) return spinner;
  if (customers.length === 0) return noCustomers;

  return (
    <>
      <List className={classes.customerList}>
        {customers.map((customer) => (
          <ListItem
            key={customer.id}
            className={classes.customerListItem}
          >
            <ListItemText
              primary={customer.companyName || customer.name}
              className={classes.customerListItemText}
            />
            <div>
              <EditCustomerModal customer={customer} />
            </div>
          </ListItem>
        ))}
      </List>
    </>
  );
}

export default compose(withCustomerData, withCustomerActions, withUserData)(CustomersList);
