import { styled } from '@mui/material/styles';
import {
  Divider,
} from '@mui/material';

const SectionDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export default SectionDivider;