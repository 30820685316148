import React from 'react';

import EssentialIcon from '@mui/icons-material/NotificationsActive';
import {
  Card,
  List,
  Switch,
  Divider,
  ListItem,
  FormGroup,
  Container,
  Typography,
  CardContent,
  FormControlLabel,
} from '@mui/material';

import {
  locationTypes,
  extractHoursFromTimeInterval,
  extractMinutesFromTimeInterval,
} from '../trackerSettingsHelpers';

const CurrentTrackerSettings = ({ classes, currentSettings }) => {
  const isCalendarMode = currentSettings.heartbeatType === 'CAL';

  const hours = extractHoursFromTimeInterval(currentSettings.interval);
  const minutes = extractMinutesFromTimeInterval(currentSettings.interval);

  return (
    <Card className={classes.trackerSettings}>
      <CardContent>
        <Typography className={classes.trackerSettingsHeader}>
          Current Tracker Settings
        </Typography>
        <Typography className={classes.trackerSettingsSubHeader}>
          The settings shown below are the current settings of the device.
        </Typography>
        <FormGroup className={classes.trackerSettingsToggleContainer}>
          <FormControlLabel
            control={(<Switch checked={currentSettings.debug} disabled />)}
            className={classes.trackerSettingsToggle}
            labelPlacement="start"
            label="Debug"
          />
          <FormControlLabel
            control={(<Switch checked={currentSettings.forceUpdate} disabled />)}
            className={classes.trackerSettingsToggle}
            labelPlacement="start"
            label="Force Update"
          />
        </FormGroup>
        <Divider className={classes.trackerSettingsDivider} />
        <Container className={classes.trackerSettingsFieldContainer}>
          <Typography className={classes.trackerSettingsFieldLabel}>
            Schedule Mode
          </Typography>
          <Typography className={classes.trackerSettingsFieldValue}>
            {isCalendarMode ? 'Calendar' : 'Interval'}
          </Typography>
        </Container>
        <Container className={classes.trackerSettingsFieldContainer}>
          <Typography className={classes.trackerSettingsFieldLabel}>
            {isCalendarMode ? 'Timezone' : 'Time Interval'}
          </Typography>
          {isCalendarMode && (
            <Typography className={classes.trackerSettingsFieldValue}>
              {currentSettings.timezone}
            </Typography>
          )}
          {!isCalendarMode && (
          <Typography className={classes.trackerSettingsFieldValue}>
            { `Every ${hours === 1 ? 'hour' : `${hours} hours`} and ${minutes === 1 ? `${minutes} minute` : `${minutes} minutes`}` }
          </Typography>
          )}
        </Container>
        {isCalendarMode && (
        <List>
          {currentSettings.calendarEvents.map((calendarEvent) => (
            <ListItem
              key={calendarEvent.id}
              className={classes.trackerSettingsCalendarEventListItemCurrentSettings}
            >
              {calendarEvent.essential && (
                <EssentialIcon
                  className={classes.trackerSettingsCalendarEventEssentialIcon}
                />
              )}
              <Typography className={classes.trackerSettingsCalendarEventTimeLabel}>
                {`${calendarEvent.day} ${calendarEvent.time}`}
              </Typography>
              <Typography
                className={classes.trackerSettingsCalendarEventLocationLabel}
                style={{ backgroundColor: calendarEvent.backgroundColor }}
              >
                {locationTypes.find((type) => type.id === calendarEvent.locationType).name}
              </Typography>
            </ListItem>
          ))}
        </List>
        )}
      </CardContent>
    </Card>
  );
};

export default CurrentTrackerSettings;
