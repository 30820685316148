import { compose } from 'redux';
import React, { useState } from 'react';

import {
  Button,
  TextField,
  Typography,
} from '@mui/material';

import validator from 'validator';
import editCustomerModalContentStyles from './style';
import { isAccountRoleUser } from '../../../../Utils/Utils';

import withUserData from '../../../../redux/WithData/user';
import withCustomerData from '../../../../Hocs/WithData/customer';
import withCustomerActions from '../../../../Hocs/WithAction/customer';

const EditCustomerModalContent = ({
  role,
  handleClose,
  customerSecret,
  isEditingCustomer,
  cancelEditCustomer,
  editCustomerRequest,
  generateNewCustomerSecret,
  currentCustomer: customer,
  isGeneratingNewCustomerSecret,
}) => {
  const classes = editCustomerModalContentStyles();

  const [customerDetails, setCustomerDetails] = useState({
    id: customer.id,
    name: customer.name,
    secret: customerSecret || customer.secret,
    number: customer.number || '',
    contact: {
      name: customer.contact?.name || '',
      email: customer.contact?.email || '',
      phone: customer.contact?.phone || '',
    },
    companyName: customer.companyName || '',
    emergencyDetails: {
      name: customer.emergencyDetails?.[0].name || '',
      email: customer.emergencyDetails?.[0].email || '',
      phone: customer.emergencyDetails?.[0].phone || '',
    },
    defaultRole: 'USER',
    address: {
      addressLine1: customer.address?.addressLine1 || '',
      addressLine2: customer.address?.addressLine2 || '',
      addressLine3: customer.address?.addressLine3 || '',
      city: customer.address?.city || '',
      country: customer.address?.country || '',
      postcode: customer.address?.postcode || '',
    },
  });

  const handleChangeCustomerDetails = ({ target: { name, value } }) => {
    setCustomerDetails({
      ...customerDetails,
      [name]: value,
    });
  };

  const handleGenerateSecretCode = () => {
    generateNewCustomerSecret();
  };

  const handleChangeCustomerAddressDetails = ({ target: { name, value } }) => {
    setCustomerDetails({
      ...customerDetails,
      address: {
        ...customerDetails.address,
        [name]: value,
      },
    });
  };

  const handleChangeCustomerContactDetails = ({ target: { name, value } }) => {
    setCustomerDetails({
      ...customerDetails,
      contact: {
        ...customerDetails.contact,
        [name]: value,
      },
    });
  };

  const handleChangeEmergencyContactDetails = ({ target: { name, value } }) => {
    setCustomerDetails({
      ...customerDetails,
      emergencyDetails: {
        ...customerDetails.emergencyDetails,
        [name]: value,
      },
    });
  };

  const handleSave = () => {
    const {
      id,
      name,
      secret,
      number,
      contact,
      address,
      companyName,
      defaultRole,
      emergencyDetails,
    } = customerDetails;

    const updatedCustomerDetails = {
      id,
      name,
      number,
      contact,
      address,
      companyName,
      defaultRole,
      secret: customerSecret || secret,
      emergencyDetails: [emergencyDetails],
    };
    editCustomerRequest({ updatedCustomerDetails });
    customerSecret = '';
    handleClose();
  };

  const handleCancel = () => {
    cancelEditCustomer();
    handleClose();
  };

  const customerDetailsForm = (
    <>
      <Typography variant="h4" className={classes.editCustomerModalSectionHeader}>
        Customer Details
      </Typography>
      <div className={classes.editCustomerModalInputFieldContainer}>
        <TextField
          autoFocus
          fullWidth
          size="small"
          variant="outlined"
          name="companyName"
          label="Company Name (Optional)"
          value={customerDetails.companyName}
          onChange={handleChangeCustomerDetails}
          className={classes.editCustomerModalInputField}
        />
      </div>
      <div className={classes.editCustomerModalInputFieldContainer}>
        <TextField
          required
          fullWidth
          name="name"
          size="small"
          variant="outlined"
          label="Customer Name"
          value={customerDetails.name}
          disabled={isAccountRoleUser(role)}
          onChange={handleChangeCustomerDetails}
          className={classes.editCustomerModalInputField}
        />
      </div>
      <div className={classes.editCustomerModalInputFieldContainer}>
        <TextField
          required
          fullWidth
          size="small"
          key="Secret Code"
          variant="outlined"
          label="Secret Code"
          value={customerSecret || customerDetails.secret}
          disabled={isGeneratingNewCustomerSecret}
          className={`
              ${classes.editCustomerModalInputField}
              ${classes.editCustomerModalInputFieldWithButton}`}
        />
        <div className={classes.editCustomerModalInputFieldButtonContainer}>
          <Button
            disabled={isGeneratingNewCustomerSecret}
            onClick={handleGenerateSecretCode}
            className={classes.editCustomerModalInputFieldButton}
          >
            {isGeneratingNewCustomerSecret ? 'Generating...' : 'Generate Code'}
          </Button>
        </div>
      </div>
      {!isAccountRoleUser(role) && (
        <div className={classes.editCustomerModalInputFieldContainer}>
          <TextField
            fullWidth
            size="small"
            name="number"
            variant="outlined"
            label="Number (For accounting)"
            value={customerDetails.number}
            onChange={handleChangeCustomerDetails}
            className={classes.editCustomerModalInputField}
          />
        </div>
      )}
      <Typography variant="h4" className={classes.editCustomerModalSectionHeader}>
        Customer Address
      </Typography>
      <div className={classes.editCustomerModalInputFieldContainer}>
        <TextField
          required
          fullWidth
          size="small"
          variant="outlined"
          name="addressLine1"
          label="Address Line 1"
          value={customerDetails.address.addressLine1}
          onChange={handleChangeCustomerAddressDetails}
          className={classes.editCustomerModalInputField}
        />
      </div>
      <div className={classes.editCustomerModalInputFieldContainer}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="addressLine2"
          label="Address Line 2"
          value={customerDetails.address.addressLine2}
          onChange={handleChangeCustomerAddressDetails}
          className={classes.editCustomerModalInputField}
        />
      </div>
      <div className={classes.editCustomerModalInputFieldContainer}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="addressLine3"
          label="Address Line 3"
          value={customerDetails.address.addressLine3}
          onChange={handleChangeCustomerAddressDetails}
          className={classes.editCustomerModalInputField}
        />
      </div>
      <div className={classes.editCustomerModalInputFieldContainer}>
        <TextField
          required
          fullWidth
          name="city"
          size="small"
          label="City"
          variant="outlined"
          value={customerDetails.address.city}
          onChange={handleChangeCustomerAddressDetails}
          className={classes.editCustomerModalInputField}
        />
      </div>
      <div className={classes.editCustomerModalInputFieldContainer}>
        <TextField
          required
          fullWidth
          size="small"
          name="country"
          label="Country"
          variant="outlined"
          value={customerDetails.address.country}
          onChange={handleChangeCustomerAddressDetails}
          className={classes.editCustomerModalInputField}
        />
      </div>
      <div className={classes.editCustomerModalInputFieldContainer}>
        <TextField
          required
          fullWidth
          size="small"
          name="postcode"
          variant="outlined"
          label="Postcode / Zipcode"
          value={customerDetails.address.postcode}
          onChange={handleChangeCustomerAddressDetails}
          className={classes.editCustomerModalInputField}
        />
      </div>
      <Typography variant="h4" className={classes.editCustomerModalSectionHeader}>
        Contact Details
      </Typography>
      <div className={classes.editCustomerModalInputFieldContainer}>
        <TextField
          required
          fullWidth
          name="name"
          size="small"
          variant="outlined"
          label="Contact Name"
          value={customerDetails.contact.name}
          onChange={handleChangeCustomerContactDetails}
          className={classes.editCustomerModalInputField}
        />
      </div>
      <div className={classes.editCustomerModalInputFieldContainer}>
        <TextField
          fullWidth
          size="small"
          name="email"
          type="email"
          variant="outlined"
          label="Contact Email"
          id="outlined-helperText"
          value={customerDetails.contact.email}
          onChange={handleChangeCustomerContactDetails}
          className={classes.editCustomerModalInputField}
          error={!validator.isEmail(customerDetails.contact.email) && customerDetails.contact.email !== ''}
          helperText={!validator.isEmail(customerDetails.contact.email) && customerDetails.contact.email !== ''
            ? 'Please enter a valid email format' : ''}
        />
      </div>
      <div className={classes.editCustomerModalInputFieldContainer}>
        <TextField
          fullWidth
          size="small"
          name="phone"
          variant="outlined"
          label="Contact Phone Number"
          value={customerDetails.contact.phone}
          onChange={handleChangeCustomerContactDetails}
          className={classes.editCustomerModalInputField}
        />
      </div>
      <Typography variant="h4" className={classes.editCustomerModalSectionHeader}>
        Emergency Contact Details
      </Typography>
      <div className={classes.editCustomerModalInputFieldContainer}>
        <TextField
          required
          fullWidth
          name="name"
          size="small"
          variant="outlined"
          label="Emergency Contact Name"
          value={customerDetails.emergencyDetails.name}
          onChange={handleChangeEmergencyContactDetails}
          className={classes.editCustomerModalInputField}
        />
      </div>
      <div className={classes.editCustomerModalInputFieldContainer}>
        <TextField
          fullWidth
          size="small"
          name="email"
          type="email"
          variant="outlined"
          id="outlined-helperText"
          label="Emergency Contact Email"
          value={customerDetails.emergencyDetails.email}
          onChange={handleChangeEmergencyContactDetails}
          className={classes.editCustomerModalInputField}
          error={!validator.isEmail(customerDetails.emergencyDetails.email) && customerDetails.emergencyDetails.email !== ''}
          helperText={!validator.isEmail(customerDetails.emergencyDetails.email) && customerDetails.emergencyDetails.email !== ''
            ? 'Please enter a valid email format' : ''}
        />
      </div>
      <div className={classes.editCustomerModalInputFieldContainer}>
        <TextField
          fullWidth
          size="small"
          name="phone"
          variant="outlined"
          label="Emergency Contact Phone Number"
          value={customerDetails.emergencyDetails.phone}
          onChange={handleChangeEmergencyContactDetails}
          className={classes.editCustomerModalInputField}
        />
      </div>
    </>
  );

  const submitButtons = (
    <div className={classes.editCustomerModalButtonsContainer}>
      <Button
        onClick={handleCancel}
        className={classes.editCustomerModalCancelButton}
      >
        Cancel
      </Button>
      <Button
        type="submit"
        onClick={handleSave}
        className={classes.editCustomerModalSaveButton}
        disabled={!customerDetails.name || isEditingCustomer}
      >
        {isEditingCustomer ? 'Saving...' : 'Save'}
      </Button>
    </div>
  );

  return (
    <div className={classes.editCustomerModalContentContainer}>
      {customerDetailsForm}
      {submitButtons}
    </div>
  );
};

export default compose(
  withUserData,
  withCustomerData,
  withCustomerActions,
)(EditCustomerModalContent);
