import React from 'react';
import { compose } from 'redux';

import { WatchLater, Lock } from '@mui/icons-material';

import CustomModal from '../Components/Modal';
import TimezoneSelector from './Components/TimezoneSelector';
import ModalContent from '../Components/Modal/ModalContent/ModalContent';

import withUserActions from '../../redux/WithActions/user';

const actionList = ({ setUserPassword }) => {
  const handleChangePassword = async (password) => {
    setUserPassword(password);
  };

  return (
    <div className="userActionList-wrapper">
      <div className="userAction-item">
        <Lock />
        <CustomModal
          openWith="textButton"
          title="Change Password"
          btnText="Change Password"
          className="change-password"
          body={(handleClose) => (
            <ModalContent
              modalAction="Change"
              handleClose={handleClose}
              handleConfirm={handleChangePassword}
              inputFields={[{ id: 'password', label: 'New Password' }]}
            />
          )}
        />
      </div>
      <div className="userAction-item">
        <WatchLater />
        <CustomModal
          openWith="textButton"
          title="Choose Timezone"
          btnText="Change Timezone"
          className="change-timezone"
          body={(handleClose) => (
            <TimezoneSelector handleClose={handleClose} />
          )}
        />
      </div>
    </div>
  );
};

export default compose(withUserActions)(actionList);
