import React from 'react';
import { compose } from 'redux';

import withUserData from '../../../redux/WithData/auth';
import withEventActions from '../../../redux/WithActions/event';
import withWebSocketActions from '../../../Hocs/WithAction/TrackerOnWebSocket';

const StompJS = require('@stomp/stompjs');

const DEBUG_TO_IGNORE = [
  '>>> PING',
  '<<< PONG',
  'Received data',
];

const onDebugHandler = (...message) => {
  // Filter out the spam but leave useful debug (for now)
  message = message.filter((msg) => !DEBUG_TO_IGNORE.includes(msg))

  if (message.length > 0) {
    console.log(...message);
  }
};

const onCloseHandler = () => {
  console.log('Socket closed.');
};

const onErrorHandler = (error) => {
  console.warn(error);
};

const onMessageHandler = (msg, appendEvent) => {
  const event = JSON.parse(msg.body);

  if (event.objectType === 'trackerEvent') {
    appendEvent(event);
  }
};

const onConnectionHandler = (ws, tracker, appendEvent) => {
  ws.subscribe(
    `/exchange/AUTOMATRICS.deck42.exchange.websockets.tracker.event.1/${tracker}`,
    (msg) => onMessageHandler(msg, appendEvent),
  );
};

const TrackerEventsWS = ({
  trackerId,
  accessToken,
  receiveEventFromWS,
}) => {
  const apiServerWS = `wss://@${process.env.REACT_APP_WEBSOCKET_BASE_URI}/api/v1/secure/ws`;
  const access = accessToken.replace(' ', '.');

  // TODO: update to newer version of Stomp: https://stomp-js.github.io/guide/stompjs/upgrading-stompjs.html
  const client = StompJS.Stomp.client(apiServerWS, [
    'v10.stomp',
    'v11.stomp',
    'v12.stomp',
    access,
  ]);
  
  client.onConnect = () => onConnectionHandler(client, trackerId, receiveEventFromWS);
  client.onDisconnect = onCloseHandler;
  client.onStompError = onErrorHandler;
  client.onWebSocketClose = onCloseHandler;
  client.onWebSocketError = onErrorHandler;

  client.debug = onDebugHandler;

  React.useEffect(() => {
    client.activate();
    return () => {
      if (client && (client.active || client.connected)) {
        client.disconnect();
      }
    };
  }, [
    client,
    client.connected
  ]);

  return <></>;
};

export default compose(withUserData, withEventActions, withWebSocketActions)(TrackerEventsWS);
