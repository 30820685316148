import { connect } from 'react-redux';

import {
  loading,
  toggleView,
  toggleItem,
  shareFolder,
  filterItems,
  removeFilter,
  toggleAllItems,
  toggleMoveItems,
  createNewFolder,
  shareSubfolders,
  moveItemsRequest,
  deselectAllItems,
  getCurrentFolder,
  loadCurrentFolder,
  getMoreFolderItems,
  deleteFolderRequest,
  renameFolderRequest,
  getRootLevelFolders,
  withdrawFolderAccess,
  assignTrackersRequest,
  unassignTrackersRequest,
} from '../../redux/Actions/FolderActions';

export default (WrappedComponent) => connect(
  null, {
    loading,
    toggleView,
    toggleItem,
    shareFolder,
    filterItems,
    removeFilter,
    toggleAllItems,
    createNewFolder,
    toggleMoveItems,
    shareSubfolders,
    moveItemsRequest,
    deselectAllItems,
    getCurrentFolder,
    loadCurrentFolder,
    getMoreFolderItems,
    renameFolderRequest,
    deleteFolderRequest,
    getRootLevelFolders,
    withdrawFolderAccess,
    assignTrackersRequest,
    unassignTrackersRequest,
  },
)(WrappedComponent);
