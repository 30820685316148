import * as React from 'react';
import { styled } from '@mui/material/styles';

import { Link } from 'react-router-dom';

import { STATES } from '../../Utils/States';

const getRowStyle = (theme, state) => ({
  backgroundColor: theme.palette.states[state.id].light,
  borderBottom: `1px solid ${theme.palette.states[state.id].main}`,
  '&:hover': {
    backgroundColor: theme.palette.states[state.id].main,
  },
});

const styles = ({ theme }) => ({
  [`&.${STATES.ALARM.tableClassName}`]: getRowStyle(theme, STATES.ALARM),
  [`&.${STATES.WARNING.tableClassName}`]: getRowStyle(theme, STATES.WARNING),
  [`&.${STATES.ALARM_ACK.tableClassName}`]: getRowStyle(theme, STATES.ALARM_ACK),
  [`&.${STATES.WARNING_ACK.tableClassName}`]: getRowStyle(theme, STATES.WARNING_ACK),
  [`&.${STATES.FAULTY.tableClassName}`]: getRowStyle(theme, STATES.FAULTY),
  [`&.${STATES.NO_CONTACT.tableClassName}`]: getRowStyle(theme, STATES.NO_CONTACT),
  [`&.${STATES.LATE_CONTACT.tableClassName}`]: getRowStyle(theme, STATES.LATE_CONTACT),
  [`&.${STATES.FAULTY_SENT.tableClassName}`]: getRowStyle(theme, STATES.FAULTY_SENT),
  [`&.${STATES.FAULTY_ACK.tableClassName}`]: getRowStyle(theme, STATES.FAULTY_ACK),
  [`&.${STATES.OK.tableClassName}`]: getRowStyle(theme, STATES.OK),
  [`&.${STATES.OFF.tableClassName}`]: getRowStyle(theme, STATES.OFF),
});

const Row = styled('div')(styles);

export default function trackerRowRenderer({
  className,
  columns,
  index,
  key,
  rowData,
  style,
}) {
  const a11yProps = {'aria-rowindex': index + 1};

  const state = STATES[rowData?.state?.mnemonic] || STATES.OFF;

  if (state) {
    className = className + ' ' + state.tableClassName;
  }
  
  return (
    <Link to={`/trackers/${rowData.simiccid}`} target="_blank" rel="noopener noreferrer" key={key}>
      <Row
        {...a11yProps}
        className={className}
        role="row"
        style={style}
      >
        {columns}
      </Row>
    </Link>
  );
}