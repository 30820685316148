import React from 'react';

export default ({
  children, ...rest
}) => {
  const { className } = rest;
  return (
    <div className={`content-wrapper ${className}`}>
      {children}
    </div>
  );
};
