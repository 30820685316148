import { connect } from 'react-redux';

const mapStateToProps = ({
  // Data in the Store
  AuthData_NEW: {
    isLoggedIn,
    isLoggingIn,
    isRegistering,

    accessToken,
    refreshToken,
  }
}) => ({
  // Props for the Component
  isLoggedIn,
  isLoggingIn,
  isRegistering,

  accessToken,
  refreshToken,
})

export default (WrappedComponent) => connect(mapStateToProps, null)(WrappedComponent);