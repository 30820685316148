import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

import {
  IconButton,
} from '@mui/material';

import {
  Bookmark,
  BookmarkBorder,
  Bookmarks,
  BookmarksOutlined,
} from '@mui/icons-material';

const BookmarkButton = ({
  local,  // Current user has bookmarked this unit
  other,  // 1 or more other users have bookmarked this unit
  iconSize,
  onClick,
  sx,
}) => {
  const theme = useTheme();

  let icon;
  let iconSx;

  if (local) {
    iconSx = { fontSize: iconSize, color: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.primary.contrastText }

    icon = other ? <Bookmarks sx={iconSx}/> : <Bookmark sx={iconSx}/>;
  }
  else {
    iconSx = { fontSize: iconSize, color: theme.palette.text.disabled }

    icon = other ? <BookmarksOutlined sx={iconSx}/> : <BookmarkBorder sx={iconSx}/>;
  }

  return (
    <IconButton
      title={local ? 'Remove tracker from bookmarks' : 'Add tracker to bookmarks'}
      onClick={onClick}
      sx={sx}
    >
      {icon}
    </IconButton>
  );
}

BookmarkButton.propTypes = {
  local: PropTypes.bool,
  other: PropTypes.bool,
  onClick: PropTypes.func,
  sx: PropTypes.object,
};

export default BookmarkButton;