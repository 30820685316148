import { compose } from 'redux';
import React, { useState } from 'react';

import {
  Select,
  Button,
  Switch,
  MenuItem,
  TextField,
  Typography,
  FormControl,
  FormControlLabel,
} from '@mui/material';

import addNewScheduleModalContentStyles from './style';
import { days, locationTypes } from '../../../trackerSettingsHelpers';
import withSystemActions from '../../../../../../../redux/WithActions/system';

const AddNewScheduleModalContent = ({
  setNewTrackerSettings,
  newTrackerSettings,
  addNotification,
  handleClose,
}) => {
  const classes = addNewScheduleModalContentStyles();
  const [newSchedule, setNewSchedule] = useState({
    day: 0,
    time: '07:30',
    locationType: 0,
    essential: false,
  });

  const handleChangeDay = ({ target: { value } }) => (
    setNewSchedule({
      ...newSchedule,
      day: value,
    })
  );

  const handleChangeTime = ({ target: { value } }) => (
    setNewSchedule({
      ...newSchedule,
      time: value,
    })
  );

  const handleChangeLocationType = ({ target: { value } }) => (
    setNewSchedule({
      ...newSchedule,
      locationType: value,
    })
  );

  const handleToggleEssential = () => (
    setNewSchedule({
      ...newSchedule,
      essential: !newSchedule.essential,
    })
  );

  const handleAddSchedule = () => {
    const newCalendarEventId = `${days[newSchedule.day].toUpperCase()}-${newSchedule.time}-${locationTypes[newSchedule.locationType].name.toUpperCase()}-${newSchedule.essential}`;

    const doesCalendarEventAlreadyExist = newTrackerSettings.calendarEvents
      .some((calendarEvent) => calendarEvent.id === newCalendarEventId);

    if (doesCalendarEventAlreadyExist) {
      addNotification({
        type: 'error',
        source: 'handleAddSchedule',
        title: 'Schedule already added!',
        message: 'A schedule with the same time, date, location and essential label has already been added.',
      });
    } else {
      const newCalendarEvent = {
        id: newCalendarEventId,
        time: newSchedule.time,
        day: days[newSchedule.day],
        essential: newSchedule.essential,
        locationType: locationTypes[newSchedule.locationType].id,
        backgroundColor: locationTypes[newSchedule.locationType].backgroundColor,
      };
      setNewTrackerSettings({
        ...newTrackerSettings,
        calendarEvents: [
          ...newTrackerSettings.calendarEvents,
          newCalendarEvent,
        ],
      });
    }
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  const dayDropdown = (
    <>
      <Typography variant="h4" className={classes.addNewScheduleModalContentInputLabel}>
        Schedule Day
      </Typography>
      <FormControl variant="outlined" className={classes.addNewScheduleModalContentFormControl}>
        <Select
          value={newSchedule.day}
          onChange={handleChangeDay}
          className={classes.addNewScheduleModalContentDropdown}
        >
          {days.map((day, index) => (
            <MenuItem
              key={day}
              value={index}
            >
              {`Every ${day}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );

  const timeDropdown = (
    <>
      <Typography variant="h4" className={classes.addNewScheduleModalContentInputLabel}>
        Schedule Time
      </Typography>
      <form
        noValidate
        className={`
          ${classes.addNewScheduleModalContentForm}
          ${classes.addNewScheduleModalContentFormControl}`}
      >
        <TextField
          id="time"
          type="time"
          variant="outlined"
          onChange={handleChangeTime}
          className={classes.textField}
          defaultValue={newSchedule.time}
        />
      </form>
    </>
  );

  const locationTypeDropdown = (
    <>
      <Typography variant="h4" className={classes.addNewScheduleModalContentInputLabel}>
        Location Type
      </Typography>
      <FormControl variant="outlined" className={classes.addNewScheduleModalContentFormControl}>
        <Select
          value={newSchedule.locationType}
          onChange={handleChangeLocationType}
          className={classes.addNewScheduleModalContentDropdown}
        >
          {locationTypes.map((locationType, index) => (
            <MenuItem
              value={index}
              key={locationType.name}
            >
              <Typography
                style={{ backgroundColor: locationType.backgroundColor }}
                className={classes.addNewScheduleModalContentLocationType}
              >
                {locationType.name}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );

  const essentialToggle = (
    <FormControlLabel
      label="Essential"
      labelPlacement="start"
      className={classes.addNewScheduleModalContentEssentialToggle}
      control={(
        <Switch
          checked={newSchedule.essential}
          onChange={handleToggleEssential}
        />
      )}
    />
  );

  const submitButtons = (
    <div className={classes.addNewScheduleModalContentButtonsContainer}>
      <Button
        onClick={handleCancel}
        className={classes.addNewScheduleModalContentCancelButton}
      >
        Cancel
      </Button>
      <Button
        type="submit"
        onClick={handleAddSchedule}
        className={classes.addNewScheduleModalContentAddButton}
      >
        Add
      </Button>
    </div>
  );

  return (
    <>
      {dayDropdown}
      {timeDropdown}
      {locationTypeDropdown}
      {essentialToggle}
      {submitButtons}
    </>
  );
};

export default compose(withSystemActions)(AddNewScheduleModalContent);
