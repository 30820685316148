import { compose } from 'redux';
import React, { useEffect, useState } from 'react';

import FolderIcon from '@mui/icons-material/Folder';
import AccessFolderIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Select,
  Button,
  Divider,
  MenuItem,
  TextField,
  Typography,
  IconButton,
  InputLabel,
  FormControl,
} from '@mui/material';

import editFranchiseModalContentStyles from './style';
import { isAccountRoleRoot } from '../../../../Utils/Utils';
import withFoldersData from '../../../../Hocs/WithData/folder';
import withFoldersActions from '../../../../Hocs/WithAction/folder';
import withFranchiseData from '../../../../Hocs/WithData/franchise';
import withFranchiseActions from '../../../../Hocs/WithAction/franchise';

const EditFranchiseModalContent = ({
  role,
  franchise,
  handleClose,
  franchiseFolder,
  franchiseSecret,
  getFranchiseFolder,
  isEditingFranchise,
  loadFranchiseFolder,
  cancelEditFranchise,
  editFranchiseRequest,
  createNewFranchiseFolder,
  isFranchiseFolderLoading,
  getFranchiseFolderParent,
  editFranchiseSecretRequest,
  generateNewFranchiseSecret,
  isCreatingNewFranchiseFolder,
  isGeneratingNewFranchiseSecret,
}) => {
  const classes = editFranchiseModalContentStyles();

  const roles = ['ROOT', 'ADMIN', 'USER'];
  const isFranchiseHomeFolderRoot = franchise.homeFolder?.name === 'root';
  const isFranchiseFolderVirtualRoot = franchiseFolder.name === 'Virtual Root';
  const isParentFolderVirtualRoot = franchiseFolder.parentFolder.name === 'Virtual Root';

  const [newFolderName, setNewFolderName] = useState('');
  const [showFolderIcons, setShowFolderIcons] = useState(false);
  const [franchiseDetails, setFranchiseDetails] = useState({
    homeFolder: 0,
    id: franchise.id,
    name: franchise.name,
    secret: franchise.secret,
    defaultRole: roles.indexOf(franchise.defaultRole),
  });

  useEffect(() => {
    if (isFranchiseHomeFolderRoot) {
      const virtualRoot = {
        id: 'virtual-root',
        name: 'Virtual Root',
        parentFolder: '',
        subfolders: [{
          id: franchise.homeFolder?.uuid || '',
          name: franchise.homeFolder?.name || '',
        }],
      };
      loadFranchiseFolder({ franchiseFolder: virtualRoot });
    } else {
      getFranchiseFolderParent({ currentFolderId: franchise.homeFolder?.uuid });
    }
    // Disabling eslist to allow for an empty dependency array
    // So to trigger this useEffect only on mount
    // *eslint-*disable-next-line *react-*hooks/exhaustive-deps
    // NOTE: this caused errors - the asterisks are there to disable it
  }, []);

  useEffect(() => {
    if (franchiseFolder.parentFolder.id && franchiseFolder.subfolders.length === 0) {
      getFranchiseFolder({ currentFolderId: franchiseFolder.parentFolder.id });
    }
  }, [
    getFranchiseFolder,
    franchiseFolder.parentFolder.id,
    franchiseFolder.subfolders.length,
  ]);

  useEffect(() => {
    const isHomeFolderInFranchiseFolder = franchiseFolder.subfolders
      .some((subfolder) => subfolder.id === franchise.homeFolder?.uuid);
    if (franchiseFolder.parentFolder.id) {
      setFranchiseDetails((details) => ({
        ...details,
        homeFolder: isHomeFolderInFranchiseFolder
          ? franchiseFolder.subfolders
            .findIndex((subfolder) => subfolder.id === franchise.homeFolder?.uuid)
          : 0,
      }));
    }
  }, [franchiseFolder.subfolders, franchiseFolder.parentFolder.id, franchise]);

  useEffect(() => {
    if (franchiseSecret) {
      setFranchiseDetails((details) => ({
        ...details,
        secret: franchiseSecret,
      }));
    }
  }, [franchiseSecret]);

  const handleChangeFranchiseName = ({ target: { value } }) => (
    setFranchiseDetails({
      ...franchiseDetails,
      name: value,
    })
  );

  const handleGenerateSecretCode = () => {
    generateNewFranchiseSecret();
  };

  const handleChangeDefaultRole = ({ target: { value } }) => (
    setFranchiseDetails({
      ...franchiseDetails,
      defaultRole: value,
    })
  );

  const toggleOpenHomeFolderDropdown = () => {
    setShowFolderIcons(!showFolderIcons);
  };

  const handleChangeHomeFolder = ({ target: { value } }) => (
    setFranchiseDetails({
      ...franchiseDetails,
      homeFolder: value,
    })
  );

  const handleAccessFolder = (e, folderId) => {
    e.stopPropagation();
    setFranchiseDetails({
      ...franchiseDetails,
      homeFolder: 0,
    });
    getFranchiseFolder({ currentFolderId: folderId });
  };

  const handleGoBack = () => {
    if (franchiseFolder.parentFolder.id) {
      setFranchiseDetails({
        ...franchiseDetails,
        homeFolder: 0,
      });
      if (isParentFolderVirtualRoot) {
        const virtualRoot = {
          id: 'virtual-root',
          name: 'Virtual Root',
          parentFolder: '',
          subfolders: [{
            id: franchiseFolder.id,
            name: franchiseFolder.name,
          }],
        };
        loadFranchiseFolder({ franchiseFolder: virtualRoot });
      } else {
        getFranchiseFolder({ currentFolderId: franchiseFolder.parentFolder.id });
      }
    }
  };

  const handleChangeNewFolderName = (e) => {
    setNewFolderName(e.target.value);
  };

  const handleCreateNewFolder = () => {
    const parentFolder = franchiseFolder.subfolders[franchiseDetails.homeFolder];
    createNewFranchiseFolder({
      folderName: newFolderName,
      parentFolder,
    });
    setNewFolderName('');
  };

  const handleSave = () => {
    if (isAccountRoleRoot(role)) {
      const {
        id,
        name,
        secret,
        homeFolder,
        defaultRole,
      } = franchiseDetails;

      const updatedFranchiseDetails = {
        id,
        name,
        secret,
        defaultRole: roles[defaultRole].toUpperCase(),
        location: {
          uuid: franchise.location.uuid,
          name: franchise.location.name,
        },
        homeFolder: {
          uuid: franchiseFolder.subfolders[homeFolder].id,
          name: franchiseFolder.subfolders[homeFolder].name,
        },
      };
      editFranchiseRequest({ updatedFranchiseDetails });
    } else {
      editFranchiseSecretRequest({
        franchiseId: franchise.id,
        newSecret: franchiseSecret || franchise.secret,
      });
    }
    handleClose();
  };

  const handleCancel = () => {
    cancelEditFranchise();
    handleClose();
  };

  const franchiseDetailsForm = (
    <>
      <Typography variant="h4" className={classes.editFranchiseModalSectionHeader}>
        Franchise Details
      </Typography>
      <div className={classes.editFranchiseModalInputFieldContainer}>
        <TextField
          required
          fullWidth
          autoFocus
          size="small"
          variant="outlined"
          label="Franchise Name"
          id="outlined-helperText"
          value={franchiseDetails.name}
          field={franchiseDetails.name}
          disabled={!isAccountRoleRoot(role)}
          onChange={handleChangeFranchiseName}
          className={classes.editFranchiseModalInputField}
        />
      </div>
      <div className={classes.editFranchiseModalInputFieldContainer}>
        <TextField
          required
          fullWidth
          size="small"
          key="Secret Code"
          variant="outlined"
          label="Secret Code"
          field={franchiseDetails.secret}
          value={franchiseDetails.secret}
          disabled={isGeneratingNewFranchiseSecret}
          className={`
              ${classes.editFranchiseModalInputField}
              ${classes.editFranchiseModalInputFieldWithButton}`}
        />
        <div className={classes.editFranchiseModalInputFieldButtonContainer}>
          <Button
            disabled={isGeneratingNewFranchiseSecret}
            onClick={handleGenerateSecretCode}
            className={`
                ${classes.editFranchiseModalInputFieldButton} 
                ${classes.editFranchiseModalInputFieldButtonLarge}`}
          >
            {isGeneratingNewFranchiseSecret ? 'Generating...' : 'Generate Code'}
          </Button>
        </div>
      </div>
    </>
  );

  const defaultRoleDropdown = (
    <>
      <Typography variant="h4" className={classes.editFranchiseModalSectionHeader}>
        Default Role
      </Typography>
      <Typography variant="h4" className={classes.editFranchiseModalSectionSubheader}>
        Choose a role that will be applied to new users added to the franchise.
      </Typography>
      <FormControl variant="outlined" className={classes.editFranchiseModalFormControl}>
        <InputLabel>Role</InputLabel>
        <Select
          label="Role"
          onChange={handleChangeDefaultRole}
          value={franchiseDetails.defaultRole}
          className={classes.editFranchiseModalDropdown}
        >
          {roles.map((r, index) => (
            <MenuItem
              key={r}
              value={index}
              className={classes.editFranchiseModalDropdownRole}
            >
              {r[0] + r.slice(1).toLowerCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );

  const homeFolderDropdown = (
    <>
      <Typography variant="h4" className={classes.editFranchiseModalSectionHeader}>
        Home Folder
      </Typography>
      <Typography variant="h4" className={classes.editFranchiseModalSectionSubheader}>
        Select the folder that this franchise will see as its home folder.
      </Typography>
      <FormControl variant="outlined" className={classes.editFranchiseModalFormControl}>
        <InputLabel>Selected Folder</InputLabel>
        <Select
          label="Select Folder"
          onChange={handleChangeHomeFolder}
          value={franchiseDetails.homeFolder}
          disabled={isFranchiseFolderLoading}
          onOpen={toggleOpenHomeFolderDropdown}
          onClose={toggleOpenHomeFolderDropdown}
          className={classes.editFranchiseModalDropdown}
        >
          {franchiseFolder.subfolders.map((folder, index) => (
            <MenuItem
              value={index}
              key={folder.id}
              className={classes.editFranchiseModalDropdownItem}
            >
              <FolderIcon className={classes.franchiseFolderIcon} />
              <Typography className={classes.franchiseFolderName}>
                {folder.name}
              </Typography>
              {showFolderIcons && (
                <IconButton
                  onClick={(e) => handleAccessFolder(e, folder.id)}
                  className={classes.editFranchiseModalDropdownItemIconContainer}
                  size="large">
                  <AccessFolderIcon className={classes.editFranchiseModalDropdownItemIcon} />
                </IconButton>
              )}
            </MenuItem>
          ))}
        </Select>
        {franchiseFolder.name && !isFranchiseFolderVirtualRoot && (
          <div className={classes.editFranchiseModalInputFieldButtonContainer}>
            <Button
              onClick={handleGoBack}
              disabled={isFranchiseFolderLoading}
              className={`
                  ${classes.editFranchiseModalInputFieldButton} 
                  ${classes.editFranchiseModalInputFieldButtonLarge}`}
            >
              Go Back
            </Button>
          </div>
        )}
      </FormControl>
      <Typography variant="h4" className={classes.editFranchiseModalSectionSubheader}>
        Use this input to create a subfolder inside the folder selected above.
      </Typography>
      <FormControl variant="outlined" className={classes.editFranchiseModalFormControl}>
        <TextField
          required
          fullWidth
          size="small"
          variant="outlined"
          label="New Subfolder"
          value={newFolderName}
          field={newFolderName}
          onChange={handleChangeNewFolderName}
          className={`
                ${classes.editFranchiseModalInputField}
                ${classes.editFranchiseModalInputFieldWithButton}`}
        />
        <div className={classes.editFranchiseModalInputFieldButtonContainer}>
          <Button
            disabled={!newFolderName}
            onClick={handleCreateNewFolder}
            className={`
                ${classes.editFranchiseModalInputFieldButton} 
                ${classes.editFranchiseModalInputFieldButtonSmall}`}
          >
            {isCreatingNewFranchiseFolder ? 'Creating...' : 'Create'}
          </Button>
        </div>
      </FormControl>
    </>
  );

  const submitButtons = (
    <div className={classes.editFranchiseModalButtonsContainer}>
      <Button
        onClick={handleCancel}
        className={classes.editFranchiseModalCancelButton}
      >
        Cancel
      </Button>
      <Button
        type="submit"
        onClick={handleSave}
        className={classes.editFranchiseModalSaveButton}
        disabled={!franchiseDetails.name || isEditingFranchise}
      >
        {isEditingFranchise ? 'Saving...' : 'Save'}
      </Button>
    </div>
  );

  return (
    <div className={classes.editFranchiseModalContentContainer}>
      {franchiseDetailsForm}
      {isAccountRoleRoot(role) && (
        <>
          <Divider className={classes.editFranchiseModalSectionDivider} />
          {defaultRoleDropdown}
          <Divider className={classes.editFranchiseModalSectionDivider} />
          {homeFolderDropdown}
        </>
      )}
      {submitButtons}
    </div>
  );
};

export default compose(
  withFoldersData,
  withFoldersActions,
  withFranchiseData,
  withFranchiseActions,
)(EditFranchiseModalContent);
