import { handleActions } from 'redux-actions';

import {
  editCustomer,
  editingCustomer,
  loadNewCustomer,
  loadAllCustomers,
  cancelEditCustomer,
  loadingAllCustomers,
  creatingNewCustomer,
  resetCustomerSecret,
  loadNewCustomerSecret,
  stopLoadingAllCustomers,
  generatingNewCustomerSecret,
} from '../Actions/CustomerActions';

const initialState = {
  customers: [],
  customerSecret: '',
  isEditingCustomer: false,
  areCustomersLoading: true,
  isCreatingNewCustomer: false,
  isGeneratingNewCustomerSecret: false,
};

export default handleActions({
  [loadingAllCustomers]: (state) => ({
    ...state,
    areCustomersLoading: true,
  }),
  [stopLoadingAllCustomers]: (state) => ({
    ...state,
    areCustomersLoading: false,
  }),
  [loadAllCustomers]: (state, { payload: { customers } }) => ({
    ...state,
    customers,
    areCustomersLoading: false,
  }),
  [creatingNewCustomer]: (state) => ({
    ...state,
    isCreatingNewCustomer: true,
  }),
  [loadNewCustomer]: (state, { payload: customer }) => ({
    ...state,
    customers: [
      customer,
      ...state.customers,
    ],
    isCreatingNewCustomer: false,
  }),
  [editingCustomer]: (state) => ({
    ...state,
    isEditingCustomer: true,
  }),
  [cancelEditCustomer]: (state) => ({
    ...state,
    customerSecret: '',
    isEditingCustomer: false,
  }),
  [resetCustomerSecret]: (state) => ({
    ...state,
    customerSecret: '',
  }),
  [editCustomer]: (state, { payload: { updatedCustomer } }) => ({
    ...state,
    customers: state.customers
      .map((customer) => (customer.id === updatedCustomer.id
        ? updatedCustomer
        : customer
      )),
    isEditingCustomer: false,
    customerSecret: '',
  }),
  [generatingNewCustomerSecret]: (state) => ({
    ...state,
    isGeneratingNewCustomerSecret: true,
  }),
  [loadNewCustomerSecret]: (state, { payload: { newCustomerSecret } }) => ({
    ...state,
    customerSecret: newCustomerSecret,
    isGeneratingNewCustomerSecret: false,
  }),
}, initialState);
