import React from 'react';
import { styled } from '@mui/system';
import { useTheme, alpha } from '@mui/material/styles';

import {
  Box,
  ButtonBase,
} from '@mui/material';

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

import { TAG_TYPES } from '../../Utils/Tags';

const BORDER_WIDTH = '3px';

const TagBase = styled(ButtonBase)(({ theme, ownerState }) => ({
  padding: '6px 8px',
  borderBottomWidth: ownerState.pending ? 0 : BORDER_WIDTH,
  borderBottomColor: ownerState.active ? ownerState.colour : alpha(ownerState.colour, 0.3),
  borderBottomStyle: 'solid',
  backgroundColor: ownerState.active ? (ownerState.important ? alpha(ownerState.colour, 0.2) : theme.palette.action.selected) : 'transparent',
  // fontWeight: ownerState.active ? 'bold' : 'normal',
  textShadow: ownerState.active ? `0.75px 0px 0px ${theme.palette.text.primary}` : 'none',      // Fake 'bold' effect without changing the text width
  fontStyle: ownerState.pending ? 'italic' : 'normal',
  color: ownerState.clickable ? theme.palette.text.primary : theme.palette.text.secondary,

  ...(ownerState.clickable && {
    transition: theme.transitions.create(
      ['background-color', 'border-color', 'color'],
      { duration: theme.transitions.duration.short },
    ),

    '&:hover': {
      backgroundColor: ownerState.active ? theme.palette.action.focus : theme.palette.action.hover,

      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: ownerState.active ? (ownerState.important ? alpha(ownerState.colour, 0.15) : theme.palette.action.selected) : 'transparent',
      },
    },
  }),
}));

const PendingBar = styled(LinearProgress)(({ theme, ownerState }) => ({
  height: BORDER_WIDTH,

  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: alpha(theme.palette.health.t0, 0.3),
  },

  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: ownerState.active ? ownerState.colour : alpha(ownerState.colour, 0.3),
  },
}));

const TagButton = ({
  tag,
  important = false,
  active,
  pending,
  disabled,
  onClick,
  sx,
  ...props
}) => {
  const theme = useTheme();

  const clickable = !disabled && onClick;
  const isImportant = important || tag?.important;

  let colour;

  if (isImportant && active) {
    colour = theme.palette.secondary.main;
  }
  else if (!clickable) {
    colour = theme.palette.health.t0;   // Grey
  }
  else {
    switch (tag.type) {
      case TAG_TYPES.subscription:
        colour = theme.palette.primary.main;
        break;

      case TAG_TYPES.command:
        colour = theme.palette.health.t5;   // Red
        break;

      case TAG_TYPES.operator:
        colour = theme.palette.health.t2;   // Green
        break;

      case TAG_TYPES.server:
      default:
        colour = theme.palette.health.t0;   // Grey
        break;
    }
  }

  const ownerState = {
    colour,
    active,
    pending,
    important: isImportant,
    clickable,
  };

  function handleClick() {
    onClick(tag, active, pending);
  }
  
  return (
    <Box title={tag.description} sx={sx} {...props}>
      <TagBase ownerState={ownerState} disabled={!clickable} onClick={handleClick}>
        {tag?.label || '???'}
      </TagBase>
      {pending && (
        <PendingBar ownerState={ownerState}/>
      )}
    </Box>
  );
}

export default TagButton;