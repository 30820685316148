import { DateTime } from 'luxon';

import ConfinedTypography from '../../../Components/Typographies/ConfinedTypography';

import { TYPES } from './types';
import { SCROLLBAR_WIDTH } from "../../../../Utils/Defines";
import { formatDateTime } from '../../../../Utils/TimeUtils';

export const getColumns = (totalWidth) => {
  const COL_TIMESTAMP = 170;
  const COL_TYPE = 100;

  const remainingWidth = totalWidth - COL_TIMESTAMP - COL_TYPE - SCROLLBAR_WIDTH;

  return [
    { width: COL_TIMESTAMP, label: 'Timestamp', dataKey: 'timestamp', render: ({ cellData }) => formatDateTime(DateTime.fromISO(cellData), true) },
    { width: COL_TYPE, label: 'Event', dataKey: 'event' , render: ({ cellData }) => TYPES[cellData].label },
    { width: remainingWidth, label: 'Description', dataKey: 'desciption', render: ({ cellData }) => <ConfinedTypography title maxLines={2}>{cellData}</ConfinedTypography> },
  ];
};