import { connect } from 'react-redux';

import {
  getLatestLocations,
  getLocations,
  setSelectedLocations,
  getLocationAddress,
  setShowTowers,

  createDummyLocation,
  setGenerateDummyLocations,
} from '../Actions/location';

export default (WrappedComponent) => connect(null, {
  getLatestLocations,
  getLocations,
  setSelectedLocations,
  getLocationAddress,
  setShowTowers,

  createDummyLocation,
  setGenerateDummyLocations,
})(WrappedComponent);