import React from 'react';
import { compose } from 'redux';

import {
  Box,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';

import {
  Sort as SortIcon,
} from '@mui/icons-material';

import PaperSection from '../Components/PaperSection';
import FranchiseCard from './FranchiseCard';

import { sortArrayAlphabetically } from '../../Utils/Utils';

import withFranchiseData from '../../Hocs/WithData/franchise';
import withFranchiseActions from '../../Hocs/WithAction/franchise';
import withSystemActions from '../../redux/WithActions/system';

const SORT_FIELDS = [
  { field: 'name', label: 'Name', type: 'text' },
  { field: 'tag', label: 'Tag', type: 'text' },
  { field: 'region', label: 'Region', type: 'text' },
  { field: 'customers', label: 'Customers', type: 'number' },
  { field: 'units', label: 'Units', type: 'number' },
];

const FRANCHISES = [
  { id: 0, name: 'Automatrics UK', tag: 'AM-UK', region: 'United Kingdom', customers: 100, units: 10000 },
  { id: 1, name: 'Automatrics US', tag: 'AM-US', region: 'United States', customers: 20, units: 200 },
  { id: 2, name: 'Automatrics EU', tag: 'AM-EU', region: 'Europe', customers: 50, units: 500 },
  { id: 3, name: 'Automatrics SA', tag: 'AM-SA', region: 'South America', customers: 100, units: 100 },
  { id: 4, name: 'Automatrics SEA', tag: 'AM-SEA', region: 'South East Asia', customers: 10, units: 100 },
  { id: 5, name: 'Automatrics AU', tag: 'AM-AU', region: 'Australia', customers: 10, units: 100 },
  { id: 6, name: 'Automatrics ME', tag: 'AM-ME', region: 'Middle East', customers: 10, units: 100 },
  { id: 7, name: 'Automatrics CN', tag: 'AM-CN', region: 'China', customers: 10, units: 100 },
  { id: 8, name: 'Automatrics AN', tag: 'AM-AN', region: 'Antarctica', customers: 1, units: 3 },
  { id: 9, name: 'Automatrics CO', tag: 'AM-CO', region: 'Coruscant', customers: 1000000, units: 100000000 },
];

const FranchiseSearch = ({
  franchises,
  areFranchisesLoading,
  getAllFranchises,
  setShowSubHeader,
}) => {
  // Set the browser tab's title
  document.title = 'Franchises - MTrack';

  const [hasStartedLoading, setStartedLoading] = React.useState(false);
  const [sorterIdx, setSorterIdx] = React.useState(0);
  const [sortedFranchises, setSortedFranchises] = React.useState([]);

  // Clear 'showSubHeader' so the main header renders correctly
  React.useEffect(() => { setShowSubHeader(false); }, [ setShowSubHeader ]);

  // Get list of franchises
  React.useEffect(() => {
    getAllFranchises();

    setStartedLoading(true);
  }, [
    getAllFranchises,
    setStartedLoading,
  ]);

  React.useEffect(() => {
    console.log(franchises);
  }, [
    franchises,
  ]);

  // Sort the array of franchises with the initial sort field
  React.useEffect(() => {
    sortFranchises(sorterIdx);
  }, []);
  
  const handleChangeSorterIdx = (_, index) => {
    setSorterIdx(index);

    sortFranchises(index);
  }

  const sortFranchises = (index) => {
    const sorter = SORT_FIELDS[index];

    if (!sorter) {
      return;
    }

    switch (sorter.type) {
      case 'text':
        setSortedFranchises(sortArrayAlphabetically(FRANCHISES, sorter.field));
        break;

      case 'number':
        setSortedFranchises(FRANCHISES.sort((a, b) => ((a[sorter.field] > b[sorter.field]) ? -1 : 1)));
        break;
    }
  }

  return (
    <>
      <PaperSection sx={{ m: '0 auto', mb: 3, p: 2, width: 'max-content', display: 'flex', flexDirection: 'row' }}>
        <SortIcon sx={{ m: 'auto 0', mr: 2 }}/>
        <ToggleButtonGroup
          exclusive
          value={sorterIdx}
          onChange={handleChangeSorterIdx}
        >
          {SORT_FIELDS.map((sorter, index) => (
            <ToggleButton key={sorter.field} value={index}>{sorter.label}</ToggleButton>
          ))}
        </ToggleButtonGroup>
      </PaperSection>

      <Box sx={{ display: 'flex', flexDirection: 'row', flexFlow: 'wrap', justifyContent: 'center', p: 3, pt: 0, overflow: 'auto' }}>
        {(!hasStartedLoading || areFranchisesLoading) ? (
          <CircularProgress/>
        ) : (sortedFranchises && sortedFranchises.length > 0) ? (
          sortedFranchises.map((franchise) => (
            <FranchiseCard key={franchise.id} franchise={franchise} sx={{ m: 1, mt: 0, mb: 2 }}/>
          ))
        ) : (
          <Typography variant="h4">No franchises found</Typography>
        )}
      </Box>
    </>
  );
};

export default compose(withFranchiseData, withFranchiseActions, withSystemActions)(FranchiseSearch);