export const TYPES = {
  ATCOMMAND: {
    id: 'ATCOMMAND',
    label: 'AT Command',
  },
  LOCATION: {
    id: 'LOCATION',
    label: 'Location',
  },
  REQUEST: {
    id: 'REQUEST',
    label: 'Request',
  },
  SETTINGS: {
    id: 'SETTINGS',
    label: 'Settings',
  },
};

export const TYPES_ARRAY = [
  TYPES.ATCOMMAND,
  TYPES.LOCATION,
  TYPES.REQUEST,
  TYPES.SETTINGS,
];