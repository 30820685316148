import {
  put,
  all,
  call,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import {
  loaded,
  loading,
  moveItems,
  shareFolder,
  renameFolder,
  deleteFolder,
  loadNewFolder,
  assignTrackers,
  createNewFolder,
  moveItemsRequest,
  deselectAllItems,
  getCurrentFolder,
  unassignTrackers,
  loadCurrentFolder,
  getMoreFolderItems,
  loadMoreFolderItems,
  deleteFolderRequest,
  renameFolderRequest,
  getRootLevelFolders,
  withdrawFolderAccess,
  loadRootLevelFolders,
  assignTrackersRequest,
  unassignTrackersRequest,
  addUserToFolderPermissions,
  removeUserFromFolderPermissions,
} from '../Actions/FolderActions';
import { authApiHandler } from './auth';
import FolderAPI from '../../Api/Endpoints_OLD/folder';
import TrackersAPI from '../../Api/Endpoints_OLD/tracker';
import { currentFolderAuthorizations } from './selectors';
import { addNotification } from '../Actions/system';
import ResponseCodes from '../../Api/Responses/responseCodes';
import { ResponseTypes } from '../../Api/Responses/baseResponse';

function* handleGetRootLevelFoldersIds() {
  const response = yield authApiHandler(FolderAPI.getRootLevelFoldersIds);
  if (response.type === ResponseTypes.ActionCompleted) {
    const rootLevelFoldersIds = response.data.map((folder) => folder.uuid);
    return rootLevelFoldersIds;
  } if (response.type === ResponseTypes.NotFound) {
    yield put(loaded());
    yield put(addNotification({
      type: 'error',
      source: 'handleGetRootLevelFoldersIds',
      title: 'You do not have access to any folder',
      message: 'Please, double check your permissions with your folder admin.',
    }));
    return false;
  } if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not retrieve folder',
      source: 'handleGetRootLevelFoldersIds',
      message: 'Please, check your connection and try again.',
    }));
    return false;
  }
  return false;
}

function* handleGetRootLevelFolderDetails(folderId) {
  const response = yield authApiHandler(FolderAPI.getFolderDetails, folderId);
  if (response.type === ResponseTypes.ActionCompleted) {
    const rootLevelFolderDetails = {
      id: response.data.id,
      name: response.data.name,
      type: response.data.objectType,
      sharedWith: response.data.authorisations,
      parentFolder: {
        id: response.data.location.uuid,
        name: response.data.location.name,
      },
      subfolders: response.data.subfolders.map((subfolder) => ({
        id: subfolder.uuid,
        name: subfolder.name,
      })),
      isFiltered: true,
    };
    return rootLevelFolderDetails;
  } if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not retrieve folder',
      source: 'handleGetRootLevelFolderDetails',
      message: 'Please, check your connection and try again.',
    }));
  }
  return {};
}

function* handleGetCurrentFolderDetails(currentFolderId, history) {
  const response = yield authApiHandler(FolderAPI.getFolderDetails, currentFolderId);
  if (response.type === ResponseTypes.ActionCompleted) {
    const currentFolderDetails = {
      id: response.data.id,
      name: response.data.name,
      sharedWith: response.data.authorisations,
      parentFolder: {
        id: response.data.location.uuid,
        name: response.data.location.name,
      },
      subfolders: response.data.subfolders.map((subfolder) => ({
        id: subfolder.uuid,
        name: subfolder.name,
      })),
    };
    return currentFolderDetails;
  } if (response.type === ResponseTypes.Forbidden) {
    const isCurrentFolderLoaded = yield select((state) => state.FoldersData.currentFolder.id);
    if (isCurrentFolderLoaded) {
      yield history.goBack();
    } else {
      yield history.push('/folders');
    }
    yield put(addNotification({
      type: 'error',
      title: 'Access Denied',
      source: 'handleGetCurrentFolderDetails',
      message: 'You do not have access to this folder.',
    }));
    return false;
  } if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not retrieve folder',
      source: 'handleGetCurrentFolderDetails',
      message: 'Please, check your connection and try again.',
    }));
    return false;
  }
  return false;
}

function* handleGetCurrentFolderContent(currentFolderId, offset = 0) {
  const response = yield authApiHandler(FolderAPI.getFolderContent, currentFolderId, offset);
  if (response.type === ResponseTypes.ActionCompleted) {
    const {
      totalCount: totalNumberOfItems,
      noOfObjects: numberOfItemsLoaded,
    } = response.data;
    const currentFolderContent = response.data.data
      .map((item) => ({
        id: item.id || item.simiccid || item.username,
        name: item.name || item.username,
        type: item.objectType,
        franchise: {
          id: item.franchise?.uuid || '',
          name: item.franchise?.name || '',
        },
        customer: {
          id: item.customer?.uuid || '',
          name: item.customer?.name || '',
        },
        parentFolder: {
          id: item.location.uuid,
          name: item.location.name,
        },
        sharedWith: item.authorisations || [],
        isFiltered: true,
      }))
      .sort((firstItem, nextItem) => {
        const firstItemName = firstItem.name.toLowerCase();
        const nextItemName = nextItem.name.toLowerCase();
        if (firstItemName < nextItemName) return -1;
        if (firstItemName > nextItemName) return 1;
        return 0;
      });
    return { currentFolderContent, totalNumberOfItems, numberOfItemsLoaded };
  } if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not retrieve folder',
      source: 'handleGetCurrentFolderContent',
      message: 'Please, check your connection and try again.',
    }));
    return false;
  }
  return false;
}

function* handleGetRootLevelFolders() {
  const rootLevelFoldersIds = yield call(handleGetRootLevelFoldersIds);
  if (rootLevelFoldersIds) {
    const rootLevelFolders = yield all(rootLevelFoldersIds
      .map((folderId) => call(handleGetRootLevelFolderDetails, folderId)));
    if (rootLevelFolders) {
      yield put(loadRootLevelFolders(rootLevelFolders));
    }
  }
}

function* handleGetCurrentFolder({ payload: { currentFolderId, history } }) {
  yield put(loading());
  const currentFolderDetails = yield call(handleGetCurrentFolderDetails, currentFolderId, history);
  if (currentFolderDetails) {
    const { currentFolderContent, totalNumberOfItems, numberOfItemsLoaded } = yield call(
      handleGetCurrentFolderContent, currentFolderId,
    );
    if (currentFolderContent) {
      const currentFolder = {
        ...currentFolderDetails,
        content: currentFolderContent,
      };
      yield put(loadCurrentFolder({ currentFolder, totalNumberOfItems, numberOfItemsLoaded }));
    }
  }
}

function* handleGetMoreFolderItems({ payload: { currentFolderId, offset } }) {
  const {
    numberOfItemsLoaded,
    currentFolderContent: items,
  } = yield call(handleGetCurrentFolderContent, currentFolderId, offset);
  yield put(loadMoreFolderItems({ numberOfItemsLoaded, items }));
}

function* handleCreateNewFolder({ payload: { folderName, parentFolderId } }) {
  const response = yield authApiHandler(
    FolderAPI.createNewFolder, folderName, parentFolderId,
  );
  if (response.type === ResponseTypes.ActionCompleted) {
    const newFolder = {
      id: response.data.id,
      name: response.data.name || 'Untitled Folder',
      type: response.data.objectType,
      parentFolder: {
        id: response.data.location.uuid,
        name: response.data.location.name,
      },
      sharedWith: response.data.authorisations || [],
      isFiltered: true,
    };
    yield put(loadNewFolder(newFolder));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not create folder',
      source: 'handleCreateNewFolder',
      message: 'Please, check your connection and try again.',
    }));
  }
}

function* handleDeleteFolderRequest({ payload: { folderId, folderName } }) {
  const response = yield authApiHandler(FolderAPI.deleteFolder, folderId);
  if (response.type === ResponseTypes.ActionCompleted) {
    yield put(deleteFolder(folderId));
  } else if (response.type === ResponseTypes.ClientError) {
    yield put(addNotification({
      type: 'error',
      title: `Could not delete "${folderName}"`,
      source: 'handleDeleteFolderRequest',
      message: 'Please, empty the folder before deleting it. If the folder is already empty, please make also sure that it is not set as home folder for any franchise.',
    }));
  } else if (response.type === ResponseTypes.BadRequest) {
    yield put(addNotification({
      type: 'error',
      title: `Could not delete "${folderName}"`,
      source: 'handleDeleteFolderRequest',
      message: `"${folderName}" cannot be deleted from the system.`,
    }));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: `Could not delete "${folderName}"`,
      source: 'handleDeleteFolderRequest',
      message: 'Please, check your connection and try again.',
    }));
  }
}

function* handleRenameFolderRequest({ payload: { folderId, folderName } }) {
  const response = yield authApiHandler(FolderAPI.renameFolder, folderId, folderName);
  if (response.type === ResponseTypes.ActionCompleted) {
    yield put(renameFolder({ folderId, folderName }));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: `Could not rename "${folderName}"`,
      source: 'handleRenameFolderRequest',
      message: 'Please, check your connection and try again.',
    }));
  }
}

function* handleMoveItemsRequest({ payload: { newParentFolder, items } }) {
  const itemsIds = items.map((item) => item.id);
  const response = yield authApiHandler(FolderAPI.moveItems, newParentFolder.id, itemsIds);
  if (response.type === ResponseTypes.ActionCompleted) {
    const updatedItems = items.map((item) => ({
      ...item,
      parentFolder: {
        id: newParentFolder.id,
        name: newParentFolder.name,
      },
    }));
    yield put(moveItems(updatedItems));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not move items',
      source: 'handleMoveItemsRequest',
      message: 'Please, check your connection and try again.',
    }));
  }
}

function* handleShareFolder({
  payload: {
    email,
    folderId,
    folderName,
    arePermissionsCascadedToSubfolders: cascade,
  },
}) {
  const folderAuthorizations = yield select(currentFolderAuthorizations);
  const isFolderAlreadySharedWithUser = folderAuthorizations.includes(email);
  if (!isFolderAlreadySharedWithUser) {
    const response = yield authApiHandler(FolderAPI.shareFolder, folderId, email, cascade);
    if (response.type === ResponseTypes.ActionCompleted) {
      yield put(addUserToFolderPermissions({ email }));
      yield put(addNotification({
        type: 'success',
        source: 'handleShareFolder',
        title: 'Folder successfully shared!',
        message: `${email} has been granted access to "${folderName}"${cascade ? ' and its subfolders' : ''}.`,
      }));
    } else if (response.type === ResponseTypes.NotFound) {
      yield put(addNotification({
        type: 'error',
        source: 'handleShareFolder',
        title: 'User not registered',
        message: `Could not share "${folderName}". ${email} is not a registered user.`,
      }));
    } else if (response.type === ResponseTypes.Forbidden) {
      yield put(addNotification({
        type: 'error',
        source: 'handleWithdrawFolderAccess',
        title: 'Permission denied',
        message: 'You do not have permission to make this change.',
      }));
    } else if (response.type === ResponseTypes.Exception) {
      yield put(addNotification({
        type: 'error',
        source: 'handleShareFolder',
        title: 'Could not share folder',
        message: `Could not share "${folderName}" with ${email}. 
        Please, check your connection and try again.`,
      }));
    }
  } else {
    yield put(deselectAllItems());
    yield put(addNotification({
      type: 'success',
      source: 'handleShareFolder',
      title: 'Folder already shared',
      message: `"${folderName}" has already been shared with ${email}.`,
    }));
  }
}

function* handleWithdrawFolderAccess({
  payload: {
    email,
    folderId,
    folderName,
    arePermissionsCascadedToSubfolders: cascade,
  },
}) {
  const response = yield authApiHandler(FolderAPI.withdrawFolderAccess, folderId, email, cascade);
  if (response.type === ResponseTypes.ActionCompleted) {
    yield put(removeUserFromFolderPermissions({ email }));
    yield put(addNotification({
      type: 'success',
      title: 'Folder Access Withdrawn!',
      source: 'handleWithdrawFolderAccess',
      message: `Access to "${folderName}"${cascade ? ' and its subfolders' : ''} successfully withdrawn for ${email}.`,
    }));
  } else if (response.type === ResponseTypes.NotFound) {
    yield put(addNotification({
      type: 'error',
      source: 'handleWithdrawFolderAccess',
      title: 'User not registered',
      message: `${email} is not a registered user.`,
    }));
  } else if (response.type === ResponseTypes.Forbidden) {
    yield put(addNotification({
      type: 'error',
      source: 'handleWithdrawFolderAccess',
      title: 'Permission denied',
      message: 'You do not have permission to make this change.',
    }));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      source: 'handleWithdrawFolderAccess',
      title: 'Could not withdraw access',
      message: `Could not withdraw access to "${folderName}" for ${email}. 
      Please, check your connection and try again.`,
    }));
  }
}

function* handleAssignTrackersRequest({
  payload: {
    customer,
    franchise,
    selectedTrackersIds,
  },
}) {
  let response = {};

  if (franchise) {
    response = yield authApiHandler(TrackersAPI.assignTrackersToFranchise,
      selectedTrackersIds, franchise.id);
  } else {
    response = yield authApiHandler(TrackersAPI.assignTrackersToCustomer,
      selectedTrackersIds, customer.id);
  }

  if (response.type === ResponseTypes.ActionCompleted) {
    yield put(assignTrackers({ selectedTrackersIds, franchise, customer }));
    yield put(deselectAllItems());
    yield put(addNotification({
      type: 'success',
      source: 'handleAssignTrackersRequest',
      title: 'Trackers successfully assigned!',
      message: `The selected trackers have been successfully assigned to "${franchise ? franchise.name : customer.name}".`,
    }));
  } else if (response.data?.code === ResponseCodes.TrackersNotAssignedToAnyFranchise) {
    yield put(addNotification({
      type: 'error',
      source: 'handleAssignTrackersRequest',
      title: 'Could not assign trackers',
      message: `Could not assign the selected trackers to "${customer.name}". Before trying again, make sure to assign the selected trackers to your franchise.`,
    }));
  } else if (response.data?.code === ResponseCodes.TrackersNotAssignedToYourFranchise) {
    yield put(addNotification({
      type: 'error',
      source: 'handleAssignTrackersRequest',
      title: 'Could not assign trackers',
      message: `Could not assign the selected trackers to "${customer.name}". Before trying again, make sure to assign the selected trackers to your franchise.`,
    }));
  } else if (response.type === ResponseTypes.ClientError) {
    yield put(addNotification({
      type: 'error',
      source: 'handleAssignTrackersRequest',
      title: 'Trackers already assigned',
      message: `Could not assign trackers to "${franchise ? franchise.name : customer.name}". Before trying again, make sure to unassign all of the selected trackers from any existing ${franchise ? 'franchise' : 'customer'}.`,
    }));
  } else if (response.type === ResponseTypes.Forbidden) {
    yield put(addNotification({
      type: 'error',
      source: 'handleAssignTrackersRequest',
      title: 'Could not assign trackers',
      message: `Could not assign trackers to "${customer.name}". Before trying again, make sure to assign all the selected trackers to a franchise that this customer belongs to.`,
    }));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      source: 'handleAssignTrackersRequest',
      title: 'Could not assign trackers',
      message: `Could not assign the selected trackers to "${franchise ? franchise.name : customer.name}". Please, check your connection and try again.`,
    }));
  }
}

function* handleUnassignTrackersRequest({
  payload: {
    customer,
    franchise,
    selectedTrackersIds,
  },
}) {
  let response = {};

  if (franchise) {
    response = yield authApiHandler(TrackersAPI.unassignTrackersFromFranchise, selectedTrackersIds);
  } else if (customer) {
    response = yield authApiHandler(TrackersAPI.unassignTrackersFromCustomer, selectedTrackersIds);
  }

  if (response.type === ResponseTypes.ActionCompleted) {
    yield put(unassignTrackers({ selectedTrackersIds, franchise }));
    yield put(deselectAllItems());
    yield put(addNotification({
      type: 'success',
      source: 'handleUnassignTrackersRequest',
      title: 'Trackers successfully unassigned!',
      message: `The selected trackers have been successfully unassigned from all ${franchise ? 'franchises' : 'customers'}.`,
    }));
  } else if (response.data?.code === ResponseCodes.TrackersAssignedToCustomer) {
    yield put(addNotification({
      type: 'error',
      source: 'handleUnassignTrackersRequest',
      title: 'Could not unassign trackers',
      message: 'The selected trackers could not be unassigned. Before trying again, make sure to unassign all selected trackers from any existing customer.',
    }));
  } else if (response.data?.code === ResponseCodes.TrackersNotAssignedToAnyFranchise) {
    yield put(addNotification({
      type: 'error',
      source: 'handleUnassignTrackersRequest',
      title: 'Could not unassign trackers',
      message: 'The selected trackers could not be unassigned as they are not currently assigned to any franchise.',
    }));
  } else if (response.type === ResponseTypes.Forbidden) {
    yield put(addNotification({
      type: 'error',
      source: 'handleUnassignTrackersRequest',
      title: 'Could not unassign trackers',
      message: 'The selected trackers could not be unassigned. Before trying again, make sure to assign all selected trackers to your franchise.',
    }));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      source: 'handleUnassignTrackersRequest',
      title: 'Could not unassign trackers',
      message: 'Please, check your connection and try again.',
    }));
  }
}

export default function* FolderSagas() {
  yield takeEvery(shareFolder, handleShareFolder);
  yield takeLatest(createNewFolder, handleCreateNewFolder);
  yield takeEvery(moveItemsRequest, handleMoveItemsRequest);
  yield takeEvery(getCurrentFolder, handleGetCurrentFolder);
  yield takeEvery(getMoreFolderItems, handleGetMoreFolderItems);
  yield takeEvery(getRootLevelFolders, handleGetRootLevelFolders);
  yield takeEvery(deleteFolderRequest, handleDeleteFolderRequest);
  yield takeLatest(renameFolderRequest, handleRenameFolderRequest);
  yield takeEvery(withdrawFolderAccess, handleWithdrawFolderAccess);
  yield takeEvery(assignTrackersRequest, handleAssignTrackersRequest);
  yield takeEvery(unassignTrackersRequest, handleUnassignTrackersRequest);
}
