import React from 'react';
import { compose } from 'redux';
import { DateTime } from 'luxon';

import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
} from '@mui/material';

import {
  ContentCopy as CopyIcon,
  Download as DownloadIcon,
} from '@mui/icons-material';

import PaperSection from '../../../Components/PaperSection';
import Map from '../../../Components/Map';
import TooltipIconButton from '../../../Components/TooltipIconButton';

import { NAV_TYPES_ARRAY } from '../../../../Utils/NavTypes';
import { formatDate, formatTime, formatDateTime } from '../../../../Utils/TimeUtils';
import { useWindowDimensions } from '../../../../Utils/ScreenSize';
import { convertEventToMapMarker } from '../../../Components/Map/Utils';

import withTrackerData from '../../../../redux/WithData/tracker';
import withTrackerActions from '../../../../redux/WithActions/tracker';
import withLocationData from '../../../../redux/WithData/location';
import withLocationActions from '../../../../redux/WithActions/location';

const MAX_POSITIONS = 10;

const TOOLTIP_COPY_W3W = 'Copy W3W';
const TOOLTIP_COPY_ADDRESS = 'Copy Address';
const TOOLTIP_COPIED = 'Copied to clipboard!';

const MAP_CENTRE = {
  lat: 51.0030048,
  lng: -0.9494693
};

const DEFAULT_NEW_PATH = [
  { lat: 50.996, lng: -0.955 },
  { lat: 51.004, lng: -0.931 },
  { lat: 51.018, lng: -0.932 },
  { lat: 51.015, lng: -0.967 },
];

const MapSection = ({
  // zones,
  // hightlightedZoneIdx,
  // newZonePath,
  // onEditPath,
  // onLoadEditablePolygon,
  // onUnmountEditablePolygon,
  sx,

  zones,
  highlightedZone,
  showZones,
  newZone,
  setHighlightedZone,
  setNewZone,
  selectedLocations,
  getLocationAddress,
}) => {
  React.useEffect(() => {console.log(zones)}, [zones]);
  React.useEffect(() => {console.log(highlightedZone)}, [highlightedZone]);
  React.useEffect(() => {console.log(showZones)}, [showZones]);
  React.useEffect(() => {console.log(newZone)}, [newZone]);

  const { isMobileWidth, isTabletWidth } = useWindowDimensions();

  const [positions, setPositions] = React.useState([]);
  const [addressDownloadable, setAddressDownloadable] = React.useState(false);

  const [newZonePath, setNewZonePath] = React.useState([]);

  React.useEffect(() => {console.log(newZonePath)}, [newZonePath]);

  // Convert location data into map markers
  React.useEffect(() => {
    if (selectedLocations && selectedLocations.length > 0) {
      let newPositions = [];
      let newPos;

      for (let i = 0; i < selectedLocations.length && newPositions.length < MAX_POSITIONS; i++) {
        newPos = convertEventToMapMarker(selectedLocations[i]);

        if (newPos) {
          newPositions.push(newPos);
        }
      }

      setPositions(newPositions);

      // If the address is blank, it can be requested
      setAddressDownloadable(!newPositions[0]?.address);
    }
    else {
      setPositions([]);
    }
  }, [
    selectedLocations,
  ]);

  // React to changes to the new zone
  React.useEffect(() => {
    // TODO: handle changing zone shape

    // Polygon
    if (newZone && !newZone.path) {
      setNewZone({
        ...newZone,
        path: [ ...DEFAULT_NEW_PATH ],
      });
    }

    // Setup/delete the new zone path when the user creates/deletes one
    // if (newZone && newZonePath.length === 0) {
    //   // TODO: create new default path based on current map position & zoom
    //   setNewZonePath([ ...DEFAULT_NEW_PATH ]);
    // }
    // else if (!newZone && newZonePath.length > 0) {
    //   setNewZonePath([]);
    // }
  }, [
    newZone,
  ]);

  // Define refs for Polygon instance and listeners
  const polygonRef = React.useRef(null);
  const listenersRef = React.useRef([]);

  // Call setPath with new edited path
  const handleEditPath = React.useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map(latLng => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });

        setNewZone({
          ...newZone,
          path: nextPath,
        })
    }
  }, [newZone, setNewZone]);

  // Bind refs to current Polygon and listeners
  const handleLoadNewPolygon = React.useCallback(
    polygon => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", handleEditPath),
        path.addListener("insert_at", handleEditPath),
        path.addListener("remove_at", handleEditPath)
      );
    },
    [handleEditPath]
  );

  // Clean up refs
  const handleUnmountNewPolygon = React.useCallback(() => {
    listenersRef.current.forEach(lis => lis.remove());
    polygonRef.current = null;
  }, []);

  function handleClickCopyW3W() {
    if (positions[0]?.w3w) {
      navigator.clipboard.writeText(positions[0].w3w);
    }
  }

  function handleClickCopyAddress() {
    if (addressDownloadable) {
      getLocationAddress(positions[0].id);
    }
    else {
      navigator.clipboard.writeText(positions[0].address);
    }
  }

  let mapSx = undefined;

  if (isMobileWidth) {
    mapSx = { height: '300px' };
  }
  else if (isTabletWidth) {
    mapSx = { height: '400px' };
  }

  const dateTime = DateTime.fromISO(positions[0]?.timestamp);

  return (
    <PaperSection sx={{ display: 'flex', flexDirection: 'column', overflow: 'auto', p: 0, ...sx }}>
      <Map
        fullMap
        centre={MAP_CENTRE}
        positions={positions}
        zones={showZones && zones}
        highlightedZone={highlightedZone}
        newZonePath={newZone && newZone.path}
        onEditPath={handleEditPath}
        onLoadEditablePolygon={handleLoadNewPolygon}
        onUnmountEditablePolygon={handleUnmountNewPolygon}
        sx={mapSx}
      />

      <Divider/>

      <Box sx={{ display: 'flex', flexDirection: 'row', m: 2 }}>
        <Stack spacing={1} sx={{ width: '100%' }}>
          {isMobileWidth ? (
            <>
              <Stack direction="row" spacing={1} sx={{ m: '0 auto' }}>
                <Typography>*Vodafone*</Typography>
                <Divider orientation="vertical"/>
                <Typography>*81%*</Typography>
                <Divider orientation="vertical"/>
                <Typography>{formatDateTime(dateTime, true)}</Typography>
              </Stack>

              <Stack direction="row" spacing={1} sx={{ m: '0 auto' }}>
                {NAV_TYPES_ARRAY.map(nav => (
                  <Button key={nav.id} value={nav.id} variant="outlined" color={nav.colour}>{nav.label}</Button>
                ))}
              </Stack>
            </>
          ) : (
            <Box sx={{ display: 'flex', flexDirection: 'row'}}>
              <Typography sx={{ m: 'auto 0', minWidth: '100px' }}>Ping</Typography>
              <Stack direction="row" spacing={1}>
                {NAV_TYPES_ARRAY.map(nav => (
                  <Button key={nav.id} value={nav.id} variant="outlined" color={nav.colour}>{nav.label}</Button>
                ))}
              </Stack>
            </Box>
          )}
          
          <Box sx={{ display: 'flex', flexDirection: 'row'}}>
            <Typography sx={{ m: 'auto 0', minWidth: '100px' }}>W3W</Typography>
            <Typography sx={{ m: 'auto 0' }}>{positions[0]?.w3w || '-'}</Typography>
            <TooltipIconButton
              tooltip={TOOLTIP_COPY_W3W}
              tooltipAfterClick={TOOLTIP_COPIED}
              icon={<CopyIcon/>}
              onClick={handleClickCopyW3W}
              sx={{ ml: 'auto' }}
            />
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'row'}}>
            <Typography sx={{ m: 'auto 0', minWidth: '100px' }}>Address</Typography>
            <Typography sx={{ m: 'auto 0' }}>{positions[0]?.address || '-'}</Typography>
            <TooltipIconButton
              tooltip={!addressDownloadable ? TOOLTIP_COPY_ADDRESS : 'Request location address'}
              tooltipAfterClick={!addressDownloadable && TOOLTIP_COPIED}
              icon={addressDownloadable ? <DownloadIcon/> : <CopyIcon/>}
              onClick={handleClickCopyAddress}
              sx={{ ml: 'auto' }}
            />
          </Box>
        </Stack>

        {!isMobileWidth && (
          <>
            <Divider orientation="vertical" sx={{ ml: 2 }}/>
            <Stack spacing={1} sx={{ mt: 'auto', mb: 'auto', ml: 2 }}>
              <Typography sx={{ m: '0 auto' }}>*Vodafone*</Typography>
              <Typography sx={{ m: '0 auto' }}>*81%*</Typography>
              <Typography sx={{ m: '0 auto' }}>{formatDate(dateTime)}</Typography>
              <Typography sx={{ m: '0 auto' }}>{formatTime(dateTime, true)}</Typography>
            </Stack>
          </>
        )}
      </Box>
    </PaperSection>
  );
}

export default compose(withTrackerData, withTrackerActions, withLocationData, withLocationActions)(MapSection);