import React from 'react';
import {
  Circle,
} from 'react-google-maps';

const MarkerCircle = ({
  show, zoom, lat, long, accuracy,
}) => {
  let mainCircle = 180;
  let externalCircle = 100;

  if (zoom < 8) {
    mainCircle = 300000;
  } else if (zoom < 12) {
    mainCircle = 15000;
  } else if (zoom < 14) {
    mainCircle = 1500;
  } else if (zoom < 18) {
    mainCircle = 75;
  } else {
    mainCircle = 5;
  }
  externalCircle = mainCircle * 2;

  externalCircle = accuracy;
  mainCircle = accuracy;
  return (
    <>
      <Circle
        defaultCenter={{ lat, lng: long }}
        radius={externalCircle}
        options={{
          strokeOpacity: 0,
          fillColor: '#1888ac',
          fillOpacity: 0.2,
        }}
      />
      {show && (
        <Circle
          defaultCenter={{ lat, lng: long }}
          radius={mainCircle}
          options={{
            fillColor: '#1888ac',
            fillOpacity: 0.2,
            strokeOpacity: 0,
          }}
        />
      )}
    </>
  );
};
export default MarkerCircle;
