import { DateTime } from 'luxon';

import {
  Avatar,
} from '@mui/material';

import defaultAvatar from '../../../Assets/Images/avatarDefault.png'
import { SCROLLBAR_WIDTH } from "../../../Utils/Defines";
import { formatDate } from '../../../Utils/TimeUtils';

export const getColumns = (totalWidth, avatarSize, spacing) => {
  const COL_AVATAR = avatarSize + (4 * spacing);
  const COL_NAME = 200;
  const COL_EMAIL = 400;
  const COL_TIMESTAMP = 200;

  // TODO: scale timestamp column to put delete button at the end
  
  return [
    { width: COL_AVATAR, label: '', dataKey: 'avatar' , render: ({ cellData }) => (
      <Avatar
        // src={userData.avatar && userData.avatar.thumbnailUrl}
        src={cellData && cellData.thumbnailUrl || defaultAvatar}
        sx={{ bgcolor: 'background.default', color: 'primary.main', width: `${avatarSize}px`, height: `${avatarSize}px` }}
      />
    )},
    { width: COL_NAME, label: 'First Name', dataKey: 'firstName' },
    { width: COL_NAME, label: 'Last Name', dataKey: 'lastName' },
    { width: COL_EMAIL, label: 'Email', dataKey: 'username' },
    { width: COL_TIMESTAMP, label: 'Created On', dataKey: 'createdBy', render: ({ cellData }) => formatDate(DateTime.fromISO(cellData.createdOn)) },
  ];
};