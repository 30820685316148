import { ResponseTypes } from './baseResponse';

export const AuthResponseTypes = {
  Exception: 'Exception',
  Unauthorized: 'Unauthorized',
  RejectedLogin: 'RejectedLogin',
  AcceptedLogin: 'AcceptedLogin',
  AcceptedRegister: 'AcceptedRegister',
  RejectedRegister: 'RejectedRegister',
};

export const RegisterResponseHandler = (payload) => {
  const status = 401;
  let data;
  let resultType = AuthResponseTypes.RejectedRegister;

  if ('format' in payload && payload.format === 'helios') {
    if (ResponseTypes.ActionCompleted === payload.type) {
      resultType = AuthResponseTypes.AcceptedRegister;
    } else {
      resultType = AuthResponseTypes.RejectedRegister;
    }
    data = payload.data;
  }

  return {
    format: 'helios',
    type: resultType,
    code: status,
    data,
  };
};

export const LoginResponseHandler = (payload) => {
  const status = 401;
  let data;
  let resultType = AuthResponseTypes.RejectedLogin;

  if ('format' in payload && payload.format === 'helios') {
    if (ResponseTypes.ActionCompleted === payload.type) {
      resultType = AuthResponseTypes.AcceptedLogin;
    } else {
      resultType = AuthResponseTypes.RejectedLogin;
    }
    data = payload.data;
  }

  return {
    format: 'helios',
    type: resultType,
    code: status,
    data,
  };
};

export default { LoginResponseHandler };
