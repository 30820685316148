import makeStyles from '@mui/styles/makeStyles';

import {
  font,
  fieldColor,
  buttonPurple,
  onHoverButtonPurple,
  onInputFocusPurple,
  onInputHoverPurple,
} from '../../../Styles/styles';

const deleteFranchiseModalStyles = makeStyles(() => ({
  deleteFranchiseModalOpenButton: {
    transform: 'unset',
    position: 'unset',
  },
  deleteFranchiseModalContainer: {
    '& .MuiDialog-paper': {
      borderRadius: '8px',
      padding: '24px',
    },
  },
  deleteFranchiseModalContent: {
    padding: 'unset',
    '& .MuiFormLabel-root.Mui-focused': {
      color: onInputFocusPurple,
    },
  },
  deleteFranchiseModalHeader: {
    padding: 'unset',
    fontFamily: font,
    fontWeight: '600',
    color: fieldColor,
    letterSpacing: '0',
  },
  deleteFranchiseModalSubheader: {
    margin: 'unset',
    fontSize: '14px',
    fontFamily: font,
    lineHeight: '20px',
    letterSpacing: '0',
    padding: '12px 0px 24px 0px',
  },
  deleteFranchiseModalInputLabel: {
    fontSize: '14px',
    fontFamily: font,
    fontWeight: '600',
    color: fieldColor,
    lineHeight: '20px',
    letterSpacing: '0',
    paddingBottom: '6px',
    '& span': {
      fontStyle: 'italic',
    },
  },
  deleteFranchiseModalInputField: {
    paddingBottom: '24px',
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${onInputHoverPurple}`,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: `1px solid ${onInputFocusPurple}`,
      },
    },
    '& div': {
      '& input': {
        '&::placeholder': {
          fontStyle: 'italic',
        },
      },
    },
  },
  deleteFranchiseModalCancelButton: {
    margin: '0px',
    width: '100%',
    borderRadius: '8px',
    color: buttonPurple,
    textTransform: 'none',
    maxWidth: 'fit-content',
  },
  deleteFranchiseModalSaveButton: {
    color: 'white',
    padding: '6px 15px',
    borderRadius: '8px',
    textTransform: 'none',
    margin: '0px 0px 0px 12px',
    backgroundColor: buttonPurple,
    '&:disabled': {
      backgroundColor: '#E4E7EC',
      color: '#929FB4',
    },
    '&:hover': {
      color: 'white',
      backgroundColor: onHoverButtonPurple,
    },
  },
}));

export default deleteFranchiseModalStyles;
