import Cookies from 'universal-cookie';
import { handleActions } from 'redux-actions';

import { logout } from '../Actions/auth';
import {
  updateUserDetails,
  updateUserAvatar,
  setUploadingAvatar,
} from '../Actions/user';

const COOKIE_USER_INFO = 'userInfo';

const cookies = new Cookies();
const userInfo = cookies.get(COOKIE_USER_INFO);

const defaultState = {
  email: userInfo?.email || '',
  firstName: userInfo?.firstName || '',
  lastName: userInfo?.lastName || '',
  role: userInfo?.role || '',
  avatar: userInfo?.avatar || {
    fullsizeUrl: '',
    thumbnailUrl: '',
  },
  timezone: userInfo?.timezone || '',
  unitSystem: userInfo?.unitSystem || '',
  userFranchise: userInfo?.userFranchise || null,
  userCustomer: userInfo?.userCustomer || null,
  isAvatarUploading: false,
};

export default handleActions({
  [logout]: () => {
    cookies.remove(COOKIE_USER_INFO);

    return defaultState;
  },

  // User Details
  [updateUserDetails]: (state, { payload: details }) => {
    // Store user data in a cookie so it persists between tabs
    if (details) {
      cookies.set(COOKIE_USER_INFO, details, { path: '/', sameSite: 'lax' });
    }
    else {
      cookies.remove(COOKIE_USER_INFO);
    }

    return { ...state, ...details };
  },
  [updateUserAvatar]: (state, { payload: avatar }) => ({ ...state, avatar }),
  [setUploadingAvatar]: (state, { payload: isUploading }) => ({ ...state, isAvatarUploading: isUploading }),
}, defaultState);