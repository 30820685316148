import React from 'react';
import { compose } from 'redux';
import { DateTime } from 'luxon';

import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
} from '@mui/material';

import {
  ContentCopy as CopyIcon,
  Download as DownloadIcon,
} from '@mui/icons-material';

import PaperSection from '../../../Components/PaperSection';
import Map from '../../../Components/Map';
import TooltipIconButton from '../../../Components/TooltipIconButton';

import { NAV_TYPES_ARRAY } from '../../../../Utils/NavTypes';
import { formatDate, formatTime, formatDateTime } from '../../../../Utils/TimeUtils';
import { useWindowDimensions } from '../../../../Utils/ScreenSize';
import { convertEventToMapMarker } from '../../../Components/Map/Utils';

import withLocationData from '../../../../redux/WithData/location';
import withLocationActions from '../../../../redux/WithActions/location';

const MAX_POSITIONS = 10;

const TOOLTIP_COPY_W3W = 'Copy W3W';
const TOOLTIP_COPY_ADDRESS = 'Copy Address';
const TOOLTIP_COPIED = 'Copied to clipboard!';

const position = {
  type: 'gpsHi',
  timestamp: '2022-06-10T12:00:00',
  w3w: 'Apple Bacon Carrot',
  address: 'Unknown',
  pos: {
    lat: 51.00202586493287,
    lng: -0.9473548785898962
  }
}

const MapSection = ({
  zones,
  hightlightedZoneIdx,
  newZonePath,
  onEditPath,
  onLoadEditablePolygon,
  onUnmountEditablePolygon,
  sx,

  selectedLocations,
  getLocationAddress,
}) => {
  const { isMobileWidth, isTabletWidth } = useWindowDimensions();

  const [positions, setPositions] = React.useState([]);
  const [addressDownloadable, setAddressDownloadable] = React.useState(false);

  // Convert location data into map markers
  React.useEffect(() => {
    if (selectedLocations && selectedLocations.length > 0) {
      let newPositions = [];
      let newPos;

      for (let i = 0; i < selectedLocations.length && newPositions.length < MAX_POSITIONS; i++) {
        newPos = convertEventToMapMarker(selectedLocations[i]);

        if (newPos) {
          newPositions.push(newPos);
        }
      }

      setPositions(newPositions);

      // If the address is blank, it can be requested
      setAddressDownloadable(!newPositions[0]?.address);
    }
    else {
      setPositions([]);
    }
  }, [
    selectedLocations,
  ]);

  const handleClickCopyW3W = () => {
    if (positions[0]?.w3w) {
      navigator.clipboard.writeText(positions[0].w3w);
    }
  }

  const handleClickCopyAddress = () => {
    if (addressDownloadable) {
      getLocationAddress(positions[0].id);
    }
    else {
      navigator.clipboard.writeText(positions[0].address);
    }
  }

  const mapCentre = {
    lat: 51.0030048,
    lng: -0.9494693
  };
  
  let mapSx = undefined;

  if (isMobileWidth) {
    mapSx = { height: '300px' };
  }
  else if (isTabletWidth) {
    mapSx = { height: '400px' };
  }

  const dateTime = DateTime.fromISO(positions[0]?.timestamp);

  return (
    <PaperSection sx={{ display: 'flex', flexDirection: 'column', overflow: 'auto', p: 0, ...sx }}>
      <Map
        fullMap
        centre={mapCentre}
        positions={positions}
        zones={zones}
        hightlightedZoneIdx={hightlightedZoneIdx}
        newZonePath={newZonePath}
        onEditPath={onEditPath}
        onLoadEditablePolygon={onLoadEditablePolygon}
        onUnmountEditablePolygon={onUnmountEditablePolygon}
        sx={mapSx}
      />

      <Divider/>

      <Box sx={{ display: 'flex', flexDirection: 'row', m: 2 }}>
        <Stack spacing={1} sx={{ width: '100%' }}>
          {isMobileWidth ? (
            <>
              <Stack direction="row" spacing={1} sx={{ m: '0 auto' }}>
                <Typography>*Vodafone*</Typography>
                <Divider orientation="vertical"/>
                <Typography>*81%*</Typography>
                <Divider orientation="vertical"/>
                <Typography>{formatDateTime(dateTime, true)}</Typography>
              </Stack>

              <Stack direction="row" spacing={1} sx={{ m: '0 auto' }}>
                {NAV_TYPES_ARRAY.map(nav => (
                  <Button key={nav.id} value={nav.id} variant="outlined" color={nav.colour}>{nav.label}</Button>
                ))}
              </Stack>
            </>
          ) : (
            <Box sx={{ display: 'flex', flexDirection: 'row'}}>
              <Typography sx={{ m: 'auto 0', minWidth: '100px' }}>Ping</Typography>
              <Stack direction="row" spacing={1}>
                {NAV_TYPES_ARRAY.map(nav => (
                  <Button key={nav.id} value={nav.id} variant="outlined" color={nav.colour}>{nav.label}</Button>
                ))}
              </Stack>
            </Box>
          )}
          
          <Box sx={{ display: 'flex', flexDirection: 'row'}}>
            <Typography sx={{ m: 'auto 0', minWidth: '100px' }}>W3W</Typography>
            <Typography sx={{ m: 'auto 0' }}>{positions[0]?.w3w || '-'}</Typography>
            <TooltipIconButton
              tooltip={TOOLTIP_COPY_W3W}
              tooltipAfterClick={TOOLTIP_COPIED}
              icon={<CopyIcon/>}
              onClick={handleClickCopyW3W}
              sx={{ ml: 'auto' }}
            />
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'row'}}>
            <Typography sx={{ m: 'auto 0', minWidth: '100px' }}>Address</Typography>
            <Typography sx={{ m: 'auto 0' }}>{positions[0]?.address || '-'}</Typography>
            <TooltipIconButton
              tooltip={!addressDownloadable ? TOOLTIP_COPY_ADDRESS : 'Request location address'}
              tooltipAfterClick={!addressDownloadable && TOOLTIP_COPIED}
              icon={addressDownloadable ? <DownloadIcon/> : <CopyIcon/>}
              onClick={handleClickCopyAddress}
              sx={{ ml: 'auto' }}
            />
          </Box>
        </Stack>

        {!isMobileWidth && (
          <>
            <Divider orientation="vertical" sx={{ ml: 2 }}/>
            <Stack spacing={1} sx={{ mt: 'auto', mb: 'auto', ml: 2 }}>
              <Typography sx={{ m: '0 auto' }}>*Vodafone*</Typography>
              <Typography sx={{ m: '0 auto' }}>*81%*</Typography>
              <Typography sx={{ m: '0 auto' }}>{formatDate(dateTime)}</Typography>
              <Typography sx={{ m: '0 auto' }}>{formatTime(dateTime, true)}</Typography>
            </Stack>
          </>
        )}
      </Box>
    </PaperSection>
  );
}

export default compose(withLocationData, withLocationActions)(MapSection);