import React from 'react';

import {
  Box,
  Stack,
} from '@mui/material';

import AssetSection from './AssetSection';
import LogsSection from './LogsSection';
import MapSection from './MapSection';
import UnitSection from './UnitSection';

import { useWindowDimensions } from '../../../../Utils/ScreenSize';

const OverviewTab = () => {
  const { isTabletWidth, isDesktopWidth } = useWindowDimensions();

  // Desktop View
  if (isDesktopWidth) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%', mr: 0.5 }}>
          <UnitSection sx={{ height: '100%', mb: 1 }}/>
          <LogsSection/>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%', ml: 0.5 }}>
          <MapSection sx={{ height: '100%', mb: 1 }}/>
          <AssetSection/>
        </Box>
      </Box>
    );
  }
  // Tablet/Mobile View
  else {
    return (
      <Stack direction="column" spacing={2} sx={isTabletWidth ? { m: 2 } : { mt: 2 }}>
        <MapSection/>
        <UnitSection/>
        <AssetSection/>
        <LogsSection/>
      </Stack>
    );
  }
}

export default OverviewTab;