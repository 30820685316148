export const NAV_TYPES = {
  GPS: {
    id: 'GPS',
    label: 'GPS-Hi',
    colour: 'locationGpsHi',
  },
  GPS_LO: {
    id: 'GPS_LO',
    label: 'GPS-Lo',
    colour: 'locationGpsLo',
  },
  WIFI: {
    id: 'WIFI',
    label: 'WiFi',
    colour: 'locationWifi',
  },
  GSM: {
    id: 'GSM',
    label: 'GSM',
    colour: 'locationGsm',
  },
};

export const NAV_TYPES_ARRAY = [
  NAV_TYPES.GPS,
  // NAV_TYPES.GPS_LO,
  NAV_TYPES.WIFI,
  NAV_TYPES.GSM,
];