import { handleActions } from 'redux-actions';

import {
  loadNewAccount,
  creatingNewAccount,
  loadPaginatedAccounts,
  loadingPaginatedAccounts,
  stopLoadingPaginatedAccounts,
} from '../Actions/AccountActions';

const initialState = {
  accounts: [],
  areAccountsLoading: false,
  isCreatingNewCustomer: false,
};

export default handleActions({
  [loadingPaginatedAccounts]: (state) => ({
    ...state,
    areAccountsLoading: true,
  }),
  [stopLoadingPaginatedAccounts]: (state) => ({
    ...state,
    areAccountsLoading: false,
  }),
  [loadPaginatedAccounts]: (state, { payload: accounts }) => ({
    ...state,
    accounts,
    areAccountsLoading: false,
  }),
  [creatingNewAccount]: (state) => ({
    ...state,
    isCreatingNewAccount: true,
  }),
  [loadNewAccount]: (state, { payload: account }) => ({
    ...state,
    accounts: [
      account,
      ...state.accounts,
    ],
    isCreatingNewCustomer: false,
  }),
}, initialState);
