import React from 'react';
import { compose } from 'redux';
import { Link } from 'react-router-dom';

import { Typography } from '@mui/material';

import lastViewedTrackerCardStyles from './style';
import withTrackersData from '../../../Hocs/WithData/tracker';
import TrackerImage from '../../Trackers/Elements/trackerImage';

const LastViewedTrackerCard = ({ lastViewedTracker, currentTracker }) => {
  const classes = lastViewedTrackerCardStyles();

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.textContainer}>
          <Typography className={classes.header}>
            Serial Number
          </Typography>
          <Typography className={classes.typography}>
            {lastViewedTracker.simiccid}
          </Typography>
          <Typography className={classes.header}>
            Name
          </Typography>
          <Typography className={classes.typography}>
            {lastViewedTracker.name}
          </Typography>
        </div>
        <TrackerImage tracker={currentTracker} />
      </div>
      <Link className={classes.linkButton} to={`/trackerDetails_old/${lastViewedTracker.simiccid}`}>
        INSPECT TRACKER
      </Link>
    </div>
  );
};
export default compose(withTrackersData)(LastViewedTrackerCard);
