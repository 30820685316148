import React from 'react';
import { compose } from 'redux';

import { NavLink } from 'react-router-dom';

import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import {
  Menu as MenuIcon,
  ChevronLeft as CloseIcon,
} from '@mui/icons-material/';

import makeStyles from '@mui/styles/makeStyles';

import getRoutes from '../../../Routes/menuRoutes';
import { MENU_DRAWER_WIDTH } from '../../../Utils/Defines';

import withUserData from '../../../redux/WithData/user';

// Use 'makeStyles' for non-MUI components
const customStyles = makeStyles((theme) => ({
  navLink: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '& .MuiSvgIcon-root': {
      color: theme.palette.text.secondary,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.active': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      '& .MuiSvgIcon-root': {
        color: theme.palette.primary.contrastText,
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
  listItem: {
    height: '68px',
    color: 'inherit',
    backgroundColor: 'inherit',
    "&:hover": {
      backgroundColor: 'inherit',
    },
  },
  listItemIcon: {
    color: 'inherit',
  },
}));

const CustomDrawer = ({
  barHeight,
  isOpen,
  handleCloseDrawer,

  role,
}) => {
  const classes = customStyles();
  const routes = getRoutes(role);
  
  return (
    <Drawer
      open={isOpen}
      anchor="left"
      variant="temporary"
      onClose={handleCloseDrawer}
      sx={{
        width: `${MENU_DRAWER_WIDTH}px`,
        '& .MuiPaper-root': {
          width: `${MENU_DRAWER_WIDTH}px`,
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: `${barHeight}px` }}>
        <IconButton onClick={handleCloseDrawer} sx={{ m: 'auto', mr: 2 }}>
          <CloseIcon/>
        </IconButton>

        <Divider/>
      </Box>

      <List sx={{ p: 0 }}>
        {routes.map((route) => (
          <NavLink
            key={route.id}
            to={route.linkTo}
            onClick={handleCloseDrawer}
            exact={route.linkTo === '/'}
            className={classes.navLink}
          >
            <ListItem button key={route.label} className={classes.listItem}>
              <ListItemIcon className={classes.listItemIcon}>
                {route.icon}
              </ListItemIcon>
              <ListItemText primary={route.label} />
            </ListItem>
          </NavLink>
        ))}
      </List>
    </Drawer>
  )
};

export default compose(withUserData)(CustomDrawer);
