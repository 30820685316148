import React from 'react';
import { Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import {
  mobile,
  fieldColor,
  valueColor,
  semiBoldFont,
} from '../../../../Styles/styles';

const trackerInfoRowStyles = makeStyles((theme) => (
  {
    container: {
      padding: '25px',
    },
    infoRow: {
      display: 'flex',
      padding: '12px 0px',
    },
    text: {
      fontFamily: semiBoldFont,
      fontSize: '14px',
      width: '100%',
      letterSpacing: '0',
      lineHeight: '20px',
    },
    field: {
      color: fieldColor,
      fontWeight: '600',
    },
    value: {
      color: valueColor,
    },
    [theme.breakpoints.down(mobile)]: {
      display: 'flex',
    },
  }
));

const RowComponent = ({ field, value }) => {
  const classes = trackerInfoRowStyles();

  return (
    <div className={classes.infoRow}>
      <Typography className={`${classes.text} ${classes.field}`} variant="h5">
        {field}
      </Typography>
      <Typography className={`${classes.text} ${classes.value}`}>
        {value}
      </Typography>
    </div>
  );
};

export default RowComponent;
