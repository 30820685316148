import { createAction } from 'redux-actions';

const ENTITY = '@@CUSTOMER';

// Customer List
export const getCustomer = createAction(`${ENTITY}/GET_CUSTOMER`);
export const getAllCustomers = createAction(`${ENTITY}/GET_ALL_CUSTOMERS`);
export const loadingAllCustomers = createAction(`${ENTITY}/LOADING_ALL_CUSTOMERS`);
export const stopLoadingAllCustomers = createAction(`${ENTITY}/STOP_LOADING_ALL_CUSTOMERS`);
export const loadAllCustomers = createAction(`${ENTITY}/LOAD_ALL_CUSTOMERS`);

// Create Customer
export const createNewCustomer = createAction(`${ENTITY}/CREATE_NEW_CUSTOMER`);
export const creatingNewCustomer = createAction(`${ENTITY}/CREATING_NEW_CUSTOMER`);
export const loadNewCustomer = createAction(`${ENTITY}/LOAD_NEW_CUSTOMER`);

// Edit customer
export const editCustomerRequest = createAction(`${ENTITY}/EDIT_CUSTOMER_REQUEST`);
export const cancelEditCustomer = createAction(`${ENTITY}/CANCEL_EDIT_CUSTOMER`);
export const editingCustomer = createAction(`${ENTITY}/EDITING_CUSTOMER`);
export const editCustomer = createAction(`${ENTITY}/EDIT_CUSTOMER`);

// Generate new Customer Secret
export const generateNewCustomerSecret = createAction(`${ENTITY}/GENERATE_NEW_CUSTOMER_SECRET`);
export const generatingNewCustomerSecret = createAction(`${ENTITY}/GENERATING_NEW_CUSTOMER_SECRET`);
export const resetCustomerSecret = createAction(`${ENTITY}/RESET_CUSTOMER_SECRET`);
export const loadNewCustomerSecret = createAction(`${ENTITY}/LOAD_NEW_CUSTOMER_SECRET`);
