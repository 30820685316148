import { compose } from 'redux';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import FavoritesIcon from '@mui/icons-material/Star';
import FavoritesIconOutlined from '@mui/icons-material/StarBorder';
import {
  Paper,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
} from '@mui/material';

import trackersTableStyles from './style';
import withTrackersData from '../../../Hocs/WithData/tracker';
import withTrackersActions from '../../../Hocs/WithAction/tracker';

const columns = [
  { id: 'simiccid', label: 'Serial' },
  { id: 'name', label: 'Name' },
  { id: 'msisdn', label: 'Tel' },
  { id: 'type', label: 'Type' },
  { id: 'franchise', label: 'Franchise' },
  { id: 'location', label: 'Location' },
  { id: 'favorite', label: 'Favourite' },
];

function TrackersTable({
  trackers,
  favoriteTrackers,
  areTrackersLoading,
  trackersListFilter,
  getPaginatedTrackers,
  totalNumberOfTrackers,
  recentlyViewedTrackers,
  addTrackerToFavouritesReq,
  removeTrackerFromFavouritesReq,
}) {
  const history = useHistory();
  const classes = trackersTableStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const filteredTrackers = () => {
    switch (trackersListFilter) {
      case 'recent':
        return trackers
          .filter((tracker) => recentlyViewedTrackers
            .find((recentlyViewedTracker) => recentlyViewedTracker.simiccid === tracker.simiccid));
      case 'favorites':
        return trackers
          .filter((tracker) => favoriteTrackers
            .find((favoriteTracker) => favoriteTracker.simiccid === tracker.simiccid));
      case 'all':
      default:
        return trackers;
    }
  };

  useEffect(() => {
    getPaginatedTrackers({ limit: rowsPerPage });
  }, [getPaginatedTrackers, rowsPerPage]);

  const handleChangePage = (_, newPage) => {
    getPaginatedTrackers({ offset: newPage * rowsPerPage, limit: rowsPerPage });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = ({ target }) => {
    setRowsPerPage(target.value);
    setPage(0);
  };

  const handleRowClick = (trackerId) => {
    history.push(`/trackerDetails_old/${trackerId}`);
  };

  const handleFavoriteIconClick = (event, { tracker, isTrackerFavorite }) => {
    event.stopPropagation();
    return isTrackerFavorite
      ? removeTrackerFromFavouritesReq(tracker.simiccid)
      : addTrackerToFavouritesReq(tracker);
  };

  if (areTrackersLoading) {
    return <CircularProgress className={classes.spinner} />;
  }

  return (
    <Paper className={classes.paper}>
      <TableContainer>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {columns.map((column) => (<TableCell key={column.id}>{column.label}</TableCell>))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(filteredTrackers().length === 0)
              ? (
                <TableRow>
                  <TableCell component="th" scope="row">
                    No trackers found
                  </TableCell>
                </TableRow>
              )
              : filteredTrackers().map((tracker) => {
                const isTrackerFavorite = favoriteTrackers
                  .find((favoriteTracker) => favoriteTracker.simiccid === tracker.simiccid);

                return (
                  <TableRow
                    key={tracker.simiccid}
                    className={classes.tableRow}
                    onClick={() => handleRowClick(tracker.simiccid)}
                  >
                    <TableCell component="th" scope="row">
                      {tracker.simiccid}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {tracker.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {tracker.msisdn}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {tracker.type}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {tracker.franchise.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {tracker.location.name}
                    </TableCell>
                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>
                      {tracker.updating
                        ? (
                          <IconButton size="large">
                            <CircularProgress className={classes.spinner} size={24} />
                          </IconButton>
                        )
                        : (
                          <IconButton
                            className={classes.starIconButton}
                            onClick={(event) => handleFavoriteIconClick(event, {
                              tracker: {
                                simiccid: tracker.simiccid,
                                msisdn: tracker.msisdn,
                                name: tracker.name,
                              },
                              isTrackerFavorite,
                            })}
                            size="large">
                            {isTrackerFavorite
                              ? (<FavoritesIcon />)
                              : (<FavoritesIconOutlined />)}
                          </IconButton>
                        )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {filteredTrackers().length !== 0 && (
        <TablePagination
          page={page}
          component="div"
          rowsPerPage={rowsPerPage}
          count={totalNumberOfTrackers}
          className={classes.pagination}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[5, 10, 30, 50, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
}

export default compose(withTrackersData, withTrackersActions)(TrackersTable);
