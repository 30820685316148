import { connect } from 'react-redux';
import {
  setTrackerLocationFilter,
  selectLocationEventAction,
  changeEventDetailsTabAction,
} from '../../../redux/Actions/TrackerActions';

export default (WrappedComponent) => connect(
  null,
  {
    setTrackerLocationFilterAction: (filterName) => setTrackerLocationFilter(filterName),
    changeEventDetailsTab: (tabType) => changeEventDetailsTabAction(tabType),
    selectLocationEvent: (event) => selectLocationEventAction({ event, force: false }),
    selectEventById: ({ eventID, force = false }) => selectLocationEventAction({ event: { id: eventID }, force }),
  },
)(WrappedComponent);
