import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';

import {
  Paper,
  Table,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  CircularProgress,
} from '@mui/material';

import TableHead from './TableHead';
import stableSort from './stableSort';
import TableToolbar from './TableToolbar';
import getComparator from './getComparator';
import ContentWrapper from '../Elements/ContentWrapper';

const EnhancedTable = (props) => {
  const {
    className = '',
    columns,
    data: trackerList,
    title = 'Table',
    onDataChange,
    offset,
    limit = 5,
    changeLimit,
    nextPage,
    prevPage,
    hasRowColumn = true,
    onClick = () => {},
  } = props;

  let loading = !trackerList;
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(limit);
  const list = trackerList || {};
  const { rows = [], totalCount = 0 } = list;
  const page = 0;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    const params = {
      sorters: [{
        dir: newOrder,
        name: property,
      }],
    };
    loading = true;
    onDataChange(params);
    setOrder(newOrder);
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.simiccid);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    if (newPage > 0) nextPage();
    else prevPage();
  };

  const handlePaginationDisplay = ({ count }) => {
    const from = offset + 1;
    const to = offset + rows.length;
    if (to > rowsPerPage) setRowsPerPage(limit);
    return `Items ${from}-${to} of ${count}`;
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    loading = true;
    changeLimit(newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const currentPage = Math.ceil((offset + rows.length) / rowsPerPage);
  const lastPage = Math.ceil(totalCount / rowsPerPage);
  return (
    <ContentWrapper className={`table-container helios-table ${className}`}>
      <Paper className="paper">
        {(loading || trackerList === undefined) && (
          <div className="loading">
            <CircularProgress />
          </div>
        )}
        <TableToolbar title={title} numSelected={selected.length} />
        <TableContainer className="helios-table-container">
          <Table
            className="table"
          >
            <TableHead
              hasRowColumn={hasRowColumn}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={columns}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const rowNumber = index + 1;
                  const rowKey = row.simiccid;
                  const rowValues = Object.values(row);
                  const isItemSelected = isSelected(rowKey);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`${rowKey}${Math.random()}`}
                      selected={isItemSelected}
                    >
                      {hasRowColumn && (
                      <>
                        <TableCell
                          padding="checkbox"
                          className="sticky-column"
                          onClick={(event) => handleClick(event, rowKey)}
                        >
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>
                        <TableCell component="th" id={labelId} className="sticky-column">
                          {rowNumber + offset}
                        </TableCell>
                      </>
                      )}
                      {rowValues.map((rowValue, rowIndex) => (
                        <TableCell
                          key={uuid()}
                          onClick={() => onClick(index, rowIndex)}
                        >
                          {rowValue}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          page={page}
          component="div"
          count={totalCount}
          className="pagination"
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          labelDisplayedRows={handlePaginationDisplay}
          onRowsPerPageChange={handleChangeRowsPerPage}
          backIconButtonProps={{ disabled: !(currentPage > 1) }}
          nextIconButtonProps={{ disabled: !(currentPage < lastPage) }}
        />
      </Paper>
    </ContentWrapper>
  );
};
export default EnhancedTable;
