import { connect } from 'react-redux';

const mapStateToProps = ({
  AuthData: {
    isLoggedIn,
    auth: {
      accessToken,
    },
    isSubmitting,
  },
}) => ({
  isLoggedIn,
  accessToken,
  isSubmitting,
});

export default (WrappedComponent) => connect(mapStateToProps, null)(WrappedComponent);
