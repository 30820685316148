import React from 'react';
import { compose } from 'redux';
import { Paper, Typography, Button } from '@mui/material';

import withTrackersActions from '../../../../Hocs/WithAction/tracker';

export const EventModalSideBar = ({
  setActiveTab,
  selectTrackerEvent,
  selectedEvent: {
    details,
    id: eventId,
  },
}) => {
  const isGPSEvent = details && details.gps;

  const handleViewOnMapClick = () => {
    selectTrackerEvent(eventId);
    setActiveTab('map');
  };

  return (
    <>
      {isGPSEvent
        && (
        <>
          <Paper elevation={0} className="sats">
            <div className="box-sats">
              <Typography component="h2">{details?.gps.satellites || '-'}</Typography>
              SATS
            </div>
          </Paper>
          <Paper className="speed">
            <div className="box">
              <Typography component="h2">COURSE</Typography>
              {details?.gps.bearing || '-'}
            </div>
          </Paper>
          <Paper className="course">
            <div className="box">
              <Typography component="h3">SPEED</Typography>
              <Typography component="h2">
                {`${details?.gps.speed || '-'} km/h`}
              </Typography>
            </div>
          </Paper>
        </>
        )}
      <Paper className="location">
        <div className="box">
          <Typography component="h3">LOCATION</Typography>
          {details && details.position.w3w.split('.').map((word) => (
            <Typography key={word}>
              {word}
            </Typography>
          ))}
        </div>
      </Paper>
      <Button
        className="blueBoxViewMap"
        variant="contained"
        color="primary"
        onClick={handleViewOnMapClick}
      >
        View on Map
      </Button>
    </>
  );
};

export default compose(withTrackersActions)(EventModalSideBar);
