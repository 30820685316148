import {
  put,
  delay,
  select,
  takeLatest,
  putResolve,
} from 'redux-saga/effects';
import Cookies from 'universal-cookie';

import { authApiHandler } from './auth';
import UserAPI from '../../Api/Endpoints_OLD/user';
import { profileDetails } from './selectors';
import { addNotification } from '../Actions/system';
import { ResponseTypes } from '../../Api/Responses/baseResponse';
import {
  resetPassword,
  changePassword,
  getProfileDetails,
  uploadProfileImage,
  updateProfileAction,
  saveProfileTimezone,
  updateProfileTimezone,
  persistProfileDetails,
  loadDefaultProfileImage,
} from '../Actions/UserActions';

export function handlePersistProfileDetails({ payload }) {
  const cookies = new Cookies();
  cookies.set('profileInfo', payload, { path: '/', sameSite: 'lax' });
}

export function* handleGetProfileDetails() {
  const username = yield select((state) => state.AuthData_NEW.email);
  const response = yield authApiHandler(UserAPI.getProfile, username);

  if (ResponseTypes.ActionCompleted === response.type) {
    const details = {
      role: response.data.role,
      timezone: response.data.timezone,
      username: response.data.username,
      lastName: response.data.lastName,
      firstName: response.data.firstName,
      customer: {
        id: response.data.customer?.uuid || '',
        name: response.data.customer?.name || '',
      },
      franchise: {
        id: response.data.franchise.uuid,
        name: response.data.franchise.name,
      },
    };
    yield put(persistProfileDetails(details));
    yield put(updateProfileAction({
      ...details,
      avatar: response.data.avatar,
    }));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(loadDefaultProfileImage());
    yield put(addNotification({
      type: 'error',
      source: 'handleGetProfileDetails',
      title: 'Could not load profile image',
      message: 'Please, check your connection and try again.',
    }));
  }
}

function* handleChangePassword({ payload: password }) {
  const response = yield UserAPI.changePassword(password);
  if (response.type === ResponseTypes.ActionCompleted) {
    yield put(addNotification({
      type: 'success',
      title: 'Password successfully changed!',
      source: 'handleChangePassword',
      message: 'Your password was successfully updated.',
    }));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      source: 'handleChangePassword',
      title: 'Could not change password',
      message: 'Please, check your connection and try again.',
    }));
  }
}

function* handleResetPassword({ payload: email }) {
  const response = yield UserAPI.resetPassword(email);
  if (response.type === ResponseTypes.ActionCompleted) {
    yield put(addNotification({
      type: 'success',
      source: 'handleResetPassword',
      title: 'Password successfully reset!',
      message: "You'll receive a confirmation email to complete the process.",
    }));
  } else if (response.type === ResponseTypes.NotFound) {
    yield put(addNotification({
      type: 'error',
      source: 'handleResetPassword',
      title: 'Email not registered!',
      message: 'Please check if you typed your email correctly or try with another email.',
    }));
  } else if (response.type === ResponseTypes.ClientError) {
    yield put(addNotification({
      type: 'error',
      title: 'Email not verified!',
      source: 'handleResetPassword',
      message: 'Please check your inbox and verify your email before resetting the password.',
    }));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      source: 'handleResetPassword',
      title: 'Could not reset password',
      message: 'Please, check your connection and try again.',
    }));
  }
}

export function* handleUploadProfileImage({ payload: imageFile }) {
  const response = yield authApiHandler(UserAPI.uploadProfileImage, imageFile);
  if (ResponseTypes.ActionCompleted === response.type) {
    // Delay the redux store update to wait for AWS "S3"
    // to update the profile image resource behind the thumbnailUrl
    // Alternatively, fix the backend [JIRA-299]
    yield delay(1000);
    yield put(getProfileDetails());
  } else if (response.type === ResponseTypes.Exception) {
    yield put(loadDefaultProfileImage());
    yield put(addNotification({
      type: 'error',
      title: 'Could upload image',
      source: 'handleUploadProfileImage',
      message: 'Please, check your connection and try again.',
    }));
  }
}

export function* handleUpdateProfileTimezone({ payload: timezone }) {
  const profileData = yield select(profileDetails);
  const response = yield authApiHandler(UserAPI.editProfile, { ...profileData, timezone });
  if (response.type === ResponseTypes.ActionCompleted) {
    yield putResolve(saveProfileTimezone(timezone));
    yield put(addNotification({
      type: 'success',
      title: 'Timezone successfully changed!',
      source: 'handleUpdateProfileTimezone',
      message: 'Your timezone was successfully updated.',
    }));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      source: 'handleUpdateProfileTimezone',
      title: 'Could not update your timezone',
      message: 'Please, check your connection and try again.',
    }));
  } else {
    yield put(addNotification({
      type: 'error',
      source: 'handleUpdateProfileTimezone',
      title: 'Could not update your timezone',
      message: 'Your timezone update was unsuccessful. Please, try again in a few minutes',
    }));
  }
}

export function LogoutRedirection() {
  window.location.href = '/';
}

export default function* UserSagas() {
  yield takeLatest(resetPassword, handleResetPassword);
  yield takeLatest(changePassword, handleChangePassword);
  yield takeLatest(getProfileDetails, handleGetProfileDetails);
  yield takeLatest(uploadProfileImage, handleUploadProfileImage);
  yield takeLatest(updateProfileTimezone, handleUpdateProfileTimezone);
  yield takeLatest(persistProfileDetails, handlePersistProfileDetails);
}
