import React from 'react';

import {
  Box,
  Button,
  Typography,
} from '@mui/material';

import {
  Refresh as RefreshIcon,
} from '@mui/icons-material';

import { useWindowDimensions } from '../../../Utils/ScreenSize';

const ResultsCounter = ({
  rowCount,
  totalCount,
  onClickRefresh,
  sx,
}) => {
  const { isMobileWidth } = useWindowDimensions();

  const getCounter = (counterSx) => (
    <Typography sx={counterSx}>
      {!isMobileWidth ? 
      `${rowCount}/${totalCount} result${rowCount !== 1 ? 's' : ''}` :
      `${rowCount}/${totalCount}`}
    </Typography>
  );

  return onClickRefresh ? (
    <Box sx={{ ...sx, display: 'flex', flexDirection: 'row' }}>
      {onClickRefresh && (
        <Button variant="contained" startIcon={<RefreshIcon />} onClick={onClickRefresh} sx={{ mr: 2 }}>
          Refresh
        </Button>
      )}
      {getCounter({ m: 'auto 0' })}
    </Box>
  ) : getCounter(sx);
}

export default ResultsCounter;