import React from 'react';

import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
} from '@mui/material';

import {
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';

const PasswordField = ({
  required = true,
  fullWidth = true,
  size = 'small',
  autoComplete,
  type = 'text',
  name = 'password',
  label = 'Password',
  value,
  onChange,
  helperText,
  FormHelperTextProps,
  ...other
}) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const visibleType = type;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl
      required={required}
      className="PasswordField"
      margin="dense"
      fullWidth={fullWidth}
      size={size}
      {...other}
    >
      <InputLabel>{label}</InputLabel>
      <OutlinedInput
        autoComplete={autoComplete}
        type={showPassword ? visibleType : 'password'}
        name={name}
        label={label}
        value={value}
        onChange={onChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      {helperText && (
        <FormHelperText {...FormHelperTextProps}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
};

export default PasswordField;