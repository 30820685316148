import React from 'react'

import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';

import { sortArrayAlphabetically } from '../../../Utils/Utils';

const MultiSelectField = ({
  name,
  label,
  value,
  items,
  required = true,
  fullWidth = true,
  size = 'small',
  onChange,
  ...props
}) => {
  const renderValue = (values) => {
    return sortArrayAlphabetically(value, 'label').reduce((text, value, idx) => {
      if (idx === 0) {
        return `${value.label}`;
      }
      else {
        return text + `, ${value.label}`;
      }
    },
    undefined);
  }

  return (
    <FormControl fullWidth={fullWidth} margin="dense" size={size} {...props}>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        labelId={`${name}-label`}
        multiple
        name={name}
        value={value}
        onChange={onChange}
        input={<OutlinedInput label={label} />}
        renderValue={renderValue}
        sx={{ textTransform: 'capitalize' }}
      >
        {items.map((item) => (
          <MenuItem key={item.id} value={item} sx={{ textTransform: 'capitalize' }}>
            <Checkbox checked={value.includes(item)} />
            <ListItemText primary={item.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default MultiSelectField;