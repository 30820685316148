import React from 'react';
import { useTheme } from '@mui/material/styles';
import { DateTime } from 'luxon';

import {
  Box,
  IconButton,
  Divider,
  Typography,
} from '@mui/material';

import {
  Delete as DeleteIcon,
} from '@mui/icons-material';

import { formatDateTime } from '../../../../Utils/TimeUtils';

const USER_WIDTH = '240px';

const Note = ({
  note,
  onDelete,
  sx,
}) => {
  const theme = useTheme();
  const SPACING = parseInt(theme.spacing(1));

  return (
    <Box key={note.id} sx={theme => ({
      ...sx,
      pl: 3, pr: 3, pt: 2,
      color: note.deleted ? theme.palette.text.disabled : theme.palette.text.primary,
      '.MuiIconButton-root': {
        visibility: 'hidden',
      },
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
        '.MuiIconButton-root': {
          visibility: 'visible',
        },
      },
    })}>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: USER_WIDTH }}>
          <Typography sx={{ mb: 1 }}>{formatDateTime(DateTime.fromISO(note.timestamp), true)}</Typography>
          <Typography>{note.whoCreated}</Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2, mr: note.deleted ? `${40 + SPACING}px` : 2 }}>
          <Typography sx={{ minHeight: `${48 + SPACING}px` }}>{note.text}</Typography>

          {note.deleted && (
            <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1 }}>
              <Typography>Deleted:</Typography>
              <Typography sx={{ ml: 2, mr: 2, width: USER_WIDTH }}>{note.whoDeleted}</Typography>
              <Typography>{formatDateTime(DateTime.fromISO(note.timestampDeleted), true)}</Typography>
            </Box>
          )}
        </Box>

        {!note.deleted && (
          <IconButton onClick={() => onDelete(note)} sx={{ ml: 'auto', mb: 'auto', mt: '-8px' }}>
            <DeleteIcon/>
          </IconButton>
        )}
      </Box>
      
      <Divider sx={{ mt: 2 }}/>
    </Box>
  );
}

export default Note;