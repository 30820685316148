import { createAction } from 'redux-actions';

const ENTITY = '@@AUTH_NEW';

  // Sign in/up
export const login = createAction(`${ENTITY}/LOGIN`);
export const logout = createAction(`${ENTITY}/LOGOUT`);
export const resetPassword = createAction(`${ENTITY}/RESET_PASSWORD`);
export const registerUser = createAction(`${ENTITY}/REGISTER_USER`);

export const setLoggedIn = createAction(`${ENTITY}/SET_LOGGED_IN`);
export const setLoggingIn = createAction(`${ENTITY}/SET_LOGGING_IN`);
export const setRegistering = createAction(`${ENTITY}/SET_REGISTERING`);

  // Access/Refresh Tokens
export const updateTokens = createAction(`${ENTITY}/UPDATE_TOKENS`);
