import React from 'react';
import { compose } from 'redux';
import { v4 as uuid } from 'uuid';

import Alert from './NotificationItem';
import withSystemData from '../../redux/WithData/system';

// TODO: switch to Notistack (https://github.com/iamhosseindhv/notistack)

const SysNotification = ({ notifications }) => notifications.map((notification) => (
  <Alert
    key={uuid()}
    type={notification.type}
    title={notification.title}
    message={notification.message}
  />
));

export default compose(withSystemData)(SysNotification);
