import React, { useState } from 'react';

//import EssentialIcon from '@mui/icons-material/NotificationsActive';
import {
  Card,
  Typography,
  CardContent,
  TextareaAutosize,
} from '@mui/material';

import SubmitButtons from './SubmitButtons';

const RawTextTrackerSettings = ({
  areNewTrackerRawSettingsUpdating,
  updateTrackerRawSettingsRequest,
  currentTracker,
  classes,
}) => {
  const [newTrackerRawSettings, setNewTrackerRawSettings] = useState('');

  const handleChangeText = ({ target: { value } }) => {
    setNewTrackerRawSettings(value);
  };

  return (
    <Card className={classes.trackerSettings}>
      <CardContent>
        <Typography className={classes.trackerSettingsHeader}>
          Raw Tracker Commands
        </Typography>
        <TextareaAutosize
          id="textArea"
          className={classes.trackerTextArea}
          spellCheck={false}
          onChange={handleChangeText}
          value={newTrackerRawSettings}
        />
        <SubmitButtons
          classes={classes}
          currentTracker={currentTracker}
          newTrackerRawSettings={newTrackerRawSettings}
          setNewTrackerRawSettings={setNewTrackerRawSettings}
          updateTrackerRawSettingsRequest={updateTrackerRawSettingsRequest}
          areNewTrackerRawSettingsUpdating={areNewTrackerRawSettingsUpdating}
        />
      </CardContent>
    </Card>
  );
}

export default RawTextTrackerSettings;