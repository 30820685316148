import { compose } from 'redux';
import React, { useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  ListItemSecondaryAction,
} from '@mui/material';

import editCustomerModalStyles from './style';
import withCustomerData from '../../../Hocs/WithData/customer';
import withCustomerActions from '../../../Hocs/WithAction/customer';
import EditCustomerModalContent from './EditCustomerModalContent/EditCustomerModalContent';

const EditCustomerModal = ({ customer, resetCustomerSecret }) => {
  const classes = editCustomerModalStyles();
  const [showEditCustomerModal, setShowEditCustomerModal] = useState(false);

  const handleClickOpen = () => {
    setShowEditCustomerModal(true);
  };

  const handleClose = () => {
    setShowEditCustomerModal(false);
    resetCustomerSecret();
  };

  return <>
    <ListItemSecondaryAction
      onClick={handleClickOpen}
      className={classes.editCustomerModalOpenButton}
    >
      <IconButton edge="end" size="large">
        <EditIcon />
      </IconButton>
    </ListItemSecondaryAction>
    <Dialog
      onClose={handleClose}
      open={showEditCustomerModal}
      className={classes.editCustomerModalContainer}
    >
      <div className={classes.editCustomerModalHeaderContainer}>
        <DialogTitle className={classes.editCustomerModalHeader}>
          Edit Customer
        </DialogTitle>
        <IconButton
          onClick={handleClose}
          className={classes.editCustomerModalCloseButton}
          size="large">
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent className={classes.editCustomerModalContent}>
        <EditCustomerModalContent currentCustomer={customer} handleClose={handleClose} />
      </DialogContent>
    </Dialog>
  </>;
};

export default compose(withCustomerData, withCustomerActions)(EditCustomerModal);
