import React from 'react';

import {
  IconButton,
  Tooltip,
} from '@mui/material';

const TooltipIconButton = ({
  disableInteractive = true,
  tooltip,
  tooltipAfterClick,
  tooltipPlacement = 'top',
  icon,
  onClick,
  sx,
}) => {
  if (tooltipAfterClick === undefined) {
    return (
      <Tooltip disableInteractive={disableInteractive} title={tooltip} placement={tooltipPlacement}>
        <IconButton onClick={onClick} sx={sx}>
          {icon}
        </IconButton>
      </Tooltip>
    );
  }
  else {
    const [open, setOpen] = React.useState(false);
    const [tooltipText, setTooltipText] = React.useState(tooltip);

    const handleOpen = () => {
      setOpen(true);
    }

    const handleClick = (event) => {
      onClick(event);
      setTooltipText(tooltipAfterClick);
    }

    const handleClose = () => {
      setOpen(false);

      setTimeout(() => {
        setTooltipText(tooltip);
      }, 50);
    }
    
    return (
      <Tooltip
        disableInteractive={disableInteractive}
        title={tooltipText}
        placement={tooltipPlacement}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
      >
        <IconButton onClick={handleClick} sx={sx}>
          {icon}
        </IconButton>
      </Tooltip>
    );
  }
}

export default TooltipIconButton;