import { compose } from 'redux';
import React, { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useHistory, useParams } from 'react-router-dom';

import SearchIcon from '@mui/icons-material/Search';
import CheckedIcon from '@mui/icons-material/CheckCircle';
import UncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {
  ListItem,
  InputBase,
  Typography,
  ListItemText,
  ListItemIcon,
  CircularProgress,
  ListItemSecondaryAction,
} from '@mui/material';

import ItemIcom from './ItemIcom';
import folderListStyles from './style';
import CheckBox from '../../Components/Checkbox';
import withFoldersData from '../../../Hocs/WithData/folder';
import withFoldersActions from '../../../Hocs/WithAction/folder';

function FolderList({
  mode,
  isLoading,
  selectAll,
  toggleItem,
  filterItems,
  removeFilter,
  selectedItems,
  isMovingItems,
  currentFolder,
  toggleAllItems,
  rootLevelFolders,
  getCurrentFolder,
  deselectAllItems,
  loadCurrentFolder,
  getMoreFolderItems,
  totalNumberOfItems,
  numberOfItemsLoaded,
  getRootLevelFolders,
}) {
  const params = useParams();
  const history = useHistory();
  const classes = folderListStyles();

  const isListVew = mode === 'list';
  const isMosaicVew = mode === 'mosaic';
  const currentFolderId = params.id;
  const hasMore = numberOfItemsLoaded < totalNumberOfItems;

  useEffect(() => {
    if (rootLevelFolders.length === 0) {
      getRootLevelFolders();
    }
  }, [
    currentFolderId,
    getRootLevelFolders,
    rootLevelFolders.length]);

  useEffect(() => {
    if (currentFolderId) {
      getCurrentFolder({ currentFolderId, history });
    }
  }, [history, currentFolderId, getCurrentFolder]);

  useEffect(() => {
    if (!currentFolderId && rootLevelFolders.length !== 0) {
      const virtualRoot = {
        id: 'virtual-root',
        name: 'Virtual Root',
        parentFolder: '',
        content: rootLevelFolders,
        subfolders: rootLevelFolders,
      };
      loadCurrentFolder({
        currentFolder: virtualRoot,
        totalNumberOfItems: rootLevelFolders.length,
        numberOfItemsLoaded: rootLevelFolders.length,
      });
    }
  }, [currentFolderId, loadCurrentFolder, rootLevelFolders]);

  const loadMore = () => {
    getMoreFolderItems({ currentFolderId, offset: numberOfItemsLoaded });
  };

  const handleToggle = (e, itemId) => {
    e.stopPropagation();
    toggleItem(itemId);
  };

  const handleAccessFolder = (_, folderId) => {
    const isFolderSelected = selectedItems.find((item) => item.id === folderId);
    if (!isFolderSelected) {
      if (!isMovingItems) deselectAllItems();
      history.push(`/folders/${folderId}`);
    }
  };

  const spinner = (
    <div className={classes.spinnerContainer}>
      <CircularProgress />
    </div>
  );

  const noItems = (
    <div className={classes.emptyFolderList}>
      <Typography className={classes.emptyFolderListHeader}>
        {`"${currentFolder.name}" is empty. Use the "New Folder" button to add a new folder.`}
      </Typography>
    </div>
  );

  const searchBar = (
    <div className={classes.searchBarContainer}>
      <div className={classes.searchBarIconContainer}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder="Search in this folder..."
        onKeyDown={(e) => e.stopPropagation()}
        onChange={({ target }) => (target.value
          ? filterItems(target.value.toLowerCase())
          : removeFilter())}
      />
    </div>
  );

  const toggleAllCheckBox = (
    <div className={classes.toggleAllCheckboxContainer}>
      <CheckBox
        icon={<UncheckedIcon />}
        onChange={toggleAllItems}
        checked={selectAll || false}
        checkedIcon={<CheckedIcon />}
      />
    </div>
  );

  const itemsList = currentFolder.content.map((item) => {
    const isFolder = item.type === 'folder';
    const isItemSelected = selectedItems
      .some((selectedFolder) => selectedFolder.id === item.id);
    return item.isFiltered && (
      <ListItem
        key={item.id}
        disabled={isLoading}
        onClick={(_) => (isFolder ? handleAccessFolder(_, item.id) : null)}
        className={`
          ${classes.folderItemListView}
          ${isMosaicVew ? classes.folderItemMosaicView : ''}
          ${isListVew && isItemSelected ? classes.selectedFolderItem : ''}
        `}
      >
        <ListItemIcon
          className={`
            ${isMosaicVew ? classes.folderListItemIconMosaicView : ''}
            ${isMosaicVew && isItemSelected ? `${classes.selectedFolderListItemIconMosaicView}` : ''}`}
        >
          <ItemIcom isListVew={isListVew} itemType={item.type} />
          {isMosaicVew && !isMovingItems && (
          <ListItemSecondaryAction className={classes.folderListItemCheckBoxContainerMosaicView}>
            <CheckBox
              edge="end"
              checked={isItemSelected}
              icon={<UncheckedIcon />}
              checkedIcon={<CheckedIcon />}
              onClick={(e) => handleToggle(e, item.id)}
              className={`
                ${classes.folderListItemCheckBoxMosaicView} 
                ${isItemSelected ? classes.folderListItemchecked : ''}
              `}
            />
          </ListItemSecondaryAction>
          )}
        </ListItemIcon>
        <ListItemText
          primary={item.name}
          className={`
            ${classes.folderListItemText} 
            ${isMosaicVew ? classes.folderListItemTextMosaic : ''}`}
        />
        {isListVew && !isMovingItems && (
        <ListItemIcon className={classes.folderListItemCheckboxContainer}>
          <ListItemSecondaryAction className={classes.folderListItemCheckBox}>
            <CheckBox
              edge="end"
              onClick={(e) => handleToggle(e, item.id)}
              checked={isItemSelected}
              icon={<UncheckedIcon />}
              checkedIcon={<CheckedIcon />}
            />
          </ListItemSecondaryAction>
        </ListItemIcon>
        )}
      </ListItem>
    );
  });

  const infiniteLoader = (
    <div className={classes.infiniteLoaderContainer}>
      <CircularProgress key="loader" className={classes.infiniteLoader} />
    </div>
  );

  if (isLoading) return spinner;
  if (currentFolderId && currentFolder.content.length === 0) return noItems;

  return (
    <div className={classes.folderListContainer}>
      <div className={classes.actionBarContainer}>
        {searchBar}
        {!isMovingItems && toggleAllCheckBox}
      </div>
      <InfiniteScroll
        pageStart={0}
        hasMore={hasMore}
        loadMore={loadMore}
        loader={infiniteLoader}
        className={`${classes.folderListView} ${isMosaicVew && classes.folderMosaicView}`}
      >
        {itemsList}
      </InfiniteScroll>
    </div>
  );
}

export default compose(withFoldersData, withFoldersActions)(FolderList);
