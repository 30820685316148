import React from 'react';
import { compose } from 'redux';
import { Formik } from 'formik';
import { styled } from '@mui/material/styles';
import validator from 'validator';

import {
  Button,
  TextField,
  Typography,
} from '@mui/material';

import {
  Login as SignInIcon,
} from '@mui/icons-material';

import LoginHandler from '../../Components/Auth';
import Submit from '../Components/Inputs/SubmitButton';
import PasswordField from '../Components/Inputs/PasswordField';
import FormDialog from '../Components/Dialog_NEW/FormDialog';

import withAuthData from '../../redux/WithData/auth';
import withAuthActions from '../../redux/WithActions/auth';

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
});

const SignIn = ({
  isLoggingIn,
  login,
  resetPassword,
}) => {
  const [forgotPWOpen, setForgotPWOpen] = React.useState(false);

  const handleSubmitForgotPW = (values) => {
    console.log(`Sending recovery email to '${values.email}'`);

    resetPassword(values.email);

    setForgotPWOpen(false);
  }

  const handleValidateForgotPW = (values) => {
    const errors = {};

    // Email
    if (!values.email) {
      errors.email = 'Required';
    } else if (!validator.isEmail(values.email)) {
      errors.email = 'Invalid email address';
    }

    return errors;
  }

  return (
    <>
      <Formik
        initialValues={LoginHandler.LoginInitialValue()}
        validate={LoginHandler.LoginValidate}
        // onSubmit={(values) => LoginHandler.LoginSubmit(values)}
        onSubmit={(values) => login(values)}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <TextField
              required
              name="email"
              label="Email"
              autoComplete="username"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(errors.email)}
              helperText={errors.email || undefined}
              sx={{ mt: 2 }}
            />
            <PasswordField
              name="password"
              autoComplete="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(errors.password)}
              helperText={errors.password || undefined}
              sx={{ mt: 2 }}
            />

            <Button
              variant="text"
              onClick={() => { setForgotPWOpen(true); }}
              sx={{ ml: 'auto', mt: 1, p: 0.5, fontSize: '0.75rem' }}
            >
              Forgot Password?
            </Button>
            <FormDialog
              open={forgotPWOpen}
              title="Reset Password"
              defaultValues={{ email: '' }}
              onClose={() => { setForgotPWOpen(false); }}
              onSubmit={handleSubmitForgotPW}
              onValidate={handleValidateForgotPW}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
              }) => (
                <>
                  <TextField
                    required
                    autoFocus
                    name="email"
                    // autoComplete="email"     // Doesn't seem to autofill?
                    label="Email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.email)}
                    helperText={errors.email || undefined}
                  />
                  <Typography variant="subtitle1" sx={{ mt: 2 }}>
                    We’ll send you email confirmation to reset your password
                  </Typography>
                </>
              )}
            </FormDialog>
            
            <Submit
              buttonText="Sign in"
              loading={isLoggingIn}
              startIcon={<SignInIcon/>}
              sx={{ m: '0 auto', mt: 1 }}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default compose(withAuthData, withAuthActions)(SignIn);
