import makeStyles from '@mui/styles/makeStyles';
import {
  white,
  buttonPurple,
  inputLabelColor,
  onInputHoverPurple,
  onInputFocusPurple,
  inputLabelFontSize,
  onHoverButtonPurple,
  toggleUncheckedGrey,
  toggleCheckedPurple,
  inputLabelLineHeight,
  inputLabelFontWeight,
  inputLabelFontFamily,
  inputLabelLetterSpacing,
  toggleTrackUncheckedGrey,
  toggleTrackCheckedPurple,
} from '../../../../../../../Styles/styles';

const addNewScheduleModalContentStyles = makeStyles(() => ({
  addNewScheduleModalContentInputLabel: {
    color: inputLabelColor,
    fontSize: inputLabelFontSize,
    fontWeight: inputLabelFontWeight,
    lineHeight: inputLabelLineHeight,
    fontFamily: inputLabelFontFamily,
    letterSpacing: inputLabelLetterSpacing,
    padding: '15px 0px 10px 0px',
  },
  addNewScheduleModalContentFormControl: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${onInputHoverPurple}`,
    },
    '& .MuiInputBase-input:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: onInputFocusPurple,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: `1px solid ${onInputFocusPurple}`,
      },
    },
  },
  addNewScheduleModalContentForm: {
    '& input': {
      padding: '10px 14px',
    },
    '& div': {
      width: '100%',
    },
  },
  addNewScheduleModalContentDropdown: {
    height: '40px',
    flexGrow: '1',
    '& div': {
      display: 'flex',
    },
  },
  addNewScheduleModalContentButtonsContainer: {
    display: 'flex',
    paddingTop: '26px',
    justifyContent: 'flex-end',
  },
  addNewScheduleModalContentCancelButton: {
    margin: '0px',
    width: '100%',
    borderRadius: '8px',
    color: buttonPurple,
    textTransform: 'none',
    maxWidth: 'fit-content',
  },
  addNewScheduleModalContentAddButton: {
    color: 'white',
    padding: '6px 15px',
    borderRadius: '8px',
    textTransform: 'none',
    margin: '0px 0px 0px 12px',
    backgroundColor: buttonPurple,
    '&:disabled': {
      backgroundColor: '#E4E7EC',
      color: '#929FB4',
    },
    '&:hover': {
      color: 'white',
      backgroundColor: onHoverButtonPurple,
    },
  },
  addNewScheduleModalContentLocationType: {
    color: white,
    width: '48px',
    fontSize: '12px',
    fontWeight: '600',
    letterSpacing: '0',
    lineHeight: '24px',
    borderRadius: '8px',
    textAlign: 'center',
    fontFamily: 'Source Sans Pro',
  },
  addNewScheduleModalContentEssentialToggle: {
    justifyContent: 'space-between',
    padding: '15px 0px 10px 0px',
    display: 'flex',
    margin: 'unset',
    '& span': {
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '20px',
      letterSpacing: '0',
      fontFamily: 'Source Sans Pro',
      '& span': {
        '&.MuiSwitch-switchBase': {
          color: toggleUncheckedGrey,
        },
        '&.MuiSwitch-track': {
          backgroundColor: toggleTrackUncheckedGrey,
        },
        '&.Mui-checked.MuiSwitch-switchBase': {
          color: toggleCheckedPurple,
        },
        '&.Mui-checked + .MuiSwitch-track': {
          backgroundColor: toggleTrackCheckedPurple,
        },
      },
    },
  },
}));

export default addNewScheduleModalContentStyles;
