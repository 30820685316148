import React from 'react';

import {
  Typography,
} from '@mui/material';

const ConfinedTypography = ({
  disabled = false,
  alignment = 'left',
  variant = 'body2',
  title,
  maxLines = 1,
  forceWrap = false,
  sx,
  children,
  ...typographyProps
}) => {
  /**
   * If 'title' is falsey -> no title
   * If 'title' is true -> use 'children' as the title
   * If 'title' is a string -> use 'title' as the title
   */

  return (
    <Typography
      title={title && (typeof title === 'string' ? title : children)}
      color={!disabled ? "text.primary" : "text.disabled"}
      variant={variant}
      sx={{
        fontSize: 'inherit',
        display: '-webkit-box',
        WebkitLineClamp: maxLines,
        WebkitBoxOrient: 'vertical',
        textAlign: alignment,
        overflow: 'hidden',
        ...(forceWrap && {
          wordWrap: 'anywhere',
        }),
        ...sx,
      }}
      {...typographyProps}
    >
      {children}
    </Typography>
  );
}

export default ConfinedTypography;