import makeStyles from '@mui/styles/makeStyles';

const lastViewedTrackerCardStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    margin: '20px 0px',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  textContainer: {
    display: 'flex',
    alignSelf: 'flex-start',
    flexDirection: 'column',
  },
  header: {
    color: '#666666',
    fontSize: '15px',
    fontWeight: '500',
  },
  typography: {
    color: theme.palette.primary.main,
    fontSize: '15px',
    fontWeight: '500',
    paddingBottom: '10px',
  },
  image: {
    alignSelf: 'flex-end',
  },
  linkButton: {
    color: 'white',
    padding: '15px',
    fontSize: '14px',
    display: 'block',
    fontWeight: '500',
    textAlign: 'center',
    borderRadius: '8px',
    fontFamily: 'Roboto',
    textDecoration: 'none',
    letterSpacing: '1.25px',
    backgroundColor: theme.palette.primary.main,
  },
}));

export default lastViewedTrackerCardStyles;
