import React from 'react';
import { compose } from 'redux';
import makeStyles from '@mui/styles/makeStyles';

import { Avatar } from '@mui/material';

import DefaultAvatar from '../../../Assets/Images/profile.png';

import withUserData from '../../../redux/WithData/user';

const customAvatarStyles = makeStyles(() => ({
  customAvatar: {
    width: 'fit-content',
    position: 'relative',
    height: 'fit-content',
    '&img': {
      display: 'block',
      position: 'relative',
      objectFit: 'cover',
      height: '36px',
    },
  },
  customAvatarSmall: {
    '& img': {
      height: '36px',
      width: '36px',
    },
  },
  customAvatarBig: {
    '& img': {
      height: '150px',
      width: '150px',
    },
  },
}));

const CustomAvatar = ({
  avatar,
  size = 'large',
}) => {
  const classes = customAvatarStyles();

  const imageUrl = (avatar && avatar.thumbnailUrl)
    ? avatar.thumbnailUrl
    : DefaultAvatar;

  return (
    <Avatar
      title="avatar"
      src={`${imageUrl}?${new Date().getTime()}`}
      className={`
        ${classes.customAvatar} 
        ${size === 'small'
        ? classes.customAvatarSmall
        : classes.customAvatarBig}
      `}
    />
  );
};

export default compose(withUserData)(CustomAvatar);
