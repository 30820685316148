import React from 'react';

import {
  Box,
  Stack,
} from '@mui/material';

import StateSection from './StateSection';
import ModeSection from './ModeSection';
import DriverIDSection from './DriverIDSection';
import TagsSection from './TagsSection';

import { useWindowDimensions } from '../../../../Utils/ScreenSize';

const ControlPanelTab = () => {
  const { isTabletWidth, isDesktopWidth } = useWindowDimensions();

  // Desktop View
  if (isDesktopWidth) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
        <Box sx={{ width: '50%', mr: 0.5, display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ height: '100%', mb: 1, display: 'flex', flexDirection: 'row' }}>
            <StateSection sx={{ width: '50%', mr: 0.5 }}/>
            <ModeSection sx={{ width: '50%', ml: 0.5 }}/>
          </Box>
          <DriverIDSection/>
        </Box>
        <TagsSection sx={{ width: '50%', height: '100%', ml: 0.5 }} />
      </Box>
    );
  }
  // Tablet/Mobile View
  else {
    return (
      <Stack direction="column" spacing={2} sx={isTabletWidth ? { m: 2 } : { mt: 2 }}>
        <ModeSection/>
        <StateSection/>
        <TagsSection/>
        <DriverIDSection/>
      </Stack>
    );
  }
}

export default ControlPanelTab;