import React from 'react';
import { styled } from '@mui/material/styles';

import { Link } from 'react-router-dom';

export function renderRow ({
  className,
  columns,
  index,
  key,
  rowData,
  style,
}) {
  const a11yProps = {'aria-rowindex': index + 1};

  a11yProps['aria-label'] = 'row';
  a11yProps.tabIndex = 0;
  
  const Row = styled(Link)(({ theme }) => ({
    userSelect: 'none',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  }));

  return (
    <Row
      {...a11yProps}
      key={key}
      style={style}
      className={className}
      role="row"
      to={`/trackers/${rowData.simiccid}`}
    >
      {columns}
    </Row>
  );
}