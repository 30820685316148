import Cookies from 'universal-cookie';

import {
  put,
  call,
  select,
  takeLatest,
  putResolve,
} from 'redux-saga/effects';

import {
  login,
  logout,
  register,
  submitted,
  submitting,
  loginRequest,
  registerRequest,
} from '../Actions/AuthActions';
import AuthApi from '../../Api/Endpoints/auth';
import { addNotification } from '../Actions/system';
import { getProfileDetails } from '../Actions/UserActions';
import { AuthResponseTypes } from '../../Api/Responses/authResponse';
import ResponseCodes from '../../Api/Responses/responseCodes';

export function* authApiHandler(endpoint, ...args) {
  let authResponse = yield call(endpoint, ...args);

  if (authResponse.type === AuthResponseTypes.Unauthorized) {
    // Refresh accessToken if it is expired
    const refreshResponse = yield call(AuthApi.refreshAccess);
    if (refreshResponse.type === AuthResponseTypes.AcceptedLogin) {
      const email = yield select((state) => state.AuthData.email);
      yield putResolve(login({ ...refreshResponse.data, email }));
    }
    // Logout user if refreshToken is expired
    if (refreshResponse.type === AuthResponseTypes.RejectedLogin) {
      yield put(logout());
      yield put(addNotification({
        type: 'error',
        title: 'Your session has expired',
        source: 'authApiHandler',
        message: 'Please sign in again.',
      }));
    }
    // Re-try the endpoint
    authResponse = yield call(endpoint, ...args);
  }
  return authResponse;
}

export function* handleLoginRequest({ payload: { email, password } }) {
  yield put(submitting());
  const response = yield call(AuthApi.login, email, password);
  if (response.type === AuthResponseTypes.AcceptedLogin) {
    yield putResolve(login({ ...response.data, email }));
    yield putResolve(getProfileDetails());
  } else if (response.data.code === ResponseCodes.EmailUnverified) {
    yield put(submitted());
    yield put(addNotification({
      type: 'error',
      source: 'handleLoginRequest',
      title: 'Unverified email',
      message: 'Please go to your inbox and click on the verification link we have sent. Once that is done, you will be able to sign in.',
    }));
  } else if (response.data.code === ResponseCodes.InvalidCredentials) {
    yield put(submitted());
    yield put(addNotification({
      type: 'error',
      source: 'handleLoginRequest',
      title: 'Invalid credentials',
      message: 'If you are registered, please make sure your credentials are correctly typed. If you are not registered yet, click on the sign up tab to register. If you forgot your password, click on the forgot password link below to reset it.',
    }));
  } else if (response.type === AuthResponseTypes.Exception) {
    yield put(submitted());
    yield put(addNotification({
      type: 'error',
      source: 'handleLoginRequest',
      title: 'Could not sign in',
      message: 'Please, check your connection and try again.',
    }));
  }
}

export function* handleRegisterRequest({ payload: { credentials, history } }) {
  yield put(submitting());
  const response = yield call(AuthApi.register, credentials);
  if (response.type === AuthResponseTypes.AcceptedRegister) {
    yield put(register());
    yield history.push('/login');
    yield put(addNotification({
      type: 'success',
      source: 'handleRegisterRequest',
      title: 'You have successfully signed up!',
      message: "You'll receive a confirmation email to complete the process",
    }));
  } else if (response.data.code === ResponseCodes.EmailAlreadyRegistered) {
    yield put(submitted());
    yield put(addNotification({
      type: 'error',
      source: 'handleRegisterRequest',
      title: 'Email already registered',
      message: 'This email address has already been used to register an account. If you want to register a new account please use a different email. If you want to sign in, but forgot your password, simply switch to the sign in tab and click on the forgot password link.',
    }));
  } else if (response.data.code === ResponseCodes.InvalidSecretCode) {
    yield put(submitted());
    yield put(addNotification({
      type: 'error',
      source: 'handleRegisterRequest',
      title: 'Invalid Activation Code',
      message: 'The activation code you have typed is invalid. If you have forgotten your activation code, contact your Automatrics account manager to receive a new one.',
    }));
  } else if (response.type === AuthResponseTypes.Exception) {
    yield put(submitted());
    yield put(addNotification({
      type: 'error',
      title: 'Could not sign up',
      source: 'handleRegisterRequest',
      message: 'Please, check your connection and try again.',
    }));
  }
}

export function handleLogin({ payload }) {
  const cookies = new Cookies();
  cookies.set('email', payload.email, { path: '/', sameSite: 'lax' });
  cookies.set('accessToken', payload.accessToken, { path: '/', sameSite: 'lax' });
  cookies.set('refreshToken', payload.refreshToken, { path: '/', sameSite: 'lax' });
}

export default function* AuthSagas() {
  yield takeLatest(registerRequest, handleRegisterRequest);
  yield takeLatest(loginRequest, handleLoginRequest);
  yield takeLatest(login, handleLogin);
}
