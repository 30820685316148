import React from 'react';
import { compose } from 'redux';
import { useParams, useHistory  } from 'react-router-dom';

import {
  Box,
  CircularProgress,
} from '@mui/material';

import TabPanel from '../Components/TabPanel';
import Header from './Header';
import TrackerEventsWS from '../Components/WebSocket/TrackerEventsWS';

import { getTabs, getTabData } from './Tabs/tabs';
import { useWindowDimensions } from '../../Utils/ScreenSize';
import { capitaliseFirstLetter } from '../../Utils/Utils';
import { HEADER_ROW_HEIGHT } from '../../Utils/Defines';

import withSystemActions from '../../redux/WithActions/system';
import withUserData from '../../redux/WithData/user';
import withTrackerData from '../../redux/WithData/tracker';
import withTrackerActions from '../../redux/WithActions/tracker';
import withEventActions from '../../redux/WithActions/event';
import withLocationData from '../../redux/WithData/location';
import withLocationActions from '../../redux/WithActions/location';

const LAT_LNG_VARIANCE = 0.05;

/*
TODO:
- Redirect back to tracker list if trackerId is invalid

- Convert to class
- When mounted, update 'current tracker'
- When unmounted, clear 'current tracker'
 */

export const getHeaderHeights = (windowDimensions) => {
  const ROW_HEIGHT = windowDimensions.isDesktopWidth ? 48 : 40;
  const TAB_HEIGHT = 48;
  const UNIT_HEADER_HEIGHT = windowDimensions.isDesktopWidth ? ROW_HEIGHT + 1 + TAB_HEIGHT : ((ROW_HEIGHT + 1) * 2) + TAB_HEIGHT;

  return {
    totalHeaderHeight: UNIT_HEADER_HEIGHT + HEADER_ROW_HEIGHT,
    unitHeaderHeight: UNIT_HEADER_HEIGHT,
    unitRowHeight: ROW_HEIGHT,
  };
}

const TrackerDetails = ({
  role,
  tracker,
  isTrackerLoading,
  isGeneratingDummyLocations,
  getTracker,
  getLatestEvents,
  getLocations,
  getLatestLocations,
  addTrackerToRecents,
  createDummyLocation,
  setShowSubHeader,
}) => {
  // TODO: remove
  const count = React.useRef(0);
  React.useEffect(() => {
    count.current = count.current + 1;
    console.log(`Unit render #${count.current}`);
  });
  
  const { trackerId, tab } = useParams();
  let history = useHistory();
  
  const [hasStartedLoading, setStartedLoading] = React.useState(false);

  // Set 'showSubHeader' so the main header renders correctly
  React.useEffect(() => { setShowSubHeader(true); }, [ setShowSubHeader ]);

  // Load tracker data
  React.useEffect(() => {
    getTracker(trackerId);
    setStartedLoading(true);
  }, [
    trackerId,
    getTracker,
    setStartedLoading,
  ]);
  
  // Load latest event data
  React.useEffect(() => {
    getLatestEvents(trackerId);
  }, [
    trackerId,
    getLatestEvents,
  ]);
  
  // Load latest location data
  React.useEffect(() => {
    getLatestLocations(trackerId);
  }, [
    trackerId,
    getLatestLocations,
  ]);
  
  // TODO: If ID is invalid, redirect!
  // - Does the API differentiate 'ID not found' and 'Failed to reach server'?

  const windowDimensions = useWindowDimensions();
  const { height, isMobileWidth } = windowDimensions;

  const { totalHeaderHeight, unitRowHeight, unitHeaderHeight } = getHeaderHeights(windowDimensions);

  const [activeTab, setActiveTab] = React.useState('');
  const [tabs, setTabs] = React.useState([]);
  const [addedToRecents, setAddedToRecents] = React.useState(false);
  
  // Set active tab based on URL
  React.useEffect(() => {
    // Set available tabs based on users role
    const availableTabs = getTabs(role);
    setTabs(availableTabs);

    // Try to find a tab matching the URL (without case sensitivity)
    let tabToShow = availableTabs[0].key;     // Use first tab as a fallback
    
    if (tab) {
      for (let i = 0; i < availableTabs.length; i++) {
        if (availableTabs[i].key.toLowerCase() === tab.toLowerCase()) {
          tabToShow = availableTabs[i].key;
          break;
        }
      }
    }

    // Set selected tab and standardise URL
    setActiveTab(tabToShow);
  }, [
    //tab,    // NOTE: don't trigger based on 'tab' changing - this effect is only relevant when navigating via URL directly which always re-renders anyway
    role,
  ]);

  // Set the browser tab's title to the current UI tab
  React.useEffect(() => {
    const tabData = getTabData(activeTab);

    // Set URL based on active tab
    if (activeTab) {
      history.replace(`/trackers/${trackerId}/${capitaliseFirstLetter(activeTab)}`);
    }

    document.title = (tabData && tracker) ? `${tracker.asset?.name || tracker.name || trackerId} - ${tabData.label} - MTrack` : 'Unit Details - MTrack';
  }, [
    activeTab,
    // currentTracker,
    tracker,
  ]);

  // Add tracker to list of recently-viewed
  React.useEffect(() => {
    if (tracker?.simiccid && addTrackerToRecents && !addedToRecents) {
      addTrackerToRecents(tracker);

      setAddedToRecents(true);
    }
  }, [
    tracker,
    addTrackerToRecents,
  ]);
  
  // TODO: remove? - this adds dummy tracker location events every 10 seconds (DEV mode only)
  if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
    React.useEffect(() => {
      if (isGeneratingDummyLocations) {
        // Assign interval to a variable to clear it
        const intervalId = setInterval(() => {
          // Emulate the tracker sending this info to the server, then let the websocket retrieve it
          createDummyLocation({
            trackerId: tracker.simiccid,
            event: {
              latg: 51.002 - LAT_LNG_VARIANCE + (Math.random() * LAT_LNG_VARIANCE * 2),
              long: -0.95  - LAT_LNG_VARIANCE + (Math.random() * LAT_LNG_VARIANCE * 2),
              altg: 0,
              accg: 150 + (Math.random() * 100),
              b: 1000,
              t: 1,
            },
          });
        }, 10000)
      
        // Clears the interval when this useEffect triggers again to prevent multiple intervals building up
        return () => clearInterval(intervalId);
      }
    }, [
      tracker,
      isGeneratingDummyLocations,
      createDummyLocation
    ]);
  }
  
  const handleChangeTab = (_, tabValue) => {
    setActiveTab(tabValue);
  };

  return (
    <>
      <Header rowHeight={unitRowHeight} tabs={tabs} tab={activeTab} onChangeTab={handleChangeTab}/>
      {
        // (!hasStartedLoading || isCurrentTrackerLoading) ? (
        (!hasStartedLoading || isTrackerLoading) ? (
          <CircularProgress sx={{ margin: 'auto' }} />
        ) : (
          <>
            <TrackerEventsWS trackerId={trackerId}/>

            {tabs.map((tab) => (
              <TabPanel value={activeTab} key={tab.key} name={tab.key} content={tab.content} sx={{
                height: `${height - totalHeaderHeight}px`,
                mt: isMobileWidth ? 0 : `${unitHeaderHeight}px`
              }}/>
            ))}
          </>
        )
      }
    </>
  );
}

export default compose(
  withSystemActions,
  withUserData,
  withTrackerData,
  withTrackerActions,
  withEventActions,
  withLocationData,
  withLocationActions,
)(TrackerDetails);