import { handleActions } from 'redux-actions';

import { logout } from '../Actions/auth';
import {
  storeTracker,
  setTrackerLoading,

  storeTrackers,
  setTrackersLoading,

  storeRecentAndBookmarkedTrackers,
  setRecentAndBookmarkedTrackersLoading,
  addRecentTrackerToStore,
  addBookmarkedTrackerToStore,
  removeBookmarkedTrackerFromStore,

  storeCustomerTrackers,
  setCustomerTrackersLoading,

  storeAssetDetails,
  storeAssetImage,

  storeTrackerSettings,
  setTrackerSettingsLoading,

  setZones,
  setHighlightedZone,
  setShowZones,
  setNewZone,
} from '../Actions/tracker';

const defaultState = {
  tracker: {},
  isTrackerLoading: false,

  trackers: [],
  totalNumOfTrackers: 0,
  trackerFilter: {},        // TODO
  areTrackersLoading: false,

  recentTrackers: [],
  bookmarkedTrackers: [],
  areRecentTrackersLoading: false,
  areBookmarkedTrackersLoading: false,

  customerTrackers: [],
  areCustomerTrackersLoading: false,

  areTrackerSettingsLoading: false,

  zones: [
    {
      id: 100,
      name: 'Zone 1',
      forbidden: false,
      shape: 'polygon',
      path: [
        { lat: 51.001, lng: -0.945 },
        { lat: 51.001, lng: -0.943 },
        { lat: 51.002, lng: -0.944 },
        { lat: 51.003, lng: -0.944 }
      ],
    },
    {
      id: 101,
      name: 'Zone 2',
      forbidden: true,
      shape: 'polygon',
      path: [
        { lat: 51.001, lng: -0.952 },
        { lat: 51.000, lng: -0.949 },
        { lat: 51.001, lng: -0.949 },
        { lat: 51.003, lng: -0.951 }
      ],
    },
    {
      id: 102,
      name: 'Zone 3',
      forbidden: false,
      shape: 'polygon',
      path: [
        { lat: 51.007, lng: -0.946 },
        { lat: 51.007, lng: -0.947 },
        { lat: 51.006, lng: -0.948 },
        { lat: 51.005, lng: -0.947 }
      ],
    },
  ],
  highlightedZone: null,
  showZones: false,
  newZone: null,
};

export default handleActions({
  [logout]: () => defaultState,

  [storeTracker]: (state, { payload: tracker }) => ({ ...state, tracker: tracker }),
  [setTrackerLoading]: (state, { payload: isLoading }) => ({ ...state, isTrackerLoading: isLoading }),
  
  [storeTrackers]: (state, { payload: { trackers, totalCount } }) => ({ ...state, trackers: trackers, totalNumOfTrackers: totalCount }),
  [setTrackersLoading]: (state, { payload: isLoading }) => ({ ...state, areTrackersLoading: isLoading }),

  [storeRecentAndBookmarkedTrackers]: (state, { payload: { recents, bookmarks } }) => ({ ...state, recentTrackers: recents, bookmarkedTrackers: bookmarks }),
  [setRecentAndBookmarkedTrackersLoading]: (state, { payload: isLoading }) => ({ ...state, areBookmarkedTrackersLoading: isLoading, areRecentTrackersLoading: isLoading }),
  [addRecentTrackerToStore]: (state, { payload: tracker }) => ({
    ...state,
    recentTrackers: [
      { simiccid: tracker.simiccid, msisdn: tracker.msisdn, name: tracker.name },   // TODO: replace with asset name
      ...state.recentTrackers.filter(unit => unit.simiccid !== tracker.simiccid),
    ],
  }),
  [addBookmarkedTrackerToStore]: (state, { payload: tracker }) => ({
    ...state,
    bookmarkedTrackers: [
      { simiccid: tracker.simiccid, msisdn: tracker.msisdn, name: tracker.name },   // TODO: replace with asset name
      ...state.bookmarkedTrackers.filter(unit => unit.simiccid !== tracker.simiccid),
    ],
  }),
  [removeBookmarkedTrackerFromStore]: (state, { payload: trackerId }) => ({
    ...state,
    bookmarkedTrackers: [ ...state.bookmarkedTrackers.filter(unit => unit.simiccid !== trackerId) ],
  }),

  [storeCustomerTrackers]: (state, { payload: trackers }) => ({ ...state, customerTrackers: trackers }),
  [setCustomerTrackersLoading]: (state, { payload: isLoading }) => ({ ...state, areCustomerTrackersLoading: isLoading }),

  [storeAssetDetails]: (state, { payload: assetDetails }) => ({
    ...state,
    tracker: {
      ...state.tracker,
      assetDetails: { ...assetDetails },
    },
  }),
  // [storeAssetImage]: (state, { payload: assetImage }) => ({ ...state }),

  [storeTrackerSettings]: (state, { payload: newSettings }) => ({
    ...state,
    tracker: {
      ...state.tracker,
      newSettings: {
        ...state.tracker.newSettings,
        ...newSettings
      }
    },
  }),
  [setTrackerSettingsLoading]: (state, { payload: isLoading }) => ({ ...state, areTrackerSettingsLoading: isLoading }),

  [setZones]:           (state, { payload: zones })           => ({ ...state, zones: zones }),
  [setHighlightedZone]: (state, { payload: highlightedZone }) => ({ ...state, highlightedZone: highlightedZone }),
  [setShowZones]:       (state, { payload: showZones })       => ({ ...state, showZones: showZones }),
  [setNewZone]:         (state, { payload: newZone })         => ({ ...state, newZone: newZone }),
}, defaultState);