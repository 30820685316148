import makeStyles from '@mui/styles/makeStyles';

import {
  white,
  mobile,
  cardBoxShadow,
} from '../../../Styles/styles';

const featuredTrackersTableStyles = makeStyles((theme) => ({
  featuredTrackersContainer: {
    width: '49%',
    display: 'flex',
    borderRadius: '8px',
    flexDirection: 'column',
    backgroundColor: 'white',
    boxShadow: cardBoxShadow,
    [theme.breakpoints.down(mobile)]: {
      marginBottom: '20px',
      flexGrow: '1',
      width: '100%',
    },
  },
  featuredTrackersTableContainer: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: white,
    },
  },
  featuredTrackersTableHeader: {
    padding: '16px',
    display: 'flex',
    color: '#333333',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    '& svg': {
      marginRight: '10px',
    },
  },
  featuredTrackersTableBody: {
    overflowY: 'auto',
    marginBottom: '16px',
    // 100% - table header height
    height: 'calc(100% - 56px)',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  featuredTrackersTableRow: {
    cursor: 'pointer',
  },
  featuredTrackersTableCell: {
    border: 'none',
    color: '#666666',
    fontSize: '15px',
    fontWeight: 'bold',
  },
  featuredTrackersTableCellTracker: {
    color: theme.palette.primary.main,
  },
  featuredTrackersTableSpinnerContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  spinner: {
    margin: '20px auto 76px auto',
  },
}));

export default featuredTrackersTableStyles;
