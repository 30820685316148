import axios from 'axios';
import qs from 'qs';
import { ResponseHandler, ErrorHandler } from '../Responses/baseResponse';

const ApiCall = {
  baseUrl: process.env.REACT_APP_BASE_URL,

  buildOptions: (extraOptions = {}) => {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    };
    return { ...headers, ...extraOptions };
  },
  generateParams: (dataQS, dataBody) => {
    if (dataQS && dataBody) {
      return { ...qs.stringify(dataQS), ...dataBody };
    }
    if (dataQS) {
      return qs.stringify(dataQS);
    }
    return dataBody;
  },
  handleError: (error) => ErrorHandler(error),
  handleResponse: (response) => ResponseHandler(response),
  get: async (
    endPoint,
    dataQS = undefined,
    extraOptions = {},
    dataBody = undefined,
    baseUrl = ApiCall.baseUrl,
  ) => {
    const headers = ApiCall.buildOptions(extraOptions);
    const responseObj = await axios
      .get(
        baseUrl + endPoint,
        { headers, params: dataQS, data: dataBody },
        // ApiCall.generateParams(dataQS, dataBody),
      )
      .then((response) => ApiCall.handleResponse(response))
      .catch((error) => ApiCall.handleError(error));
    return responseObj;
  },
  post: async (
    endPoint,
    dataBody = undefined,
    extraOptions = {},
    dataQS = undefined,
  ) => {
    const headers = ApiCall.buildOptions(extraOptions);
    const responseObj = await axios
      .post(ApiCall.baseUrl + endPoint, dataBody, { headers, params: dataQS })
      .then((response) => ApiCall.handleResponse(response))
      .catch((error) => ApiCall.handleError(error));
    return responseObj;
  },
  put: async (
    endPoint,
    dataBody = undefined,
    extraOptions = {},
    dataQS = undefined,
  ) => {
    const headers = ApiCall.buildOptions(extraOptions);
    const responseObj = await axios
      .put(ApiCall.baseUrl + endPoint, dataBody, { headers, params: dataQS })
      .then((response) => ApiCall.handleResponse(response))
      .catch((error) => ApiCall.handleError(error));
    return responseObj;
  },
  delete: async (
    endPoint,
    dataBody = undefined,
    extraOptions = {},
    dataQS = undefined,
  ) => {
    const headers = ApiCall.buildOptions(extraOptions);
    const responseObj = await axios
      .delete(ApiCall.baseUrl + endPoint, {
        headers,
        params: dataQS,
        data: dataBody,
      })
      .then((response) => ApiCall.handleResponse(response))
      .catch((error) => ApiCall.handleError(error));
    return responseObj;
  },
  healthCheck: async () => ApiCall.get('/api/v1/open/healthcheck'),
  currentVersion: async () => ApiCall.get(
    // `/meta.json?timestamp=${new Date().getTime()}`,
    '/meta.json',
    undefined,
    {},
    undefined,
    '',
  ),
};

export default ApiCall;
