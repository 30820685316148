import React from 'react';

import makeStyles from '@mui/styles/makeStyles';

import ProfileCard from './ProfileCard';
import UserActionList from './actionList';
import { cardBoxShadow, mobile } from '../../Styles/styles';

const profileManagerStyles = makeStyles((theme) => ({
  profileManager: {
    flexGrow: '1',
    margin: '30px',
    overflowY: 'auto',
    borderRadius: '8px',
    boxSizing: 'border-box',
    backgroundColor: 'white',
    boxShadow: cardBoxShadow,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme.breakpoints.down(mobile)]: {
      margin: 'unset',
    },
  },
}));

const ProfileManager = () => {
  const classes = profileManagerStyles();
  return (
    <div className={classes.profileManager}>
      <ProfileCard />
      <UserActionList />
    </div>
  );
};

export default ProfileManager;
