import AuthCall from '../Endpoints/auth';
import ApiCall from '../Endpoints/base';

const UserAPI = {
  getProfile: async (username) => {
    const response = await AuthCall.get(`/api/v1/secure/account/instance/${username}`, { username });
    return response;
  },

  editProfile: async (newProfileDetails) => {
    const response = await AuthCall.post('/api/v1/secure/account/instance', newProfileDetails);
    return response;
  },

  uploadProfileImage: async (imageFile) => {
    const username = AuthCall.getUserEmail();
    const formData = new FormData();
    formData.append('file', imageFile);
    const header = { 'Content-Type': 'multipart/form-data' };
    const response = await AuthCall.post(`/api/v1/secure/account/avatar/upload/${username}`, formData, header);
    return response;
  },

  getFeaturedTrackers: async () => {
    // Returns both favorite and recently viewed trackers
    const response = await AuthCall.get('/api/v1/secure/account/favourites');
    return response;
  },

  addTrackerToFavourites: async (simiccid) => {
    // The API only accepts an array of simiccids
    const simiccids = [ simiccid ];
    const response = await AuthCall.post('/api/v1/secure/account/favourites', { simiccids });
    return response;
  },

  removeTrackerFromFavourites: async (simiccid) => {
    // The API only accepts an array of simiccids
    const simiccids = [ simiccid ];
    const response = await AuthCall.delete('/api/v1/secure/account/favourites', { simiccids });
    return response;
  },

  addTrackerToRecentlyViewed: async (simiccid) => {
    // The API only accepts an array of simiccids
    const simiccids = [simiccid];
    const response = await AuthCall.post('/api/v1/secure/account/recents', { simiccids });
    return response;
  },

  changePassword: async (password) => {
    const username = AuthCall.getUserEmail();
    const response = await AuthCall.post(
      '/api/v1/secure/account/password/change', {
        username,
        password,
      },
    );
    return response;
  },

  resetPassword: async (email) => {
    const response = await ApiCall.post(
      `/api/v1/open/account/resetPassword?username=${email}`,
    );
    return response;
  },
};

export default UserAPI;
