import React from 'react';
import { compose } from 'redux';

import withSystemActions from '../../redux/WithActions/system';

const RequireReload = ({
  checkVersion,
}) => {
  // Check the current cached version against the server's latest version
  React.useEffect(() => {
    checkVersion();
  }, [ checkVersion ]);

  return (
    <> </>
  );
};

export default compose(withSystemActions)(RequireReload);
