import React from 'react';
import { styled } from '@mui/system';
import { useTheme, alpha } from '@mui/material/styles';

import {
  Box,
  ButtonBase,
} from '@mui/material';

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

import { MODES } from '../../../Utils/Modes';

export const LARGE_WIDTH  = 120;
export const SMALL_WIDTH  = 90;

export const LARGE_HEIGHT = 48;
export const SMALL_HEIGHT = 36;

export const LARGE_BORDER_WIDTH = 8;
export const SMALL_BORDER_WIDTH = 6;

export const LARGE_FONT_SIZE = '0.875rem';
export const SMALL_FONT_SIZE = '0.8rem';

const ModeBase = styled(ButtonBase)(({ theme, ownerState }) => ({
  width: `${ownerState.width}px`,
  height: `${ownerState.height}px`,
  backgroundColor: ownerState.active ? theme.palette.action.selected : 'transparent',
  fontSize: ownerState.fontSize,
  fontWeight: ownerState.active ? 'bold' : 'normal',
  fontStyle: ownerState.pending ? 'italic' : 'normal',
  color: (ownerState.active || ownerState.clickable) ? theme.palette.text.primary : theme.palette.text.secondary,

  ...(ownerState.left && {
    marginLeft: ownerState.pending ? ownerState.borderWidth : 0,
    borderLeftWidth: ownerState.pending ? 0 : ownerState.borderWidth,
    borderLeftColor: ownerState.colour,
    borderLeftStyle: 'solid',
  }),

  ...(ownerState.right && {
    marginRight: ownerState.pending ? ownerState.borderWidth : 0,
    borderRightWidth: ownerState.pending ? 0 : ownerState.borderWidth,
    borderRightColor: ownerState.colour,
    borderRightStyle: 'solid',
  }),

  ...(ownerState.clickable && {
    transition: theme.transitions.create(
      ['background-color', 'border-color', 'color'],
      { duration: theme.transitions.duration.short },
    ),

    '&:hover': {
      backgroundColor: ownerState.active ? theme.palette.action.focus : theme.palette.action.hover,

      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: ownerState.active ? theme.palette.action.selected : 'transparent',
      },
    },
  }),
}));

const PendingBar = styled(LinearProgress)(({ theme, ownerState }) => ({
  height: ownerState.borderWidth,

  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: ownerState.pendingBgColour,
  },

  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: ownerState.colour,
  },
}));

const ModeButton = ({
  mode,
  active,
  pending,
  disabled,
  small = false,
  left  = false,
  right = false,
  widthMult = 1,
  heightMult = 1,
  onClick,
  sx,
  ...props
}) => {
  const theme = useTheme();

  const clickable = !disabled && !active && onClick;

  const WIDTH  = ((small ? SMALL_WIDTH  : LARGE_WIDTH)  + 1) * widthMult  - 1;
  const HEIGHT = ((small ? SMALL_HEIGHT : LARGE_HEIGHT) + 1) * heightMult - 1;
  const BORDER_WIDTH = small ? SMALL_BORDER_WIDTH : LARGE_BORDER_WIDTH;
  const FONT_SIZE = small ? SMALL_FONT_SIZE : LARGE_FONT_SIZE;

  let totalBorderWidth = 0;

  if (left) {
    totalBorderWidth += small ? SMALL_BORDER_WIDTH : LARGE_BORDER_WIDTH;
  }

  if (right) {
    totalBorderWidth += small ? SMALL_BORDER_WIDTH : LARGE_BORDER_WIDTH;
  }

  let colour;

  if (active || (pending && mode === MODES.STANDARD)) {
    colour = theme.palette.modes[mode.id].dark;
  }
  else if (pending || mode === MODES.STANDARD) {
    colour = theme.palette.modes[mode.id].main
  }
  else {
    colour = theme.palette.modes[mode.id].light;
  }

  const ownerState = {
    width: WIDTH - (pending ? totalBorderWidth : 0),
    height: HEIGHT,
    borderWidth: BORDER_WIDTH,
    fontSize: FONT_SIZE,
    colour,
    pendingBgColour: !(mode === MODES.STANDARD) ? theme.palette.action.hover : alpha(theme.palette.action.hover, 0.02),
    active,
    pending,
    left,
    right,
    clickable,
  };

  function getPendingBar(right = false) {
    const rotationCentre = {
      x: -(HEIGHT - BORDER_WIDTH) / 2,
      y:  (HEIGHT - BORDER_WIDTH) / 2,
    };

    return (
      <Box sx={{
        width: HEIGHT,
        height: BORDER_WIDTH,
        ml: right ? `${WIDTH - BORDER_WIDTH}px` : 0,
        position: 'absolute',
        transform: pending ? `matrix(0, 1, -1, 0, ${rotationCentre.x}, ${rotationCentre.y})` : 'none',
      }}>
        <PendingBar ownerState={ownerState}/>
      </Box>
    )
  }
  
  return (
    <Box title={mode.description} sx={{ ...sx, display: 'flex', flexDirection: 'row' }} {...props}>
      {pending && left && getPendingBar()}
      <ModeBase ownerState={ownerState} disabled={!clickable} onClick={() => onClick(mode)}>
        {mode?.label || '???'}
      </ModeBase>
      {pending && right && getPendingBar(true)}
    </Box>
  );
}

export default ModeButton;