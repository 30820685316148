import {
  put,
  select,
  takeLatest,
} from "redux-saga/effects";

import {
  getTracker,
  storeTracker,
  setTrackerLoading,

  getTrackers,
  storeTrackers,
  setTrackersLoading,

  getRecentAndBookmarkedTrackers,
  storeRecentAndBookmarkedTrackers,
  setRecentAndBookmarkedTrackersLoading,
  addTrackerToRecents,
  addTrackerToBookmarks,
  removeTrackerFromBookmarks,
  addRecentTrackerToStore,
  addBookmarkedTrackerToStore,
  removeBookmarkedTrackerFromStore,

  getCustomerTrackers,
  storeCustomerTrackers,
  setCustomerTrackersLoading,

  updateAsset,
  updateAssetImage,
  storeAssetDetails,
  storeAssetImage,

  setTrackerState,
  getTrackerSettings,
  setTrackerSettings,
  sendRawTrackerSettings,
  storeTrackerSettings,
  setTrackerSettingsLoading,
} from '../Actions/tracker';

import { addNotification } from '../Actions/system';
import { authApiHandler } from './auth';

import TrackerAPI from '../../Api/Endpoints/tracker';
import { ResponseTypes } from '../../Api/Responses/baseResponse';

export function* handleGetTracker({ payload: trackerId }) {
  yield put(setTrackerLoading(true));
  let response = yield authApiHandler(TrackerAPI.getTracker, trackerId);

  if (response.type == ResponseTypes.ActionCompleted) {
    yield put(storeTracker(response.data));
  }
  else if (response.type == ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      source: 'handleGetTracker',
      title: 'Could not retrieve tracker',
      message: 'Please check your connection and try again.',
    }));
  }

  yield put(setTrackerLoading(false));
}

export function* handleGetTrackers({ payload: { limit, offset, filters, sorters } }) {
  yield put(setTrackersLoading(true));
  let response = yield authApiHandler(TrackerAPI.getTrackers, limit, offset, filters, sorters);

  if (response.type == ResponseTypes.ActionCompleted) {
    yield put(storeTrackers({ trackers: response.data.data.filter(tracker => !!tracker), totalCount: response.data.totalCount }));
  }
  else if (response.type == ResponseTypes.Exception) {
    console.log('handleGetTrackers')
    yield put(addNotification({
      type: 'error',
      source: 'handleGetTrackers',
      title: 'Could not retrieve trackers',
      message: 'Please check your connection and try again.',
    }));
  }

  yield put(setTrackersLoading(false));
}

export function* handleGetRecentAndBookmarkedTrackers() {
  yield put(setRecentAndBookmarkedTrackersLoading(true));
  let response = yield authApiHandler(TrackerAPI.getRecentAndBookmarkedTrackers);

  if (response.type == ResponseTypes.ActionCompleted) {
    // API returns recent trackers with most-recent at the end - reverse this for the UI
    yield put(storeRecentAndBookmarkedTrackers({ recents: response.data.recents.reverse(), bookmarks: response.data.favourites }));
  }
  else if (response.type == ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      source: 'handleGetRecentAndBookmarkedTrackers',
      title: 'Could not retrieve recently-viewed and/or bookmarked trackers',
      message: 'Please check your connection and try again.',
    }));
  }

  yield put(setRecentAndBookmarkedTrackersLoading(false));
}

export function* handleAddTrackerToRecents({ payload: tracker }) {
  let response = yield authApiHandler(TrackerAPI.addTrackerToRecents, tracker.simiccid);

  if (response.type == ResponseTypes.ActionCompleted) {
    yield put(addRecentTrackerToStore(tracker));
  }
  else if (response.type == ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      source: 'handleAddTrackerToRecents',
      title: 'Could not add tracker to list of recently-viewed trackers',
      message: 'Please check your connection and try again.',
    }));
  }
}

export function* handleAddTrackerToBookmarks({ payload: tracker }) {
  let response = yield authApiHandler(TrackerAPI.addTrackerToBookmarks, tracker.simiccid);

  if (response.type == ResponseTypes.ActionCompleted) {
    yield put(addBookmarkedTrackerToStore(tracker));
  }
  else if (response.type == ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      source: 'handleAddTrackerToBookmarks',
      title: 'Could not add tracker to bookmarks',
      message: 'Please check your connection and try again.',
    }));
  }
}

export function* handleRemoveTrackerFromBookmarks({ payload: trackerId }) {
  let response = yield authApiHandler(TrackerAPI.removeTrackerFromBookmarks, trackerId);

  if (response.type == ResponseTypes.ActionCompleted) {
    yield put(removeBookmarkedTrackerFromStore(trackerId));
  }
  else if (response.type == ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      source: 'handleRemoveTrackerFromBookmarks',
      title: 'Could not remove tracker from bookmarks',
      message: 'Please check your connection and try again.',
    }));
  }
}

export function* handleGetCustomerTrackers() {
  yield put(setCustomerTrackersLoading(true));
  let response = yield authApiHandler(TrackerAPI.getCustomerTrackers);

  if (response.type == ResponseTypes.ActionCompleted) {
    yield put(storeCustomerTrackers(response.data.data));  // TODO: change depending on actual response format
  }
  else if (response.type == ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      source: 'handleGetCustomerTrackers',
      title: 'Could not retrieve personal trackers',
      message: 'Please check your connection and try again.',
    }));
  }

  yield put(setCustomerTrackersLoading(false));
}

export function* handleUpdateAsset({ payload: assetDetails }) {
  const trackerId = yield select((state) => state.TrackerData_NEW.tracker.simiccid);
  const response = yield authApiHandler(TrackerAPI.updateAsset, trackerId, assetDetails);

  if (response.type === ResponseTypes.ActionCompleted) {
    yield put(storeAssetDetails(response.data.asset));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      source: 'handleUpdateAsset',
      title: 'Could not update asset details',
      message: 'Please, check your connection and try again.',
    }));
  }
}

export function* handleUpdateAssetImage({ payload: assetImage }) {
  
}

export function* handleSetTrackerState({ payload: { trackerId, stateId } }) {
  // const trackerId = yield select((state) => state.TrackerData_NEW.tracker.simiccid);
  const response = yield authApiHandler(TrackerAPI.setTrackerState, trackerId, { mnemonic: stateId });

  if (response.type === ResponseTypes.ActionCompleted) {
    yield put(storeTracker(response.data));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      source: 'handleSetTrackerState',
      title: 'Could not update tracker state',
      message: 'Please, check your connection and try again.',
    }));
  }
}

export function* handleGetTrackerSettings({ payload: trackerId }) {
  yield put(setTrackerSettingsLoading(true));
  const response = yield authApiHandler(TrackerAPI.getTracker, trackerId);

  if (response.type === ResponseTypes.ActionCompleted) {
    yield put(storeTrackerSettings(response.data.newSettings));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      source: 'handleSetTrackerSettings',
      title: 'Could not update settings',
      message: 'Please, check your connection and try again.',
    }));
  }

  yield put(setTrackerSettingsLoading(false));
}

export function* handleSetTrackerSettings({ payload: { trackerId, newSettings, hideNotification } }) {
  const response = yield authApiHandler(TrackerAPI.setTrackerSettings, trackerId, newSettings);

  if (response.type === ResponseTypes.ActionCompleted) {
    yield put(storeTrackerSettings(response.data.newSettings));

    if (!hideNotification) {
      yield put(addNotification({
        type: 'success',
        source: 'handleSetTrackerSettings',
        title: 'Tracker settings successfully updated!',
        message: 'Your new tracker settings have been updated. They will be received by the tracker when it next wakes up.',
      }));
    }
  } else if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      source: 'handleSetTrackerSettings',
      title: 'Could not update settings',
      message: 'Please, check your connection and try again.',
    }));
  }
}

export function* handleSendRawTrackerSettings({ payload: { trackerId, rawSettings } }) {
  const response = yield authApiHandler(TrackerAPI.sendRawTrackerSettings, trackerId, rawSettings);

  if (response.type === ResponseTypes.ActionCompleted) {
    yield put(addNotification({
      type: 'success',
      source: 'handleSendRawTrackerSettings',
      title: 'Tracker raw settings successfully updated!',
      message: 'Your new raw tracker settings have been updated. They will be received by the tracker during its next wake up time.',
    }));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      source: 'handleSendRawTrackerSettings',
      title: 'Could not update settings',
      message: 'Please, check your connection and try again.',
    }));
  } else if (response.type === ResponseTypes.BadRequest) {
    yield put(addNotification({
      type: 'error',
      source: 'handleSendRawTrackerSettings',
      title: 'Could not update settings',
      message: response.data.description,
    }));
  }
}

export default function* Sagas() {
  yield takeLatest(getTracker, handleGetTracker);

  yield takeLatest(getTrackers, handleGetTrackers);

  yield takeLatest(getRecentAndBookmarkedTrackers, handleGetRecentAndBookmarkedTrackers);
  yield takeLatest(addTrackerToRecents, handleAddTrackerToRecents);
  yield takeLatest(addTrackerToBookmarks, handleAddTrackerToBookmarks);
  yield takeLatest(removeTrackerFromBookmarks, handleRemoveTrackerFromBookmarks);

  yield takeLatest(getCustomerTrackers, handleGetCustomerTrackers);

  yield takeLatest(updateAsset, handleUpdateAsset);
  yield takeLatest(updateAssetImage, handleUpdateAssetImage);

  yield takeLatest(setTrackerState, handleSetTrackerState);
  yield takeLatest(getTrackerSettings, handleGetTrackerSettings);
  yield takeLatest(setTrackerSettings, handleSetTrackerSettings);
  yield takeLatest(sendRawTrackerSettings, handleSendRawTrackerSettings);
}