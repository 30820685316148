import React from 'react';
import { isEqual } from 'underscore';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import {
  Box,
  Checkbox,
  IconButton,
  FormGroup,
  FormControlLabel,
  MenuItem,
  TextField,
} from '@mui/material';

import {
  Tune as FilterIcon,
  ExpandLess as ArrowUpIcon,
  ExpandMore as ArrowDownIcon,
} from '@mui/icons-material';

import PaperSection from '../PaperSection';
import FilterButton from './FilterButton';
import MultiSelectField from '../Inputs/MultiSelectField';

const TEXT_FIELD_WIDTH_VALUE  = 240;
const TEXT_FIELD_WIDTH        = `${TEXT_FIELD_WIDTH_VALUE}px`;

const INPUTS_PER_ROW = 4;

const FilterHeader = ({
  standalone = false,
  fixedWidth = false,
  values,
  defaultValues,
  setValues,
  filters = [],
  toggles = [],
  maxWidth = -1,
  onFilter,
  sx,
}) => {
  const [isExpanded, setExpanded] = React.useState(false);
  const [topRow, setTopRow] = React.useState([]);
  const [expandableRows, setExpandableRows] = React.useState([]);

  React.useEffect(() => {
    if (filters?.length > 0) {
      setTopRow(filters.slice(0, Math.min(INPUTS_PER_ROW, filters.length)));
  
      if (filters.length >= INPUTS_PER_ROW) {
        let extraRows = [];
        let filterIdx = INPUTS_PER_ROW;

        for (let i = 0; filterIdx < filters.length; filterIdx = (i + 1) * INPUTS_PER_ROW) {
          if (filterIdx + INPUTS_PER_ROW < filters.length) {
            extraRows[i] = filters.slice(filterIdx, filterIdx + INPUTS_PER_ROW);
          }
          else {
            extraRows[i] = filters.slice(filterIdx);
          }

          i++;
        }

        setExpandableRows(extraRows);
      }
    }
    else {
      setTopRow([]);
      setExpandableRows([]);
    }
  }, [
    filters,
  ]);

  // When this value changes, the onFilter method is called. This allows synchronicity when clearing the filter.
  const [clearFilter, setClearFilter] = React.useState(false);
  // React.useEffect(() => { onFilter(); }, [ clearFilter ]);

  function handleChange({ target }) {
    if (values[target.name] !== target.value) {
      setValues({
        ...values,
        [target.name]: target.value,
      })
    }
  }

  function handleChangeTime(key, value) {
    if (values[key] !== value) {
      setValues({
        ...values,
        [key]: value,
      });
    }
  }
  
  function handleChangeToggle({ target }) {
    if (values[target.name] !== target.checked) {
      setValues({
        ...values,
        [target.name]: target.checked,
      })
    }
  }

  function handleClear() {
    setValues({ ...defaultValues });

    // Triggers onFilter to applied to newly-cleared filter
    // setClearFilter(!clearFilter);

    onFilter(true);
  }

  function handleClickExpand() {
    setExpanded(!isExpanded);
  }

  function getInput(filter, sx) {
    if (Array.isArray(filter?.type)) {
      return filter.multiple ? (
        <MultiSelectField
          disabled={!!filter.disabled}
          key={filter.id}
          name={filter.id}
          value={values[filter.id] || []}
          label={filter.label}
          items={filter.type}
          onChange={handleChange}
          sx={{ ...sx, width: TEXT_FIELD_WIDTH, mr: 1 }}
        />
        ) : (
        <TextField
          disabled={!!filter.disabled}
          select
          key={filter.id}
          name={filter.id}
          value={values[filter.id] || ''}
          label={filter.label}
          onChange={handleChange}
          sx={{ ...sx, width: TEXT_FIELD_WIDTH, mr: 1 }}
        >
          <MenuItem key="" value="">-</MenuItem>
          {filter.type.map((item) => (
            <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
          ))}
        </TextField>
      );
    }
  
    switch (filter.type) {
      case 'text':
        return (
          <TextField
            disabled={!!filter.disabled}
            key={filter.id}
            name={filter.id}
            value={values[filter.id] || ''}
            label={filter.label}
            onChange={handleChange}
            sx={{ ...sx, width: TEXT_FIELD_WIDTH, mr: 1 }}
          />
        );

      case 'dateTime':
        return (
          <DateTimePicker
            renderInput={(props) => <TextField {...props} sx={{
              ...props?.sx,
              ...sx,
              width: TEXT_FIELD_WIDTH,
              mr: 1,
            }}/>}
            disabled={!!filter.disabled}
            key={filter.id}
            name={filter.id}
            value={values[filter.id]}
            label={filter.label}
            onChange={(value) => handleChangeTime(filter.id, value)}
          />
        );
  
      default:
        return <></>
    }
  }

  function getToggle(toggle) {
    return (
      <FormControlLabel
        disabled={!!toggle.disabled}
        key={toggle.id}
        control={<Checkbox
          name={toggle.id}
          checked={values[toggle.id] || false}
          onChange={handleChangeToggle}
        />}
        label={toggle.label}
      />
    );
  }

  const containerSx = {
    display: 'flex',
    flexDirection: 'column',
    width: 'max-content',
    maxWidth: maxWidth > 0 ? `${maxWidth}px`: undefined,
    p: 2,
    ...sx,
  }

  const contents = (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <FilterIcon sx={{ m: 'auto 0', mr: 2 }}/>
        {topRow.map((filter) => getInput(filter))}

        <FilterButton fixedWidth={fixedWidth} showClear={!isEqual(values, defaultValues)} onFilter={() => onFilter()} onClear={handleClear} sx={{ m: '2px 0', ml: 1 }}/>

        {expandableRows.length > 0 && (
          <IconButton onClick={handleClickExpand} sx={{ m: 'auto 0', ml: 1 }}>
            {isExpanded ? <ArrowUpIcon/> : <ArrowDownIcon/>}
          </IconButton>
        )}
      </Box>

      {expandableRows.length > 0 && isExpanded && (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {expandableRows.map((row, index) => (
              <Box key={index} sx={{ display: 'flex', flexDirection: 'row', mt: 1, ml: '40px' }}>
                {row.map((filter) => getInput(filter))}
              </Box>
            ))}
          </Box>

          {toggles?.length > 0 && (
            <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1, ml: 1 }}>
              <FormGroup>
                {toggles.map((toggle) => getToggle(toggle))}
              </FormGroup>
            </Box>
          )}
        </Box>
      )}
    </>
  );

  return standalone ? (
    <PaperSection sx={containerSx}>
      {contents}
    </PaperSection>
  ) : (
    <Box sx={containerSx}>
      {contents}
    </Box>
  );
}

export default FilterHeader;