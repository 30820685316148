import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

export default (props) => {
  const {
    hasRowColumn = true,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const SortableColumn = ({ headCell }) => (
    <TableSortLabel
      active={orderBy === headCell.id}
      direction={orderBy === headCell.id ? order : 'asc'}
      onClick={createSortHandler(headCell.id)}
    >
      {headCell.label}
      {orderBy === headCell.id && (
        <span className="visually-hidden">
          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
        </span>
      )}
    </TableSortLabel>
  );
  return (
    <TableHead>
      <TableRow>
        {hasRowColumn && (
        <>
          <TableCell padding="checkbox" className="sticky-column">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all elements' }}
            />
          </TableCell>
          <TableCell className="sticky-column">
            ROW
          </TableCell>
        </>
        )}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {(headCell.sortable) ? (
              <SortableColumn headCell={headCell} />
            ) : headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
