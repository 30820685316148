import React from 'react';

import {
  Paper,
  Tab,
  Tabs,
} from '@mui/material';

const TabHeader = ({
  tabs,
  tab,
  onChangeTab,
}) => {
  return (
    <Paper sx={theme => ({
      width: '100%',
      borderRadius: 0,
      position: 'fixed',
      zIndex: 1,
      [theme.breakpoints.down('tablet')]: {
        position: 'sticky',
        top: 0,
      },
    })}>
      <Tabs centered value={tab} onChange={onChangeTab} sx={theme => ({ backgroundColor: theme.palette.background.header })}>
        {tabs.map((tab) => (
          <Tab value={tab.key} key={tab.key} label={tab.label} />
        ))}
      </Tabs>
    </Paper>
  );
}

export default TabHeader;