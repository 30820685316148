import React from 'react';

import CustomPopover from '../../Components/Elements/CustomPopover';
import vehicleByDefault from '../../../Assets/Images/vehicle.png';

const SmallImage = ({ urlImage }) => (
  <div className="tracker-image">
    <img className="last-viewed-tracker-image" src={`${urlImage}?${new Date().getTime()}`} alt="Last viewed Tracker" />
  </div>
);

const LargeImage = ({ urlImage }) => (
  <img className="last-viewed-tracker-image" src={`${urlImage}?${new Date().getTime()}`} alt="Last viewed Tracker" />
);

const TrackerImage = ({ tracker }) => (
  <CustomPopover
    TrigerContent={(
      <SmallImage urlImage={(tracker.image && tracker.image.thumbnailUrl) || vehicleByDefault}/>
    )}
    PopOverContent={(
      <LargeImage urlImage={(tracker.image && tracker.image.fullsizeUrl) || vehicleByDefault}/>
    )}
  />
);

export default TrackerImage;
