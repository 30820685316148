import Cookies from 'universal-cookie';
import { handleActions } from 'redux-actions';

import { logout } from '../Actions/auth';
import {
  setAppTheme,
  setAppLanguage,
  setShowSubHeader,

  addNotification,
  removeNotifications,
} from '../Actions/system';

const COOKIE_THEME = 'theme';
const COOKIE_LANGUAGE = 'language';

const cookies = new Cookies();

const defaultState = {
  appTheme: cookies.get(COOKIE_THEME) || 'light',
  appLanguage: cookies.get(COOKIE_LANGUAGE) || 'en-GB',
  showingSubHeader: false,
  notifications: [],
};

export default handleActions({
  [logout]: () => defaultState,

  [setAppTheme]: (state, { payload: theme }) => {
    cookies.set(COOKIE_THEME, theme, { path: '/', sameSite: 'lax' });
    return { ...state, appTheme: theme };
  },
  [setAppLanguage]: (state, { payload: language }) => {
    cookies.set(COOKIE_LANGUAGE, language, { path: '/', sameSite: 'lax' });
    return { ...state, appLanguage: language };
  },

  [setShowSubHeader]: (state, { payload: isShown }) => ({ ...state, showingSubHeader: isShown }),

  // TODO: switch to Notistack (https://github.com/iamhosseindhv/notistack)
  [addNotification]: (state, { payload: notification }) => ({
    ...state,
    notifications: [
      ...state.notifications,
      notification,
    ],
  }),
  [removeNotifications]: (state) => ({
    ...state,
    notifications: state.notifications.slice(0, state.notifications.length - 1),
  }),
}, defaultState);