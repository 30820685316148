import React from 'react';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';

import {
  Box,
  ButtonBase,
} from '@mui/material';

import { STATES } from '../../../Utils/States';

export const LARGE_WIDTH  = 140;
export const SMALL_WIDTH  = 120;

export const LARGE_HEIGHT = 48;
export const SMALL_HEIGHT = 36;

export const LARGE_BORDER_WIDTH = 8;
export const SMALL_BORDER_WIDTH = 6;

export const LARGE_FONT_SIZE = '0.875rem';
export const SMALL_FONT_SIZE = '0.8rem';

const StateBase = styled(ButtonBase)(({ theme, ownerState }) => ({
  width: `${ownerState.width}px`,
  height: `${ownerState.height}px`,
  backgroundColor: ownerState.active ? theme.palette.action.selected : 'transparent',
  fontSize: ownerState.fontSize,
  fontWeight: ownerState.active ? 'bold' : 'normal',
  fontStyle: ownerState.pending ? 'italic' : 'normal',
  color: (ownerState.active || ownerState.clickable) ? theme.palette.text.primary : theme.palette.text.secondary,

  ...(ownerState.left && {
    borderLeftWidth: ownerState.pending ? 0 : ownerState.borderWidth,
    borderLeftColor: ownerState.colour,
    borderLeftStyle: 'solid',
  }),

  ...(ownerState.right && {
    borderRightWidth: ownerState.pending ? 0 : ownerState.borderWidth,
    borderRightColor: ownerState.colour,
    borderRightStyle: 'solid',
  }),

  ...(ownerState.clickable && {
    transition: theme.transitions.create(
      ['background-color', 'border-color', 'color'],
      { duration: theme.transitions.duration.short },
    ),

    '&:hover': {
      backgroundColor: ownerState.active ? theme.palette.action.focus : theme.palette.action.hover,

      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: ownerState.active ? theme.palette.action.selected : 'transparent',
      },
    },
  }),
}));

const StateButton = ({
  state,
  active,
  disabled,
  small = false,
  left  = false,
  right = false,
  widthMult = 1,
  onClick,
  sx,
  ...props
}) => {
  const theme = useTheme();

  const clickable = !disabled && !active && onClick;

  const WIDTH  = ((small ? SMALL_WIDTH  : LARGE_WIDTH)  + 1) * widthMult  - 1;
  const HEIGHT = small ? SMALL_HEIGHT : LARGE_HEIGHT;
  const BORDER_WIDTH = small ? SMALL_BORDER_WIDTH : LARGE_BORDER_WIDTH;
  const FONT_SIZE = small ? SMALL_FONT_SIZE : LARGE_FONT_SIZE;

  let colour;

  if (active) {
    colour = theme.palette.states[state.id].dark;
  }
  else if (state === STATES.OK) {
    colour = theme.palette.states.OK.main
  }
  else {
    colour = theme.palette.states[state.id].light;
  }

  const ownerState = {
    width: WIDTH,
    height: HEIGHT,
    borderWidth: BORDER_WIDTH,
    fontSize: FONT_SIZE,
    colour,
    active,
    small,
    left,
    right,
    clickable,
  };
  
  return (
    <Box title={state.description} sx={sx} {...props}>
      <StateBase ownerState={ownerState} disabled={!clickable} onClick={() => onClick(state)}>
        {state?.label || '???'}
      </StateBase>
    </Box>
  );
}

export default StateButton;