export const TYPES = {
  STATIC_INFO:  { id: 'STATIC_INFO', label: 'Static Info' },
  ASSET_INFO:   { id: 'ASSET_INFO', label: 'Asset Info' },
  STATE_UPDATE: { id: 'STATE_UPDATE', label: 'State Update' },
  SETTINGS:     { id: 'SETTINGS', label: 'Settings' },
  IMAGE:        { id: 'IMAGE', label: 'Image' },
  SETTINGS_SMS: { id: 'SETTINGS_SMS', label: 'Settings SMS' },
  BESPOKE_SMS:  { id: 'BESPOKE_SMS', label: 'Bespoke SMS' },
};

export const TYPES_ARRAY = [
  TYPES.STATIC_INFO,
  TYPES.ASSET_INFO,
  TYPES.STATE_UPDATE,
  TYPES.SETTINGS,
  TYPES.IMAGE,
  TYPES.SETTINGS_SMS,
  TYPES.BESPOKE_SMS,
];