import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

import {
  Box,
} from '@mui/material';

import {
  CircleRounded as GreenIcon,
  RemoveCircleRounded as AmberIcon,
  ErrorRounded as RedIcon,
} from '@mui/icons-material';

export const HEALTH_STATES = {
  unknown:  { id: 'blue',  colour: 't0', symbol: '•' },
  green:    { id: 'green', colour: 't2', symbol: '' },
  amber:    { id: 'amber', colour: 't4', symbol: '-' },
  red:      { id: 'red',   colour: 't5', symbol: '!' },
}

export const HEALTH_STATES_ARRAY = [
  HEALTH_STATES.unknown,
  HEALTH_STATES.green,
  HEALTH_STATES.amber,
  HEALTH_STATES.red,
];

const UnitHealth = ({
  state,
  size = 28,
  sx,
}) => {
  const theme = useTheme();

  const BORDER_WIDTH = Math.ceil(size / 16);

  if (!state) {
    state = HEALTH_STATES.unknown;
  }

  function getIcon() {
    const props = {
      sx: {
        m: `-${BORDER_WIDTH}px`,
        fontSize: size,
        color: theme.palette.health[state.colour],
      },
    }
  
    switch (state.id) {
      case 'red':
        return (<RedIcon { ...props }/>);
  
      case 'amber':
        return (<AmberIcon { ...props }/>);
  
      default:
        return (<GreenIcon { ...props }/>);
    }
  }
  
  return (
    <Box sx={theme => ({
      width: `${size}px`,
      height: `${size}px`,
      borderRadius: `${size / 2}px`,
      color: theme.palette.text.disabled,
      border: `${BORDER_WIDTH}px solid ${theme.palette.primary.contrastText}`,
      bgcolor: theme.palette.primary.contrastText,
      ...sx
    })}>
      {getIcon()}
    </Box>
  );

  // TODO: re-add symbol & fix layout
  // return (
  //   <Box sx={theme => ({
  //     width: `${size}px`,
  //     height: `${size}px`,
  //     borderRadius: `${size / 2}px`,
  //     color: theme.palette.primary.contrastText,
  //     border: `${BORDER_WIDTH}px solid ${theme.palette.primary.contrastText}`,
  //     bgcolor: theme.palette.health[state.colour],
  //     textAlign: 'center',
  //     fontSize: `${size}px`,
  //     ...sx,
  //   })}>
  //     {/* {state.symbol} */}
  //   </Box>
  // );
}

UnitHealth.propTypes = {
  state: PropTypes.exact({
    id: PropTypes.string,
    colour: PropTypes.string,
    symbol: PropTypes.string,
  }),
  size: PropTypes.number,
  sx: PropTypes.object,
};

export default UnitHealth;