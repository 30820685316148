import { createAction } from 'redux-actions';

const ENTITY = '@@SYSTEM_NEW';

// System
export const setAppTheme = createAction(`${ENTITY}/SET_APP_THEME`);
export const setAppLanguage = createAction(`${ENTITY}/SET_APP_LANGUAGE`);
export const setShowSubHeader = createAction(`${ENTITY}/SET_SHOW_SUB_HEADER`);
export const checkVersion = createAction(`${ENTITY}/CHECK_VERSION`);

// Notifications
export const addNotification = createAction(`${ENTITY}/ADD_NOTIFICATION`);
export const removeNotifications = createAction(`${ENTITY}/REMOVE_NOTIFICATIONS`);
