import React from 'react';
import { compose } from 'redux';

import {
  Box,
  Button,
  Divider,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';

import {
  Close as CancelIcon,
  Edit as EditIcon,
  Save as SaveIcon,
} from '@mui/icons-material';

import PaperSection from '../Components/PaperSection';
import Image from '../Components/Image';
import FormDialog from '../Components/Dialog_NEW/FormDialog';
import PasswordField from '../Components/Inputs/PasswordField';

import defaultProfile from '../../Assets/Images/profile.png';
import { UNITS_ARRAY } from '../../Utils/Units';
import timezones from '../../Assets/availableTimezones.json';
import { getTimezones } from '../../Utils/TimeUtils';

import withSystemActions from '../../redux/WithActions/system';
import withUserData from '../../redux/WithData/user';
import withUserActions from '../../redux/WithActions/user';

const AVATAR_SIZE = 180;

// TODO: update [Luxon] 'Settings.defaultZone()' with user-selected timezone

const UserProfile = ({
  role,
  email,
  firstName,
  lastName,
  avatar,
  customer,
  franchise,
  setUserPassword,
  setShowSubHeader,
}) => {
  // Set the browser tab's title
  document.title = 'My Profile - MTrack';

  const [changePWOpen, setChangePWOpen] = React.useState(false);
  const [isEditable, setEditable] = React.useState(false);
  const [timezones_NEW, setTimezones] = React.useState([]);

  const [values, setValues] = React.useState({
    firstName: '',
    lastName: '',
    phone: '',
  });

  const [preferences, setPreferences] = React.useState({
    timezone: '',
    units: '',
  });
  
  const savedState = React.useRef({});

  // Clear 'showSubHeader' so the main header renders correctly
  React.useEffect(() => { setShowSubHeader(false); }, [ setShowSubHeader ]);

  // Load list of timezones
  React.useEffect(() => { setTimezones(getTimezones()); }, []);

  React.useEffect(() => {
    // TODO: include phone number & fetch preferences from cookies

    setValues({
      firstName: firstName,
      lastName: lastName,
      phone: '',
    });

    setPreferences({
      // timezone: 'Etc/UTC',
      timezone: 'local',
      units: 'metric',
    });

    savedState.current = values;
  }, [])

  const handleClickEdit = () => {
    setEditable(true);
  }

  const handleClickSave = () => {
    // TODO: Send to server

    savedState.current = values;

    setEditable(false);
  }

  const handleClickCancel = () => {
    setValues(savedState.current);

    setEditable(false);
  }

  const handleChangeDetails = (e) => {
    if (values[e.target.name] !== e.target.value) {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
      })
    }
  }

  const handleChangePreferences = (e) => {
    if (preferences[e.target.name] !== e.target.value) {
      setPreferences({
        ...preferences,
        [e.target.name]: e.target.value,
      })
    }

    // TODO: update preferences in cookie(s)
  }

  const handleSubmitChangePW = (values) => {
    // API call to change users password
    setUserPassword(values.confirmPW);

    setChangePWOpen(false);
  }

  const handleValidateChangePW = (values) => {
    const errors = {};

    if (!values.currentPW) errors.currentPW = 'Required';   // Current Password
    if (!values.newPW)     errors.newPW     = 'Required';   // New Password
    if (!values.confirmPW) errors.confirmPW = 'Required';   // Confirm New Password

    if (values.newPW !== values.confirmPW) {
      errors.confirmPW = 'Password does not match';
    }

    return errors;
  }

  const getConstDataRow = (title, data) => (
    <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1, mb: 1 }}>
      <Typography sx={{ m: 'auto 0', width: '200px' }}>{title}</Typography>
      <Typography sx={{ m: 'auto 0' }}>{data}</Typography>
    </Box>
  );

  const getEditableDataRow = (key, label) => (
    <TextField
      disabled={!isEditable}
      name={key}
      label={label}
      value={values[key]}
      onChange={handleChangeDetails}
      sx={theme => ({
        mt: 1,
        mb: 1,
        // TODO: generalise these 'disabled but still legible' TextField params
        '& .MuiOutlinedInput-input.Mui-disabled': {
          color: theme.palette.text.secondary,
          WebkitTextFillColor: theme.palette.text.secondary,
        },
        '& .MuiInputLabel-root.Mui-disabled': {
          color: theme.palette.text.secondary,
        },
      })}
    />
  );

  const getButtonChangePassword = (sx) => (
    <>
      <Button variant="text" onClick={() => setChangePWOpen(true)} sx={sx}>Change Password</Button>
      <FormDialog
        open={changePWOpen}
        title="Change Password"
        defaultValues={{ currentPW: '', newPW: '', confirmPW: '' }}
        onClose={() => setChangePWOpen(false)}
        onSubmit={handleSubmitChangePW}
        onValidate={handleValidateChangePW}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
        }) => (
          <>
            <PasswordField
              name="currentPW"
              label="Current Password"
              autoComplete="current-password"
              value={values.currentPW}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(errors.currentPW)}
              helperText={errors.currentPW || undefined}
            />
            <PasswordField
              name="newPW"
              label="New Password"
              autoComplete="new-password"
              value={values.newPW}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(errors.newPW)}
              helperText={errors.newPW || undefined}
              sx={{ mt: 4, mb: 2 }}
            />
            <PasswordField
              name="confirmPW"
              label="Confirm New Password"
              autoComplete="new-password"
              value={values.confirmPW}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(errors.confirmPW)}
              helperText={errors.confirmPW || undefined}
            />
          </>
        )}
      </FormDialog>
    </>
  );

  return (
    <PaperSection sx={{ display: 'flex', flexDirection: 'column', ml: 'auto', mr: 'auto', p: 6, pt: 4, pb: 4, width: '100%', maxWidth: '800px', height: '100%' }}>
      <Typography variant="h4" sx={{ m: '0 auto', mb: 2 }}>My Profile</Typography>

      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography variant="h6">Account</Typography>
        {getButtonChangePassword({ ml: 'auto' })}
      </Box>

      {getConstDataRow('Email', email)}
      {franchise?.name && getConstDataRow('Franchise', franchise.name)}
      {customer?.name && getConstDataRow('Customer', customer.name)}

      <Divider sx={{ mt: 2, mb: 2 }}/>

      <Typography variant="h6">Personal Details</Typography>

      <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
        <Image src={defaultProfile} fit="contain" height={AVATAR_SIZE} width={AVATAR_SIZE} sx={{ borderRadius: `${AVATAR_SIZE / 2}px` }}/>

        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', m: 'auto 0', ml: 4 }}>
          {/* TODO: Add input validation - name cannot have numbers, phone must be valid format */}
          {getEditableDataRow('firstName', 'First Name')}
          {getEditableDataRow('lastName', 'Last Name')}
          {getEditableDataRow('phone', 'Phone Number')}
        </Box>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        {isEditable ? (
          <>
            <Button variant="contained" startIcon={<SaveIcon/>} onClick={handleClickSave} sx={{ ml: 'auto', mr: 1 }}>Save</Button>
            <Button variant="contained" startIcon={<CancelIcon/>} onClick={handleClickCancel}>Cancel</Button>
          </>
        ) : (
          <Button variant="contained" startIcon={<EditIcon/>} onClick={handleClickEdit} sx={{ ml: 'auto' }}>Edit</Button>
        )}
      </Box>

      <Divider sx={{ mt: 2, mb: 2 }}/>

      <Typography variant="h6">Preferences</Typography>

      <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
        <TextField
          select
          name="timezone"
          label="Timezone"
          value={preferences.timezone}
          onChange={handleChangePreferences}
          sx={{ width: '60%', mr: 2 }}
        >
          {/* {timezones.map((timezone) => (
            <MenuItem key={timezone.tz} value={timezone.tz}>{timezone.label}</MenuItem>
          ))} */}
          {timezones_NEW.map((tz) => (
            <MenuItem key={tz.luxonTz} value={tz.luxonTz}>{tz.label}</MenuItem>
          ))}
        </TextField>
        <TextField
          select
          name="units"
          label="Units"
          value={preferences.units}
          onChange={handleChangePreferences}
          sx={{ width: '40%' }}
        >
          {UNITS_ARRAY.map((unit) => (
            <MenuItem key={unit.id} value={unit.id}>{unit.label}</MenuItem>
          ))}
        </TextField>
      </Box>
    </PaperSection>
  );
}

export default compose(withSystemActions, withUserData, withUserActions)(UserProfile);