import React from 'react';
import { compose } from 'redux';

import {
  Box,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';

import PaperSection from '../../../Components/PaperSection';
import ModeSelector from '../../../Components/ModeNEW/ModeSelector';
import TagButton from '../../../Components/TagButton';

import { useWindowDimensions } from '../../../../Utils/ScreenSize';
import { TAGS } from '../../../../Utils/Tags';
import { sortArrayAlphabetically } from '../../../../Utils/Utils';

import withTrackerData from '../../../../redux/WithData/tracker';

const TAG_BUTTONS = [
  TAGS.ignoreId,
  TAGS.move,
  TAGS.watch,
  TAGS.wired,
  TAGS.notification,
];

// NOTE: Emulating tags retrieved from server
const unitTags = [
  TAGS.ignoreId,
  TAGS.move,
  TAGS.ignoreTamper,
  TAGS.special,
  TAGS.customSettings,
];

const UnitDetails = ({
  tracker,
  isTrackerLoading,
  sx,
}) => {
  const { isMobileWidth, isDesktopWidth } = useWindowDimensions();

  const [pendingTags, setPendingTags] = React.useState([]);

  const dividerSpacing = isDesktopWidth ? 4 : 2;

  const filterAndSortTags = (tags) => {
    let filteredTags = tags.filter(tag => !TAG_BUTTONS.includes(tag) && tag !== TAGS.customSettings);

    filteredTags = sortArrayAlphabetically(filteredTags, 'label');

    if (tags.includes(TAGS.customSettings)) {
      filteredTags.unshift(TAGS.customSettings);
    }

    return filteredTags;
  }
  
  const handleTagClick = (tag, active, pending) => {
    console.log(`Clicked '${tag.label}'`);

    // TODO: tell server to toggle tag

    if (!pending) {
      setPendingTags([
        ...pendingTags,
        tag,
      ]);
    }
    else {
      setPendingTags(pendingTags.filter(pendingTag => pendingTag !== tag));
    }
  }

  const extraTags = filterAndSortTags(unitTags);

  return (
    <PaperSection sx={{ display: 'flex', flexDirection: 'column', ...sx }}>
      <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Unit</Typography>

      <Stack spacing={2}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography sx={{ m: 'auto 0', minWidth: '150px' }}>Sleep Interval</Typography>
          <Typography>*8 Hours*</Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography sx={{ m: 'auto 0', minWidth: '150px' }}>Next Wake-up</Typography>
          <Typography>*14:31*</Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography sx={{ m: 'auto 0', minWidth: '150px' }}>Battery</Typography>
          <Typography>{`${tracker.battery || 0}%`}</Typography>
        </Box>
      </Stack>

      <Divider sx={{ mt: dividerSpacing, mb: dividerSpacing }}/>

      <Box sx={{ display: 'flex', flexDirection: !isMobileWidth ? 'row' : 'column' }}>
        <Typography sx={{ minWidth: '120px', mb: !isMobileWidth ? 0 : 1 }}>Mode</Typography>
        <ModeSelector small horizontal/>
      </Box>

      <Divider sx={{ mt: dividerSpacing, mb: dividerSpacing }}/>

      <Box sx={{ display: 'flex', flexDirection: !isMobileWidth ? 'row' : 'column' }}>
        <Typography sx={{ minWidth: '120px', mb: !isMobileWidth ? 0 : 1 }}>Tags</Typography>
        
        <Stack spacing={1}>
          <Box sx={{ display: 'flex', flexDirection: 'row', flexFlow: 'wrap' }}>
            {TAG_BUTTONS.map((tag, index) => (
              <TagButton key={index} tag={tag} active={unitTags.includes(tag)} pending={pendingTags.includes(tag)} onClick={handleTagClick} sx={{ mb: 1, mr: 1 }} />
            ))}
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'row', flexFlow: 'wrap' }}>
            {extraTags.map((tag, index) => (
              <TagButton key={index} tag={tag} active sx={{ mb: 1, mr: 1 }}/>
            ))}
          </Box>
        </Stack>
      </Box>
    </PaperSection>
  );
}

export default compose(withTrackerData)(UnitDetails);