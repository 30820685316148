import React from 'react';
import { compose } from 'redux';

import {
  Box,
  Button,
  CircularProgress,
  Checkbox,
  Divider,
  FormGroup,
  FormControlLabel,
  TextField,
} from '@mui/material';

import {
  Add as AddIcon,
} from '@mui/icons-material';

import PaperSection from '../../../Components/PaperSection';
import FormDialog from '../../../Components/Dialog_NEW/FormDialog';
import Note from './Note';

import withTrackerData from '../../../../redux/WithData/tracker';
import withEventData from '../../../../redux/WithData/event';
import withEventActions from '../../../../redux/WithActions/event';

const NotesSection = ({
  filter = [],
  sx,

  tracker,
  notes,
  areNotesLoading,
  createNote,
  deleteNote,
  getNotes,
}) => {
  const [hasStartedLoading, setStartedLoading] = React.useState(false);
  const [showDeleted, setShowDeleted] = React.useState(false);
  const [isDialogOpen, setDialogOpen] = React.useState(false);

  // Fetch all notes via the API
  React.useEffect(() => {
    if (tracker?.simiccid) {
      getNotes({ trackerId: tracker.simiccid, ...filter });

      setStartedLoading(true);
    }
  }, [
    filter,
    tracker,
    getNotes,
  ]);

  function handleChangeToggleShowDeleted({ target }) {
    if (showDeleted !== target.checked) {
      setShowDeleted(target.checked)
    }
  }

  function handleSubmitNote(values) {
    createNote({ trackerId: tracker.simiccid, text: values.note });

    setDialogOpen(false);
  }

  function handleValidateNote(values) {
    const errors = {};

    // Note
    if (!values.note || values.note.length < 1) {
      errors.note = 'Required';
    }

    return errors;
  }

  function handleDeleteNote(note) {
    if (!note || note.deleted) {
      return;
    }

    // Delete note via API
    deleteNote(note.id);
  }

  return (
    <PaperSection sx={{ ...sx, p: 0 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <FormGroup sx={{ m: 'auto 0', ml: 3 }}>
          <FormControlLabel
            control={<Checkbox checked={showDeleted} onChange={handleChangeToggleShowDeleted} />}
            label="Show Deleted Notes"
          />
        </FormGroup>

        <Button variant="contained" startIcon={<AddIcon/>} onClick={() => { setDialogOpen(true); }} sx={{ m: 2, mr: 3, ml: 'auto' }}>Add Notes</Button>
        <FormDialog
          open={isDialogOpen}
          title="Add Notes"
          defaultValues={{ note: '' }}
          onClose={() => { setDialogOpen(false); }}
          onSubmit={handleSubmitNote}
          onValidate={handleValidateNote}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
          }) => (
            <TextField
              required
              autoFocus
              multiline
              rows={3}
              name="note"
              label="Notes"
              value={values.note}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(errors.note)}
              helperText={errors.note || undefined}
            />
          )}
        </FormDialog>
      </Box>

      <Divider/>

      {(!hasStartedLoading || areNotesLoading) ? (
        <CircularProgress sx={{ m: '0 auto', mt: 2 }}/>
      ) :(
        <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
          {notes.map((note) => (showDeleted || !note.deleted) && (
            <Note key={note.id} note={note} onDelete={handleDeleteNote}/>
          ))}
        </Box>
      )}
    </PaperSection>
  );
}

export default compose(withTrackerData, withEventData, withEventActions)(NotesSection);