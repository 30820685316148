import makeStyles from '@mui/styles/makeStyles';

import {
  mobile,
  cardBoxShadow,
} from '../../../Styles/styles';

const customersListStyles = makeStyles((theme) => ({
  emptyCustomerList: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyCustomerListHeader: {
    fontSize: '20px',
    color: '#9E9E9E',
    textAlign: 'center',
  },
  customerList: {
    width: '100%',
    flexGrow: '1',
    padding: '24px',
    overflowY: 'auto',
    borderRadius: '8px',
    boxSizing: 'border-box',
    backgroundColor: 'white',
    boxShadow: cardBoxShadow,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme.breakpoints.down(mobile)]: {
      overflowY: 'unset',
    },
  },
  customerListItem: {
    height: '72px',
    // Do not Display edit and delete buttons
    '&>:nth-child(2)': {
      display: 'none',
      justifyContent: 'space-between',
    },
    // Display edit and delete buttons on hover
    '&:hover': {
      cursor: 'pointer',
      borderRadius: '10px',
      backgroundColor: '#fafafa',
      '&>:nth-child(2)': {
        display: 'flex',
      },
    },
    // Always display edit and delete buttons on mobile
    // Breakpoint for iPadAir, iPadMini and iPhone11Pro
    [theme.breakpoints.down(mobile)]: {
      '&>:last-child': {
        display: 'flex',
      },
    },
  },
  customerListItemText: {
    color: '#323547',
    fontFamily: 'Source Sans Pro',
  },
  spinnerContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default customersListStyles;
