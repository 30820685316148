const ResponseCodes = {
  EmailUnverified: 'API-40108',
  InvalidSecretCode: 'API-40109',
  InvalidCredentials: 'API-40101',
  EmailAlreadyRegistered: 'API-40901',
  TrackersAssignedToCustomer: 'API-40908',
  TrackersNotAssignedToAnyFranchise: 'API-40907',
  TrackersNotAssignedToYourFranchise: 'API-40909',
};

export default ResponseCodes;
