import {
  put,
  select,
  takeLatest,
} from "redux-saga/effects";

import {
  getUserDetails,
  setUserDetails,
  setUserPassword,
  setUserAvatar,

  updateUserDetails,
  updateUserAvatar,
  setUploadingAvatar,
} from '../Actions/user';

import { addNotification } from '../Actions/system';
import { authApiHandler } from './auth';

import UserAPI from '../../Api/Endpoints/user';
import { ResponseTypes } from '../../Api/Responses/baseResponse';

export function* handleGetUserDetails() {
  console.log('Getting user details...');
  const email = yield select((state) => state.AuthData_NEW.email);
  const response = yield authApiHandler(UserAPI.getUserDetails, email);

  if (response.type === ResponseTypes.ActionCompleted) {
    const details = {
      email: email,
      firstName: response.data.firstName,
      lastName: response.data.lastName,
      role: response.data.role,
      avatar: response.data.avatar,
      timezone: response.data.timezone,
      customer: response.data.customer,
      franchise: response.data.franchise,
    };

    yield put(updateUserDetails(details));
  } else if (response.type === ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      source: 'handleGetUserDetails',
      title: 'Could not load profile data',
      message: 'Please, check your connection and try again.',
    }));
  }
}

export function* handleSetUserDetails({ payload }) {
  yield console.log('Setting user details...');
}

export function* handleSetUserPassword({ payload: newPassword }) {
  const email = yield select((state) => state.AuthData_NEW.email);
  let response = yield authApiHandler(UserAPI.setUserPassword, email, newPassword);

  if (response.type == ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not update password',
      source: 'handleSetUserPassword',
      message: 'Please check your connection and try again.',
    }));
  }
}

export function* handleSetUserAvatar({ payload: avatarImage }) {
  yield put(setUploadingAvatar(true));

  const email = yield select((state) => state.AuthData_NEW.email);
  let response = yield authApiHandler(UserAPI.setUserAvatar, email, avatarImage);

  // TODO: store new avatar URLs

  if (response.type == ResponseTypes.Exception) {
    yield put(addNotification({
      type: 'error',
      title: 'Could not upload avatar',
      source: 'handleSetUserAvatar',
      message: 'Please check your connection and try again.',
    }));
  }

  yield put(setUploadingAvatar(false));
}

export default function* Sagas() {
  yield takeLatest(getUserDetails, handleGetUserDetails);
  yield takeLatest(setUserDetails, handleSetUserDetails);
  yield takeLatest(setUserPassword, handleSetUserPassword);
  yield takeLatest(setUserAvatar, handleSetUserAvatar);
}