import validator from 'validator';
import React, { useState } from 'react';

import { TextField, IconButton, InputAdornment, FormHelperText } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { semiBoldFont } from '../../../Styles/styles';

const inputFieldStyles = makeStyles(() => ({
  inputFieldContainer: {
    display: 'flex',
    minHeight: '60px',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  inputField: {
    width: '100%',
    display: 'flex',
    fontSize: '14px',
    lineHeight: '20px',
    alignItems: 'center',
    fontFamily: semiBoldFont,
  },
}));

const InputField = ({
  value,
  id = '',
  onChange,
  onKeyDown,
  name = '',
  label = '',
  type = 'text',
  helper = false,
  helperText = '',
  disabled = false,
  modalAction = '',
  autoFocus = false,
  errors = undefined,
}) => {
  const classes = inputFieldStyles();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const textFieldType = () => {
    if (type === 'password' && showPassword) return 'text';
    if (type === 'password' && !showPassword) return 'password';
    return 'text';
  };

  return (
    <div className={classes.inputFieldContainer}>
      <TextField
        id={id}
        required
        fullWidth
        name={name}
        size="small"
        value={value}
        label={label}
        variant="outlined"
        onChange={onChange}
        disabled={disabled}
        autoFocus={autoFocus}
        onKeyDown={onKeyDown}
        type={textFieldType()}
        className={classes.inputField}
        style={{ marginBottom: '0px', marginTop: '10px' }}
        InputProps={type === 'password'
          ? {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={handleClickShowPassword} size="large">
                  {showPassword ? <Visibility color="secondary" /> : <VisibilityOff color="secondary" />}
                </IconButton>
              </InputAdornment>
            ),
          } : {}}
      />
      { modalAction !== '' && !(value && validator.isEmail(value))
        && <FormHelperText>{helperText}</FormHelperText>}
      {
        errors ? (<FormHelperText className="error MuiFormHelperText-root" id={id}>{errors}</FormHelperText>)
          : helper && (<FormHelperText id={id}>{helperText}</FormHelperText>)
      }
    </div>
  );
};

export default InputField;
