import React from 'react';
import { compose } from 'redux';

import {
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import {
  Edit as EditIcon,
  SwapHoriz as TransferIcon,
} from '@mui/icons-material';

import PaperSection from '../../../Components/PaperSection';
import FormDialog from '../../../Components/Dialog_NEW/FormDialog';

import { ROLES } from '../../../../Utils/Roles';

import withUserData from '../../../../redux/WithData/user';

const OverviewTab = ({
  role,
}) => {
  const [transferFranchiseOpen, setTransferFranchiseOpen] = React.useState(false);
  const [transferUnitsOpen, setTransferUnitsOpen] = React.useState(false);

  const emails = [

  ];

  const phoneNumbers = [
    {
      number: '01234 567890',
      description: 'Landline',
    },
    {
      number: '97531 147369',
      description: 'Mobile 1',
    },
    {
      number: '84265 014739',
      description: 'Mobile 2',
    },
  ];

  const address = [

  ];

  // TODO: replace with real franchises from API
  const franchises = [
    'Franchise 1',
    'Franchise 2',
    'Franchise 3',
  ];

  // TODO: replace with real customers from API
  const customers = [
    'Customer 1',
    'Customer 2',
    'Customer 3',
  ];

  const handleClickTransferFranchise = () => {
    // TODO: get franchises via API OR get from 'store'?

    setTransferFranchiseOpen(true);
  }

  const handleClickTransferUnits = () => {
    // TODO: get customers via API OR get from 'store'?

    setTransferUnitsOpen(true);
  }

  const handleSubmitTransferFranchise = (values) => {
    console.log(`Transferred customer to '${values.franchise}'`);

    // TODO: API call

    setTransferFranchiseOpen(false);
  }

  const handleSubmitTransferUnits = (values) => {
    console.log(`Transferred units to '${values.customer}'`);

    // TODO: API call

    setTransferUnitsOpen(false);
  }

  const handleValidateTransferFranchise = (values) => {
    const errors = {};

    // Franchise
    if (!values.franchise) {
      errors.email = 'Required';
    }

    return errors;
  }

  const getButtonTransferCustomers = (sx) => (
    <>
      <IconButton onClick={handleClickTransferFranchise} sx={sx}><TransferIcon/></IconButton>
      <FormDialog
        open={transferFranchiseOpen}
        title="Change Franchise"
        defaultValues={{ franchise: '' }}
        onClose={() => setTransferFranchiseOpen(false)}
        onSubmit={handleSubmitTransferFranchise}
        onValidate={handleValidateTransferFranchise}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
        }) => (
          <>
            <Typography sx={{ mb: 2}}>Transfer this customer to a different franchise</Typography>
            <TextField
              required
              select
              name="franchise"
              label="Franchise"
              value={values.franchise}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(errors.franchise)}
              helperText={errors.franchise || undefined}
            >
              {franchises.map((franchise) => (
                <MenuItem key={franchise} value={franchise}>{franchise}</MenuItem>
              ))}
            </TextField>
          </>
        )}
      </FormDialog>
    </>
  );

  const getButtonTransferUnits = (sx) => (
    <>
      <IconButton onClick={handleClickTransferUnits} sx={sx}><TransferIcon/></IconButton>
      <FormDialog
        open={transferUnitsOpen}
        title="Transfer Units"
        defaultValues={{ customer: '' }}
        onClose={() => setTransferUnitsOpen(false)}
        onSubmit={handleSubmitTransferUnits}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
        }) => (
          <>
            <Typography sx={{ mb: 2}}>Transfer this customers units to another customer. If no customer is specified, it will be assigned to the franchises stock</Typography>
            <TextField
              required
              select
              name="customer"
              label="Customer"
              value={values.customer}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(errors.customer)}
              helperText={errors.customer || undefined}
            >
              {customers.map((customer) => (
                <MenuItem key={customer} value={customer}>{customer}</MenuItem>
              ))}
            </TextField>
          </>
        )}
      </FormDialog>
    </>
  );

  return (
    <PaperSection sx={{ display: 'flex', flexDirection: 'column', ml: 'auto', mr: 'auto', p: 6, pt: 4, pb: 4, width: '100%', maxWidth: '800px', height: '100%' }}>
      <Typography variant="h4" sx={{ m: '0 auto' }}>*Customer Name*</Typography>

      <Box sx={{ display: 'flex', flexDirection: 'row', mt: 4 }}>
        <Typography sx={{ m: 'auto 0', width: '200px' }}>Franchise</Typography>
        <Link href="/franchises/123" underline="hover" color="inherit" sx={{ m: 'auto 0' }}>*Automatrics*</Link>
        {role === ROLES.root && getButtonTransferCustomers({ m: '-8px 0 -8px auto' })}
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
        <Typography sx={{ m: 'auto 0', width: '200px' }}>Number of Units</Typography>
        <Typography sx={{ m: 'auto 0' }}>*123*</Typography>
        {(role === ROLES.root || role === ROLES.admin) && getButtonTransferUnits({ m: '-8px 0 -8px auto' })}
      </Box>

      <Divider sx={{ mt: 2, mb: 2 }}/>

      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography variant="h6" sx={{ m: 'auto 0' }}>Contact Details</Typography>
        <Button variant="contained" startIcon={<EditIcon/>} sx={{ m: 'auto', mr: 0 }}>Edit</Button>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
        <Typography sx={{ width: '200px' }}>Email</Typography>
        {(emails && emails.length > 0) ? (
          <Stack spacing={1}>
            {emails.map((email) => (
              <Typography key={email}>{email}</Typography>
            ))}
          </Stack>
        ) : (
          <Typography>N/A</Typography>
        )}
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
        <Typography sx={{ width: '200px' }}>Phone Number</Typography>
        {(phoneNumbers && phoneNumbers.length > 0) ? (
          <Stack spacing={1}>
            {phoneNumbers.map((phone) => (
              <Typography key={phone.number}>{phone.number} - {phone.description}</Typography>
            ))}
          </Stack>
        ) : (
          <Typography>N/A</Typography>
        )}
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
        <Typography sx={{ width: '200px' }}>Address</Typography>
        {(address && address.length > 0) ? (
          <Stack spacing={1}>
            {address.map((adr) => (
              <Typography key={adr}>{adr}</Typography>
            ))}
          </Stack>
        ) : (
          <Typography>N/A</Typography>
        )}
      </Box>
    </PaperSection>
  );
}

export default compose(withUserData)(OverviewTab);