import { compose } from 'redux';
import React, { useEffect } from 'react';
import {
  Paper,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TableContainer,
  CircularProgress,
} from '@mui/material';

import accountsTableStyles from './style';
import { isAccountRoleUser, isAccountRoleAdmin, isAccountRoleRoot } from '../../../Utils/Utils';

import withUserData from '../../../redux/WithData/user';
import withAccountData from '../../../Hocs/WithData/account';
import withAccountActions from '../../../Hocs/WithAction/account';

const AccountsTable = ({
  role,
  accounts,
  areAccountsLoading,
  getPaginatedAccounts,
}) => {
  const classes = accountsTableStyles();
  const columns = [
    { id: 'name', label: 'Full Name' },
    { id: 'email', label: 'Email Address' },
    { id: 'franchise', label: 'Franchise' },
    { id: 'customer', label: 'Customer' },
    { id: 'role', label: 'Role' },
    { id: 'status', label: 'Status' },
  ];
  const adminColumns = [
    { id: 'name', label: 'Full Name' },
    { id: 'email', label: 'Email Address' },
    { id: 'customer', label: 'Customer' },
    { id: 'role', label: 'Role' },
    { id: 'status', label: 'Status' },
  ];
  const userColumns = [
    { id: 'name', label: 'Full Name' },
    { id: 'email', label: 'Email Address' },
    { id: 'status', label: 'Status' },
  ];

  useEffect(() => {
    getPaginatedAccounts();
  }, [getPaginatedAccounts]);

  const spinner = (
    <div className={classes.spinnerContainer}>
      <CircularProgress />
    </div>
  );

  if (areAccountsLoading) return spinner;

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>
            {(isAccountRoleRoot(role)) && columns
              .map((column) => (<TableCell key={column.id}>{column.label}</TableCell>))}
            {(isAccountRoleAdmin(role)) && adminColumns
              .map((column) => (<TableCell key={column.id}>{column.label}</TableCell>))}
            {(isAccountRoleUser(role)) && userColumns
              .map((column) => (<TableCell key={column.id}>{column.label}</TableCell>))}
          </TableRow>
        </TableHead>
        <TableBody>
          {accounts.map((account) => (
            <TableRow
              key={account.username}
              className={classes.tableRow}
            >
              <TableCell component="th" scope="row">
                {`${account.firstName} ${account.lastName}` || 'N/A'}
              </TableCell>
              <TableCell component="th" scope="row">
                {account.username}
              </TableCell>
              {(!isAccountRoleUser(role))
              && (
                <>
                  {(!isAccountRoleAdmin(role))
                  && (
                    <TableCell component="th" scope="row">
                        {account.franchise?.name || 'N/A'}
                    </TableCell>
                  )}
                  <TableCell component="th" scope="row">
                    {account.customer?.name || 'N/A'}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {account.role}
                  </TableCell>
                </>
              )}
              <TableCell component="th" scope="row">
                {account.status === 0 ? 'Active' : 'Pending verification'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default compose(
  withUserData,
  withAccountData,
  withAccountActions,
)(AccountsTable);
