import React from 'react';
import { compose } from 'redux';

import CameraIcon from '@mui/icons-material/PhotoCamera';
import { Avatar, Button, Typography, CircularProgress } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { buttonPurple } from '../../../../Styles/styles';
import withTrackersData from '../../../../Hocs/WithData/tracker';
import defaultImageUrl from '../../../../Assets/Images/vehicle.png';
import withTrackersActions from '../../../../Hocs/WithAction/tracker';

const trackerImageUploaderStyles = makeStyles(() => ({
  overlay: {
    opacity: '0',
    color: 'white',
    width: '72px',
    left: '-72px',
    height: '72px',
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    position: 'relative',
    background: '#92278F',
    transition: '.5s ease',
    justifyContent: 'center',
  },
  loading: {
    opacity: '50%',
    display: 'flex',
    justifyContent: 'center',
    '& svg': {
      color: 'white',
    },
  },
  imageUploader: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    marginBottom: '13px',
    '& div': {
      width: '72px',
      height: '72px',
    },
    '&:hover': {
      '& div': {
        opacity: '50%',
      },
    },
  },
  uploadText: {
    fontSize: '12px',
    color: '#4F5572',
    lineHeight: '20px',
    letterSpacing: '0',
    fontStyle: 'italic',
    marginBottom: '15px',
    fontFamily: '"Source Sans Pro"',
  },
  button: {
    left: '-46px',
    margin: '0px',
    width: '100%',
    color: buttonPurple,
    borderRadius: '8px',
    position: 'relative',
    textTransform: 'none',
    maxWidth: 'fit-content',
  },
}));

const TrackerImageUploader = ({ tracker, uploadTrackerImage, isTrackerImageUploading }) => {
  const classes = trackerImageUploaderStyles();
  const urlImage = (tracker.image) ? tracker.image.thumbnailUrl : defaultImageUrl;
  const handleChangeImage = ({ target: { files } }) => {
    const imageFile = files[0];
    if (imageFile) {
      uploadTrackerImage({ trackerId: tracker.simiccid, imageFile });
    }
  };

  return (
    <>
      <input
        hidden
        type="file"
        id="file-upload"
        accept="image/jpeg"
        onChange={handleChangeImage}
      />
      <Typography className={classes.uploadText}>
        You can upload a JPG or PNG file (File size limit is 10MB)
      </Typography>
      <label htmlFor="file-upload" className={classes.imageUploader}>
        <Avatar
          className="avatar-wrapper medium"
          src={`${urlImage}?${new Date().getTime()}`}
          title="avatar"
        />
        {isTrackerImageUploading
          ? (
            <div className={`${classes.overlay} ${classes.loading}`}>
              <CircularProgress />
            </div>
          )
          : (
            <div className={classes.overlay}>
              <CameraIcon />
            </div>
          )}
        <Button className={classes.button} component="span">
          Upload image
        </Button>
      </label>
    </>
  );
};

export default compose(withTrackersActions, withTrackersData)(TrackerImageUploader);
