import React from 'react';
import { compose } from 'redux';

import { Link, useHistory } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  Avatar,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';

import {
  AccountCircle as ProfileIcon,
  Translate as LanguageIcon,
  KeyboardArrowRight as ArrowRightIcon,
  LightMode as LightIcon,
  DarkMode as DarkIcon,
  Logout as LogoutIcon,
} from '@mui/icons-material/';

import withSystemData from '../../../redux/WithData/system';
import withSystemActions from '../../../redux/WithActions/system';

const ProfileMenu = ({
  edge,
  color,
  size,
  userData,
  barHeight,
  handleCloseDrawer,
  handleLogout,

  appTheme,
  setAppTheme,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isOpen = Boolean(anchorEl);

  const history = useHistory();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

  const handleClickOpenMenu = (event) => {
    handleCloseDrawer();

    setAnchorEl(event.currentTarget);
  }

  const handleClickClose = () => {
    setAnchorEl(null);
  }

  const handleClickToggleTheme = () => {
    setAppTheme(appTheme === 'light' ? 'dark' : 'light')
  }

  const handleClickLogout = () => {
    history.push('/login');
    handleLogout();
  }

  const userFullName = `${userData.firstName} ${userData.lastName}`;
  const userInitials = `${userData.firstName[0]}${userData.lastName[0]}`;

  const iconSize = barHeight * 0.75;

  return (
    <>
      <Button
        id="profileMenuButton"
        aria-controls={isOpen ? 'profileMenu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        edge={edge}
        color={color}
        size={size}
        onClick={handleClickOpenMenu}
        sx={{ marginLeft: 'auto' }}
        endIcon={
          <Avatar
            src={userData.avatar && userData.avatar.thumbnailUrl}
            sx={{ bgcolor: 'background.default', color: 'primary.main', width: `${iconSize}px`, height: `${iconSize}px` }} />}
      >
        {isMobile ? (userInitials) : (userFullName)}
      </Button>
      <Menu
        id="profileMenu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClickClose}
        MenuListProps={{
          'aria-labelledby': 'profileMenuButton',
        }}
        sx={{
          '& .MuiList-root': {
            '& .MuiMenuItem-root': {
              paddingTop: 0,
              paddingBottom: 0,
              minHeight: '36px',
            },
            '& .MuiDivider-root': {
              marginTop: '4px',
              marginBottom: '4px',
            },
          },
        }}
      >
        <MenuItem onClick={handleClickClose}>
          <Link
            to="/profile"
            key="profile"
            style={{ display: 'flex', color: 'inherit', textDecoration: 'none' }}>
            <ListItemIcon>
              <ProfileIcon />
            </ListItemIcon>
            <ListItemText>View Profile</ListItemText>
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClickClose}>
          <Link
            to="/profile_old"
            key="profile_old"
            style={{ display: 'flex', color: 'inherit', textDecoration: 'none' }}>
            <ListItemIcon>
              <ProfileIcon />
            </ListItemIcon>
            <ListItemText>View Profile (OLD)</ListItemText>
          </Link>
        </MenuItem>
        
        <Divider/>

        <MenuItem>
          <ListItemIcon>
            <LanguageIcon/>
          </ListItemIcon>
          <ListItemText>Language</ListItemText>
          <ArrowRightIcon/>
        </MenuItem>

        <MenuItem onClick={handleClickToggleTheme}>
          <ListItemIcon>
            {appTheme === 'light' ? <DarkIcon/> : <LightIcon/>}
          </ListItemIcon>
          <ListItemText>{appTheme === 'light' ? 'Dark Mode' : 'Light Mode'}</ListItemText>
        </MenuItem>

        <Divider/>

        <MenuItem onClick={handleClickLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default compose(withSystemData, withSystemActions)(ProfileMenu);